import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useHistory, useLocation } from "react-router";
import {
  DeviceMobile,
  RssSimple,
  Eye,
  CurrencyCircleDollar,
  Storefront,
  Activity,
} from "phosphor-react";
import { StoreResource } from "../../../types/resource/store.d";
import { ActivityLogContentResource } from "../../../types/resource/activityLogContent.d";
import { ActivityLogContentResponse, ActivityLogStatus, ActionType, ActivityLogResource, IllegalStatus } from "../../../types/activityLog";
import { UserActivitiesResponse } from "../../../types/api/activityLog.d";
import { useApi, useGetApiCall, useBooleanState, usePcSizeFlag, useQuery } from "../../../lib/hooks";
import { formatDateExceptTimeToJp, downloadCSVFile } from "../../../lib/general";
import { IllegalStatusLabel } from "../../../constants/activityLog";
import { useNotification } from "../../../providers/NotificationProvider";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import ActivityLogEntityContainer, { StatusType } from "../../../containers/entities/ActivityLogEntityContainer";
import OptionButton from "../../../components/CustomButton/OptionButton";
import Button from "../../../components/CustomButton/CustomButton";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import ActivityLogIllegalStatusChangeModal from "./ActivityLogIllegalStatusChangeModal";
import SectionTitle from "./SectionTitle";
import NoItemContent from "../../../components/Utils/NoItemContent";
import ActivityLogFilteringModal from "../../ActivityLog/parts/ActivityLogFilteringModal";
import ActivityLogFilteringConditionElements from "../../ActivityLog/parts/ActivityLogFilteringConditionElements";

type Props = {
  userId: number;
  onDeleteActivityLog: () => void;
  [key: string]: any;
};

type UpdateActivityLogProps = {
  id: number;
  illegalStatus: IllegalStatus;
  doubtfulReasonText: string;
};

type QueryParams = {
  from: string | null;
  to: string | null; 
  store_ids: number[];
  action_types: ActionType[];
  content_id: number | null;
  status: ActivityLogStatus[];
  illegal_statuses: IllegalStatus[];
  page?: number;
}

const ActivityLog = ({
  userId,
  onDeleteActivityLog,
  ...otherProps
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext();
  const { showSuccessNotification } = useNotification();
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();

  const urlPage = Number(query.get("page")) || 1;
  const urlFrom = query.get("from");
  const urlTo = query.get("to");
  // getAllメソッドは指定した引数の値を配列で返すメソッドである
  // そのため、useMemoをつけないとレンダリングのたびに新しい配列が生成されてしまうため無限ループが起きる
  const urlStoreIds = useMemo(() => query.getAll("store_ids[]")?.map(Number), [query]);
  const urlActionTypes = useMemo(() => query.getAll("action_types[]") as Array<ActionType> || [], [query]);
  const urlContentId = Number(query.get("content_id")) || null;
  const urlStatus = useMemo(() => query.getAll("status[]") as Array<StatusType> || [], [query]);
  const urlIllegalStatuses = useMemo(() => query.getAll("illegal_statuses[]") as Array<IllegalStatus> || [], [query]);
  const activityLogFilteringCondition = useMemo(() => ({
    from: urlFrom,
    to: urlTo,
    store_ids: urlStoreIds,
    action_types: urlActionTypes,
    content_id: urlContentId,
    status: urlStatus,
    illegal_statuses: urlIllegalStatuses,
  }), [urlFrom, urlTo, urlStoreIds, urlActionTypes, urlContentId, urlStatus, urlIllegalStatuses]);

  const activityLogEntityContainer = ActivityLogEntityContainer.useContainer();
  const { actionTypesToJp } = activityLogEntityContainer.logic;

  const [selectedActivityLog, setSelectedActivityLog] = useState<ActivityLogResource>();
  const [isIllegalStatusModalOpen, openIllegalStatusModal, closeIllegalStatusModal] = useBooleanState(false);
  const [isFiteringModalOpen, openFiteringModal, closeFiteringModal] = useBooleanState(false);

  const { response: contentsResponse } = useGetApiCall<ActivityLogContentResponse>("/activity_logs/contents");
  const { api: activityUpdateApi } = useApi();
  const { api: activityDeleteApi } = useApi();
  const { api: activityCsvApi } = useApi();
  const { api: activityApi, response: acitivitiesResponse, totalPages, currentPage } = useApi<UserActivitiesResponse>();
  const activityLogs = acitivitiesResponse?.activity_logs || [];

  const replaceUrl = useCallback((queryParams: QueryParams) => {
    const { page, from, to, content_id, status, store_ids, action_types, illegal_statuses } = queryParams;

    let newUrl = `${pathname}?user_id=${userId}`;

    if (page) {
      newUrl += `&page=${page}`;
    }

    if (from && to) {
      newUrl += `&from=${from}&to=${to}`;
    }

    if (content_id) {
      newUrl += `&content_id=${content_id}`;
    }

    if (status?.length) {
      status.forEach((i) => { newUrl += `&status[]=${i}` });
    }

    if (store_ids?.length) {
      store_ids.forEach((id) => { newUrl += `&store_ids[]=${id}` });
    }

    if (action_types?.length) {
      action_types.forEach((action_type) => { newUrl += `&action_types[]=${action_type}` });
    }

    if (illegal_statuses?.length) {
      illegal_statuses.forEach((i) => { newUrl += `&illegal_statuses[]=${i}` });
    }

    history.replace(newUrl);
  }, [history, pathname, userId]);

  const getActivityLogs = useCallback(() => {
    activityApi.get(`/users/${userId}/activities`, {
      ...activityLogFilteringCondition,
      page: urlPage
    });
  }, [activityApi, userId, activityLogFilteringCondition, urlPage]);

  useEffect(() => {
    getActivityLogs();
  }, [getActivityLogs]);

  const updateActivityLog = async ({
    id,
    illegalStatus,
    doubtfulReasonText,
  }: UpdateActivityLogProps): Promise<void> => {
    const res = await activityUpdateApi.patch(`/activity_logs/${id}`, {
      activity_log: {
        illegal_status: illegalStatus,
        doubtful_reason_text: doubtfulReasonText,
      },
    });

    if (!res) return;

    getActivityLogs();

    closeIllegalStatusModal();
    showSuccessNotification("ステータスを更新しました。");
  };

  const deleteActivityLog = async (id: number, doCancelDependentLogs = false): Promise<void> => {
    if (
      !window.confirm(
        doCancelDependentLogs ? "チェックイン処理を取り消しますか？" : "このログを取り消しますか？"
      )
    ) {
      return;
    }

    const res = await activityDeleteApi.delete(`/activity_logs/${id}`, {
      do_cancel_dependent_logs: doCancelDependentLogs,
    });

    if (!res) return;

    getActivityLogs();
    onDeleteActivityLog();

    showSuccessNotification("取り消しました。");
  };

  const downloadActivityLogs = async () => {
    const response = await activityCsvApi.blob(`/users/${userId}/activities.csv`, {
      ...activityLogFilteringCondition,
      page: urlPage
    })
    if (!response) return;

    downloadCSVFile(response);
  }

  return (
    <div {...otherProps}>
      <SectionTitle
        style={{ marginBottom: 12 }}
        title={<b>利用履歴</b>}
        element={
          <StyledButtonsContainer>
            <Button onClick={openFiteringModal}>
              <b>絞り込み設定</b>
            </Button>
            <LoadingButton
              label="CSV出力"
              loadingLabel="ダウンロード中..."
              color="info"
              onClick={downloadActivityLogs}
            />
          </StyledButtonsContainer>
        }
      />

      {activityLogs.length === 0 ? (
        <NoItemContent
          icon={<Activity size={32} color="#596069" weight="bold" />}
          label="利用履歴がありません"
        />
      ) : (
        <div>
          {contentsResponse && (
            <Box mb={1.5}>
              <ActivityLogFilteringConditionElements
                store={currentStore}
                condition={{
                  from: activityLogFilteringCondition.from,
                  to: activityLogFilteringCondition.to,
                  store_ids: activityLogFilteringCondition.store_ids,
                  action_types: activityLogFilteringCondition.action_types,
                  content_id: activityLogFilteringCondition.content_id,
                  statuses: activityLogFilteringCondition.status,
                  illegal_statuses: activityLogFilteringCondition.illegal_statuses
                }}
                contents={contentsResponse}
              />
            </Box>
          )}

          {activityLogs.map(({ activity_log, activity_log_content, store }, index) => (
            <ActivitiyLogItem
              key={activity_log.id}
              activityLogs={activityLogs}
              activityLog={activity_log}
              activityLogContent={activity_log_content}
              store={store}
              index={index}
              actionTypesToJp={actionTypesToJp}
              onClickChangeIllegalStatus={(activityLog) => {
                setSelectedActivityLog(activityLog);
                openIllegalStatusModal();
              }}
              onClickDeleteActivityLog={(id, isCheckIn) => deleteActivityLog(id, isCheckIn)}
            />
          ))}

          {totalPages > 1 ? (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, page) => {
                replaceUrl({ ...activityLogFilteringCondition, page });
              }}
              sx={{ fontSize: 24, color: "black" }}
            />
          ) : null}
        </div>
      )}

      {selectedActivityLog && (
        <ActivityLogIllegalStatusChangeModal
          show={isIllegalStatusModalOpen}
          onHide={closeIllegalStatusModal}
          title={<div>どの不正ステータスに更新しますか？</div>}
          buttonText="更新する"
          defaultIllegalStatus={selectedActivityLog.illegal_status}
          defaultDoubtfulReasonText={selectedActivityLog.doubtful_reason_text}
          selectedActivityLogId={selectedActivityLog.id}
          onUpdateIllegalStatus={updateActivityLog}
        />
      )}

      {contentsResponse && (
        <ActivityLogFilteringModal
          open={isFiteringModalOpen}
          onClose={closeFiteringModal}
          contents={contentsResponse}
          defaultCondition={{
            from: activityLogFilteringCondition.from,
            to: activityLogFilteringCondition.to,
            store_ids: activityLogFilteringCondition.store_ids,
            action_types: activityLogFilteringCondition.action_types,
            content_id: activityLogFilteringCondition.content_id,
            statuses: activityLogFilteringCondition.status,
            illegal_statuses: activityLogFilteringCondition.illegal_statuses
          }}
          onSubmit={(condition) => replaceUrl({
            from: condition.from,
            to: condition.to,
            store_ids: condition.store_ids,
            action_types: condition.action_types,
            content_id: condition.content_id,
            status: condition.statuses,
            illegal_statuses: condition.illegal_statuses
          })}
        />
      )}
    </div>
  );
};

type ActivitiyLogItemProps = {
  activityLogs: UserActivitiesResponse["activity_logs"];
  activityLog: ActivityLogResource;
  activityLogContent: ActivityLogContentResource | null;
  store: StoreResource;
  index: number;
  actionTypesToJp: (
    actionType: ActionType,
    value?: number | undefined,
    amount?: number | undefined
  ) => string;
  onClickChangeIllegalStatus: (activityLog: ActivityLogResource) => void;
  onClickDeleteActivityLog: (activityLogId: number, isCheckIn: boolean) => void;
};

const ActivitiyLogItem = ({
  activityLogs,
  activityLog,
  activityLogContent,
  store,
  index,
  actionTypesToJp,
  onClickChangeIllegalStatus,
  onClickDeleteActivityLog,
}: ActivitiyLogItemProps): JSX.Element => {
  const ref = useRef<HTMLHeadingElement>(null);
  const isFristLogOfMonth = (): boolean => {
    if (index === 0) return true;

    if (
      index !== 0 &&
      moment(activityLog.created_at).month() !==
        moment(activityLogs[index - 1].activity_log.created_at).month()
    ) {
      return true;
    }
    return false;
  };

  const isLastLogOfMonth = (): boolean => {
    if (!activityLogs[index + 1]) return true;

    if (
      moment(activityLog.created_at).month() !==
      moment(activityLogs[index + 1]?.activity_log?.created_at).month()
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {isFristLogOfMonth() && (
        <StyledMonthLabel>
          {formatDateExceptTimeToJp(activityLog.created_at, "month")}
        </StyledMonthLabel>
      )}

      <StyledLogItemContainer
        ref={ref}
        key={activityLog.id}
        illegalStatus={activityLog.illegal_status}
      >
        <ActionIcon
          actionType={activityLog.action_type}
          itemHeight={ref.current?.clientHeight || 0}
          isFristLogOfMonth={isFristLogOfMonth()}
          isLastLogOfMonth={isLastLogOfMonth()}
          isCheckinWithinSixHours={activityLog.is_visit}
        />

        <StyledLogItemContentContainer>
          {(activityLog.illegal_status === "illegal" ||
            activityLog.illegal_status === "doubtful") && (
            <StyledIllegalStatus illegalStatus={activityLog.illegal_status}>
            <i className="fas fa-exclamation-triangle" style={{ marginRight: 4 }} />
              {activityLog.doubtful_reason_text
                ? `${IllegalStatusLabel[activityLog.illegal_status]}: ${
                    activityLog.doubtful_reason_text
                  }`
                : IllegalStatusLabel[activityLog.illegal_status]}
            </StyledIllegalStatus>
          )}

          <StyledCreatedAtContainer>
            <div>{moment(activityLog.created_at).format("M/D(dddd) HH:mm")}</div>
            <StyledActionButtonContainer>
              <StyledStoreName>{store?.name}</StyledStoreName>
              <OptionButton
                options={[
                  {
                    label: "不正ステータスを変更する",
                    onClick: () => onClickChangeIllegalStatus(activityLog),
                  },
                  {
                    label: "このログを取り消す",
                    labelColor: "#FF4A55",
                    onClick: () => onClickDeleteActivityLog(activityLog.id, false),
                  },
                  activityLog.with_check_in && {
                    label: "チェックイン処理を取り消す",
                    labelColor: "#FF4A55",
                    onClick: () => onClickDeleteActivityLog(activityLog.id, true),
                  },
                ].filter((v) => v)}
              />
            </StyledActionButtonContainer>
          </StyledCreatedAtContainer>

          <StyledActionType>
            {actionTypesToJp(activityLog.action_type, activityLog.value, activityLog.amount) || ""}
          </StyledActionType>

          <StyledLogContentTitle>{activityLogContent?.title}</StyledLogContentTitle>
        </StyledLogItemContentContainer>
      </StyledLogItemContainer>
    </>
  );
};

type ActionIconProps = {
  actionType: ActionType;
  itemHeight: number;
  isFristLogOfMonth: boolean;
  isLastLogOfMonth: boolean;
  isCheckinWithinSixHours: boolean;
};

const ICON_BORDER_SIZE = 2;

const ActionIcon = ({
  actionType,
  itemHeight,
  isFristLogOfMonth,
  isLastLogOfMonth,
  isCheckinWithinSixHours,
}: ActionIconProps): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();

  const ICON_PADDING = isPcOrTablet ? 10 : 8;
  const ICON_SIZE = isPcOrTablet ? 24 : 20;
  const ICON_WIDTH = ICON_SIZE + ICON_PADDING * 2 + ICON_BORDER_SIZE * 2;
  const ICON_BOTTOM_LINE_BOTTOM = isLastLogOfMonth
    ? -(itemHeight - ICON_SIZE - ICON_PADDING * 2 - ICON_BORDER_SIZE * 2 - 8 - 8)
    : -(
        itemHeight -
        (isCheckinWithinSixHours ? ICON_SIZE : 0) -
        ICON_PADDING * 2 -
        ICON_BORDER_SIZE * 2 -
        8)
  const ICON_BOTTOM_LINE_HEIGHT = isLastLogOfMonth
    ? itemHeight - ICON_SIZE - ICON_PADDING * 2 - ICON_BORDER_SIZE * 2 - 8 - 8
    : itemHeight -
      (isCheckinWithinSixHours ? ICON_SIZE : 0) -
      ICON_PADDING * 2 -
      ICON_BORDER_SIZE * 2 -
      8;

  const Icon = (): JSX.Element => {
    switch (actionType) {
      // 登録
      case "connect_store":
      case "reconnect_store":
        return (
          <StyledActionIconContainer padding={ICON_PADDING} backgroundColor="#67C3BC">
            <DeviceMobile size={ICON_SIZE} weight="bold" color="#C8E9E7" />
          </StyledActionIconContainer>
        );
      // 受け取り
      case "get_coupon":
      case "get_notice":
        return (
          <StyledActionIconContainer padding={ICON_PADDING} backgroundColor="#91A2B7">
            <RssSimple size={ICON_SIZE} weight="bold" color="#E9ECF1" />
          </StyledActionIconContainer>
        );
      // 開封
      case "open_coupon":
      case "open_notice":
        return (
          <StyledActionIconContainer padding={ICON_PADDING} backgroundColor="#F0AA74">
            <Eye size={ICON_SIZE} weight="bold" color="#FCEEE3" />
          </StyledActionIconContainer>
        );
      // 購入
      case "get_ticket":
      case "get_subscription":
      case "update_subscription":
        return (
          <StyledActionIconContainer padding={ICON_PADDING} backgroundColor="#9747FF">
            <CurrencyCircleDollar size={ICON_SIZE} weight="bold" color="#E6E0FC" />
          </StyledActionIconContainer>
        );
      // 来店
      case "get_point":
      case "get_payment_point":
      case "get_check_in_point":
      case "use_service":
      case "get_stamp":
      case "get_payment_stamp":
      case "get_check_in_stamp":
      case "use_coupon":
      case "use_ticket":
      case "use_subscription":
      case "get_order":
      case "check_in":
        return (
          <StyledActionIconContainer padding={ICON_PADDING} backgroundColor="#ED6CC0">
            {isCheckinWithinSixHours && (
              <Storefront size={ICON_SIZE} weight="bold" color="#FBE2F2" />
            )}
          </StyledActionIconContainer>
        );
      // その他
      default:
        return (
          <StyledActionIconContainer padding={ICON_PADDING} backgroundColor="#EBEEF2">
            <Activity size={ICON_SIZE} weight="bold" color="#596069" />
          </StyledActionIconContainer>
        );
    }
  };
  return (
    <StyledActionIconComponentContainer width={ICON_WIDTH}>
      {!isFristLogOfMonth && <StyledTopLine />}

      {Icon()}

      {itemHeight ? (
        <StyledBottomLine bottom={ICON_BOTTOM_LINE_BOTTOM} height={ICON_BOTTOM_LINE_HEIGHT} />
      ): null}
    </StyledActionIconComponentContainer>
  );
};

const StyledButtonsContainer = styled("div")({
  display: "flex",
  gap: 10
});
const StyledMonthLabel = styled("div")({
  color: "#9A9A9A",
  fontWeight: "bold",
  fontSize: 16,
});
const StyledLogItemContainer = styled("div")<{ illegalStatus: IllegalStatus }>(({ illegalStatus }) => ({
  display: "flex",
  alignItems: "start",
  gap: 16,
  padding: 8,
  backgroundColor: illegalStatus === "illegal" ? "#FEF7F7" :
    illegalStatus ===  "doubtful" ? "#FEF9F1" :
    "white"
}));
const StyledIllegalStatus = styled("div")<{ illegalStatus: IllegalStatus }>(({ illegalStatus }) => ({
  marginBlock: 4,
  fontWeight: "bold",
  color: illegalStatus === "illegal" ? "#ff6d64" :
    illegalStatus ===  "doubtful" ? "#ffb764" :
    "white",
}));
const StyledLogItemContentContainer = styled("div")({
  flex: 1,
});
const StyledStoreName = styled("div")({
  color: "#9A9A9A",
  marginRight: 12,
  wordBreak: "break-all",
});
const StyledActionType = styled("div")({
  fontWeight: "bold",
});
const StyledLogContentTitle = styled("div")({
  fontSize: 13,
  color: "#9A9A9A",
  marginTop: 4,
});
const StyledCreatedAtContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const StyledActionButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center"
});
const StyledActionIconComponentContainer = styled("div")<{ width: number }>(({ width }) => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: width,
  alignItems: "center",
  position: "relative"
}));
const StyledActionIconContainer = styled("div")<{ padding: number, backgroundColor: string }>(({ padding, backgroundColor }) => ({
  padding: padding,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `${ICON_BORDER_SIZE}px solid #fff`,
  backgroundColor: backgroundColor,
}));
const StyledTopLine = styled("div")({
  position: "absolute",
  right: "50%",
  left: "50%",
  top: -8,
  height: 8,
  width: 2,
  background: "#E5E5E5",
});
const StyledBottomLine = styled("div")<{ bottom: number, height: number }>(({ bottom, height }) => ({
  position: "absolute",
  right: "50%",
  left: "50%",
  width: 2,
  background: "#E5E5E5",
  height: height,
  bottom: bottom,
}));

export default ActivityLog;
