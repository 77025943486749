import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const getEcFeature = async () => {
  const GET_EC_FEATURE = `/staff/stores/${loadViewObj()?.id}/ec_features`;

  const response = await apiClient({
    method: "GET",
    uri: GET_EC_FEATURE,
  });
  return response.data;
};

export const updateEcFeature = async ({ params }) => {
  const UPDATE_EC_FEATURE = `/staff/stores/${loadViewObj()?.id}/ec_features`;

  const requestParams = {
    ec_feature: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_EC_FEATURE,
    params: requestParams,
  });
  return response.data;
};
