import React, { useState } from "react";
import styled from "@emotion/styled";
import Card from "../../../components/Card/Card";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { StampCardStatus } from "../../../containers/entities/StampCardContentEntityContainer";
import { FormChangedAlertModal, Alert } from "../../../components/Modal/FormChangedAlertModal";
import { usePcSizeFlag, useBooleanState } from "../../../lib/hooks";

const modalTitle = (status: StampCardStatus) => {
  switch (status) {
    case "close": return "スタンプカードの公開を停止";
    case "open": return "スタンプカードを公開";
    default: return "";
  }
};

const modalSubmitButtonLabel = (status: StampCardStatus) => {
  switch (status) {
    case "close": return "公開を停止する";
    case "open": return "公開する";
    default: return "";
  }
};

const modalBody = (status: StampCardStatus) => {
  switch (status) {
    case "close": return "スタンプカードの公開を停止してよろしいですか？";
    case "open": return "スタンプカードを公開してよろしいですか？";
    default: return "";
  }
};

type Props = {
  status: StampCardStatus;
  disabled: boolean;
  onChangeStatus: (status: StampCardStatus) => void;
};

const StampCardPublishSettingCard = ({
  status,
  disabled,
  onChangeStatus
}: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const [newStatus, setNewStatus] = useState<StampCardStatus>("close");
  const openModalFor = (s: StampCardStatus) => {
    setNewStatus(s);
    openModal();
  };

  const handleSubmit = () => {
    onChangeStatus(newStatus);
    closeModal();
  };

  return (
    <>
      <Card
        title="公開設定"
        content={
          <StyledCard isPcOrTablet={isPcOrTablet}>
            {status === "open" && (
              <CustomButton
                bsStyle="danger"
                onClick={() => openModalFor("close")}
                disabled={disabled}
                style={{ fontWeight: 700 }}
              >
                スタンプカードの公開を停止
              </CustomButton>
            )}

            {["draft", "preview", "close"].includes(status) && (
              <CustomButton
                bsStyle="info"
                fill
                onClick={() => openModalFor("open")}
                disabled={disabled}
                style={{ fontWeight: 700 }}
              >
                スタンプカードを公開
              </CustomButton>
            )}
          </StyledCard>
        }
      />

      <FormChangedAlertModal
        show={isModalOpen}
        title={modalTitle(newStatus)}
        submitButtonLabel={modalSubmitButtonLabel(newStatus)}
        onSubmit={handleSubmit}
        onCancel={closeModal}
        needAgreement={newStatus === "close"}
        displayStatus={newStatus === "close" ? "danger" : "info"}
      >
        {modalBody(newStatus)}

        {newStatus === "close" && (
          <Alert severity="danger">
            公開を停止すると、ユーザーがスタンプカードを利用できなくなります
          </Alert>
        )}
      </FormChangedAlertModal>
    </>
  );
};

const StyledCard = styled("div")<{ isPcOrTablet: boolean }>(({ isPcOrTablet }) => ({
  display: "flex",
  flexDirection: isPcOrTablet ? "row" : "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: 8
}));

export default StampCardPublishSettingCard;
