import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const createPresentCouponContent = async ({ params }) => {
  const CREATE_PRESENT_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/present_coupon_contents`;

  const requestParams = {
    present_coupon_content: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_PRESENT_COUPON_CONTENT,
    params: requestParams,
  });
  return response;
};

export const getPresentCouponContent = async (id) => {
  const GET_PRESENT_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/present_coupon_contents/${id}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_PRESENT_COUPON_CONTENT,
  });
  return response;
};

export const updatePresentCoupon = async ({ coupon_id, params }) => {
  const UPDATE_PRESENT_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/present_coupon_contents/${coupon_id}`;

  const requestParams = {
    present_coupon_content: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_PRESENT_COUPON_CONTENT,
    params: requestParams,
  });
  return response;
};

export const publishCoupon = async ({ coupon_id, params }) => {
  const PUBLISH_COUPON = `/staff/stores/${
    loadViewObj()?.id
  }/present_coupon_contents/${coupon_id}/publish`;

  const requestParams = {
    present_coupon_content: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: PUBLISH_COUPON,
    params: requestParams,
  });
  return response;
};
