import React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { MenuResource } from "../../../types/menu";
import MenuStatusChip from "../../../components/Chip/MenuStatusChip";
import { useLoginContext } from "../../../providers/LoginContextProvider";

type Props = {
  menus: MenuResource[];
  disabled: boolean;
  onClickMenu: (menu: MenuResource) => void;
  onClickMore: (e: React.MouseEvent<HTMLButtonElement>, menu: MenuResource) => void;
  onClickStatus: (menu: MenuResource) => void;
};

const LaptopMenuList = ({
  menus,
  disabled,
  onClickMenu,
  onClickMore,
  onClickStatus
}: Props): JSX.Element => {
  const { currentStaff } = useLoginContext();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell/>
          {currentStaff.is_toypo_member && // CSV 機能を利用するためにわかりやすいようにしているだけなので、通常ユーザーには不要
            <TableCell>ID</TableCell>
          }
          <TableCell>タイトル</TableCell>
          <TableCell>カテゴリ</TableCell>
          <TableCell>値段</TableCell>
          <TableCell>状態</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
      <TableBody>
        {menus.map((menu) => (
          <StyledTableRow
            key={menu.id}
            hover={!disabled}
            onClick={() => {
              if (!disabled) {
                onClickMenu(menu);
              }
            }}
          >
            <TableCell width={48}>
              {menu.image_url && <StyledMenuImage alt="メニュー画像" src={menu.image_url} />}
            </TableCell>
            {currentStaff.is_toypo_member && // CSV 機能を利用するためにわかりやすいようにしているだけなので、通常ユーザーには不要
              <TableCell>
                {menu.id}
              </TableCell>
            }
            <TableCell>
              {menu.title}
            </TableCell>
            <TableCell>
              {menu.category_label}
            </TableCell>
            <TableCell>
              {menu.price || 0}円
            </TableCell>
            <TableCell>
              <MenuStatusChip
                status={menu.status}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickStatus(menu);
                }}
              />
            </TableCell>

            <TableCell>
              <IconButton
                sx={{ lineHeight: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickMore(e, menu);
                }}
              >
                <i className="ri-more-fill" />
              </IconButton>
            </TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  "& > td": {
    fontWeight: 700
  }
});

const IMAGE_WIDTH = 48;
const IMAGE_HEIGHT = 30;
const StyledMenuImage = styled("img")({
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
  borderRadius: 2,
  objectFit: "cover"
});

export default LaptopMenuList;
