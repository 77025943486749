import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

type Props = {
  icon: JSX.Element;
  label: string;
  iconSize?: number;
};

const NoItemContent = ({ icon, iconSize = 32, label }: Props): JSX.Element => (
  <StyledContainer>
    <Box color="#596069" fontSize={iconSize}>{icon}</Box>
    <Box color="#596069">{label}</Box>
  </StyledContainer>
);

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(4, 0),
}));

export default NoItemContent;
