import React, { useEffect, useCallback } from "react";
import moment from "moment";
import { Pagination } from "@mui/material";
import { Table } from "react-bootstrap";
import { BookBookmark } from "phosphor-react";
import { TicketsContentResponse } from "../../../types/api/ticket.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import { TicketStatusColors, TicketStatusLabels } from "../../../constants/ticket";
import SectionTitle from "./SectionTitle";
import NoItemContent from "../../../components/Utils/NoItemContent";

type Props = {
  userId: number;
};

const Ticket = ({ userId }: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();

  const { api: ticketsGetApi, response: ticketsResponse, headers: ticketsResHeaders } = useApi();
  const tickets: Array<TicketsContentResponse> = ticketsResponse?.tickets || [];

  const getTickets = useCallback((page: number = 1) => {
    ticketsGetApi.get(`/users/${userId}/tickets`, {
      page,
      limit: 5
    })
  }, [ticketsGetApi, userId]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  if (tickets.length === 0) {
    return (
      <div>
        <SectionTitle style={{ marginBottom: 12 }} title={<b>購入済みのチケット</b>} />

        <NoItemContent label="購入済みのチケットはありません" icon={<BookBookmark />} />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle style={{ marginBottom: 12 }} title={<b>購入済みのチケット</b>} />

      {isPcOrTablet ? (
        <Table striped hover>
          <thead>
            <tr>
              {["タイトル", "利用状況", "配信日時"].map((prop) => (
                <th key={prop}>{prop}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tickets.map(({ ticket, ticket_content, store }) => (
              <tr key={ticket.id}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {ticket_content.image_url && (
                      <img
                        key={ticket_content.id}
                        alt="チケット画像"
                        src={ticket_content.image_url}
                        width={48}
                        height={30}
                        style={{
                          borderRadius: 2,
                          objectFit: "cover",
                          marginRight: 8,
                        }}
                      />
                    )}

                    <div>
                      <div style={{ fontWeight: 700 }}>{ticket_content.title}</div>
                      <div style={{ color: "#9A9A9A", fontSize: 12 }}>{store.name}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ color: TicketStatusColors[ticket.status], fontWeight: 700 }}>
                    {TicketStatusLabels[ticket.status]}
                  </div>
                </td>
                <td>
                  {ticket.created_at
                    ? moment(ticket.created_at).format("YYYY/M/D(dddd) HH:mm")
                    : "----"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table striped hover>
          <tbody>
            {tickets.map(({ ticket, ticket_content, store }) => (
              <tr key={ticket.id}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      gap: 16,
                      alignItems: "flex-start",
                    }}
                  >
                    {ticket_content.image_url && (
                      <img
                        key={ticket_content.id}
                        alt="チケット画像"
                        src={ticket_content.image_url}
                        width={48}
                        height={30}
                        style={{
                          borderRadius: 2,
                          objectFit: "cover",
                          marginRight: 8,
                        }}
                      />
                    )}

                    <div>
                      <div style={{ marginBottom: 4 }}>
                        <div style={{ fontWeight: 700 }}>{ticket_content.title}</div>
                        <div
                          style={{
                            fontWeight: "normal",
                            color: "#9a9a9a",
                            fontSize: 12,
                          }}
                        >
                          {store.name}
                        </div>
                      </div>
                      <div style={{ color: "#7d7d7d" }}>
                        利用状況{" "}
                        <span style={{ color: TicketStatusColors[ticket.status], fontWeight: 700 }}>
                          {TicketStatusLabels[ticket.status]}
                        </span>
                      </div>
                      <div style={{ color: "#7d7d7d" }}>
                        配信日時{" "}
                        {ticket.created_at
                          ? moment(ticket.created_at).format("YYYY/M/D(dddd) HH:mm")
                          : "----"}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {Number(ticketsResHeaders["total-pages"]) > 1 && (
        <Pagination
          count={Number(ticketsResHeaders["total-pages"]) || 1}
          page={Number(ticketsResHeaders["current-page"]) || 1}
          onChange={(_, page) => getTickets(page)}
          style={{ paddingBottom: 8, borderBottom: "1px solid #E8E8E8" }}
          sx={{ fontSize: 24, color: "black" }}
        />
      )}
    </div>
  );
};

export default Ticket;
