import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "../../../components/DialogTitle";
import FormLabel from "../../../components/FormInputs/FormLabel";

type Props = {
  defaultShippingLink: string;
  open: boolean;
  disabled: boolean;
  onSubmit: (orderNumber: string) => void;
  onClose: () => void;
};

const ShippingLinkModal = ({
  defaultShippingLink,
  open,
  disabled,
  onSubmit,
  onClose
}: Props): JSX.Element => {
  const [shippingLink, setShippingLink] = useState<string>(defaultShippingLink);

  useEffect(() => {
    if (open) {
      setShippingLink(defaultShippingLink);
    }
  }, [open, defaultShippingLink]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle onClose={onClose}>
        配達状況リンクの変更
      </DialogTitle>

      <DialogContent>
        <FormLabel label="配達状況リンク" />

        <OutlinedInput
          fullWidth
          value={shippingLink}
          disabled={disabled}
          onChange={({ target }) => setShippingLink(target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          onClick={() => {
            if (defaultShippingLink === shippingLink) {
              onClose();
            } else {
              onSubmit(shippingLink);
            }
          }}
        >
          変更する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShippingLinkModal;
