import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { OrderBlock, OrderResource } from "../../../types/order";
import OrderStatusChip from "../../../components/Chip/OrderStatusChip";
import OrderScheduledDateTimeLabel from "./OrderScheduledDateTimeLabel";

type Props = {
  orders: OrderBlock[];
  onClickOrder: (order: OrderResource) => void;
  onClickMenu: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, order: OrderResource) => void;
  onClickStatusLabel: (order: OrderResource) => void;
};

const LaptopEcOrderListTable = ({
  orders,
  onClickOrder,
  onClickMenu,
  onClickStatusLabel,
}: Props): JSX.Element => {
  return (
    <Table>
      <TableHead>
        <StyledHeaderRow>
          <TableCell width={80}>状態</TableCell>
          <TableCell width={120}>注文番号</TableCell>
          <TableCell>注文内容</TableCell>
          <TableCell>備考</TableCell>
          <TableCell width={150}>注文日時</TableCell>
          <TableCell>店舗</TableCell>
          <TableCell width={70}></TableCell>
        </StyledHeaderRow>
      </TableHead>
      <TableBody>
        {orders.map(({ order, store, menus }) => (
          <StyledBodyRow key={order.id} hover>
            <TableCell>
              <OrderStatusChip
                orderType={order.order_type}
                status={order.status}
                onClick={() => onClickStatusLabel(order)}
              />
            </TableCell>
            <TableCell onClick={() => onClickOrder(order)}>
              <Box fontSize={18} fontWeight={700}>{order.order_number}</Box>
            </TableCell>
            <TableCell onClick={() => onClickOrder(order)}>
              <Box display="flex" flexDirection="column" gap={0.5}>
                {menus.map((menu) => (
                  <div key={menu.order_menu_id}>
                    <Box>{menu.title} x {menu.quantity}</Box>
                    <Box display="flex" flexWrap="wrap">
                      {menu.option_items.map((optionItem) => (
                        <StyledOptionItem key={optionItem.id}>
                          {optionItem.title}
                        </StyledOptionItem>
                      ))}
                    </Box>
                  </div>
                ))}
              </Box>
            </TableCell>
            <TableCell onClick={() => onClickOrder(order)}>
              {order.description || "なし"}
            </TableCell>
            <TableCell onClick={() => onClickOrder(order)}>
              <OrderScheduledDateTimeLabel value={order.created_at} />
            </TableCell>
            <TableCell onClick={() => onClickOrder(order)}>
              {store.name}
            </TableCell>
            <TableCell>
              <IconButton
                sx={{ lineHeight: 1 }}
                onClick={(e) => onClickMenu(e, order)}
              >
                <i className="ri-more-fill" />
              </IconButton>
            </TableCell>
          </StyledBodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

const StyledHeaderRow = styled(TableRow)({
  "& > th": {
    whiteSpace: "nowrap"
  }
});

const StyledBodyRow = styled(TableRow)({
  cursor: "pointer",
  "& > td": {
    fontSize: 13,
    fontWeight: 700
  }
});

const StyledOptionItem = styled("div")(({ theme }) => ({
  color: "#525252",
  marginRight: 4,
  padding: theme.spacing(0.5),
  fontSize: 11,
  backgroundColor: "#e3e3e3",
  borderRadius: 4
}));

export default LaptopEcOrderListTable;
