import React from "react";

type Props = {
  name: string;
  onClick: () => void;
};

const OpenStatusMessage: React.FC<Props> = ({ name, onClick }) => (
  <div
    style={{
      border: "2px solid #F5BF74",
      backgroundColor: "#FDF2E3",
      borderRadius: 8,
      padding: 12,
    }}
  >
    <div>{name}は現在アプリ非公開になっています。</div>
    <div>
      設定を変更するには、
      <span style={{ color: "#0076D7", cursor: "pointer" }} onClick={onClick}>
        アプリ公開設定
      </span>
      へ移動して公開してください。
    </div>
  </div>
);

export default OpenStatusMessage;
