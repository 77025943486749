import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { styled } from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { useNotification } from "../../../providers/NotificationProvider";
import { useApi } from "../../../lib/hooks";
import { ToshibaTecCheckInResponse } from "../../../types/toshibaTec";
import ToshibaTecPosCheckInForm from "./ToshibaTecPosCheckInForm";
import ManualCheckInForm from "./ManualCheckInForm";

type Props = {
  show: boolean;
  onHide: () => void;
  onCheckedInByManual: () => void;
  onCheckedInByPos: (userComplement: ToshibaTecCheckInResponse) => void;
  userId: number;
  membersCardPointEnabled: boolean;
  isNeedAmount: boolean;
};

type ManualCheckInFormType = {
  checkInAt: Date | null;
  couponIds: number[];
  serviceIds: number[];
  ticketIds: number[];
  subscriptionIds: number[];
  stampCardId: number;
  stamp: number | null;
  point: number | null;
  amount: number | null;
};
type ToshibaTecCheckInFormType = {
  receiptNo: number | null;
  settlementDate: string | null;
  checkInStoreId: number;
};

export type CheckInFormType = {
  checkInByManual: ManualCheckInFormType;
  checkInByPos: ToshibaTecCheckInFormType;
};

type CheckInType = "checkInByManual" | "checkInByPos";

const CheckInModal = ({
  show,
  onHide,
  onCheckedInByManual,
  onCheckedInByPos,
  userId,
  membersCardPointEnabled,
  isNeedAmount
}: Props) => {
  const { showSuccessNotification } = useNotification();
  const { currentStore } = useLoginContext();
  const { api: checkInCreateApi } = useApi();
  const { api: toshibaTecCheckInCreateApi } = useApi<ToshibaTecCheckInResponse>();
  const methods = useForm<CheckInFormType>({
    defaultValues: {
      checkInByManual: {
        checkInAt: null,
        couponIds: [],
        serviceIds: [],
        ticketIds: [],
        subscriptionIds: [],
        stampCardId: 0,
        stamp: null,
        point: null,
        amount: null,
      },
      checkInByPos: {
        receiptNo: null,
        settlementDate: null,
        checkInStoreId: currentStore.id
      }
    }
  });
  const { handleSubmit } = methods;

  const [selectedChekInType, setSelectedChekInType] = useState<CheckInType>("checkInByManual");

  const checkInByManual = async (maualCheckInValues: ManualCheckInFormType) => {
    if (!window.confirm("チェックイン処理を行いますか？")) return;

    const response = await checkInCreateApi.post("/ikkatu", {
      user_id: userId,
      check_in_at: maualCheckInValues.checkInAt,
      coupon_ids: maualCheckInValues.couponIds,
      service_ids: maualCheckInValues.serviceIds,
      ticket_ids: maualCheckInValues.ticketIds,
      subscription_ids: maualCheckInValues.subscriptionIds,
      stamp_card_id: maualCheckInValues.stampCardId,
      stamp: maualCheckInValues.stamp,
      point: maualCheckInValues.point,
      amount: maualCheckInValues.amount,
    });

    if (!response) return;

    showSuccessNotification("チェックイン処理を行いました。");
    onCheckedInByManual();
  };

  const checkInByPos = async (posCheckInValues: ToshibaTecCheckInFormType) => {
    if (!window.confirm("チェックイン処理を行いますか？")) return;

    const response = await toshibaTecCheckInCreateApi.post("/toshiba_tec/check_in", {
      check_in_store_id: posCheckInValues.checkInStoreId,
      user_id: userId,
      target_date: posCheckInValues.settlementDate,
      receipt_no: posCheckInValues.receiptNo
    });

    if (!response) return;

    if (response.data.toshiba_tec_pos_transaction_user_complement.status === "processed") {
      showSuccessNotification("チェックイン処理を行いました。");
    }
    onCheckedInByPos(response.data);
  };

  const checkIn = (formValues: CheckInFormType) => {
    if (selectedChekInType === "checkInByManual") {
      checkInByManual(formValues.checkInByManual);
    } else {
      checkInByPos(formValues.checkInByPos);
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog open={show} onClose={onHide} maxWidth="md" fullWidth>
        <DialogTitle>チェックイン</DialogTitle>
        <DialogContent>
          <div>
            {currentStore.pos_type === "toshiba_tec" && (
              <StyledSectionContainer>
                <StyledSectionTitleContainer>
                  <StyledTitleContainer>
                    <StyledContentTitle>チェックイン作成方法</StyledContentTitle>
                  </StyledTitleContainer>
                </StyledSectionTitleContainer>

                <RadioGroup
                  value={selectedChekInType}
                  onChange={(e) => setSelectedChekInType(e.target.value as CheckInType)}
                >
                  <FormControlLabel value="checkInByManual" control={<Radio />} label="通常のチェックイン" />
                  <FormControlLabel value="checkInByPos" control={<Radio />} label="レシートをもとにチェックイン(東芝テックPOS連携)" />
                </RadioGroup>
              </StyledSectionContainer>
            )}

            {selectedChekInType === "checkInByManual" ? (
              <ManualCheckInForm
                userId={userId}
                isNeedAmount={isNeedAmount}
                membersCardPointEnabled={membersCardPointEnabled}
              />
            ) : (
              <ToshibaTecPosCheckInForm />
            )}
          </div>
        </DialogContent>

        <DialogActions sx={{ pt: 2 }}>
          <Button fullWidth variant="outlined" color="cancel" onClick={onHide}>
            キャンセル
          </Button>
          <Button fullWidth variant="contained" color="submit" onClick={handleSubmit(checkIn)}>
            チェックインする
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

const StyledSectionContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
const StyledSectionTitleContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));
const StyledTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));
const StyledContentTitle = styled("div")({
  fontSize: 16,
  fontWeight: "bold",
});

export default CheckInModal;
