import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from "react-bootstrap";
import LoadingButton from "../components/CustomButton/LoadingButton";
import Button from "../components/CustomButton/CustomButton";
import { Card } from "../components/Card/Card";
import { combineContainer } from "../lib/general";
import AppContainer from "../containers/AppContainer";
import AuthEntityContainer from "../containers/entities/AuthEntityContainer";
import PasswordEditFormViewContainer from "../containers/views/PasswordEditFormViewContainer";
import { useOnceEffect, useApi } from "../lib/hooks";

const PasswordEditFormViewWrapper = (props) => (
  <PasswordEditFormViewContainer.Provider>
    <PasswordEditFormView
      {...props}
      staffForm={props.location.pathname.indexOf("staff") !== -1}
    />
  </PasswordEditFormViewContainer.Provider>
);

export default PasswordEditFormViewWrapper;

const PasswordEditFormView = (props) => {
  const history = useHistory();
  const { api } = useApi("/");
  const [auth, setAuth] = useState({});
  const [successMode, setSuccessMode] = useState(false);
  const container = combineContainer([
    AppContainer.useContainer(),
    AuthEntityContainer.useContainer(),
    PasswordEditFormViewContainer.useContainer(),
  ]);

  const { state, logic, setViewState } = container;

  const getAuthParams = () => {
    const arg = {};
    const pair = window.location.search.substring(1).split("&");
    for (let i = 0; pair[i]; i += 1) {
      const kv = pair[i].split("=");
      arg[kv[0]] = kv[1];
    }
    const provisionalUid = arg.uid;
    const uid = provisionalUid.replace("%40", "@");
    setAuth({
      accessToken: arg["access-token"],
      client: arg.client,
      uid: uid,
    });
  };

  useOnceEffect(() => {
    getAuthParams();
    setViewState.initialize(logic.getInitialPasswordReset());
  });

  const onClick = {
    passwordReset: async () => {
      const formData = {
        newPassword: state.formNewPassword,
        confirmPassword: state.formConfirmPassword,
        auth: auth,
      };
      if (!logic.validate(formData)) {
        return;
      }
      try {
        const path = props.staffForm ? "staff/auth/password" : "auth/password";
        api.patch(path, {
          password: state.formNewPassword,
          password_confirmation: state.formConfirmPassword
        }, {
          skipSetAuth: true
        }, {
          headers: {
            "Access-Token": auth.accessToken,
            client: auth.client,
            uid: auth.uid
          }
        });
        setSuccessMode(true);
      } catch (error) {
        props.displayNotification({
          level: "error",
          message: error.message,
        });
      }
    },
  };
  return (
    <div className="content">
      <Grid fluid>
        <Row className="justify-content-md-center">
          <Col md={3} xs={1} />
          <Col md={6} xs={10}>
            <Card
              ctTableResponsive
              content={
                !successMode ? (
                  <form>
                    <Row>
                      <Col md={1} xs={0} />
                      <Col md={10} xs={12}>
                        <FormGroup controlId="formControlsText">
                          <ControlLabel>新しいパスワード</ControlLabel>
                          <FormControl
                            name="formNewPassword"
                            placeholder="新しいパスワードを入力する"
                            bsClass="form-control"
                            type="password"
                            value={state.formNewPassword}
                            onChange={({ target }) =>
                              logic.handleFormChange(target.name, target.value)
                            }
                          />
                          <HelpBlock className="text-danger">
                            {state.newPasswordValidationMessage}
                          </HelpBlock>
                        </FormGroup>
                      </Col>
                      <Col md={1} xs={0} />
                    </Row>

                    <Row>
                      <Col md={1} xs={0} />
                      <Col md={10} xs={12}>
                        <FormGroup controlId="formControlsText">
                          <ControlLabel>確認用パスワード</ControlLabel>
                          <FormControl
                            name="formConfirmPassword"
                            placeholder="確認用パスワードを入力する"
                            bsClass="form-control"
                            type="password"
                            value={state.formConfirmPassword}
                            onChange={({ target }) =>
                              logic.handleFormChange(target.name, target.value)
                            }
                          />
                          <HelpBlock className="text-danger">
                            {state.confirmPasswordValidationMessage ||
                              state.comparePasswordValidationMessage}
                          </HelpBlock>
                        </FormGroup>
                      </Col>
                      <Col md={1} xs={0} />
                    </Row>

                    <Row style={{ marginBottom: 30 }}>
                      <Col md={1} sm={1} xs={1} />
                      <Col md={10} sm={10} xs={10}>
                        <LoadingButton
                          label="変更"
                          loadingLabel="変更中..."
                          block
                          fill
                          color="info"
                          onClick={() => onClick.passwordReset()}
                        />
                      </Col>
                      <Col md={1} sm={1} xs={1} />
                    </Row>
                  </form>
                ) : (
                  <div>
                    <h4>
                      パスワードを変更いたしました。ログイン画面に戻り、新しいパスワードでログインしてください。
                    </h4>
                    <Row style={{ marginBottom: 30, marginTop: 30 }}>
                      <Col md={1} xs={0} />
                      {props.staffForm ? (
                        <Col md={10} xs={12}>
                          <Button block fill onClick={() => history.push("/login")}>
                            <b>ログイン画面に戻る</b>
                          </Button>
                        </Col>
                      ) : null}
                      <Col md={1} xs={0} />
                    </Row>
                  </div>
                )
              }
            />
          </Col>
          <Col md={3} xs={1} />
        </Row>
      </Grid>
    </div>
  );
};
