import React, { useCallback, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import DiscountPeriodCard from "./parts/DiscountPeriodCard";
import DiscountContentsListView from "./parts/DiscountContentsListView";
import DiscountLogListView from "./parts/DiscountLogListView";
import { DisplayNotification } from "../../types/common.d";
import DiscountEntityContainer from "../../containers/entities/DiscountEntityContainer";

interface Props extends RouteComponentProps {
  displayNotification: DisplayNotification;
}

const DiscountView: React.FC<Props> = ({
  displayNotification,
  location,
  history,
}) => {
  const discountEntityContainer = DiscountEntityContainer.useContainer();
  const {
    filteringPeriod,
    discountLogFilteringCondition,
    contentPage,
    logPage,
  } = discountEntityContainer.state;
  const { setFilteringPeriod, setUrlParams, setDiscountLogFilteringCondition } =
    discountEntityContainer.logic;

  const changeUrl = useCallback(({ content_page = 1, log_page = 1, condition, period }) => {
    let newUrl = `${location.pathname}?content_page=${content_page}&log_page=${log_page}`;

    if (period.from && period.to) {
      newUrl += `&from=${period.from}&to=${period.to}`;
    }
    if (condition.store_ids.length) {
      condition.store_ids.forEach((id) => {
        newUrl += `&store_ids[]=${id}`;
      });
    }
    if (condition.action_types.length) {
      condition.action_types.forEach((action_type) => {
        newUrl += `&action_types[]=${action_type}`;
      });
    }
    if (condition.content_id) {
      newUrl += `&content_id=${condition.content_id}`;
    }
    if (condition.status) {
      newUrl += `&status=${condition.status}`;
    }
    if (condition.illegal_statuses.length) {
      condition.illegal_statuses.forEach((i) => {
        newUrl += `&illegal_statuses[]=${i}`;
      });
    }
    history.replace(newUrl);
  }, [location, history]);

  useEffect(() => {
    // urlパラメータがあれば保存
    // urlパラメータがなければページ遷移
    if (location.search) {
      setUrlParams(location.search);
    } else {
      changeUrl({
        period: filteringPeriod,
        condition: discountLogFilteringCondition,
        content_page: contentPage,
        log_page: logPage,
      });
    }
  }, [
    location.search,
    filteringPeriod,
    discountLogFilteringCondition,
    contentPage,
    logPage,
    setUrlParams,
    changeUrl
  ]);

  useEffect(() => {
    // urlパラメータをもとに絞り込みの条件を取得
    if (location.search) {
      const { searchParams } = new URL(window.location.href);
      const from = searchParams.get("from");
      const to = searchParams.get("to");
      const content_id = Number(searchParams.get("content_id")) || null;
      const store_ids = searchParams.getAll("store_ids[]").map(Number);
      const status: any = searchParams.get("status") || "active";
      const action_types: any = searchParams.getAll("action_types[]");
      const illegal_statuses: any = searchParams.getAll("illegal_statuses[]") || [];

      setFilteringPeriod({ from, to });
      setDiscountLogFilteringCondition({
        store_ids,
        action_types,
        content_id,
        status,
        illegal_statuses
      });
    }
  }, [
    location.search,
    setDiscountLogFilteringCondition,
    setFilteringPeriod
  ]);

  return (
    <div className="content">
      <DiscountPeriodCard
        displayNotification={displayNotification}
        changeUrl={changeUrl}
        filteringPeriod={filteringPeriod}
        discountLogFilteringCondition={discountLogFilteringCondition}
      />
      <DiscountContentsListView
        changeUrl={changeUrl}
        displayNotification={displayNotification}
      />
      <DiscountLogListView changeUrl={changeUrl} />
    </div>
  );
};

export default DiscountView;
