import React, { ReactNode } from "react";
import styled from "@emotion/styled";

type Props = {
  title: string;
  status?: ReactNode;
  note?: ReactNode;
  imageUrl?: string | null;
  [key: string]: any;
};

const InstanceItem = ({
  title,
  status,
  note,
  imageUrl,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledContainer {...otherProps}>
      {imageUrl ? (
        <StyledImage src={imageUrl} alt={title} />
      ) : (
        <StyledDummyImage>
          画像<br />なし
        </StyledDummyImage>
      )}

      <div>
        <StyledTitle>
          {title}
        </StyledTitle>

        <StyledNote>
          {status}

          <div style={{ marginRight: 8 }} />

          {note}
        </StyledNote>
      </div>
    </StyledContainer>
  );
};

const IMAGE_SIZE = 60;

const StyledContainer = styled("div")({
  padding: "8px 16px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#eee",
  }
});

const StyledImage = styled("img")({
  width: IMAGE_SIZE,
  maxHeight: IMAGE_SIZE,
  marginRight: 8
});
const StyledDummyImage = styled("div")({
  width: IMAGE_SIZE,
  height: IMAGE_SIZE,
  marginRight: 8,
  fontSize: 11,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "dashed 1px #bbb"
});

const StyledNote = styled("div")({
  fontSize: 12,
  color: "#9b9b9b",
  display: "flex",
  alignItems: "center",
  marginTop: 4
});
const StyledTitle = styled("div")({
  fontSize: 16
});

export default InstanceItem;
