import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { useGetApiCall } from "../../../lib/hooks";
import { UserGroupCollectionsResponse } from "../../../types/api/userGroupCollection.d";
import { UserGroupCollectionResource } from "../../../types/resource/userGroupCollection.d";

type Props = {
  disabled: boolean,
  renderingCount: number;
  onClickCollection: (id: number | null) => void;
  selectedUserGroupCollectionId: number | null;
  [key: string]: any;
};

const UserGroupCollectionList = ({
  disabled,
  renderingCount,
  selectedUserGroupCollectionId,
  onClickCollection,
  ...otherProps
}: Props): JSX.Element => {
  const { response, loading, reload } = useGetApiCall<UserGroupCollectionsResponse>("/user_group_collections");
  const userGroupCollections: UserGroupCollectionResource[] = response?.user_group_collections || [];

  useEffect(() => {
    if (renderingCount > 1) {
      reload();
    }
  }, [renderingCount, reload]);

  const handleClick = (collectionId: number) => {
    if (!disabled) {
      onClickCollection(collectionId);
    }
  };

  return (
    <div {...otherProps}>
      <StyledLabel
        disabled={disabled}
        selected={!selectedUserGroupCollectionId}
        onClick={() => onClickCollection(null)}
      >
        全体
      </StyledLabel>

      <StyledUserGroupTitle>
        ユーザーグループ
      </StyledUserGroupTitle>

      {loading ? (
        <CircularProgress />
      ) : (
        userGroupCollections.map((collection) => (
          <StyledLabel
            key={collection.id}
            disabled={disabled}
            selected={collection.id === selectedUserGroupCollectionId}
            onClick={() => handleClick(collection.id)}
          >
            {collection.name}
          </StyledLabel>
        ))
      )}
    </div>
  );
};

const StyledLabel = styled.div<{
  selected: boolean,
  disabled: boolean
}>(({ selected, disabled }) => ({
  cursor: "pointer",
  padding: 8,
  borderRadius: 4,
  backgroundColor: selected ? "#F3F3F6" : "transparent",
  color: disabled ? "#999" : "inherit",
  fontWeight: selected ? "bold" : "normal",
  "&:hover": {
    backgroundColor: "#F3F3F6"
  }
}));

const StyledUserGroupTitle = styled.div({
  color: "#9A9A9A",
  fontWeight: "bold",
  padding: "16px 4px 4px 4px",
});

export default UserGroupCollectionList;
