import React from "react";
import DateOptionFormItem from "../parts/DateOptionFormItem";
import { Condition, ConditionDiff } from "../../type";

type Props = {
  condition: Condition,
  onChange: (diff: ConditionDiff) => void;
};

const StoreConnectionCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <DateOptionFormItem
      condition={condition}
      label="つながった日"
      noneLabel="指定してください"
      onChange={(scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to) => {
        onChange({ scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to });
      }}
    />
  );
};

export default StoreConnectionCondition;
