import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "../../../components/FormInputs/FormLabel";
import { UserGender } from "../../../types/user";
import { GenderLabel } from "../../../constants/user";

type Props = {
  open: boolean;
  defaultGender: UserGender | null;
  disabled: boolean;
  onClose: () => void;
  onSubmit: (sex: UserGender) => void;
};

const GenderUpdateModal = ({
  open,
  defaultGender,
  disabled,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  const [gender, setGender] = useState<UserGender | null>(defaultGender);

  const submit = () => {
    if (gender) {
      onSubmit(gender);
    };
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm">
      <StyledDialogTitle>
        性別変更
        <i onClick={onClose} className="ri-close-line" />
      </StyledDialogTitle>
      <DialogContent>
        <FormLabel label="現在の性別" />
        <Box fontSize={18} ml={1} mb={2}>
          {defaultGender ? GenderLabel[defaultGender] : "設定されていません"}
        </Box>

        <FormLabel label="変更後の性別" />
        <Select value={gender} onChange={(e) => setGender(e.target.value as UserGender)}>
          <MenuItem value="male">男性</MenuItem>
          <MenuItem value="female">女性</MenuItem>
          <MenuItem value="other">その他</MenuItem>
        </Select>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          color="cancel"
          disabled={disabled}
          onClick={onClose}
        >
          キャンセル
        </Button>

        <Button
          fullWidth
          variant="contained"
          color="submit"
          disabled={!gender || disabled}
          onClick={submit}
        >
          性別を更新
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  "& > i": {
    cursor: "pointer"
  }
});

export default GenderUpdateModal;
