import { useCallback } from "react";
import { useApi } from "../../hooks";
import { SubscriptionContentStatus } from "../../../types/resource/subscriptionContent.d";
import { SortOrderType } from "../../../types/common.d";
import { SubscriptionSortByType } from "../../../types/subscription.d";

export function useSubscriptionContentGetApi() {
  const api = useApi();

  const fetchSubscriptionContents = useCallback(
    (page: number, sort: SubscriptionSortByType, order: SortOrderType) =>
      api.api.get({
        uri: "/subscription_contents",
        params: {
          page,
          sort,
          order,
        },
      }),
    [api.api]
  );

  return { ...api, fetchSubscriptionContents };
}

export function useSubscriptionContentStatusUpdateApi() {
  const api = useApi();

  const updateSubscriptionContentStatus = useCallback(
    (id: number, status: SubscriptionContentStatus) =>
      api.api.patch(`/subscription_contents/${id}`, {
        subscription_content: { status },
      }),
    [api.api]
  );

  return { ...api, updateSubscriptionContentStatus };
}
