import { OrderType, OrderStatus } from "../types/order";

export const OrderTypeLabels = {
  takeout: "テイクアウト",
  delivery: "デリバリー",
  preorder: "プレオーダー",
  ec: "オンラインショップ"
} as const;

export const OrderStatusLabels = {
  unapproved: "未承認",
  receive: "注文受付",
  ready: "準備中",
  wait: "受渡待ち",
  in_delivery: "配達中",
  done: "受渡済み",
  cancel: "キャンセル",
  shipped: "発送済み",
} as const;

export const OrderWaitStatusLabels = {
  takeout: "受渡待ち",
  delivery: "配達待ち",
  preorder: "受渡待ち",
  ec: "発送待ち"
} as const;

export const getOrderStatusLabel = (orderType: OrderType, orderStatus: OrderStatus) => {
  if (orderStatus === "wait") {
    return OrderWaitStatusLabels[orderType];
  }

  return OrderStatusLabels[orderStatus];
};
