import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useBooleanState } from "../../lib/hooks";
import UserGroupModal, {
  Condition,
  ConditionGroup,
  generateDescription,
  generateConditionGroup
} from "./UserGroupModal";

type Props = {
  disabled: boolean;
  openModalOnMount?: boolean;
  conditionGroups: ConditionGroup[],
  onChangeConditionGroups: (conditionGroups: ConditionGroup[]) => void;
  style?: React.CSSProperties;
};

const ConditionEditor = ({
  disabled,
  openModalOnMount,
  conditionGroups,
  onChangeConditionGroups,
  style
}: Props): JSX.Element => {
  const [currentConditionGroupKey, setCurrentConditionGroupKey] = useState<string>("");
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);

  useEffect(() => {
    if (openModalOnMount) {
      openModal();
    }
  }, [openModalOnMount, openModal]);

  const openModalFor = (conditionGroupKey = "") => {
    if (disabled) {
      return;
    }
    setCurrentConditionGroupKey(conditionGroupKey);
    openModal();
  };

  const handleDeleteConditionGroup = (conditionGroupKey: string) => {
    if (disabled || !window.confirm("条件を削除しますか？")) {
      return;
    }

    const newConditionGroups = conditionGroups.filter(({ key }) => (
      key !== conditionGroupKey
    ));

    onChangeConditionGroups(newConditionGroups);
    closeModal();
  };

  const handleSubmit = (conditions: Condition[]) => {
    const currentIndex = conditionGroups.findIndex((conditionGroup) => (
      conditionGroup.key === currentConditionGroupKey
    ));

    const newConditionGroups = [...conditionGroups];
    if (currentIndex > -1) {
      newConditionGroups[currentIndex].conditions = conditions;
    } else {
      newConditionGroups.push(generateConditionGroup(conditions));
    }

    onChangeConditionGroups(newConditionGroups);
    closeModal();
  };

  return (
    <StyledContainer style={style}>
      <StyledLabel>絞り込み条件</StyledLabel>

      {conditionGroups.map((conditionGroup) => (
        <React.Fragment key={conditionGroup.key}>
          <StyledConditionItemContainer>
            <StyledButtonContainer
              position="left"
              onClick={() => openModalFor(conditionGroup.key)}
            >
              {conditionGroup.conditions.map((condition) => (
                <StyledConditionContainer key={condition.key} disabled={disabled}>
                  {generateDescription(condition)}
                </StyledConditionContainer>
              ))}

              <StyledIcon disabled={disabled}>
                <i className="fas fa-pen" />
              </StyledIcon>
            </StyledButtonContainer>

            <StyledButtonContainer
              position="right"
              onClick={() => handleDeleteConditionGroup(conditionGroup.key)}
            >
              <StyledIcon hideBorder disabled={disabled}>
                <i className="fas fa-trash" />
              </StyledIcon>
            </StyledButtonContainer>
          </StyledConditionItemContainer>

          <StyledLabel>または</StyledLabel>
        </React.Fragment>
      ))}

      <StyledAddConditionButton
        data-cy="condition-editor-add-condition-button"
        disabled={disabled}
        onClick={() => openModalFor()}
      >
        <i className="fas fa-plus" />
        条件を追加
      </StyledAddConditionButton>

      <UserGroupModal
        show={isModalOpen}
        defaultConditions={conditionGroups.find(({ key }) => (
          key === currentConditionGroupKey
        ))?.conditions}
        onDelete={() => handleDeleteConditionGroup(currentConditionGroupKey)}
        onSubmit={handleSubmit}
        onClose={closeModal}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F9F9F9",
  padding: "8px 16px",
  borderRadius: 4,
  flexWrap: "wrap"
});

const StyledLabel = styled.div({
  fontWeight: "bold",
  color: "#9A9A9A"
});

export const StyledAddConditionButton = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  color: disabled ? "#96d9e7" : "#1DC7EA",
  cursor: "pointer",
  backgroundColor: "rgba(29, 199, 234, 0.1)",
  borderRadius: 4,
  padding: "8px 16px",
  borderWidth: 0,
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  marginLeft: 8,
  "&:hover": {
    color: "#0eb0d1",
    backgroundColor: "rgba(29, 199, 234, 0.2)"
  },
  "& > i": {
    marginRight: 8
  }
}));

const StyledConditionItemContainer = styled.div({
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "#E6E6E6",
  fontWeight: "bold",
  borderRadius: 4,
  margin: "0 8px"
});

const StyledButtonContainer = styled.div<{ position: string }>(({ position }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: position === "left" ? "4px 0 0 4px" : "0 4px 4px 0",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
}));

const StyledConditionContainer = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  color: disabled ? "#ccc" : "",
  padding: 8,
  borderRight: "1px solid rgba(0, 0, 0, 0.1)",
}));

const StyledIcon = styled.div<{
  disabled: boolean;
  hideBorder?: boolean;
}>(({ disabled, hideBorder }) => ({
  borderRight: hideBorder ? "" : "1px solid rgba(0, 0, 0, 0.1)",
  padding: "0 12px",
  height: 36,
  display: "flex",
  alignItems: "center",
  color: disabled ? "#ccc" : "#9A9A9A"
}));

export default ConditionEditor;
