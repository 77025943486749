import React from "react";
import moment from "moment"
import { X } from "phosphor-react";
import styled from "@emotion/styled";
import DatePicker from "./DatePicker";
import { ScopeType } from "../../type";

type Props = {
  from: ScopeType;
  to: ScopeType;
  onChange: (from: ScopeType, to: ScopeType) => void;
};

const DateRangeForm = ({
  from,
  to,
  onChange
}: Props): JSX.Element => {
  return (
    <StyledFlexContainer>
      <StyledDatePickerContainer>
        <DatePicker
          placeholder="いつから"
          value={from}
          maxDate={to}
          onChange={(dates: Date[]) => onChange(moment(dates[0]).format("YYYY/MM/DD"), to)}
        />

        {from && <StyledClearIcon onClick={() => onChange(null, to)} />}
      </StyledDatePickerContainer>

      <StyledFlexSpacer />

      <StyledDatePickerContainer>
        <DatePicker
          placeholder="いつまで"
          value={to}
          minDate={from}
          onChange={(dates: Date[]) => onChange(from, moment(dates[0]).format("YYYY/MM/DD"))}
        />

        {to && <StyledClearIcon onClick={() => onChange(from, null)} />}
      </StyledDatePickerContainer>
    </StyledFlexContainer>
  );
};

const StyledFlexContainer = styled.div({
  display: "flex",
  alignItems: "center"
});
const StyledFlexSpacer = styled.div({
  width: 16,
  flexBasis: 16
});

const StyledDatePickerContainer = styled.div({
  flex: 1,
  position: "relative"
});

const StyledClearIcon = styled(X)({
  fontSize: 18,
  right: 8,
  top: 11,
  position: "absolute",
  color: "#aaa",
  cursor: "pointer"
});

export default DateRangeForm;
