import React, { useState } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DATE_PICK_OPTION } from "../../../lib/general";
import { UserGender } from "../../../types/user";
import { QuestionnaireSectionResponse } from "../../../types/questionnaire";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import DialogTitle from "../../../components/DialogTitle";
import FormLabel from "../../../components/FormInputs/FormLabel";
import MultipleStoreSelectForm from "../../../components/FormInputs/MultipleStoreSelectForm";
import MembersCardRankSelectForm from "../../../components/FormInputs/MembersCardRankSelectForm";
import GenderSelectForm from "../../../components/FormInputs/GenderSelectForm";
import GenerationSelectForm from "../../../components/FormInputs/GenerationSelectForm";
import { RestaurantSatisfactionAnswerFilterCondition } from "../type";

type Props = {
  sections: QuestionnaireSectionResponse[];
  open: boolean;
  defaultCondition: RestaurantSatisfactionAnswerFilterCondition;
  onClose: () => void;
  onSubmit: (conditions: RestaurantSatisfactionAnswerFilterCondition) => void;
};

const RestaurantSatisfactionFilterConditionModal = ({
  sections,
  open,
  defaultCondition,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  const totalQualitySection = sections.find((v) => v.dashboard_section === "total_quality") ?? null;
  const orderItemsSection = sections.find((v) => v.dashboard_section === "order_items") ?? null;
  const { hasFunction } = useLoginContext();
  const [totalQualityIds, setTotalQualityIds] = useState<number[]>(defaultCondition.totalQualityIds);
  const [dateFrom, setDateFrom] = useState<string | null>(defaultCondition.dateFrom);
  const [dateTo, setDateTo] = useState<string | null>(defaultCondition.dateTo);
  const [answerStoreIds, setAnswerStoreIds] = useState<number[]>(defaultCondition.answerStoreIds);
  const [orderItemIds, setOrderItemIds] = useState<number[]>(defaultCondition.orderItemIds);
  const [rankId, setRankId] = useState<number | null>(defaultCondition.rankId);
  const [gender, setGender] = useState<UserGender | null>(defaultCondition.gender);
  const [ageFrom, setAgeFrom] = useState<number | null>(defaultCondition.ageFrom);
  const [ageTo, setAgeTo] = useState<number | null>(defaultCondition.ageTo);
  const totalQualityMap = totalQualitySection?.section_items
    .reduce((result, item) => ({ ...result, [item.id]: item }), {}) || {};
  const orderItemMap = orderItemsSection?.section_items
    .reduce((result, item) => ({ ...result, [item.id]: item }), {}) || {};

  const reset = () => {
    setTotalQualityIds([]);
    setDateFrom(null);
    setDateTo(null);
    setAnswerStoreIds([]);
    setOrderItemIds([]);
    setRankId(null);
    setGender(null);
    setAgeFrom(null);
    setAgeTo(null);
  };

  const submit = () => {
    onSubmit({
      totalQualityIds,
      dateFrom,
      dateTo,
      answerStoreIds,
      orderItemIds,
      rankId,
      gender,
      ageFrom,
      ageTo
    });
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>
        絞り込み条件
      </DialogTitle>

      <DialogContent>
        <FormLabel label="総合満足度評価" />
        {totalQualitySection && (
          <Autocomplete
            sx={{ mb: 2 }}
            multiple
            disableCloseOnSelect
            size="small"
            value={totalQualityIds.map((id) => totalQualityMap[id])}
            options={totalQualitySection.section_items.sort((a, b) => (a.order || 0) > (b.order || 0) ? -1 : 1)}
            getOptionLabel={(option) => `星${option.label_value} (${option.label})`}
            onChange={(_, values) => setTotalQualityIds(values.map((value) => value.id))}
            renderInput={(params) => (
              <TextField
                {...params}
                label={null}
                variant="outlined"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ padding: "0 8px" }}>
                <Checkbox checked={selected} />
                星{option.label_value} ({option.label})
              </li>
            )}
          />
        )}

        <FormLabel label="期間" />
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <Flatpickr
            value={dateFrom || ""}
            options={DATE_PICK_OPTION}
            onChange={(dates: Date[]) => {
              const newValue = dates[0];
              setDateFrom(newValue ? moment(newValue).format("YYYY/MM/DD") : null)
            }}
          />
          〜
          <Flatpickr
            value={dateTo || ""}
            options={DATE_PICK_OPTION}
            onChange={(dates: Date[]) => {
              const newValue = dates[0];
              setDateTo(newValue ? moment(newValue).format("YYYY/MM/DD") : null)
            }}
          />
        </Box>

        <FormLabel label="店舗" />
        <Box mb={2}>
          <MultipleStoreSelectForm
            fullWidth
            storeIds={answerStoreIds}
            onChange={setAnswerStoreIds}
          />
        </Box>

        {orderItemsSection && (
          <>
            <FormLabel label="注文した商品" />
            <Autocomplete
              sx={{ mb: 2 }}
              multiple
              disableCloseOnSelect
              size="small"
              value={orderItemIds.map((id) => orderItemMap[id])}
              options={orderItemsSection.section_items}
              getOptionLabel={(option) => option.label}
              onChange={(_, values) => setOrderItemIds(values.map((value) => value.id))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={null}
                  variant="outlined"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ padding: "0 8px" }}>
                  <Checkbox checked={selected} />
                  {option.label}
                </li>
              )}
            />
          </>
        )}

        {hasFunction("members_card") && (
          <>
            <FormLabel label="ランク" />
            <MembersCardRankSelectForm
              sx={{ mb: 2 }}
              fullWidth
              rankId={rankId}
              onChange={(newRankId) => setRankId(newRankId)}
            />
          </>
        )}

        <FormLabel label="性別" />
        <GenderSelectForm
          fullWidth
          sx={{ mb: 2 }}
          gender={gender}
          onChange={setGender}
        />

        <FormLabel label="年代" />
        <GenerationSelectForm
          fullWidth
          sx={{ mb: 2 }}
          age={ageFrom}
          onChange={(_, range) => {
            setAgeFrom(range?.[0] ?? null);
            setAgeTo(range?.[1] ?? null);
          }}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          color="cancel"
          onClick={reset}
        >
          条件をリセットする
        </Button>

        <Button
          fullWidth
          variant="contained"
          color="submit"
          onClick={submit}
        >
          この条件で絞り込む
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestaurantSatisfactionFilterConditionModal;
