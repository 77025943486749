import React, { useState } from "react";
import { Form, Row, Col, FormGroup, HelpBlock } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { Card } from "../../../components/Card/Card";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import FormInputs from "../../../components/FormInputs/FormInputs";
import FormLabel from "../../../components/FormInputs/FormLabel";
import ImageDisplay from "../../../components/Image/ImageDisplay";

import { formatDateExceptTime } from "../../../lib/general";
import SpecifyPointQrSettingModal from "./SpecifyPointQrSettingModal";
import { PaymentPointsLabel, PaymentPoints } from "../../../constants/membersCard";
import { useApi, useBooleanState } from "../../../lib/hooks";
import {
  Alert,
  FormChangedAlertModal,
} from "../../../components/Modal/FormChangedAlertModal";
import { MembersCardForm } from "../type.d";
import { MembersCardContentInterface } from "../../../containers/entities/MembersCardContentEntityContainer";
import { useNotification } from "../../../providers/NotificationProvider";

interface Props {
  membersCardContent: MembersCardContentInterface;
  onDeletedMembersCardQrCode: () => void;
  onAddedMembersCardQrCode: () => void;
  onDisableCheckInPoint: () => void;
  onDisablePaymentPoint: () => void;
  disabled: boolean;
  onSubmit: () => void;
  style: React.CSSProperties;
}

const PointSettingCard: React.FC<Props> = ({
  membersCardContent,
  onDeletedMembersCardQrCode,
  onAddedMembersCardQrCode,
  onDisableCheckInPoint,
  onDisablePaymentPoint,
  disabled,
  onSubmit,
  style
}) => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { register, formState, handleSubmit, watch, errors } = useFormContext<MembersCardForm>();
  const { dirtyFields } = formState;
  const [showSpecifyPointQrSettingModal, setShowSpecifyPointQrSettingModal] = useState(false);

  const [isQrConfirmModalOpen, openQrConfirmModal, closeQrConfirmModal] = useBooleanState(false);
  const [deleteTargetQrCodeId, setDeleteTargetQrCodeId] = useState<number | null>(null);

  const watchPointEnabled = watch("point_enabled");
  const watchCheckInPointEnabled = watch("check_in_point_enabled") === "enabled";
  const watchPaymentPointType = watch("payment_point_type");

  const { api: membersCardQrCodeApi } = useApi();
  const addMembersCardQrCode = async (point: number | null) => {
    if (!window.confirm("ポイント付与用QRコードを追加しますか")) {
      return;
    }

    const query = point ? `?point=${point}` : "";
    membersCardQrCodeApi.post(`/members_card_content_qrcodes${query}`).then(() => {
      showSuccessNotification("追加しました。");
      onAddedMembersCardQrCode();
    });
  }

  const deleteMembersCardQrCode = async () => {
    membersCardQrCodeApi.delete(`/members_card_content_qrcodes/${deleteTargetQrCodeId}`).then(() => {
      showSuccessNotification("削除しました。");
      onDeletedMembersCardQrCode();
    });
    closeQrConfirmModal();
  };

  const onError = () => {
    showErrorNotification("値が正しく入力されていません。");
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)} style={style}>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title="ポイント設定"
            content={
              <>
                <SelectorForm
                  name="check_in_point_enabled"
                  inputRef={register}
                  label="チェックインポイント"
                  options={[
                    { label: "有効", value: "enabled"},
                    { label: "無効", value: "disabled"},
                  ]}
                  onSelect={() => {
                    if (!watchCheckInPointEnabled) {
                      onDisableCheckInPoint();
                    }
                  }}
                  showChangeIndicator={dirtyFields.check_in_point_enabled}
                />

                <FormInputs
                  properties={[
                    {
                      label: "チェックインポイント数",
                      name: "check_in_point",
                      type: "number",
                      bsClass: "form-control",
                      ncol: "col-md-6",
                      inputRef: register( {
                        validate: (v) => {
                          if (!watchPointEnabled || !watchCheckInPointEnabled)  return true;
                          if (v === "") return "数字を入力して下さい。";
                          return (1 <= Number(v) && Number(v) < 1000) || "1以上1000未満の数字を入力してください。";
                        },
                        valueAsNumber: true,
                      }),
                      unit: "ポイント",
                      width: 200,
                      validationMessage:
                      errors?.check_in_point?.message,
                      showChangeIndicator: dirtyFields.check_in_point,
                    }
                  ]}
                  style={!watchCheckInPointEnabled ? { display: "none"} : {}}
                />

                <SelectorForm
                  name="payment_point_type"
                  inputRef={register}
                  label="お会計ポイント"
                  options={PaymentPoints.map((paymentPoint) => ({
                    label: PaymentPointsLabel[paymentPoint],
                    value: paymentPoint,
                  }))}
                  onSelect={() => {
                    if (watchPaymentPointType === "disable") {
                      onDisablePaymentPoint();
                    }
                  }}
                  showChangeIndicator={dirtyFields.payment_point_type}
                />

                <FormInputs
                  properties={[
                    !membersCardContent.point_rate && membersCardContent.payment_point_amount ? {
                      label: "お会計ポイント数",
                      name: "payment_point_amount",
                      type: "number",
                      bsClass: "form-control",
                      ncol: "col-md-6",
                      inputRef: register({
                        valueAsNumber: true,
                      }),
                      unit: "円で1ポイント",
                      width: 200,
                      validationMessage: errors.payment_point_amount?.message,
                      showChangeIndicator: dirtyFields.payment_point_amount,
                    } : {
                      label: "ポイント還元率",
                      name: "point_rate",
                      type: "number",
                      bsClass: "form-control",
                      ncol: "col-md-3",
                      inputRef: register({
                        validate: (rate) => {
                          if (!watchPointEnabled || watchPaymentPointType === "disable") return true;
                          if (rate === "") return "数字を入力して下さい。";
                          return (0 <= Number(rate)) || "0 ％未満の値は設定できません。";
                        },
                        valueAsNumber: true
                      }),
                      unit: "％還元",
                      step: "any",
                      validationMessage: errors.point_rate?.message,
                      showChangeIndicator: dirtyFields.point_rate,
                    }
                  ]}
                  style={watchPaymentPointType === "disable" ? { display: "none"} : {}}
                />

                <SelectorForm
                  name="point_expiration_month"
                  inputRef={register({ valueAsNumber: true })}
                  label="ポイントの有効期限"
                  options={[...Array(25)].map((_, index) => ({
                    label: index ? `${index}ヶ月間` : "設定しない",
                    value: index,
                  }))}
                  showChangeIndicator={dirtyFields.point_expiration_month}
                />

                <Row>
                  <FormGroup className="col-md-12">
                    <FormLabel label="ポイント付与用QRコード" />
                    <HelpBlock
                      className="text-muted"
                      style={{ fontSize: 11, marginBottom: 7 }}
                    >
                      お客様がQRコードを読み込むと、ポイントの獲得ができます。
                    </HelpBlock>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {membersCardContent?.point_qrcodes.map((pointQrcode) => (
                        <ImageDisplay
                          key={pointQrcode.id}
                          label={`作成日：${formatDateExceptTime(
                            pointQrcode.created_at
                          )}\n付与ポイント：${pointQrcode.point || "入力"}`}
                          href={pointQrcode.image_url}
                          altMessage={pointQrcode.name}
                          image={pointQrcode.image_url}
                          width={120}
                          height={120}
                          filename={
                            pointQrcode.point
                              ? `ポイント付与用QRコード(${pointQrcode.point}ポイント).png`
                              : `ポイント付与用QRコード(入力).png`
                          }
                          onClickDeleteButton={(e) => {
                            e.preventDefault();
                            setDeleteTargetQrCodeId(pointQrcode.id);
                            openQrConfirmModal();
                          }}
                        />
                      ))}
                      <ImageDisplay
                        width={120}
                        height={120}
                        onClickCreateButton={() =>
                          setShowSpecifyPointQrSettingModal(true)
                        }
                      />
                    </div>
                  </FormGroup>
                </Row>

                <Row>
                  <Col xs={12}>
                    <LoadingButton
                      type="submit"
                      label="更新する"
                      loadingLabel="更新中..."
                      color="info"
                      fill
                      pullRight
                      disabled={Object.keys(dirtyFields).length === 0 || disabled}
                    />
                  </Col>
                </Row>

                <FormChangedAlertModal
                  show={isQrConfirmModalOpen}
                  title="ポイント付与用QRコードを削除"
                  onSubmit={deleteMembersCardQrCode}
                  onCancel={closeQrConfirmModal}
                  displayStatus="danger"
                  needAgreement
                >
                  ポイント付与用のQRコードを削除します。本当に削除してよろしいですか？
                  <Alert severity="danger">
                    <ul>
                      <li>QRコードを削除するとユーザーがQRコードを読み取れなくなります。</li>
                      <li>QRコードを復元することはできません。</li>
                    </ul>
                  </Alert>
                </FormChangedAlertModal>
              </>
            }
          />
        </Col>
      </Row>
      <SpecifyPointQrSettingModal
        show={showSpecifyPointQrSettingModal}
        onHide={() => setShowSpecifyPointQrSettingModal(false)}
        onClickCreateMembersCardContentQrcode={addMembersCardQrCode}
      />
    </Form>
  );
};

export default PointSettingCard;
