import React from "react";
import { Genders, } from "../../../../constants/user";
import { UserGender } from "../../../../types/user";
import { Condition, ConditionDiff } from "../../type";
import GenderSelectForm from "../../../FormInputs/GenderSelectForm";

type Props = {
  condition: Condition;
  onChange: (diff: ConditionDiff) => void;
};

const GenderCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  const gender = condition.value_from === null
    ? null
    : Genders.includes(condition.value_from as UserGender)
    ? condition.value_from as UserGender
    : null;

  return (
    <GenderSelectForm
      fullWidth
      emptyLabel="選択してください"
      sx={{ backgroundColor: "#fff" }}
      gender={gender}
      onChange={(gender) => onChange({ value_from: gender })}
    />
  );
};

export default GenderCondition;
