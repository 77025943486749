import { useMemo, useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { storeApi, ownerStoreApi } from "../../lib/api";
import { formatDate } from "../../lib/general";
import { loadViewObj } from "../../lib/viewObj";
import { isOwner } from "../../lib/staff";
import { ActiveFunctionResource, QrScanMode } from "../../types/store";

type QrCodeCategory =
  | "user"
  | "tmp_store"
  | "stamp"
  | "privilege"
  | "check_in"
  | "point"
  | "rank";

type StoreQrcodes = {
  category: QrCodeCategory;
  created_at: string;
  id: number;
  app_icon_url: string;
  name: string;
  scanned_count: number;
};

type StoreType = "child" | "store_group" | "owner_group";
type StoreStatusType = "open" | "close" | "archive";

type ParentStoreType = {
  id: number;
  app_icon_url: string | null;
  name: string;
  status: StoreStatusType;
};

type ChildStoreType = {
  address: string;
  id: number;
  app_icon_url: string;
  name: string;
  parent_stores: Array<ParentStoreType>;
  status: StoreStatusType;
};

export type Store = {
  activeFunction: ActiveFunctionResource;
  address: string;
  appeal: string;
  businessType: undefined;
  checkInQrcodes: Array<StoreQrcodes>;
  child_stores: Array<ChildStoreType>;
  code: string | null;
  createdAt: string;
  customerUnitPrice: string | null;
  monthlyCustomer: number | null;
  email: string;
  facebookAccessToken: string | undefined;
  facebookUrl: string;
  firstMessage: string | null;
  firstMessageStaffImageUrl: string | null;
  firstMessageStaffName: string | null;
  id: number;
  appIconUrl: string;
  instagramUrl: string;
  mapUrl: string;
  isFacebookOauth: boolean;
  is_open_child_stores: boolean;
  name: string;
  open_time: string;
  open_time_active: boolean;
  phone_number: string;
  qrscan_mode: QrScanMode;
  presidentName: string | undefined;
  status: StoreStatusType;
  storeLink: string | null;
  storeQrcodes: Array<StoreQrcodes>;
  store_type: StoreType;
  subImageUrls: Array<string>;
  twitterUrl: string;
  url: string;
};

const useStoreEntityHook = () => {
  const [store, setStore] = useState<Store>();
  const [stores, setStores] = useState([]);
  const [groups, setGroups] = useState([]);

  const state = {
    store,
    stores,
    groups,
  };

  const setEntityState = useMemo(() => ({
    store: (nextStore) => {
      setStore(nextStore);
    },
    stores: (nextStore) => {
      setStores(nextStore);
    },
    groups: (nextGroup) => {
      setGroups(nextGroup);
    },
  }), []);

  const toStoreParams = useCallback((data) => {
    const {
      name,
      businessType,
      appeal,
      customerUnitPrice,
      monthlyCustomer,
      appIcon,
      url,
      twitterUrl,
      instagramUrl,
      facebookUrl,
      mapUrl,
      facebookAccessToken,
      address,
      phone_number,
      qrscan_mode,
      open_time,
      open_time_active,
      status,
      open_on_map,
      code,
      is_open_child_stores,
      firstMessageStaffImage,
      firstMessageStaffName,
      firstMessage,
    } = data;
    return {
      name,
      business_type: businessType,
      appeal,
      customer_unit_price: customerUnitPrice,
      monthly_customer: monthlyCustomer,
      app_icon: appIcon,
      url: url,
      twitter_url: twitterUrl,
      instagram_url: instagramUrl,
      facebook_url: facebookUrl,
      map_url: mapUrl,
      facebook_access_token: facebookAccessToken,
      address,
      qrscan_mode,
      phone_number,
      open_time,
      open_time_active,
      status,
      open_on_map,
      code,
      is_open_child_stores,
      first_message_staff_image: firstMessageStaffImage,
      first_message_staff_name: firstMessageStaffName,
      first_message: firstMessage,
    };
  }, []);
  const toStoreEntity = useCallback((data) => {
    const {
      id,
      name,
      email,
      code,
      appeal,
      customer_unit_price,
      monthly_customer,
      president_name,
      business_type,
      app_icon_url,
      store_qrcodes,
      check_in_qrcodes,
      created_at,
      sub_image_urls,
      active_function,
      qrscan_mode,
      url,
      store_link,
      twitter_url,
      instagram_url,
      facebook_url,
      map_url,
      facebook_access_token,
      is_facebook_oauth,
      address,
      phone_number,
      open_time,
      open_time_active,
      status,
      open_on_map,
      child_stores,
      store_type,
      is_open_child_stores,
      first_message_staff_image_url,
      first_message_staff_name,
      first_message,
    } = data;

    return {
      id: id,
      name: name,
      email: email,
      code: code,
      appeal: appeal,
      customerUnitPrice: customer_unit_price,
      monthlyCustomer: monthly_customer,
      presidentName: president_name,
      businessType: business_type,
      appIconUrl: app_icon_url,
      storeQrcodes: store_qrcodes,
      checkInQrcodes: check_in_qrcodes,
      subImageUrls: sub_image_urls,
      createdAt: formatDate(created_at),
      activeFunction: active_function,
      url: url,
      storeLink: store_link,
      twitterUrl: twitter_url,
      instagramUrl: instagram_url,
      facebookUrl: facebook_url,
      mapUrl: map_url,
      facebookAccessToken: facebook_access_token,
      isFacebookOauth: is_facebook_oauth,
      address,
      qrscan_mode,
      phone_number,
      open_time,
      open_time_active,
      status,
      open_on_map,
      child_stores,
      store_type,
      is_open_child_stores,
      firstMessageStaffImageUrl: first_message_staff_image_url,
      firstMessageStaffName: first_message_staff_name,
      firstMessage: first_message,
    };
  }, []);

  const bind = { toStoreParams, toStoreEntity };

  const api = {
    getGroups: useCallback(async () => {
      const newGroups = await ownerStoreApi.getGroups();
      return newGroups;
    }, []),
    getStores: useCallback(async () => {
      // オーナー
      const newStores = await ownerStoreApi.getStores();
      return newStores;
    }, []),
    getStore: useCallback(async (id?: number) => {
      const hasOwnership = isOwner();

      if (hasOwnership && id) {
        const newStore = await ownerStoreApi.getStore({ id });
        return newStore;
      }

      const requestId = id || loadViewObj()?.id;
      const newStore = await storeApi.getStore(requestId);
      return toStoreEntity(newStore);
    }, [toStoreEntity]),
    updateStore: useCallback(
      async (params: Partial<Store>, id?) => {
        if (isOwner() && id) {
          const newStore = await ownerStoreApi.updateStore({ params, id });
          return newStore;
        }
        const newStore = await storeApi.updateStore({
          params: toStoreParams(params),
        });
        return toStoreEntity(newStore);
      },
      [toStoreParams, toStoreEntity]
    ),
    createSubImage: async (params, storeId) => {
      if (isOwner() && storeId) {
        const subImage = await ownerStoreApi.postSubImage({
          params: params,
          storeId,
        });
        return bind.toStoreEntity(subImage);
      }
      const subImage = await storeApi.postSubImage({
        params: params,
      });
      return bind.toStoreEntity(subImage);
    },
    updateSubImage: async (params, storeId) => {
      if (isOwner() && storeId) {
        const subImage = await ownerStoreApi.updateSubImage({
          params: params,
          storeId,
        });
        return bind.toStoreEntity(subImage);
      }
      const subImage = await storeApi.updateSubImage({
        params: params,
      });
      return bind.toStoreEntity(subImage);
    },
    deleteSubImage: async (index, storeId) => {
      if (isOwner() && storeId) {
        const subImage = await ownerStoreApi
          .deleteSubImage(index, storeId)
          .then(() => true);
        return bind.toStoreEntity(subImage);
      }
      const subImage = await storeApi.deleteSubImage(index).then(() => true);
      return bind.toStoreEntity(subImage);
    },
    createStore: async (params) => {
      const type = loadViewObj()?.type;
      const hasOwnership = isOwner();

      if (hasOwnership) {
        const newStore = await ownerStoreApi.createStore({ params });
        return newStore;
      }

      if (type === "store") {
        const newStore = await storeApi.createStore({
          params: params,
        });
        return bind.toStoreEntity(newStore);
      }
      return null;
    },
    createChild: async (storeId, childId) => {
      const newStore = await storeApi.createChild(storeId, childId);
      return newStore;
    },
    deleteChild: async (groupId, childId) => {
      const response = await storeApi.deleteChild(groupId, childId);
      return response;
    },
  };

  return { state, setEntityState, api, bind };
};

export default createContainer(useStoreEntityHook);
