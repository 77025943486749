import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { formatDateExceptTime } from "../../../lib/general";
import { usePcSizeFlag } from "../../../lib/hooks";
import { PayjpBalance, PayjpStatement } from "../../../types/payjp";
import { PayjpBalanceStatusLabels, PayjpStatementTypeLabels } from "../../../constants/payjp";

type Props = {
  disabled: boolean;
  balances: PayjpBalance[];
  onClickDownload: (statement: PayjpStatement) => void;
};

const BalanceList = ({
  disabled,
  balances,
  onClickDownload
}: Props): JSX.Element => {
  const isPcOrTable = usePcSizeFlag();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width={110}>入金日</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>入金額</TableCell>
          {isPcOrTable && <TableCell colSpan={2} sx={{ pl: 0 }}>明細</TableCell>}
          {isPcOrTable && <TableCell width={220}>期間</TableCell>}
          <TableCell width={60} />
        </TableRow>
      </TableHead>
      <TableBody>
        {balances.map((balance) => {
          balance.statements.sort((a, b) => (
            (a.term?.start_at ?? 0) < (b.term?.start_at ?? 0) ? 1 : -1
          ));

          return (
            <TableRow key={balance.id}>
              <TableCell>
                {balance.due_date ? formatDateExceptTime(balance.due_date * 1000) : "-"}
              </TableCell>
              <TableCell>
                {PayjpBalanceStatusLabels[balance.state]}
              </TableCell>
              <TableCell align="right">
                {balance.net.toLocaleString()}円
              </TableCell>
              {isPcOrTable && (
                <TableCell sx={{ px: 0 }}>
                  {balance.statements.map((statement) => (
                    <StyledStatementRow key={statement.id} justifyContent="flex-end">
                      {PayjpStatementTypeLabels[statement.type]}
                    </StyledStatementRow>
                  ))}
                </TableCell>
              )}
              {isPcOrTable && (
                <TableCell sx={{ pl: 1 }}>
                  {balance.statements.map((statement) => (
                    <StyledStatementRow key={statement.id} justifyContent="flex-end">
                      {statement.net.toLocaleString()}円
                    </StyledStatementRow>
                  ))}
                </TableCell>
              )}
              {isPcOrTable && (
                <TableCell>
                  {balance.statements.map((statement) => (
                    <StyledStatementRow key={statement.id}>
                      {statement.term ? (
                        <>
                          {moment(statement.term.start_at * 1000).format("YYYY/MM/DD")}
                          &nbsp;〜&nbsp;
                          {moment((statement.term.end_at - 1) * 1000).format("YYYY/MM/DD")}
                        </>
                      ) : null}
                    </StyledStatementRow>
                  ))}
                </TableCell>
              )}
              <TableCell>
                {balance.statements.map((statement) => (
                  <StyledStatementRow key={statement.id} justifyContent="center">
                    {statement.term ? (
                      <i
                        className="ri-file-download-line"
                        style={{ color: disabled ? "#aaa" : "#1DC7EA", cursor: "pointer", fontSize: 18 }}
                        onClick={() => {
                          if (!disabled) {
                            onClickDownload(statement);
                          }
                        }}
                      />
                    ) : null}
                  </StyledStatementRow>
                ))}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const StyledStatementRow = styled(Box)({
  height: 26,
  display: "flex",
  alignItems: "center",
});

export default BalanceList;
