import React from "react";
import { withRouter } from "react-router";
import { Grid } from "react-bootstrap";
import * as H from "history";

import WalkThroughImg from "../../assets/img/walk_through.png";
import Button from "../../components/CustomButton/CustomButton";

interface Props {
  history: H.History;
}

const WalkThroughWrapper: React.FC<Props> = ({ history }) => (
  <WalkThrough history={history} />
);

const WalkThrough: React.FC<Props> = ({ history }) => (
  <Grid
    fluid
    className="content"
    style={{ width: "400px", display: "flex", flexDirection: "column" }}
  >
    <div style={{ textAlign: "center" }}>
      <div style={{ fontWeight: "bold", fontSize: 18 }}>
        トイポ管理アプリへようこそ！
      </div>

      <div style={{ marginTop: 10 }}>
        <div>
          このアプリは、お客様が利用する「トイポアプリ」内に作成されたあなたのお店のアプリを管理できます。
        </div>
      </div>
    </div>

    <img
      src={WalkThroughImg}
      alt="ウォークスルー"
      style={{ width: 300, height: 300, margin: "40px auto 0" }}
    />

    <Button
      style={{ marginTop: 70 }}
      fill
      bsStyle="info"
      onClick={(): void => history.push("/admin/welcome/plugin")}
    >
      <b>はじめる</b>
    </Button>
  </Grid>
);

export default withRouter(WalkThroughWrapper);
