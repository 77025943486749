import React, { useCallback, useEffect } from "react";
import { Modal, Form, Row, Col, FormGroup, HelpBlock } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";

// components
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import FormLabel from "../../../components/FormInputs/FormLabel";
import CustomButton from "../../../components/CustomButton/CustomButton";

// container
import {
  DiscountLogFilteringCondition,
  FilteringPeriod,
} from "../../../containers/entities/DiscountEntityContainer";

// lib
import { compareValidateDate } from "../../../lib/validation";

type Props = {
  show: boolean;
  onHide: () => void;
  displayNotification: ({
    level,
    message,
  }: {
    level: "success" | "error";
    message: string;
  }) => void;
  filteringPeriod: FilteringPeriod;
  changeUrl: ({ content_page, log_page, condition, period }) => void;
  discountLogFilteringCondition: DiscountLogFilteringCondition;
};

const DiscountPeriodFilteringModal: React.FC<Props> = ({
  show,
  onHide,
  displayNotification,
  changeUrl,
  filteringPeriod,
  discountLogFilteringCondition,
}) => {
  const { handleSubmit, errors, getValues, watch, setValue, control, reset } =
    useForm<{ filteringPeriod: FilteringPeriod }>({
      defaultValues: {
        filteringPeriod: {
          from: null,
          to: null,
        },
      },
    });

  const watchFrom = watch("filteringPeriod.from");
  const watchTo = watch("filteringPeriod.to");

  const processFormData = useCallback((data) => {
    const processedData = data;

    return processedData;
  }, []);

  const onSubmit = (data) => {
    const formData = processFormData(data.filteringPeriod);
    changeUrl({
      period: formData,
      content_page: 1,
      log_page: 1,
      condition: discountLogFilteringCondition,
    });
    onHide();
  };

  const onError = () => {
    displayNotification({
      level: "error",
      message: "値が正しく入力されていません。",
    });
  };

  const onClickResetButton = () => {
    setValue("filteringPeriod.from", null, {
      shouldDirty: true,
    });
    setValue("filteringPeriod.to", null, {
      shouldDirty: true,
    });
  };

  const validateFrom = () => {
    const getValuesFrom = getValues("filteringPeriod.from");
    const getValuesTo = getValues("filteringPeriod.to");

    if (!getValuesFrom && !!getValuesTo) {
      return "期間を入力してください";
    }
    return true;
  };

  const validateTo = () => {
    const getValuesFrom = getValues("filteringPeriod.from");
    const getValuesTo = getValues("filteringPeriod.to");

    if (!!getValuesFrom && !getValuesTo) {
      return "期間を入力してください";
    }

    if (
      getValuesFrom &&
      getValuesTo &&
      compareValidateDate(getValuesTo, getValuesFrom).validation
    ) {
      return "開始日時より過去の終了日時を設定することはできません";
    }

    return true;
  };

  useEffect(() => {
    reset({ filteringPeriod: filteringPeriod });
  }, [filteringPeriod, reset]);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <b>期間絞り込み</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12}>
              <FormGroup bsSize="large">
                <FormLabel label="期間" />
                <Row>
                  <Col lg={5} md={5} xs={12}>
                    <Controller
                      control={control}
                      name="filteringPeriod.from"
                      rules={{
                        validate: validateFrom,
                      }}
                      render={(): JSX.Element => (
                        <Flatpickr
                          data-enable-time
                          value={watchFrom}
                          options={{
                            altInput: true,
                            allowInput: true,
                            locale: Japanese,
                            altFormat: "Y-m-d H:i",
                            minuteIncrement: 1,
                          }}
                          onChange={(date): void => {
                            setValue(
                              "filteringPeriod.from",
                              date.toLocaleString().slice(0, -3),
                              { shouldDirty: true }
                            );
                          }}
                          placeholder="いつから"
                        />
                      )}
                    />
                    <HelpBlock className="text-danger">
                      {errors?.filteringPeriod?.from?.message}
                    </HelpBlock>
                  </Col>
                  <Col lg={5} md={5} xs={12}>
                    <Controller
                      control={control}
                      name="filteringPeriod.to"
                      rules={{
                        validate: validateTo,
                      }}
                      render={(): JSX.Element => (
                        <Flatpickr
                          data-enable-time
                          value={watchTo}
                          options={{
                            altInput: true,
                            allowInput: true,
                            locale: Japanese,
                            altFormat: "Y-m-d H:i",
                            minuteIncrement: 1,
                          }}
                          onChange={(date): void => {
                            setValue(
                              "filteringPeriod.to",
                              date.toLocaleString().slice(0, -3),
                              { shouldDirty: true }
                            );
                          }}
                          placeholder="いつまで"
                        />
                      )}
                    />
                    <HelpBlock className="text-danger">
                      {errors?.filteringPeriod?.to?.message}
                    </HelpBlock>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          <LoadingButton
            type="submit"
            label="この条件で絞り込む"
            loadingLabel="絞り込み中..."
            color="info"
            fill
            block
          />

          <CustomButton
            block
            simple
            style={{ marginTop: 10 }}
            onClick={onClickResetButton}
          >
            <b style={{ fontSize: 14 }}>条件をリセットする</b>
          </CustomButton>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default DiscountPeriodFilteringModal;
