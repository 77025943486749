import styled from "@emotion/styled";
import { CouponStatusColors, CouponStatusType } from "../../../containers/entities/CouponContentEntityContainer";

type Props = {
  status: CouponStatusType,
  size?: "small" | "normal"
};

export default styled("div")<Props>(({ status, size }) => ({
  backgroundColor: CouponStatusColors[status],
  display: "inline",
  color: "white",
  padding: "5px 6px",
  border: 0,
  borderRadius: 6,
  fontWeight: "bold",
  ...(size === "small" ? { fontSize: 10 } : {})
}));
