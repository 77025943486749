import React, { useState, useEffect } from "react";
import { loadOwner } from "../../lib/owner";

type ViewProps = {
  text: string;
};

const SuspendViewWrapper: React.FC = () => {
  const owner = loadOwner();
  const [text, setText] = useState("");
  useEffect(() => {
    if (owner?.status === "rejected") {
      setText(
        "店舗の確認が取れなかったため、このアカウントの利用を停止しました。support@toypo.co.jpに店舗の確認が取れる書類を提出して下さい。店舗確認が取れるとアカウントが復旧いたします。"
      );
    }
    if (owner?.status === "rejected_by_terms") {
      setText(
        "利用規約の違反があったため、アカウントの利用を停止しました。詳細につきましてはsupport@toypo.co.jpまでお問い合わせ下さい。"
      );
    }
    // if (owner?.status === "rejected_by_unpaid") {
    //   return "二ヶ月分の未納の請求以上、支払が行われなかったため、アカウントの利用を停止しました。アカウントを復旧するためには支払いを行って下さい。詳細につきましてはsupport@toypo.co.jpまでお問い合わせ下さい。";
    // }
  }, [owner]);

  return <SuspendView text={text} />;
};

const SuspendView: React.FC<ViewProps> = ({ text }) => (
  <div
    className="content"
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <div
      style={{
        backgroundColor: "white",
        maxWidth: 500,
        width: "90%",
        margin: "0px auto",
        padding: "50px 30px",
      }}
    >
      <h1
        style={{
          fontSize: window.screen.width > 768 ? 22 : 18,
          fontWeight: 700,
          marginTop: 0,
          marginBottom: 20,
        }}
      >
        このアカウントは利用停止されています。
      </h1>
      <p style={{ fontSize: 12 }}>{text}</p>
    </div>
  </div>
);

export default SuspendViewWrapper;
