import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { intersection } from "lodash";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { formatDate } from "../../lib/general";
import { useApi, useQuery, useMobileFlag } from "../../lib/hooks";
import { StaffsResponse, StaffBlock } from "../../types/staff";
import Section from "../../components/Section";
import StaffRoleModalAddWrapper from "./parts/StaffRoleModalAddWrapper";
import StaffRoleModalRemoveWrapper from "./parts/StaffRoleModalRemoveWrapper";
import StoreStaffEntityContainer from "../../containers/entities/StoreStaffEntityContainer";
import { useNotification } from "../../providers/NotificationProvider";
import { StoreResource } from "../../types/store";
import LaptopStaffList from "./parts/LaptopStaffList";

const StaffListView = (): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const storeStaffContainer = StoreStaffEntityContainer.useContainer();
  const { currentStaff, currentStore } = useLoginContext();
  const isMobile = useMobileFlag();
  const [selectedStaff, setSelectedStaff] = useState<StaffBlock | null>(null);
  const [showEditModalMode, setShowEditModalMode] = useState<boolean | string>(false);
  const [optionAnchorEl, setOptionAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { api } = useApi();
  const { removeAuthFromStaff } = storeStaffContainer.logic;
  const isOwner = (group: StoreResource) => group.store_type === "owner_group";
  const { changeUrlAndFetchStaffs, staffs, loaded, totalPages, reload, queryParams } = useStaffApi();
  const goToDetail = (staff: StaffBlock) => {
    const loginStaffStoreIds = [...currentStaff.stores, ...currentStaff.groups].map(({ id }) => id);
    const staffStoreIds = [...staff.stores, ...staff.groups].map(({ id }) => id);
    const storeId = intersection(loginStaffStoreIds, staffStoreIds)[0];
    history.push(`staff/edit?id=${staff.staff.id}&store_id=${storeId}`);
  };

  const generateHandler = (handler: () => void) => () => {
    handler();
    setOptionAnchorEl(null);
  };

  const sendTestPushNotification = async () => {
    if (!selectedStaff) return;

    const response = await api.post(`/staffs/${selectedStaff.staff.id}/test_push_notifications`);
    if (response) {
      showSuccessNotification("テスト通知が送られました。");
    }
  };

  const removeStaffPermission = async () => {
    if (!selectedStaff) return;

    if (currentStaff.is_owner || currentStore.store_type !== "child") {
      setShowEditModalMode("remove");
    } else {
      await removeAuthFromStaff([currentStore.id], selectedStaff.staff.id, 0)
        .then(() => showSuccessNotification("権限を削除しました。"))
        .catch((error) => showErrorNotification(error.response.data.message));
      reload();
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Button color="submit" variant="contained" onClick={() => history.push("staff/new")}>
          スタッフを作成する
        </Button>
      </Box>

      <Section title="スタッフ一覧">
        {!loaded && (
          <Box display="flex" justifyContent="center" padding={4}>
            <CircularProgress />
          </Box>
        )}

        {!isMobile && loaded && (
          <LaptopStaffList
            staffs={staffs}
            onClickStaff={goToDetail}
            onClickOption={(e, staff) => {
              setOptionAnchorEl(e.currentTarget);
              setSelectedStaff(staff);
            }}
          />
        )}

        {isMobile && loaded && (
          <Table>
            <TableBody>
              {staffs.map((staff) => (
                <StyledMobileRow onClick={() => goToDetail(staff)}>
                  <Box flex="1 0 0" mr={1}>
                    <div style={{ fontWeight: "bold", fontSize: 14, marginBottom: 7 }}>
                      {staff.staff.name}
                    </div>
                    <Box color="#525252">
                      <div>メールアドレス: {staff.staff.email}</div>
                      <div>{staff.groups.find(isOwner) ? `オーナー名: ${staff.groups.find(isOwner)!.name}` : ""}</div>
                      <div>作成日: {staff.staff.created_at ? formatDate(staff.staff.created_at) : "----"}</div>
                    </Box>
                  </Box>

                  <IconButton
                    sx={{ lineHeight: 1 }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOptionAnchorEl(e.currentTarget);
                      setSelectedStaff(staff);
                    }}
                  >
                    <i className="ri-more-fill" />
                  </IconButton>
                </StyledMobileRow>
              ))}
            </TableBody>
          </Table>
        )}

        <Menu
          anchorEl={optionAnchorEl}
          open={Boolean(optionAnchorEl)}
          onClose={() => setOptionAnchorEl(null)}
        >
          {Boolean(selectedStaff?.staff.push_token) && (
            <MenuItem onClick={generateHandler(sendTestPushNotification)}>
              テスト通知を送る
            </MenuItem>
          )}
          {(currentStaff.is_owner || currentStore.store_type !== "child") && (
            <MenuItem onClick={generateHandler(() => setShowEditModalMode("add"))}>
              権限を追加する
            </MenuItem>
          )}
          <MenuItem
            onClick={generateHandler(removeStaffPermission)}
            sx={{ color: (theme) => theme.palette.danger.main }}
          >
            権限を削除する
          </MenuItem>
        </Menu>

        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={queryParams.page}
            sx={{ mt: 2 }}
            onChange={(_, page) => changeUrlAndFetchStaffs({ page })}
          />
        )}
      </Section>

      {selectedStaff && (
        <StaffRoleModalAddWrapper
          show={showEditModalMode === "add"}
          staff={selectedStaff}
          onHide={() => setShowEditModalMode(false)}
          onAdded={() => {
            if (currentStaff.id === selectedStaff?.staff.id) {
              window.location.reload();
            } else {
              reload();
            }
          }}
        />
      )}

      {selectedStaff && (
        <StaffRoleModalRemoveWrapper
          show={showEditModalMode === "remove"}
          staff={selectedStaff}
          onHide={() => setShowEditModalMode(false)}
          onDeleted={async () => {
            if (currentStaff.id === selectedStaff?.staff.id) {
              window.location.reload();
            } else {
              reload();
            }
          }}
        />
      )}
    </div>
  );
};

type QueryParams = {
  page: number;
};

const useStaffApi = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { api, response, loading, loaded, totalPages } = useApi<StaffsResponse>();
  const [queryParams, setQueryParams] = useState<QueryParams>({ page: Number(query.get("page") || 1) });

  const changeUrl = useCallback((params: QueryParams) => {
    setQueryParams(params);

    const searchParams = new URLSearchParams();
    searchParams.append("page", String(params.page));

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);
  }, [history, location.pathname]);

  const reload = useCallback(() => {
    api.get("/staffs", {
      page: queryParams.page,
      limit: 10
    });
  }, [api, queryParams]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    changeUrlAndFetchStaffs: changeUrl,
    reload,
    staffs: response?.staffs || [],
    loading,
    loaded,
    totalPages,
    queryParams
  };
};

const StyledMobileRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 0)
}));

export default StaffListView;
