import React, { useEffect } from "react";
import { withRouter } from "react-router";
import ReactLoading from "react-loading";

import { Grid, Row, Col, Table, ButtonToolbar } from "react-bootstrap";

import { Card } from "../../components/Card/Card";
import Button from "../../components/CustomButton/CustomButton";

import AppContainer from "../../containers/AppContainer";
import NoticeTemplateEntityContainer from "../../containers/entities/NoticeTemplateEntityContainer";

import { formatDate } from "../../lib/general";

const NoticeTemplateListView = (props) => {
  const thArray = ["", "テンプレート名", "作成日"];

  const appContainer = AppContainer.useContainer();
  const noticeTemplateEntityContainer = NoticeTemplateEntityContainer.useContainer();

  const { isLoading } = appContainer.state;
  const { noticeTemplates } = noticeTemplateEntityContainer.state;
  const { getNoticeTemplates } = noticeTemplateEntityContainer.logic;

  useEffect(() => {
    getNoticeTemplates();
  }, [getNoticeTemplates]);

  const goTo = {
    noticeTemplateNew: () => props.history.push("templates/new"),
    noticeTemplateEdit: (template) =>
      props.history.push("templates/edit", { template }),
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <ButtonToolbar style={{ marginBottom: 10 }}>
              <Button
                bsStyle="info"
                fill
                pullRight
                onClick={goTo.noticeTemplateNew}
              >
                <b>テンプレートを作成する</b>
              </Button>
            </ButtonToolbar>

            <Card
              title="テンプレート一覧"
              ctTableFullWidth
              ctTableResponsive
              content={
                isLoading ? (
                  <div style={{ width: "10%", margin: "0 auto" }}>
                    <ReactLoading
                      type="bubbles"
                      color="#333"
                      height="80%"
                      width="80%"
                    />
                  </div>
                ) : (
                  <Table striped hover>
                    <thead>
                      <tr>
                        {thArray.map((prop, i) => (
                          <th key={i}>{prop}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {noticeTemplates.map((template) => (
                        <tr
                          key={template.id}
                          onClick={() => goTo.noticeTemplateEdit(template)}
                          style={{ cursor: "pointer" }}
                        >
                          <td style={{ width: 48 }}>
                            {template.image_urls && template.image_urls[0] && (
                              <img
                                key={template.id}
                                alt="テンプレート画像"
                                src={template.image_urls[0]}
                                width={48}
                                height={30}
                                style={{
                                  borderRadius: 2,
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {template.name}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {formatDate(template.created_at)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(NoticeTemplateListView);
