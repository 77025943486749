import { AxiosResponse } from "axios";
import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";
import { EventItem } from "../../containers/entities/EventEntityContainer";

export const getEvents = async ({
  page = 1,
}): Promise<AxiosResponse<EventItem[]>> => {
  const GET_EVENTS = `/staff/stores/${loadViewObj()?.id}/events?page=${page}`;

  const response: AxiosResponse<EventItem[]> = await apiClient({
    method: "GET",
    uri: GET_EVENTS,
  });
  return response;
};

export const createEvent = async (data: EventItem): Promise<EventItem[]> => {
  const CREATE_EVENT = `/staff/stores/${loadViewObj()?.id}/events`;

  const params = {
    event: data,
  };

  const response: AxiosResponse<EventItem[]> = await apiClient({
    method: "POST",
    params: params,
    uri: CREATE_EVENT,
  });
  return response.data;
};

export const updateEvent = async (data: EventItem): Promise<EventItem[]> => {
  const UPDATE_EVENT = `/staff/stores/${loadViewObj()?.id}/events/${data.id}`;

  const params = {
    event: data,
  };

  const response: AxiosResponse<EventItem[]> = await apiClient({
    method: "PATCH",
    params: params,
    uri: UPDATE_EVENT,
  });
  return response.data;
};

export const deleteEvent = async (id: number): Promise<EventItem[]> => {
  const DELETE_EVENT = `/staff/stores/${loadViewObj()?.id}/events/${id}`;

  const response: AxiosResponse<EventItem[]> = await apiClient({
    method: "DELETE",
    uri: DELETE_EVENT,
  });
  return response.data;
};
