import React from "react";
import styled from "@emotion/styled";

const ChangeIndicator = ({ style = {} }) => (
  <StyledContainer style={style}>
    <i className="fa fa-exclamation-circle" />
    変更中
  </StyledContainer>
);

const StyledContainer = styled("div")({
  fontSize: 14,
  color: "#1dc7ea"
});

export default ChangeIndicator;
