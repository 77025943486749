import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const createAutoCouponContent = async ({ params }) => {
  const CREATE_AUTO_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/auto_coupon_contents`;

  const requestParams = {
    auto_coupon_content: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_AUTO_COUPON_CONTENT,
    params: requestParams,
  });
  return response.data;
};

export const getAutoCouponContent = async (id: number) => {
  const AUTO_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/auto_coupon_contents/${id}`;

  const response = await apiClient({
    method: "GET",
    uri: AUTO_COUPON_CONTENT,
  });
  return response.data;
};

export const updateAutoCouponContent = async ({
  auto_coupon_content_id,
  params,
}) => {
  const UPDATE_AUTO_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/auto_coupon_contents/${auto_coupon_content_id}`;

  const requestParams = {
    auto_coupon_content: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_AUTO_COUPON_CONTENT,
    params: requestParams,
  });
  return response.data;
};
