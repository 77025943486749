import React, { Dispatch, SetStateAction } from "react";
import { Table } from "react-bootstrap";
import MediaQuery from "react-responsive";
import {
  TicketContent,
  ticketContentStatus,
  TicketContentStatus,
} from "../../../containers/entities/TicketContentEntityContainer";
import SmallTableBody from "../../../components/Table/SmallTableBody";
import { formatDateExceptTime } from "../../../lib/general";
import TicketContentTableRow from "./TicketContentTableRow";

type Props = {
  ticketContents: TicketContent[];
  statusToJp: (status: TicketContentStatus) => string;
  goTo: {
    ticketEdit: (ticketContent: TicketContent) => void;
    ticketNew: () => void;
    config: () => void;
  };
  setSelectedTicketContentContent: Dispatch<
    SetStateAction<TicketContent | undefined>
  >;
  setShowStatusChangeModal: Dispatch<SetStateAction<boolean>>;
};
const getTicketType = (usable_count: number): string => {
  if (!usable_count) {
    return "定期券";
  }
  if (usable_count > 1) {
    return "回数券";
  }
  if (usable_count === 1) {
    return "一回券";
  }
  return "---";
};
const getLabelStyle = (status: TicketContentStatus): string => {
  switch (status) {
    case ticketContentStatus.Open:
      return "active";
    case ticketContentStatus.Close:
      return "closed";
    case ticketContentStatus.Preview:
      return "draft";
    case ticketContentStatus.Sold:
    default:
      return status;
  }
};
const TicketListTable = ({
  ticketContents,
  statusToJp,
  goTo,
  setSelectedTicketContentContent,
  setShowStatusChangeModal
}: Props): JSX.Element => {
  const thArray = ["", "タイトル", "種類", "状態", "有効期限"];

  return (
    <Table striped hover>
      <MediaQuery query="(min-width: 767px)">
        <thead>
          <tr>
            {thArray.map((prop) => (
              <th key={`column-${prop}`}>{prop}</th>
            ))}
          </tr>
        </thead>
      </MediaQuery>
      <tbody>
        {ticketContents.map((ticketContent) => (
          <tr key={ticketContent.id}>
            <MediaQuery query="(min-width: 767px)">
              <TicketContentTableRow
                ticketContent={ticketContent}
                label={statusToJp(ticketContent.status)}
                labelStyle={getLabelStyle(ticketContent.status)}
                ticketType={getTicketType(ticketContent.usable_count)}
                openStatusModal={(): void => {
                  setSelectedTicketContentContent(ticketContent);
                  setShowStatusChangeModal(true);
                }}
                goToTicketEdit={(): void => goTo.ticketEdit(ticketContent)}
              />
            </MediaQuery>
            {/* スマホサイズ */}
            <MediaQuery query="(max-width: 766px)">
              <SmallTableBody
                image={ticketContent.image_url}
                title={ticketContent.title}
                contents={[
                  getTicketType(ticketContent.usable_count),
                  `有効期限: ${
                    ticketContent.expiration_month
                      ? `購入から${ticketContent.expiration_month}ヶ月`
                      : `${formatDateExceptTime(
                          ticketContent.expiration_from
                            ?.toLocaleString()
                            .slice(0, -6)
                        )}
                                ~ ${formatDateExceptTime(
                                  ticketContent.expiration_to
                                    ?.toLocaleString()
                                    .slice(0, -6)
                                )}`
                  }`,
                ]}
                status={
                  <div className={getLabelStyle(ticketContent.status)}>
                    {statusToJp(ticketContent.status)}
                  </div>
                }
                goTo={(): void => goTo.ticketEdit(ticketContent)}
                button={
                  <div
                    className="editStatus"
                    aria-hidden="true"
                    onClick={(): void => {
                      setSelectedTicketContentContent(ticketContent);
                      setShowStatusChangeModal(true);
                    }}
                  >
                    <i className="pe-7s-angle-up" style={{ fontSize: 24 }} />
                  </div>
                }
                displayImageBlank
                imageBlankIcon="ticket-item"
              />
            </MediaQuery>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TicketListTable;
