/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from "react";
import { FormGroup, Modal, Radio } from "react-bootstrap";
import Button from "../../../components/CustomButton/CustomButton";
import { TicketContentStatus } from "../../../containers/entities/TicketContentEntityContainer";

type Props = {
  ticketId?: number;
  ticketStatus?: TicketContentStatus;
  statuses: TicketContentStatus[];
  statusToJp: (status: TicketContentStatus) => string;
  changeStatus: (
    id: number | undefined,
    newStatus: TicketContentStatus
  ) => void;
  show: boolean;
  onHide: () => void;
};

const TicketStatusChangeModal: FC<Props> = ({
  ticketId,
  ticketStatus,
  statuses,
  statusToJp,
  changeStatus,
  show,
  onHide,
}) => {
  const [selectedStatus, setSelectdStatus] =
    useState<TicketContentStatus>("draft");

  useEffect(() => setSelectdStatus(ticketStatus || "draft"), [ticketStatus]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="small"
      aria-labelledby="contained-modal-title-sm"
      onExited={(): void => {
        document.body.style.overflow = "auto";
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm" componentClass="h6">
          どの状態に更新しますか？
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          {statuses.map((status) => {
            if (["draft", "expired", "sold"].includes(status)) return null;

            return (
              <Radio
                key={`status-change-modal-${status}`}
                name="radioGroup"
                onChange={(): void => setSelectdStatus(status)}
                checked={selectedStatus === status}
              >
                <b>{statusToJp(status)}</b>
              </Radio>
            );
          })}
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="info"
          bsSize="small"
          fill
          onClick={(): void => {
            changeStatus(ticketId, selectedStatus);
            onHide();
          }}
        >
          <b>更新する</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketStatusChangeModal;
