import React, { useEffect } from "react";
import { styled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { useApi, useBooleanState } from "../../../lib/hooks";
import { QuestionnaireAnswersResponse } from "../../../types/questionnaire";
import DialogTitle from "../../../components/DialogTitle";
import RestaurantSatisfactionAnswerItem from "./RestaurantSatisfactionAnswerItem";

type Props = {
  questionnaireId: number;
  userId: number;
  numberOfTimes: number;
}

const RestaurantSatisfactionAnswerModal = ({
  questionnaireId,
  userId,
  numberOfTimes
}: Props): JSX.Element => {
  const { api, response } = useApi<QuestionnaireAnswersResponse>();
  const answer = response?.questionnaire_answers[0];
  const [isAnswerModalOpen, , closeAnswerModal] = useBooleanState(true);

  useEffect(() => {
    api.get(`/questionnaires/${questionnaireId}/answers`, {
      user_id: userId,
      number_of_times: numberOfTimes,
    });
  }, [userId, numberOfTimes, api, questionnaireId])

  return (
    <Dialog
      open={isAnswerModalOpen}
      onClose={closeAnswerModal}
    >
      <DialogTitle onClose={closeAnswerModal}>
        回答
      </DialogTitle>

      <DialogContent>
        {answer ? (
          <RestaurantSatisfactionAnswerItem answer={answer} questionnaireId={questionnaireId} />
        ) : (
          <StyledIndicatorContainer>
            <CircularProgress />
          </StyledIndicatorContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

const StyledIndicatorContainer = styled("div")({
  width: 300,
  height: 300,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export default RestaurantSatisfactionAnswerModal;
