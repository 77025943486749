import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Condition, ConditionDiff } from "../../type";
import { ThirdPartyServices, ThirdPartyServiceLabel } from "../../../../constants/user";

type Props = {
  condition: Condition;
  onChange: (diff: ConditionDiff) => void;
};

const ThirdPartyServiceCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <Select
      fullWidth
      value={condition.value_from ?? "empty"}
      sx={{ backgroundColor: "#fff" }}
      onChange={(e) => {
        const { value } = e.target;
        onChange({ value_from: value === "empty" ? null : value });
      }}
    >
      <MenuItem value="empty">選択してください</MenuItem>
      {ThirdPartyServices.map((service) => (
        <MenuItem key={service} value={service}>{ThirdPartyServiceLabel[service]}</MenuItem>
      ))}
    </Select>
  );
};

export default ThirdPartyServiceCondition;
