import apiClient from "./apiClient";

export const getBranches = async () => {
  const GET_BRANCHES = "/branches";

  const response = await apiClient({ method: "GET", uri: GET_BRANCHES });
  return response.data;
};

export const createBranch = async ({ params }) => {
  const CREATE_BRANCH = "/branches";

  const requestParams = {
    branch: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_BRANCH,
    params: requestParams,
  });
  return response.data;
};

export const updateBranch = async ({ id, params }) => {
  const UPDATE_BRANCH = `/branches/${id}`;

  const requestParams = {
    branch: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_BRANCH,
    params: requestParams,
  });
  return response.data;
};

export const deleteBranch = async ({ id }) => {
  const DELETE_BRANCH = `/branches/${id}`;

  const response = await apiClient({ method: "DELETE", uri: DELETE_BRANCH });
  return response.data;
};
