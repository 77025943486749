import React, { useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { usePcSizeFlag } from "../../../lib/hooks";
import { StampCardsResponse } from "../../../types/api/stampCard.d";
import FormInput from "../../../components/FormInputs/FormInput";
import { CheckInFormType } from "./CheckInModal";

type Props = {
  stampCards: Array<StampCardsResponse>;
  isNeedAmount: boolean;
  isStampEnabled: boolean;
  isPointEnabled: boolean;
}

const StampAndPointAddCheckboxes = ({
  stampCards,
  isNeedAmount,
  isStampEnabled,
  isPointEnabled,
}: Props) => {
  const isPcOrTablet = usePcSizeFlag();
  const { control, errors } = useFormContext<CheckInFormType>();
  const [checkedStampAndPointType, setCheckedStampAndPointType] = useState<"amount" | "optional" | null>(null);
  const stampValue = useWatch({ control, name: "checkInByManual.stamp" });

  return (
    <div>
      <FormControlLabel control={<Checkbox defaultChecked disabled />} label="チェックイン（1日1回）" />

      {isNeedAmount && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedStampAndPointType === "amount"}
                onChange={(e) => {
                  const checked = e.target.checked ? "amount" : null;
                  setCheckedStampAndPointType(checked);
                }}
              />
            }
            label="お会計金額"
          />
          {checkedStampAndPointType === "amount" && (
            <Controller
              control={control}
              name="checkInByManual.amount"
              render={({ onChange, value }) => (
                <FormInput
                  type="number"
                  unit="円"
                  style={{ width: 250 }}
                  formGroupStyle={{ marginTop: 8, marginBottom: 0 }}
                  value={value}
                  onChange={({ target }) => onChange(Number(target.value))}
                />
              )}
            />
          )}
        </div>
      )}

      {(isStampEnabled || isPointEnabled) && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedStampAndPointType === "optional"}
                onChange={(e) => {
                  const checked = e.target.checked ? "optional" : null;
                  setCheckedStampAndPointType(checked);
                }}
              />
            }
            label="指定数での付与"
          />
          {checkedStampAndPointType === "optional" && (
            <StyledAddPointFieldsContainer>
              {isStampEnabled && (
                <StyledAddPointField isPcOrTablet={isPcOrTablet}>
                  <div>スタンプ</div>
                  <div>
                    <Controller
                      control={control}
                      name="checkInByManual.stampCardId"
                      rules={{
                        validate: value => {
                          if (stampValue) {
                            return value > 0 || "スタンプカードを選択してください。";
                          }
                          return true;
                        }
                      }}
                      render={({ onChange, value }) => (
                        <Select
                          displayEmpty
                          value={value}
                          onChange={({ target }) => onChange(Number(target.value))}
                        >
                          <MenuItem disabled value={0}>スタンプカードを選択してください</MenuItem>
                          {stampCards.map(({ stamp_card, store }) => (
                            <MenuItem key={stamp_card.id} value={stamp_card.id}>{store.name}</MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.checkInByManual?.stampCardId?.message && (
                      <FormHelperText error>{errors.checkInByManual?.stampCardId?.message}</FormHelperText>
                    )}
                  </div>
                  <Controller
                    control={control}
                    name="checkInByManual.stamp"
                    render={({ onChange, value }) => (
                      <FormInput
                        type="number"
                        unit="個"
                        style={{ width: 250 }}
                        formGroupStyle={{ margin: 0 }}
                        value={value}
                        onChange={({ target }) => onChange(Number(target.value))}
                      />
                    )}
                  />
                </StyledAddPointField>
              )}
              {isPointEnabled && (
                <StyledAddPointField isPcOrTablet={isPcOrTablet}>
                  <div>ポイント</div>
                  <Controller
                    control={control}
                    name="checkInByManual.point"
                    render={({ onChange, value }) => (
                      <FormInput
                        type="number"
                        unit="ポイント"
                        style={{ width: 250 }}
                        formGroupStyle={{ margin: 0 }}
                        value={value}
                        onChange={({ target }) => onChange(Number(target.value))}
                      />
                    )}
                  />
                </StyledAddPointField>
              )}
            </StyledAddPointFieldsContainer>
          )}
        </div>
      )}
    </div>
  );
};

const StyledAddPointFieldsContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "grid",
  gap: theme.spacing(1),
}));
const StyledAddPointField = styled("div")<{ isPcOrTablet: boolean }>(({ isPcOrTablet, theme }) => ({
  display: "flex",
  alignItems: isPcOrTablet ? "baseline" : "start",
  flexDirection: isPcOrTablet ? "row" : "column",
  gap: theme.spacing(1),
}))

export default StampAndPointAddCheckboxes
