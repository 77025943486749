import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "../../../components/DialogTitle";
import FormLabel from "../../../components/FormInputs/FormLabel";

type Props = {
  defaultOrderNumber: string;
  open: boolean;
  disabled: boolean;
  onSubmit: (orderNumber: string) => void;
  onClose: () => void;
};

const OrderNumberModal = ({
  defaultOrderNumber,
  open,
  disabled,
  onSubmit,
  onClose
}: Props): JSX.Element => {
  const [orderNumber, setOrderNumber] = useState<string>(defaultOrderNumber);

  useEffect(() => {
    if (open) {
      setOrderNumber(defaultOrderNumber);
    }
  }, [open, defaultOrderNumber]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle onClose={onClose}>
        注文番号の変更
      </DialogTitle>

      <DialogContent>
        <FormLabel label="注文番号" />

        <OutlinedInput
          value={orderNumber}
          disabled={disabled}
          onChange={({ target }) => setOrderNumber(target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          onClick={() => {
            if (defaultOrderNumber === orderNumber) {
              onClose();
            } else {
              onSubmit(orderNumber);
            }
          }}
        >
          変更する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderNumberModal;
