import React, { useState } from "react"
import { UseFormMethods } from "react-hook-form";
import { HelpBlock } from "react-bootstrap";
import styled from "@emotion/styled";
import { useGetApiCall } from "../../../lib/hooks";
import { Card } from "../../../components/Card/Card";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";

type Props = {
  register: UseFormMethods["register"];
  errors: UseFormMethods["errors"];
}

const StaffRoleSection = ({
  register,
  errors,
}: Props) => {
  const { response } = useGetApiCall("", {}, "staff/stores");
  const staffOwnedStoresResponse = response?.stores || [];

  const ownerGroup = staffOwnedStoresResponse.find(store => store.store.store_type === "owner_group");
  const storeGroups = staffOwnedStoresResponse.filter(store => store.store.store_type === "store_group");
  const stores = staffOwnedStoresResponse.filter(store => store.store.store_type === "child");

  const [selectedStoreIds, setSelectedStoreIds] = useState<Array<number>>([]);
  const [relationStoreIds, setRelationStoreIds] = useState<Array<number>>([]);

  const handleCheckbox = (storeId) => {
    const newSelectedStoreIds = selectedStoreIds.includes(storeId) ?
      selectedStoreIds.filter(id => id !== storeId) :
      [...selectedStoreIds, storeId];
    setSelectedStoreIds(newSelectedStoreIds);

    // 選択した店舗に所属する店舗一覧を取得する
    const selectedStores = staffOwnedStoresResponse.filter(store =>
      newSelectedStoreIds.includes(store.store.id)
    );

    const isOwnerGroupIncluded = selectedStores.some(({ store }) =>
      store.store_type === "owner_group"
    );
    if (isOwnerGroupIncluded) {
      const storeGroupIds = storeGroups.map(({ store }) => store.id);
      const storeIds = stores.map(({ store }) => store.id);

      const relationIds = [...storeGroupIds, ...storeIds];
      setRelationStoreIds(relationIds);
      return;
    }

    const childStoreIds = selectedStores?.flatMap(({ child_stores }) =>
      child_stores.map((childStore) => childStore.id)
    );
    setRelationStoreIds(childStoreIds);
  }

  return (
    <Card
      title="追加する権限"
      content={
        <div>
          {ownerGroup &&
            <StyledStoresContainer>
              <StyledLabel>オーナー</StyledLabel>
              <CustomCheckbox
                data-cy="staff-form-store-authority"
                name="store_ids"
                inputRef={register({
                  required: "1つ以上選択してください",
                })}
                number={ownerGroup?.store.id}
                value={ownerGroup?.store.id}
                label={ownerGroup?.store.name}
                checked={selectedStoreIds.includes(ownerGroup?.store.id)}
                onChange={() => handleCheckbox(ownerGroup?.store.id)}
              />
            </StyledStoresContainer>
          }

          {Boolean(storeGroups?.length) &&
            <StyledStoresContainer>
              <StyledLabel>グループ</StyledLabel>
              <StyledCheckBoxContainer>
                {storeGroups?.map((group) => (
                  <CustomCheckbox
                    data-cy="staff-form-store-authority"
                    name="store_ids"
                    inputRef={register({
                      required: "1つ以上選択してください",
                    })}
                    number={group?.store.id}
                    label={group.store.name}
                    value={group.store.id}
                    disabled={relationStoreIds.includes(group?.store.id)}
                    checked={selectedStoreIds.includes(group?.store.id) || relationStoreIds.includes(group?.store.id)}
                    onChange={() => handleCheckbox(group?.store.id)}
                  />
                ))}
              </StyledCheckBoxContainer>
            </StyledStoresContainer>
          }

          <StyledStoresContainer>
            <StyledLabel>店舗</StyledLabel>
            <StyledCheckBoxContainer>
              {stores?.map((store) => (
                <CustomCheckbox
                  data-cy="staff-form-store-authority"
                  name="store_ids"
                  inputRef={register({
                    required: "1つ以上選択してください",
                  })}
                  label={store.store.name}
                  number={store.store.id}
                  value={store.store.id}
                  disabled={relationStoreIds.includes(store?.store.id)}
                  checked={selectedStoreIds.includes(store?.store.id) || relationStoreIds.includes(store?.store.id)}
                  onChange={() => handleCheckbox(store?.store.id)}
                />
              ))}
            </StyledCheckBoxContainer>
          </StyledStoresContainer>

          <HelpBlock className="text-danger">{errors?.store_ids?.message}</HelpBlock>
        </div>
      }
    />
  )
}

const StyledStoresContainer = styled.div({
  marginBottom: 24
})
const StyledLabel = styled.div({
  fontWeight: "bold",
  fontSize: 16,
  marginBottom: 8, 
})
// WANT:カラム可変にしたい
const StyledCheckBoxContainer = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  rowGap: 12
})

export default StaffRoleSection;
