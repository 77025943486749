import React, { ReactNode } from "react";
import styled from "@emotion/styled";

type Props = {
  label?: ReactNode;
  children: ReactNode;
  [x:string]: any;
};

const ConditionFormItem = ({
  label,
  children,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledContainer {...otherProps}>
      <StyledLabel>{label}</StyledLabel>

      <StyledFlex>
        {children}
      </StyledFlex>
    </StyledContainer>
  );
};

const StyledContainer = styled.div({
  display: "flex",
  alignItems: "center"
});

const StyledLabel = styled.div({
  fontSize: 15,
  color: "#787878",
  width: 120,
  flex: "0 0 120px",
  fontWeight: 700
});

const StyledFlex = styled.div({
  flex: 1
});

export default ConditionFormItem;
