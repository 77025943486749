import React, {
  useState,
  memo,
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
} from "react";
import moment from "moment";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import PeriodFilteringModal from "./PeriodFilteringModal";
import { DataPeriod, DataPeriodType } from "./TotalChargeCard";

type Props = {
  dataPeriod: DataPeriod;
  setDataPeriod: Dispatch<SetStateAction<DataPeriod>>;
};

type Options = {
  value: DataPeriodType;
  label: string;
};

const dataPeriodTypeToPeriod: {
  [key in DataPeriodType]: DataPeriod;
} = {
  today: {
    type: "today",
    from: moment().startOf("day").format("YYYY/MM/DD"),
    to: moment().endOf("day").format("YYYY/MM/DD"),
  },
  yesterday: {
    type: "yesterday",
    from: moment().add(-1, "day").startOf("day").format("YYYY/MM/DD"),
    to: moment().add(-1, "day").endOf("day").format("YYYY/MM/DD"),
  },
  this_week: {
    type: "this_week",
    from: moment().startOf("isoWeek").format("YYYY/MM/DD"),
    to: moment().endOf("isoWeek").format("YYYY/MM/DD"),
  },
  this_month: {
    type: "this_month",
    from: moment().startOf("month").format("YYYY/MM/DD"),
    to: moment().endOf("month").format("YYYY/MM/DD"),
  },
  custom: { type: "custom", from: null, to: null },
};

const options: Options[] = [
  {
    value: "today",
    label: "今日",
  },
  {
    value: "yesterday",
    label: "昨日",
  },
  {
    value: "this_week",
    label: "今週",
  },
  {
    value: "this_month",
    label: "今月",
  },
  {
    value: "custom",
    label: "期間を指定",
  },
];

const PeriodSelectorForm: FC<Props> = ({ setDataPeriod, dataPeriod }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (dataPeriod.type !== "custom" && !dataPeriod.from) {
      setDataPeriod(dataPeriodTypeToPeriod[dataPeriod.type]);
    }
  }, [dataPeriod, setDataPeriod]);

  const onSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    const type = event.target.value;
    setDataPeriod(dataPeriodTypeToPeriod[type]);
    if (type === "custom") setShowModal(true);
  };

  const setCustomDataPeriod = ({ from, to }) =>
    setDataPeriod({ type: "custom", from, to });

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SelectorForm
          style={{ margin: 0 }}
          onSelect={onSelect}
          width="100%"
          height={36}
          value={dataPeriod.type || undefined}
          options={options}
        />
        {["custom", "this_week", "this_month"].includes(dataPeriod.type) && (
          <p style={{ fontSize: 12, color: "#999", marginBottom: 0 }}>
            {dataPeriod.from} - {dataPeriod.to}
          </p>
        )}
      </div>

      <PeriodFilteringModal
        show={showModal}
        onHide={() => setShowModal(false)}
        dataPeriod={dataPeriod}
        setDataPeriod={setCustomDataPeriod}
      />
    </>
  );
};

export default memo(PeriodSelectorForm);
