import React, { useEffect } from "react";
import { Grid, Row, Col, Table, ButtonToolbar } from "react-bootstrap";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { Card } from "../../components/Card/Card";
import OptionButton from "../../components/CustomButton/OptionButton";
import Button from "../../components/CustomButton/CustomButton";
import SmallTableBody from "../../components/Table/SmallTableBody";
import StoreEntityContainer from "../../containers/entities/StoreEntityContainer";
import { isOwner } from "../../lib/staff";

const thArray = [
  { key: "icon", label: "" },
  { key: "storeName", label: "店舗名" },
  { key: "groupName", label: "所属グループ" },
  { key: "status", label: "状態" },
  { key: "option", label: "" },
];

const thSmallArray = [
  { key: "icon", label: "" },
  { key: "storeName", label: "店舗名" },
  { key: "status", label: "状態" },
  { key: "option", label: "" },
];

const GroupView = () => {
  const container = StoreEntityContainer.useContainer();
  const { state, api, setEntityState } = container;
  const { store } = state;
  const { getStore } = api;
  const { reloadCurrentStaff } = useLoginContext();
  const history = useHistory();

  useEffect(() => {
    getStore().then((res) => setEntityState.store(res));
  }, [getStore, setEntityState]);

  const goStoreEdit = (child) => history.push("stores/edit", { storeId: child.id });

  const optionButton = (child) => (
    <OptionButton
      options={[
        store?.store_type !== "owner_group" ? {
          label: "このグループから外す",
          labelColor: "#FF4A55",
          onClick: () =>
            api.deleteChild(store?.id, child.id).then(() => {
              api.getStore().then((res) => setEntityState.store(res));
            }),
        } : isOwner() && {
          label: "削除する",
          labelColor: "#FF4A55",
          onClick: () => {
            if (!window.confirm("店舗を削除しますか？")) {
              return;
            }
            api.updateStore({ status: "archive" }, child.id).then(() => {
              api.getStore().then((res) => setEntityState.store(res));
              reloadCurrentStaff();
            });
          },
        }
      ]}
    />
  );

  return (
    <Grid fluid className="content">
      <Row>
        <Col md={12}>
          {store?.store_type === "owner_group" && isOwner() && (
            <ButtonToolbar style={{ marginBottom: 10 }}>
              <Button
                bsStyle="info"
                fill
                pullRight
                onClick={() => history.push("stores/new")}
              >
                <b>店舗を作成する</b>
              </Button>
            </ButtonToolbar>
          )}
          <Card
            title={
              store?.store_type === "owner_group"
                ? `${store?.name}(オーナー)`
                : store?.name
            }
            category={
              store?.store_type === "owner_group" &&
              "オーナーは全ての店舗が所属するグループです。自動で店舗が追加されます。"
            }
            content={
              <Table striped hover>
                <thead>
                  {/* PC */}
                  <MediaQuery query="(min-width: 767px)">
                    <tr>
                      {thArray.map((head) => (
                        <th key={head.key}>{head.label}</th>
                      ))}
                    </tr>
                  </MediaQuery>
                  {/* スマホ */}
                  <MediaQuery query="(max-width: 766px)">
                    <tr>
                      {thSmallArray.map((head) => (
                        <th key={head.key}>{head.label}</th>
                      ))}
                    </tr>
                  </MediaQuery>
                </thead>
                <tbody>
                  {store?.child_stores
                    ?.filter((child) =>
                      ["open", "close"].includes(child.status)
                    )
                    ?.map((child) => (
                      <tr key={child.id}>
                        <MediaQuery query="(min-width: 767px)">
                          <td width={48}>
                            {child.app_icon_url && (
                              <img
                                key={child.id}
                                alt="店舗画像"
                                src={child.app_icon_url}
                                width={45}
                                height={45}
                                style={{
                                  borderRadius: 2,
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </td>
                          <td
                            style={{ fontWeight: "bold" }}
                            onClick={() => goStoreEdit(child)}
                          >
                            {child.name}
                          </td>
                          <td
                            style={{ fontWeight: "bold" }}
                            onClick={() => goStoreEdit(child)}
                          >
                            {child.parent_stores
                              .map((parent) => parent.name)
                              .join(", ")}
                          </td>
                          <td width={80} onClick={() => goStoreEdit(child)}>
                            <div
                              className={
                                child.status === "open" ? "active" : "closed"
                              }
                            >
                              {child.status === "open" ? "公開" : "非公開"}
                            </div>
                          </td>
                          <td width={50}>
                            {(store?.store_type !== "owner_group" ||
                              isOwner()) &&
                              optionButton(child)}
                          </td>
                        </MediaQuery>
                        <MediaQuery query="(max-width: 766px)">
                          <SmallTableBody
                            title={child.name}
                            contents={[]}
                            image={child.app_icon_url}
                            goTo={() => goStoreEdit(child)}
                            status={
                              <div
                                className={
                                  child.status === "open" ? "active" : "closed"
                                }
                              >
                                {child.status === "open" ? "公開" : "非公開"}
                              </div>
                            }
                            button={
                              (store?.store_type !== "owner_group" ||
                                isOwner()) &&
                              optionButton(child)
                            }
                          />
                        </MediaQuery>
                      </tr>
                    ))}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default withRouter(GroupView);
