import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "@mui/material/styles";
import { OrderStatus, OrderType } from "../../types/order";
import { getOrderStatusLabel } from "../../constants/order";

const ColorsByStatus = {
  unapproved: "#b0b0b0",
  receive: "#ff9933",
  ready: "#4b4b4b",
  wait: "#00ddb4",
  in_delivery: "#00ddb4",
  shipped: "#00ddb4",
  done: "#b0b0b0",
  cancel: "#ff6363"
} as const;

type Props = ComponentPropsWithoutRef<"div"> & {
  orderType: OrderType;
  status: OrderStatus;
};

const OrderStatusChip = ({
  orderType,
  status,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledChip status={status} {...otherProps}>
      {getOrderStatusLabel(orderType, status)}
    </StyledChip>
  );
};

const StyledChip = styled("div")<{
  status: OrderStatus
}>(({ status, theme }) => ({
  backgroundColor: ColorsByStatus[status],
  display: "inline",
  color: "white",
  padding: theme.spacing(0.5, 1),
  border: 0,
  borderRadius: 6,
  fontWeight: "bold",
  fontSize: 10,
  whiteSpace: "nowrap"
}));

export default OrderStatusChip;
