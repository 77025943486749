import React, { useReducer, useState, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { Grid, Row, Col, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { useApi, useBooleanState } from "../../lib/hooks";
import { CouponContentResource } from "../../types/coupon";
import { useNotification } from "../../providers/NotificationProvider";
import { useLoginContext } from "../../providers/LoginContextProvider";
import GuideButton from "../../components/Link/GuideLink";
import CouponCreationConduit from "./parts/CouponCreationConduit";
import PresentCouponList from "./parts/PresentCouponList";
import AutoCouponList from "./parts/AutoCouponList";
import PrivilegeCouponList from "./parts/PrivilegeCouponList";
import ContentCopyDialog from "../../components/CouponContentCopyDialog";
import { AllCouponsAggregationResponse } from "../../types/api/coupon.d";
import PeriodSpecifiableContentSummaryCard from "../../components/Card/PeriodSpecifiableContentSummaryCard";

const CouponListView = (): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const { currentStore } = useLoginContext();
  const couponDeleteApi = useApi();
  const contentCopyApi = useApi();
  const { api: allCouponsAggregationApi, response } = useApi<AllCouponsAggregationResponse>();
  const [couponListReRenderKey, reRenderCouponList] = useReducer(state => state + 1, 1);
  const [isCopyModalOpen, openCopyModal, closeCopyModal] = useBooleanState(false);
  const [copiedCouponContent, setCopiedCouponContent] = useState<CouponContentResource | null>(null);

  const handleClickCopyButton = (couponContent: CouponContentResource) => {
    openCopyModal();
    setCopiedCouponContent(couponContent);
  };

  const copyCouponContent = async (contentId: number, copyStoreIds: number[]) => {
    const path = `/coupon_contents/${contentId}/copies`;
    const params = { store_ids: copyStoreIds };
    const response = await contentCopyApi.api.post(path, params);
    closeCopyModal();
    if (!response) return;

    if (copyStoreIds.some(storeId => storeId !== currentStore.id)) {
      setTimeout(() => {
        alert("他の店舗・グループにクーポンを複製しました。ご確認ください。");
      }, 100);
    } else {
      reRenderCouponList();
      showSuccessNotification("クーポンを複製しました。");
    }
  };

  const deleteCouponContent = async (id: number) => {
    if (!window.confirm("このクーポンを削除しますか？")) return;

    const res = await couponDeleteApi.api.delete(`/coupon_contents/${id}`);
    if (!res) return;

    reRenderCouponList();
    showSuccessNotification("クーポンを削除しました。");
  };

  const getAllCouponsAggregation = useCallback(async (params = {}) => {
    const { fromDate, toDate } = params;
    const query: Record<string, any> = {};

    if (fromDate) query.from = fromDate;
    if (toDate) query.to = toDate;

    await allCouponsAggregationApi.get("/all_coupons_aggregation", query);
  }, [allCouponsAggregationApi]);

  useEffect(() => {
    getAllCouponsAggregation();
  }, [getAllCouponsAggregation]);

  return (
    <div className="content">
      <Grid fluid>
        <Row style={{ marginBottom: 10 }}>
          <StyledToolbarContainer md={12}>
            <GuideButton
              label="クーポン"
              link="https://toypo.notion.site/c9b1ed5e75ef47d6bd6448e9210787c0"
            />

            <ButtonToolbar className="pull-right">
              <DropdownButton
                title={<b>クーポンを作成する</b>}
                className="btn-info btn-fill"
                pullRight
                id="dropdown-menu-list"
              >
                <MenuItem eventKey="1" onClick={() => history.push("coupons/new?type=normal")}>
                  通常配信クーポンを作成する
                </MenuItem>
                <MenuItem divider />
                <MenuItem eventKey="2" onClick={() => history.push("coupons/new?type=auto")}>
                  自動配信クーポンを作成する
                </MenuItem>
                <MenuItem divider />
                <MenuItem
                  eventKey="3"
                  onClick={() => history.push("/admin/privilege_coupon/new?isCode=true")}
                >
                  特殊配信クーポンを作成する
                </MenuItem>
              </DropdownButton>
            </ButtonToolbar>
          </StyledToolbarContainer>
        </Row>

        <CouponCreationConduit
          onCreateCoupon={() => history.push("coupons/new?type=normal")}
          onCreateAutoCoupon={() => history.push("coupons/new?type=auto")}
          onCreatePrivilegeCodeCoupon={() => history.push("/admin/privilege_coupon/new?isCode=true")}
        />

        <PeriodSpecifiableContentSummaryCard
          title={"クーポン集計"}
          onChangePeriod={getAllCouponsAggregation}
          showArrivalCount={currentStore.show_coupon_arrival_count}
          deliveredCount={response?.delivered_count || 0}
          arrivalCount={response?.arrival_count || 0}
          readCount={response?.read_count || 0}
          usedCount={response?.used_count || 0}
          salesEffect={response?.sales_effect || 0}
        />

        <PresentCouponList
          key={`present-${couponListReRenderKey}`}
          onDeleteCouponContent={deleteCouponContent}
          onClickCopyButton={handleClickCopyButton}
          loading={couponDeleteApi.loading}
          showArrivalCount={currentStore.show_coupon_arrival_count}
        />

        <AutoCouponList
          key={`auto-${couponListReRenderKey}`}
          onDeleteCouponContent={deleteCouponContent}
          onClickCopyButton={handleClickCopyButton}
          loading={couponDeleteApi.loading}
          showArrivalCount={currentStore.show_coupon_arrival_count}
        />


        <PrivilegeCouponList
          key={`privilege-${couponListReRenderKey}`}
          onDeleteCouponContent={deleteCouponContent}
          loading={couponDeleteApi.loading}
          showArrivalCount={currentStore.show_coupon_arrival_count}
        />
      </Grid>

      {copiedCouponContent && (
        <ContentCopyDialog
          copiedContentName={copiedCouponContent.benefit}
          contentId={copiedCouponContent.id}
          open={isCopyModalOpen}
          onClose={closeCopyModal}
          onClickCopy={copyCouponContent}
          disabled={contentCopyApi.loading}
        />
      )}
    </div>
  );
};

const StyledToolbarContainer = styled(Col)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: 10,
  alignItems: "center",
  flexDirection: "row",
  [theme.breakpoints.down("laptop")]: {
    alignItems: "flex-end",
    flexDirection: "column"
  }
}));

export default CouponListView;
