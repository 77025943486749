import React from "react";
import { Col, FormGroup, Modal, Radio, Row } from "react-bootstrap";
import Button from "../../../components/CustomButton/CustomButton";
import FormInput from "../../../components/FormInputs/FormInput";
import { IllegalStatus } from "../../../types/activityLog";
import { IllegalStatusLabel } from "../../../constants/activityLog";

interface Props {
  show: boolean;
  onHide: () => void;
  selectedIllegalStatus: IllegalStatus | null;
  illegalStatuses: IllegalStatus[];
  setSelectedIllegalStatus: React.Dispatch<React.SetStateAction<IllegalStatus | null>>;
  onClickUpdateIllegalStatusButton: (doubtfulReasonText: string) => void;
  doubtfulReasonText: string;
  setDoubtfulReasonText: React.Dispatch<React.SetStateAction<string>>;
}

const ActivityLogIllegalStatusChangeModal = ({
  show,
  onHide,
  selectedIllegalStatus,
  illegalStatuses,
  setSelectedIllegalStatus,
  onClickUpdateIllegalStatusButton,
  doubtfulReasonText,
  setDoubtfulReasonText,
}: Props): JSX.Element => (
  <Modal
    show={show}
    onHide={(): void => onHide()}
    onExited={(): void => {
      document.body.style.overflow = "auto";
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title componentClass="h6">
        どの不正ステータスに更新しますか？
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row style={{ marginTop: 10 }}>
        <Col md={12}>
          {illegalStatuses
            .filter((illegalStatues) => illegalStatues !== "not_check")
            .map((status) => (
              <Radio
                key={`subscription-status-change-modal-${status}`}
                name="radioGroup"
                checked={status === selectedIllegalStatus}
                onChange={(): void => setSelectedIllegalStatus(status)}
              >
                <b>{IllegalStatusLabel[status]}</b>
              </Radio>
            ))}
        </Col>
      </Row>
      {(selectedIllegalStatus === "doubtful" ||
        selectedIllegalStatus === "illegal") && (
        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <FormGroup bsSize="large">
              <FormInput
                label="疑わしい理由"
                ncol="col-md-12"
                type="text"
                rows="2"
                componentClass="textarea"
                bsClass="form-control"
                value={doubtfulReasonText}
                onChange={(e) => setDoubtfulReasonText(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button
        bsStyle="info"
        bsSize="small"
        fill
        onClick={(): void => {
          if (
            selectedIllegalStatus === "doubtful" ||
            selectedIllegalStatus === "illegal"
          ) {
            onClickUpdateIllegalStatusButton(doubtfulReasonText);
          } else {
            onClickUpdateIllegalStatusButton("");
          }
        }}
      >
        <b>更新する</b>
      </Button>
    </Modal.Footer>
  </Modal>
);
export default ActivityLogIllegalStatusChangeModal;
