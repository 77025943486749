import React, { FC } from "react";
import CouponCreationCouduitItem from "./CouponCreationCouduitItem";

type Props = {
  onCreateCoupon: () => void;
  onCreateAutoCoupon: () => void;
  onCreatePrivilegeCodeCoupon: () => void;
};

const CouponCreationConduit: FC<Props> = ({
  onCreateCoupon,
  onCreateAutoCoupon,
  onCreatePrivilegeCodeCoupon,
}) => (
  <div
    style={{
      marginBottom: 20,
      display: "flex",
      flexWrap: "wrap",
      gap: "12px 12px",
    }}
  >
    <CouponCreationCouduitItem
      title="通常配信クーポンを作成"
      description="すべてのユーザーや指定したユーザーへ即時に発行できます。"
      onClick={onCreateCoupon}
      icon="fas fa-box"
    />
    <CouponCreationCouduitItem
      title="自動配信クーポンを作成"
      description="再来店後や誕生日など、指定のタイミングでの自動発行や定期発行もできます。"
      onClick={onCreateAutoCoupon}
      icon="fas fa-sync"
    />
    <CouponCreationCouduitItem
      title="特殊配信クーポンを作成"
      description="ユーザーにクーポンQRコードを読み取ってもらうことで配信できるクーポンです。"
      onClick={onCreatePrivilegeCodeCoupon}
      icon="fas fa-qrcode"
    />
  </div>
);

export default CouponCreationConduit;
