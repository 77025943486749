import React, { useEffect, useState } from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useBooleanState, useApi } from "../../../../lib/hooks";
import { CouponContentsResponse } from "../../../../types/api/coupon.d";
import { CouponContentResource } from "../../../../types/coupon";
import { Condition } from "../../type";
import ConditionFormItem from "./ConditionFormItem";
import InstanceItem from "./InstanceItem";
import InstanceFilterDialog from "./InstanceFilterDialog";

type Props = {
  condition: Condition,
  onSelectCouponContent: (couponContent: CouponContentResource) => void;
};

const CouponContentFormItem = ({
  condition,
  onSelectCouponContent
}: Props): JSX.Element => {
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const { api, response, loading } = useApi<CouponContentsResponse>();
  const [filterText, setFilterText] = useState<string>("");
  const couponContents = (response?.coupon_contents || [])
    .map((item) => item.coupon_content)
    .filter(({ benefit }) => !filterText || benefit.indexOf(filterText) > -1);

  useEffect(() => {
    if (isModalOpen) {
      api.get("/coupon_contents", {
        sort: "publish_at",
        order: "DESC",
        with_aggregation: false,
        with_details: false,
        limit: LIMIT
      });
    }
  }, [isModalOpen, api]);

  return (
    <>
      <ConditionFormItem label="クーポン">
        <Select
          readOnly
          fullWidth
          value="default"
          style={{ backgroundColor: "#fff" }}
          sx={{ "& > .MuiSelect-select": { width: 370 } }}
          onClick={openModal}
        >
          <MenuItem value="default">
            {condition.couponContent?.benefit || "すべてのクーポン"}
          </MenuItem>
        </Select>
      </ConditionFormItem>

      <InstanceFilterDialog
        show={isModalOpen}
        loading={loading}
        items={couponContents}
        placeholder="クーポン名を入力して検索..."
        emptyLabel={
          Boolean(filterText)
            ? `${filterText}を含むクーポンが見つかりません。`
            : "配信済みのクーポンがありません。"
        }
        renderItem={(couponContent: CouponContentResource) => (
          <InstanceItem
            key={couponContent.id}
            imageUrl={couponContent.image_url}
            title={couponContent.benefit}
            status={
              <StyledTypeLabel type={couponContent.type}>
                {TypeLabel[couponContent.type]}
              </StyledTypeLabel>
            }
            note={couponContent.type === "PresentCouponContent" && couponContent.status === "publish" ? (
              <>
                配信日: 
                {moment(couponContent.publish_at).format("YYYY/MM/DD HH:mm")}
              </>
            ) : null}
            onClick={() => {
              onSelectCouponContent(couponContent);
              closeModal();
            }}
          />
        )}
        onHide={closeModal}
        onFilter={setFilterText}
      />
    </>
  );
};

const LIMIT = 200;

const LabelColor = {
  PresentCouponContent: "#b0b0b0",
  AutoCouponContent: "#00ddb4",
  PrivilegeCouponContent: "#8577ff"
} as const;
const TypeLabel = {
  PresentCouponContent: "通常クーポン",
  AutoCouponContent: "自動配信クーポン",
  PrivilegeCouponContent: "限定クーポン"
} as const;

const StyledTypeLabel = styled("div")<{ type: string }>(({ type }) => ({
  backgroundColor: LabelColor[type],
  display: "inline-block",
  color: "#fff",
  padding: "4px 8px",
  borderRadius: 6,
  fontWeight: 700
}));

export default CouponContentFormItem;
