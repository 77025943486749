import React from "react";
import { styled } from "@mui/system";
import { AnalyticsReportCategory } from "../types/analyticsReport";
import { CategoryIconMap, CategoryBgColorMap } from "../constants/analyticsReport";

type Props = {
  category: AnalyticsReportCategory;
};

const AnalyticsCategoryIcon = ({
  category
}: Props): JSX.Element => {
  return (
    <StyledContainer category={category}>
      {CategoryIconMap[category]}
    </StyledContainer>
  );
};

const StyledContainer = styled("div")<{
  category: AnalyticsReportCategory
}>(({ category }) => ({
  backgroundColor: CategoryBgColorMap[category],
  display: "inline-flex",
  fontSize: 20,
  width: 48,
  height: 48,
  borderRadius: 48,
  justifyContent: "center",
  alignItems: "center"
}));

export default AnalyticsCategoryIcon;
