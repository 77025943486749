import React, { useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { setViewObj, loadViewObj } from "../../../lib/viewObj";
import Checkbox from "../../CustomCheckbox/CustomCheckbox";

type Props = {
  onClose: () => void;
};

const StoreSelectModal = ({
  onClose
}: Props): JSX.Element => {
  const { currentStaff } = useLoginContext();
  const [selectedViewObj, setSelectedViewObj] = useState(loadViewObj());
  const ownerGroup = currentStaff.groups.find((g) => g.store_type === "owner_group");
  const storeGroups = currentStaff.groups.filter((g) => g.store_type === "store_group");
  const multipleStoresExists = currentStaff.stores.length > 1;

  const handleChange = (e, item) => {
    const type = item.store_type === "child" ? "store" : "group";
    if (e.target.checked) {
      setSelectedViewObj({
        type: type,
        id: item.id,
        name: item.name,
        status: item.status,
        isOwnerGroup: item.store_type === "owner_group",
        role: item.role,
      });
    }
  };

  const handleSubmit = () => {
    if (selectedViewObj) {
      amplitude.track("CHANGE_LOGIN_STORE", { storeId: selectedViewObj.id });
      setViewObj(selectedViewObj);
      window.location.href = "/admin/home";
    }
  };

  return (
    <StyledContainer>
      <StyledHeader>
        管理画面切り替え
      </StyledHeader>

      <StyledContentContainer>
        {multipleStoresExists && ownerGroup && (
          <>
            <StyledSectionTitle>
              オーナー
            </StyledSectionTitle>

            <StoreCheckbox
              showClosedBadge={ownerGroup.status === "close"}
              label={`${ownerGroup.name}(${ownerGroup.stores_count}店舗)`}
              checked={selectedViewObj?.type === "group" && ownerGroup.id === selectedViewObj?.id}
              onChange={(e) => handleChange(e, ownerGroup)}
            />
          </>
        )}

        {multipleStoresExists && storeGroups.length > 0 && (
          <>
            <StyledSectionTitle>
              グループ
            </StyledSectionTitle>

            {storeGroups.map((group) => (
              <StoreCheckbox
                key={group.id}
                showClosedBadge={group.status === "close"}
                label={`${group.name}(${group.stores_count}店舗)`}
                checked={selectedViewObj?.type === "group" && group.id === selectedViewObj?.id}
                onChange={(e) => handleChange(e, group)}
              />
            ))}
          </>
        )}

        {currentStaff.stores.length > 0 && (
          <>
            <StyledSectionTitle>
              店舗
            </StyledSectionTitle>

            {currentStaff.stores.map((store) => (
              <StoreCheckbox
                key={store.id}
                showClosedBadge={store.status === "close"}
                label={store.name}
                checked={selectedViewObj?.type === "store" && store.id === selectedViewObj?.id}
                onChange={(e) => handleChange(e, store)}
              />
            ))}
          </>
        )}
      </StyledContentContainer>

      <StyledActionContainer>
        <Button color="cancel" variant="outlined" onClick={onClose}>
          キャンセル
        </Button>

        <Button color="submit" variant="contained" onClick={handleSubmit}>
          選択する
        </Button>
      </StyledActionContainer>
    </StyledContainer>
  );
};

const StoreCheckbox = ({
  showClosedBadge,
  ...otherProps
}) => (
  <div style={{ marginBottom: 8 }}>
    <Checkbox
      inline
      labelStyle={{ color: "#000", fontSize: 14, fontWeight: "bold" }}
      {...otherProps}
    />

    {showClosedBadge && (
      <StyledBadge>非公開</StyledBadge>
    )}
  </div>
);

const StyledContainer = styled("div")({
  border: "1px solid rgba(0, 0, 0, 0.1)",
  width: window.innerWidth > 991 ? 520 : 260,
  height: 450,
  overflow: "inherit",
  backgroundColor: "#fff",
  borderRadius: 4
});

const StyledHeader = styled("h1")({
  color: "#000",
  fontSize: 20,
  fontWeight: "bold",
  padding: 16,
  margin: 0,
  borderBottom: "solid 1px #eee"
});

const StyledSectionTitle = styled("h2")({
  color: "#000",
  fontSize: 16,
  fontWeight: "bold",
  margin: "24px 0 8px 0"
});

const StyledContentContainer = styled("div")({
  width: "100%",
  height: 320,
  overflowY: "scroll",
  padding: "0 16px",
  borderBottom: "solid 1px #eee"
});

const StyledActionContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: 8,
  padding: 8
});

const StyledBadge = styled("div")({
  backgroundColor: "#4b4b4b",
  color: "white",
  padding: "4px 8px",
  borderRadius: 6,
  fontWeight: "bold",
  display: "inline-block"
});

export default StoreSelectModal;
