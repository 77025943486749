import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router";
import { useGetApiCall, useMobileFlag } from "../../../lib/hooks";
import { NoticeContentResource } from "../../../types/resource/noticeContent.d";
import { CouponContentResource } from "../../../types/coupon";
import { NoticeContentsResponse } from "../../../types/api/notice.d";
import { CouponContentsResponse } from "../../../types/api/coupon.d";
import ContentSummaryCard from "../../../components/Card/ContentSummaryCard";
import HomeSection from "./HomeSection";

type RecentContent = NoticeContentResource | CouponContentResource;

const RecentPublishedContents = (): JSX.Element => {
  const isMobile = useMobileFlag();
  const history = useHistory();
  const recentContents = useRecentContents();
  const columns = isMobile ? 1 : 2;
  const rows = Math.ceil(recentContents.length / columns);

  const goToContentEdit = (params: { category: string; content: RecentContent }) => {
    const url = params.category === "お知らせ"
      ? `notices/edit?notice_content_id=${params.content.id}`
      : `coupons/edit?type=normal&coupon_content_id=${params.content.id}`;

    history.push(url);
  };

  return (
    <HomeSection title="直近の配信">
      {recentContents.length === 0 ? (
        <StyledEmptyContainer>
          お知らせ・クーポンを配信するとここに表示されます
        </StyledEmptyContainer>
      ) : (
        <Box display="grid" gap={1}>
          {[...Array(rows)].map((_, row) => (
            <React.Fragment key={row}>
              <Box width="100%" display="flex">
                {[...Array(columns)].map((_, column) => recentContents[row * columns + column]).map((item, i) => (
                  <Box flex={1} key={i}>
                    {Boolean(item) ? (
                      <StyledContentSummaryCard
                        onClick={() => goToContentEdit({ category: item.label, content: item.content })}
                        imageUrl={item.imageUrl}
                        title={item.title}
                        label={<StyledLabel>{item.label}</StyledLabel>}
                        note={`配信日: ${moment(item.publishAt).format("YYYY/M/D(dddd) HH:mm")}`}
                        deliveredCount={item.deliveredCount}
                        readCount={item.readCount}
                        readCountDiff={item.readCountDiff}
                        createdAt={item.createdAt}
                        memo={item.memo}
                      />
                    ) : null}
                  </Box>
                ))}
              </Box>

              {row < rows - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      )}
    </HomeSection>
  );
};

const NUM_OF_LATEST_CONTENTS = 4;

const useRecentContents = () => {
  const noticeUrl = "/notice_contents?type=normal&status=publish&sort=publish_at&order=desc&limit=2";
  const couponUrl = "/coupon_contents?type=present&status=publish&sort=publish_at&order=desc&limit=2";
  const { response: noticeContentsResponse } = useGetApiCall<NoticeContentsResponse>(noticeUrl);
  const { response: couponContentsResponse } = useGetApiCall<CouponContentsResponse>(couponUrl);
  const notices = noticeContentsResponse?.notice_contents || [];
  const coupons = couponContentsResponse?.coupon_contents || [];

  const formattedNotices = notices.map((notice) => ({
    id: notice.notice_content.id,
    imageUrl: notice.notice_content.image_urls[0] || "",
    title: notice.notice_content.title,
    label: "お知らせ",
    publishAt: notice.notice_content.publish_at || "",
    deliveredCount: notice.delivered_count,
    readCount: notice.read_count,
    readCountDiff: notice.read_count_diff,
    content: notice.notice_content,
    createdAt: notice.notice_content.created_at,
    memo: notice.notice_content.memo || ""
  }));
  const formattedCoupons = coupons.map((coupon) => ({
    id: coupon.coupon_content.id,
    imageUrl: coupon.coupon_content.image_url,
    title: coupon.coupon_content.benefit,
    label: "クーポン",
    publishAt: coupon.coupon_content.publish_at || "",
    deliveredCount: coupon.delivered_count,
    readCount: coupon.read_count,
    readCountDiff: coupon.read_count_diff,
    usedCount: coupon.used_count,
    usedCountDiff: coupon.used_count_diff,
    salesEffect: coupon.sales_effect,
    content: coupon.coupon_content,
    createdAt: coupon.coupon_content.created_at,
    memo: coupon.coupon_content.memo || ""
  }));

  return [...formattedNotices, ...formattedCoupons]
    .sort((a, b) => (a.publishAt > b.publishAt ? -1 : 1))
    .slice(0, NUM_OF_LATEST_CONTENTS);
};

const StyledEmptyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(6, 2),
  justifyContent: "center",
  alignItems: "center"
}));

const StyledContentSummaryCard = styled(ContentSummaryCard)({
  padding: 16,
  cursor: "pointer",
  "&:hover": { opacity: 0.75 }
});

const StyledLabel = styled("div")({
  fontSize: 10,
  fontWeight: "bold",
  color: "#858585",
  backgroundColor: "#F7F7F8",
  borderRadius: 2,
  padding: 3,
  textAlign: "center"
});

export default RecentPublishedContents;
