import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { Table } from "react-bootstrap";
import { NoticeContentBlock } from "../../../types/api/notice.d";
import { SortOrderType } from "../../../types/common.d";
import { NoticeSortByType } from "../../../types/notice.d";
import {
  NoticeStatusType,
  NoticeStatus,
  NoticeStatusColors,
  NoticeContentStatusToJpObj,
} from "../../../containers/entities/NoticeEntityContainer";
import ContentMeasurementValueAndDiff from "../../../components/Utils/ContentMeasurementValueAndDiff";
import OptionButton from "../../../components/CustomButton/OptionButton";
import SortIconTh from "../../../components/Table/SortIconTh";

const StyledStatusLabel = styled.div<{ status: NoticeStatusType }>`
  background-color: ${(props) => NoticeStatusColors[props.status]};
  display: inline;
  color: white;
  padding: 5px 6px;
  border: 0px;
  border-radius: 6px;
  font-weight: bold;
`;

type Props = {
  noticeContents: NoticeContentBlock[];
  goToEdit: (id: number) => void;
  deleteNoticeContent: (id: number) => Promise<void>;
  sortBy: NoticeSortByType;
  sortOrder: SortOrderType;
  onSort: (sortBy: NoticeSortByType, sortOrder: SortOrderType) => void;
  showArrivalCount: boolean;
};

const NoticeTable = ({
  noticeContents,
  goToEdit,
  deleteNoticeContent,
  sortBy,
  sortOrder,
  onSort,
  showArrivalCount
}: Props): JSX.Element => (
  <Table data-cy="notice-table" striped hover>
    <thead>
      <tr>
        <th> </th>
        <th>タイトル</th>
        <th>配信</th>
        {showArrivalCount && (<th>到達</th>)}
        <th>開封</th>
        <th>配信状態</th>
        <SortIconTh
          data-cy="notice-table-header-publish-at"
          sortOrder={sortBy === "publish_at" ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) => onSort("publish_at", newSortOrder)}
        >
          配信日時/配信予定日時
        </SortIconTh>
        <th> </th>
      </tr>
    </thead>
    <tbody>
      {noticeContents.map(
        ({
          notice_content,
          delivered_count,
          read_count,
          read_count_diff,
          arrival_count
        }) => (
          <tr style={{ cursor: "pointer" }} key={notice_content.id}>
            <td onClick={() => goToEdit(notice_content.id)}>
              {notice_content.image_urls[0] && (
                <img
                  key={notice_content.id}
                  alt="お知らせ画像"
                  src={notice_content.image_urls[0]}
                  width={48}
                  height={30}
                  style={{
                    borderRadius: 2,
                    objectFit: "cover",
                  }}
                />
              )}
            </td>
            <td style={{ width: 350 }} onClick={() => goToEdit(notice_content.id)}>
                <div><b>{notice_content.title}</b></div>
                {notice_content.memo && (
                  <StyledMemo>{notice_content.memo}</StyledMemo>
                )}
            </td>
            <td onClick={() => goToEdit(notice_content.id)}>
              {notice_content.status === NoticeStatus.Publish ? delivered_count : "---"}
            </td>
            {showArrivalCount && (
              <td onClick={() => goToEdit(notice_content.id)}>
                {notice_content.status === NoticeStatus.Publish ? arrival_count : "---"}
              </td>
            )}
            <td onClick={() => goToEdit(notice_content.id)}>
              {notice_content.status === NoticeStatus.Publish ? (
                <ContentMeasurementValueAndDiff
                  value={read_count}
                  diff={read_count_diff}
                  createdAt={notice_content.created_at}
                />
              ) : "---"}
            </td>
            <td onClick={() => goToEdit(notice_content.id)}>
              <StyledStatusLabel
                data-cy={`notice-list-status-label-${notice_content.id}`}
                status={notice_content.status}
              >
                {NoticeContentStatusToJpObj[notice_content.status]}
              </StyledStatusLabel>
            </td>
            <td onClick={() => goToEdit(notice_content.id)}>
              {notice_content.publish_at
                ? moment(notice_content.publish_at).format("YYYY/M/D(dddd) HH:mm")
                : "---"}
            </td>
            <td>
              {delivered_count === 0 && (
                <OptionButton
                  data-cy={`notice-list-option-button-${notice_content.id}`}
                  options={[
                    {
                      "data-cy": `notice-list-delete-button-${notice_content.id}`,
                      label: "削除",
                      labelColor: "#FF4A55",
                      onClick: () => deleteNoticeContent(notice_content.id)
                    },
                  ]}
                />
              )}
            </td>
          </tr>
        )
      )}
    </tbody>
  </Table>
);

const StyledMemo = styled("div")({
  fontSize: 12,
  color: "#787878"
});

export default NoticeTable;
