// DONE: refact

import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { serviceContentApi } from "../../lib/api";

export interface ServiceContentInterface {
  id: number;
  title: string;
  body: string;
  status: ServiceContentStatus;
  image_url: string;
  service_type: ServiceType | null;
  service_value: number | null;
  usable_count: number | null;
  monthly_usable_count: number | null;
  daily_usable_count: number | null;
}
export type ServiceContentStatus = "draft" | "open" | "close";
export type ServiceType = "discount" | "free" | "other";
export type ServiceTypeToJp = "円引き" | "無料交換" | "その他";

const statuses: ServiceContentStatus[] = ["draft", "open", "close"];
const statusesToJpObj = {
  draft: "下書き",
  open: "公開",
  close: "非公開",
};
const serviceTypes: ServiceType[] = [
  "discount",
  "free",
  "other",
];
const serviceTypeToJpObj = {
  discount: "円引き",
  free: "無料交換",
  other: "その他",
} as const;

const useServiceContentEntityHook = () => {
  const [serviceContents, setServiceContents] = useState<
    ServiceContentInterface[]
  >([]);
  const [allServiceContents, setAllServiceContents] = useState<
    ServiceContentInterface[]
  >([]);

  const updateServiceContentState = useCallback(
    (newServiceContent) => {
      const index = serviceContents.findIndex(
        (serviceContent) => serviceContent.id === newServiceContent.id
      );
      setServiceContents([
        ...serviceContents.slice(0, index),
        newServiceContent,
        ...serviceContents.slice(index + 1),
      ]);
    },
    [serviceContents]
  );

  const addServiceContentState = useCallback(
    (newServiceContent) => {
      setServiceContents([newServiceContent, ...serviceContents]);
    },
    [serviceContents]
  );

  return {
    state: { serviceContents, allServiceContents },
    constant: { statuses, serviceTypes },
    logic: {
      getServiceContents: async ({
        rankId,
      }): Promise<ServiceContentInterface[]> => {
        const sc = await serviceContentApi.getServiceContents({ rankId });
        setServiceContents(sc);
        return sc;
      },
      getAllServiceContents: async (): Promise<ServiceContentInterface[]> => {
        const sc = await serviceContentApi.getAllServiceContents();
        setAllServiceContents(sc);
        return sc;
      },
      createServiceContent: async ({
        params,
        rankId,
      }): Promise<ServiceContentInterface> => {
        const sc = await serviceContentApi.createServiceContent({
          params,
          rankId,
        });
        addServiceContentState(sc);
        return sc;
      },
      updateServiceContent: async ({
        params,
        id,
      }): Promise<ServiceContentInterface> => {
        const sc = await serviceContentApi.updateServiceContent({
          params,
          id,
        });
        updateServiceContentState(sc);
        return sc;
      },
      deleteServiceContent: async (id): Promise<{ message: string }> => {
        const sc = await serviceContentApi.deleteServiceContent(id);
        return sc;
      },
      statusesToJp: (status): ServiceContentStatus => statusesToJpObj[status],
      serviceTypeToJp: (serviceType: string): ServiceTypeToJp =>
        serviceTypeToJpObj[serviceType],
    },
  };
};

export default createContainer(useServiceContentEntityHook);
