import React, { useState, useCallback } from "react";
import { withRouter } from "react-router";
import { Grid } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import FeatureChoiceViewContainer from "../../containers/views/FeatureChoiceViewContainer";
import FunctionDetailModal from "./parts/FunctionDetailModal";
import { loadViewObj } from "../../lib/viewObj";
import { useLoginContext } from "../../providers/LoginContextProvider";
import Button from "../../components/CustomButton/CustomButton";
import FeaturesBlock from "./parts/FeauresBlock";
import { useSatisfactionFeatures } from "./hooks";

const FeatureChoiceWrapper = () => {
  return (
    <FeatureChoiceViewContainer.Provider>
      <FeatureChoice />
    </FeatureChoiceViewContainer.Provider>
  );
};

type Feature =
  | "notice"
  | "coupon"
  | "menu"
  | "stamp_card"
  | "members_card"
  | "ticket"
  | "takeout"
  | "preorder"
  | "delivery"
  | "banner"
  | "subscription"
  | "calendar"
  | "ec";

interface SelectModal {
  selectActive: boolean;
  active: boolean;
  type: Feature | string;
  updateFeature: any;
}

type FeatureType = "distribution" | "optimization" | "visit" | "default";

const FeatureChoice = (): JSX.Element => {
  const { currentStore } = useLoginContext();
  const activeFunction = currentStore.active_function;
  const featureChoiceViewContainer = FeatureChoiceViewContainer.useContainer();
  const { logic } = featureChoiceViewContainer;
  const features = logic.createFeature();
  const satisfactionFeatures = useSatisfactionFeatures();

  const fliterFeatures = (text: FeatureType) =>
    features
      .filter(
        (feature) =>
          feature.type.includes(text) &&
          !feature.disabled &&
          feature.excludeType !== loadViewObj()?.type
      )
      .map((item) => {
        const feature = item;
        feature.active = activeFunction && activeFunction[item.function];
        return feature;
      });

  const minWidth1000px: boolean = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  // ウォークスルー画面かどうか
  const isWalkThrough: boolean = window.location.pathname.includes("welcome");
  const isWebView = useCallback(() => {
    const ua = navigator.userAgent;
    return ua.indexOf("webView") >= 0;
  }, []);

  const [featureModal, setFeatureModal] = useState<boolean>(false);
  const [selectModal, setSelectModal] = useState<SelectModal>();

  return (
    <div className="content">
      <Grid fluid style={{ display: "flex", flexDirection: "column" }}>
        {isWalkThrough && (
          <div style={{ textAlign: "center", marginBottom: 40 }}>
            <div style={{ fontWeight: "bold", fontSize: 18 }}>
              利用する機能を選択してはじめましょう
            </div>

            <div style={{ marginTop: 10 }}>
              <div>最初に利用する機能を選択しましょう。</div>
              <div>機能は後からいつでも有効や無効に変更できます。</div>
            </div>
          </div>
        )}
        <FeaturesBlock
          sectionTitle="再来店の促進に"
          features={fliterFeatures("visit")}
          setFeatureModal={setFeatureModal}
          setSelectModal={setSelectModal}
          isWalkThrough={isWalkThrough}
          minWidth1000px={minWidth1000px}
        />
        <FeaturesBlock
          sectionTitle="店舗情報の配信に"
          features={fliterFeatures("distribution")}
          setFeatureModal={setFeatureModal}
          setSelectModal={setSelectModal}
          isWalkThrough={isWalkThrough}
          minWidth1000px={minWidth1000px}
        />
        <FeaturesBlock
          sectionTitle="モバイルオーダーでお店をもっと便利に"
          features={fliterFeatures("optimization")}
          setFeatureModal={setFeatureModal}
          setSelectModal={setSelectModal}
          isWalkThrough={isWalkThrough}
          minWidth1000px={minWidth1000px}
        />
        <FeaturesBlock
          sectionTitle="顧客満足度の調査・分析に"
          features={satisfactionFeatures}
          setFeatureModal={setFeatureModal}
          setSelectModal={setSelectModal}
          isWalkThrough={isWalkThrough}
          minWidth1000px={minWidth1000px}
        />

        {!isWebView() && isWalkThrough && (
          <Button
            style={{ margin: "70px auto 0", width: "100%", maxWidth: 400 }}
            fill
            bsStyle="info"
            onClick={(): void => {
              window.location.href = "/admin/home";
            }}
          >
            <b>選択した機能ではじめる</b>
          </Button>
        )}

        {/* 詳細モーダル */}
        <FunctionDetailModal
          selectActive={selectModal?.selectActive}
          active={selectModal?.active}
          feature={selectModal?.type}
          onClickAddFeature={() => selectModal?.updateFeature(true, !isWalkThrough)}
          onClickRemoveFeature={() => selectModal?.updateFeature(false, !isWalkThrough)}
          show={featureModal}
          onHide={() => setFeatureModal(false)}
        />
      </Grid>
    </div>
  );
};

export default withRouter(FeatureChoiceWrapper);
