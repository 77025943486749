// DONE: refact

import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { membersCardContentApi, membersCardRankApi } from "../../lib/api";
import { PrivilegeCouponType } from "./PrivilegeCouponContentEntityContainer";

export type Status = "draft" | "open" | "close";

export type RankUpMethod =
  | "old_rank_up_by_point"
  | "rank_up_by_qrcode"
  | "rank_up_by_check_in"
  | "rank_up_by_payment"
  | "rank_up_by_point";

type RankUpMethodToJp = "QRコード" | "チェックイン" | "お会計" | "獲得ポイント";

export interface MembersCardContentInterface {
  id: number;
  store_id: number;
  active: boolean;
  member_code_prefix: string;
  display_store_name: string;
  how_to_get: string | null;
  usage_text: string | null;
  point_enabled: boolean;
  rank_enabled: boolean;
  default_rank_up_method: RankUpMethod;
  is_integrated_phone_number: boolean;
  rank_value_expiration_month: number | null;
  check_in_point_enabled: boolean;
  check_in_point: number;
  payment_point_type: PaymentPointType;
  payment_point_amount: number | null;
  point_rate: number | null;
  point_expiration_month: number | null;
  created_at: Date;
  members_card_ranks: MembersCardRankInterface[];
  point_qrcodes: PointQrCodeInterface[];
  status: Status;
  point_coupon_contents: PrivilegeCouponType[];
}

export interface MembersCardRankInterface {
  id: number;
  description: string;
  members_card_content_id: number;
  status: MembersCardRankStatus;
  name: string;
  display_name: string;
  need_value: number;
  point_enabled: boolean;
  check_in_point: number | null;
  payment_point_amount: number | null;
  point_rate: number | null;
  color: ColorType;
  is_default_rank: boolean;
  created_at: Date;
  rank_qrcodes: QrCodeInterface[];
  expiration_month: number | null;
  rank_coupon_contents: PrivilegeCouponType[];
}

export interface PointQrCodeInterface {
  category: string;
  created_at: string;
  id: number;
  image_url: string;
  name: string;
  point: number;
}

interface QrCodeInterface {
  category: string;
  created_at: string;
  id: number;
  image_url: string;
  name: string;
}

interface MembersCardContentParamsInterface {
  active?: boolean;
  member_code_prefix?: string;
  display_store_name?: string;
  point_enabled?: boolean;
  check_in_point_enable?: boolean;
  check_in_point?: number;
  payment_point_type?: PaymentPointType;
  payment_point_amount?: number;
  point_expiration_month?: number;
  status?: Status;
}

interface MembersCardRankParamsInterface {
  status?: MembersCardRankStatus;
  name?: string;
  display_name?: string;
  need_point?: number;
  rank_up_method?: RankUpMethod;
  color?: ColorType;
  is_default_rank?: boolean;
}

interface MembersCardParamsInterface {
  point?: number;
  member_code?: string;
}

export type PaymentPointType =
  | "disable"
  | "only_toypo_payment"
  | "only_store_payment"
  | "everything";
const paymentPoints: PaymentPointType[] = [
  "disable",
  "only_toypo_payment",
  "only_store_payment",
  "everything",
];
const paymentPointsToJpObj = {
  disable: "無効",
  only_toypo_payment: "トイポ内決済のみ",
  only_store_payment: "店舗決済のみ",
  everything: "すべて有効",
};

export type MembersCardRankStatus = "open" | "close";
const statuses: MembersCardRankStatus[] = ["open", "close"];
// 会員ランクのステータス
const membersCardRankStatusToJpObj = {
  open: "公開",
  close: "非公開",
};

export type MembersCardStatusType = "draft" | "open" | "close";
const membersCardStatuses: MembersCardStatusType[] = ["draft", "open", "close"];
// 会員カード自体のステータス
const membersCardStatusesToJpObj = {
  draft: "下書き",
  open: "公開",
  close: "非公開",
};

export type ColorType =
  | "blue"
  | "turquoise_blue"
  | "red"
  | "green"
  | "yellow"
  | "bronze"
  | "silver"
  | "light_gray"
  | "gold"
  | "white"
  | "black";

export const membersCardRankColorCode = {
  blue: "#005CFF",
  turquoise_blue: "#55CCDC",
  red: "#FF0000",
  green: "#4EE671",
  yellow: "#FFFF27",
  bronze: "#FF6927",
  silver: "#B9B9B9",
  light_gray: "#E8E8E8",
  gold: "#CBAC2C",
  white: "#fff",
  black: "#000",
};

export type FontColorType = "light_blue" | "white" | "black";
const fontColors = ["light_blue", "white", "black"];

const rankUpMethods: RankUpMethod[] = [
  "rank_up_by_qrcode",
  "rank_up_by_check_in",
  "rank_up_by_payment",
  "rank_up_by_point",
];

const rankUpMethodsToJpObj: {
  rank_up_by_qrcode: "QRコード";
  rank_up_by_check_in: "チェックイン";
  rank_up_by_payment: "お会計";
  rank_up_by_point: "獲得ポイント";
} = {
  rank_up_by_qrcode: "QRコード",
  rank_up_by_check_in: "チェックイン",
  rank_up_by_payment: "お会計",
  rank_up_by_point: "獲得ポイント",
};

type MembersCardContentEntity = {
  state: {
    membersCardContent: MembersCardContentInterface | undefined;
    membersCardRanks: MembersCardRankInterface[];
  };
  constant: {
    statuses: MembersCardRankStatus[];
    membersCardStatuses: MembersCardStatusType[];
    paymentPoints: PaymentPointType[];
    fontColors: string[];
    rankUpMethods: RankUpMethod[];
  };
  logic: {
    getMembersCardContent: () => Promise<MembersCardContentInterface>;
    updateMembersCardContent: (
      params: MembersCardContentParamsInterface
    ) => Promise<MembersCardContentInterface | null>;
    getMembersCardRank: (id: number) => Promise<MembersCardRankInterface>;
    createMembersCardRank: (
      params: MembersCardRankParamsInterface
    ) => Promise<MembersCardRankInterface | null>;
    updateMembersCardRank: (
      id: number,
      params: MembersCardRankParamsInterface
    ) => Promise<MembersCardRankInterface | null>;
    updateMembersCard: (
      id: number,
      params: MembersCardParamsInterface
    ) => Promise<void>;
    membersCardRankStatusToJp: (status: MembersCardRankStatus) => string;
    membersCardStatusesToJp: (status: MembersCardStatusType) => string;
    paymentPointsToJp: (paymentPoint: PaymentPointType) => string;
    rankUpMethodsToJp: (method: RankUpMethod) => RankUpMethodToJp;
  };
};

const useMembersCardContentEntityHook = (): MembersCardContentEntity => {
  const [membersCardContent, setMembersCardContent] =
    useState<MembersCardContentInterface>();
  const [membersCardRanks, setMembersCardRanks] = useState<
    MembersCardRankInterface[]
  >([]);

  const setMembersCardRank = useCallback((newRank) => {
    setMembersCardRanks((currentMembersCardRanks) => {
      const index = currentMembersCardRanks.findIndex(
        (rank) => rank.id === newRank.id
      );

      return [
        ...currentMembersCardRanks.slice(0, index),
        newRank,
        ...currentMembersCardRanks.slice(index + 1),
      ];
    });
  }, []);

  return {
    state: {
      membersCardContent,
      membersCardRanks,
    },
    constant: {
      statuses,
      membersCardStatuses,
      paymentPoints,
      fontColors,
      rankUpMethods,
    },
    logic: {
      getMembersCardContent:
        useCallback(async (): Promise<MembersCardContentInterface> => {
          const mcc: MembersCardContentInterface =
            await membersCardContentApi.getMembersCardContent();
          setMembersCardContent(mcc);
          return mcc;
        }, []),
      updateMembersCardContent: async (
        params: MembersCardContentParamsInterface
      ): Promise<MembersCardContentInterface | null> => {
        const mcc: MembersCardContentInterface =
          await membersCardContentApi.updateMembersCardContent({
            params: params,
          });
        setMembersCardContent(mcc);
        return mcc;
      },
      getMembersCardRank: useCallback(
        async (id: number): Promise<MembersCardRankInterface> => {
          const rank: MembersCardRankInterface =
            await membersCardRankApi.getMembersCardRank(id);
          setMembersCardRank(rank);
          return rank;
        },
        [setMembersCardRank]
      ),
      createMembersCardRank: async (
        params: MembersCardRankParamsInterface
      ): Promise<MembersCardRankInterface | null> => {
        const rank: MembersCardRankInterface =
          await membersCardRankApi.createMembersCardRank({
            params: params,
          });
        setMembersCardRank(rank);
        return rank;
      },
      updateMembersCardRank: async (
        id: number,
        params: MembersCardRankParamsInterface
      ): Promise<MembersCardRankInterface | null> => {
        const rank: MembersCardRankInterface =
          await membersCardRankApi.updateMembersCardRank({
            id: id,
            params: params,
          });
        setMembersCardRank(rank);
        return rank;
      },
      updateMembersCard: async (
        id: number,
        params: MembersCardParamsInterface
      ): Promise<void> => {
        await membersCardContentApi.updateMembersCard({ id, params });
      },
      membersCardRankStatusToJp: (status: MembersCardRankStatus): string =>
        membersCardRankStatusToJpObj[status],
      membersCardStatusesToJp: (status: MembersCardStatusType): string =>
        membersCardStatusesToJpObj[status],
      paymentPointsToJp: (paymentPoint: PaymentPointType): string =>
        paymentPointsToJpObj[paymentPoint],
      rankUpMethodsToJp: (method: RankUpMethod): RankUpMethodToJp =>
        rankUpMethodsToJpObj[method],
    },
  };
};

export default createContainer(useMembersCardContentEntityHook);
