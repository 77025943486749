import React, { useEffect, useCallback } from "react";
import moment from "moment";
import { Pagination } from "@mui/material";
import { Bell } from "phosphor-react";
import { Table } from "react-bootstrap";
import { NoticesResponse } from "../../../types/api/notice.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import CustomButton from "../../../components/CustomButton/CustomButton";
import SectionTitle from "./SectionTitle";
import NoItemContent from "../../../components/Utils/NoItemContent";

type Props = {
  userId: number;
  onClickCreateNotice: () => void;
};

const Notice = ({ userId, onClickCreateNotice }: Props) => {
  const isPcOrTablet = usePcSizeFlag();

  const { api: noticesGetApi, response: noticesResponse, headers: noticesResHeaders } = useApi();

  const getNotices = useCallback((page: number = 1) => {
    noticesGetApi.get(`/users/${userId}/notices`, {
      page,
      limit: 5,
    });
  }, [noticesGetApi, userId]);

  useEffect(() => {
    getNotices();
  }, [getNotices]);

  const notices: Array<NoticesResponse> = noticesResponse?.notices || [];

  if (notices.length === 0) {
    return (
      <div>
        <SectionTitle
          style={{ marginBottom: 12 }}
          title={<b>配信済みのお知らせ</b>}
          element={
            <CustomButton bsStyle="info" fill pullRight onClick={onClickCreateNotice}>
              <b>お知らせを作成</b>
            </CustomButton>
          }
        />

        <NoItemContent label="配信済みのお知らせはありません" icon={<Bell />} />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle
        style={{ marginBottom: 12 }}
        title={<b>配信済みのお知らせ</b>}
        element={
          <CustomButton bsStyle="info" fill pullRight onClick={onClickCreateNotice}>
            <b>お知らせを作成</b>
          </CustomButton>
        }
      />

      {isPcOrTablet ? (
        <Table striped hover>
          <thead>
            <tr>
              {["タイトル", "開封状況", "配信日時"].map((prop) => (
                <th key={prop}>{prop}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {notices.map(({ notice, notice_content, store }) => (
              <tr key={notice.id}>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {notice_content.image_urls && notice_content.image_urls.length > 0 && (
                      <img
                        key={notice_content.id}
                        alt="お知らせ画像"
                        src={notice_content.image_urls[0]}
                        width={48}
                        height={30}
                        style={{
                          borderRadius: 2,
                          objectFit: "cover",
                          marginRight: 8,
                        }}
                      />
                    )}

                    <div>
                      <div style={{ fontWeight: "bold" }}>{notice_content.title}</div>
                      <div style={{ color: "#9A9A9A", fontSize: 12 }}>{store.name}</div>
                    </div>
                  </div>
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {notice.is_read ? (
                    <div style={{ fontWeight: "bold", color: "#00ddb4" }}>開封済み</div>
                  ) : (
                    <div style={{ fontWeight: "bold", color: "#888888" }}>未開封</div>
                  )}
                </td>
                <td>
                  {notice.created_at
                    ? moment(notice.created_at).format("YYYY/M/D(dddd) HH:mm")
                    : "----"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table striped hover>
          <tbody>
            {notices.map(({ notice, notice_content, store }) => (
              <tr key={notice.id}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      gap: 16,
                      alignItems: "flex-start",
                    }}
                  >
                    {notice_content.image_urls && notice_content.image_urls.length > 0 && (
                      <img
                        key={notice_content.id}
                        alt="お知らせ画像"
                        src={notice_content.image_urls[0]}
                        width={48}
                        height={30}
                        style={{
                          borderRadius: 2,
                          objectFit: "cover",
                          marginRight: 8,
                        }}
                      />
                    )}

                    <div>
                      <div style={{ marginBottom: 4 }}>
                        <div style={{ fontWeight: "bold" }}>{notice_content.title}</div>
                        <div
                          style={{
                            fontWeight: "normal",
                            color: "#9a9a9a",
                            fontSize: 12,
                          }}
                        >
                          {store.name}
                        </div>
                      </div>

                      <div style={{ color: "#7d7d7d" }}>
                        開封状況{" "}
                        {notice.is_read ? (
                          <span style={{ fontWeight: "bold", color: "#00ddb4" }}>開封済み</span>
                        ) : (
                          <span style={{ fontWeight: "bold", color: "#888888" }}>未開封</span>
                        )}
                      </div>
                      <div style={{ color: "#7d7d7d" }}>
                        配信日時{" "}
                        {notice.created_at
                          ? moment(notice.created_at).format("YYYY/M/D(dddd) HH:mm")
                          : "----"}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {Number(noticesResHeaders["total-pages"]) > 1 && (
        <Pagination
          count={Number(noticesResHeaders["total-pages"]) || 1}
          page={Number(noticesResHeaders["current-page"]) || 1}
          onChange={(_, page) => getNotices(page)}
          style={{ paddingBottom: 8, borderBottom: "1px solid #E8E8E8" }}
          sx={{ fontSize: 24, color: "black" }}
        />
      )}
    </div>
  );
};

export default Notice;
