import React from "react";
import { MenuResource } from "../../../../types/menu";
import DateOptionFormItem from "../parts/DateOptionFormItem";
import ValueRangeFormItem from "../parts/ValueRangeFormItem";
import MenuFormItem from "../parts/MenuFormItem";
import { Condition, ConditionDiff } from "../../type";

type Props = {
  condition: Condition,
  onChange: (diff: ConditionDiff) => void;
};

const TakeoutOrderCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <>
      <MenuFormItem
        style={{ marginTop: 8 }}
        condition={condition}
        onSelectMenu={(menu: MenuResource) => {
          onChange({ instance_id: menu.id, menu });
        }}
      />

      <ValueRangeFormItem
        style={{ marginTop: 8 }}
        label="注文回数"
        unit="回"
        zeroLabel="注文したことがない"
        from={condition.value_from}
        to={condition.value_to}
        onChange={(value_from, value_to) => onChange({ value_from, value_to })}
      />

      <DateOptionFormItem
        style={{ marginTop: 8 }}
        label="注文日"
        condition={condition}
        onChange={(scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to) => {
          onChange({ scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to });
        }}
      />
    </>
  );
};

export default TakeoutOrderCondition;
