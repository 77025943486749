import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "../../../components/DialogTitle";
import { open as openUrl } from "../../../lib/general";

const INQUIRY_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSf1GAQFktVkz35n0Crv_XGE_jGeyRQKA-pmoYbGb8uINaANiA/viewform?usp=sf_link";
const INQUIRY_PHONE_NUMBER = "0800-300-4415";

type Props = {
  open: boolean;
  onClose: () => void;
};

const OrderInquiryModal = ({
  open,
  onClose
}: Props): JSX.Element => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle onClose={onClose}>
        お問い合わせ
      </DialogTitle>

      <DialogContent>
        <Box mb={1}>
          配達に関するお問い合わせは以下にご連絡ください。<br />
          Uberサポート営業時間：8:00-23:00
          </Box>

        <Box fontSize={20} fontWeight={700} mb={2}>
          {INQUIRY_PHONE_NUMBER}
        </Box>

        <Box mb={1}>一部返金などその他お問い合わせは以下のフォームよりご連絡ください。</Box>

        <Button onClick={() => openUrl(INQUIRY_FORM_URL)}>
          お問い合わせフォーム
        </Button>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="cancel"
          fullWidth
          onClick={onClose}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderInquiryModal;
