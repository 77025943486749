import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Menu, MenuItem } from "@mui/material";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { MenuResource, MenuType } from "../../types/menu";
import GuideButton from "../../components/Link/GuideLink";
import OptionTemplateList from "./parts/OptionTemplateList";
import MenuListTable from "./parts/MenuListTable";
import { useApi, useBooleanState, useQuery } from "../../lib/hooks";
import { downloadCSVFile } from "../../lib/general";
import MenuCsvImportDialog from "./parts/MenuCsvImportDialog";
import OptionCsvImportDialog from "./parts/OptionCsvImportDialog";

const MenuView = (): JSX.Element => {
  const history = useHistory();
  const { pageType, menuTypes } = useMenuTypes();
  const query = useQuery();
  const csvApi = useApi();
  const { currentStaff } = useLoginContext();

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [isCsvMenuImportDialogOpen, openCsvMenuImportDialog, closeCsvMenuImportDialog] = useBooleanState(false);

  const [optionAnchorElement, setOptionAnchorElement] = useState<HTMLElement | null>(null);
  const [isCsvOptionImportDialogOpen, openCsvOptionImportDialog, closeCsvOptionImportDialog] = useBooleanState(false);

  const tabFromQuery = query.get("menu_type") as MenuType;
  const menuType = menuTypes.includes(tabFromQuery) ? tabFromQuery : menuTypes[0];

  const menuHandler = (fn: () => void) => () => {
    fn();
    setAnchorElement(null);
  };

  const goToNewMenu = () => {
    pageType === "mobile_order"
      ? history.push(`/admin/order/menu/new`, { function: "order" })
      : pageType === "ec_menu"
      ? history.push(`/admin/ec/menu/new`, { function: "ec" })
      : history.push(`/admin/menu/new`)
  };

  const goToDetail = (menu: MenuResource) => {
    pageType === "mobile_order"
      ? history.push(`/admin/order/menu/edit`, { menu, function: "order" })
      : pageType === "ec_menu"
      ? history.push(`/admin/ec/menu/edit`, { menu, function: "ec" })
      : history.push(`/admin/menu/edit`, { menu })
  };

  const exportMenuCsv = async () => {
    const params = { menu_type: menuType };
    const response = await csvApi.api.blob("/menus.csv", params);
    downloadCSVFile(response);
  };

  const exportOptionTemplateCsv = async () => {
    const response = await csvApi.api.blob("/option_templates.csv");
    downloadCSVFile(response);
  };

  return (
    <div className="content">
      <ButtonContainer>
        <GuideButton
          label="メニュー"
          link="https://toypo.notion.site/ca803210c41e4baeb2c5570d2043f087"
        />
        {currentStaff.is_toypo_member && // CSV 機能は現状CSのみ対応
          <>
            <Button
              variant="outlined"
              color="toypoAdmin"
              onClick={exportMenuCsv}
            >
              CSV出力
            </Button>

            <ButtonGroup variant="contained" color="submit">
              <Button
                variant="contained"
                color="submit"
                onClick={goToNewMenu}
              >
                メニューを作成する
              </Button>
              <Button
                size="small"
                variant="contained"
                color="toypoAdmin"
                onClick={(e) => setAnchorElement(e.currentTarget)}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Menu
              anchorEl={anchorElement}
              open={Boolean(anchorElement)}
              onClose={() => setAnchorElement(null)}
            >
              <MenuItem onClick={menuHandler(goToNewMenu)}>
                個別に作成する
              </MenuItem>
              <MenuItem onClick={menuHandler(openCsvMenuImportDialog)}>
                CSVから一括で作成する
              </MenuItem>
            </Menu>
          </>
        }
        {!currentStaff.is_toypo_member && 
          <Button
            variant="contained"
            color="submit"
            onClick={goToNewMenu}
          >
            メニューを作成する
          </Button>
        }
      </ButtonContainer>
      <MenuListTable
        key={pageType}
        title={`${TitleLabels[pageType]}メニュー一覧`}
        menuTypes={menuTypes}
        onClickMenu={goToDetail}
      />
        <ButtonContainer>
          {currentStaff.is_toypo_member && // CSV 機能は現状CSのみ対応
            <>
              <Button
                variant="outlined"
                color="toypoAdmin"
                onClick={exportOptionTemplateCsv}
              >
                CSV出力
              </Button>

              <ButtonGroup variant="contained" color="submit">
                <Button
                  variant="contained"
                  color="submit"
                  onClick={() => history.push("/admin/option_template/new")}
                >
                  オプションを作成する
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="toypoAdmin"
                  onClick={(e) => setOptionAnchorElement(e.currentTarget)}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Menu
                anchorEl={optionAnchorElement}
                open={Boolean(optionAnchorElement)}
                onClose={() => setOptionAnchorElement(null)}
              >
                <MenuItem onClick={() => history.push("/admin/option_template/new")}>
                  個別に作成する
                </MenuItem>
                <MenuItem onClick={menuHandler(openCsvOptionImportDialog)}>
                  CSVから一括で作成する
                </MenuItem>
              </Menu>
            </>
          }
          {!currentStaff.is_toypo_member && 
            <Button
              variant="contained"
              color="submit"
              onClick={() => history.push("/admin/option_template/new")}
            >
              オプションを作成する
            </Button>
          }
        </ButtonContainer>
      <OptionTemplateList />
      {currentStaff.is_toypo_member &&
        <>
          <MenuCsvImportDialog
            menuType={menuType}
            open={isCsvMenuImportDialogOpen}
            onClose={closeCsvMenuImportDialog}
          />
          <OptionCsvImportDialog
            open={isCsvOptionImportDialogOpen}
            onClose={closeCsvOptionImportDialog}
          />
        </>
      }
    </div>
  );
};

const TitleLabels = {
  mobile_order: "モバイルオーダー",
  ec_menu: "オンラインショップ",
  display_menu: ""
} as const;

const useMenuTypes = () => {
  const { pathname } = useLocation();
  const { hasFunction } = useLoginContext();

  const pageType: "mobile_order" | "ec_menu" | "display_menu" = pathname.includes("order")
    ? "mobile_order"
    : pathname.includes("ec")
    ? "ec_menu"
    : "display_menu";

  const menuTypes: MenuType[] = [];
  if (pageType === "mobile_order") {
    if (hasFunction("takeout")) menuTypes.push("takeout");
    if (hasFunction("delivery")) menuTypes.push("delivery");
    if (hasFunction("preorder")) menuTypes.push("preorder");
  } else if (pageType === "ec_menu") {
    menuTypes.push("ec");
  } else if (pageType === "display_menu") {
    menuTypes.push("display");
  }

  return { pageType, menuTypes };
};

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexWrap: "wrap",
  margin: theme.spacing(0, 2, 1, 2),
  gap: theme.spacing(1)
}));

export default MenuView;
