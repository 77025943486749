import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const deleteStoreQrcodes = async (id) => {
  const DELETE_STORE_QRCODES = `/staff/stores/${
    loadViewObj()?.id
  }/store_qrcodes/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_STORE_QRCODES,
  });
  return response.data;
};

export const updateStoreQrcodeName = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const UPDATE_STORE_QRCODE_NAME = `/staff/stores/${
    loadViewObj()?.id
  }/store_qrcodes/${id}`;

  const params = {
    qrcode: {
      name,
    },
  };

  const response = await apiClient({
    method: "PATCH",
    params,
    uri: UPDATE_STORE_QRCODE_NAME,
  });
  return response.data;
};

export const deleteCheckInQrcodes = async (id) => {
  const DELETE_CHECK_IN_QRCODES = `/staff/stores/${
    loadViewObj()?.id
  }/check_in_qrcodes/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_CHECK_IN_QRCODES,
  });
  return response.data;
};

export const updateCheckInQrcodeName = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const UPDATE_CHECK_IN_QRCODE_NAME = `/staff/stores/${
    loadViewObj()?.id
  }/check_in_qrcodes/${id}`;

  const params = {
    qrcode: {
      name,
    },
  };

  const response = await apiClient({
    method: "PATCH",
    params,
    uri: UPDATE_CHECK_IN_QRCODE_NAME,
  });
  return response.data;
};

export const createMembersCardContentQrcode = async (
  point: number | null = null
) => {
  const point_query = point ? `point=${point}` : "";
  const CREATE_MEMBERS_CARD_CONTENT_QRCODE = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_content_qrcodes?${point_query}`;
  const response = await apiClient({
    method: "POST",
    uri: CREATE_MEMBERS_CARD_CONTENT_QRCODE,
  });
  return response.data;
};

export const deleteMembersCardContentQrcode = async (id) => {
  const DELETE_MEMBERS_CARD_CONTENT_QRCODE = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_content_qrcodes/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_MEMBERS_CARD_CONTENT_QRCODE,
  });
  return response.data;
};

export const createMembersCardRankQrcode = async (members_card_rank_id) => {
  const CREATE_MEMBERS_CARD_CONTENT_QRCODE = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_ranks/${members_card_rank_id}/members_card_rank_qrcodes`;

  const response = await apiClient({
    method: "POST",
    uri: CREATE_MEMBERS_CARD_CONTENT_QRCODE,
  });
  return response.data;
};

export const deleteMembersCardRankQrcode = async ({
  members_card_rank_id,
  members_card_rank_qrcode_id,
}) => {
  const DELETE_MEMBERS_CARD_CONTENT_QRCODE = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_ranks/${members_card_rank_id}/members_card_rank_qrcodes/${members_card_rank_qrcode_id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_MEMBERS_CARD_CONTENT_QRCODE,
  });
  return response.data;
};
