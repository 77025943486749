import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { activityLogApi } from "../../lib/api";
import { ActionTypeLabels, ContentTypeMappings } from "../../constants/activityLog";
import { ActionType, IllegalStatus, ContentType, ActivityLogContentResponse } from "../../types/activityLog";
export type { IllegalStatus } from "../../types/activityLog";

export type ActivityLogType = {
  id: number;
  action_type: ActionType;
  amount: number;
  name: string;
  content: ActivityLogContent;
  created_at: string;
  instance_id: number;
  status: "active";
  store_id: number;
  store_image_url: string;
  store_name: string;
  user_id: number;
  user_name: string;
  value: number;
  with_check_in: boolean;
  illegal_status: IllegalStatus;
  doubtful_reason_text: string;
  user_avatar_url: string;
};

export type ContentLogType = {
  id: number;
  title: string;
  user_name: string;
  store_name: string;
  created_at: string;
};

type ActivityLogContent = {
  activity_log_id: number;
  content_id: number;
  created_at: string;
  id: number;
  image_url: string;
  is_read: boolean;
  title: string;
  updated_at: string;
};

export const Status = {
  Active: "active",
  Cancel: "cancel"
} as const;

export const Statuses = Object.values(Status)
export type StatusType = typeof Status[keyof typeof Status]

const illegalStatuses: IllegalStatus[] = [
  "not_check",
  "legal",
  "illegal",
  "doubtful",
];

const useActivityLogEntityHook = () => {
  const [activityLogs, setActivityLogs] = useState<ActivityLogType[]>([]);
  const [contents, setContents] = useState<ActivityLogContentResponse>({
    coupons: [],
    services: [],
    subscriptions: [],
    stamp_cards: [],
    tickets: [],
  });
  const [useTicketLogs, setUseTicketLogs] = useState<ContentLogType[]>([]);
  const [useSubscriptionLogs, setUseSubscriptionLogs] = useState<
    ContentLogType[]
  >([]);

  // url
  const [urlParams, setUrlParams] = useState("");

  return {
    state: {
      activityLogs,
      useTicketLogs,
      useSubscriptionLogs,
      contents,
      urlParams,
    },
    constant: {
      illegalStatuses,
    },
    logic: {
      setUrlParams,
      getActivityLogs: async (newUrlParams: string) => {
        const response = await activityLogApi.getActivityLogs(newUrlParams);
        setActivityLogs(response.data);
        return response;
      },
      getContents: useCallback(async (): Promise<ActivityLogContentResponse> => {
        const newContents = await activityLogApi.getContents();
        setContents(newContents);
        return newContents;
      },[]),
      getUseSubscriptionLogs: async (): Promise<ContentLogType[]> => {
        const newUseSubscriptionLogs: ContentLogType[] =
          await activityLogApi.getUseSubscriptionLogs();
        setUseSubscriptionLogs(newUseSubscriptionLogs);
        return newUseSubscriptionLogs;
      },
      getUseTicketLogs: async (): Promise<ContentLogType[]> => {
        const newUseTicketLogs: ContentLogType[] =
          await activityLogApi.getUseTicketLogs();
        setUseTicketLogs(newUseTicketLogs);
        return newUseTicketLogs;
      },
      updateActivityLog: async ({ id, params }) => {
        const response = await activityLogApi.updateActivityLog({
          id,
          params,
        });
        return response;
      },
      deleteActivityLog: async (id, doCancelDependentLogs) => {
        const response = await activityLogApi.deleteActivityLog(
          id,
          doCancelDependentLogs
        );
        return response;
      },
      downloadActivityLogsCsv: async (url: string): Promise<void> => {
        await activityLogApi.downloadActivityLogsCsv(url);
      },
      actionTypesToJp: (
        actionType: ActionType,
        value?: number,
        amount?: number
      ): string => {
        const title: string = ActionTypeLabels[actionType];

        if (actionType !== "use_ticket" && value) {
          return `${value} ${title}`;
        }
        if (actionType === "check_in" && amount) {
          return `${title}(お会計: ${amount}円)`;
        }
        if (actionType === "use_house_money" && amount) {
          return `${title}(お会計: ${amount}円)`;
        }
        if (actionType === "charge_house_money_by_credit" && amount) {
          return `${title}(チャージ金額: ${amount}円)`;
        }
        if (actionType === "charge_house_money_by_cash" && amount) {
          return `${title}(チャージ金額: ${amount}円)`;
        }

        return title;
      },
      actionTypeToContentType: (actionType: ActionType): ContentType =>
        ContentTypeMappings[actionType],
    },
  };
};

export default createContainer(useActivityLogEntityHook);
