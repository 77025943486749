import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { Grid, Row, Col, ButtonToolbar } from "react-bootstrap";
import ReactLoading from "react-loading";
import { Pagination } from "@mui/material";
import Button from "../../components/CustomButton/CustomButton";
import GuideButton from "../../components/Link/GuideLink";
import { Card } from "../../components/Card/Card";
import TenantAlert from "../../components/Modal/TenantAlert";
import SubscriptionListLargeTable from "./parts/SubscriptionListLargeTable";
import SubscriptionListSmallTable from "./parts/SubscriptionListSmallTable";
import SubscriptionStatusChangeModal from "./parts/SubscriptionStatusChangeModal";
import OwnerEntityContainer from "../../containers/entities/OwnerEntityContainer";
import { SubscriptionContentStatusLabel } from "../../constants/subscription";
import { useBooleanState, usePcSizeFlag } from "../../lib/hooks";
import { useSubscriptionContentGetApi,
  useSubscriptionContentStatusUpdateApi,
} from "../../lib/hooks/apis/subscriptionContent";
import { SortOrderType } from "../../types/common.d";
import { SubscriptionContentStatus } from "../../types/resource/subscriptionContent.d";
import { SubscriptionContentBlock } from "../../types/api/subscription.d";
import { SubscriptionSortByType } from "../../types/subscription.d";
import { useNotification } from "../../providers/NotificationProvider";

type ReloadSubscriptionContentsArgs = {
  page?: number;
  sortBy?: SubscriptionSortByType;
  sortOrder?: SortOrderType;
};

const SubscriptionListView = (): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();
  const history = useHistory();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [isSubscriptionStatusModalOpen, openSubscriptionStatusModal, closeSubscriptionStatusModal] = useBooleanState(false);
  const [selectSubscriptionId, setSelectSubscriptionId] = useState<number>(0);
  const [selectSubscriptionStatus, setSelectSubscriptionStatus] = useState<SubscriptionContentStatus>("draft");
  const [sortBy, setSortBy] = useState<SubscriptionSortByType>("created_at");
  const [sortOrder, setSortOrder] = useState<SortOrderType>("desc");
  const ownerEntityContianer = OwnerEntityContainer.useContainer();
  const { owner, cardApplicationStatus } = ownerEntityContianer.state;
  const { updateSubscriptionContentStatus, loading: updatingSubscriptionContentStatus } = useSubscriptionContentStatusUpdateApi();
  const {
    fetchSubscriptionContents,
    response: subscriptionContentsResponse,
    totalPages: subscriptionContentsTotalPages,
    currentPage: subscriptionContentsCurrentPage,
    loading: loadingSubscriptionContents,
    loaded: loadedSubscriptionContents,
  } = useSubscriptionContentGetApi();

  const loading = loadingSubscriptionContents || updatingSubscriptionContentStatus;

  const reloadSubscriptionContentsWithParams = useCallback(
    async (params: ReloadSubscriptionContentsArgs = {}) => {
      const page = params.page || 1;
      const sort = params.sortBy || "created_at";
      const order = params.sortOrder || "desc";

      const res = await fetchSubscriptionContents(page, sort, order);

      if (!res) return;

      setSortBy(sort);
      setSortOrder(order);
    },
    [fetchSubscriptionContents]
  );

  useEffect(() => {
    reloadSubscriptionContentsWithParams();
  }, [reloadSubscriptionContentsWithParams]);

  const subscriptionContents: SubscriptionContentBlock[] =
    subscriptionContentsResponse?.subscription_contents || [];

  const canPublishSubscription = owner?.has_tenant && cardApplicationStatus === "passed"

  const onClickStatusLabel = (
    id: number,
    status: SubscriptionContentStatus
  ): void => {
    openSubscriptionStatusModal();
    setSelectSubscriptionId(id);
    setSelectSubscriptionStatus(status);
  };

  const onClickStatusUpdateButton = async ({
    newStatus,
    id,
  }: {
    newStatus: SubscriptionContentStatus;
    id: number;
  }): Promise<void> => {
    if (!canPublishSubscription && ["open", "limited_open"].includes(newStatus)) {
      showErrorNotification("サブスクを公開するには、口座登録とクレジット利用申請が必要です。");
      return;
    }

    const message = `${SubscriptionContentStatusLabel[newStatus]}に変更しますか？`;
    if (!window.confirm(message)) return;

    const res = await updateSubscriptionContentStatus(id, newStatus);

    if (!res) return;

    reloadSubscriptionContentsWithParams({ sortBy, sortOrder });
    showSuccessNotification("ステータスを更新しました。");
  };

  return (
    <div className="content">
      <Grid fluid>
        <TenantAlert functionName="サブスク" />

        <Row style={{ marginBottom: 10 }}>
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              alignItems: isPcOrTablet ? "center" : "flex-end",
              flexDirection: isPcOrTablet ? "row" : "column",
            }}
          >
            <GuideButton
              label="サブスク"
              link="https://toypo.notion.site/87fd170d4a374e8293be228485f8a086"
            />

            <ButtonToolbar className="pull-right">
              <Button
                bsStyle="info"
                fill
                pullRight
                onClick={() => history.push("/admin/subscription/new")}
              >
                <b>サブスクを作成する</b>
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card
              title="サブスク一覧"
              ctTableFullWidth
              content={
                !loadedSubscriptionContents ? (
                  <StyledLoadingContainer>
                    <ReactLoading type="spinningBubbles" color="gray" height={70} width={70} />
                  </StyledLoadingContainer>
                ) : (
                  <SubscriptionContentList
                    subscriptionContents={subscriptionContents}
                    onClickStatusLabel={onClickStatusLabel}
                    onClickSubscriptionContent={(subscription) =>
                      history.push("/admin/subscription/edit", { subscription })
                    }
                    onSortSubscriptionContents={(newSortBy, newSortOrder) => {
                      reloadSubscriptionContentsWithParams({
                        sortBy: newSortBy,
                        sortOrder: newSortOrder,
                      });
                    }}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    loading={loading}
                  />
                )
              }
              stats={
                loadedSubscriptionContents &&
                subscriptionContentsCurrentPage &&
                subscriptionContentsTotalPages &&
                subscriptionContentsTotalPages > 1 ? (
                  <Pagination
                    disabled={loading}
                    count={subscriptionContentsTotalPages}
                    page={subscriptionContentsCurrentPage}
                    onChange={(_, value) =>
                      reloadSubscriptionContentsWithParams({
                        page: value,
                        sortBy: sortBy,
                        sortOrder: sortOrder,
                      })
                    }
                    sx={{ fontSize: 24, color: "black" }}
                  />
                ) : null
              }
            />

            <SubscriptionStatusChangeModal
              show={isSubscriptionStatusModalOpen}
              onHide={closeSubscriptionStatusModal}
              subscriptionId={selectSubscriptionId}
              defaultStatus={selectSubscriptionStatus}
              onClickStatusUpdateButton={onClickStatusUpdateButton}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const SubscriptionContentList = ({
  subscriptionContents,
  onClickSubscriptionContent,
  onClickStatusLabel,
  onSortSubscriptionContents,
  sortBy,
  sortOrder,
  loading,
}) => {
  const isPcOrTablet = usePcSizeFlag();

  return isPcOrTablet ? (
    <SubscriptionListLargeTable
      subscriptionContents={subscriptionContents}
      onClickRow={onClickSubscriptionContent}
      onClickStatusLabel={onClickStatusLabel}
      onSort={onSortSubscriptionContents}
      sortBy={sortBy}
      sortOrder={sortOrder}
    />
  ) : (
    <SubscriptionListSmallTable
      subscriptionContents={subscriptionContents}
      onClickRow={onClickSubscriptionContent}
      onClickStatusLabel={onClickStatusLabel}
      loading={loading}
    />
  );
};

const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

export default SubscriptionListView;
