import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { useMobileFlag } from "../../../lib/hooks";
import DialogTitle from "../../../components/DialogTitle";

type Props = {
  open: boolean;
  memo: string;
  onClose: () => void;
  onSubmit: (updatedMemo: string) => void;
};

const CustomerInfoEditMemoModal = ({
  open,
  memo,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  const isMobile = useMobileFlag();
  const [editedMemo, setEditedMemo] = useState(memo);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (open) {
      setEditedMemo(memo);
    }
  }, [open, memo]);

  useEffect(() => {
    setIsSubmitDisabled(editedMemo === memo);
  }, [editedMemo, memo]);

  const handleSubmit = () => {
    onSubmit(editedMemo);
  };

  return (
    <Dialog fullWidth fullScreen={isMobile} open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle onClose={onClose}>メモ</DialogTitle>
      <DialogContent style={{ paddingTop: 4, height: 400, overflowY: "auto" }}>
        <Box sx={{height: "100%"}}>
          <OutlinedInput
            multiline
            fullWidth
            sx={{
              minHeight: "100%",
              overflowY: "auto",
              fontSize: 14,
              lineHeight: "21px",
              alignItems: "start",
            }}
            value={editedMemo}
            onChange={({ target }) => setEditedMemo(target.value)}
            placeholder="メモを入力してください"
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          color="cancel"
          onClick={onClose}
        >
          戻る
        </Button>

        <Button
          fullWidth
          variant="contained"
          color="submit"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          sx={{
            "&:disabled": {
              backgroundColor: "#88e0f7",
              color: "white",
            },
          }}
        >
          変更を保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerInfoEditMemoModal;
