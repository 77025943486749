import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";
import { GraphSummary } from "../../../containers/entities/AnalyticsEntityContainer";

type Props = {
  summaries: GraphSummary[];
};

const comparedValueColor = (
  type: "positive" | "negative",
  comparedValue: number
): "#5A5A5A" | "#7BC9C4" | "#C97A8D" => {
  if (comparedValue === 0) {
    return "#5A5A5A";
  }
  if (
    (type === "positive" && comparedValue > 0) ||
    (type === "negative" && comparedValue < 0)
  ) {
    return "#7BC9C4";
  }
  return "#C97A8D";
};

const GraphSummaries: React.FC<Props> = ({ summaries }) => {
  const minWidth600px = useMediaQuery({
    query: "(min-width: 600px)",
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {summaries.map((summary) => (
        <div
          key={summary.label}
          style={{
            backgroundColor: "#F0F1F1",
            textAlign: "center",
            padding: "15px 10px",
            margin: "20px 10px 0",
            flex: 1,
          }}
        >
          <div>
            <span
              style={{ fontSize: minWidth600px ? 14 : 8, fontWeight: "bold" }}
            >
              {summary.label}
            </span>
          </div>
          <div>
            <span
              style={{ fontSize: minWidth600px ? 24 : 16, fontWeight: "bold" }}
            >
              {summary.mainValue ? summary.mainValue.toLocaleString() : "0"}
            </span>
            <span
              style={{ fontSize: minWidth600px ? 14 : 8, fontWeight: "bold" }}
            >
              {summary.mainValueUnit}
            </span>
            {!!summary.subValue && (
              <span
                style={{
                  fontSize: minWidth600px ? 14 : 8,
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                ({summary.subValue ? summary.subValue.toLocaleString() : "0"}
                {summary.subValueUnit})
              </span>
            )}
          </div>
          {!!summary.comparedLabel && (
            <div
              style={{
                color: "#676D77",
                fontSize: minWidth600px ? 14 : 8,
                fontWeight: "bold",
              }}
            >
              {summary.comparedLabel}と比較して
              <span
                style={{
                  marginLeft: 5,
                  color: comparedValueColor(
                    summary.type,
                    summary.comparedValue
                  ),
                }}
              >
                {summary.comparedValue > 0 && "+"}
                {summary.comparedValue
                  ? summary.comparedValue.toLocaleString()
                  : "0"}
                <span style={{ fontSize: minWidth600px ? 10 : 6 }}>
                  {summary.comparedValueUnit}
                </span>
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(GraphSummaries);
