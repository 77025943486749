import { useState, useCallback } from "react";
import moment from "moment";
import { createContainer } from "unstated-next";
import { dashboardApi } from "../../lib/api";

export type Data = {
  label: string;
  description: string;
  display: boolean;
  data;
  summary?: {
    average: number;
  };
};
export type BarGraphData = {
  label: string;
  description: string;
  display: boolean;
  data;
  summaries?: GraphSummary[];
};
export type LineGraphData = {
  label: string;
  description: string;
  display: boolean;
  data;
  summaries?: GraphSummary[];
};
export type GraphSummary = {
  label: string;
  mainValue: number;
  mainValueUnit?: string;
  comparedLabel: string;
  subValue?: number;
  subValueUnit?: string;
  comparedValue: number;
  comparedValueUnit?: string;
  type: "positive" | "negative";
};

export type DataType =
  | "total_user"
  | "user"
  | "fan"
  | "visit"
  | "user"
  | "churn"
  | "retention_rate_30"
  | "retention_rate_90"
  | "new_connect"
  | "retention_rate"
  | "pv"
  | "charge"
  | "gender"
  | "age"
  | "rank"
  | "connect_days"
  | "revisit_days"
  | "visit_rate";

export type DataUnit = "daily" | "weekly" | "monthly";
export type DataUnitJp = "日" | "週" | "月";

type DataPeriodMonthlyType = "this_year" | "last_year" | "2_years_ago";
type DataPeriodWeeklyType =
  | "last_8_weeks"
  | "this_month"
  | "last_month"
  | "2_months_ago"
  | "3_months_ago"
  | "4_months_ago"
  | "5_months_ago"
  | "6_months_ago"
  | "custom";
type DataPeriodDailyType =
  | "last_7_days"
  | "last_14_days"
  | "this_month"
  | "last_month"
  | "2_months_ago"
  | "3_months_ago"
  | "4_months_ago"
  | "5_months_ago"
  | "6_months_ago"
  | "custom";
export type DataPeriodType = DataPeriodMonthlyType | DataPeriodWeeklyType | DataPeriodDailyType;

export type DataPeriod = {
  type: DataPeriodType | null;
  from: string | null;
  to: string | null;
};

export type GenderDataKey = "male" | "female" | "other" | "empty";
export type GenderDataKeyJp = "男性" | "女性" | "その他" | "未回答";
export type AgeDataKey =
  | "ave"
  | "child"
  | "teens"
  | "twenties"
  | "thirties"
  | "forties"
  | "fifties"
  | "sixties";
export type AgeDataKeyJp =
  | "平均"
  | "10代未満"
  | "10代"
  | "20代"
  | "30代"
  | "40代"
  | "50代"
  | "60代";
export type FanDataKey = "fan" | "potential" | "repeater" | "new" | "dormant";
export type FanDataKeyJp = "ファン" | "潜在ファン" | "リピーター" | "新規" | "休眠";
export type PeriodKey =
  | "all"
  | "this_month"
  | "last_month"
  | "last_6_months"
  | "this_year"
  | "last_year"
  | "custom";
export type PeriodKeyJp =
  | "すべての期間"
  | "今月"
  | "先月"
  | "直近半年"
  | "今年"
  | "去年"
  | "カスタム";

export type NewConnectDataKey = "new_connect" | "first" | "more";
export type AmountDataKeyJp = "売上" | "料金入力" | "料金未入力";

export const dataPeriodTypeToPeriod: {
  [key in DataPeriodType]: { from: string; to: string };
} = {
  this_year: {
    from: moment().startOf("year").format("YYYY/MM/DD"),
    to: moment().endOf("year").format("YYYY/MM/DD"),
  },
  last_year: {
    from: moment().add(-1, "year").startOf("year").format("YYYY/MM/DD"),
    to: moment().add(-1, "year").endOf("year").format("YYYY/MM/DD"),
  },
  "2_years_ago": {
    from: moment().add(-2, "year").startOf("year").format("YYYY/MM/DD"),
    to: moment().add(-2, "year").endOf("year").format("YYYY/MM/DD"),
  },
  last_8_weeks: {
    from: moment().add(-8, "week").startOf("week").format("YYYY/MM/DD"),
    to: moment().format("YYYY/MM/DD"),
  },
  this_month: {
    from: moment().startOf("month").format("YYYY/MM/DD"),
    to: moment().endOf("month").format("YYYY/MM/DD"),
  },
  last_month: {
    from: moment().add(-1, "month").startOf("month").format("YYYY/MM/DD"),
    to: moment().add(-1, "month").endOf("month").format("YYYY/MM/DD"),
  },
  "2_months_ago": {
    from: moment().add(-2, "month").startOf("month").format("YYYY/MM/DD"),
    to: moment().add(-2, "month").endOf("month").format("YYYY/MM/DD"),
  },
  "3_months_ago": {
    from: moment().add(-3, "month").startOf("month").format("YYYY/MM/DD"),
    to: moment().add(-3, "month").endOf("month").format("YYYY/MM/DD"),
  },
  "4_months_ago": {
    from: moment().add(-4, "month").startOf("month").format("YYYY/MM/DD"),
    to: moment().add(-4, "month").endOf("month").format("YYYY/MM/DD"),
  },
  "5_months_ago": {
    from: moment().add(-5, "month").startOf("month").format("YYYY/MM/DD"),
    to: moment().add(-5, "month").endOf("month").format("YYYY/MM/DD"),
  },
  "6_months_ago": {
    from: moment().add(-6, "month").startOf("month").format("YYYY/MM/DD"),
    to: moment().add(-6, "month").endOf("month").format("YYYY/MM/DD"),
  },
  last_7_days: {
    from: moment().add(-7, "day").startOf("day").format("YYYY/MM/DD"),
    to: moment().format("YYYY/MM/DD"),
  },
  last_14_days: {
    from: moment().add(-14, "day").startOf("day").format("YYYY/MM/DD"),
    to: moment().format("YYYY/MM/DD"),
  },
  custom: { from: "", to: "" },
};
export const formatDateLabel = (label: string, unit: DataUnit): string => {
  switch (unit) {
    case "monthly":
      return moment(label).format("M月");
    case "weekly":
      return moment(label).format("M月D日週");
    case "daily":
      return moment(label).format("M月D日");
    default:
      return "";
  }
};

const fanDatakeys = ["count", "count_other"];
const newConnectDataKeys = ["first", "more"];
const pvDataKeys = ["pv"];
const churnDataKeys = ["churn"];

const fanDataColor = {
  ファン: "#FB496C",
  潜在ファン: "#DFA9E9",
  リピーター: "#EECC9F",
  ビギナー: "#47B8B3",
  潜在客: "#5B8FF9",
  休眠ユーザー: "#A1AFCB",
};

const newConnectDataColor = {
  new_connect: "#AFB8F0",
  first: "#47B8B3",
  more: "#F1C631",
};

const pvDataColor = {
  pv: "#47B8B3",
};
const churnDataColor = {
  churn: "#E35B52",
};
const retentionRateDataColor = {
  retention_rate_30: "#54ADFF",
  retention_rate_90: "#E88E9C",
};
const rankDataColor = {
  blue: "#005CFF",
  turquoise_blue: "#55CCDC",
  red: "#FF0000",
  green: "#4EE671",
  yellow: "#FFFF27",
  bronze: "#FF6927",
  silver: "#B9B9B9",
  light_gray: "#E8E8E8",
  gold: "#CBAC2C",
  white: "#fff",
  black: "#000",
};
export const genderDataColor = {
  男性: "#72C9E0",
  女性: "#787CF7",
  その他: "#65789B",
  未設定: "#60DEAA",
};
export const ageDataColor = {
  "9歳以下": "#72C9E0",
  "10代": "#26ABA5",
  "20代": "#AFB8F0",
  "30代": "#787CF7",
  "40代": "#5B8FF9",
  "50代": "#60DEAA",
  "60代以上": "#A2E9E0",
  未設定: "#65789B",
} as const;
const connectDaysDataColor = {
  "7日以内": "#72C9E0",
  "14日以内": "#26ABA5",
  "30日以内": "#AFB8F0",
  "90日以内": "#787CF7",
  "180日以内": "#5B8FF9",
  "181日以降": "#60DEAA",
} as const;
export const revisitDaysDataColor = {
  "7日以内": "#72C9E0",
  "14日以内": "#26ABA5",
  "30日以内": "#AFB8F0",
  "90日以内": "#787CF7",
  "91日以降": "#60DEAA",
  再来店なし: "#A2E9E0",
} as const;
export const visitRateDataColor = {
  "0回": "#72C9E0",
  "1回": "#26ABA5",
  "2回": "#AFB8F0",
  "3回": "#787CF7",
  "4回": "#60DEAA",
  "5回": "#A2E9E0",
  "6 ~ 10回": "#65789B",
  "11 ~ 50回": "#85789B",
  "51 ~ 100回": "#42E9E0",
  "101回以上": "#22E922",
} as const;

export const dataUnitToJpObj = {
  monthly: "月",
  weekly: "週",
  daily: "日",
} as const;
const newConnectDataKeyToJpObj = {
  new_connect: "合計",
  first: "初回登録",
  more: "その他",
} as const;

const useAnalyticsEntityHook = () => {
  // modal
  const [selectedDataType, setSelectedDataType] = useState<DataType | null>(null);

  // data
  /// total
  const [totalUserData, setTotalUserData] = useState<BarGraphData>();

  /// fan
  const [fanData, setFanData] = useState<BarGraphData>();

  /// new_connect
  const [newConnectData, setNewConnectData] = useState<BarGraphData>();
  const [newConnectDataUnit, setNewConnectDataUnit] = useState<DataUnit>("weekly");
  const [newConnectDataPeriod, setNewConnectDataPeriod] = useState<DataPeriod>({
    type: "last_8_weeks",
    from: dataPeriodTypeToPeriod.last_8_weeks.from,
    to: dataPeriodTypeToPeriod.last_8_weeks.to,
  });

  /// pv
  const [pvData, setPvData] = useState<BarGraphData>();
  const [pvDataUnit, setPvDataUnit] = useState<DataUnit>("weekly");
  const [pvDataPeriod, setPvDataPeriod] = useState<DataPeriod>({
    type: "last_8_weeks",
    from: dataPeriodTypeToPeriod.last_8_weeks.from,
    to: dataPeriodTypeToPeriod.last_8_weeks.to,
  });

  /// churn
  const [churnData, setChurnData] = useState<BarGraphData>();
  const [churnDataUnit, setChurnDataUnit] = useState<DataUnit>("weekly");
  const [churnDataPeriod, setChurnDataPeriod] = useState<DataPeriod>({
    type: "last_8_weeks",
    from: dataPeriodTypeToPeriod.last_8_weeks.from,
    to: dataPeriodTypeToPeriod.last_8_weeks.to,
  });

  /// retention_rate
  const [retentionRateData, setRetentionRateData] = useState<LineGraphData>();

  /// gender
  const [genderData, setGenderData] = useState<Data>();

  /// age
  const [ageData, setAgeData] = useState<Data>();

  /// rank
  const [rankData, setRankData] = useState<Data>();

  /// connect_days
  const [connectDaysData, setConnectDaysData] = useState<Data>();

  /// revisit_days
  const [revisitDaysData, setRevisitDaysData] = useState<Data>();

  /// visit_rate
  const [visitRateData, setVisitRateData] = useState<Data>();

  const setDataPeriod = (formData: DataPeriod): void => {
    const newDataPeriod: DataPeriod = {
      type: "custom",
      from: formData.from,
      to: formData.to,
    };
    switch (selectedDataType) {
      case "new_connect":
        setNewConnectDataPeriod(newDataPeriod);
        break;
      case "pv":
        setPvDataPeriod(newDataPeriod);
        break;
      case "churn":
        setChurnDataPeriod(newDataPeriod);
        break;
      default:
        break;
    }
  };

  return {
    state: {
      selectedDataType,
      totalUserData,
      fanData,
      newConnectData,
      newConnectDataUnit,
      newConnectDataPeriod,
      pvData,
      pvDataUnit,
      pvDataPeriod,
      churnData,
      churnDataUnit,
      churnDataPeriod,
      retentionRateData,
      genderData,
      ageData,
      rankData,
      connectDaysData,
      revisitDaysData,
      visitRateData,
    },
    constant: {
      fanDatakeys,
      fanDataColor,
      newConnectDataKeys,
      newConnectDataColor,
      pvDataKeys,
      pvDataColor,
      churnDataKeys,
      churnDataColor,
    },
    logic: {
      setDataPeriod,
      setSelectedDataType,
      setNewConnectDataUnit,
      setNewConnectDataPeriod,
      setPvDataUnit,
      setPvDataPeriod,
      setChurnDataUnit,
      setChurnDataPeriod,
      getTotalUser: useCallback(async () => {
        const res = await dashboardApi.getAnalyticsData({
          type: "total_user",
        });
        setTotalUserData(res);
        return res;
      }, []),
      getFanData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "fan",
          user_group_id,
        });
        const total = res.data.reduce((sum, element) => sum + element.count, 0);
        const summaries: GraphSummary[] = [
          {
            label: res?.data[res?.data.length - 1]?.label,
            mainValue: res?.data[res?.data.length - 1]?.count,
            mainValueUnit: "人",
            subValue: Math.round(res?.data[res?.data.length - 1]?.rate * 10) / 10,
            subValueUnit: "%",
            comparedLabel: "全体",
            comparedValue: Math.round(res?.data[res?.data.length - 1]?.rate_diff),
            comparedValueUnit: "%",
            type: "positive",
          },
        ];
        const formattedData = {
          ...res,
          data: res?.data.map((d) => ({
            ...d,
            count_color: fanDataColor[d.label],
            count_other: total === 0 ? 10 : total - d.count,
            count_other_color: "#F8F6F7",
          })),
          summaries,
        };
        setFanData(formattedData);
        return res;
      }, []),
      getUserData: useCallback(async ({ unit, from, to, user_group_id }) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "user",
          unit,
          from,
          to,
          user_group_id,
        });
        return res;
      }, []),
      getNewConnectData: useCallback(async ({ unit, from, to, user_group_id }) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "new_connect",
          unit,
          from,
          to,
          user_group_id,
        });
        const summaries: GraphSummary[] = [
          {
            label: formatDateLabel(
              moment(res?.data[res?.data.length - 1]?.date).format("YYYY/MM/DD"),
              newConnectDataUnit
            ),
            mainValue: res?.data[res?.data.length - 1]?.new_connect,
            mainValueUnit: "人",
            comparedLabel: `前${dataUnitToJpObj[newConnectDataUnit]}`,
            comparedValue:
              res?.data[res?.data.length - 1]?.new_connect -
              res?.data[res?.data.length - 2]?.new_connect,
            comparedValueUnit: "人",
            type: "positive",
          },
        ];
        setNewConnectData({ ...res, summaries });
        return res;
      }, [newConnectDataUnit]),
      getChargeData: useCallback(async ({ unit, from, to, user_group_id }) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "charge",
          unit,
          from,
          to,
          user_group_id,
        });

        return res;
      }, []),
      getVisitData: useCallback(async ({ unit, from, to, user_group_id }) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "visit",
          unit,
          from,
          to,
          user_group_id,
        });

        return res;
      }, []),
      getPvData: useCallback(async ({ unit, from, to, user_group_id }) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "pv",
          unit,
          from,
          to,
          user_group_id,
        });
        const summaries: GraphSummary[] = [
          {
            label: formatDateLabel(
              moment(res?.data[res?.data.length - 1]?.date).format("YYYY/MM/DD"),
              pvDataUnit
            ),
            mainValue: res?.data[res?.data.length - 1]?.pv,
            mainValueUnit: "回",
            comparedLabel: `前${dataUnitToJpObj[pvDataUnit]}`,
            comparedValue:
              res?.data[res?.data.length - 1]?.pv - res?.data[res?.data.length - 2]?.pv,
            comparedValueUnit: "回",
            type: "positive",
          },
        ];
        setPvData({ ...res, summaries });
        return res;
      }, [pvDataUnit]),
      getChurnData: useCallback(async ({ unit, from, to, user_group_id }) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "churn",
          unit,
          from,
          to,
          user_group_id,
        });
        const summaries: GraphSummary[] = [
          {
            label: formatDateLabel(
              moment(res?.data[res?.data.length - 1]?.date).format("YYYY/MM/DD"),
              churnDataUnit
            ),
            mainValue: res?.data[res?.data.length - 1]?.churn,
            mainValueUnit: "人",
            comparedLabel: `前${dataUnitToJpObj[churnDataUnit]}`,
            comparedValue:
              res?.data[res?.data.length - 1]?.churn - res?.data[res?.data.length - 2]?.churn,
            comparedValueUnit: "人",
            type: "negative",
          },
        ];
        setChurnData({ ...res, summaries });
        return res;
      }, [churnDataUnit]),
      getRetentionRateData: useCallback(async (user_group_id) => {
        const retentionRate30Data = await dashboardApi.getAnalyticsData({
          type: "retention_rate_30",
          user_group_id,
        });
        const retentionRate90Data = await dashboardApi.getAnalyticsData({
          type: "retention_rate_90",
          user_group_id,
        });
        const data = [
          {
            id: "30日後",
            color: retentionRateDataColor.retention_rate_30,
            data: retentionRate30Data.data.map((d) => ({
              x: d.date,
              y: Math.round(Number(d.retention_rate) * 10) / 10, // 小数第1位で四捨五入
            })),
          },
          {
            id: "90日後",
            color: retentionRateDataColor.retention_rate_90,
            data: retentionRate90Data.data.map((d) => ({
              x: d.date,
              y: Math.round(Number(d.retention_rate) * 10) / 10, // 小数第1位で四捨五入
            })),
          },
        ];
        const summaries: GraphSummary[] = data.map((d) => ({
          label: `${d?.id}継続率`,
          mainValue: d.data[d.data.length - 1]?.y,
          mainValueUnit: "%",
          comparedLabel: "前月",
          comparedValue:
            Math.round(Number(d.data[d.data.length - 1]?.y - d.data[d.data.length - 2]?.y) * 10) /
            10,
          comparedValueUnit: "%",
          type: "positive",
        }));
        const formattedData = {
          ...retentionRate30Data,
          data,
          summaries,
        };
        setRetentionRateData(formattedData);
        return formattedData;
      }, []),
      getGenderData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "gender",
          user_group_id,
        });
        const formattedData = {
          ...res,
          data: Object.keys(res.data).map((key) => ({
            id: key,
            label: res.data[key].label,
            color: genderDataColor[res.data[key].label],
            value: res.data[key].count,
          })),
        };
        setGenderData(formattedData);
        return res;
      }, []),
      getAgeData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "age",
          user_group_id,
        });
        const formattedData = {
          ...res,
          data: Object.keys(res.data).map((key) => ({
            id: key,
            label: res.data[key].label,
            color: ageDataColor[res.data[key].label],
            value: res.data[key].count,
          })),
        };
        setAgeData(formattedData);
        return res;
      }, []),
      getRankData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "rank",
          user_group_id,
        });
        const formattedData = {
          ...res,
          data: Object.keys(res.data).map((key) => ({
            id: key,
            label: res.data[key].label,
            color: rankDataColor[res.data[key].color],
            value: res.data[key].count,
          })),
        };
        setRankData(formattedData);
        return res;
      }, []),
      getConnectDaysData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "connect_days",
          user_group_id,
        });
        const formattedData = {
          ...res,
          data: Object.keys(res.data).map((key) => ({
            id: key,
            label: res.data[key].label,
            color: connectDaysDataColor[res.data[key].label],
            value: res.data[key].count,
          })),
        };
        setConnectDaysData(formattedData);
        return res;
      }, []),
      getRevisitDaysData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "revisit_days",
          user_group_id,
        });
        const formattedData = {
          ...res,
          data: Object.keys(res.data).map((key) => ({
            id: key,
            label: res.data[key].label,
            color: revisitDaysDataColor[res.data[key].label],
            value: res.data[key].count,
          })),
        };
        setRevisitDaysData(formattedData);
        return res;
      }, []),
      getVisitRateData: useCallback(async (user_group_id) => {
        const res = await dashboardApi.getAnalyticsData({
          type: "visit_rate",
          user_group_id,
        });
        const formattedData = {
          ...res,
          data: Object.keys(res.data).map((key) => ({
            id: key,
            label: res.data[key].label,
            color: visitRateDataColor[res.data[key].label],
            value: res.data[key].count,
          })),
        };
        setVisitRateData(formattedData);
        return res;
      }, []),
      dataUnitToJp: (key: DataUnit): DataUnitJp => dataUnitToJpObj[key],
      newConnectDataKeyToJp: (key): NewConnectDataKey => newConnectDataKeyToJpObj[key],
    },
  };
};

export default createContainer(useAnalyticsEntityHook);
