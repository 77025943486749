import React from "react";
import { styled } from "@mui/system";
import { isLogin } from "../../lib/auth";
import NotificationProvider from "../../providers/NotificationProvider";
import ThemeProvider from "../../providers/ThemeProvider";
import LoginContent from "./parts/LoginContent";
import NoLoginContent from "./parts/NoLoginContent";

const ToypoApp = (): JSX.Element => {
  return (
    <ThemeProvider>
      <NotificationProvider>
        <StyledContainer>
          {isLogin() ? (
            <LoginContent />
          ) : (
            <NoLoginContent />
          )}
        </StyledContainer>
      </NotificationProvider>
    </ThemeProvider>
  );
};

const StyledContainer = styled("div")({
  minHeight: "100vh",
  position: "relative",
  backgroundColor: "white",
  display: "flex"
});

export default ToypoApp;
