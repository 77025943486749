import React from "react";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { useApi, useGetApiCall, useBooleanState } from "../../lib/hooks";
import FormLabel from "../../components/FormInputs/FormLabel";
import Section from "../../components/Section";
import { FormChangedAlertModal, Alert } from "../../components/Modal/FormChangedAlertModal";
import { LineChannelResponseIncludingSecret } from "../../types/store";
import StatusBar from "../../components/StatusBar";

type LineSettingFormType = {
  line_channel_id: string;
  line_channel_secret: string;
  liff_id: string;
};

const DEFAULT_VALUES = {
  line_channel_id: "",
  line_channel_secret: "",
  liff_id: ""
} as const;

const LineSettingView = (): JSX.Element => {
  const [isDeleteConfirmationDialogOpen, openDeleteConfirmationDialog, closeDeleteConfirmationDialog] = useBooleanState(false);
  const { handleSubmit, control, errors } = useForm<LineSettingFormType>({ defaultValues: DEFAULT_VALUES });
  const api = useApi();
  const lineChannelApi = useGetApiCall<LineChannelResponseIncludingSecret>("/line_channel?include_secret=true");
  const lineChannel = lineChannelApi.response ?? null;
  const status = lineChannelApi.status;

  const submit = async (params: LineSettingFormType) => {
    const response = await api.api.post("/line_channel", params);
    if (!response) return;

    window.location.reload();
  };

  const deleteIntegration = async () => {
    const response = await api.api.delete("/line_channel");
    if (!response) return;

    closeDeleteConfirmationDialog();
    window.location.reload();
  };

  if (status === null) {
    return (
      <Box display="flex" justifyContent="center" py={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <StatusBar published={status === 200} label={status === 200 ? "有効" : "無効"} />

      {lineChannel && (
        <Section title="LINE連携">
          <Box mb={2}>
            <FormLabel label="LINEのChannel ID" />
            <Typography variant="body1">
              {lineChannel.secret.line_channel_id}
            </Typography>
          </Box>

          <Box mb={2}>
            <FormLabel label="LINEのChannel Secret" />
            <Typography variant="body1">
              {lineChannel.secret.line_channel_secret}
            </Typography>
          </Box>

          <Box mb={2}>
            <FormLabel label="LIFF ID" />
            <Typography variant="body1">
              {lineChannel.line_channel.liff_id}
            </Typography>
          </Box>

          <Box mb={2}>
            <FormLabel label="連携しているLINE公式アカウント" />
            <Typography variant="body1">
              {lineChannel.line_bot.displayName} ({lineChannel.line_bot.basicId})
            </Typography>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button color="danger" onClick={openDeleteConfirmationDialog}>
              連携を解除する
            </Button>
          </Box>

          <FormChangedAlertModal
            disabled={api.loading}
            show={isDeleteConfirmationDialogOpen}
            submitButtonLabel="連携を解除する"
            onSubmit={deleteIntegration}
            onCancel={closeDeleteConfirmationDialog}
            danger
            title="LINE連携の解除"
            needAgreement
          >
            本当にLINE連携を解除してよろしいですか？

            <Alert severity="danger">
              <ul>
                <li>LINEからトイポのミニアプリにアクセスできなくなります。</li>
                <li>トイポからお知らせやクーポンを配信した際、LINE公式アカウントから通知することができなくなります。</li>
              </ul>
            </Alert>
          </FormChangedAlertModal>
        </Section>
      )}

      {!lineChannel && (
        <Section title="LINE連携">
          <Box mb={2}>
            <FormLabel label="LINEのChannel ID" />
            <Controller
              control={control}
              name="line_channel_id"
              rules={{ required: "Channel IDを入力してください。" }}
              render={({ onChange, value }) => (
                <OutlinedInput
                  fullWidth
                  error={Boolean(errors.line_channel_id)}
                  value={value}
                  disabled={api.loading || lineChannelApi.loading}
                  onChange={onChange}
                />
              )}
            />
            {errors.line_channel_id && (
              <FormHelperText error>{errors.line_channel_id?.message}</FormHelperText>
            )}
          </Box>

          <Box mb={2}>
            <FormLabel label="LINEのChannel Secret" />
            <Controller
              control={control}
              name="line_channel_secret"
              rules={{ required: "Channel Secretを入力してください。" }}
              render={({ onChange, value }) => (
                <OutlinedInput
                  fullWidth
                  error={Boolean(errors.line_channel_secret)}
                  value={value}
                  disabled={api.loading || lineChannelApi.loading}
                  onChange={onChange}
                />
              )}
            />
            {errors.line_channel_secret && (
              <FormHelperText error>{errors.line_channel_secret?.message}</FormHelperText>
            )}
          </Box>

          <Box mb={2}>
            <FormLabel label="LIFF ID" />
            <Controller
              control={control}
              name="liff_id"
              rules={{ required: "LIFF IDを入力してください。" }}
              render={({ onChange, value }) => (
                <OutlinedInput
                  fullWidth
                  error={Boolean(errors.liff_id)}
                  value={value}
                  disabled={api.loading || lineChannelApi.loading}
                  onChange={onChange}
                />
              )}
            />
            {errors.liff_id && (
              <FormHelperText error>{errors.liff_id?.message}</FormHelperText>
            )}
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={api.loading || lineChannelApi.loading}
              variant="contained"
              color="submit"
              onClick={handleSubmit(submit)}
            >
              保存する
            </Button>
          </Box>
        </Section>
      )}
    </div>
  );
};

export default LineSettingView;
