import React from "react";
import styled from "@emotion/styled";
import { FormControl } from "react-bootstrap";
import { padding } from "../../../../lib/general";
import ConditionFormItem from "./ConditionFormItem";
import { TimeType } from "../../type";

type Props = {
  from: TimeType;
  to: TimeType;
  onChange: (timeFrom: TimeType, timeTo: TimeType) => void;
  style?: React.CSSProperties;
};

const TimeRangeFormItem = ({
  from,
  to,
  onChange,
  style = {}
}: Props): JSX.Element => {
  return (
    <ConditionFormItem label="時間帯" style={style}>
      <StyledFlexContainer>
        <StyledDatePickerContainer>
          <FormControl
            componentClass="select"
            value={from || "none"}
            onChange={(e: any) => onChange(e.target.value === "none" ? null : e.target.value, to)}
            style={{ color: from ? "#565656" : "#d9d9d9" }}
          >
            <option value="none">何時から</option>
            {[...Array(24)].map((_, i) => (
              <option key={i} value={`${padding(i, 2)}:00:00`}>{`${padding(i, 2)}:00`}から</option>
            ))}
          </FormControl>
        </StyledDatePickerContainer>

        <StyledFlexSpacer />

        <StyledDatePickerContainer>
          <FormControl
            componentClass="select"
            value={to || "none"}
            onChange={(e: any) => onChange(from, e.target.value === "none" ? null : e.target.value)}
            style={{ color: to ? "#565656" : "#d9d9d9" }}
          >
            <option value="none">何時まで</option>
            {[...Array(24)].map((_, i) => (
              <option key={i} value={`${padding(i, 2)}:59:59`}>{`${padding(i, 2)}:59`}まで</option>
            ))}
          </FormControl>
        </StyledDatePickerContainer>
      </StyledFlexContainer>
    </ConditionFormItem>
  );
};

const StyledFlexContainer = styled.div({
  display: "flex",
  alignItems: "center"
});
const StyledFlexSpacer = styled.div({
  width: 16,
  flexBasis: 16
});

const StyledDatePickerContainer = styled.div({
  flex: 1,
  position: "relative"
});

export default TimeRangeFormItem;
