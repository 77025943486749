import React, { useState } from "react";
import Compressor from "compressorjs";
import ImageTrimmingModal from "../Modal/ImageTrimmingModal";
import ImageInput from "../Image/ImageInput";

const imageCompressor = (data, imgWidth, imgHeight) =>
  new Promise(
    (resolve, reject) =>
      new Compressor(data, {
        quality: 1,
        maxWidth: imgWidth,
        maxHeight: imgHeight,
        mimeType: "image/jpeg",
        convertSize: 10000,
        success: (result) => {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      })
  );

const ImageTrimmingInput = ({
  doCompress,
  doTrimming,
  id,
  updateImage,
  canDelete,
  image,
  width,
  maxWidth,
  height,
  maxHeight,
  altMessage,
  deleteImage,
  doDisplayImage,
  inline,
  aspect,
}) => {
  const [doTrimmingModalOpen, setDoTrimmingModalOpen] = useState(false);
  const [trimmingImage, setTrimmingImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = {
    startLoading: () => setIsLoading(true),
    stopLoading: () => setIsLoading(false),
    closeTrimmingModal: () => setDoTrimmingModalOpen(false),
    uploadImage: (e) => {
      const data = e.target.files[0];

      if (!data) return;

      const imageElement = new Image();
      const reader = new FileReader();

      // propsによって圧縮するのか圧縮しないかを選択できる
      // 今後は圧縮以外のこともやりたいと思うので、その都度条件を分岐させる
      if (doCompress && data.size >= 1000000) {
        // doCompress かつ 1MB以上の時
        imageElement.onload = () => {
          let imgWidth = 1024;
          let imgHeight = 1024;
          // 画像Width(Height)取得
          if (imageElement.naturalWidth > imageElement.naturalHeight) {
            imgHeight *=
              imageElement.naturalHeight / imageElement.naturalWidth;
          } else {
            imgWidth *=
              imageElement.naturalWidth / imageElement.naturalHeight;
          }
          imageCompressor(data, imgWidth, imgHeight)
            .then((result) => reader.readAsDataURL(result))
            .catch((error) => console.log(error));
        };
      } else {
        reader.readAsDataURL(data);
      }

      reader.onload = () => {
        // トリミング
        if (doTrimming) {
          setDoTrimmingModalOpen(true);
          setTrimmingImage(reader.result);
        } else {
          onClick.doneTrimmingImage(reader.result);
        }
      };

      if (data) {
        imageElement.src = URL.createObjectURL(data);
      };
    },
    doneTrimmingImage: (croppedImage) => {
      setDoTrimmingModalOpen(false);
      // updateImage関数が非同期処理のものとそうじゃないもので混在しているためid!==undefined(非同期処理)はLoadingを使い、それ以外のものにはLoadingをかけていません
      if (id !== undefined) {
        updateImage(croppedImage, id).then(() => {
          onClick.stopLoading();
        });
      } else {
        updateImage(croppedImage);
      }
    },
  };

  return (
    <>
      <ImageInput
        startLoading={onClick.startLoading}
        canDelete={canDelete}
        image={image}
        width={width}
        maxWidth={maxWidth}
        height={height}
        maxHeight={maxHeight}
        aspect={aspect}
        altMessage={altMessage}
        isLoading={isLoading}
        uploadImage={onClick.uploadImage}
        deleteImage={() => {
          if (id !== undefined) {
            onClick.startLoading();
            deleteImage(id).then(() => {
              onClick.stopLoading();
            });
          } else {
            deleteImage();
          }
        }}
        doDisplayImage={doDisplayImage}
        inline={inline}
      />
      <ImageTrimmingModal
        id={id}
        open={doTrimmingModalOpen}
        aspect={aspect}
        image={trimmingImage}
        doneTrimmingImage={onClick.doneTrimmingImage}
        closeModal={onClick.closeTrimmingModal}
        startLoading={onClick.startLoading}
      />
    </>
  );
};

export default ImageTrimmingInput;
