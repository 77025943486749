import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Prompt } from "react-router";
import { Form } from "react-bootstrap";
import "flatpickr/dist/themes/material_blue.css";
import OpenTimeRow from "./OpenTimeRow";
import { useNotification } from "../../../providers/NotificationProvider";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { Card } from "../../../components/Card/Card";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import { compareValidateEventDate } from "../../../lib/validation";
import OpenTimeEntityContainer, { DefaultOpenTimeItem } from "../../../containers/entities/OpenTimeEntityContainer";
import { useApi } from "../../../lib/hooks";

const initialOpenData = (day: number): DefaultOpenTimeItem => ({
  id: null,
  title: "",
  start_hour: 0,
  start_minute: 0,
  end_hour: 0,
  end_minute: 0,
  is_all_day: false,
  is_holiday: false,
  start_day: day,
  end_day: day,
});

const openTimeData: DefaultOpenTimeItem[] = [...Array(8)].map((_, day) => initialOpenData(day));

const OpenTimeView = (): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { api } = useApi();
  const openTimeContainer = OpenTimeEntityContainer.useContainer();
  const { getDefaultOpenTime, updateDefaultOpenTimes, deleteDefaultOpenTime } = openTimeContainer.logic;
  const { currentStore } = useLoginContext();

  const methods = useForm({
    defaultValues: {
      openTimeActive: false, // 営業ステータス
      openTime: openTimeData, // 基本営業時間
    },
  });
  const { getValues, control, handleSubmit, setValue, formState, reset } = methods;

  const { isDirty } = formState;

  const { fields, remove, insert } = useFieldArray({
    control,
    name: "openTime",
    keyName: "fieldId",
  });

  const [loading, setLoading] = useState(false);

  const validateEndTime = (value: number, index: number) => {
    if (!getValues(`openTime[${index}].is_all_day`) && !value && (value ?? true)) {
      return "終了時間を設定してください";
    }

    const startHour = getValues(`openTime[${index}].start_hour`);
    const startMinute = getValues(`openTime[${index}].start_minute`);
    const endHour = getValues(`openTime[${index}].end_hour`);
    const endMinute = getValues(`openTime[${index}].end_minute`);
    const compareValidate = compareValidateEventDate({
      startHour,
      startMinute,
      endHour,
      endMinute,
    });
    if (!getValues(`openTime[${index}].is_all_day`) && !compareValidate.validation) {
      return compareValidate.message;
    }
    return true;
  };

  const openTimeActive = currentStore.open_time_active;
  useEffect(() => {
    getDefaultOpenTime()
      .then((openTime) => setValue("openTime", openTime))
      .catch((error) => showErrorNotification(error.message));
  }, [getDefaultOpenTime, setValue, showErrorNotification]);

  useEffect(() => {
    setValue("openTimeActive", openTimeActive);
  }, [setValue, openTimeActive]);

  const processFormData = useCallback(
    (data: DefaultOpenTimeItem[]) =>
      data.map((openItem) => {
        // 休日 or 24時間営業の場合
        if (openItem.is_holiday || openItem.is_all_day) {
          return {
            ...openItem,
            is_all_day: true,
            start_hour: null,
            start_minute: null,
            end_hour: null,
            end_minute: null,
          };
        }

        if (!openItem.is_all_day) {
          return {
            ...openItem,
            is_all_day: false,
          };
        }
        return openItem;
      }),
    []
  );

  const onSubmit = async (data: { openTimeActive: boolean; openTime: DefaultOpenTimeItem[] }) => {
    setLoading(true);

    // 営業ステータス
    const showOpenStatus = data.openTimeActive;
    const params = { open_time_active: showOpenStatus };
    const response = await api.patch("", params);
    if (!response) return;

    // 営業時間
    const defaultOpenTimeData = data.openTime;
    const formData = processFormData(defaultOpenTimeData);
    updateDefaultOpenTimes(formData)
      .then((response) => {
        reset({
          openTime: response,
          openTimeActive: showOpenStatus
        });

        showSuccessNotification("更新しました。");
      })
      .catch((error) => showErrorNotification(error.message))
      .finally(() => setLoading(false));
  };

  const onError = () => showErrorNotification("値が正しく入力されていません");

  const onClickAddButton = ({ day, index }: { day: number; index: number }) => {
    insert(index + 1, initialOpenData(day));
  };

  const onClickDeleteButton = (index: number) => {
    const openTimeId: number = getValues(`openTime[${index}].id`);
    if (openTimeId) {
      if (!window.confirm("この基本営業時間を削除します。よろしいですか？")) {
        return;
      }
      deleteDefaultOpenTime(openTimeId)
        .then(() => {
          setValue("openTime", getValues().openTime.filter((_, i) => i !== index));
          showSuccessNotification("削除しました。");
        })
        .catch((error) => showErrorNotification(error.message));
    } else {
      remove(index);
    }
  };

  return (
    <>
      <Card
        title="基本営業時間"
        content={
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <div style={{ marginBottom: "10px" }}>
                <Controller
                  name="openTimeActive"
                  control={control}
                  render={({ value, onChange }): JSX.Element => (
                    <Checkbox
                      number={1}
                      label="営業ステータスをアプリに表示する"
                      checked={value}
                      onChange={(): void => onChange(!value)}
                    />
                  )}
                />
              </div>

              <div style={{ display: "grid", rowGap: "17px" }}>
                {fields.map((field, index) => (
                  <OpenTimeRow
                    key={field.fieldId}
                    field={field}
                    openTimes={fields}
                    index={index}
                    onClickAddButton={onClickAddButton}
                    onClickDeleteButton={onClickDeleteButton}
                    validateEndTime={validateEndTime}
                  />
                ))}
              </div>

              <div style={{ marginTop: "20px" }} />
              <LoadingButton
                type="submit"
                label="更新する"
                loadingLabel="更新中..."
                color="info"
                fill
                pullRight
                loading={loading}
                disabled={!isDirty}
              />
              <div className="clearfix" />
            </Form>
          </FormProvider>
        }
      />
      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </>
  );
};

export default OpenTimeView;
