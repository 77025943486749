import React, { useState, useReducer } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router";
import { Grid, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { Pagination, CircularProgress } from "@mui/material";
import { PersonSearch } from "@mui/icons-material";
import { usePcSizeFlag, useQuery, useApi, useBooleanState, useOnceEffect } from "../../lib/hooks";
import { SortOrder, DisplaySuccessNotification } from "../../types/common.d";
import { UserSortByType } from "../../types/user";
import { UserGroupCollectionResponse } from "../../types/api/userGroupCollection.d";
import { useLoginContext } from "../../providers/LoginContextProvider";
import ConditionEditor from "../../components/UserGroup/ConditionEditor";
import { ConditionGroup } from "../../components/UserGroup/UserGroupModal";
import { convertConditionGroupFrom } from "../../components/UserGroup/utils";
import { useContentInstanceFetcher } from "../../components/UserGroup/hooks";
import CustomButton from "../../components/CustomButton/CustomButton";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import OptionButton from "../../components/CustomButton/OptionButton";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import CustomerListLarge from "./parts/CustomerListLarge";
import CustomerListSmall from "./parts/CustomerListSmall";
import CustomerSearch from "./parts/CustomerSearch";
import UserGroupCollectionList from "./parts/UserGroupCollectionList";
import UserGroupCollectionNameModal from "./parts/UserGroupCollectionNameModal";
import SortButtonOnMobile from "./parts/SortButtonOnMobile";
import PublishDropdown from "./parts/PublishDropdown";
import { downloadCSVFile } from "../../lib/general";
import { useUserGroupCollectionApiForCustomer } from "./hooks";

type SearchParams = {
  page?: number;
  sort?: UserSortByType;
  order?: SortOrder;
  keyword?: string;
  conditionGroups?: ConditionGroup[] | null;
  userGroupCollectionId?: number | null;
};

type Props = {
  displaySuccessNotification: DisplaySuccessNotification;
};

const CustomerTableView = ({
  displaySuccessNotification
}: Props): JSX.Element => {
  const history = useHistory();
  const isPc = usePcSizeFlag();
  const query = useQuery();
  const userGroupApi = useApi();
  const exportUsersByCsvApi = useApi();
  const collectionDetailApi = useApi<UserGroupCollectionResponse>();
  const { api: searchApi, loading: searching, response: searchResponse, totalPages } = useApi();
  const users = searchResponse?.customers || [];
  const userCount = searchResponse?.user_count || 0;
  const allUserCount = searchResponse?.all_user_count || 0;
  const loading = searching || userGroupApi.loading;
  const [keyword, setKeyword] = useState<string>(query.get("keyword") || "");
  const [page, setPage] = useState<number>(Number(query.get("page")) || 1);
  const [sort, setSort] = useState<UserSortByType>("last_visit_at");
  const [order, setOrder] = useState<SortOrder>("desc");
  const [conditionGroups, setConditionGroups] = useState<ConditionGroup[]>([]);
  const [isNewModalOpen, openNewModal, closeNewModal] = useBooleanState(false);
  const [isEditModalOpen, openEditModal, closeEditModal] = useBooleanState(false);
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useBooleanState(false);
  const [selectedUserGroupCollectionResponse, setSelectedUserGroupCollectionResponse] = useState<UserGroupCollectionResponse | null>(null);
  const resetSelectedUserGroupCollection = () => setSelectedUserGroupCollectionResponse(null);
  const selectedUserGroupCollection = selectedUserGroupCollectionResponse?.user_group_collection;
  const [isConditionChanged, markAsConditionChanged, markAsConditionUnchanged] = useBooleanState(false);
  const [userGroupCollectionReRenderKey, reRenderUserGroupCollection] = useReducer(s => s + 1, 1);
  const { fetchAndSetInstance } = useContentInstanceFetcher();
  const userGroupCollectionApi = useUserGroupCollectionApiForCustomer(selectedUserGroupCollection?.id);
  const goToCustomerDetail = (id: number) => history.push(`customers/detail?user_id=${id}`);
  const { hasPermission } = useLoginContext();

  const exportCsv = async (searchConditionGroups) => {
    const response = await exportUsersByCsvApi.api.post(
      "/users/conditions.csv",
      { condition_groups: searchConditionGroups.map(({ conditions }) => conditions) },
      { responseType: "blob" }
    );
    if (!response) return;

    downloadCSVFile(response);
  };


  const searchUsers = async (override: SearchParams = {}) => {
    const params = {
      page: override.page || page,
      sort: override.sort || sort,
      order: override.order || order,
      keyword: override.keyword !== undefined ? override.keyword : keyword,
      user_group_collection_id:  override.userGroupCollectionId !== undefined
        ? override.userGroupCollectionId
        : selectedUserGroupCollection?.id,
      condition_groups: (override.conditionGroups || conditionGroups).map(({ conditions }) => conditions)
    };

    const queryParams: { [key:string]: string } = {
      page: String(params.page),
      sort: params.sort,
      order: params.order
    };

    if (params.keyword) {
      await searchApi.get("/users", params);

      queryParams.keyword = params.keyword;
      delete queryParams.user_group_collection_id;
      resetSelectedUserGroupCollection();
      setConditionGroups([]);
    } else {
      await searchApi.post("/users/conditions", params);

      delete queryParams.keyword;
      if (params.user_group_collection_id) {
        queryParams.user_group_collection_id = String(params.user_group_collection_id);
      }
      setKeyword("");
    }

    setPage(params.page);
    setSort(params.sort);
    setOrder(params.order);

    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/admin/customers?${queryString}`);
  };

  const fetchUserGroupCollection = async (userGroupCollectionId: number | null, override: SearchParams = {}) => {
    await searchUsers({ conditionGroups: [], userGroupCollectionId, page: override.page || page });

    if (userGroupCollectionId === null) {
      resetSelectedUserGroupCollection();
      setConditionGroups([]);
    } else {
      const response = await collectionDetailApi.api.get(`/user_group_collections/${userGroupCollectionId}`);
      const collection = response.data;
      setSelectedUserGroupCollectionResponse(collection);

      const newConditionGroups = collection.user_groups.map((userGroup) => (
        convertConditionGroupFrom(userGroup.user_group.id, userGroup.user_group_conditions)
      ));
      await fetchAndSetInstance(newConditionGroups);
      setConditionGroups(newConditionGroups);
    }

    markAsConditionUnchanged();
  };



  const handleUpdateUserGroupCollectionName = (name: string) => {
    userGroupCollectionApi.updateName(name, (id: number) => {
      fetchUserGroupCollection(id);
      reRenderUserGroupCollection();
      closeEditModal();
      displaySuccessNotification("ユーザーグループ名を変更しました。");
    });
  };

  const handleOverwriteUserGroupCondition = () => {
    userGroupCollectionApi.updateConditions(conditionGroups, (id: number) => {
      fetchUserGroupCollection(id);
      displaySuccessNotification("ユーザーグループの絞り込み条件を変更しました。");
    });
  };

  const handleCreateUserGroupCollection = (name: string) => {
    userGroupCollectionApi.create(name, conditionGroups, (id: number) => {
      fetchUserGroupCollection(id);
      reRenderUserGroupCollection();
      closeNewModal();
      displaySuccessNotification("ユーザーグループを作成しました。");
    });
  };

  const deleteSelectedUserGroupCollection = () => {
    userGroupCollectionApi.delete(() => {
      fetchUserGroupCollection(null);
      reRenderUserGroupCollection();
      closeDeleteModal();
      displaySuccessNotification("ユーザーグループが削除されました。");
    });
  };

  const handleClickPublishContent = async (url: string) => {
    if (selectedUserGroupCollection) {
      history.push(`${url}&user_group_collection_id=${selectedUserGroupCollection.id}`);
    } else {
      if (conditionGroups.length > 0) {
        const response = await userGroupApi.api.post("/user_groups/bulk", {
          condition_groups: conditionGroups.map(({ conditions }) => conditions)
        });
        const userGroupIds = response?.data.user_groups
          .map(({ user_group }) => user_group.id)
          .join(",");
        history.push(`${url}&user_group_ids=${userGroupIds}`);
      } else {
        history.push(url);
      }
    }
  };

  const handleChangeConditionGroups = (newConditionGroups: ConditionGroup[]) => {
    searchUsers({ conditionGroups: newConditionGroups, userGroupCollectionId: null, page: 1 });
    setConditionGroups(newConditionGroups);
    markAsConditionChanged();
  }

  const userGroupCollectionId = Number(query.get("user_group_collection_id"));
  useOnceEffect(() => fetchUserGroupCollection(userGroupCollectionId || null));

  const serchLoading = !searchResponse && searching;

  return (
    <div className="content">
      <Grid fluid>
        {hasPermission("END_POINT_SEARCH_USERS") && (
          <CustomerSearch
            defaultKeyword={query.get("keyword") || ""}
            placeholder={isPc ? "ユーザーID・名前・電話番号・会員コードで検索..." : "検索"}
            onSubmit={(newKeyword) => {
              setKeyword(newKeyword);
              searchUsers({ keyword: newKeyword, page: 1 });
            }}
          />
        )}

        <StyledContentContainer>
          {isPc && !keyword && (
            <StyledUserGroupCollectionList
              disabled={loading}
              renderingCount={userGroupCollectionReRenderKey}
              selectedUserGroupCollectionId={selectedUserGroupCollection?.id || null}
              onClickCollection={(id) => fetchUserGroupCollection(id, { page: 1 })}
            />
          )}

          <div style={{ flex: 1 }}>
            {!keyword && hasPermission("END_POINT_SEARCH_USERS") && (
              <UserGroupCollectionHeader
                disabled={loading}
                userGroupCollection={selectedUserGroupCollection}
                conditionGroups={conditionGroups}
                renderingCount={userGroupCollectionReRenderKey}
                onClickCollection={fetchUserGroupCollection}
                onEditUserGroupCollectionName={openEditModal}
                onDeleteUserGroupCollection={openDeleteModal}
                onChangeConditionGroups={handleChangeConditionGroups}
              />
            )}

            <StyledToolbarContainer isPc={isPc}>
              <StyledMobileToolbarContainer>
                <StyledUserCountContainer>
                  <span>{userCount.toLocaleString()}</span>人
                  {userCount < allUserCount && (
                    <>
                      <span> / </span>
                      <span>{allUserCount.toLocaleString()}</span>人
                    </>
                  )}
                </StyledUserCountContainer>

                {!isPc && (
                  <SortButtonOnMobile
                    onSort={(newSort, newOrder) =>
                      searchUsers({ sort: newSort, order: newOrder })
                    }
                  />
                )}
              </StyledMobileToolbarContainer>

              {!keyword && (
                <UserGroupCollectionButtons
                  disabledUserGroupButtons={
                    loading ||
                    conditionGroups.length === 0 ||
                    !isConditionChanged
                  }
                  disabledPublishButton={loading}
                  overwritable={selectedUserGroupCollection?.is_protected !== true}
                  onCreateUserGroupCollection={openNewModal}
                  onUpdateUserGroupCollection={handleOverwriteUserGroupCondition}
                  onExportToCsv={() => exportCsv(conditionGroups)}
                  onClickPublishButton={handleClickPublishContent}
                />
              )}
            </StyledToolbarContainer>

            {serchLoading && (
              <StyledProgressContainer>
                <CircularProgress />
              </StyledProgressContainer>
            )}

            {!serchLoading &&
              (userCount > 0 ? (
                <>
                  {isPc ? (
                    <CustomerListLarge
                      onClickCustomer={goToCustomerDetail}
                      users={users}
                      sortBy={sort}
                      sortOrder={order}
                      onSort={(newSort, newOrder) => searchUsers({ sort: newSort, order: newOrder })}
                    />
                  ) : (
                    <CustomerListSmall
                      users={users}
                      onClickCustomer={goToCustomerDetail}
                    />
                  )}

                  {totalPages > 1 && (
                    <Pagination
                      disabled={loading}
                      count={totalPages}
                      page={page || 1}
                      onChange={(_, newValue) => searchUsers({ page: newValue })}
                    />
                  )}
                </>
              ) : (
                <StyledNoCustomer>
                  <PersonSearch />
                  条件に該当するユーザーが見つかりません
                </StyledNoCustomer>
              ))}
          </div>
        </StyledContentContainer>
      </Grid>

      <UserGroupCollectionNameModal
        disabled={userGroupCollectionApi.loading}
        show={isNewModalOpen}
        onHide={closeNewModal}
        title="ユーザーグループを作成"
        submitButtonLabel="作成する"
        onSubmit={handleCreateUserGroupCollection}
      />

      <UserGroupCollectionNameModal
        disabled={userGroupCollectionApi.loading}
        show={isEditModalOpen}
        onHide={closeEditModal}
        title="ユーザーグループを編集"
        submitButtonLabel="変更を保存"
        defaultName={selectedUserGroupCollection?.name}
        onSubmit={handleUpdateUserGroupCollectionName}
      />

      <ConfirmModal
        disabled={userGroupCollectionApi.loading}
        show={isDeleteModalOpen}
        title="ユーザーグループを削除"
        okButtonTitle="削除する"
        cancelButtonTitle="キャンセル"
        okButtonBsStyle="danger"
        onClickCancelButton={closeDeleteModal}
        onClickOkButton={deleteSelectedUserGroupCollection}
      >
        ユーザーグループ
        <span style={{ fontWeight: "bold", padding: "0 4px" }}>
          {selectedUserGroupCollection?.name}
        </span>
        を削除します。よろしいですか？
      </ConfirmModal>
    </div>
  );
};

const UserGroupCollectionHeader = ({
  disabled,
  userGroupCollection,
  conditionGroups,
  renderingCount,
  onClickCollection,
  onEditUserGroupCollectionName,
  onDeleteUserGroupCollection,
  onChangeConditionGroups
}): JSX.Element => {
  const isPc = usePcSizeFlag();

  return (
    <>
      <div style={{ display: "flex" }}>
        {isPc ? (
          <StyledCollectionName>
            {userGroupCollection?.name || "全体"}
          </StyledCollectionName>
        ) : (
          <StyledCollectionNameDropdownButton
            title={userGroupCollection?.name || "全体"}
            id="dropdown-menu-list"
          >
            <UserGroupCollectionList
              disabled={disabled}
              renderingCount={renderingCount}
              selectedUserGroupCollectionId={userGroupCollection?.id || null}
              onClickCollection={onClickCollection}
            />
          </StyledCollectionNameDropdownButton>
        )}

        {userGroupCollection?.is_protected === false && (
          <OptionButton
            options={[{
              label: "ユーザーグループ名を編集",
              onClick: onEditUserGroupCollectionName
            }, {
              label: "このユーザーグループを削除",
              labelColor: "#FF4A55",
              onClick: onDeleteUserGroupCollection
            }]}
          />
        )}
      </div>

      {isPc && (
        <ConditionEditor
          disabled={disabled}
          conditionGroups={conditionGroups}
          style={{ marginTop: 16 }}
          onChangeConditionGroups={onChangeConditionGroups}
        />
      )}
    </>
  );
};

const UserGroupCollectionButtons = ({
  disabledUserGroupButtons,
  disabledPublishButton,
  overwritable,
  onCreateUserGroupCollection,
  onUpdateUserGroupCollection,
  onExportToCsv,
  onClickPublishButton
}): JSX.Element => {
  const { fromWebView, hasPermission } = useLoginContext();
  const isPc = usePcSizeFlag();

  return (
    <StyledButtonsContainer>
      {isPc && hasPermission("END_POINT_SEARCH_USERS") && (overwritable ? (
        <ButtonToolbar style={{ marginRight: 8 }}>
          <DropdownButton
            id="dropdown-menu-list"
            className="btn-info"
            title={<strong>この条件でユーザーグループを設定</strong>}
            disabled={disabledUserGroupButtons}
          >
            <MenuItem onClick={onCreateUserGroupCollection}>新しいユーザーグループとして作成</MenuItem>
            <MenuItem onClick={onUpdateUserGroupCollection}>このユーザーグループへ上書き</MenuItem>
          </DropdownButton>
        </ButtonToolbar>
      ) : (
        <CustomButton
          data-cy="open-name-modal-button"
          bsStyle="info"
          style={{ fontWeight: "bold", marginRight: 8 }}
          onClick={onCreateUserGroupCollection}
          disabled={disabledUserGroupButtons}
        >
          この条件でユーザーグループを作成
        </CustomButton>
      ))}

      {!fromWebView && hasPermission("DOWNLOAD_USER_CSV") && (
        <LoadingButton
          label="CSV出力"
          loadingLabel="出力中..."
          bsStyle="info"
          style={{ fontWeight: "bold", marginRight: 8 }}
          onClick={onExportToCsv}
        />
      )}

      <PublishDropdown
        title="配信する"
        disabled={disabledPublishButton}
        onMovePage={onClickPublishButton}
      />
    </StyledButtonsContainer>
  );
};

const StyledContentContainer = styled.div({
  backgroundColor: "white",
  borderRadius: 4,
  border: "1px solid #E3E3E5",
  padding: 16,
  marginTop: 24,
  display: "flex"
});

const StyledCollectionName = styled.div({
  fontWeight: "bold",
  fontSize: 24,
  marginRight: 8
});
const StyledCollectionNameDropdownButton = styled(DropdownButton)({
  fontWeight: "bold",
  fontSize: 24,
  marginRight: 8,
  color: "#000",
  borderWidth: 0,
  padding: 0
});

const StyledUserGroupCollectionList = styled(UserGroupCollectionList)({
  width: 220,
  borderRight: "1px solid #E3E3E5",
  paddingRight: 16,
  marginRight: 16
});

const StyledToolbarContainer = styled.div<{ isPc: boolean }>(({ isPc }) => ({
  marginTop: 16,
  marginBottom: 16,
  ...(isPc ? {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    flexWrap: "wrap",
  } : {}),
}));
const StyledMobileToolbarContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const StyledButtonsContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 8
});

const StyledProgressContainer = styled.div({
  display: "flex",
  justifyContent: "center"
});
const StyledUserCountContainer = styled.div({
  fontWeight: "bold",
  fontSize: 16,
  "& > span:last-of-type": { fontSize: 20 },
  "& > span:first-of-type": { fontSize: 28 }
});

const StyledNoCustomer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 32,
  "& > svg": {
    fontSize: 40,
    color: "#757575"
  }
});

export default CustomerTableView;
