import React from "react";
import FeatureCard from "../../../components/FeatureCard/NewFeatureCard";

const FeaturesBlock = ({
  sectionTitle,
  features,
  setFeatureModal,
  setSelectModal,
  isWalkThrough,
  minWidth1000px,
}) =>
  features.length > 0 ? (
    <div style={{ marginBottom: 32 }}>
      <div style={{ fontWeight: "bold", fontSize: 18 }}>{sectionTitle}</div>
      <div
        style={{
          display: "flex",
          gap: 16,
          flexWrap: minWidth1000px ? "wrap" : "nowrap",
          overflowX: minWidth1000px ? "visible" : "scroll",
          padding: "16px 0",
        }}
      >
        {features.map((feature) => (
          <FeatureCard
            key={feature.title}
            title={feature.title}
            description={feature.description}
            icon={feature.icon.icon}
            color={feature.icon.color}
            showMoreInfoLink={feature.showMoreInfoLink}
            onClickDetail={() => {
              setFeatureModal(true);
              setSelectModal({
                selectActive: feature.selectActive,
                active: feature.active,
                type: feature.function,
                updateFeature: feature.onChange,
              });
            }}
            onClickAddButton={() =>
              feature.onChange && feature.onChange(true, !isWalkThrough)
            }
            onClickRemoveButton={() =>
              feature.onChange && feature.onChange(false, !isWalkThrough)
            }
            active={feature.active}
            note={feature?.note}
          />
        ))}
      </div>
    </div>
  ) : null;

export default FeaturesBlock;
