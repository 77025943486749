import { numberOrNull } from "../../lib/general";
import { GenderLabel, Generations, GenerationLabel } from "../../constants/user";
import { Condition, ConditionType, ConditionGroup } from "./type";
import { UserGroupConditionResource } from "../../types/userGroup";
import { ThirdPartyServiceLabel } from "../../constants/user";

let globalConditionGroupIndex = 0;
export const generateConditionGroup = (conditions: Condition[] = []): ConditionGroup => ({
  key: `default-${++globalConditionGroupIndex}`,
  conditions
});

let globalConditionIndex = 0;
export const generateDefaultCondition = (conditionType: ConditionType = ""): Condition => ({
  key: `default-${++globalConditionIndex}`,
  condition_type: conditionType,
  scope_from: null,
  scope_to: null,
  relative_beginning_scope: null,
  relative_end_scope: null,
  value_from: null,
  value_to: null,
  time_from: null,
  time_to: null,
  instance_id: null
});

export const convertConditionGroupFrom = (
  userGroupId: number,
  userGroupConditions: UserGroupConditionResource[]
): ConditionGroup => ({
  key: `user-group${userGroupId}`,
  conditions: userGroupConditions.map((condition) => ({
    ...condition,
    key: `user-group-condition${condition.id}`
  }))
});

export const getErrors = (conditions: Condition[]) => {
  const errors = {};

  conditions.forEach((condition)=>{
    const add = (message: string) => { errors[condition.key] = message; };

    const isScopeInvalid = condition.scope_from === null && condition.scope_to === null;
    const isRelativeScopeInvalid = condition.relative_beginning_scope === null && condition.relative_end_scope === null;
    const isValueInvalid = condition.value_from === null && condition.value_to === null;
    const isValueInvalidOrZero = (condition.value_from || 0) === 0 && (condition.value_to || 0) === 0;

    switch (condition.condition_type) {
      case "third_party_service":
        if (isValueInvalid) add("利用サービスを選択してください");
        break;

      case "members_card_rank":
        if (isValueInvalid) add("ランクを選択してください");
        break;

      case "user_gender":
        if (isValueInvalid) add("性別を選択してください");
        break;

      case "user_generation":
        if (isValueInvalid) add("年代を選択してください");
        break;

      case "store_connection":
        if (isScopeInvalid && isRelativeScopeInvalid) add("つながった日を指定してください");
        break;

      case "visit_count":
        if (isValueInvalid) add("来店回数を入力してください");
        break;

      case "last_visit":
        if (isScopeInvalid && isRelativeScopeInvalid) add("最終来店日を指定してください");
        break;

      case "visit_frequency":
        if (isValueInvalidOrZero) add("来店頻度を指定してください");
        break;

      case "coupon_received_count":
        if (isValueInvalid) add("獲得回数を指定してください");
        break;

      case "coupon_used_count":
        if (isValueInvalid) add("利用回数を指定してください");
        break;

      case "notice_read":
        if (condition.value_from === null) add("お知らせの閲覧を選択してください");
        break;

      case "mobile_order":
      case "mobile_order_takeout":
      case "mobile_order_delivery":
      case "mobile_order_preorder":
      case "mobile_order_ec":
      case "mobile_order_by_order_date":
      case "mobile_order_takeout_by_order_date":
      case "mobile_order_delivery_by_order_date":
      case "mobile_order_preorder_by_order_date":
      case "mobile_order_ec_by_order_date":
        if (isValueInvalid) add("注文回数を指定してください");
        break;

      case "subscription_purchased_count":
        if (condition.value_from === null) add("サブスクの購入を指定してください");
        break;

      case "subscription_used_count":
        if (isValueInvalid) add("利用回数を指定してください");
        break;

      case "subscription_updated_count":
        if (isValueInvalid) add("更新回数を指定してください");
        break;

      default:
        if (!condition.condition_type) add("絞り込み条件を選択してください");
        break;
    }
  })

  return errors;
};

const generateScopeAndTimeRangeText = (condition: Condition) => {
  const scopeText = generateScopeText(condition);
  const timeRangeText = generateTimeRangeText(condition);

  if (scopeText) {
    if (timeRangeText) {
      // scopeTextには最後に"に"という助詞がついたテキストが生成されているが
      // ここに時間帯の文言を入れる場合、"に"という文字ではなく"の"という文字の方が
      // 自然につながるため、その変換をしている。例えば
      // - yyyy/mm/dd以降"に" -> yyyy/mm/dd以降"の"10時〜12時
      // - x日前"に" -> x日前"の"13時〜20時
      // - 今日 -> 今日"の"14時〜15時 ("に"という助詞は元々ついていないので、そのまま"の"をつけているだけ)
      const adjustedScopeText = scopeText.slice(-1) === "に"
        ? scopeText.substring(0, scopeText.length - 1)
        : scopeText;
      return adjustedScopeText + "の" + timeRangeText;
    } else {
      return scopeText;
    }
  }

  return "";
};

const generateScopeText = (condition: Condition) => {
  const { scope_from, scope_to, relative_beginning_scope, relative_end_scope } = condition;
  const formattedScopeFrom = scope_from ? new Date(scope_from).toLocaleDateString() : null;
  const formattedScopeTo = scope_to ? new Date(scope_to).toLocaleDateString() : null;

  if (formattedScopeFrom && !formattedScopeTo) return `${formattedScopeFrom}以降に`;
  if (!formattedScopeFrom && formattedScopeTo) return `${formattedScopeTo}以前に`;
  if (formattedScopeFrom && formattedScopeTo) {
    return formattedScopeFrom === formattedScopeTo
      ? `${formattedScopeFrom}に`
      : `${formattedScopeFrom} 〜 ${formattedScopeTo}の間に`;
  }

  if (relative_beginning_scope === 0 && relative_end_scope === 0) return "今日";
  if (relative_beginning_scope === 1 && relative_end_scope === 1) return "昨日";
  if (relative_beginning_scope && !relative_end_scope) return `今日から${relative_beginning_scope}日以内に`;
  if (!relative_beginning_scope && relative_end_scope) return `今日から${relative_end_scope}日以上前に`;
  if (relative_beginning_scope && relative_end_scope) {
    return relative_beginning_scope === relative_end_scope
      ? `今日から${relative_beginning_scope}日前に`
      : `今日から${relative_end_scope} 〜 ${relative_beginning_scope}日前に`;
  }

  return "";
};

const generateTimeRangeText = (condition: Condition) => {
  const { time_from, time_to } = condition;
  const formattedFrom = time_from?.substring(0, 5);
  const formattedTo = time_to?.substring(0, 5);

  if (formattedFrom && !formattedTo) return `${formattedFrom} 〜 23:59の間に`;
  if (!formattedFrom && formattedTo) return `00:00 〜 ${formattedTo}の間に`;
  if (formattedFrom && formattedTo) return `${formattedFrom} 〜 ${formattedTo}の間に`;

  return "";
};

const MobileOrderLabel = {
  mobile_order: "モバイルオーダー",
  mobile_order_takeout: "テイクアウト",
  mobile_order_delivery: "デリバリー",
  mobile_order_preorder: "プレオーダー",
  mobile_order_ec: "オンラインショップ",
  mobile_order_by_order_date: "モバイルオーダー",
  mobile_order_takeout_by_order_date: "テイクアウト",
  mobile_order_delivery_by_order_date: "デリバリー",
  mobile_order_preorder_by_order_date: "プレオーダー",
  mobile_order_ec_by_order_date: "オンラインショップ"
} as const;

export const generateDescription = (condition: Condition) => {
  switch (condition.condition_type) {
    case "members_card_rank":
      return condition.rank
        ? `会員ランクが${condition.rank.name}`
        : "";

    case "user_gender":
      return condition.value_from !== null
        ? `性別が${GenderLabel[condition.value_from]}`
        : "";

    case "user_generation":
      return condition.value_from !== null
        ? GenerationLabel[Generations[Number(condition.value_from) / 10]]
        : "";

    case "third_party_service":
        return condition.value_from !== null
          ? `${ThirdPartyServiceLabel[condition.value_from]}を利用している`
          : "";

    case "store_connection": {
      const scopeText = generateScopeAndTimeRangeText(condition);
      return scopeText ? `${scopeText}店舗とつながった` : "";
    }

    case "visit_count": {
      const scopeText = generateScopeAndTimeRangeText(condition);
      const valueText = ((from, to) => {
        if (from === 0 && to === 0) return scopeText ? "来店してない" : "来店したことがない";
        if (from && !to) return `${from}回以上来店した`;
        if ((!from || from === 1) && to) return `${to}回以下しか来店してない`;
        if (from && to) {
          if (from === to) return `${from}回来店した`;
          return `${from} 〜 ${to}回来店した`;
        }

        return "";
      })(numberOrNull(condition.value_from), numberOrNull(condition.value_to));

      if (valueText) return (scopeText || "") + valueText;

      return "";
    }

    case "last_visit": {
      const scopeText = generateScopeAndTimeRangeText(condition);

      if (scopeText) return `${scopeText}最後の来店があった`;

      return "";
    }

    case "visit_frequency": {
      const valueText = ((from, to) => {
        if (from && !to) return `来店の間隔が${from}日以上あいている`;
        if (!from && to) return `少なくとも${to}日に1回は来店している`;
        if (from && to) {
          if (from === to) return `${from === 1 ? "毎日" : `${from}日に1回`}来店している`;
          return `${from} 〜 ${to}日に1回来店している`;
        }
        return "";
      })(numberOrNull(condition.value_from), numberOrNull(condition.value_to));

      if (valueText) return `2回以上来店したことがあり、${valueText}`;

      return "";
    }

    case "coupon_received_count":
    case "coupon_used_count": {
      const couponName = condition.couponContent?.benefit || "クーポン";
      const verb = condition.condition_type === "coupon_received_count" ? "獲得" : "利用";
      const scopeText = generateScopeAndTimeRangeText(condition);
      const valueText = ((from, to) => {
        if (from === 0 && to === 0) return scopeText ? `${verb}していない` : `${verb}したことがない`;
        if (from && !to) return `${from}回以上${verb}した${scopeText ? "" : "ことがある"}`;
        if (!from && to) return `${to}回以下${verb}した${scopeText ? "" : "ことがある"}`;
        if (from && to) {
          if (from === to) return `${from}回${verb}した${scopeText ? "" : "ことがある"}`;
          return `${from} 〜 ${to}回${verb}した${scopeText ? "" : "ことがある"}`;
        }
        return "";
      })(numberOrNull(condition.value_from), numberOrNull(condition.value_to));

      if (!scopeText && !valueText) return "";
      if (!scopeText && valueText) return `${couponName}を${valueText}`;
      if (scopeText && valueText) return `${couponName}を${scopeText}${valueText}`;

      return ""
    }

    case "notice_read": {
      if (condition.value_from === null) return "";

      const noticeContentName = condition.noticeContent?.title || "お知らせ";
      const scopeText = generateScopeAndTimeRangeText(condition);
      const valueText = condition.value_from === "0" ? "閲覧したことがない" : "閲覧した";

      return scopeText
        ? `${noticeContentName}を${scopeText}${valueText}`
        : `${noticeContentName}を${valueText}`;
    }

    case "mobile_order":
    case "mobile_order_takeout":
    case "mobile_order_delivery":
    case "mobile_order_preorder":
    case "mobile_order_ec":
    case "mobile_order_by_order_date":
    case "mobile_order_takeout_by_order_date":
    case "mobile_order_delivery_by_order_date":
    case "mobile_order_preorder_by_order_date":
    case "mobile_order_ec_by_order_date": {
      const mobileOrderType = MobileOrderLabel[condition.condition_type];
      const scopeText = generateScopeAndTimeRangeText(condition);
      const menuName = condition.menu?.title || "商品";
      const verb = condition.condition_type.indexOf("_by_order_date") > 0 ? "注文した" : "受け取った";

      const valueText = ((from, to) => {
        if (from === 0 && to === 0) return `${menuName}を${verb}ことがない`;
        if (from && !to) return `${from}回以上${menuName}を${verb}${scopeText ? "" : "ことがある"}`;
        if (!from && to) return `${to}回以下${menuName}を${verb}${scopeText ? "" : "ことがある"}`;
        if (from && to) {
          if (from === to) return `${from}回${menuName}を${verb}${scopeText ? "" : "ことがある"}`;
          return `${from} 〜 ${to}回${menuName}を${verb}${scopeText ? "" : "ことがある"}`;
        }
        return ""
      })(numberOrNull(condition.value_from), numberOrNull(condition.value_to));

      if (!scopeText && !valueText) return "";
      if (!scopeText && valueText) return `${mobileOrderType}で${valueText}`;
      if (scopeText && valueText) return `${mobileOrderType}で${scopeText}${valueText}`;

      return ""
    }

    case "subscription_contracted": {
      console.log({ condition });
      const subscriptionContentName = condition.subscriptionContent?.title;
      return subscriptionContentName
        ? `${subscriptionContentName}を現在契約中`
        : "契約中のサブスクがある";
    }

    case "subscription_paused": {
      const subscriptionContentName = condition.subscriptionContent?.title;
      return subscriptionContentName
        ? `${subscriptionContentName}が現在一時停止中`
        : "一時停止中のサブスクがある";
    }

    case "subscription_canceled": {
      const subscriptionContentName = condition.subscriptionContent?.title;
      return subscriptionContentName
        ? `${subscriptionContentName}を解約済み`
        : "解約済みのサブスクがある";
    }

    case "subscription_no_contracted": {
      const subscriptionContentName = condition.subscriptionContent?.title;
      return subscriptionContentName
        ? `${subscriptionContentName}を契約していない`
        : "サブスクを契約していない";
    }

    case "subscription_purchased_count": {
      if (condition.value_from === null) return "";

      const subscriptionContentName = condition.subscriptionContent?.title || "サブスク";
      const scopeText = generateScopeAndTimeRangeText(condition);
      const valueText = scopeText
        ? "購入した"
        : condition.value_from === "0"
        ? "購入したことがない"
        : "購入したことがある";

      return scopeText
        ? `${subscriptionContentName}を${scopeText}${valueText}`
        : `${subscriptionContentName}を${valueText}`;
    }

    case "subscription_used_count": {
      const couponName = condition.subscriptionContent?.title || "サブスク";
      const scopeText = generateScopeAndTimeRangeText(condition);
      const valueText = ((from, to) => {
        if (from === 0 && to === 0) return scopeText ? "利用していない" : "利用したことがない";
        if (from && !to) return `${from}回以上利用した${scopeText ? "" : "ことがある"}`;
        if (!from && to) return `${to}回以下利用した${scopeText ? "" : "ことがある"}`;
        if (from && to) {
          if (from === to) return `${from}回利用した${scopeText ? "" : "ことがある"}`;
          return `${from} 〜 ${to}回利用した${scopeText ? "" : "ことがある"}`;
        }
        return "";
      })(numberOrNull(condition.value_from), numberOrNull(condition.value_to));

      if (!scopeText && !valueText) return "";
      if (!scopeText && valueText) return `${couponName}を${valueText}`;
      if (scopeText && valueText) return `${couponName}を${scopeText}${valueText}`;

      return ""
    }

    case "subscription_updated_count": {
      const couponName = condition.subscriptionContent?.title || "サブスク";
      const scopeText = generateScopeAndTimeRangeText(condition);
      const valueText = ((from, to) => {
        if (from === 0 && to === 0) return scopeText ? "更新していない" : "更新したことがない";
        if (from && !to) return `${from}回以上更新した${scopeText ? "" : "ことがある"}`;
        if (!from && to) return `${to}回以下更新した${scopeText ? "" : "ことがある"}`;
        if (from && to) {
          if (from === to) return `${from}回更新した${scopeText ? "" : "ことがある"}`;
          return `${from} 〜 ${to}回更新した${scopeText ? "" : "ことがある"}`;
        }
        return "";
      })(numberOrNull(condition.value_from), numberOrNull(condition.value_to));

      if (!scopeText && !valueText) return "";
      if (!scopeText && valueText) return `${couponName}を${valueText}`;
      if (scopeText && valueText) return `${couponName}を${scopeText}${valueText}`;

      return ""
    }

    default:
      break;
  };

  return "";
};

export const hasScope = (condition: Condition) => (
  condition.scope_from !== null ||
    condition.scope_to !== null ||
    condition.relative_beginning_scope !== null ||
    condition.relative_end_scope !== null
);
