import React, { useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";

// components
import { Card } from "../../../components/Card/Card";
import Button from "../../../components/CustomButton/CustomButton";

// container
import {
  DiscountLogFilteringCondition,
  FilteringPeriod,
} from "../../../containers/entities/DiscountEntityContainer";

// parts
import DiscountPeriodElement from "./DiscountPeriodElement";
import DiscountPeriodFilteringModal from "./DiscountPeriodFilteringModal";

type Props = {
  displayNotification: ({
    level,
    message,
  }: {
    level: "success" | "error";
    message: string;
  }) => void;
  changeUrl: ({ content_page, log_page, condition, period }) => void;
  filteringPeriod: FilteringPeriod;
  discountLogFilteringCondition: DiscountLogFilteringCondition;
};

const DiscountPeriodCard: React.FC<Props> = ({
  displayNotification,
  changeUrl,
  filteringPeriod,
  discountLogFilteringCondition,
}) => {
  // modal
  const [showFilteringModal, setShowFilteringModal] = useState(false);

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <Card
            title="期間"
            ctTableFullWidth
            titleRightContent={
              <DiscountPeriodElement filteringPeriod={filteringPeriod} />
            }
            rightContent={
              <MediaQuery query="(min-width: 530px)">
                <Button
                  onClick={(): void => setShowFilteringModal(true)}
                  style={{ marginRight: 10 }}
                >
                  <b>期間絞り込み</b>
                </Button>
              </MediaQuery>
            }
            content={
              <MediaQuery query="(max-width: 529px)">
                <Button
                  onClick={(): void => setShowFilteringModal(true)}
                  style={{ marginRight: 10 }}
                >
                  <b>期間絞り込み</b>
                </Button>
              </MediaQuery>
            }
          />
        </Col>
      </Row>

      <DiscountPeriodFilteringModal
        show={showFilteringModal}
        onHide={(): void => setShowFilteringModal(false)}
        displayNotification={displayNotification}
        changeUrl={changeUrl}
        filteringPeriod={filteringPeriod}
        discountLogFilteringCondition={discountLogFilteringCondition}
      />
    </Grid>
  );
};

export default DiscountPeriodCard;
