import React from "react";
import Switch from "react-switch";

const CustomSwitch = ({
  width = 96,
  height = 32,
  offColor = "#DDDDDD",
  onColor = "#0076d7",
  uncheckedText = "無効",
  checkedText = "有効",
  checked,
  onChange,
  inputRef,
  ...props
}) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      offColor={offColor}
      onColor={onColor}
      width={width}
      height={height}
      ref={inputRef}
      uncheckedIcon={
        <div
          style={{
            marginLeft: -15,
            display: "flex",
            fontSize: 14,
            fontWeight: "bold",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {uncheckedText}
        </div>
      }
      checkedIcon={
        <div
          style={{
            marginLeft: 15,
            display: "flex",
            fontSize: 14,
            fontWeight: "bold",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {checkedText}
        </div>
      }
      {...props}
    />
  );
};

export default CustomSwitch;
