import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Button from "./CustomButton";

// このコンポーネントは古いreact-bootstrapを利用しているためDeprecatedにします。
//
// 注:
// このコンポーネントをformのtype="submit"として利用する場合、
// Button配下に他の要素(<b>や<div>など)をネストするとその部分がiOSでタップできない問題がある。
// 「<Button><b>作成する</b></Button>」などとしないこと。
// <Button>がdisabledの場合はそもそもタップできないので気にしなくて良い。
const LoadingButton = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    type,
    color,
    loadingColor,
    fill,
    simple,
    pullRight,
    block,
    label,
    loadingLabel,
    disabled,
    onClick = async () => {},
    loading: loadingFromProps,
    style = {},
    ...otherProps
  } = props;

  const onPress = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  useEffect(() => {
    setLoading(loadingFromProps);
  }, [loadingFromProps]);

  return (
    <>
      {!loading ? (
        <Button
          style={{ fontWeight: "bold", ...style }}
          type={type}
          bsStyle={color}
          fill={fill}
          simple={simple}
          disabled={disabled}
          pullRight={pullRight}
          block={block}
          onClick={() => onPress()}
          {...otherProps}
        >
          {label}
        </Button>
      ) : (
        <Button
          style={{ display: "flex", ...style }}
          bsStyle={color}
          fill={fill}
          simple={simple}
          disabled
          block={block}
          pullRight={pullRight}
          {...otherProps}
        >
          <ReactLoading
            type="spinningBubbles"
            color={loadingColor}
            height="20px"
            width="20px"
          />
          <b style={{ paddingLeft: 10 }}>{loadingLabel || label}</b>
        </Button>
      )}
    </>
  );
};

export default LoadingButton;
