import React from "react";
import SelectorForm from "../../FormInputs/SelectorForm";
import { useGetApiCall } from "../../../lib/hooks";
import { UserGroupCollectionsResponse } from "../../../types/api/userGroupCollection";
import { UserGroupCollectionResource } from "../../../types/resource/userGroupCollection";

type Props = {
  disabled: boolean;
  onSelect: (userGroupCollectionId: number | null) => void;
  userGroupCollectionId: number | null;
};

const UserGroupCollectionSelectBox = ({
  disabled,
  onSelect,
  userGroupCollectionId
}: Props): JSX.Element => {
  const { response, loading } = useGetApiCall<UserGroupCollectionsResponse>("/user_group_collections");
  const userGroupCollections = response?.user_group_collections || [];

  const handleSelect = ({ target }) => {
    const newUserGroupCollectionId = target.value;
    if (newUserGroupCollectionId === "") {
      onSelect(null);
    } else {
      onSelect(Number(newUserGroupCollectionId));
    }
  };

  return (
    <SelectorForm
      style={{ marginBottom: 0 }}
      width={250}
      value={userGroupCollectionId === null ? "" : userGroupCollectionId}
      disabled={disabled || loading}
      options={[
        { label: "選択してください", value: "" },
        ...userGroupCollections.map((collection: UserGroupCollectionResource) => ({
          label: collection.name,
          value: collection.id,
        }))
      ]}
      onSelect={handleSelect}
    />
  );
};
export default UserGroupCollectionSelectBox;
