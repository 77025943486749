import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { CircularProgress } from "@mui/material";
import { useGetApiCall } from "../../../lib/hooks";
import { OptionTemplatesResponse } from "../../../types/menu";
import Section from "../../../components/Section";
import ListEmptyContainer from "../../../components/ListEmptyContainer";
import { useLoginContext } from "../../../providers/LoginContextProvider";

const OptionTemplateList = () => {
  const history = useHistory();
  const { response, loaded } = useGetApiCall<OptionTemplatesResponse>("/option_templates");
  const templates = response?.option_templates ?? [];
  const { currentStaff } = useLoginContext();

  return (
    <Section title="オプション一覧">
      {!loaded && (
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      )}

      {loaded && templates.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {currentStaff.is_toypo_member && // CSV 機能を利用するためにわかりやすいようにしているだけなので、通常ユーザーには不要
                <TableCell>ID</TableCell>
              }
              <TableCell>タイトル</TableCell>
              <TableCell>オプション内容</TableCell>
              <TableCell>必須</TableCell>
              <TableCell>複数選択</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map(({ option_template, option_items }) => (
              <StyledTableRow
                key={option_template.id}
                hover
                onClick={() => history.push(`/admin/option_template/${option_template.id}`)}
              >
                {currentStaff.is_toypo_member && // CSV 機能を利用するためにわかりやすいようにしているだけなので、通常ユーザーには不要
                  <TableCell>
                    {option_template.id}
                  </TableCell>
                }
                <TableCell>{option_template.title}</TableCell>
                <TableCell>
                  {option_items.map((optionItem) => (
                    <Box key={optionItem.id}>
                      {optionItem.title}({optionItem.price?.toLocaleString()}円)
                    </Box>
                  ))}
                </TableCell>
                <TableCell>{option_template.is_required && "選択必須"}</TableCell>
                <TableCell>{option_template.is_multiple && "複数選択可能"}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {loaded && templates.length === 0 && (
        <ListEmptyContainer icon={<i className="ri-file-copy-2-line" />}>
          オプションがありません
        </ListEmptyContainer>
      )}
    </Section>
  )
};

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  "& > td": {
    fontWeight: 700
  }
});

export default OptionTemplateList;
