import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNotification } from "../../../providers/NotificationProvider";
import { useApi } from "../../../lib/hooks";
import { StoreBenchmarkResource } from "../../../types/storeBenchmark";
import { StaffNotificationPolicyResource } from "../../../types/staffNotificationPolicy";
import Section from "../../../components/Section";
import BenchmarkProgressNotificationSettingsForm, {
  BenchmarkNotificationSetting,
  generateDefaultValues
} from "./BenchmarkProgressNotificationSettingsForm";

type Props = {
  benchmark: StoreBenchmarkResource;
  notificationPolicies: StaffNotificationPolicyResource[];
  dailyBenchmarks: StoreBenchmarkResource[];
  dailyNotificationPolicies: StaffNotificationPolicyResource[];
  onUpdatedNotification: () => void;
};

const BenchmarkNotificationSettings = ({
  benchmark,
  notificationPolicies,
  dailyBenchmarks,
  dailyNotificationPolicies,
  onUpdatedNotification
}: Props): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const { api, loading } = useApi();
  const showDailyProgressNotification = dailyBenchmarks.length > 0;
  const [enabledProgressNotification, setEnabledProgressNotification] =
    useState(benchmark.enabled_progress_notification);
  const [enabledDailyProgressNotification, setEnabledDailyProgressNotification] =
    useState(showDailyProgressNotification && dailyBenchmarks[0].enabled_progress_notification);
  const [notificationSettings, setNotificationSettings] =
    useState<BenchmarkNotificationSetting>(generateDefaultValuesBy(benchmark, notificationPolicies));
  const [dailyNotificationSettings, setDailyNotificationSettings] =
    useState<BenchmarkNotificationSetting>(generateDefaultValuesBy(dailyBenchmarks[0], dailyNotificationPolicies));

  const handleSubmit = async () => {
    const params = {
      enabled_progress_notification: enabledProgressNotification,
      time_of_notification_on_started: enabledProgressNotification ? notificationSettings.timeOfNotificationOnStarted : null,
      time_of_notification_on_middle: enabledProgressNotification ? notificationSettings.timeOfNotificationOnMiddle : null,
      time_of_notification_on_finished: enabledProgressNotification ? notificationSettings.timeOfNotificationOnFinished : null,
      days_of_notification_on_finished: enabledProgressNotification ? notificationSettings.daysOfNotificationOnFinished : null,
      notification_policies: notificationSettings.notificationPolicies,
      enabled_daily_progress_notification: enabledDailyProgressNotification,
      time_of_daily_notification_on_started: enabledDailyProgressNotification ? dailyNotificationSettings.timeOfNotificationOnStarted : null,
      time_of_daily_notification_on_middle: enabledDailyProgressNotification ? dailyNotificationSettings.timeOfNotificationOnMiddle : null,
      time_of_daily_notification_on_finished: enabledDailyProgressNotification ? dailyNotificationSettings.timeOfNotificationOnFinished : null,
      days_of_daily_notification_on_finished: enabledDailyProgressNotification ? dailyNotificationSettings.daysOfNotificationOnFinished : null,
      daily_notification_policies: dailyNotificationSettings.notificationPolicies
    };

    const response = await api.patch(`/store_benchmarks/${benchmark.id}`, params);
    if (!response) return;

    showSuccessNotification("通知設定を更新しました。");
    onUpdatedNotification();
  };

  return (
    <>
      <Section title="目標進捗の通知設定">
        <Box p={1.5} bgcolor="#F2F2F2">
          <FormControlLabel
            label="目標進捗の通知を受け取る"
            disabled={loading}
            sx={{ mb: 0 }}
            control={
              <Checkbox
                checked={enabledProgressNotification}
                onChange={(e) => setEnabledProgressNotification(e.target.checked)}
              />
            }
          />
        </Box>

        {enabledProgressNotification && (
          <BenchmarkProgressNotificationSettingsForm
            storeId={benchmark.store_id}
            notificationSettings={notificationSettings}
            labelOnStarted="目標開始日の"
            labelOnMiddle="毎週月曜日の"
            labelOnFinished="目標終了の"
            disabled={loading}
            onChange={setNotificationSettings}
          />
        )}
      </Section>

      {showDailyProgressNotification && (
        <Section title="日次目標進捗の通知設定">
          <Box p={1.5} bgcolor="#F2F2F2">
            <FormControlLabel
              label="日次目標進捗の通知を受け取る"
              disabled={loading}
              sx={{ mb: 0 }}
              control={
                <Checkbox
                  checked={enabledDailyProgressNotification}
                  onChange={(e) => setEnabledDailyProgressNotification(e.target.checked)}
                />
              }
            />
          </Box>

          {enabledDailyProgressNotification && (
            <BenchmarkProgressNotificationSettingsForm
              storeId={benchmark.store_id}
              notificationSettings={dailyNotificationSettings}
              labelOnStarted="日次目標を設定している日の"
              labelOnMiddle="日次目標を設定している日の"
              labelOnFinished="日次目標を設定している日の"
              disabled={loading}
              onChange={setDailyNotificationSettings}
            />
          )}
        </Section>
      )}

      <StyledActionContainer>
        <Button
          variant="outlined"
          color="cancel"
          disabled={loading}
          onClick={() => history.push("/admin/benchmark")}
        >
          もどる
        </Button>

        <Button
          variant="contained"
          color="submit"
          disabled={loading}
          onClick={handleSubmit}
        >
          変更を保存する
        </Button>
      </StyledActionContainer>
    </>
  );
};

const generateDefaultValuesBy = (
  benchmark: StoreBenchmarkResource | undefined,
  notificationPolicies: StaffNotificationPolicyResource[]
): BenchmarkNotificationSetting => (
  benchmark && benchmark?.enabled_progress_notification ? {
    timeOfNotificationOnStarted: benchmark.time_of_notification_on_started,
    timeOfNotificationOnMiddle: benchmark.time_of_notification_on_middle,
    timeOfNotificationOnFinished: benchmark.time_of_notification_on_finished,
    daysOfNotificationOnFinished: benchmark.days_of_notification_on_finished,
    notificationPolicies: notificationPolicies.map((policy) => ({
      staff_id: policy.staff_id,
      enabled_email: policy.enabled_email,
      enabled_push_notification: policy.enabled_push_notification,
      include_child_store_staffs: policy.include_child_store_staffs
    }))
  } : generateDefaultValues()
);

const StyledActionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  "& > button": {
    width: 300
  }
}));

export default BenchmarkNotificationSettings;
