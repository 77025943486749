import React from "react";
import { useQuery } from "../../lib/hooks";
import { DisplayErrorNotification, DisplaySuccessNotification } from "../../types/common.d";
import NoticeContentForm from "./parts/NoticeContentForm";

type Props = {
  displayErrorNotification: DisplayErrorNotification;
  displaySuccessNotification: DisplaySuccessNotification;
};

const NewNoticeFormView = ({
  displayErrorNotification,
  displaySuccessNotification
}: Props): JSX.Element => {
  const query = useQuery();
  const type = query.get("type");
  const userIds = query.get("user_ids");
  const userGroupIds = query.get("user_group_ids");
  const userGroupCollectionId = query.get("user_group_collection_id");
  const questionnaireUrl = query.get("questionnaire_url") || "";

  const defaultTargetUserCondition = {
    user_ids: userIds?.split(",").map((v) => Number(v)) || [],
    user_group_ids: userGroupIds?.split(",").map((v) => Number(v)) || [],
    user_group_collection_id: userGroupCollectionId ? Number(userGroupCollectionId) : null
  };

  return (
    <NoticeContentForm
      style={{ padding: "24px 12px" }}
      isAuto={type === "auto"}
      defaultTargetUserCondition={defaultTargetUserCondition}
      defaultQuestionnaireUrl={questionnaireUrl}
      displayErrorNotification={displayErrorNotification}
      displaySuccessNotification={displaySuccessNotification}
    />
  );
};

export default NewNoticeFormView;
