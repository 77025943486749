import React, { ComponentPropsWithoutRef } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LinearProgress from '@mui/material/LinearProgress';
import { displayJpReadableNumber } from "../../../lib/general";
import { StoreBenchmarkResource } from "../../../types/storeBenchmark";
import { StoreBenchmarkIndicatorLabels } from "../../../constants/storeBenchmark";

type Props = ComponentPropsWithoutRef<"div"> & {
  storeName?: string | null;
  benchmark: StoreBenchmarkResource;
  dailyBenchmarks: StoreBenchmarkResource[];
};

const BenchmarkProgressCard = ({
  storeName = "",
  benchmark,
  dailyBenchmarks,
  ...otherProps
}: Props): JSX.Element => {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().add(-1, "days").format("YYYY-MM-DD");
  const startDate = moment(benchmark.start_date).format("YYYY-MM-DD");
  const progress = (benchmark.performance_value || 0) / benchmark.benchmark_value;
  const todayBenchmark = dailyBenchmarks.find((child) => child.start_date === today);
  const yesterdayBenchmark = dailyBenchmarks.find((child) => child.start_date === yesterday);
  const sumOfBenchmarkValueUpToToday = dailyBenchmarks
    .filter(({ start_date }) => moment(start_date).isSameOrBefore(moment()))
    .reduce((sum, { benchmark_value }) => sum + benchmark_value, 0);
  const performanceDiff = (benchmark.performance_value || 0) - sumOfBenchmarkValueUpToToday;
  const ongoing = benchmark.benchmark_progress === "ongoing";

  return (
    <StyledPaper {...otherProps}>
      <Box fontSize={13} mb={0.5}>
        {storeName && <Box color="#666">{storeName}</Box>}
        <div>{StoreBenchmarkIndicatorLabels[benchmark.benchmark_indicator]}</div>
      </Box>
      <Box fontSize={16} mb={1} fontWeight={700}>
        {benchmark.title}
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end">
          <Box fontSize={32} fontWeight={700}>{Math.floor(progress * 100)}</Box>
          <Box fontSize={16} style={{ marginLeft: 4, marginBottom: 6 }}>%</Box>
        </Box>

        {ongoing && dailyBenchmarks.length > 0 && (
          <div>
            <DailyValue
              label="今日"
              performanceValue={todayBenchmark?.performance_value || 0}
              benchmarkValue={todayBenchmark?.benchmark_value}
            />
            <DailyValue
              label="昨日"
              hideValues={startDate === today}
              performanceValue={yesterdayBenchmark?.performance_value || 0}
              benchmarkValue={yesterdayBenchmark?.benchmark_value}
            />
          </div>
        )}
      </Box>

      <Box color="#47B8B3">
        <LinearProgress
          sx={{ height: 10, borderRadius: 1, mb: 1 }}
          variant="determinate"
          value={Math.min(100, progress * 100)}
          color="inherit"
        />
      </Box>

      <Box display="flex" gap={2} mb={1}>
        <TotalValue label="実績" value={benchmark.performance_value || 0} />
        <TotalValue label="目標" value={benchmark.benchmark_value} />
      </Box>

      {ongoing && dailyBenchmarks.length > 0 && performanceDiff === 0 && (
        <Box bgcolor="#EEF8EB" p={1} fontSize={13} borderRadius={1}>
          今日までの目標と同じ数値を達成できています
        </Box>
      )}
      {ongoing && dailyBenchmarks.length > 0 && performanceDiff > 0 && (
        <Box bgcolor="#EEF8EB" p={1} fontSize={13} borderRadius={1}>
          今日までの目標に対して
          <StyledPerformanceDiffLabel color="#53B635">
            {displayJpReadableNumber(performanceDiff)}
          </StyledPerformanceDiffLabel>
          上回っています
        </Box>
      )}
      {ongoing && dailyBenchmarks.length > 0 && performanceDiff < 0 && (
        <Box bgcolor="#FEF1F2" p={1} fontSize={13} borderRadius={1}>
          今日までの実績目標に対して
          <StyledPerformanceDiffLabel color="#F33841">
            {displayJpReadableNumber(-performanceDiff)}
          </StyledPerformanceDiffLabel>
          下回っています
        </Box>
      )}
    </StyledPaper>
  );
};

type DailyValueProps = {
  label: string;
  hideValues?: boolean;
  performanceValue: number;
  benchmarkValue?: number;
};
const DailyValue = ({
  label,
  hideValues = false,
  performanceValue,
  benchmarkValue
}: DailyValueProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="flex-end" fontSize={13}>
      <Box mr={0.5}>{label}</Box>
      {hideValues ? (
        "--- / ---"
      ) : (
        <>
          <Box fontWeight={700} fontSize={16} style={{ marginBottom: -1 }}>
            {displayJpReadableNumber(performanceValue)}
          </Box>
          {benchmarkValue && (
            <Box fontSize={14}> / {displayJpReadableNumber(benchmarkValue)}</Box>
          )}
        </>
      )}
    </Box>
  );
};

type TotalValue = {
  label: string;
  value: number;
};
const TotalValue = ({
  label,
  value
}: TotalValue): JSX.Element => {
  return (
    <Box display="flex" alignItems="flex-end">
      <Box color="#555555" fontSize={13}>{label}</Box>
      <Box fontWeight={700} fontSize={15} style={{ margin: "0 0 -1px 8px" }}>
        {displayJpReadableNumber(value)}
      </Box>
    </Box>
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 300,
  minWidth: 300
}));

const StyledPerformanceDiffLabel = styled(Box)({
  display: "inline-block",
  fontWeight: 700,
  fontSize: 16,
  margin: "0 2px"
});

export default BenchmarkProgressCard;
