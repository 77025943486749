import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import { OptionItemsResponse, OptionTemplateResponse } from "../../types/menu";
import { useApi, useGetApiCall, useBooleanState, useMobileFlag } from "../../lib/hooks";
import { useNotification } from "../../providers/NotificationProvider";
import FormLabel from "../../components/FormInputs/FormLabel";
import OptionItemDialog from "./parts/OptionItemDialog";

type OptionTemplateForm = {
  title: string;
  is_required: boolean;
  is_multiple: boolean;
  option_item_ids: number[];
};

const OptionTemplateFormView = () => {
  const isMobile = useMobileFlag();
  const optionTemplateApi = useApi<OptionTemplateResponse>();
  const updateApi = useApi();
  const optionItemsGetApi = useGetApiCall<OptionItemsResponse>("/option_items");
  const loading = optionItemsGetApi.loading || optionTemplateApi.loading || updateApi.loading;
  const optionItems = optionItemsGetApi.response ?? [];
  const defaultValues = generateDefaultValues();
  const { control, errors, handleSubmit, reset, formState } = useForm<OptionTemplateForm>({ defaultValues });
  const { isDirty } = formState;
  const { showSuccessNotification } = useNotification();
  const [isOptionItemDialogOpen, openOptionItemDialog, closeOptionItemDialog] = useBooleanState(false);
  const history = useHistory();
  const { id: optionTemplateId } = useParams();
  const editMode = Boolean(optionTemplateId);

  useEffect(() => {
    if (editMode) {
      optionTemplateApi.api.get(`/option_templates/${optionTemplateId}`).then((response) => {
        reset(generateDefaultValues(response.data));
      });
    };
  }, [editMode, optionTemplateApi.api, optionTemplateId, reset]);

  const createOrUpdateOptionTemplate = async (formValue: OptionTemplateForm) => {
    if (editMode) {
      const response = await updateApi.api.patch(`/option_templates/${optionTemplateId}`, formValue);
      if (!response) return;
      showSuccessNotification("オプションを更新しました");
    } else {
      const response = await updateApi.api.post("/option_templates", formValue);
      if (!response) return;
      showSuccessNotification("オプションを作成しました");
    };

    history.goBack();
  };

  const deleteTemplate = async () => {
    if (!window.confirm("このオプションを削除しますか？")) return;

    const res = await updateApi.api.delete(`/option_templates/${optionTemplateId}`);
    if (!res) return;

    showSuccessNotification("オプションを削除しました");
    history.goBack();
  };

  return (
    <>
      <Paper
        variant={isMobile ? "elevation" : "outlined"}
        square={isMobile}
        sx={{ padding: 2, margin: 2 }}
      >
        <Typography variant="title" sx={{ mb: 2 }}>
          オプション設定
        </Typography>

        <Box mb={2}>
          <FormLabel label="オプション名" />
          <Controller
            name="title"
            control={control}
            rules={{ required: "タイトルを入力してください" }}
            render={({ onChange, value }) => (
              <OutlinedInput
                error={Boolean(errors.title)}
                placeholder="例)ご飯の量、小鉢など"
                fullWidth
                sx={{ maxWidth: 400 }}
                value={value}
                onChange={({ target }) => onChange(target.value)}
              />
            )}
          />
          {errors.title?.message && (
            <FormHelperText error>{errors.title?.message}</FormHelperText>
          )}
        </Box>

        <Controller
          name="is_required"
          control={control}
          render={({ onChange, value }) => (
            <FormControlLabel
              label="選択を必須にする"
              control={<Checkbox checked={value} onChange={({ target }) => onChange(target.checked)} />}
            />
          )}
        />

        <Controller
          name="is_multiple"
          control={control}
          render={({ onChange, value }) => (
            <FormControlLabel
              label="複数選択を可能にする"
              control={<Checkbox checked={value} onChange={({ target }) => onChange(target.checked)} />}
            />
          )}
        />

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="title" sx={{ my: 2 }}>
            オプションメニュー選択
          </Typography>

          <Button
            color="submit"
            startIcon={<i className="ri-edit-line" />}
            size="small"
            onClick={openOptionItemDialog}
          >
            オプションメニューのマスタを編集
          </Button>
        </Box>

        <Controller
          name="option_item_ids"
          control={control}
          render={({ onChange, value }) => (
            <Box display="flex" flexWrap="wrap">
              {optionItems.map((optionItem) => (
                <StyledFormControlLabel
                  key={optionItem.id}
                  label={`${optionItem.title} (${optionItem.price?.toLocaleString() || 0}円)`}
                  isMobile={isMobile}
                  control={
                    <Checkbox
                      checked={value.includes(optionItem.id)}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? [...value, optionItem.id]
                          : value.filter((id: number) => id !== optionItem.id);
                        onChange(newValue);
                      }}
                    />
                  }
                />
              ))}
            </Box>
          )}
        />
      </Paper>

      <Box>
        <StyledActionContainer>
          <Button
            variant="outlined"
            color="cancel"
            disabled={loading}
            onClick={history.goBack}
          >
            もどる
          </Button>

          <Button
            variant="contained"
            color="submit"
            disabled={!isDirty || loading}
            onClick={handleSubmit(createOrUpdateOptionTemplate)}
          >
            変更を保存する
          </Button>
        </StyledActionContainer>

        {editMode && (
          <StyledActionContainer>
            <Button
              color="danger"
              onClick={deleteTemplate}
              disabled={loading}
            >
              このオプションを削除する
            </Button>
          </StyledActionContainer>
        )}
      </Box>

      <OptionItemDialog
        open={isOptionItemDialogOpen}
        onClose={() => {
          optionItemsGetApi.reload();
          closeOptionItemDialog();
        }}
      />
    </>
  )
};

const generateDefaultValues = (optionTemplate: OptionTemplateResponse | null = null) => ({
  title: optionTemplate?.option_template.title ?? "",
  is_required: optionTemplate?.option_template.is_required ?? false,
  is_multiple: optionTemplate?.option_template.is_multiple ?? false,
  option_item_ids: (optionTemplate?.option_items ?? []).map(({ id }) => id)
});


const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop: string) => "isMobile" !== prop
})<{ isMobile: boolean }>(({ isMobile }) => ({
  height: "auto",
  width: isMobile ? "100%" : `calc(90%/3)`,
  display: "flex",
}));

const StyledActionContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  "& > button": {
    width: 300
  }
}));

export default OptionTemplateFormView;
