import React from "react";
import { Modal, Button } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";

type Props = {
  disabled?: boolean;
  show: boolean;
  title: string;
  onClickCancelButton: () => void;
  okButtonTitle: string;
  cancelButtonTitle: string;
  onClickOkButton: () => void;
  description?: string | JSX.Element;
  okButtonBsStyle: "info" | "success" | "danger";
  children?: React.ReactNode
};

const ConfirmModal = ({
  disabled,
  show,
  title,
  description,
  onClickCancelButton,
  okButtonTitle,
  cancelButtonTitle,
  onClickOkButton,
  okButtonBsStyle,
  children
}: Props): JSX.Element => (
  <Modal
    show={show}
    onHide={onClickCancelButton}
    aria-labelledby="contained-modal-title"
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title">{title}</Modal.Title>
    </Modal.Header>

    {description && <Modal.Body>{description}</Modal.Body>}
    {children && <Modal.Body>{children}</Modal.Body>}

    <Modal.Footer>
      <Button
        disabled={disabled}
        onClick={onClickCancelButton}
      >
        {cancelButtonTitle}
      </Button>

      <CustomButton
        disabled={disabled}
        bsStyle={okButtonBsStyle}
        fill
        style={{ height: "100%" }}
        onClick={(): void => {
          onClickOkButton();
        }}
      >
        {okButtonTitle}
      </CustomButton>
    </Modal.Footer>
  </Modal>
);

export default ConfirmModal;
