import React, { useState } from "react";
import { Table, Tabs, Tab } from "react-bootstrap";
import { Pagination } from "@mui/material";

import { Card } from "../../../components/Card/Card";

import DataTable from "./DataTable";
import { Product } from "../../../containers/entities/ChargeEntityContainer";

interface Props {
  devideProducts: Product[][];
}

const ProductSalesCard: React.FC<Props> = ({ devideProducts }) => {
  const [parPage] = useState(10);
  const [isRepeatKey, setIsRepeatKey] = useState(0);
  const [page, setPage] = useState(1);

  const productHandleSelect = (key) => {
    setIsRepeatKey(key);
    setPage(1);
  };

  return (
    <Card
      title="商品別売上"
      ctTableFullWidth
      content={
        <Tabs
          defaultActiveKey={0}
          id="uncontrolled-tab-menus"
          onSelect={productHandleSelect}
        >
          {["オーダー", "チケット", "サブスク"].map((category, index) => (
            <Tab eventKey={index} title={category} key={category}>
              <ProductSalesTable
                devideProducts={devideProducts}
                index={index}
                offSet={parPage * (page - 1)}
                parPage={parPage}
              />
            </Tab>
          ))}
        </Tabs>
      }
      stats={
        <Pagination
          count={Math.ceil(devideProducts[isRepeatKey].length / parPage)}
          page={page}
          onChange={(_, newPage): void => setPage(newPage)}
          sx={{ fontSize: 24, color: "black" }}
        />
      }
    />
  );
};

export default ProductSalesCard;

interface TableProps {
  devideProducts: Product[][];
  index: number;
  offSet: number;
  parPage: number;
}

const ProductSalesTable: React.FC<TableProps> = ({
  devideProducts,
  index,
  offSet,
  parPage,
}) => (
  <Table striped hover>
    <DataTable
      data={devideProducts[index].slice(offSet, offSet + parPage)}
      columns={["商品名", "店舗", "金額", "販売数"]}
      renderCell={(product): JSX.Element => (
        <tr key={`report-${product.id}`}>
          <td style={{ fontWeight: "bold", width: "45%" }}>{product.title}</td>
          <td style={{ fontWeight: "bold", width: "30%" }}>
            {product.store_name}
          </td>
          <td style={{ fontWeight: "bold", width: "15%" }}>
            {`${product.price?.toLocaleString() || 0}円`}
          </td>
          <td style={{ fontWeight: "bold", width: "10%" }}>
            {product.sales_number}
          </td>
        </tr>
      )}
    />
  </Table>
);
