import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";
import {
  Data,
  DataType,
  DataUnit,
} from "../../containers/entities/AnalyticsEntityContainer";

export const getAnalyticsData = async ({
  type,
  unit,
  from,
  to,
  user_group_id,
}: {
  type: DataType;
  unit?: DataUnit;
  from?: string;
  to?: string;
  user_group_id?: number;
}): Promise<Data> => {
  let GET_DATA = `/staff/stores/${loadViewObj()?.id}/data?type=${type}`;

  if (unit) {
    GET_DATA += `&unit=${unit}`;
  }

  if (from) {
    GET_DATA += `&from=${from}`;
  }

  if (to) {
    GET_DATA += `&to=${to}`;
  }

  if (user_group_id) {
    GET_DATA += `&user_group_id=${user_group_id}`;
  }

  const response = await apiClient({ method: "GET", uri: GET_DATA });

  return response.data;
};
