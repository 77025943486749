import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";
import {
  UserGroup,
  UserGroupType,
} from "../../containers/entities/UserGroupEntityContainer";

export const getUserGroups = async (
  type: UserGroupType
): Promise<UserGroup[]> => {
  const GET_USER_GROUPS = `/staff/stores/${
    loadViewObj()?.id
  }/user_groups?type=${type}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_USER_GROUPS,
  });
  return response.data;
};

export const getUserGroup = async (id: number): Promise<UserGroup> => {
  const GET_USER_GROUP = `/staff/stores/${loadViewObj()?.id}/user_groups/${id}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_USER_GROUP,
  });
  return response.data;
};

export const createUserGroup = async (params): Promise<UserGroup> => {
  const CREATE_USER_GROUP = `/staff/stores/${loadViewObj()?.id}/user_groups`;

  const requestParams = {
    user_group: params,
  };
  const response = await apiClient({
    method: "POST",
    uri: CREATE_USER_GROUP,
    params: requestParams,
  });
  return response.data;
};

export const updateUserGroup = async ({ id, params }): Promise<UserGroup> => {
  const UPDATE_USER_GROUP = `/staff/stores/${
    loadViewObj()?.id
  }/user_groups/${id}`;

  const requestParams = {
    user_group: params,
  };
  const response = await apiClient({
    method: "PUT",
    uri: UPDATE_USER_GROUP,
    params: requestParams,
  });
  return response.data;
};
