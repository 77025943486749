import { useState } from "react";
import { createContainer } from "unstated-next";
import {
  validateTextForm,
  validateDigit,
  validateBankAccountName,
  validatePrice,
} from "../../lib/validation";

const useBankAccountFormViewHook = () => {
  const [doSelectBranch, setDoSelectBranch] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankBranchCode, setBankBranchCode] = useState("");
  const [bankAccountType, setBankAccountType] = useState("");
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");
  const [keyword, setKeyword] = useState("");
  const [branchKeyword, setBranchKeyword] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [minimumTransferAmount, setMinimumTransferAmount] = useState("");
  // validation
  const [bankNameValidationMessage, setBankNameValidationMessage] =
    useState("");
  const [branchNameValidationMessage, setBranchNameValidationMessage] =
    useState("");
  const [
    bankAccountNumberValidationMessage,
    setBankAccountNumberValidationMessage,
  ] = useState("");
  const [
    bankAccountTypeValidationMessage,
    setBankAccountTypeValidationMessage,
  ] = useState("");
  const [holderNameValidationMessage, setHolderNameValidationMessage] =
    useState("");
  const [
    minimumTransferAmountValidationMessage,
    setMinimumTransferAmountValidationMessage,
  ] = useState("");

  const [isDirty, setIsDirty] = useState(false);

  const state = {
    doSelectBranch,
    bankName,
    bankBranchName,
    bankCode,
    bankAccountNumber,
    bankBranchCode,
    bankAccountType,
    bankAccountHolderName,
    keyword,
    branchKeyword,
    viewMode,
    minimumTransferAmount,
    bankNameValidationMessage,
    branchNameValidationMessage,
    bankAccountNumberValidationMessage,
    bankAccountTypeValidationMessage,
    holderNameValidationMessage,
    minimumTransferAmountValidationMessage,
    isDirty,
  };

  const setViewState = {
    setForm: (res) => {
      const {
        bank_code,
        bank_name,
        bank_branch_code,
        bank_branch_name,
        bank_account_type,
        bank_account_number,
        bank_account_holder_name,
        minimum_transfer_amount,
      } = res;
      setBankName(bank_name);
      setBankBranchName(bank_branch_name);
      setBankCode(bank_code);
      setBankBranchCode(bank_branch_code);
      setBankAccountType(bank_account_type);
      setBankAccountNumber(bank_account_number);
      setBankAccountHolderName(bank_account_holder_name);
      setMinimumTransferAmount(minimum_transfer_amount);
      setBankNameValidationMessage("");
      setBranchNameValidationMessage("");
      setBankAccountNumberValidationMessage("");
      setBankAccountTypeValidationMessage("");
      setHolderNameValidationMessage("");
      setMinimumTransferAmountValidationMessage("");
    },
    doSelectBranch: setDoSelectBranch,
    bankName: setBankName,
    bankBranchName: setBankBranchName,
    bankCode: setBankCode,
    bankAccountNumber: setBankAccountNumber,
    bankBranchCode: setBankBranchCode,
    bankAccountHolderName: setBankAccountHolderName,
    bankAccountType: setBankAccountType,
    keyword: setKeyword,
    branchKeyword: setBranchKeyword,
    viewMode: setViewMode,
    minimumTransferAmount: setMinimumTransferAmount,
    bankNameValidationMessage: setBankNameValidationMessage,
    branchNameValidationMessage: setBranchNameValidationMessage,
    bankAccountNumberValidationMessage: setBankAccountNumberValidationMessage,
    bankAccountTypeValidationMessage: setBankAccountTypeValidationMessage,
    holderNameValidationMessage: setHolderNameValidationMessage,
    minimumTransferAmountValidationMessage:
      setMinimumTransferAmountValidationMessage,
    isDirty: setIsDirty,
  };

  const logic = {
    getInitialBankAccount: () => ({
      bank_code: "",
      bank_name: "",
      bank_branch_code: "",
      bank_branch_name: "",
      bank_account_type: "",
      bank_account_number: "",
      bank_account_holder_name: "",
      minimum_transfer_amount: "",
    }),
    handleFormChange: (name, value) => {
      setViewState[name](value);
      setIsDirty(true);
    },
    getBankAccountFormData: () => ({
      bank_name: bankName,
      bank_branch_name: bankBranchName,
      bank_account_number: bankAccountNumber,
      bank_branch_code: bankBranchCode,
      bank_code: bankCode,
      bank_account_holder_name: bankAccountHolderName,
      bank_account_type: bankAccountType,
      minimum_transfer_amount: minimumTransferAmount,
    }),
    validate: ({
      bank_name,
      bank_branch_name,
      bank_account_number,
      bank_account_type,
      bank_account_holder_name,
      minimum_transfer_amount,
    }) => {
      const resultBankName = validateTextForm(bank_name);
      setViewState.bankNameValidationMessage(resultBankName.message);

      const resultBranchName = validateTextForm(bank_branch_name);
      setViewState.branchNameValidationMessage(resultBranchName.message);

      const resultBankAccountNumber = validateDigit(bank_account_number, 7);
      setViewState.bankAccountNumberValidationMessage(
        resultBankAccountNumber.message
      );

      const resultBankAccountType = validateTextForm(bank_account_type);
      setViewState.bankAccountTypeValidationMessage(
        resultBankAccountType.message
      );

      const resultHolderName = validateBankAccountName(
        bank_account_holder_name
      );
      setViewState.holderNameValidationMessage(resultHolderName.message);

      const resultMinimumTransferAmount = validatePrice(
        minimum_transfer_amount,
        1000
      );
      setViewState.minimumTransferAmountValidationMessage(
        resultMinimumTransferAmount.message
      );

      return (
        resultBankName.validation &&
        resultBranchName.validation &&
        resultBankAccountNumber.validation &&
        resultBankAccountType.validation &&
        resultHolderName.validation &&
        resultMinimumTransferAmount.validation
      );
    },
  };

  return { logic, state, setViewState };
};

export default createContainer(useBankAccountFormViewHook);
