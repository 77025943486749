import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Prompt } from "react-router";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotification } from "../../providers/NotificationProvider";
import FormLabel from "../../components/FormInputs/FormLabel";
import Section from "../../components/Section";
import { getObjectDiff } from "../../lib/general";
import { useApi, useQuery } from "../../lib/hooks";
import { StaffResource } from "../../types/staff";
import StaffRoleSection from "./parts/StaffRoleSection";

type StaffFormType = {
  staff: {
    name: string;
    email: string;
    password: string;
    password_confirm: string;
    registered: boolean;
  },
  store_ids: number[];
};

const StaffFormView = (): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const history = useHistory();
  const { pathname } = useLocation();
  const { currentStaff } = useLoginContext();
  const query = useQuery();
  const staffId = Number(query.get("id") ?? 0);
  const storeId = Number(query.get("store_id") ?? 0);
  const editMode = pathname.indexOf("edit") !== -1;
  const ownerApi = useApi("/staff/owner");
  const storeApi = useApi();
  const staffApi = useApi<StaffResource>("/staff");
  const updating = ownerApi.loading || storeApi.loading;
  const staff = staffApi.response;
  const isPrimaryAdmin = staff?.owner?.role === "primary_admin";
  const groups = staff?.groups.filter((v) => v.store_type === "store_group") ?? [];
  const stores = staff?.stores ?? [];
  const defaultValues = generateDefaultValues();
  const {
    register,
    control,
    handleSubmit,
    errors,
    getValues,
    formState: { isDirty },
    reset
  } = useForm<StaffFormType>({ defaultValues });

  useEffect(() => {
    if (!staffId || !storeId) return;

    staffApi.api.get(`/stores/${storeId}/staffs/${staffId}`)
      .then(({ data }) => reset(generateDefaultValues(data)));
  }, [staffApi.api, staffId, storeId, reset]);

  const createStaff = async (formData: StaffFormType) => {
    const path = "/staffs";
    const response = currentStaff.is_owner
      ? await ownerApi.api.post(path, formData)
      : await storeApi.api.post(path, formData);
    if (!response) return;

    showSuccessNotification("スタッフを作成しました。");
    reset();
    history.goBack();
  };

  const updateStaff = async (formData: StaffFormType) => {
    const isEmailChanged = Boolean(getObjectDiff(staff, formData.staff).email);

    const params = {
      staff: formData.staff,
      confirm_success_url: `${process.env.REACT_APP_BASE_URL}admin/login`,
      error_redirect_url: `${process.env.REACT_APP_BASE_URL}admin/account`
    };
    const response = await storeApi.api.patch(`/staffs/${staff!.id}`, params);
    if (!response) return;

    const message = isEmailChanged && isPrimaryAdmin
      ? "入力されたメールアドレス宛に確認メールを送信しました。認証後アドレスが変更されます。"
      : "編集を保存しました。";
    showSuccessNotification(message);

    reset();
    history.goBack();
  };

  const deleteStaff = async () => {
    if (!window.confirm("このスタッフを削除しますか？")) {
      return;
    }

    const response = await storeApi.api.delete(`/staffs/${staff!.id}`);
    if (!response) return;

    showSuccessNotification("スタッフを削除しました。");
    history.goBack();
  };

  const submit = (data: StaffFormType) => {
    if (editMode) {
      updateStaff(data);
    } else {
      createStaff(data);
    }
  };

  const handleError = () => showErrorNotification("値が正しく入力されていません。");

  if (editMode && !staff) {
    return (
      <Box display="flex" justifyContent="center" py={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Section title={editMode ? "スタッフ編集" : "スタッフ作成"}>
        <Box mb={2}>
          <FormLabel label="名前" />
          <Controller
            name="staff.name"
            control={control}
            rules={{ required: "名前を入力してください" }}
            render={({ onChange, value }) => (
              <OutlinedInput
                data-cy="staff-form-staff-name"
                error={Boolean(errors.staff?.name)}
                placeholder="名前を入力してください"
                fullWidth
                sx={{ maxWidth: 400 }}
                value={value}
                onChange={({ target }) => onChange(target.value)}
              />
            )}
          />
          {errors.staff?.name?.message && (
            <FormHelperText error>{errors.staff?.name.message}</FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <FormLabel label="メールアドレス" />
          <Controller
            name="staff.email"
            control={control}
            rules={{ required: "メールアドレスを入力してください" }}
            render={({ onChange, value }) => (
              <OutlinedInput
                data-cy="staff-form-staff-email"
                error={Boolean(errors.staff?.email)}
                placeholder="メールアドレスを入力してください"
                fullWidth
                sx={{ maxWidth: 600 }}
                value={value}
                onChange={({ target }) => onChange(target.value)}
              />
            )}
          />
          {errors.staff?.email?.message && (
            <FormHelperText error>{errors.staff?.email.message}</FormHelperText>
          )}
        </Box>

        {!editMode && (
          <>
            <Box mb={2}>
              <FormLabel label="パスワード(6文字以上)" />
              <Controller
                name="staff.password"
                control={control}
                rules={{ required: "パスワードを入力してください" }}
                render={({ onChange, value }) => (
                  <OutlinedInput
                    data-cy="staff-form-staff-password"
                    error={Boolean(errors.staff?.password)}
                    type="password"
                    placeholder="パスワードを入力してください"
                    value={value}
                    fullWidth
                    sx={{ maxWidth: 400 }}
                    onChange={({ target }) => onChange(target.value)}
                  />
                )}
              />
              {errors.staff?.password?.message && (
                <FormHelperText error>{errors.staff?.password.message}</FormHelperText>
              )}
            </Box>

            <Box mb={2}>
              <FormLabel label="パスワード確認" />
              <Controller
                name="staff.password_confirm"
                control={control}
                rules={{
                  required: "パスワード確認を入力してください",
                  validate: (value) => value === getValues("staff.password") || "パスワードが確認用パスワードと一致していません。",
                }}
                render={({ onChange, value }) => (
                  <OutlinedInput
                    data-cy="staff-form-staff-password-confirm"
                    error={Boolean(errors.staff?.password_confirm)}
                    type="password"
                    placeholder="もう一度パスワードを入力してください"
                    value={value}
                    fullWidth
                    sx={{ maxWidth: 400 }}
                    onChange={({ target }) => onChange(target.value)}
                  />
                )}
              />
              {errors.staff?.password_confirm?.message && (
                <FormHelperText error>{errors.staff?.password_confirm.message}</FormHelperText>
              )}
            </Box>

            <Controller
              control={control}
              name="staff.registered"
              render={({ value, onChange }): JSX.Element => (
                <FormControlLabel
                  label="初回ログイン時にパスワードを設定させる"
                  onChange={() => onChange(!value)}
                  control={
                    <Checkbox
                      data-cy="staff-form-staff-registered"
                      checked={!value}
                    />
                  }
                />
              )}
            />
          </>
        )}

        {editMode && (
          <>
            <Box data-cy="staff-form-staff-owner" mb={2}>
              <FormLabel label="所属オーナー" />
              <Typography fontWeight={700} fontSize={17} ml={1}>
                {staff?.owner?.name || "未設定"}
              </Typography>
            </Box>

            <Box data-cy="staff-form-staff-groups" mb={2}>
              <FormLabel label="所属グループ" />
              <Box ml={1}>
                {groups.map((group) => (
                  <Typography key={group.id} fontWeight={700} fontSize={17}>
                    {group.name}
                  </Typography>
                ))}
                {groups.length === 0 && (
                  <Typography fontWeight={700} fontSize={17}>
                    未設定
                  </Typography>
                )}
              </Box>
            </Box>

            <Box data-cy="staff-form-staff-stores">
              <FormLabel label="所属店舗" />
              <Box ml={1}>
                {stores.map((store) => (
                  <Typography key={store.id} fontWeight={700} fontSize={17}>
                    {store.name}
                  </Typography>
                ))}
                {stores.length === 0 && (
                  <Typography fontWeight={700} fontSize={17}>
                    未設定
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      </Section>

      {!editMode && (
        <Box m={2}>
          <StaffRoleSection
            register={register}
            errors={errors}
          />
        </Box>
      )}

      <StyledActionContainer>
        <Button
          variant="outlined"
          color="cancel"
          disabled={updating}
          onClick={history.goBack}
        >
          もどる
        </Button>

        <Button
          data-cy="staff-form-view-submit-button"
          variant="contained"
          color="submit"
          disabled={!isDirty || updating}
          type="submit"
          onClick={handleSubmit(submit, handleError)}
        >
          {editMode ? "保存する" : "作成する"}
        </Button>
      </StyledActionContainer>

      {editMode && (
        <Box display="flex" justifyContent="center">
          <Tooltip
            title={isPrimaryAdmin ? "このスタッフは店舗作成時に最初に作られたスタッフのため、削除できません。" : ""}
            placement="top"
          >
            <span>
              <Button
                color="danger"
                disabled={updating || isPrimaryAdmin}
                onClick={deleteStaff}
              >
                このスタッフを削除する
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}

      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </div>
  );
};

const generateDefaultValues = (staff: StaffResource | null = null) => ({
  staff: {
    name: staff?.name || "",
    email: staff?.email || "",
    password: "",
    password_confirm: "",
    registered: true,
  },
  store_ids: []
});

const StyledActionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  "& > button": {
    width: 300
  }
}));

export default StaffFormView;
