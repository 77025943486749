import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router";
import { formatDate } from "../../../lib/general";
import { useQuery } from "../../../lib/hooks";
import { IllegalStatusLabel, IllegalLogStatusBackgroundColor, IllegalLogStatusFontColor, ActionTypeLabels } from "../../../constants/activityLog";
import ActivityLogTagChip from "./ActivityLogTagChip";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import { UserLogsResource, ActivityLogResource } from "../../../types/activityLog";
import ActivityLogBody from "./ActivityLogBody";

const StyledTable = styled(Table)({
  "& > tbody > tr:nth-of-type(even)": {
    backgroundColor: "initial",
  },
});

type Props = {
  activityLogs: UserLogsResource[];
  onClickMenu: (e: React.MouseEvent<HTMLButtonElement>, activityLog: ActivityLogResource) => void;
};

const LaptopActivityLogTable = ({
  activityLogs,
  onClickMenu
}: Props): JSX.Element => {
  const query = useQuery();
  const history = useHistory();

  const goToCustomerDetailWithQueryParams = (userId: number) => {
    query.delete("page");
    const queryString = query.toString();
    const conditionParams = queryString ? "&" + queryString : "";
    history.push(`customers/detail?user_id=${userId}${conditionParams}`);
  }

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell width={150}>日時</TableCell>
          <TableCell>アクション</TableCell>
          <TableCell>内容</TableCell>
          <TableCell>店舗</TableCell>
          <TableCell width={150}>ユーザー</TableCell>
          <TableCell width={75} />
        </TableRow>
      </TableHead>
      <TableBody>
        {activityLogs.map(({ activity_log, activity_log_content, store, user }) => {
          const isIllegalOrDoubtful = ["doubtful", "illegal"].includes(activity_log.illegal_status);
          const backgroundColor = isIllegalOrDoubtful
            ? IllegalLogStatusBackgroundColor[activity_log.illegal_status]
            : IllegalLogStatusBackgroundColor[activity_log.status];

          return (
            <TableRow
              key={activity_log.id}
              style={{ backgroundColor }}
            >
              <TableCell>{formatDate(activity_log.created_at)}</TableCell>
              <TableCell>
                {isIllegalOrDoubtful && (
                  <Box
                    fontSize={12}
                    border="none"
                    py={0.5}
                    fontWeight={700}
                    color={IllegalLogStatusFontColor[activity_log.illegal_status]}
                    sx={{ wordBreak: "break-all" }}
                  >
                    <i className="fas fa-exclamation-triangle" />{" "}
                    {IllegalStatusLabel[activity_log.illegal_status]}
                    {activity_log.doubtful_reason_text && `: ${activity_log.doubtful_reason_text}`}
                  </Box>
                )}
                <Box display="flex" gap={1} alignItems="center">
                  <Typography fontWeight="600">
                    {ActionTypeLabels[activity_log.action_type] ?? ""}
                  </Typography>

                  <Box display="flex" gap={0.5}>
                    {activity_log.tags.map(tag => (
                      <ActivityLogTagChip key={tag.name} tag={tag} />
                    ))}
                  </Box>
                </Box>
                <ActivityLogBody activityLog={activity_log} />
              </TableCell>
              <TableCell>{activity_log_content?.title || ""}</TableCell>
              <TableCell>{store.name || ""}</TableCell>
              <TableCell
                style={{cursor: "pointer",color: "#1dc7ea"}}
                onClick={() => goToCustomerDetailWithQueryParams(user.id)}
              >
                {user.name || user.id}
              </TableCell>
              <TableCell>
                <IconButton
                  sx={{ lineHeight: 1 }}
                  onClick={(e) => onClickMenu(e, activity_log)}
                >
                  <i className="ri-more-fill" />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </StyledTable>
  );
};

export default LaptopActivityLogTable;
