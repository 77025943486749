import React, { FC } from "react";
import styled from "@emotion/styled";
import { PointTooltip, ResponsiveLine } from "@nivo/line";
import { Margin } from "@nivo/core";
import { AxisProps, GridValues } from "@nivo/axes";
import { ColorSchemeId } from "@nivo/colors";
import { CircularProgress } from "@mui/material";
import GraphSummaries from "./parts/GraphSummaries";
import LineGraphTooltip from "./parts/LineGraphTooltip";

const Container = styled.div<{ screenWidth: number }>`
  width: ${(props) => `${props.screenWidth - 60}px`};
  overflow-x: scroll;

  @media (min-width: 767px) {
    width: auto;
    overflow-x: initial;
  }
`;

const LineContainer = styled.div<{ height: string | number }>`
  height: ${(props) => (typeof props.height === "string" ? props.height : `${props.height}px`)};
  width: 750px;

  @media (min-width: 767px) {
    width: auto;
  }
`;

export type Props = {
  data?;
  scheme?: ColorSchemeId;
  margin?: Partial<Margin>;
  enableGridX?: boolean;
  gridXValues?: GridValues<string | number>;
  enableGridY?: boolean;
  gridYValues?: GridValues<string | number>;
  pointSize?: number;
  pointBorderWidth?: number;
  pointBorderColor?;
  pointColor?;
  pointLabelYOffset?: number;
  height?: string | number;
  axisTop?: AxisProps | null;
  axisRight?: AxisProps | null;
  axisBottom?: AxisProps | null;
  axisLeft?: AxisProps | null;
  tooltip?: PointTooltip;
  unit?: string;
  legends?;
  summaries?: {
    label: string;
    mainValue: number;
    mainValueUnit?: string;
    comparedLabel: string;
    subValue?: number;
    subValueUnit?: string;
    comparedValue: number;
    comparedValueUnit?: string;
    type: "positive" | "negative";
  }[];
};

const LineGraph: FC<Props> = ({
  data,
  scheme,
  margin = { top: 60, right: 50, bottom: 60, left: 50 },
  enableGridX = false,
  gridXValues = 5,
  enableGridY = false,
  gridYValues = 5,
  pointSize = 10,
  pointBorderWidth = 2,
  pointColor = "#FFF",
  pointBorderColor = { from: "serieColor", modifiers: [] },
  pointLabelYOffset = -12,
  height = "100%",
  axisTop = null,
  axisRight = null,
  axisBottom = {
    tickSize: 0,
    tickPadding: 10,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: 32,
    tickValues: 10,
  },
  axisLeft = {
    tickSize: 0,
    tickPadding: 10,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: -40,
    tickValues: 10,
    format: (value): string => value.toLocaleString(),
  },
  unit = "",
  tooltip = ({ point }): JSX.Element => <LineGraphTooltip point={point} unit={unit} />,
  legends = [
    {
      anchor: "top-left",
      direction: "row",
      justify: false,
      translateX: -30,
      translateY: -50,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
  summaries = [],
}) => {
  if (!data) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div
        style={{
          width: "100%",
          height: height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <p>表示するデータがありません。</p>
      </div>
    );
  }

  return (
    <>
      <Container screenWidth={window.screen.width}>
        <LineContainer height={height}>
          <ResponsiveLine
            data={data}
            margin={margin}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            enableGridX={enableGridX}
            gridXValues={gridXValues}
            enableGridY={enableGridY}
            gridYValues={gridYValues}
            colors={
              scheme
                ? { scheme: scheme }
                : ({ id }): string => {
                    const elements = data.find((d) => d.id === id);
                    return elements.color;
                  }
            }
            yFormat=" >-.2f"
            axisTop={axisTop}
            axisRight={axisRight}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            pointSize={pointSize}
            pointBorderWidth={pointBorderWidth}
            pointBorderColor={pointBorderColor}
            pointColor={pointColor}
            pointLabelYOffset={pointLabelYOffset}
            useMesh
            legends={legends}
            tooltip={tooltip}
          />
        </LineContainer>
      </Container>
      <GraphSummaries summaries={summaries} />
    </>
  );
};

export default LineGraph;
