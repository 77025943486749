import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const getStore = async (storeId) => {
  const GET_STORE = `/staff/stores/${storeId}`;

  const response = await apiClient({ method: "GET", uri: GET_STORE });
  return response.data;
};

export const createStore = async ({ params }) => {
  const CREATE_STORE = "/stores";

  const requestParams = {
    store: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_STORE,
    params: requestParams,
  });
  return response.data;
};

export const updateStore = async ({ params }) => {
  const UPDATE_STORE = `/staff/stores/${loadViewObj()?.id}`;

  const requestParams = {
    store: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_STORE,
    params: requestParams,
  });
  return response.data;
};

export const createChild = async (groupId, childIds) => {
  const CREATE_CHILD = `/staff/stores/${groupId}/child_stores`;

  const requestParams = {
    child_store: {
      ids: childIds,
    },
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_CHILD,
    params: requestParams,
  });
  return response.data;
};

export const deleteChild = async (groupId, childId) => {
  const DELETE_CHILD = `/staff/stores/${groupId}/child_stores/${childId}`;

  const response = await apiClient({ method: "DELETE", uri: DELETE_CHILD });
  return response.data;
};

export const postSubImage = async ({ params }) => {
  const POST_SUB_IMAGE = `/staff/stores/${loadViewObj()?.id}/sub_images`;

  const requestParams = {
    sub_image: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: POST_SUB_IMAGE,
    params: requestParams,
  });
  return response.data;
};

export const updateSubImage = async ({ params }) => {
  const UPDATE_SUB_IMAGE = `/staff/stores/${loadViewObj()?.id}/sub_images/${
    params.index
  }`;

  const requestParams = {
    sub_image: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_SUB_IMAGE,
    params: requestParams,
  });
  return response.data;
};

export const deleteSubImage = async (id) => {
  const DELETE_SUB_IMAGE = `/staff/stores/${
    loadViewObj()?.id
  }/sub_images/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_SUB_IMAGE,
  });
  return response.data;
};
