import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Prompt } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Section from "../../../components/Section";
import { OrderFeatureResource } from "../../../types/order";

export type PreorderSettingsFormType = {
  require_approval: boolean;
};

type Props = {
  orderFeature: OrderFeatureResource;
  disabled: boolean;
  onSubmit: (params: PreorderSettingsFormType) => void;
};

const PreorderSettingsForm = ({
  orderFeature,
  disabled,
  onSubmit
}: Props): JSX.Element => {
  const defaultValues = generateDefaultValues(orderFeature);
  const { control, handleSubmit, formState, reset } = useForm<PreorderSettingsFormType>({ defaultValues });
  const { isDirty } = formState;

  useEffect(() => {
    reset(generateDefaultValues(orderFeature));
  }, [reset, orderFeature]);

  return (
    <>
      <Section title="プレオーダー基本設定">
        <Box>
          <Controller
            control={control}
            name="require_approval"
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                sx={{ color: value ? undefined : "#aaa" }}
                label="注文の承認を必須にする"
              />
            )}
          />
        </Box>
      </Section>

      <Box display="flex" justifyContent="center" mb={5}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          sx={{ width: 300 }}
          onClick={handleSubmit(onSubmit)}
        >
          変更を保存する
        </Button>
      </Box>

      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </>
  );
};

const generateDefaultValues = (orderFeature: OrderFeatureResource): PreorderSettingsFormType => ({
  require_approval: orderFeature.require_approval
});

export default PreorderSettingsForm;
