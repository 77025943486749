import React from "react";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import { DEFAULT_AVATAR } from "../../../lib/const";
import { usePcSizeFlag, useBooleanState } from "../../../lib/hooks";
import { calcAge, formatDate } from "../../../lib/general";
import { QuestionnaireAnswerBlock, QuestionnaireDashboardSection } from "../../../types/questionnaire";
import { RestaurantSatisfactionSections, RestaurantSatisfactionSectionLabel } from "../../../constants/questionnaire";
import { GenderLabel } from "../../../constants/user";
import UserIcon from "../../Customer/parts/UserIcon";
import Rating from "./Rating";

type Props = {
  answer: QuestionnaireAnswerBlock;
  questionnaireId: number;
}

const RestaurantSatisfactionAnswerItem = ({
  answer,
  questionnaireId,
}: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();
  const history = useHistory();
  const [flag, openFlag, closeFlag] = useBooleanState(false);
  const { user, members_card_rank, store, answered_at, number_of_times, sections } = answer;

  const findAnswers = (dashboardSection: QuestionnaireDashboardSection) =>
    sections.find(({ section }) => section.dashboard_section === dashboardSection)?.answers;

  const hasSectionAnswer = (dashboardSection: QuestionnaireDashboardSection) =>
    !!findAnswers(dashboardSection)?.every(({ label, text }) => label || text);

  const totalQualityRating = Number(findAnswers("total_quality")?.[0]?.label_value) || 0;

  const copyUrlToShare = () => {
    const url = `${window.location.origin}/admin/satisfaction_questionnaire/${questionnaireId}`;
    const params = `user_id=${user.id}&number_of_times=${number_of_times}`;
    navigator.clipboard.writeText(`${url}?${params}`);
    openFlag();
    setTimeout(() => closeFlag(), 1500);
  };

  return (
    <div>
      <StyledUserContainer>
        <Box display="flex" alignItems="center" gap={1}>
          <UserIcon
            iconSize={35}
            badgeSize={10}
            avatar={DEFAULT_AVATAR[user.sex]}
            rankColor={members_card_rank?.color}
          />
          <div style={{ cursor: "pointer" }} onClick={() => history.push(`/admin/customers/detail?user_id=${user.id}`)}>
            <StyledUser>{calcAge(user.birthday)}歳 {user.sex && GenderLabel[user.sex]}</StyledUser>
            {members_card_rank && (
              <StyledRankName>{members_card_rank.name}</StyledRankName>
            )}
          </div>
        </Box>

        <StyledShareText onClick={copyUrlToShare}>
          <Tooltip
            open={flag}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="URLをコピーしました"
          >
            <div><i className="ri-link-m" style={{ fontSize: 16 }} /> 共有する</div>
          </Tooltip>
        </StyledShareText>
      </StyledUserContainer>

      <StyledSectionsContainer isPcOrTablet={isPcOrTablet}>
        <StyledSectionRatingContainer>
          <StyledTotalQuality>満足度 {totalQualityRating}</StyledTotalQuality>
          <Rating
            size={18}
            value={Number(totalQualityRating.toFixed(1))}
            good={Math.trunc(totalQualityRating) >= 3}
          />
        </StyledSectionRatingContainer>

        <StyledSectionRatingContainer>
          {sections
            .filter(({ section }) => RestaurantSatisfactionSections.includes(section.dashboard_section))
            .map(({ section, answers }) => (
              <div key={section.id}>
                {RestaurantSatisfactionSectionLabel[section.dashboard_section]}
                <StyledSectionRating rating={Number(answers?.[0]?.label_value)}>
                  {answers?.[0]?.label_value}
                </StyledSectionRating>
              </div>
            ))
          }
        </StyledSectionRatingContainer>
      </StyledSectionsContainer>

      {(hasSectionAnswer("visit_trigger") ||
        hasSectionAnswer("number_of_visit") ||
        hasSectionAnswer("review") ||
        hasSectionAnswer("order_items")) && (
        <StyledReviewContainer>
          <div>
            <StyledSectionTitle>来店のきっかけ：</StyledSectionTitle>
            {findAnswers("visit_trigger")?.map(({ label }) => label).join(" / ")}
          </div>
          <div>
            <StyledSectionTitle>これまでの来店回数：</StyledSectionTitle>
            {findAnswers("number_of_visit")?.map(({ label }) => label)}
          </div>
          <div>
            <StyledSectionTitle>注文したメニュー：</StyledSectionTitle>
            {findAnswers("order_items")?.map(({ label }) => label).join(" / ")}
          </div>
          {(hasSectionAnswer("visit_trigger") ||
            hasSectionAnswer("number_of_visit") ||
            hasSectionAnswer("order_items")) &&
            hasSectionAnswer("review") && (
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          )}

          <div>{findAnswers("review")?.map(({ text }) => text)}</div>
        </StyledReviewContainer>
      )}

      <Box mt={2}>
        <StyledAnsweredAt>{formatDate(answered_at)}</StyledAnsweredAt>{store.name}
      </Box>
    </div>
  )
}

const StyledUserContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between"
});
const StyledUser = styled("div")({
  fontWeight: "bold"
});
const StyledRankName = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.cancel.main,
}));
const StyledShareText = styled("div")({
  fontWeight: "bold",
  color: "#1DC7EA",
  cursor: "pointer"
});
const StyledSectionsContainer = styled("div", {
  shouldForwardProp: (prop: string) => "isPcOrTablet" !== prop
})<{ isPcOrTablet: boolean }>(({ theme, isPcOrTablet }) => ({
  display: "flex",
  flexDirection: isPcOrTablet ? "row" : "column",
  alignItems: isPcOrTablet ? "center" : "start",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2)
}));
const StyledTotalQuality = styled("div")({
  fontWeight: "bold",
  fontSize: 16
});
const StyledSectionRatingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: theme.spacing(1),
}));
const StyledAnsweredAt = styled("span")(({ theme }) => ({
  color: theme.palette.cancel.main,
  marginRight: theme.spacing(1),
}));
const StyledReviewContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#F8F9FA",
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: 4,
}));
const StyledSectionTitle = styled("span")({
  fontWeight: "bold",
  color: "#555"
});
const StyledSectionRating = styled("span", {
  shouldForwardProp: (prop: string) => "rating" !== prop
})<{ rating: number }>(({ theme, rating }) => ({
  fontWeight: "bold",
  marginLeft: theme.spacing(0.5),
  color: rating <= 2 ? "#E94C4C" :
    rating >= 4 ? "#CFA909" :
    "#000",
}));

export default RestaurantSatisfactionAnswerItem
