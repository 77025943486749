import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../../components/CustomButton/CustomButton";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import { ServiceContentStatus } from "../../../containers/entities/ServiceContentEntityContainer";

type Props = {
  editMode: boolean;
  disabled: boolean;
  loading: boolean;
  watchStatus: ServiceContentStatus;
  onClickDeleteButton: () => Promise<void>;
  onClickBackButton: () => void;
};

const ServiceFormButtonGroup: FC<Props> = ({
  editMode,
  disabled,
  loading,
  watchStatus,
  onClickDeleteButton,
  onClickBackButton,
}) => (
  <>
    {!editMode ? (
      <Row style={{ marginBottom: 30 }}>
        <Col md={1} xs={0} />
        <Col md={5} xs={6}>
          <CustomButton block fill onClick={onClickBackButton}>
            <b>もどる</b>
          </CustomButton>
        </Col>
        <Col md={5} xs={6}>
          <LoadingButton
            type="submit"
            label="作成する"
            loadingLabel="作成中..."
            color="info"
            block
            fill
            disabled={disabled}
            loading={loading}
          />
        </Col>
        <Col md={1} xs={0} />
      </Row>
    ) : (
      <>
        <Row style={{ marginBottom: 30 }}>
          <Col md={1} xs={0} />
          <Col md={5} xs={6}>
            <CustomButton block fill onClick={onClickBackButton}>
              <b>もどる</b>
            </CustomButton>
          </Col>
          <Col md={5} xs={6}>
            <LoadingButton
              type="submit"
              label="更新する"
              loadingLabel="編集中..."
              color="info"
              block
              fill
              disabled={disabled}
              loading={loading}
            />
          </Col>
          <Col md={1} xs={0} />
        </Row>

        <Row>
          <Col md={3} xs={2} />
          <Col md={6} xs={8}>
            <LoadingButton
              label="このサービスを削除する"
              loadingLabel="削除中..."
              color="danger"
              block
              fill={false}
              simple
              disabled={watchStatus !== "draft"}
              loadingColor="red"
              onClick={onClickDeleteButton}
            />
          </Col>
          <Col md={3} xs={2} />
        </Row>
      </>
    )}
  </>
);

export default ServiceFormButtonGroup;
