import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import MobileFrame, { FRAME_INNER_WIDTH } from "../../../components/MobileFrame";
import { useLoginContext } from "../../../providers/LoginContextProvider";

type Props = {
  noticeContent: any;
};

const IMAGE_WIDTH = FRAME_INNER_WIDTH - (8 * 2);

const NoticePreview = ({
  noticeContent,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext();
  const publishAt = noticeContent.status === "reservation" && noticeContent.publish_at
    ? noticeContent.publish_at
    : new Date();

  return (
    <StyledStickyContainer>
      <MobileFrame
        footer={noticeContent.button_label ? (
          <StyledNoticeContentButtonContainer>
            <StyledNoticeContentButton>
              {noticeContent.button_label}
            </StyledNoticeContentButton>
          </StyledNoticeContentButtonContainer>
        ) : null}
      >
        <StyledContentContainer>
          <StyledHeader>
            <div style={{ fontSize: 16 }}>お知らせ</div>
            <i className="ri-close-line" />
          </StyledHeader>

          <StyledPublishAt>
            {moment(publishAt).format("YYYY/MM/DD HH:mm")}
          </StyledPublishAt>

          <StyledTitle empty={!Boolean(noticeContent.title)}>
            {noticeContent.title || "お知らせのタイトル"}
          </StyledTitle>

          <StyledStoreContainer>
            {currentStore.app_icon_url && (
              <StyledStoreIcon src={currentStore.app_icon_url} alt="お知らせ配信元店舗" />
            )}

            <StyledStoreName>
              <div style={{ fontWeight: 700 }}>{currentStore.name}</div>
              <div>最新情報を見る</div>
            </StyledStoreName>
          </StyledStoreContainer>

          {noticeContent.image_urls.length > 0 && (
            <StyledImageHorizontalListContainer>
              <div style={{ width: "100%" }}>
                <StyledImageContainer hasManyImages={noticeContent.image_urls > 1}>
                  {noticeContent.image_urls.map((url: string) => (
                    <div style={{ width: IMAGE_WIDTH, maxHeight: IMAGE_WIDTH }}>
                      <StyledNoticeContentImage src={url} alt="添付画像" />
                    </div>
                  ))}
                </StyledImageContainer>
              </div>

              {noticeContent.image_urls.length > 1 && (
                <StyledImageChipContainer>
                  {[...Array(noticeContent.image_urls.length)].map(() => (
                    <StyledImageChip />
                  ))}
                </StyledImageChipContainer>
              )}
            </StyledImageHorizontalListContainer>
          )}

          <StyledBody empty={!Boolean(noticeContent.body)}>
            {noticeContent.body || "お知らせの本文"}
          </StyledBody>
        </StyledContentContainer>
      </MobileFrame>
    </StyledStickyContainer>
  );
};

const StyledStickyContainer = styled("div")({
  position: "sticky",
  top: 16
});
const StyledContentContainer = styled("div")({
  padding: 8,
  position: "relative"
});

const StyledHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16
});

const StyledPublishAt = styled("div")({
  fontSize: 13,
  color: "#999",
  marginBottom: 4
});

const StyledTitle = styled("div")<{ empty: boolean }>(({ empty }) => ({
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 8,
  ...(empty ? { color: "#aaa" } : {})
}));

const StyledStoreContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 24
});
const StyledStoreIcon = styled("img")({
  width: 32,
  height: 32,
  borderRadius: 32,
  marginRight: 4
});
const StyledStoreName = styled("div")({
  fontSize: 12,
  color: "#777"
});

const StyledImageHorizontalListContainer = styled("div")({
  marginBottom: 24
});
const StyledImageContainer = styled("div")<{ hasManyImages: boolean }>(({ hasManyImages }) => ({
  overflowX: hasManyImages ? "scroll" : "auto",
  overflowY: "hidden",
  display: "flex",
  alignItems: "flex-start"
}));
const StyledNoticeContentImage = styled("img")({
  width: IMAGE_WIDTH,
  borderRadius: 8,
  padding: "0 4px",
  boxSizing: "border-box",
  objectFit: "contain"
});
const StyledImageChipContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: 4,
  gap: 4
});
const StyledImageChip = styled("div")({
  width: 8,
  height: 8,
  backgroundColor: "#aaa",
  borderRadius: 4
});

const StyledBody = styled("div")<{ empty: boolean }>(({ empty }) => ({
  fontSize: 14,
  marginBottom: 60,
  whiteSpace: "pre-wrap",
  ...(empty ? { color: "#aaa" } : {})
}));

const StyledNoticeContentButtonContainer = styled("div")({
  width: "100%",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  padding: 8
});
const StyledNoticeContentButton = styled("div")({
  backgroundColor: "#FE6767",
  borderRadius: 100,
  color: "white",
  padding: "4px 8px",
  fontSize: 14,
  fontWeight: 700,
  textAlign: "center",
  width: "90%"
});

export default NoticePreview;
