import apiClient from "../apiClient";

export const getStores = async () => {
  const GET_STORES = "/staff/owner/stores";

  const response = await apiClient({ method: "GET", uri: GET_STORES });
  return response.data;
};

export const getGroups = async () => {
  const GET_STORES = "/staff/owner/stores?type=group";

  const response = await apiClient({ method: "GET", uri: GET_STORES });
  return response.data;
};

export const getStore = async ({ id }) => {
  const GET_STORE = `/staff/owner/stores/${id}`;

  const response = await apiClient({ method: "GET", uri: GET_STORE });
  return response.data;
};

export const createStore = async ({ params }) => {
  const CREATE_STORE = "/staff/owner/stores";

  const requestParams = {
    store: { ...params, email: "sample@example.com" },
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_STORE,
    params: requestParams,
  });
  return response.data;
};

export const updateStore = async ({ params, id }) => {
  const UPDATE_STORE = `/staff/owner/stores/${id}`;

  const requestParams = {
    store: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_STORE,
    params: requestParams,
  });
  return response.data;
};

export const deleteStore = async ({ id }) => {
  const DELETE_STORE = `/staff/owner/stores/${id}`;

  const response = await apiClient({ method: "DELETE", uri: DELETE_STORE });
  return response.data;
};

export const postSubImage = async ({ params, storeId }) => {
  const POST_SUB_IMAGE = `/staff/stores/${storeId}/sub_images`;

  const requestParams = {
    sub_image: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: POST_SUB_IMAGE,
    params: requestParams,
  });
  return response.data;
};

export const updateSubImage = async ({ params, storeId }) => {
  const UPDATE_SUB_IMAGE = `/staff/stores/${storeId}/sub_images/${params.index}`;

  const requestParams = {
    sub_image: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_SUB_IMAGE,
    params: requestParams,
  });
  return response.data;
};

export const deleteSubImage = async (id, storeId) => {
  const DELETE_SUB_IMAGE = `/staff/stores/${storeId}/sub_images/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_SUB_IMAGE,
  });
  return response.data;
};
