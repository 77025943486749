import { useState, useCallback, useMemo } from "react";
import { createContainer } from "unstated-next";
import axios from "axios";
import { ownerBankAccountApi } from "../../lib/api";

const useTenantEntityHook = () => {
  const [bankAccount, setBankAccount] = useState([]);
  const [bankBranch, setBankBranch] = useState([]);
  const [tenant, setTenant] = useState();
  const [cardStatus, setCardStatus] = useState([]);

  // notApply, inReview, passed
  const [cardApplicationStatus, setCardApplicationStatus] = useState(
    "notApply"
  );

  const state = {
    bankAccount,
    tenant,
    bankBranch,
    cardStatus,
    cardApplicationStatus,
  };

  const setEntityState = useMemo(() => ({
    bankAccounts: (nextBankAccount) => {
      setBankAccount(nextBankAccount.map((account) => account));
    },
    bankBranches: (nextBranches) => {
      setBankBranch(nextBranches.map((branch) => branch));
    },
    tenant: (nextTenant) => {
      setTenant(nextTenant);
      if (nextTenant?.reviewed_brands?.length) {
        setCardStatus(nextTenant.reviewed_brands.map((data) => data.status));
        const passedStatus = nextTenant.reviewed_brands.some(
          (value) => value.status === "passed"
        );
        setCardApplicationStatus(passedStatus ? "passed" : "inReview");
      } else {
        setCardStatus([]);
        setCardApplicationStatus("notApply");
      }
    },
  }), []);

  const api = {
    getBankAccounts: async (keyword) => {
      const uri = `https://bank.teraren.com/banks/search.json?name=${keyword}`;
      const result = await axios.get(uri);
      setEntityState.bankAccounts(result.data);
      return result;
    },
    getBankBranch: async (keyword, bankCode) => {
      const uri = `https://bank.teraren.com/banks/${bankCode}/branches/search.json?name=${keyword}`;
      const result = await axios.get(uri);
      setEntityState.bankBranches(result.data);
      return result;
    },
    getTenant: useCallback(async () => {
      try {
        const tenantData = await ownerBankAccountApi.getTenant();
        setEntityState.tenant(tenantData);
        return tenantData;
      } catch (e) {
        return null;
      }
    }, [setEntityState]),
    createBankAccount: async (params) => {
      const bank = await ownerBankAccountApi
        .createBankAccount({
          params: params,
        })
        .then(() => {
          api.getTenant();
        });
      return bank;
    },
    editBankAccount: async (params) => {
      const bank = await ownerBankAccountApi
        .editBankAccount({
          params: params,
        })
        .then(() => {
          api.getTenant();
        });
      return bank;
    },
  };

  return { state, setEntityState, api };
};

export default createContainer(useTenantEntityHook);
