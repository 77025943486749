import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Card } from "../../../components/Card/Card";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import {
  MembersCardRankInterface,
  MembersCardRankStatus,
} from "../../../containers/entities/MembersCardContentEntityContainer";

const optionArray = (
  status?: MembersCardRankStatus
): MembersCardRankStatus[] => {
  switch (status) {
    default:
      return ["open", "close"];
  }
};

type Props = {
  editMode: boolean;
  watchStatus: MembersCardRankStatus;
  membersCardRank: MembersCardRankInterface | null;
  membersCardRankStatusToJp: (status: MembersCardRankStatus) => string;
  onClickStatusUpdateButton: (isChecked: boolean) => Promise<void>;
};

const MembersCardRankPublishSettingCard: FC<Props> = ({
  editMode,
  watchStatus,
  membersCardRank,
  membersCardRankStatusToJp,
  onClickStatusUpdateButton,
}) => {
  const { register } =
    useFormContext<{ membersCardRank: MembersCardRankInterface }>();

  return (
    <Card
      title="公開設定"
      content={
        <>
          <SelectorForm
            name="membersCardRank.status"
            inputRef={register}
            width={180}
            style={{ marginBottom: 0 }}
            options={optionArray(membersCardRank?.status).map((status) => ({
              label: membersCardRankStatusToJp(status),
              value: status,
            }))}
            disabled={
              membersCardRank?.is_default_rank && membersCardRank?.point_enabled
            }
          />
          {editMode && (
            <MembersCardRankPublishSettingCardButtonGroup
              watchStatus={watchStatus}
              onClickStatusUpdateButton={onClickStatusUpdateButton}
              membersCardRank={membersCardRank}
            />
          )}
        </>
      }
    />
  );
};

export default MembersCardRankPublishSettingCard;

type MembersCardRankPublishSettingCardButtonGroupProps = Pick<
  Props,
  "watchStatus" | "onClickStatusUpdateButton" | "membersCardRank"
>;

const MembersCardRankPublishSettingCardButtonGroup: FC<MembersCardRankPublishSettingCardButtonGroupProps> =
  ({ watchStatus, onClickStatusUpdateButton, membersCardRank }) => (
    <Row
      style={{
        marginRight: 0,
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
      }}
    >
      <Col
        style={{
          marginLeft: 10,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <LoadingButton
          label="変更する"
          loadingLabel="変更中..."
          color="info"
          fill
          simple
          pullRight
          loadingColor="red"
          disabled={membersCardRank?.status === watchStatus}
          onClick={async (): Promise<void> => {
            await onClickStatusUpdateButton(watchStatus === "open");
          }}
        />
      </Col>
    </Row>
  );
