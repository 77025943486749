import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { loadViewObj } from "../../../lib/viewObj";
import HomeView from "../../../views/Home/HomeView";
import AdminMenuView from "../../../views/AdminMenuView";
import PasswordResetView from "../../../views/PasswordResetView";
import AccountChangeView from "../../../views/AccountChange/AccountChangeView";
import NewPasswordView from "../../../views/Password/NewPasswordView";
import StoreDashboardView from "../../../views/StoreDashboardView";
import TradeAreaDashboardView from "../../../views/Analytics/TradeAreaDashboardView";
import FeatureChoiceView from "../../../views/FeatureChoice/FeatureChoiceView";
import ConfigListView from "../../../views/ConfigListView";
import BankAccountFormView from "../../../views/BankAccountFormView";
import BankSelectView from "../../../views/BankSelectView";
import CouponListView from "../../../views/Coupon/CouponListView";
import NewCouponFormView from "../../../views/Coupon/NewCouponFormView";
import CouponDetailView from "../../../views/Coupon/CouponDetailView";
import PrivilegeCouponFormView from "../../../views/Coupon/PrivilegeCouponFormView";
import CustomerTableView from "../../../views/Customer/CustomerTableView";
import CustomerDetailView from "../../../views/Customer/CustomerDetailView";
import BannerFeatureView from "../../../views/Banner/BannerFeatureView";
import BannerFormView from "../../../views/Banner/BannerFormView";
import CalendarListView from "../../../views/Calendar/CalendarListView";
import NoticeListView from "../../../views/Notice/NoticeListView";
import NewNoticeFormView from "../../../views/Notice/NewNoticeFormView";
import NoticeDetailView from "../../../views/Notice/NoticeDetailView";
import NoticeTemplateListView from "../../../views/Notice/NoticeTemplateListView";
import NoticeTemplateFormView from "../../../views/Notice/NoticeTemplateFormView";
import OwnerSwitchView from "../../../views/Owner/OwnerSwitchView";
import PromotionalMaterialView from "../../../views/PromotionalMaterial/PromotionalMaterialView";
import ReportView from "../../../views/Report/ReportView";
import ChargeDetailView from "../../../views/ChargeDetailView";
import SatisfactionQuestionnaireListView from "../../../views/SatisfactionQuestionnaire/SatisfactionQuestionnaireListView";
import SatisfactionQuestionnaireView from "../../../views/SatisfactionQuestionnaire/SatisfactionQuestionnaireView";
import StaffListView from "../../../views/Staff/StaffListView";
import StaffFormView from "../../../views/Staff/StaffFormView";
import StampCardView from "../../../views/StampCard/StampCardView";
import MembersCardView from "../../../views/MembersCad/MembersCardView";
import MembersCardRankView from "../../../views/MembersCad/MembersCardRankView";
import ServiceFormView from "../../../views/MembersCad/ServiceFormView";
import StoreView from "../../../views/Store/StoreView";
import GroupsListView from "../../../views/Store/GroupsListView";
import GroupView from "../../../views/Store/GroupView";
import StoresFormView from "../../../views/Store/StoresFormView";
import GroupFormView from "../../../views/Store/GroupFormView";
import TicketFormView from "../../../views/Ticket/TicketFormView";
import TicketListView from "../../../views/Ticket/TicketListView";
import SubscriptionListView from "../../../views/Subscription/SubscriptionListView";
import SubscriptionFormView from "../../../views/Subscription/SubscriptionFormView";
import LineSettingView from "../../../views/Line/LineSettingView";
import ActivityLogView from "../../../views/ActivityLog/ActivityLogView";
import MenuView from "../../../views/Menu/MenuView";
import MenuFormView from "../../../views/Menu/MenuFormView";
import OptionTemplateFormView from "../../../views/Menu/OptionTemplateFormView";
import OptionFormView from "../../../views/Menu/OptionFormView";
import EcFeatureView from "../../../views/Order/EcFeatureView";
import EcManagement from "../../../views/Order/EcManagement";
import OrderFeatureView from "../../../views/Order/OrderFeatureView";
import OrderManagement from "../../../views/Order/OrderManagement";
import DiscountView from "../../../views/Discount/DiscountView";
import ToypoAccountView from "../../../views/ToypoAccount/ToypoAccountView";
import WelcomeView from "../../../views/walkThrough/WelcomeView";
import AnalyticsReportView from "../../../views/AnalyticsReport/AnalyticsReportView";
import BenchmarkFormView from "../../../views/Benchmark/BenchmarkFormView";
import BenchmarkDetailView from "../../../views/Benchmark/BenchmarkDetailView";
import BenchmarkListView from "../../../views/Benchmark/BenchmarkListView";
import { useRenderer } from "../hooks";

const LoginRoute = (): JSX.Element => {
  const renderer = useRenderer();
  const { currentStaff, currentStore, hasFunction } = useLoginContext();
  const isSetup = loadViewObj()?.isSetup;
  const shouldChangePassword = !currentStaff.registered;
  const isOwner = currentStaff.is_owner;
  const isChild = currentStore.store_type === "child";
  const hasCoupon = hasFunction("coupon");
  const hasBanner = hasFunction("banner");
  const hasCalendar = hasFunction("calendar");
  const hasSales = hasFunction("sales");
  const hasQuestionnaire = hasFunction("questionnaire");
  const hasStampCard = hasFunction("stamp_card");
  const hasMembersCard = hasFunction("members_card");
  const hasTicket = hasFunction("ticket");
  const hasSubscription = hasFunction("subscription");
  const hasMenu = hasFunction("menu");
  const hasEc = hasFunction("ec");
  const hasOrder = hasFunction("order");
  const hasReport = hasFunction("analytics_report");

  return (
    <Switch>
      <Route exact path="/admin/welcome" render={renderer(WelcomeView)} />
      <Route exact path="/admin/welcome/plugin" render={renderer(FeatureChoiceView)} />
      <Route exact path="/admin/home" render={renderer(HomeView)} />
      <Route exact path="/admin/admin_menu" render={renderer(AdminMenuView)} />
      <Route exact path="/admin/password/reset" render={renderer(PasswordResetView)} />
      <Route exact path="/admin/account" render={renderer(AccountChangeView)} />
      <Route exact path="/admin/staff/password/new" render={renderer(NewPasswordView)} />
      <Route exact path="/admin/dashboard" render={renderer(StoreDashboardView)} />
      <Route exact path="/admin/new_dashboard" render={renderer(StoreDashboardView)} />
      <Route exact path="/admin/trade_area_dashboard" render={renderer(TradeAreaDashboardView)} />
      <Route exact path="/admin/plugin" render={renderer(FeatureChoiceView)} />
      <Route exact path="/admin/customers" render={renderer(CustomerTableView)} />
      <Route exact path="/admin/customers/detail" render={renderer(CustomerDetailView)} />
      <Route exact path="/admin/notices" render={renderer(NoticeListView)} />
      <Route exact path="/admin/notices/new" render={renderer(NewNoticeFormView)} />
      <Route exact path="/admin/notices/edit" render={renderer(NoticeDetailView)} />
      <Route exact path="/admin/notices/templates" render={renderer(NoticeTemplateListView)} />
      <Route exact path="/admin/notices/templates/new" render={renderer(NoticeTemplateFormView)} />
      <Route exact path="/admin/notices/templates/edit" render={renderer(NoticeTemplateFormView)} />
      <Route exact path="/admin/owner" render={renderer(OwnerSwitchView)} />
      <Route exact path="/admin/staff" render={renderer(StaffListView)} />
      <Route exact path="/admin/staff/new" render={renderer(StaffFormView)} />
      <Route exact path="/admin/staff/edit" render={renderer(StaffFormView)} />
      <Route exact path="/admin/store/edit" render={renderer(StoreView)} />
      <Route exact path="/admin/stores/edit" render={renderer(StoresFormView)} />
      <Route exact path="/admin/child_stores" render={renderer(GroupView)} />
      <Route exact path="/admin/group/edit" render={renderer(GroupFormView)} />
      <Route exact path="/admin/line_setting" render={renderer(LineSettingView)} />
      <Route exact path="/admin/user_log" render={renderer(ActivityLogView)} />
      <Route exact path="/admin/discount" render={renderer(DiscountView)} />
      <Route exact path="/admin/toypo_account" render={renderer(ToypoAccountView)} />
      <Route exact path="/admin/benchmark" render={renderer(BenchmarkListView)} />
      {isChild && <Route exact path="/admin/promotional_material" render={renderer(PromotionalMaterialView)} />}
      {isChild && <Route exact path="/admin/benchmark/new" render={renderer(BenchmarkFormView)} />}
      <Route exact path="/admin/benchmark/:id" render={renderer(BenchmarkDetailView)} />
      {isOwner && <Route exact path="/admin/groups" render={renderer(GroupsListView)} />}
      {isOwner && <Route exact path="/admin/config" render={renderer(ConfigListView)} />}
      {isOwner && <Route exact path="/admin/config/bank_account" render={renderer(BankAccountFormView)} />}
      {isOwner && <Route exact path="/admin/config/bank_account/select" render={renderer(BankSelectView)} />}
      {isOwner && <Route exact path="/admin/stores/new" render={renderer(StoresFormView)} />}
      {hasCoupon && <Route exact path="/admin/coupons" render={renderer(CouponListView)} />}
      {hasCoupon && <Route exact path="/admin/coupons/new" render={renderer(NewCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/coupons/edit" render={renderer(CouponDetailView)} />}
      {hasCoupon && <Route exact path="/admin/coupons/duplicate" render={renderer(NewCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/auto_coupon_contents/edit" render={renderer(CouponDetailView)} />}
      {hasCoupon && <Route exact path="/admin/privilege_coupon/new" render={renderer(PrivilegeCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/privilege_coupon/edit" render={renderer(PrivilegeCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/privilege_point/new" render={renderer(PrivilegeCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/privilege_point/edit" render={renderer(PrivilegeCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/privilege_rank/new" render={renderer(PrivilegeCouponFormView)} />}
      {hasCoupon && <Route exact path="/admin/privilege_rank/edit" render={renderer(PrivilegeCouponFormView)} />}
      {hasBanner && <Route exact path="/admin/banner" render={renderer(BannerFeatureView)} />}
      {hasBanner && <Route exact path="/admin/banner/edit" render={renderer(BannerFormView)} />}
      {hasBanner && <Route exact path="/admin/banner/new" render={renderer(BannerFormView)} />}
      {hasCalendar && <Route exact path="/admin/calendar" render={renderer(CalendarListView)} />}
      {hasSales && <Route exact path="/admin/report" render={renderer(ReportView)} />}
      {hasSales && <Route exact path="/admin/report/detail" render={renderer(ChargeDetailView)} />}
      {hasQuestionnaire && <Route exact path="/admin/satisfaction_questionnaire" render={renderer(SatisfactionQuestionnaireListView)} />}
      {hasQuestionnaire && <Route exact path="/admin/satisfaction_questionnaire/:id" render={renderer(SatisfactionQuestionnaireView)} />}
      {hasStampCard && <Route exact path="/admin/stampcard" render={renderer(StampCardView)} />}
      {hasStampCard && <Route exact path="/admin/privilege_stamp/new" render={renderer(PrivilegeCouponFormView)} />}
      {hasStampCard && <Route exact path="/admin/privilege_stamp/edit" render={renderer(PrivilegeCouponFormView)} />}
      {hasMembersCard && <Route exact path="/admin/memberscard" render={renderer(MembersCardView)} />}
      {hasMembersCard && <Route exact path="/admin/memberscard/memberscard_rank/edit" render={renderer(MembersCardRankView)} />}
      {hasMembersCard && <Route exact path="/admin/memberscard/memberscard_rank/new" render={renderer(MembersCardRankView)} />}
      {hasMembersCard && <Route exact path="/admin/memberscard/service/new" render={renderer(ServiceFormView)} />}
      {hasMembersCard && <Route exact path="/admin/memberscard/service/edit" render={renderer(ServiceFormView)} />}
      {hasTicket && <Route exact path="/admin/ticket" render={renderer(TicketListView)} />}
      {hasTicket && <Route exact path="/admin/ticket/new" render={renderer(TicketFormView)} />}
      {hasTicket && <Route exact path="/admin/ticket/edit" render={renderer(TicketFormView)} />}
      {hasSubscription && <Route exact path="/admin/subscription" render={renderer(SubscriptionListView)} />}
      {hasSubscription && <Route exact path="/admin/subscription/new" render={renderer(SubscriptionFormView)} />}
      {hasSubscription && <Route exact path="/admin/subscription/edit" render={renderer(SubscriptionFormView)} />}
      {hasMenu && <Route exact path="/admin/menu" render={renderer(MenuView)} />}
      {hasMenu && <Route exact path="/admin/menu/edit" render={renderer(MenuFormView)} />}
      {hasMenu && <Route exact path="/admin/menu/new" render={renderer(MenuFormView)} />}
      {hasMenu && <Route exact path="/admin/menu/option/new" render={renderer(OptionFormView)} />}
      {hasMenu && <Route exact path="/admin/menu/option/edit" render={renderer(OptionFormView)} />}
      {hasMenu && <Route exact path="/admin/option_template/new" render={renderer(OptionTemplateFormView)} />}
      {hasMenu && <Route exact path="/admin/option_template/:id" render={renderer(OptionTemplateFormView)} />}
      {hasEc && <Route exact path="/admin/ec/setting" render={renderer(EcFeatureView)} />}
      {hasEc && <Route exact path="/admin/ec/orders" render={renderer(EcManagement)} />}
      {hasEc && <Route exact path="/admin/ec/menu" render={renderer(MenuView)} />}
      {hasEc && <Route exact path="/admin/ec/menu/edit" render={renderer(MenuFormView)} />}
      {hasEc && <Route exact path="/admin/ec/menu/new" render={renderer(MenuFormView)} />}
      {hasEc && <Route exact path="/admin/ec/menu/option/new" render={renderer(OptionFormView)} />}
      {hasEc && <Route exact path="/admin/ec/menu/option/edit" render={renderer(OptionFormView)} />}
      {hasOrder && <Route exact path="/admin/management_order" render={renderer(OrderManagement)} />}
      {hasOrder && <Route exact path="/admin/order" render={renderer(OrderFeatureView)} />}
      {hasOrder && <Route exact path="/admin/order/orders" render={renderer(OrderManagement)} />}
      {hasOrder && <Route exact path="/admin/order/setting" render={renderer(OrderFeatureView)} />}
      {hasOrder && <Route exact path="/admin/order/menu" render={renderer(MenuView)} />}
      {hasOrder && <Route exact path="/admin/order/menu/edit" render={renderer(MenuFormView)} />}
      {hasOrder && <Route exact path="/admin/order/menu/new" render={renderer(MenuFormView)} />}
      {hasOrder && <Route exact path="/admin/order/menu/option/new" render={renderer(OptionFormView)} />}
      {hasOrder && <Route exact path="/admin/order/menu/option/edit" render={renderer(OptionFormView)} />}
      {hasReport && <Route exact path="/admin/analytics_report" render={renderer(AnalyticsReportView)} />}

      <Redirect to={
        shouldChangePassword ?
          "/admin/staff/password/new" :
          isSetup ?
          "/admin/welcome" :
          "/admin/home"
      }/>
    </Switch>
  );
};

export const getPageTitle = (path: string) => {
  const paths = Object.keys(PAGE_TITLES).filter((key) => path.startsWith(key));
  const exactPath = paths.find((p) => p === path);
  return PAGE_TITLES[exactPath || paths[0]] || "";
};

const PAGE_TITLES = {
  "/admin/home": "ホーム",
  "/admin/admin_menu": "メニュー",
  "/admin/password/reset": "パスワードリセット",
  "/admin/account": "アカウント",
  "/admin/staff/password/new": "パスワード変更",
  "/admin/dashboard": "ダッシュボード",
  "/admin/new_dashboard": "ダッシュボード",
  "/admin/trade_area_dashboard": "商圏ダッシュボード",
  "/admin/plugin": "機能を追加",
  "/admin/config": "設定",
  "/admin/config/bank_account": "口座情報",
  "/admin/config/bank_account/select": "銀行選択",
  "/admin/coupons": "クーポン",
  "/admin/coupons/new": "クーポン",
  "/admin/coupons/edit": "通常配信クーポン",
  "/admin/coupons/duplicate": "クーポン",
  "/admin/auto_coupon_contents/edit": "自動配信クーポン",
  "/admin/privilege_coupon/new": "クーポン",
  "/admin/privilege_coupon/edit": "クーポン",
  "/admin/privilege_point/new": "ポイント交換クーポン",
  "/admin/privilege_point/edit": "ポイント交換クーポン",
  "/admin/privilege_rank/new": "ランクアップクーポン",
  "/admin/privilege_rank/edit": "ランクアップクーポン",
  "/admin/customers": "ユーザー一覧",
  "/admin/customers/detail": "ユーザー情報",
  "/admin/banner": "バナー",
  "/admin/banner/edit": "バナー",
  "/admin/banner/new": "バナー",
  "/admin/calendar": "カレンダー",
  "/admin/notices": "お知らせ",
  "/admin/notices/new": "お知らせ",
  "/admin/notices/edit": "お知らせ",
  "/admin/notices/templates": "お知らせテンプレート",
  "/admin/notices/templates/new": "お知らせテンプレート",
  "/admin/notices/templates/edit": "お知らせテンプレート",
  "/admin/owner": "事業者設定",
  "/admin/promotional_material": "QRコード・販促物",
  "/admin/report": "売上管理",
  "/admin/report/detail": "決済詳細",
  "/admin/satisfaction_questionnaire": "顧客満足度",
  "/admin/staff": "スタッフ管理",
  "/admin/staff/new": "スタッフ管理",
  "/admin/staff/edit": "スタッフ管理",
  "/admin/stampcard": "スタンプカード",
  "/admin/privilege_stamp/new": "特典",
  "/admin/privilege_stamp/edit": "特典",
  "/admin/memberscard": "会員カード",
  "/admin/memberscard/memberscard_rank/edit": "会員ランク編集",
  "/admin/memberscard/memberscard_rank/new": "会員ランク作成",
  "/admin/memberscard/service/new": "サービス",
  "/admin/memberscard/service/edit": "サービス",
  "/admin/store/edit": "店舗情報",
  "/admin/stores/edit": "店舗編集",
  "/admin/stores/new": "店舗追加",
  "/admin/groups": "グループ一覧",
  "/admin/child_stores": "店舗一覧",
  "/admin/group/edit": "基本情報",
  "/admin/ticket": "チケット",
  "/admin/ticket/new": "チケット",
  "/admin/ticket/edit": "チケット",
  "/admin/subscription": "サブスク",
  "/admin/subscription/new": "サブスク",
  "/admin/subscription/edit": "サブスク",
  "/admin/user_log": "利用履歴",
  "/admin/line_setting": "LINE連携",
  "/admin/menu": "メニュー",
  "/admin/menu/edit": "メニュー",
  "/admin/menu/new": "メニュー",
  "/admin/menu/option/new": "オプション",
  "/admin/menu/option/edit": "オプション",
  "/admin/option_template": "オプション",
  "/admin/ec/setting": "オンラインショップ設定",
  "/admin/ec/orders": "注文管理",
  "/admin/ec/orders/detail": "注文管理",
  "/admin/ec/menu": "メニュー設定",
  "/admin/ec/menu/edit": "メニュー設定",
  "/admin/ec/menu/new": "メニュー設定",
  "/admin/ec/menu/option/new": "オプション",
  "/admin/ec/menu/option/edit": "オプション",
  "/admin/management_order": "注文管理",
  "/admin/order": "モバイルオーダー設定",
  "/admin/management_order/detail": "注文管理",
  "/admin/order/orders": "注文管理",
  "/admin/order/setting": "モバイルオーダー設定",
  "/admin/order/orders/detail": "注文管理",
  "/admin/order/menu": "メニュー設定",
  "/admin/order/menu/edit": "メニュー設定",
  "/admin/order/menu/new": "メニュー設定",
  "/admin/order/menu/option/new": "オプション",
  "/admin/order/menu/option/edit": "オプション",
  "/admin/discount": "割引管理",
  "/admin/toypo_account": "トイポスタッフ切り替え",
  "/admin/welcome": "ウォークスルー",
  "/admin/welcome/plugin": "機能を追加",
  "/admin/analytics_report": "自動分析",
  "/admin/benchmark": "目標管理"
};

export default LoginRoute;
