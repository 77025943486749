import React, { FC } from "react";
import { NoticeTemplateInterface } from "../../../containers/entities/NoticeTemplateEntityContainer";
import NoticeCreationCouduitItem from "./NoticeCreationCouduitItem";

type Props = {
  onCreateNotice: (template?: NoticeTemplateInterface) => void;
  onCreateAutoNotice: (template?: NoticeTemplateInterface) => void;
};

const NoticeCreationConduit: FC<Props> = ({
  onCreateNotice,
  onCreateAutoNotice,
}) => (
  <div
    style={{
      marginBottom: 20,
      display: "flex",
      flexWrap: "wrap",
      gap: "12px 12px",
    }}
  >
    <NoticeCreationCouduitItem
      title="通常配信お知らせを作成"
      description="すべてのユーザーや特定のユーザーに即時または時間指定で配信できます。"
      onClick={onCreateNotice}
      icon="fas fa-bullhorn"
    />
    <NoticeCreationCouduitItem
      title="自動配信お知らせを作成"
      description="初回登録時や誕生日など、指定のタイミングでの自動発行や定期発行もできます。"
      onClick={onCreateAutoNotice}
      icon="fas fa-rocket"
    />
  </div>
);

export default NoticeCreationConduit;
