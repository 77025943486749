import React, { useRef, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  label?: string;
  tooltip: string;
  iconPosition?: "left" | "right";
  style?: React.CSSProperties;
};

const TooltipWithLabel = ({
  label,
  tooltip,
  iconPosition = "left",
  style = {}
}: Props): JSX.Element => {
  const tooltipId = useMemo<string>(uuid, []);

  // 通常ツールチップは要素にホバーした際に表示されるが
  // スマホだとホバーの概念が無いため、クリックした際に
  // ツールチップを表示するように制御する。
  // OverlayTriggerのインスタンスのshowメソッドが使えるが
  // react-bootstrapからインスタンスの型が提供されていないので
  // showメソッドを呼び出せるように自分で型定義をしています。
  const ref = useRef(null);
  const overlay = ref.current as { show: () => void; } | null;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    overlay?.show();
  };

  return (
    <OverlayTrigger
      ref={ref}
      placement="top"
      overlay={
        <Tooltip id={tooltipId}>
          <div style={{ textAlign: "left" }}>{tooltip}</div>
        </Tooltip>
      }
    >
      <div style={{ cursor: "pointer", ...style }} onClick={handleClick}>
        {iconPosition === "left" && (
          <i className="fas fa-question-circle" style={{ color: "#CFCFCF", marginRight: 4 }} />
        )}
        {Boolean(label) && <span>{label}</span>}
        {iconPosition === "right" && (
          <i className="fas fa-question-circle" style={{ color: "#CFCFCF", marginLeft: 4 }} />
        )}
      </div>
    </OverlayTrigger>
  );
};

export default TooltipWithLabel;
