import React from "react";
import Chip from "@mui/material/Chip";
import { useMobileFlag } from "../../../lib/hooks";
import { QuestionnaireStatus } from "../../../types/questionnaire";

type Props = {
  status: QuestionnaireStatus
};

const QuestionnaireStatusChip = ({ status }: Props): JSX.Element => {
  const isMobile = useMobileFlag();
  const questionnaireStatusLabel = status === "open" ? "公開中" : "非公開";
  const color = status === "open" ? "#75be6e" : "#747474";
  const backgroundColor = status === "open" ? "#f1f9f0" : "#f1f1f1";

  return (
    <Chip
      variant="status"
      label={questionnaireStatusLabel}
      style={{
        fontSize: isMobile ? 12 : 14,
        color,
        backgroundColor,
      }}
    />
  );
};

export default QuestionnaireStatusChip;
