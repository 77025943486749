import React, { useEffect, useCallback, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Icon
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import { useNotification } from "../../../providers/NotificationProvider";
import { getOrderStatusLabel, OrderTypeLabels } from "../../../constants/order";
import { OrderStatus, OrderResponse } from "../../../types/order";
import { useApi, useBooleanState } from "../../../lib/hooks";
import { calculateNextStatus } from "../utils";
import { PaymentMethodLabels } from "../../../constants/charge";
import OrderScheduledDateTimeLabel from "./OrderScheduledDateTimeLabel";
import OrderNumberModal from "./OrderNumberModal";
import OrderScheduledAtModal from "./OrderScheduledAtModal";
import ShippingLinkModal from "./ShippingLinkModal";
import OrderInquiryModal from "./OrderInquiryModal";
import OrderCancelModal from "./OrderCancelModal";
import { useLoginContext } from "../../../providers/LoginContextProvider";

type Props = {
  orderId: number;
  open: boolean;
  onClose: () => void;
};

const OrderDetailModal = ({
  orderId,
  open,
  onClose
}: Props): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);
  const [isOrderNumberModalOpen, openOrderNumberModal, closeOrderNumberModal] = useBooleanState(false);
  const [isScheduledAtModalOpen, openScheduledAtModal, closeScheduledAtModal] = useBooleanState(false);
  const [isShippingLinkModalOpen, openShippingLinkModal, closeShippingLinkModal] = useBooleanState(false);
  const [isOpenOrderCancelModal, openOpenOrderCancelModal, closeOpenOrderCancelModal] = useBooleanState(false);
  const [isInquiryModalOpen, openInquiryModal, closeInquiryModal] = useBooleanState(false);
  const { api, response, loaded, loading, reset } = useApi<OrderResponse>();
  const updateApi = useApi();
  const order = response?.order ?? null;
  const user = response?.user ?? null;
  const menus = response?.menus ?? [];
  const deliveryDestination = response?.delivery_destination ?? null;
  const nextStatus = order ? calculateNextStatus(order.order_type, order.status) : null;
  const { hasPermission } = useLoginContext();

  const fetchOrder = useCallback(() => {
    if (orderId) {
      api.get(`/orders/${orderId}`);
    }
  }, [api, orderId]);

  useEffect(() => {
    if (open) {
      fetchOrder();
    } else {
      reset();
    }
  }, [open, fetchOrder, reset]);

  const updateStatus = async (status: OrderStatus) => {
    const response = await updateApi.api.put(`/orders/${orderId}`, { status });
    if (response !== null) {
      showSuccessNotification(`注文のステータスを変更しました。`);
      fetchOrder();
    }
  };

  const updateOrderNumber = async (orderNumber: string) => {
    const response = await updateApi.api.put(`/orders/${orderId}`, { order_number: orderNumber });
    if (response !== null) {
      fetchOrder();
      closeOrderNumberModal();
      showSuccessNotification("注文番号を変更しました。");
    }
  };

  const updateScheduledAt = async (scheduledAt: string) => {
    const response = await updateApi.api.put(`/orders/${orderId}`, { scheduled_at: scheduledAt });
    if (response !== null) {
      fetchOrder();
      closeScheduledAtModal();
      showSuccessNotification("受取時間を変更しました。");
    }
  };

  const updateShippingLink = async (shippingLink: string) => {
    const response = await updateApi.api.put(`/orders/${orderId}`, { shipping_link: shippingLink });
    if (response !== null) {
      fetchOrder();
      closeShippingLinkModal();
      showSuccessNotification("配送状況リンクを変更しました。");
    }
  };

  const orderCancelHandler = () => () => {
    openOpenOrderCancelModal();
    setMenuAnchorElement(null)
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        <DialogTitle onClose={onClose}>
          注文詳細
        </DialogTitle>

        <DialogContent>
          {!loaded && (
            <Box display="flex" justifyContent="center" py={2}>
              <CircularProgress />
            </Box>
          )}

          {order && user && (
            <StyledContainer>
              {order.status === "cancel" && (
                <StyledNotesContainer>
                  <StyledInfoIcon className="ri-information-line" fontSize="medium" />
                  <Box>
                    <StyledNotesText>この注文はキャンセルされました。</StyledNotesText>
                    <StyledNotesText>キャンセル理由：{order.uber_direct_undeliverable_reason_ja}</StyledNotesText>
                  </Box>
                </StyledNotesContainer>
              )}
              <Box bgcolor="#F3F5F9" borderRadius={1} p={1.5} display="flex">

                <Box display="flex" flexDirection="column" gap={1} flex="1 0 0">
                  {order.order_type !== "ec" && (
                    <OrderInfo label="注文種別">
                      {OrderTypeLabels[order.order_type]}
                      {order.uber_direct_uuid && " with Uber Eats"}
                    </OrderInfo>
                  )}

                  <OrderInfo label="注文日時">
                    {moment(order.created_at).format("YYYY/M/D(dddd) HH:mm")}
                  </OrderInfo>

                  <OrderInfo label="支払い">
                    {order.payment_method === "credit" && order.status === "unapproved" && (
                      `支払い前 (${PaymentMethodLabels[order.payment_method]})`
                    )}
                    {order.payment_method === "credit" && order.status !== "unapproved" && (
                      `支払い済み (${PaymentMethodLabels[order.payment_method]})`
                    )}
                    {order.payment_method !== "credit" && (
                      PaymentMethodLabels[order.payment_method]
                    )}
                  </OrderInfo>

                  <OrderInfo label="氏名">
                    <StyledLink onClick={() => history.push(`/admin/customers/detail?user_id=${order.user_id}`)}>
                      {hasPermission("SHOW_USER_NAME") ? user.name : user.id}
                    </StyledLink>
                  </OrderInfo>

                  {user.phone_number && hasPermission("SHOW_USER_PHONE_NUMBER") && (
                    <OrderInfo label="電話番号">
                      {user.phone_number}

                      <StyledPhoneCall href={`tel:${user.phone_number}`}>
                        <i className="ri-phone-fill" />
                        電話をかける
                      </StyledPhoneCall>
                    </OrderInfo>
                  )}
                  {user.phone_number && !hasPermission("SHOW_USER_PHONE_NUMBER") && (
                    <OrderInfo label="電話番号">---</OrderInfo>
                  )}

                  {order.uber_direct_delivery_fee !== null && (
                    <OrderInfo label="Uber配送料">
                      {order.uber_direct_delivery_fee.toLocaleString()}円
                    </OrderInfo>
                  )}

                  {!order.uber_direct_uuid && deliveryDestination && (
                    <OrderInfo label="配達先">
                      〒{deliveryDestination.zipcode}
                      <div>
                        {deliveryDestination.address}
                        {deliveryDestination.house_number}
                        {deliveryDestination.building_name}
                      </div>
                    </OrderInfo>
                  )}
                </Box>

                <Box borderLeft="solid 1px #ddd" mx={2}>&nbsp;</Box>

                <Box width={180} flexBasis={180} alignSelf="center">
                  <EmphasisOrderInfo
                    label="注文番号"
                    onChange={order.uber_direct_uuid ? null : openOrderNumberModal}
                  >
                    <Box fontSize={24} fontWeight={700} mb={1.5}>
                      {order.order_number}
                    </Box>
                  </EmphasisOrderInfo>

                  {order.order_type === "delivery" && (
                    <EmphasisOrderInfo label="商品準備日時">
                      <Box mb={1.5}>
                        <OrderScheduledDateTimeLabel value={order.prepared_at} />
                      </Box>
                    </EmphasisOrderInfo>
                  )}

                  {order.order_type !== "delivery" && (
                    <EmphasisOrderInfo
                      label="受渡日時"
                      onChange={order.uber_direct_uuid || ["done", "cancel"].includes(order.status) ? null : openScheduledAtModal}
                    >
                      <OrderScheduledDateTimeLabel value={order.done_at || order.scheduled_at} />
                    </EmphasisOrderInfo>
                  )}
                </Box>
              </Box>

              {order.description && (
                <HorizontalOrderInfo label="備考">
                  <StyledItemText>{order.description}</StyledItemText>
                </HorizontalOrderInfo>
              )}

              {order.order_type === "ec" && order.status === "shipped" && (
                <HorizontalOrderInfo label="配達状況リンク">
                  <StyledItemText>{order.shipping_link}</StyledItemText>
                  <StyledLink onClick={openShippingLinkModal}>変更する</StyledLink>
                </HorizontalOrderInfo>
              )}

              <div>
                {menus?.map((menu) => (
                  <Box key={menu.order_menu_id} px={1} py={2} borderBottom="solid 1px #ddd">
                    <Box display="flex">
                      <Box flex="1 0 0">{menu.title}</Box>
                      <Box flexBasis={60} textAlign="right">×{menu.quantity}</Box>
                      <Box flexBasis={80} textAlign="right">{(menu.quantity * menu.price).toLocaleString()}円</Box>
                    </Box>

                    {menu.option_items.map((optionItem) => (
                      <Box key={optionItem.id} fontSize={13} color="#777" mt={1}>{optionItem.title}</Box>
                    ))}
                  </Box>
                ))}


                {order.delivery_price !== null && (
                  <Box px={1} py={2} borderBottom="solid 1px #ddd" display="flex">
                    <Box flex="1 0 0">配送料</Box>
                    <Box flexBasis={80} textAlign="right">{order.delivery_price.toLocaleString()}円</Box>
                  </Box>
                )}

                <Box display="flex" fontWeight={700} px={1} py={2} borderBottom="solid 1px #ddd">
                  <Box flex="1 0 0">合計</Box>
                  <Box flexBasis={80} textAlign="right">
                    {order.amount.toLocaleString()}円
                  </Box>
                </Box>
              </div>
            </StyledContainer>
          )}
        </DialogContent>

        <DialogActions sx={{ pt: 2 }}>
          {order?.status !== "cancel" && (
            <Button
              variant="outlined"
              color="cancel"
              sx={{ width: 250 }}
              disabled={loading || updateApi.loading}
              onClick={(e) => setMenuAnchorElement(e.currentTarget)}
            >
              注文の管理
            </Button>
          )}

          {order?.status !== "cancel" && (
            <Menu
              anchorEl={menuAnchorElement}
              open={Boolean(menuAnchorElement)}
              onClose={() => setMenuAnchorElement(null)}
            >
              {order?.uber_direct_uuid && (
                <MenuItem
                  onClick={() => {
                    setMenuAnchorElement(null);
                    openInquiryModal();
                  }}
                >
                  サポートにお問い合わせ
                </MenuItem>
              )}
              <MenuItem
                sx={{ color: "#FF4A55" }}
                onClick={orderCancelHandler()}
              >
                注文をキャンセルする
              </MenuItem>
            </Menu>
          )}

          {order?.status === "receive" && (
            <Button
              variant="outlined"
              color="submit"
              fullWidth
              disabled={loading || updateApi.loading}
              onClick={() => updateStatus("wait")}
            >
              {getOrderStatusLabel(order.order_type, "wait")}にする
            </Button>
          )}

          {order && nextStatus && (
            <Button
              variant="contained"
              color="submit"
              fullWidth
              disabled={loading || updateApi.loading}
              onClick={() => updateStatus(nextStatus)}
            >
              {nextStatus === "receive"
                ? "注文を受け付ける"
                : `${getOrderStatusLabel(order.order_type, nextStatus)}にする`}
            </Button>
          )}

          {order && !nextStatus && (
            <Button variant="contained" fullWidth disabled>
              {getOrderStatusLabel(order.order_type, order.status)}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {order && (
        <OrderNumberModal
          defaultOrderNumber={order.order_number}
          open={isOrderNumberModalOpen}
          disabled={updateApi.loading}
          onSubmit={updateOrderNumber}
          onClose={closeOrderNumberModal}
        />
      )}

      {order && (
        <OrderScheduledAtModal
          defaultScheduledAt={order.scheduled_at}
          open={isScheduledAtModalOpen}
          disabled={updateApi.loading}
          onSubmit={updateScheduledAt}
          onClose={closeScheduledAtModal}
        />
      )}

      {order && (
        <ShippingLinkModal
          defaultShippingLink={order.shipping_link ?? ""}
          open={isShippingLinkModalOpen}
          disabled={updateApi.loading}
          onSubmit={updateShippingLink}
          onClose={closeShippingLinkModal}
        />
      )}

      <OrderInquiryModal
        open={isInquiryModalOpen}
        onClose={closeInquiryModal}
      />


      <OrderCancelModal
        orderId={orderId ?? 0}
        open={isOpenOrderCancelModal}
        onClose={() => {
          closeOpenOrderCancelModal();
          onClose();
        }}
      />  
    </>
  );
};

type OrderInfoProps = {
  label: string;
  children: React.ReactNode;
};
const OrderInfo = ({
  label,
  children
}: OrderInfoProps): JSX.Element => (
    <Box display="flex">
      <StyledItemLabel fixed>{label}</StyledItemLabel>
      <StyledItemText>{children}</StyledItemText>
    </Box>
  );

type EmphasisOrderInfoProps = {
  label: string;
  onChange?: (() => void) | null;
  children: React.ReactNode;
};
const EmphasisOrderInfo = ({
  label,
  onChange,
  children
}: EmphasisOrderInfoProps): JSX.Element => (
    <>
      <Box display="flex" gap={1} mb={0.5}>
        <StyledItemLabel>{label}</StyledItemLabel>
        {onChange && <StyledLink onClick={onChange}>変更する</StyledLink>}
      </Box>

      {children}
    </>
  );

type HorizontalOrderInfoProps = {
  label: string;
  children: React.ReactNode;
};
const HorizontalOrderInfo = ({
  label,
  children
}: HorizontalOrderInfoProps) => (
    <Box bgcolor="#F3F5F9" borderRadius={1} p={1.5} display="flex" mt={1}>
      <StyledItemLabel>{label}</StyledItemLabel>
      <Box ml={1} flex="1 0 0">
        {children}
      </Box>
    </Box>
  );

const StyledItemLabel = styled("div", {
  shouldForwardProp: (prop) => prop !== "fixed"
})<{ fixed?: boolean }>(({ fixed }) => ({
  fontWeight: 700,
  color: "#787878",
  fontSize: 14,
  flexBasis: fixed ? 80 : undefined,
  width: fixed ? 80 : undefined
}));
const StyledItemText = styled("div")({
  color: "#333",
  fontSize: 14,
  flex: "1 0 0"
});

const StyledLink = styled("div")(({ theme }) => ({
  color: theme.palette.submit.main,
  cursor: "pointer",
  fontWeight: 700
}));

const StyledPhoneCall = styled("a")(({ theme }) => ({
  color: theme.palette.submit.main,
  fontWeight: 700,
  marginLeft: theme.spacing(1)
}));

const StyledNotesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#FFCDC7",
  border: "1px solid #E46453",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5)
}));

const StyledNotesText = styled(Typography)({
  color: "#333333",
  fontWeight: 600,
  fontSize: 15
});

const StyledContainer = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1.5),
  display: "flex",
  flexDirection: "column"
}));

const StyledInfoIcon = styled(Icon)({
  color: "#E46453",
  display: "flex",
  alignItems: "center"
});

export default OrderDetailModal;
