import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { ecFeatureApi } from "../../lib/api";

export interface EcFeatureInterface {
  id: number;
  active: boolean;
  appeal_sentence: string;
  cash_on_delivery_enabled: boolean;
  contact_method: "mail" | "phone" | "address";
  contact_value: string;
  contact_phone_number: string;
  contact_mail_address: string;
  contact_link: string;
  earliest_shipped_day: number;
  longest_shipped_day: number;
  minimum_purchase_price: number;
  shipped_business_day: number;
  shipping_price: number;
  status: "open" | "close" | "pause";
}

const useEcFeatureEntityHook = () => {
  const [ecFeature, setEcFeature] = useState<EcFeatureInterface>();

  return {
    state: { ecFeature },
    constant: {},
    logic: {
      setEcFeature,
      getEcFeature: useCallback(async (): Promise<EcFeatureInterface> => {
        const newEcFeature = await ecFeatureApi.getEcFeature();
        setEcFeature(newEcFeature);
        return newEcFeature;
      }, []),
      updateEcFeature: async (
        params: Partial<EcFeatureInterface>
      ): Promise<EcFeatureInterface> => {
        const newEcFeature = await ecFeatureApi.updateEcFeature({ params });
        setEcFeature(newEcFeature);
        return newEcFeature;
      },
    },
  };
};

export default createContainer(useEcFeatureEntityHook);
