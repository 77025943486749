import { useApi } from "../../lib/hooks";
import { ConditionGroup } from "../../components/UserGroup/UserGroupModal";

type Callback = (id: number) => void;

export const useUserGroupCollectionApiForCustomer = (userGroupCollectionId: number | undefined) => {
  const { api, loading } = useApi();

  const updateName = async (name: string, callback: Callback) => {
    if (!userGroupCollectionId) {
      return;
    }

    const url = `/user_group_collections/${userGroupCollectionId}`;
    const response = await api.patch(url, { name });
    if (!response) {
      return;
    }

    callback(userGroupCollectionId);
  };

  const updateConditions = async (conditionGroups: ConditionGroup[], callback: Callback) => {
    if (!userGroupCollectionId) {
      return;
    }

    const message = "ユーザーグループの絞り込み条件を上書きします。よろしいですか？";
    if (!window.confirm(message)) {
      return;
    }

    const url = `/user_group_collections/${userGroupCollectionId}`;
    const response = await api.patch(url, {
      condition_groups: conditionGroups.map(({ conditions }) => conditions)
    });
    if (!response) {
      return;
    }

    callback(userGroupCollectionId);
  };

  const create = async (name: string, conditionGroups: ConditionGroup[], callback: Callback) => {
    if (name === "" || conditionGroups.length === 0) {
      return;
    }

    const response = await api.post("/user_group_collections", {
      name,
      condition_groups: conditionGroups.map(({ conditions }) => conditions)
    });
    if (!response) {
      return;
    }

    callback(response.data.id);
  };

  const _delete = async (callback: Callback) => {
    if (!userGroupCollectionId) {
      return;
    }

    const response = await api.delete(`/user_group_collections/${userGroupCollectionId}`);
    if (!response) {
      return;
    }

    callback(userGroupCollectionId);
  };

  return { updateName, updateConditions, create, delete: _delete, loading };
};
