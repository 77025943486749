/* eslint @typescript-eslint/camelcase: 0 */
/* eslint camelcase: 0 */
import { createContainer } from "unstated-next";
import { ownerOwnerStaffsApi } from "../../lib/api";

export interface OwnerStaffInterface {
  id: number;
  name: string;
  email: string;
  push_token: string;
  registerd: boolean;
  stores: Store[];
  groups: Store[];
  owner: {
    id: number;
    name: string;
    role: string;
  }[];
  created_at: Date;
}

interface Store {
  id: number;
  name: string;
  role: string;
  store_type: string;
  image_url: string;
}

const useOwnerStaffEntityHook = () => ({
  logic: {
    giveOwnerToStaff: async (id: number): Promise<OwnerStaffInterface> => {
      const staff: OwnerStaffInterface = await ownerOwnerStaffsApi.giveOwnerToStaff(
        id
      );
      return staff;
    },
    removeOwnerFromStaff: async (id: number): Promise<OwnerStaffInterface> => {
      const staff: OwnerStaffInterface = await ownerOwnerStaffsApi.removeOwnerFromStaff(
        id
      );
      return staff;
    },
  },
});

export default createContainer(useOwnerStaffEntityHook);
