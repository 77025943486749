// !!! IMPORTANT !!!
// このコンポーネントは古い記法で実装されているためDeprecatedにします。
// 新しくsrc/components/Sectionを利用するようにしましょう。
import React, { Component } from "react";

export class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonValue: 1,
    };
  }

  render() {
    return (
      <div
        className={`card${this.props.plain ? " card-plain" : ""}`}
        style={this.props.noMargin ? { marginBottom: 0, marginTop: 0 } : null}
      >
        <div
          className={`header${this.props.hCenter ? " text-center" : ""}`}
          style={{
            display: null,
            ...this.props.headerStyle,
          }}
        >
          <h4 className="title" style={{ display: "inline" }}>
            <b style={{ marginRight: 10, fontSize: this.props.titleFontSize }}>
              {this.props.title}
            </b>
            {this.props.titleRightContent}
          </h4>
          <div className="rightHeader">{this.props.rightContent}</div>

          {this.props.category && (
            <div className="category" style={{ whiteSpace: "pre-wrap" }}>
              {this.props.category}
            </div>
          )}
        </div>
        <div
          className={
            (this.props.notContent ? "" : "content") +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctTableFullWidth ? " table-full-width" : "") +
            (this.props.ctTableResponsive ? " table-responsive" : "") +
            (this.props.ctTableUpgrade ? " table-upgrade" : "")
          }
        >
          {this.props.content}

          {(!!this.props.legend || !!this.props.stats) && (
            <div className="footer">
              {this.props.legend}
              {this.props.stats != null ? <hr /> : ""}
              <div className="stats">
                <i className={this.props.statsIcon} /> {this.props.stats}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Card;
