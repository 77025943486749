import { AxiosResponse } from "axios";
import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { openTimeApi } from "../../lib/api";

export type SpecialOpenTimeItem = {
  id: number | null;
  body: string;
  title: string;
  color: string;
  start_year: number | null;
  start_month: number | null;
  start_date: number | null;
  start_hour: number | null;
  start_minute: number | null;
  start_day: number | null;
  end_year: number | null;
  end_month: number | null;
  end_date: number | null;
  end_hour: number | null;
  end_minute: number | null;
  end_day: number | null;
  is_all_day: boolean;
  is_holiday: boolean;
};

export type UpdateSpecialOpenTimeItem = Omit<
  SpecialOpenTimeItem,
  "color" | "start_day" | "end_day"
>;

export type DefaultOpenTimeItem = {
  id: number | null;
  title: string;
  start_hour: number | null;
  start_minute: number | null;
  end_hour: number | null;
  end_minute: number | null;
  is_all_day: boolean;
  is_holiday: boolean;
  start_day: number;
  end_day: number;
};

const defaultOpenTimeData: DefaultOpenTimeItem[] = [...Array(8)].map(
  (_, day) => ({
    id: null,
    title: "",
    start_hour: 0,
    start_minute: 0,
    end_hour: 0,
    end_minute: 0,
    is_all_day: false,
    is_holiday: false,
    start_day: day,
    end_day: day,
  })
);

const useOpenTimeEntityHook = () => {
  const [defaultOpenTime, setDefaultOpenTime] =
    useState<DefaultOpenTimeItem[]>(defaultOpenTimeData);

  const [specialOpenTimes, setSpecialOpenTimes] = useState<
    SpecialOpenTimeItem[]
  >([]);

  return {
    state: { defaultOpenTime, specialOpenTimes },
    logic: {
      getDefaultOpenTime: useCallback(async (): Promise<DefaultOpenTimeItem[]> => {
        const response: DefaultOpenTimeItem[] =
          await openTimeApi.getDefaultOpenTime();

        if (response.length) {
          setDefaultOpenTime(response);
          return response;
        }
        return defaultOpenTimeData;
      }, []),
      getSpecialOpenTime: useCallback(async ({
        page,
      }): Promise<AxiosResponse<SpecialOpenTimeItem[]>> => {
        const response: AxiosResponse<SpecialOpenTimeItem[]> =
          await openTimeApi.getSpecialOpenTime({ page });

        setSpecialOpenTimes(response.data);
        return response;
      }, []),
      updateDefaultOpenTimes: async (
        data: DefaultOpenTimeItem[]
      ): Promise<DefaultOpenTimeItem[]> => {
        const response: DefaultOpenTimeItem[] =
          await openTimeApi.updateDefaultOpenTime(data);
        setDefaultOpenTime(response);
        return response;
      },
      createSpecialOpenTime: async (
        data: UpdateSpecialOpenTimeItem
      ): Promise<SpecialOpenTimeItem[]> => {
        const response: SpecialOpenTimeItem[] =
          await openTimeApi.createSpecialOpenTime(data);
        setSpecialOpenTimes(response);
        return response;
      },
      updateSpecialOpenTime: async (
        data: UpdateSpecialOpenTimeItem
      ): Promise<SpecialOpenTimeItem[]> => {
        const response: SpecialOpenTimeItem[] =
          await openTimeApi.updateSpecialOpenTime(data);
        setSpecialOpenTimes(response);
        return response;
      },
      deleteDefaultOpenTime: async (
        id: number
      ): Promise<DefaultOpenTimeItem[]> => {
        const response: DefaultOpenTimeItem[] =
          await openTimeApi.deleteDefaultOpenTime({
            openTimeId: id,
          });
        setDefaultOpenTime(response);
        return response;
      },
      deleteSpecialOpenTime: async (
        id: number
      ): Promise<SpecialOpenTimeItem[]> => {
        const response: SpecialOpenTimeItem[] =
          await openTimeApi.deleteSpecialOpenTime({ openTimeId: id });
        setSpecialOpenTimes(response);
        return response;
      },
    },
  };
};

export default createContainer(useOpenTimeEntityHook);
