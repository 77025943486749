import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FormInput from "../../../components/FormInputs/FormInput";
import CustomButton from "../../../components/CustomButton/CustomButton";

type Props = {
  disabled: boolean;
  show: boolean;
  title: string;
  submitButtonLabel: string;
  defaultName?: string;
  onSubmit: (name: string) => void;
  onHide: () => void;
};

const UserGroupCollectionNameModal = ({
  disabled,
  show,
  onHide,
  title,
  submitButtonLabel,
  defaultName,
  onSubmit,
}: Props): JSX.Element => {
  const [name, setName] = useState("");
  const canSubmit = Boolean(name);

  useEffect(() => {
    if (show) {
      setName(defaultName || "");
    }
  }, [defaultName, show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormInput
          placeholder="ユーザーグループ名"
          value={name}
          onChange={({ target }) => setName(target.value)}
          autoFocus
        />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomButton
            disabled={disabled}
            style={{ marginRight: 8 }}
            onClick={onHide}
          >
            <b>キャンセル</b>
          </CustomButton>

          <CustomButton
            bsStyle="info"
            fill
            onClick={() => canSubmit && onSubmit(name)}
            disabled={disabled || !canSubmit}
          >
            <b>{submitButtonLabel}</b>
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserGroupCollectionNameModal;
