import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from "react";
import ChargeEntityContainer, {
  TotalCharge,
  convertCategoryToJp,
  convertPaymentMethodToJp,
} from "../../../containers/entities/ChargeEntityContainer";

import { Card } from "../../../components/Card/Card";
import PeriodSelectorForm from "./PeriodSelectorForm";

export type DataPeriod = {
  type: DataPeriodType;
  from: string | null;
  to: string | null;
};

export type DataPeriodType =
  | "today"
  | "yesterday"
  | "this_week"
  | "this_month"
  | "custom";

interface Props {
  totalCharge: TotalCharge;
  dataPeriod: DataPeriod;
  setDataPeriod: Dispatch<SetStateAction<DataPeriod>>;
}

const styles: { [key: string]: CSSProperties } = {
  content: {
    display: "flex",
    flexDirection: "column",
  },
  totalChargeAmount: {
    alignItems: "flex-end",
    fontWeight: "bold",
    fontSize: 40,
  },
  totalChargeAmountUnit: {
    marginLeft: 8,
    fontSize: 24,
  },
  line: {
    width: "100%",
    margin: 0,
    marginTop: 2,
  },
  label: {
    fontSize: 16,
    margin: 0,
    marginTop: 8,
  },
  brakedownContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  brakedownContent: {
    backgroundColor: "rgb(240, 241, 241)",
    padding: "8px 12px",
    margin: "4px 0",
    borderRadius: 2,
    marginRight: 8,
    width: 240,
  },
  brakedownTitle: {
    fontSize: 14,
  },
  brakedownValue: {
    fontSize: 24,
    fontWeight: "bold",
  },
  brakedownItem: {
    display: "flex",
  },
  brakedownItemLabel: {
    fontSize: 14,
    flex: 1,
    margin: 0,
  },
  brakedownItemValue: {
    fontSize: 14,
    flex: 1,
    margin: 0,
  },
};

const TotalChargeCardWrapper = (): JSX.Element => {
  const chargeEntityContainer = ChargeEntityContainer.useContainer();
  const { totalCharge } = chargeEntityContainer.state;
  const { getTotalCharge } = chargeEntityContainer.logic;

  // filtering
  const [dataPeriod, setDataPeriod] = useState<DataPeriod>({
    type: "today",
    from: null,
    to: null,
  });

  useEffect(() => {
    if (dataPeriod.from && dataPeriod.to) {
      getTotalCharge(dataPeriod);
    }
  }, [getTotalCharge, dataPeriod]);

  return (
    <TotalChargeCard
      totalCharge={totalCharge}
      dataPeriod={dataPeriod}
      setDataPeriod={setDataPeriod}
    />
  );
};

const TotalChargeCard: React.FC<Props> = ({
  totalCharge,
  dataPeriod,
  setDataPeriod,
}) => (
  <Card
    title="合計売上"
    rightContent={
      <PeriodSelectorForm
        dataPeriod={dataPeriod}
        setDataPeriod={setDataPeriod}
      />
    }
    content={
      <div style={styles.content}>
        {/* 売上 */}
        <div style={styles.totalChargeAmount}>
          {/* スマホサイズ fa-2x fontSize: 35 */}
          {totalCharge.amount?.toLocaleString()}
          <span style={styles.totalChargeAmountUnit}>円</span>
        </div>

        {totalCharge.details.length > 0 && (
          <div>
            <hr style={styles.line} />

            <p style={styles.label}>機能別内訳</p>
            <div style={styles.brakedownContainer}>
              {totalCharge.details.map((detail) => (
                <div style={styles.brakedownContent}>
                  <p style={styles.brakedownTitle}>
                    {convertCategoryToJp(detail.category)}
                  </p>
                  <p style={styles.brakedownValue}>
                    {detail.amount.toLocaleString()}円
                  </p>

                  {detail.payment_methods.map((method) => (
                    <div style={styles.brakedownItem}>
                      <p style={styles.brakedownItemLabel}>
                        {convertPaymentMethodToJp(method.payment_method)}:
                      </p>
                      <p style={styles.brakedownItemValue}>
                        {method.amount.toLocaleString()}円
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    }
  />
);

export default TotalChargeCardWrapper;
