import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import Table, { TableProps } from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { StaffsResponse } from "../types/staff";
import { useGetApiCall, useBooleanState } from "../lib/hooks";
import FormLabel from "./FormInputs/FormLabel";

export type StaffNotificationPolicyForm = {
  staff_id: number | null;
  enabled_email: boolean;
  enabled_push_notification: boolean;
  include_child_store_staffs: boolean;
};

type Props = {
  storeId?: number;
  disabled?: boolean;
  policies: StaffNotificationPolicyForm[];
  includeChildStoreStaffs?: boolean;
  tableProps?: TableProps;
  onChange: (policies: StaffNotificationPolicyForm[]) => void;
};

const StaffNotificationPolicySelectorForm = ({
  storeId,
  disabled = false,
  policies,
  includeChildStoreStaffs = false,
  tableProps = {},
  onChange
}: Props): JSX.Element => {
  const [isStaffSelectDialogOpen, openStaffSelectDialog, closeStaffSelectDialog] = useBooleanState(false);
  const filterInputRef = useRef<HTMLInputElement>(null);
  const [filterText, setFilterText] = useState("");
  const [selectedStaffIds, setSelectedStaffIds] = useState<number[]>([]);
  const staffsPath = includeChildStoreStaffs ? "/staffs?type=all" : "/staffs";
  const { loading, response } = useGetApiCall<StaffsResponse>(staffsPath);
  const staffs = (response?.staffs || []).filter((staff) =>
    !storeId ||
      staff.stores.some((store) => store.id === storeId) ||
      staff.groups.some((group) => group.id === storeId)
  );
  const filteredStaffs = staffs.filter(({ staff }) => (
    staff.name.indexOf(filterText) > -1 || staff.email.indexOf(filterText) > -1
  ));

  const policyForAll = policies.find(({ staff_id }) => staff_id === null);
  const hasNoPolicy = policies.length === 0;
  const [isAllStaff, setIsAllStaff] = useState(Boolean(policyForAll) || hasNoPolicy);
  const [enabledAllPushNotification, setEnabledAllPushNotification] = useState(policyForAll?.enabled_push_notification ?? hasNoPolicy);
  const [enabledAllEmail, setEnabledAllEmail] = useState(policyForAll?.enabled_email ?? hasNoPolicy);

  useEffect(() => {
    if (isStaffSelectDialogOpen) {
      setFilterText("");
      setTimeout(() => filterInputRef.current?.focus(), 100);
    }
  }, [isStaffSelectDialogOpen]);

  return (
    <>
      <Box>
        <FormLabel label="通知対象者" />
        <RadioGroup
          row
          value={isAllStaff ? "all_staffs" : "selected_staffs"}
          onChange={(e) => {
            if (e.target.value === "all_staffs") {
              setIsAllStaff(true);
              onChange([{
                staff_id: null,
                enabled_email: enabledAllEmail,
                enabled_push_notification: enabledAllPushNotification,
                include_child_store_staffs: includeChildStoreStaffs
              }]);
            } else {
              setIsAllStaff(false);
              openStaffSelectDialog();

              const newPolicies = policies.filter(({ staff_id }) => staff_id !== null);
              onChange(newPolicies);
              setSelectedStaffIds(newPolicies.map(({ staff_id }) => staff_id) as number[]);
            }
          }}
        >
          <FormControlLabel
            value="all_staffs"
            control={<Radio disabled={disabled} />}
            label="すべてのスタッフ"
          />
          <FormControlLabel
            value="selected_staffs"
            control={<Radio disabled={disabled} />}
            label="スタッフを選択"
          />
        </RadioGroup>

        {isAllStaff && (
          <Box mt={1}>
            <FormLabel label="通知方法" />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  checked={enabledAllPushNotification}
                  onChange={(e) => {
                    setEnabledAllPushNotification(e.target.checked);
                    onChange([{
                      staff_id: null,
                      enabled_email: enabledAllEmail,
                      enabled_push_notification: e.target.checked,
                      include_child_store_staffs: includeChildStoreStaffs
                    }]);
                  }}
                />
              }
              label="プッシュ通知"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  checked={enabledAllEmail}
                  onChange={(e) => {
                    setEnabledAllEmail(e.target.checked);
                    onChange([{
                      staff_id: null,
                      enabled_email: e.target.checked,
                      enabled_push_notification: enabledAllPushNotification,
                      include_child_store_staffs: includeChildStoreStaffs
                    }]);
                  }}
                />
              }
              label="メール通知"
            />
          </Box>
        )}

        {!isAllStaff && (
          <Button
            disabled={disabled}
            size="small"
            color="submit"
            startIcon={<i className="ri-add-line" />}
            onClick={() => {
              openStaffSelectDialog();
              setSelectedStaffIds(policies.map(({ staff_id }) => staff_id).filter((staffId) => staffId) as number[]);
            }}
          >
            スタッフを追加する
          </Button>
        )}

        {!isAllStaff && policies.length > 0 && (
          <StyledTable {...tableProps}>
            <TableHead>
              <TableRow>
                <TableCell>スタッフ</TableCell>
                <TableCell sx={{ width: { mobile: 130, tablet: 270 } }}>通知方法</TableCell>
                <TableCell width={50}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => {
                const staff = staffs.find(({ staff }) => staff.id === policy.staff_id)?.staff;

                return (
                  <TableRow key={policy.staff_id}>
                    <TableCell>{staff?.name}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={staff?.push_token
                          ? ""
                          : "このスタッフはプッシュ通知が有効になっていないため、プッシュ通知は利用できません。"}
                      >
                        <StyledNotificationMethodLabel
                          control={
                            <Checkbox
                              checked={Boolean(staff?.push_token) && policy.enabled_push_notification}
                              disabled={disabled || !staff?.push_token}
                              onChange={(e) => {
                                policy.enabled_push_notification = e.target.checked;
                                const newPolicies = [...policies];
                                onChange(newPolicies);
                              }}
                            />
                          }
                          label="プッシュ通知"
                        />
                      </Tooltip>

                      <StyledNotificationMethodLabel
                        control={
                          <Checkbox
                            checked={policy.enabled_email}
                            disabled={disabled}
                            onChange={(e) => {
                              policy.enabled_email = e.target.checked;
                              const newPolicies = [...policies];
                              onChange(newPolicies);
                            }}
                          />
                        }
                        label="メール通知"
                      />
                    </TableCell>
                    <TableCell>
                      <i
                        className="ri-delete-bin-line"
                        style={{
                          cursor: disabled ? "default" : "pointer",
                          color: disabled ? "#ccc" : undefined
                        }}
                        onClick={() => {
                          if (disabled) return;
                          const newPolicies = policies.filter((v) => v !== policy);
                          onChange(newPolicies);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        )}
      </Box>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={isStaffSelectDialogOpen}
        onClose={closeStaffSelectDialog}
      >
        <DialogContent sx={{ p: 0 }}>
          <StyledInput
            ref={filterInputRef}
            type="text"
            placeholder="スタッフ名またはメールアドレスで検索..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />

          {loading ? (
            <StyledProgressContainer>
              <CircularProgress />
            </StyledProgressContainer>
          ) : (
            <StyledItemContainer>
              {filteredStaffs.length === 0 ? (
                <StyledEmptyContainer>
                  <i className="ri-search-line" />

                  スタッフが見つかりません。
                </StyledEmptyContainer>
              ) : (
                filteredStaffs.map(({ staff, groups, stores }) => (
                  <StyledCheckboxLabel
                    key={staff.id}
                    control={
                      <Checkbox
                        checked={selectedStaffIds.some((id) => id === staff.id)}
                        onChange={(e) => {
                          const newSelectedStaffIds = e.target.checked
                            ? [...selectedStaffIds, staff.id]
                            : selectedStaffIds.filter((id) => id !== staff.id);
                          setSelectedStaffIds(newSelectedStaffIds);
                        }}
                      />
                    }
                    label={
                      <>
                        <Box fontSize={16}>{staff.name}</Box>
                        <Box fontSize={14} color="#777">{staff.email}</Box>
                        <Box fontSize={13} color="#9B9B9B">
                          {[...groups, ...stores].map(({ name }) => name).join(", ")}
                        </Box>
                      </>
                    }
                  />
                ))
              )}
            </StyledItemContainer>
          )}
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button
            fullWidth
            variant="outlined"
            color="cancel"
            onClick={() => {
              closeStaffSelectDialog();
            }}
          >
            キャンセル
          </Button>

          <Button
            fullWidth
            variant="contained"
            color="submit"
            disabled={loading}
            onClick={() => {
              const newPolicies = selectedStaffIds.map((id) => {
                const staff = staffs.find(({ staff }) => staff.id === id)?.staff;
                return {
                  staff_id: id,
                  enabled_email: true,
                  enabled_push_notification: Boolean(staff?.push_token),
                  include_child_store_staffs: includeChildStoreStaffs
                };
              });
              onChange(newPolicies);

              closeStaffSelectDialog();
            }}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StyledInput = styled("input")({
  borderWidth: 0,
  fontSize: 16,
  padding: "12px 8px",
  borderBottom: "solid 1px #eee",
  width: "100%"
});

const StyledProgressContainer = styled("div")({
  width: "100%",
  height: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledItemContainer = styled("div")({
  height: 300,
  overflowY: "scroll"
});
const StyledEmptyContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 16,
  fontSize: 16,
  color: "#aaa",
  "& > i": { marginRight: 8 }
});

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1, 0),
  margin: 0,
  display: "flex",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#eee"
  },
  "& > .MuiCheckbox-root": {
    alignSelf: "flex-start",
    paddingTop: 2
  }
}));

const StyledNotificationMethodLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0),
  [theme.breakpoints.down("tablet")]: {
    margin: theme.spacing(0),
  },
  "& > .MuiCheckbox-root": {
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  "& > .MuiTypography-root": {
    whiteSpace: "nowrap"
  }
}));

const StyledTable = styled(Table)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    "& .MuiTableCell-root": {
      padding: theme.spacing(0.5, 0, 0.5, 2)
    }
  },
}));

export default StaffNotificationPolicySelectorForm;
