import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { formatDateExceptTime } from "../../../lib/general";
import { RestaurantSatisfactionQuestionnaireBlock } from "../../../types/questionnaire";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import QuestionnaireStatusChip from "./QuestionnaireStatusChip";
import Rating from "./Rating";

type Props = {
  questionnaires: RestaurantSatisfactionQuestionnaireBlock[];
  onClickRow: (questionnaireId: number) => void;
  onClickPin: (questionnaire: RestaurantSatisfactionQuestionnaireBlock) => void;
}

const MobileQuestionnaireList = ({
  questionnaires,
  onClickRow,
  onClickPin,
}: Props): JSX.Element => {
  return (
    <List dense disablePadding>
      {questionnaires.map((questionnaireBlock) => (
        <QuestionnaireListItem
          key={questionnaireBlock.questionnaire.id}
          questionnaireBlock={questionnaireBlock}
          onClickRow={() => onClickRow(questionnaireBlock.questionnaire.id)}
          onClickPin={() => onClickPin(questionnaireBlock)}
        />
      ))}
    </List>
  )
}

type QuestionnaireListItemProps = {
  questionnaireBlock: RestaurantSatisfactionQuestionnaireBlock;
  onClickRow: () => void;
  onClickPin: () => void;
};

const QuestionnaireListItem = ({
  questionnaireBlock,
  onClickRow,
  onClickPin,
}: QuestionnaireListItemProps): JSX.Element => {
  const { currentStore } = useLoginContext();
  const { questionnaire, store, is_default, total_quality_average, total_answer_count } = questionnaireBlock;
  const totalQualityAverage = Number((total_quality_average ?? 0).toFixed(1));

  return (
    <ListItem
      divider
      onClick={onClickRow}
      sx={{ p: 2, flexDirection: "column", alignItems: "stretch" }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          {is_default ? (
            <i className="ri-pushpin-fill" style={{ color: "#1dc7ea", fontSize: 18 }} />
          ) : (
            <i
              className="ri-pushpin-fill"
              style={{ color: "#A9A9A9", fontSize: 18 }}
              onClick={(e) => { onClickPin(); e.stopPropagation(); }}
            />
          )}
          <Box fontSize={15} fontWeight={700}>{questionnaire.title}</Box>
        </Box>
        <QuestionnaireStatusChip status={questionnaire.status} />
      </Box>

      <Box fontSize={12} fontWeight={currentStore.id === questionnaire.store_id ? 700 : "normal"}>
        {store.name}
        </Box>

      <Box fontSize={14} display="flex" alignItems="center" gap={0.5}>
        <Box>{totalQualityAverage || "--"}</Box>
        <Box>
          <Rating size={22} value={totalQualityAverage} good={totalQualityAverage >= 3} />
        </Box>
        <Box>{total_answer_count}件</Box>
      </Box>

      <Box fontSize={12}>{formatDateExceptTime(questionnaire.created_at)}</Box>
    </ListItem>
  )
}

export default MobileQuestionnaireList
