import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "../../../components/DialogTitle";
import { getOrderStatusLabel } from "../../../constants/order";
import { OrderResource, OrderStatus } from "../../../types/order";
import { calculateAvailableStatuses } from "../utils";

type Props = {
  order: OrderResource;
  onSubmit: (newStatus: OrderStatus) => void;
  open: boolean;
  disabled: boolean;
  onClose: () => void;
};

const OrderStatusChangeModal = ({
  order,
  onSubmit,
  open,
  disabled,
  onClose
}: Props): JSX.Element => {
  const [selectedStatus, setSelectdStatus] = useState<OrderStatus | null>(null);
  const nextStatuses = calculateAvailableStatuses(order.order_type, order.status);

  useEffect(() => {
    if (open) {
      setSelectdStatus(order.status);
    }
  }, [order, open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle onClose={onClose}>
        どの状態に更新しますか？
      </DialogTitle>

      <DialogContent>
        <RadioGroup
          value={selectedStatus}
          onChange={(e) => setSelectdStatus(e.target.value as OrderStatus)}
        >
          {nextStatuses.map((nextStatus) => (
            <FormControlLabel
              value={nextStatus}
              control={<Radio disabled={disabled} />}
              label={getOrderStatusLabel(order.order_type, nextStatus)}
            />
          ))}
        </RadioGroup>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          onClick={() => {
            if (selectedStatus === order.status) {
              onClose();
            } else if (selectedStatus) {
              onSubmit(selectedStatus);
            }
          }}
        >
          更新する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderStatusChangeModal;
