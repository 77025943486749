import React, { useEffect, useState } from "react";
import { Prompt, withRouter } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Grid, Row, Col, Form, HelpBlock, FormGroup, Image } from "react-bootstrap";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { Card } from "../../components/Card/Card";
import FormInputs from "../../components/FormInputs/FormInputs";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import Button from "../../components/CustomButton/CustomButton";
import FormLabel from "../../components/FormInputs/FormLabel";
import ImageForm from "../../components/FormInputs/ImageForm";
import CustomFormGroup from "../../components/FormInputs/FormGroup";
import ImageTrimmingInput from "../../components/ImageInput/ImageTrimmingInput";
import StoreEntityContainer from "../../containers/entities/StoreEntityContainer";
import { getObjectDiff } from "../../lib/general";
import { isViewObjOwnerGroup, isViewObjStore, setViewObj } from "../../lib/viewObj";
import StoresFormPublishSettingCard from "./_parts/StoresFormPublishSettingCard";

const StoreDefaultValue = {
  name: "",
  business_type: "",
  appeal: "",
  address: "",
  phone_number: "",
  open_time: "",
  customer_unit_price: 0,
  monthly_customer: null,
  status: "close",
  open_on_map: false,
  code: "",
};

const StoresFormView = ({ location, history, displayNotification }) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const { currentStaff, reloadCurrentStaff } = useLoginContext();
  const ownerGroup = currentStaff.groups?.find((g) => g.store_type === "owner_group");
  const [storeId] = useState(location.state?.storeId);
  const [store, setStore] = useState({});
  const editMode = !!storeId;
  const storeEntityContainer = StoreEntityContainer.useContainer();
  const { api } = storeEntityContainer;
  const { getStore } = api

  const defaultValues = { store: StoreDefaultValue };
  const methods = useForm({ defaultValues });
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
    trigger,
  } = methods;

  const watchStatus = watch("store.status");
  const watchImage = watch("store.app_icon", null);
  const watchFirstMessageStaffImage = watch("store.first_message_staff_image", null);
  const watchSubImageUrls = watch("store.sub_image_urls", []);

  const goToOwnerStoresView = () => {
    isViewObjOwnerGroup()
      ? history.push("/admin/child_stores")
      : setViewObj({
          type: "group",
          id: ownerGroup.id,
          name: ownerGroup.name,
          status: ownerGroup.status,
          isOwnerGroup: ownerGroup.store_type === "owner_group",
          role: ownerGroup.role,
        })((window.location.href = "/admin/child_stores"));
  };

  const handlePhoneNumber = (e) => {
    const phoneNumber = parsePhoneNumberFromString(e.target.value, "JP");
    if (phoneNumber && phoneNumber.isValid()) {
      setValue("store.phone_number", phoneNumber.formatNational());
    } else {
      setValue("store.phone_number", e.target.value);
    }
  };

  const processFormData = (formStore) => {
    const proceedData = formStore;
    if (proceedData.app_icon === store.app_icon_url)
      delete proceedData.app_icon;

    if (proceedData.code === "") delete proceedData.code;

    if (proceedData.customer_unit_price === 0)
      delete proceedData.customer_unit_price;

    if (editMode) {
      delete proceedData.status;
    }

    return proceedData;
  };

  const onClickUpdateButton = async (data) => {
    const formData = processFormData(data.store);
    const params = getObjectDiff(store, formData);

    await api
      .updateStore(params, storeId)
      .then(async (newStore) => {
        displayNotification({
          level: "success",
          message: "編集を保存しました。",
        });
        await reloadCurrentStaff();
        reset({ store: newStore });
      })
      .then(!isViewObjStore() && (() => history.goBack()))
      .catch((e) => {
        if (e.response.data.message.indexOf("duplicate key value") !== -1) {
          displayNotification({
            level: "error",
            message:
              "店舗コードが重複しています。別の店舗コードを設定してください。",
          });
          return;
        }
        displayNotification({ level: "error", message: e.message });
      });
  };

  const onClickCreateButton = async (data) => {
    const formData = processFormData(data.store);

    await api
      .createStore(formData)
      .then(async (newStore) => {
        displayNotification({
          level: "success",
          message: "店舗を作成しました。",
        });
        await reloadCurrentStaff();
        reset({ store: newStore });
      })
      .then(goToOwnerStoresView)
      .catch((e) =>
        displayNotification({ level: "error", message: e.message })
      );
  };

  const onSubmit = (data) => {
    setIsButtonLoading(true);
    if (editMode) {
      onClickUpdateButton(data).finally(() => setIsButtonLoading(false));
    } else {
      onClickCreateButton(data).finally(() => setIsButtonLoading(false));
    }
  };

  const onError = () => {
    displayNotification({
      level: "error",
      message: "値が正しく入力されていません。",
    });
  };

  const createSubImage = async (img) => {
    const createSubImageVal = img;
    const subImage = {
      image: createSubImageVal,
    };

    try {
      await api.createSubImage(subImage, store.id).then((res) => {
        setValue("store.sub_image_urls", res.subImageUrls);
      });
      displayNotification({
        level: "success",
        message: "紹介画像を作成しました。",
      });
    } catch (error) {
      displayNotification({ level: "error", message: error.message });
    }
  };

  const updateSubImage = async (updateImage, id) => {
    const updateSubImageVal = updateImage;
    const subImage = {
      image: updateSubImageVal,
      index: id,
    };
    try {
      await api.updateSubImage(subImage, store.id).then((res) => {
        setValue("store.sub_image_urls", res.subImageUrls);
      });
      displayNotification({
        level: "success",
        message: "紹介画像を編集しました。",
      });
    } catch (error) {
      displayNotification({ level: "error", message: error.message });
    }
  };

  const deleteSubImage = async (index) => {
    if (!window.confirm("この画像を削除しますか？")) {
      return;
    }
    try {
      await api.deleteSubImage(index, store.id);
      api.getStore(storeId).then((res) => {
        setValue(
          "store.sub_image_urls",
          res.sub_image_urls || res.subImageUrls
        );

        displayNotification({
          level: "success",
          message: "画像を削除しました。",
        });
      });
    } catch (error) {
      displayNotification({ level: "error", message: error.message });
    }
  };

  const onClickStatusUpdateButton = async (status) => {
    const isDirtyExceptStatus = !!Object.keys(dirtyFields.store || {}).filter(
      (key) => key !== "status"
    ).length;
    const isSave = isDirtyExceptStatus
      ? window.confirm(
          "行った変更が保存されていない可能性があります。変更内容を保存しますか？"
        )
      : false;
    if (isSave && !(await trigger())) {
      displayNotification({
        level: "error",
        message: "値が正しく入力されていません。",
      });
      return;
    }

    const isUpdateStatus = window.confirm(
      `${status === "open" ? "公開" : "非公開"}にしますか？`
    );

    if (!isSave && !isUpdateStatus) {
      return;
    }

    const data = getValues();
    const params = isSave
      ? {
          ...getObjectDiff(store, processFormData(data.store)),
          status: isUpdateStatus ? status : undefined,
        }
      : { status: isUpdateStatus ? status : undefined };

    setIsBackdropLoading(true);
    await api
      .updateStore(params, storeId)
      .then(async (newStore) => {
        displayNotification({
          level: "success",
          message: `更新しました。`,
        });
        await reloadCurrentStaff();
        reset({ store: newStore });
      })
      .then(!isViewObjStore() && (() => history.goBack()))
      .catch((e) => {
        if (e.response.data.message.indexOf("duplicate key value") !== -1) {
          displayNotification({
            level: "error",
            message:
              "店舗コードが重複しています。別の店舗コードを設定してください。",
          });
          return;
        }
        displayNotification({ level: "error", message: e.message });
      })
      .finally(() => setIsBackdropLoading(false));
  };

  useEffect(() => {
    if (storeId) {
      getStore(storeId).then((res) => {
        setValue("store", res);
        setValue("store.app_icon", res.app_icon_url || res.appIconUrl);
        setValue(
          "store.sub_image_urls",
          res.sub_image_urls || res.subImageUrls
        );
        setValue(
          "store.first_message_staff_image",
          res.first_message_staff_image_url || res.firstMessageStaffImageUrl
        );
        setValue("store.business_type", res.business_type || res.businessType);
        setStore(res);
      });
    }
  }, [getStore, setValue, storeId]);

  useEffect(() => {
    register({ name: "store.app_icon" });
    register({ name: "store.first_message_staff_image" });
    register({ name: "store.sub_image_urls" });

    return () => {
      unregister(["store.app_icon"]);
      unregister(["store.first_message_staff_image"]);
      unregister(["store.sub_image_urls"]);
    };
  }, [register, unregister]);

  return (
    <FormProvider {...methods}>
      <Grid fluid className="content">
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          {editMode && (
            <Row>
              <Col md={12}>
                <StoresFormPublishSettingCard
                  editMode={editMode}
                  onClickStatusUpdateButton={onClickStatusUpdateButton}
                  store={store}
                  watchStatus={watchStatus}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <Card
                title="基本情報"
                content={
                  <Row>
                    <Col md={12}>
                      <CustomFormGroup>
                        <ImageForm
                          aspect={1}
                          height={180}
                          width={180}
                          label="店舗ロゴ画像"
                          value={watchImage}
                          onChange={(img) => setValue("store.app_icon", img)}
                        />
                      </CustomFormGroup>

                      <FormInputs
                        properties={[
                          {
                            name: "store.name",
                            label: "店舗名",
                            ncol: "col-md-7",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "店舗名を入力してください。",
                            }),
                            validationMessage: errors?.store?.name?.message,
                          },

                          {
                            name: "store.address",
                            label: "住所",
                            ncol: "col-md-7",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "住所を入力してください。",
                            }),
                            validationMessage: errors?.store?.address?.message,
                          },
                          {
                            name: "store.phone_number",
                            label: "電話番号",
                            ncol: "col-md-7",
                            type: "tel",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "電話番号を入力してください。",
                            }),
                            onChange: handlePhoneNumber,
                            validationMessage:
                              errors?.store?.phone_number?.message,
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>

          {!editMode && (
            <Row>
              <Col md={12}>
                <StoresFormPublishSettingCard
                  editMode={editMode}
                  onClickStatusUpdateButton={onClickStatusUpdateButton}
                  store={store}
                  watchStatus={watchStatus}
                />
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: 30 }}>
            <Col md={10} mdOffset={1} xs={12}>
              <Col md={6} xs={6}>
                <Button block fill onClick={history.goBack}>
                  <b>もどる</b>
                </Button>
              </Col>
              <Col md={6} xs={6}>
                <LoadingButton
                  type="submit"
                  label={editMode ? "保存する" : "作成する"}
                  loadingLabel={editMode ? "保存中..." : "作成中..."}
                  color="info"
                  block
                  fill
                  disabled={!isDirty}
                  loading={isButtonLoading}
                />
              </Col>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                title="追加情報"
                content={
                  <Row>
                    <Col md={12}>
                      <FormInputs
                        properties={[
                          {
                            name: "store.code",
                            label: "店舗コード",
                            ncol: "col-md-7",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              validate: (value) =>
                                value.indexOf(" ") > -1 ||
                                value.indexOf("　") > -1
                                  ? "空白を入力することはできません。"
                                  : true,
                            }),
                            disabled: store.code,
                            validationMessage: errors?.store?.code?.message,
                          },
                        ]}
                      />
                      {!store.code && (
                        <HelpBlock className="text-muted">
                          店舗コードは一度設定すると変更できなくなります。
                        </HelpBlock>
                      )}

                      {editMode && (
                        <CustomFormGroup>
                          <FormLabel label="紹介画像" />
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {watchSubImageUrls.map((subImage, i) => (
                              <ImageTrimmingInput
                                doTrimming
                                key={subImage}
                                id={i}
                                canDelete
                                doCompress
                                doDisplayImage
                                width={200}
                                height={130}
                                altMessage="紹介画像"
                                image={subImage}
                                createImage={createSubImage}
                                updateImage={updateSubImage}
                                deleteImage={deleteSubImage}
                              />
                            ))}
                            {watchSubImageUrls.flat().length !== 3 ? (
                              <ImageTrimmingInput
                                doCompress
                                doTrimming
                                blank
                                multiple
                                width={200}
                                height={130}
                                id={3}
                                doDisplayImage={false}
                                updateImage={createSubImage}
                              />
                            ) : null}
                          </div>
                          <p style={{ fontSize: 13, color: "#888" }}>
                            ※紹介画像は3枚まで追加でき、縦横比16:9で表示されます。
                          </p>
                        </CustomFormGroup>
                      )}

                      <FormInputs
                        properties={[
                          {
                            name: "store.appeal",
                            label: "紹介文",
                            ncol: "col-md-10",
                            rows: "3",
                            type: "text",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register,
                            validationMessage: errors?.store?.appeal?.message,
                          },
                          {
                            name: "store.customer_unit_price",
                            unit: "円",
                            label: "客単価",
                            ncol: "col-md-3 col-sm-3 col-xs-8",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({ valueAsNumber: true }),
                            validationMessage: errors?.store?.customer_unit_price?.message,
                          },
                          {
                            name: "store.monthly_customer",
                            unit: "人",
                            label: "月次来客数",
                            ncol: "col-md-3 col-sm-3 col-xs-8",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({ valueAsNumber: true }),
                          },
                        ]}
                      />

                      <FormInputs
                        properties={[
                          {
                            name: "store.url",
                            label: "店舗Webページ",
                            ncol: "col-md-6",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register,
                          },
                          {
                            name: "store.map_url",
                            label: "マップURL（GoogleマップなどのURL）",
                            ncol: "col-md-6",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register,
                          },
                          {
                            name: "store.twitter_url",
                            label: "Twitter URL",
                            ncol: "col-md-6",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register,
                          },
                          {
                            name: "store.instagram_url",
                            label: "Instagram URL",
                            ncol: "col-md-6",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register,
                          },
                          {
                            name: "store.facebook_url",
                            label: "Facebook URL",
                            ncol: "col-md-6",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register,
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                title="初回登録ユーザーへの登録メッセージ"
                category={
                  "メッセージが設定されていない場合はデフォルトのアニメーションが表示されます。\nメッセージ表示時にスタッフ名・スタッフ画像が設定されていない場合は、それぞれ店舗名・店舗画像が表示されます。"
                }
                content={
                  <Row>
                    <Col md={12}>
                      <FormInputs
                        properties={[
                          {
                            name: "store.first_message",
                            label: "メッセージ内容",
                            placeholder:
                              "お店の簡単な紹介やアプリで配信することなどを入力しましょう",
                            note: "150文字以内で入力してください。",
                            ncol: "col-md-10",
                            rows: "3",
                            type: "text",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register({
                              maxLength: {
                                value: 150,
                                message: "150文字以内でご入力ください。",
                              },
                            }),
                          },
                          {
                            name: "store.first_message_staff_name",
                            label: "スタッフ名",
                            placeholder: "例：店長",
                            note: "10文字以内で入力してください。",
                            ncol: "col-md-6",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              maxLength: {
                                value: 10,
                                message: "10文字以内でご入力ください。",
                              },
                            }),
                          },
                        ]}
                      />
                      <FormGroup>
                        <ImageForm
                          doTrimming
                          aspect={1}
                          height={175}
                          width={175}
                          label="スタッフ画像"
                          value={watchFirstMessageStaffImage}
                          onChange={(img) => {
                            setValue("store.first_message_staff_image", img, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }}
                          validationMessage={
                            errors?.store?.first_message_staff_image?.message
                          }
                          deleteImage={() => {
                            setValue("store.first_message_staff_image", null, {
                              shouldDirty: true,
                            });
                          }}
                        />
                        <HelpBlock className="text-muted">
                          画像は縦横比1:1で表示されます。
                        </HelpBlock>
                      </FormGroup>

                      <FormGroup style={{ marginBottom: 20 }}>
                        <FormLabel label="メッセージ例" />
                        <Col>
                          <Image
                            style={{ width: 320, height: 320 }}
                            src={require("../../assets/img/new_conection_message_sample.png")}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 30 }}>
            <Col md={10} mdOffset={1} xs={12}>
              <Col md={6} xs={6}>
                <Button block fill onClick={history.goBack}>
                  <b>もどる</b>
                </Button>
              </Col>
              <Col md={6} xs={6}>
                <LoadingButton
                  type="submit"
                  label={editMode ? "保存する" : "作成する"}
                  loadingLabel={editMode ? "保存中..." : "作成中..."}
                  color="info"
                  block
                  fill
                  disabled={!isDirty}
                  loading={isButtonLoading}
                />
              </Col>
            </Col>
          </Row>
        </Form>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isBackdropLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Prompt
          when={isDirty}
          message="行った変更が保存されていない可能性があります。このページを離れますか？"
        />
      </Grid>
    </FormProvider>
  );
};

export default withRouter(StoresFormView);
