import React, { FC, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useBooleanState } from "../../../lib/hooks";
import { Card } from "../../../components/Card/Card";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import { SubscriptionContentStatus } from "../../../containers/entities/SubscriptionContentEntityContainer";
import { SubscriptionContentStatusLabel } from "../../../constants/subscription";
import { isViewObjOwnerGroup } from "../../../lib/viewObj";
import { Alert, FormChangedAlertModal } from "../../../components/Modal/FormChangedAlertModal";
import { SubscriptionForm } from "../type";

const modalTitle = (status: SubscriptionContentStatus) => {
  switch (status) {
    case "close": return "サブスクを販売停止";
    case "open": return "サブスクを公開";
    case "limited_open": return "サブスクを限定公開";
    default: return "";
  }
};

const modalSubmitButtonLabel = (status: SubscriptionContentStatus) => {
  switch (status) {
    case "close": return "販売を停止する";
    case "open": return "公開する";
    case "limited_open": return "限定公開する";
    default: return "";
  }
};

const modalBody = (status: SubscriptionContentStatus) => {
  switch (status) {
    case "close": return "サブスクの販売を停止してよろしいですか？";
    case "open": return "サブスクを公開してよろしいですか？";
    case "limited_open": return "サブスクを限定公開してよろしいですか？";
    default: return "";
  }
};

type Props = {
  disabled: boolean;
  editMode: boolean;
  subscriptionStatus?: SubscriptionContentStatus;
  watchStatus: SubscriptionContentStatus;
  onChangeStatus: (status: SubscriptionContentStatus) => void;
};

const optionArray = (status?: SubscriptionContentStatus): SubscriptionContentStatus[] => {
  // グループでの限定公開機能は現状対応していないため、
  // 「グループのときは限定公開選択不可」
  // 「すでにグループで限定公開にしてしまっている場合のみ限定公開がプルダウンに表示」
  // という分岐（一次対応）
  switch (status) {
    case "draft":
    case undefined:
      return isViewObjOwnerGroup() ? ["draft", "open"] : ["draft", "open", "limited_open"];
    case "sold":
      return ["sold"];
    case "limited_open":
      return ["open", "limited_open", "close"];
    default:
      return isViewObjOwnerGroup() ? ["open", "close"] : ["open", "limited_open", "close"];
  }
};

const SubscriptionPublishSettingCard: FC<Props> = ({
  disabled,
  editMode,
  subscriptionStatus,
  watchStatus,
  onChangeStatus,
}) => {
  const { register } = useFormContext<SubscriptionForm>();
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const [newStatus, setNewStatus] = useState<SubscriptionContentStatus>(subscriptionStatus || "draft");
  const openModalFor = (s: SubscriptionContentStatus) => {
    setNewStatus(s);
    openModal();
  };
  const isChanged = editMode && watchStatus !== subscriptionStatus;

  const warnings: string[] = [];
  if (newStatus === "close") {
    warnings.push("サブスクを販売停止すると、ユーザーが新規で購入することができなくなります。ただし、既に購入済みのサブスクは期限まで引き続き利用できます。");
  }
  if (newStatus === "limited_open") {
    warnings.push("サブスクを限定公開にすると、一覧に表示されなくなります。ただし、既に購入済みのサブスクは引き続き利用できます。");
  }

  return (
    <>
      <Card
        title="公開設定"
        content={
          <>
            <SelectorForm
              name="status"
              inputRef={register}
              width={180}
              style={{ marginBottom: 0 }}
              options={optionArray(subscriptionStatus).map((status) => ({
                label: SubscriptionContentStatusLabel[status],
                value: status,
              }))}
              showChangeIndicator={isChanged}
            />

            {editMode && (
              <Row style={{ margin: "10px 0 0 0" }}>
                <Col>
                  <LoadingButton
                    label="変更する"
                    loadingLabel="変更中..."
                    color="info"
                    fill
                    simple
                    pullRight
                    loadingColor="red"
                    disabled={!isChanged || disabled}
                    onClick={() => openModalFor(watchStatus)}
                  />
                </Col>
              </Row>
            )}
          </>
        }
      />

      <FormChangedAlertModal
        title={modalTitle(newStatus)}
        show={isModalOpen}
        submitButtonLabel={modalSubmitButtonLabel(newStatus)}
        onSubmit={() => onChangeStatus(newStatus)}
        onCancel={closeModal}
      >
        {modalBody(newStatus)}

        {warnings.length > 0 && (
          <Alert severity="warning">
            <ul>{warnings.map((warning) => <li>{warning}</li>)}</ul>
          </Alert>
        )}
      </FormChangedAlertModal>
    </>
  );
};

export default SubscriptionPublishSettingCard;
