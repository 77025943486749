import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { formatDateExceptTime } from "../../../lib/general";
import { RestaurantSatisfactionQuestionnaireBlock } from "../../../types/questionnaire";
import QuestionnaireStatusChip from "./QuestionnaireStatusChip";
import Rating from "./Rating";

type Props = {
  questionnaires: RestaurantSatisfactionQuestionnaireBlock[];
  onClickRow: (questionnaireId: number) => void;
  onClickPin: (questionnaire: RestaurantSatisfactionQuestionnaireBlock) => void;
};

const LaptopQuestionnaireList = ({
  questionnaires,
  onClickRow,
  onClickPin,
}: Props): JSX.Element => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>アンケート名</TableCell>
          <TableCell>配信元</TableCell>
          <TableCell>平均総合満足度</TableCell>
          <TableCell>回答数</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>作成日</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {questionnaires.map((questionnaireBlock) => (
          <SatisfactionQuestionnaireRow
            key={questionnaireBlock.questionnaire.id}
            questionnaireBlock={questionnaireBlock}
            onClickRow={() => onClickRow(questionnaireBlock.questionnaire.id)}
            onClickPin={() => onClickPin(questionnaireBlock)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

type SatisfactionQuestionnaireRowProps = {
  questionnaireBlock: RestaurantSatisfactionQuestionnaireBlock;
  onClickRow: () => void;
  onClickPin: () => void;
};

const SatisfactionQuestionnaireRow = ({
  questionnaireBlock,
  onClickRow,
  onClickPin,
}: SatisfactionQuestionnaireRowProps): JSX.Element => {
  const { currentStore } = useLoginContext();
  const { questionnaire, store, is_default, total_quality_average, total_answer_count } = questionnaireBlock;
  const totalQualityAverage = Number((total_quality_average ?? 0).toFixed(1));

  return (
    <TableRow hover onClick={onClickRow} style={{ cursor: "pointer" }}>
      {is_default ? (
        <TableCell>
          <Tooltip title="この満足度アンケートの結果がホーム画面に表示されます">
            <i className="ri-pushpin-fill" style={{ color: "#1dc7ea", fontSize: 20 }} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell onClick={(e) => { onClickPin(); e.stopPropagation(); }}>
          <i className="ri-pushpin-fill" style={{ color: "#A9A9A9", fontSize: 20 }} />
        </TableCell>
        )
      }
      <TableCell>
        <Box fontWeight={700}>{questionnaire.title}</Box>
      </TableCell>
      <TableCell>
        <Box fontWeight={currentStore.id === questionnaire.store_id ? 700 : "normal"}>
          {store.name}
        </Box>
      </TableCell>
      <TableCell>
        <StyledRatingContainer>
          <Box width={24} textAlign="center">{totalQualityAverage || "--"}</Box>
          <Box>
            <Rating
              size={24}
              value={totalQualityAverage}
              good={totalQualityAverage >= 3}
            />
          </Box>
        </StyledRatingContainer>
      </TableCell>
      <TableCell>{total_answer_count}件</TableCell>
      <TableCell>
        <QuestionnaireStatusChip status={questionnaire.status} />
      </TableCell>
      <TableCell>{formatDateExceptTime(questionnaire.created_at)}</TableCell>
    </TableRow>
  );
};

const StyledRatingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export default LaptopQuestionnaireList;
