import React from "react";
import Box from "@mui/material/Box";

const BenchmarkListEmpty = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      py={6}
    >
      <Box
        bgcolor="#F3F5F9"
        borderRadius={10}
        width={40}
        height={40}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#555"
        fontSize={20}
      >
        <i className="ri-flag-2-line" />
      </Box>
      目標がありません
    </Box>
  );
};

export default BenchmarkListEmpty;
