import React, { useState } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DATE_PICK_OPTION } from "../../../lib/general";
import FormLabel from "../../../components/FormInputs/FormLabel";

type Props = {
  open: boolean;
  defaultBirthday: string | null;
  disabled: boolean;
  onClose: () => void;
  onSubmit: (birthday: string) => void;
};

const BirthdayUpdateModal = ({
  open,
  defaultBirthday,
  disabled,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  const [birthday, setBirthday] = useState<string | null>(defaultBirthday);

  const submit = () => {
    if (birthday) {
      onSubmit(birthday);
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm">
      <StyledDialogTitle>
        誕生日変更
        <i onClick={onClose} className="ri-close-line" />
      </StyledDialogTitle>
      <DialogContent>
        <FormLabel label="現在の誕生日" />
        <Box fontSize={18} ml={1} mb={2}>
          {defaultBirthday ? moment(defaultBirthday).format("YYYY/MM/DD") : "設定されていません"}
        </Box>

        <FormLabel label="変更後の誕生日" />
        <Flatpickr
          value={birthday}
          options={DATE_PICK_OPTION}
          disabled={disabled}
          onChange={(dates: Date[]) => {
            const newValue = dates[0];
            setBirthday(newValue ? moment(newValue).format("YYYY/MM/DD") : null)
          }}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          color="cancel"
          disabled={disabled}
          onClick={onClose}
        >
          キャンセル
        </Button>

        <Button
          fullWidth
          variant="contained"
          color="submit"
          disabled={!birthday || disabled}
          onClick={submit}
        >
          誕生日を更新
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  "& > i": {
    cursor: "pointer"
  }
});

export default BirthdayUpdateModal;
