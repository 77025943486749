import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { Grid, Row, Col, FormGroup, Form } from "react-bootstrap";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import NoticeTemplateEntityContainer from "../../containers/entities/NoticeTemplateEntityContainer";
import { Card } from "../../components/Card/Card";
import FormInputs from "../../components/FormInputs/FormInputs";
import Checkbox from "../../components/CustomCheckbox/CustomCheckbox";
import FormLabel from "../../components/FormInputs/FormLabel";
import CustomFormGroup from "../../components/FormInputs/FormGroup";
import ImageTrimmingInput from "../../components/ImageInput/ImageTrimmingInput";
import { useNotification } from "../../providers/NotificationProvider";
import { useMobileFlag } from "../../lib/hooks";
import NoticeTemplateButtonGroup from "./parts/NoticeTemplateButtonGroup";

const validateUrl = (value) => {
  const regExp = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
  if (value && value.match(regExp)) {
    return true;
  };
  return "URLを入力してください";
};

const validatePhoneNumber = (value) => {
  const regExp = /^[0-9]{10,11}$/;
  if (value && value.match(regExp)) {
    return true;
  };
  return "電話番号を入力してください";
};

const NoticeTemplateFormView = () => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobileFlag();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [template] = useState(location.state?.template);
  const editMode = !!location.state?.template;
  const defaultActionType = template?.button_url ?
    "open_url" :
    template?.button_phone_number ?
    "telephone" :
    "";
  const [selectedActionType, setSelectedActionType] = useState(defaultActionType);
  const isOpenUrl = selectedActionType === "open_url";

  const noticeTemplateEntityContainer = NoticeTemplateEntityContainer.useContainer();

  const {
    createNoticeTemplate,
    updateNoticeTemplate,
    deleteNoticeTemplate,
  } = noticeTemplateEntityContainer.logic;


  const defaultValues = generateDefaultValues(template);
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    watch,
    setValue,
    formState,
    reset,
    control,
  } = useForm({ defaultValues });

  const { isDirty } = formState;
  const watchImages = watch("template.images", []);

  const onClickCreateButton = async (data) => {
    await createNoticeTemplate(data.template)
      .then((newNoticeTemplate) => {
        showSuccessNotification("テンプレートを作成しました。");
        reset({ template: newNoticeTemplate });
        history.goBack();
      })
      .catch((error) => {
        showErrorNotification(error.message);
      });
  };

  const onClickUpdateButton = async (data) => {
    await updateNoticeTemplate(template.id, data.template)
      .then((newNoticeTemplate) => {
        showSuccessNotification("編集を保存しました。");
        reset({ template: newNoticeTemplate });
        history.goBack();
      })
      .catch((error) => {
        showErrorNotification(error.message);
      });
  };

  const onClickDeleteButton = async () => {
    if (!window.confirm("このテンプレートを削除しますか？")) {
      return;
    }
    await deleteNoticeTemplate(template.id)
      .then(() => {
        showSuccessNotification("テンプレートを削除しました。");
        reset();
        history.goBack();
      })
      .catch((error) => {
        showErrorNotification(error.message);
      });
  };

  const createImage = async (img) => {
    const newImages = [...watchImages, img];
    setValue("template.images", newImages, {
      shouldDirty: true,
    });
  };

  const updateImage = async (newImage, id) => {
    const newImages = [...watchImages];
    newImages.splice(id, 1, newImage);

    setValue("template.images", newImages, {
      shouldDirty: true,
    });
  };

  const deleteImage = async (index) => {
    if (!window.confirm("この画像を削除しますか？")) {
      return;
    }

    const newImages = [...watchImages];
    newImages.splice(index, 1);
    setValue("template.images", newImages, {
      shouldDirty: true,
    });
  };

  const onSubmit = (data) => {
    if (editMode) {
      onClickUpdateButton(data);
    } else {
      onClickCreateButton(data);
    }
  };

  const onError = () => showErrorNotification("値が正しく入力されていません。");

  // custom register
  useEffect(() => {
    register({ name: "template.images" });

    return () => {
      unregister(["template.images"]);
    };
  }, [register, unregister]);

  // 編集ページなのにテンプレートのオブジェクトが渡ってきてないときは、前の画面に飛ばす。
  if (editMode && !location.state) {
    history.goBack();
  }

  return (
    <Grid fluid className="content">
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row>
          <Col md={10}>
            <Card
              title={editMode ? "テンプレート編集" : "テンプレート作成"}
              content={
                <>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <StyledCheckBoxContainer>
                          <Checkbox
                            number={1}
                            inline
                            name="template.do_push"
                            inputRef={register}
                            label="プッシュ通知で配信"
                            ncol="col-md-3"
                          />
                        </StyledCheckBoxContainer>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <FormInputs
                        properties={[
                          {
                            name: "template.name",
                            label: "テンプレート名",
                            ncol: "col-md-9",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "テンプレート名を入力してください。",
                            }),
                            validationMessage: errors?.template?.title?.message,
                          },
                          {
                            name: "template.title",
                            label: "タイトル",
                            ncol: "col-md-9",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "タイトルを入力してください。",
                            }),
                            validationMessage: errors?.template?.title?.message,
                          },
                          {
                            name: "template.body",
                            label: "本文",
                            ncol: "col-md-12",
                            rows: "6",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "本文を入力してください。",
                            }),
                            validationMessage: errors?.template?.body?.message,
                          },
                        ]}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <CustomFormGroup>
                        <FormLabel label="画像" attachment="任意" />
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {watchImages.map((image, i) => (
                            <ImageTrimmingInput
                              doTrimming={false}
                              key={image}
                              id={i}
                              canDelete
                              doCompress
                              doDisplayImage
                              width={200}
                              height={130}
                              altMessage="画像"
                              image={image}
                              updateImage={updateImage}
                              deleteImage={deleteImage}
                            />
                          ))}
                          {watchImages.flat().length !== 3 && (
                            <ImageTrimmingInput
                              doCompress
                              doTrimming={false}
                              blank
                              multiple
                              width={200}
                              height={130}
                              id={undefined}
                              doDisplayImage={false}
                              updateImage={createImage}
                            />
                          )}
                        </div>
                      </CustomFormGroup>
                    </Col>
                  </Row>
                </>
              }
            />

            <Card
              title="ボタン設置機能"
              category="お知らせページの下部に指定したURLに遷移させるボタンを設置することができます。"
              content={
                <Box>
                  <Box mb={1}>
                    <FormLabel label="ボタン押下時のアクション" />
                    <RadioGroup
                      row
                      value={selectedActionType}
                      onChange={(e) => {
                        setSelectedActionType(e.target.value);
                        setValue("template.button_label", "");
                        setValue("template.button_url", "");
                        setValue("template.button_phone_number", "");
                      }}
                    >
                      <FormControlLabel value="" control={<Radio />} label="設定しない" />
                      <FormControlLabel value="open_url" control={<Radio />} label="URLを開く" />
                      <FormControlLabel value="telephone" control={<Radio />} label="電話をかける" />
                    </RadioGroup>
                  </Box>

                  {Boolean(selectedActionType) && (
                    <>
                      <Box mb={2}>
                        <FormLabel label="ボタンのラベル" attachment="任意" />
                        <Controller
                          control={control}
                          name="template.button_label"
                          render={({ onChange, value }) => (
                            <OutlinedInput
                              placeholder={isOpenUrl ? "詳細を確認する" : "電話する"}
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              sx={{ maxWidth: isMobile ? null : 250 }}
                              fullWidth
                            />
                          )}
                        />
                      </Box>

                      <Box mb={2}>
                        <FormLabel label={isOpenUrl ? "ボタン押下時の遷移先URL" : "ボタン押下時にかける電話番号"} />
                        <Controller
                          control={control}
                          name={isOpenUrl ? "template.button_url" : "template.button_phone_number"}
                          rules={{ validate: isOpenUrl ? validateUrl : validatePhoneNumber }}
                          render={({ onChange, value }) => (
                            <OutlinedInput
                              type={isOpenUrl ? "text" : "tel"}
                              placeholder={isOpenUrl ? "https://example.com" : "09012345678"}
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              sx={{ maxWidth: 600 }}
                              fullWidth
                            />
                          )}
                        />

                        {errors?.template?.button_url?.message && (
                          <FormHelperText error>{errors?.template?.button_url?.message}</FormHelperText>
                        )}
                        {errors?.template?.button_phone_number?.message && (
                          <FormHelperText error>{errors?.template?.button_phone_number?.message}</FormHelperText>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              }
            />

            <NoticeTemplateButtonGroup
              editMode={editMode}
              disabled={!isDirty}
              onClickDeleteButton={onClickDeleteButton}
              onClickBackButton={history.goBack}
            />
          </Col>
        </Row>
      </Form>
      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </Grid>
  );
};

const generateDefaultValues = (template) => ({
  template: {
    id: template?.id || null,
    name: template?.name || "",
    title: template?.title || "",
    body: template?.body || "",
    do_push: template?.do_push ?? true,
    images: template?.image_urls ?? [],
    button_label: template?.button_label || "",
    button_url: template?.button_url || "",
    button_phone_number: template?.button_phone_number ?? "",
  },
});

const StyledCheckBoxContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#f0f1f4",
  padding: theme.spacing(1),
}));

export default NoticeTemplateFormView;
