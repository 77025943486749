import React, { useState } from "react";
import styled from "@emotion/styled";
import { CaretLeft, CaretRight, Star } from "phosphor-react";
import { QuestionnaireSectionType } from "../../../types/questionnaire";
import { QuestionnaireFormType, QuestionnaireSectionFormType } from "../type";
import { QuestionnaireSectionCount } from "../defaultValues";
import Checkmark from "../../../assets/img/checkmark.png";
import MobileFrame, { FRAME_WIDTH } from "../../../components/MobileFrame";

type Props = {
  formValues: QuestionnaireFormType;
  storeIconUrl: string | null;
};

const QuestionnairePreview = ({
  formValues,
  storeIconUrl
}: Props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const openedSections: QuestionnaireSectionFormType[] = [...Array(QuestionnaireSectionCount)]
    .map((_, i) => formValues[`section${i + 1}`])
    .filter((section) => section?.enabled);
  const firstPage = 0;
  const lastPage = openedSections.length + 1;
  const currentSection = openedSections[currentPage - 1];
  const pressAsSubmit = ["single_select"].includes(currentSection?.section_type);
  const moveToPrev = () => setCurrentPage(Math.max(firstPage, currentPage - 1));
  const moveToNext = () => setCurrentPage(Math.min(lastPage, currentPage + 1));

  return (
    <StyledContainer>
      <MobileFrame>
        {currentPage === firstPage && (
          <StyledFirstAndLastContainer>
            <StyledQuestionnaireTitle>
              {formValues.title}
            </StyledQuestionnaireTitle>

            <StyledQuestionnaireDescription>
              {formValues.description}
            </StyledQuestionnaireDescription>

            {storeIconUrl && (
              <StyledStoreIcon src={storeIconUrl} alt="アンケート配信元店舗" />
            )}

            <StyledSubmitButton onClick={moveToNext}>
              はじめる
            </StyledSubmitButton>

            <StyledCloseButton>
              閉じる
            </StyledCloseButton>
          </StyledFirstAndLastContainer>
        )}

        {currentPage === lastPage && (
          <StyledFirstAndLastContainer>
            <StyledCheckmark src={Checkmark} alt="完了" />

            <StyledPrimaryNote>
              回答が完了しました
            </StyledPrimaryNote>

            <StyledQuestionnaireTitle>
              ご協力ありがとうございました
            </StyledQuestionnaireTitle>

            <StyledQuestionnaireDescription>
              {formValues.thanks_message}
            </StyledQuestionnaireDescription>

            {formValues.google_map_review_url && (
              <GoogleMapReviewContainer>
                Google Mapのレビュー投稿へご協力お願いします
                <a href={formValues.google_map_review_url} target="_blank" rel="noopener noreferrer">
                  <GoogleMapReviewButton>
                    GoogleMapを開く
                  </GoogleMapReviewButton>
                </a>
              </GoogleMapReviewContainer>
            )}

            <StyledSubmitButton>
              閉じる
            </StyledSubmitButton>
          </StyledFirstAndLastContainer>
        )}

        {firstPage < currentPage && currentPage < lastPage && currentSection && (
          <StyledContentContainer>
            <StyledBackContainer onClick={moveToPrev}>
              <CaretLeft size={20} /> 戻る
            </StyledBackContainer>

            <StyledSectionTitle>{currentSection.title}</StyledSectionTitle>

            {!isSingle(currentSection.section_type) && (
              <StyledSectionNote>複数選択できます</StyledSectionNote>
            )}

            <div style={{ flex: 1 }}>
              <StyledSectionItemContainer sectionType={currentSection.section_type}>
                {currentSection.items?.filter((item) => item.status === "open").map((item, index) => (
                  <div
                    key={index + item.label}
                    onClick={pressAsSubmit ? moveToNext : undefined}
                  >
                    {["multi_tag_select", "single_tag_select"].includes(currentSection.section_type) && (
                      <StyledTagContainer>{item.label}</StyledTagContainer>
                    )}

                    {["single_select", "multi_select"].includes(currentSection.section_type) && (
                      <StyledNormalSelectContainer>{item.label}</StyledNormalSelectContainer>
                    )}

                    {currentSection.section_type === "rating" && (
                      <StyledRatingContainer>
                        <Star size={32} />
                        <StyledRatingLabel width={FRAME_WIDTH / (currentSection.items.length + 2)}>
                          {item.label}
                        </StyledRatingLabel>
                      </StyledRatingContainer>
                    )}

                    {currentSection.section_type === "single_line_text" && (
                      <input type="text" style={{ width: "100%" }} />
                    )}

                    {currentSection.section_type === "multi_line_text" && (
                      <textarea rows={10} style={{ width: "100%" }} />
                    )}

                  </div>
                ))}
              </StyledSectionItemContainer>
            </div>

            {!pressAsSubmit && (
              <StyledSubmitButton onClick={moveToNext}>
                次へ
              </StyledSubmitButton>
            )}

            {!currentSection.required && (
              <StyledSkipButton onClick={moveToNext}>
                回答をスキップ
              </StyledSkipButton>
            )}
          </StyledContentContainer>
        )}
      </MobileFrame>

      <StyledPreviewFooter>
        <CaretLeft
          size={18}
          style={{ cursor: "pointer" }}
          onClick={moveToPrev}
        />

        <StyledPager>{currentPage + 1}/{openedSections.length + 2}</StyledPager>

        <CaretRight
          size={18}
          style={{ cursor: "pointer" }}
          onClick={moveToNext}
        />
      </StyledPreviewFooter>
    </StyledContainer>
  );
};

const isSingle = (sectionType: QuestionnaireSectionType) =>
  ["single_select", "single_tag_select", "rating", "multi_line_text", "single_line_text"].includes(sectionType);

const StyledContainer = styled("div")({
  position: "sticky",
  top: 16
});

const StyledFirstAndLastContainer = styled("div")({
  height: "100%",
  backgroundColor: "white",
  padding: 16,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
});

const StyledCheckmark = styled("img")({
  marginBottom: 16
});

const StyledPrimaryNote = styled("div")({
  fontSize: 16,
  color: "#ED706B",
  fontWeight: 700,
  marginBottom: 16
});
const StyledQuestionnaireTitle = styled("div")({
  color: "#212121",
  fontSize: 19,
  fontWeight: 700,
  textAlign: "center",
  marginBottom: 16
});
const StyledQuestionnaireDescription = styled("div")({
  color: "#212121",
  fontSize: 14,
  marginBottom: 16
});

const GoogleMapReviewContainer = styled("div")({
  backgroundColor: "#F4F4F4",
  fontSize: 16,
  fontWeight: 700,
  padding: 8,
  marginBottom: 16
});
const GoogleMapReviewButton = styled("div")({
  cursor: "pointer",
  backgroundColor: "#ED706B",
  color: "white",
  fontSize: 15,
  fontWeight: 700,
  margin: 8,
  padding: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 100
});

const StyledStoreIcon = styled("img")({
  width: FRAME_WIDTH / 3,
  height: FRAME_WIDTH / 3,
  borderRadius: FRAME_WIDTH / 3,
  marginBottom: 24
});

const StyledSubmitButton = styled("div")({
  cursor: "pointer",
  backgroundColor: "#212121",
  color: "white",
  padding: 12,
  borderRadius: 100,
  fontWeight: 700,
  width: "100%",
  textAlign: "center",
  marginBottom: 16
});

const StyledContentContainer = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  backgroundColor: "white",
  padding: "16px 8px"
});
const StyledBackContainer = styled("div")({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  "& > svg": { marginTop: -2 }
});
const StyledSectionTitle = styled("div")({
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 8
});
const StyledSectionNote = styled("div")({
  fontSize: 12
});

const StyledSectionItemContainer = styled("div")<{ sectionType: QuestionnaireSectionType; }>(({ sectionType }) => ({
  marginTop: 16,
  ...(["single_tag_select", "multi_tag_select"].includes(sectionType) ? {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  } : ["rating"].includes(sectionType) ? {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  } : {})
}));
const StyledTagContainer = styled("div")({
  cursor: "pointer",
  backgroundColor: "white",
  border: "solid 2px #ccc",
  borderRadius: 100,
  color: "#333",
  fontSize: 13,
  padding: "8px 16px",
  margin: "0 8px 8px 0"
});
const StyledNormalSelectContainer = styled("div")({
  cursor: "pointer",
  backgroundColor: "white",
  border: "solid 2px #ccc",
  borderRadius: 4,
  color: "#333",
  fontSize: 13,
  padding: "8px 16px",
  margin: "0 8px 8px 0"
});

const StyledRatingContainer = styled("div")({
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 4px"
});
const StyledRatingLabel = styled("div")<{ width: number }>(({ width }) => ({
  width,
  textAlign: "center",
  fontSize: 10
}));

const StyledCloseButton = styled("div")({
  cursor: "pointer",
  color: "#212121",
  padding: 12,
  fontWeight: 700
});

const StyledSkipButton = styled("div")({
  cursor: "pointer",
  fontSize: 13,
  color: "#333",
  fontWeight: 700,
  textAlign: "center"
});

const StyledPreviewFooter = styled("div")({
  backgroundColor: "#EAEAEE",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 8,
  marginTop: 8
});
const StyledPager = styled("div")({
  color: "#777",
  fontSize: 14
});

export default QuestionnairePreview;
