/* eslint @typescript-eslint/camelcase: 0 */
/* eslint camelcase: 0 */
import apiClient from "../apiClient";
import { StoreStaffInterface } from "../../../containers/entities/StoreStaffEntityContainer";

export const giveAuthToStaff = async ({
  storeId,
  staffId,
}: {
  storeId: number;
  staffId: number;
}): Promise<StoreStaffInterface> => {
  const GIVE_AUTH_TO_STAFF = `/staff/owner/staffs/${staffId}/store_staffs`;

  const params = {
    store_staff: {
      store_id: storeId,
      role: "admin",
    },
  };

  const response = await apiClient({
    method: "POST",
    params,
    uri: GIVE_AUTH_TO_STAFF,
  });
  return response.data;
};

export const removeAuthFromStaff = async ({
  storeIds,
  staffId,
}: {
  storeIds: Array<number>;
  staffId: number;
}) => {
  const QUERY_PARAMS = "delete_store_ids[]=" + storeIds.join("&delete_store_ids[]=");
  const REMOVE_AUTH_FROM_STAFF = `/staff/owner/staffs/${staffId}/store_staffs?` + QUERY_PARAMS;

  const response = await apiClient({
    method: "DELETE",
    uri: REMOVE_AUTH_FROM_STAFF,
  });
  return response.data;
};
