import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { StoreBenchmarkIndicatorLabels } from "../../../constants/storeBenchmark";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { QueryParams } from "../type";

type Props = {
  queryParams: QueryParams;
};

const BenchmarkFilterConditionLabel = ({
  queryParams
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext();
  const storeNameMap = currentStore.child_stores
    .reduce((result, { id, name }) => ({ ...result, [id]: name }), {});

  return (
    <Box display="flex" flexWrap="wrap" gap={1} alignItems="center" fontWeight={700}>
      絞り込み条件：
      {isNoCondition(queryParams) && "なし"}

      {queryParams.indicator && (
        <StyledConditionChip>{StoreBenchmarkIndicatorLabels[queryParams.indicator]}</StyledConditionChip>
      )}

      {(queryParams.dateFrom || queryParams.dateTo) && (
        <StyledConditionChip>
          {queryParams.type === "monthly" ? queryParams.dateFrom?.substring(0, 7) : queryParams.dateFrom}
          <Box component="span" mx={0.5}>~</Box>
          {queryParams.type === "monthly" ? queryParams.dateTo?.substring(0, 7) : queryParams.dateTo}
        </StyledConditionChip>
      )}

      {queryParams.storeIds.map((storeId) => (
        <StyledConditionChip key={storeId}>
          {storeNameMap[storeId]}
        </StyledConditionChip>
      ))}
    </Box>
  );
};

export const isNoCondition = (queryParams: QueryParams) => (
  queryParams.indicator === null &&
    queryParams.dateFrom === null &&
    queryParams.dateTo === null &&
    queryParams.storeIds.length === 0
);

const StyledConditionChip = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  backgroundColor: "#eee",
  fontSize: 14,
  borderRadius: 4
}));

export default BenchmarkFilterConditionLabel;
