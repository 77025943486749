import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  description: string;
};

const AnalyticsCardTooltip = ({ description }: Props): JSX.Element => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id="tooltip">
        <div style={{ textAlign: "left" }}>{description}</div>
      </Tooltip>
    }
  >
    <div style={{ display: "inline-block" }}>
      <i className="fas fa-question-circle" style={{ color: "#B3B3B3", fontSize: 16 }} />
    </div>
  </OverlayTrigger>
);

export default AnalyticsCardTooltip;
