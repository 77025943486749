import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useGetApiCall } from "../../../lib/hooks";
import { StoreBenchmarksResponse } from "../../../types/storeBenchmark";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import BenchmarkProgressCard from "../../Benchmark/parts/BenchmarkProgressCard";
import HomeSection from "./HomeSection";

const BenchmarkProgressList = (): JSX.Element | null => {
  const history = useHistory();
  const { currentStore } = useLoginContext();
  const today = moment().format("YYYY-MM-DD");
  const params = `type[]=monthly&type[]=temporary&date=${today}&status=active`;
  const benchmarkApi = useGetApiCall<StoreBenchmarksResponse>(`/store_benchmarks?${params}`);
  const benchmarks = benchmarkApi.response?.store_benchmarks || [];

  return benchmarks.length > 0  ? (
    <HomeSection
      title="目標"
      onMoveToDetail={() => history.push("/admin/benchmark")}
    >
      <StyledScrollContainer>
        {benchmarks.map(({ store, store_benchmark, children }) => (
          <BenchmarkProgressCard
            key={store_benchmark.id}
            storeName={currentStore.store_type !== "child" ? store.name : null}
            benchmark={store_benchmark}
            dailyBenchmarks={children.map(({ store_benchmark }) => store_benchmark)}
          />
        ))}
      </StyledScrollContainer>
    </HomeSection>
  ) : null;
};

const StyledScrollContainer = styled("div")(({ theme }) => ({
  overflowX: "scroll",
  display: "flex",
  gap: theme.spacing(1),
  ...theme.mixins.hiddenScrollbar
}));

export default BenchmarkProgressList;
