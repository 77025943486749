import React from "react";
import styled from "@emotion/styled";

type Props = {
  changedProperties: string[];
};

const ModalContentFormChangedList = ({
  changedProperties
}: Props): JSX.Element => (
  <div style={{ marginTop: 16 }}>
    <StyledSection>更新内容</StyledSection>
    <div>{changedProperties.join("、")}</div>
  </div>
);

const StyledSection = styled("div")({
  fontWeight: "bold",
  marginBottom: 4,
  fontSize: 14,
});

export default ModalContentFormChangedList;
