/* eslint-disable @typescript-eslint/camelcase */
import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

type ParamsProps = {
  params: {
    active: boolean;
  };
};

export const getOrderFeature = async () => {
  console.log("GET");
};

export const updateBannerFeature = async ({ params }: ParamsProps) => {
  const UPDATE_BANNER_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/banner_features`;

  const requestParams = {
    banner_feature: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_BANNER_FEATURE,
    params: requestParams,
  });
  return response.data;
};
