import React from "react";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router";
import { Grid, Row, Col, Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { combineContainer, open } from "../lib/general";
import { useOnceEffect, useApi } from "../lib/hooks";
import TenantEntityContainer from "../containers/entities/TenantEntityContainer";
import ConfigListViewContainer from "../containers/views/ConfigListViewContainer";
import OwnerEntityContainer from "../containers/entities/OwnerEntityContainer";
import { useLoginContext } from "../providers/LoginContextProvider";

const ConfigListViewWrapper = (props) => (
  <ConfigListViewContainer.Provider>
    <ConfigListView {...props} />
  </ConfigListViewContainer.Provider>
);

export default withRouter(ConfigListViewWrapper);

const ConfigListView = (props) => {
  const container = combineContainer([
    TenantEntityContainer.useContainer(),
    ConfigListViewContainer.useContainer(),
    OwnerEntityContainer.useContainer(),
  ]);
  const { state, logic, setViewState } = container;
  const applyUrlApi = useApi("/staff");
  const { currentStaff } = useLoginContext();

  useOnceEffect(() => {
    logic.getOwner();
  });

  const reviewedBrands =
    !state.tenant || !Object.keys(state.tenant).length
      ? []
      : state.tenant.reviewed_brands;
  const goTo = {
    bankAccount: () =>
      props.history.push("/admin/config/bank_account", state.tenant),
  };

  const handleClickApplyPayjp = async () => {
    const params = { return_to: "https://app.toypo.me/admin/owner" };
    const result = await applyUrlApi.api.post("/owner/tenants/apply", params);

    open(result.data.url);
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={10} sm={12}>
            {(currentStaff.is_toypo_member || state.tenant) && (
              <Box className="card card-feature" display="flex">
                <Box className="flex-icon" bgcolor="#E66464">
                  <i className="fas fa-university" />
                </Box>

                <Box display="flex" justifyContent="space-between" width="100%">
                  <div className="flex-content">
                    <div className="flex-title">振込口座</div>
                    <MediaQuery query="(min-width: 700px)">
                      <p>アプリで発行したチケットやサブスクの売上が振り込まれます。</p>
                    </MediaQuery>
                    <div className="footer" />
                  </div>
                  <div className="select-content">
                    <Button
                      variant="contained"
                      color={state.tenant ? "submit" : "toypoAdmin"}
                      onClick={goTo.bankAccount}
                    >
                      <b>{state.tenant ? "編集する" : "登録する"}</b>
                    </Button>
                  </div>
                </Box>
              </Box>
            )}

            <Box className="card card-feature" display="flex">
              <Box className="flex-icon" bgcolor="#009FFF">
                <i className="far fa-credit-card" />
              </Box>

              <Box display="flex" justifyContent="space-between" width="100%">
                <div className="flex-content">
                  <div className="flex-title">クレジット利用申請</div>
                    <MediaQuery query="(min-width: 700px)">
                      <p>クレジット決済をアプリに導入するための申請です。</p>
                    </MediaQuery>
                  <div className="footer" />
                </div>
                <Box className="select-content" display="flex" gap={1}>
                  {currentStaff.is_toypo_member && state.tenant && (
                    <Button
                      variant="contained"
                      color="toypoAdmin"
                      sx={{ whiteSpace: "nowrap" }}
                      disabled={applyUrlApi.loading}
                      onClick={handleClickApplyPayjp}
                    >
                      PayJP申請
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="submit"
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={() => {
                      if (state.tenant) {
                        setViewState.show(true)
                      } else {
                        window.open("https://forms.gle/CdsS29XG7M2mBZ777", "_blank");
                      }
                    }}
                  >
                    {state.tenant ? "審査状況の確認" : "申請する"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Col>
        </Row>
      </Grid>
      {state.tenant ? (
        <StatusConfirmModal
          logic={logic}
          show={state.show}
          onHide={() => setViewState.show(false)}
          reviewedBrands={reviewedBrands}
        />
      ) : null}
    </div>
  );
};

const StatusConfirmModal = (props) => {
  const { reviewedBrands, logic, show, onHide } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="small"
      aria-labelledby="contained-modal-title-medium"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm" componentClass="h6">
          クレジットカード審査状況
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reviewedBrands.map((value) => (
          <div
            style={{ display: "flex", padding: 5 }}
            key={`status-${value.brand}`}
          >
            <div style={{ width: 150 }}>{value.brand}:</div>
            <div className="cardStatus" style={{ marginLeft: 30 }}>
              {logic.convertCardStatusJp(value.status)}
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};
