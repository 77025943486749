import React, { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useBooleanState } from "../../../lib/hooks";
import { Card } from "../../../components/Card/Card";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { MenuInterface } from "../../../containers/entities/MenuEntityContainer";
import { Alert, FormChangedAlertModal } from "../../../components/Modal/FormChangedAlertModal";
import { MenuStatusLabel } from "../../../constants/menu";
import { MenuStatus } from "../../../types/menu";

const modalTitle = (status: MenuStatus) => {
  switch (status) {
    case "close": return "メニューの公開を停止";
    case "open": return "メニューを公開";
    case "sold": return "メニューを売り切れに変更";
    default: return "";
  }
};

const modalSubmitButtonLabel = (status: MenuStatus) => {
  switch (status) {
    case "close": return "公開を停止する";
    case "open": return "公開する";
    case "sold": return "売り切れにする";
    default: return "";
  }
};

const modalBody = (status: MenuStatus) => {
  switch (status) {
    case "close": return "メニューの公開を停止してよろしいですか？";
    case "open": return "メニューを公開してよろしいですか？";
    case "sold": return "メニューを売り切れにしてよろしいですか？";
    default: return "";
  }
};

type Props = {
  disabled: boolean;
  editMode: boolean;
  onChangeStatus: (status: MenuStatus) => void;
  menuStatus: MenuStatus;
};

const optionArray = (status?: MenuStatus): MenuStatus[] => {
  switch (status) {
    case "draft":
    case undefined:
      return ["draft", "open"];
    default:
      return ["open", "close", "sold"];
  }
};

const MenuFormPublishSettingCard: FC<Props> = ({
  disabled,
  editMode,
  onChangeStatus,
  menuStatus,
}) => {
  const { register, watch } = useFormContext<{ menu: MenuInterface & { image: string } }>();
  const watchStatus = watch("menu.status") as MenuStatus;

  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const [newStatus, setNewStatus] = useState<MenuStatus>(menuStatus);
  const openModalFor = (s: MenuStatus) => {
    setNewStatus(s);
    openModal();
  };

  const isChanged: boolean = editMode && watchStatus !== menuStatus;

  const warnings: string[] = [];
  if (newStatus === "close") {
    warnings.push("非公開に設定すると、アプリのメニュー一覧に表示されなくなります。");
  }
  if (newStatus === "sold") {
    warnings.push("売り切れに設定すると、アプリのメニュー一覧に表示されますが、注文ができなくなります。");
  }

  return (
    <>
      <Card
        title="公開設定"
        content={
          <>
            <Row>
              <Col sm={12} style={{display: "flex", alignItems: "center"}}>
                <SelectorForm
                  data-cy="menu-form-publish-setting-card-menu-status"
                  name="menu.status"
                  inputRef={register}
                  width={180}
                  style={{ marginBottom: 0 }}
                  options={optionArray(menuStatus).map((currentStatus) => ({
                    label: MenuStatusLabel[currentStatus],
                    value: currentStatus,
                  }))}
                  showChangeIndicator={isChanged}
                />
              </Col>
            </Row>

            {editMode && (
              <Row style={{ margin: "8px 0 0 0", display: "flex", justifyContent: "flex-end" }}>
                <Col>
                  <CustomButton
                    disabled={disabled || !isChanged}
                    bsStyle="info"
                    block
                    fill
                    simple
                    pullRight
                    style={{ fontWeight: "bold" }}
                    onClick={() => openModalFor(watchStatus)}
                  >
                    変更する
                  </CustomButton>
                </Col>
              </Row>
            )}
          </>
        }
      />

      <FormChangedAlertModal
        title={modalTitle(newStatus)}
        show={isModalOpen}
        submitButtonLabel={modalSubmitButtonLabel(newStatus)}
        onSubmit={() => onChangeStatus(newStatus)}
        onCancel={closeModal}
      >
        {modalBody(newStatus)}

        {warnings.length > 0 && (
          <Alert severity="warning">
            <ul>{warnings.map((warning) => <li>{warning}</li>)}</ul>
          </Alert>
        )}
      </FormChangedAlertModal>
    </>
  );
};

export default MenuFormPublishSettingCard;
