import React from "react";
import styled from "@emotion/styled";
import { Table } from "react-bootstrap";
import { CouponContentBlock } from "../../../types/api/coupon.d";
import { SortOrderType } from "../../../types/common.d";
import { CouponSortByType, CouponContentResource } from "../../../types/coupon";
import { categoryToJpObj } from "../../../containers/entities/AutoCouponContentEntityContainer";
import { AutoCouponContentStatusToJpObj } from "../../../containers/entities/CouponContentEntityContainer";
import OptionButton from "../../../components/CustomButton/OptionButton";
import ContentMeasurementValueAndDiff from "../../../components/Utils/ContentMeasurementValueAndDiff";
import SortIconTh from "../../../components/Table/SortIconTh";
import StatusLabel from "./StatusLabel";
import ContentSalesEffect from "./ContentSalesEffect";

type Props = {
  autoCouponContents: CouponContentBlock[];
  sortBy: CouponSortByType;
  sortOrder: SortOrderType;
  onClickCouponContent: (id: number) => void;
  onDeleteCouponContent: (id: number) => void;
  onClickCopyButton: (couponContent: CouponContentResource) => void;
  onSort: (sortBy: CouponSortByType, sortOrder: SortOrderType) => void;
  showArrivalCount: boolean;
};

const AutoCouponTable = ({
  autoCouponContents,
  sortBy,
  sortOrder,
  onClickCouponContent,
  onDeleteCouponContent,
  onClickCopyButton,
  onSort,
  showArrivalCount
}: Props): JSX.Element => (
  <Table>
    <thead>
      <tr>
        <th> </th>
        <th>タイトル</th>
        <StyledNoWrapTh>配信</StyledNoWrapTh>
        {showArrivalCount && (<StyledNoWrapTh>到達</StyledNoWrapTh>)}
        <StyledNoWrapSortIconTh
          sortOrder={sortBy === "read_count" ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) => onSort("read_count", newSortOrder)}
        >
          開封
        </StyledNoWrapSortIconTh>
        <StyledNoWrapSortIconTh
          sortOrder={sortBy === "used_count" ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) => onSort("used_count", newSortOrder)}
        >
          利用
        </StyledNoWrapSortIconTh>
        <StyledNoWrapTh>売上効果</StyledNoWrapTh>
        <StyledNoWrapTh>カテゴリー</StyledNoWrapTh>
        <StyledNoWrapTh>状態</StyledNoWrapTh>
        <th> </th>
      </tr>
    </thead>
    <tbody>
      {autoCouponContents.map(
        ({
          auto_publish_condition,
          coupon_content,
          delivered_count,
          arrival_count,
          read_count,
          read_count_diff,
          used_count,
          used_count_diff,
          sales_effect,
        }) => (
          <tr key={coupon_content.id} style={{ cursor: "pointer" }}>
            <td onClick={() => onClickCouponContent(coupon_content.id)}>
              <StyledCouponImage
                alt="クーポン画像"
                src={coupon_content.image_url}
                width={48}
                height={30}
              />
            </td>
            <td onClick={() => onClickCouponContent(coupon_content.id)}>
              <div><b>{coupon_content.benefit}</b></div>
              {coupon_content.memo && (
                <StyledMemo>{coupon_content.memo}</StyledMemo>
              )}
            </td>
            <StyledNoWrapTd onClick={() => onClickCouponContent(coupon_content.id)}>
              {delivered_count}
            </StyledNoWrapTd>
            {showArrivalCount && (
              <StyledNoWrapTd onClick={() => onClickCouponContent(coupon_content.id)}>
                {arrival_count}
              </StyledNoWrapTd>
            )}
            <StyledNoWrapTd onClick={() => onClickCouponContent(coupon_content.id)}>
              <ContentMeasurementValueAndDiff
                value={read_count}
                diff={read_count_diff}
                createdAt={coupon_content.created_at}
              />
            </StyledNoWrapTd>
            <StyledNoWrapTd onClick={() => onClickCouponContent(coupon_content.id)}>
              <ContentMeasurementValueAndDiff
                value={used_count}
                diff={used_count_diff}
                createdAt={coupon_content.created_at}
              />
            </StyledNoWrapTd>
            <StyledNoWrapTdSalesEffect onClick={() => onClickCouponContent(coupon_content.id)}>
              {coupon_content.status === "publish" ? (
                <ContentSalesEffect
                  value={sales_effect}
                />
              ): "---"}
            </StyledNoWrapTdSalesEffect>
            <StyledNoWrapTd onClick={() => onClickCouponContent(coupon_content.id)}>
              {auto_publish_condition && categoryToJpObj[auto_publish_condition.category]}
            </StyledNoWrapTd>
            <StyledNoWrapTd onClick={() => onClickCouponContent(coupon_content.id)}>
              <StatusLabel status={coupon_content.status}>
                {AutoCouponContentStatusToJpObj[coupon_content.status]}
              </StatusLabel>
            </StyledNoWrapTd>
            <td>
              <OptionButton
                options={[{
                  label: "複製",
                  onClick: () => onClickCopyButton(coupon_content),
                }, coupon_content.published_number === 0 && {
                  label: "削除",
                  labelColor: "#FF4A55",
                  onClick: () => onDeleteCouponContent(coupon_content.id),
                }].filter((v) => v)}
              />
            </td>
          </tr>
        )
      )}
    </tbody>
  </Table>
);

const StyledNoWrapTh = styled("th")({ whiteSpace: "nowrap" });
const StyledNoWrapTd = styled("td")({ whiteSpace: "nowrap" });
const StyledNoWrapSortIconTh = styled(SortIconTh)({ whiteSpace: "nowrap" });
const StyledNoWrapTdSalesEffect = styled("td")({ whiteSpace: "nowrap" , backgroundColor: "#EFF8F7" });

const StyledCouponImage = styled("img")({
  borderRadius: 2,
  objectFit: "cover"
});

const StyledMemo = styled("div")({
  fontSize: 12,
  color: "#787878"
});

export default AutoCouponTable;
