export const CategoryIconMap = {
  analytics_report: "🔍",
  good_result: "🎉",
  improvement_plan: "🚨"
} as const;

export const CategoryBgColorMap = {
  analytics_report: "#EFF6F6",
  good_result: "rgba(73,191,163,0.1)",
  improvement_plan: "#FEEBEC"
} as const;

export const CategoryColorMap = {
  analytics_report: "#3274D0",
  good_result: "#1EB159",
  improvement_plan: "#FC6068"
} as const;

export const CategoryLabel = {
  analytics_report: "分析結果",
  good_result: "その調子！",
  improvement_plan: "改善提案"
} as const;
