import apiClient from "../apiClient";

export const getStaffs = async () => {
  const GET_STAFFS = "/staff/owner/staffs";

  const response = await apiClient({ method: "GET", uri: GET_STAFFS });
  return response.data;
};

export const getAllStaffs = async () => {
  const GET_ALL_STAFFS = "/staff/owner/staffs?type=all";

  const response = await apiClient({ method: "GET", uri: GET_ALL_STAFFS });
  return response.data;
};

export const getStaff = async ({ id }) => {
  const GET_STAFF = `/staff/owner/staffs/${id}`;

  const response = await apiClient({ method: "GET", uri: GET_STAFF });
  return response.data;
};

export const createStaff = async ({ staff, storeIds }) => {
  const POST_STAFF = "/staff/owner/staffs";

  const requestParams = {
    staff: staff,
    store_ids: storeIds
  };

  const response = await apiClient({
    method: "POST",
    uri: POST_STAFF,
    params: requestParams,
  });
  return response.data;
};

export const updateStaff = async ({ id, params }) => {
  const PUT_STAFF = `/staff/owner/staffs/${id}`;

  const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://toypo4store-web.vercel.app/";

  // redirectさせたいURL
  const CONFIRM_SUCCESS_URL = `${BASE_URL}admin/login`;
  // error時にredirectさせたいURL
  const ERROR_REDIRECT_URL = `${BASE_URL}admin/account`;

  const requestParams = {
    staff: params,
    confirm_success_url: CONFIRM_SUCCESS_URL,
    error_redirect_url: ERROR_REDIRECT_URL,
  };

  const response = await apiClient({
    method: "PUT",
    uri: PUT_STAFF,
    params: requestParams,
  });
  return response.data;
};

export const deleteStaff = async ({ id }) => {
  const DELETE_STAFF = `/staff/owner/staffs/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_STAFF,
  });
  return response.data;
};
