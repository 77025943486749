import React, { FC, memo } from "react";
import { useMediaQuery } from "react-responsive";
import { AxisTickProps } from "@nivo/axes";
import { BarDatum } from "@nivo/bar";
import moment from "moment";
import { DataUnit } from "../../../containers/entities/AnalyticsEntityContainer";

const BarGraphAxisBottomDateRenderTick: FC<
  AxisTickProps<any> & { unit?: DataUnit; data: BarDatum[] }
> = ({ opacity, textAnchor, textX, textY, value, x, y, unit, data }) => {
  const texts: string[] = [];
  const day = Number(value.split("-")[2]);
  const month = Number(value.split("-")[1]);
  const dataIndex = data.findIndex((d) => d.date === value);

  const minWidth1000px = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  const minWidth400px = useMediaQuery({
    query: "(min-width: 400px)",
  });

  switch (unit) {
    case "monthly":
      if (minWidth400px) {
        texts.push(moment(value).format("M月"));
      } else if (month % 2 === 1) {
        texts.push(moment(value).format("M月"));
      }
      if (month === 1 || dataIndex === 0) {
        texts.push(moment(value).format("YYYY年"));
      }
      break;
    case "weekly":
      if (minWidth400px || data.length <= 6) {
        texts.push(moment(value).format("D日週"));

        if (dataIndex === 0 || day <= 7) {
          texts.push(moment(value).format("M月"));
        }
      } else if (dataIndex % 2 === 0) {
        texts.push(moment(value).format("D日週"));

        if (dataIndex === 0 || day <= 14) {
          texts.push(moment(value).format("M月"));
        }
      }
      break;

    case "daily":
      if (data.length <= 10) {
        texts.push(moment(value).format("D日"));
      } else if (
        day === 1 ||
        day === 5 ||
        day === 10 ||
        day === 15 ||
        day === 20 ||
        day === 25
      ) {
        texts.push(moment(value).format("D日"));
      }
      if (dataIndex === 0 || day === 1) {
        if (texts.length === 0) {
          texts.push(moment(value).format("D日"));
        }
        texts.push(moment(value).format("M月"));
      }
      break;
    default:
      break;
  }

  return (
    <g transform={`translate(${x},${y})`} style={{ opacity }}>
      {texts.map((text, i) => (
        <text
          key={i}
          x={0}
          y={10 + i * 15}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY})`}
          style={{ color: "#555", fontSize: minWidth1000px ? 12 : 10 }}
        >
          {text}
        </text>
      ))}
    </g>
  );
};

export default memo(BarGraphAxisBottomDateRenderTick);
