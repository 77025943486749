import React from "react";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { CurrencyCircleDollar, Storefront, Timer, ClockCounterClockwise } from "phosphor-react";
import { formatRelativeDate } from "../../../lib/general";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import AdminShieldedButton from "../../../components/AdminShieldedButton";
import SectionTitle from "./SectionTitle";

type Props = {
  totalSales: number;
  totalVisitCount: number;
  visitFrequency: number | null;
  lastVisitAt: string | null;
  onClickOpenCheckInModal: () => void;
};

const CustomerSummary = ({
  totalSales,
  totalVisitCount,
  visitFrequency,
  lastVisitAt,
  onClickOpenCheckInModal,
}: Props): JSX.Element => {
  const { isAdminRole, currentStore } = useLoginContext();

  const formatVisitFrequency = (value: number): number => {
    if (value <= 1) {
      return Math.ceil(value);
    }
    return Math.round(value);
  };

  return (
      <div>
        <SectionTitle
          style={{ marginBottom: 12 }}
          title={<b>概要</b>}
          element={
            isAdminRole ? (
              <Box display="flex" flexDirection="column" alignItems="flex-end" gap={0.5}>
                <Tooltip
                  disableHoverListener={currentStore.store_type === "child"}
                  title="現在、グループの管理画面を選択中の為チェックインができません。チェックインさせたい店舗に切り替えてチェックインを行ってください。"
                >
                  <span>
                    <AdminShieldedButton
                      onClick={onClickOpenCheckInModal}
                      disabled={currentStore.store_type !== "child"}
                      iconSize={20}
                    >
                      チェックイン
                    </AdminShieldedButton>
                  </span>
                </Tooltip>
              </Box>
            ) : null
          }
        />

        <StyledContentContainer>
          <SummaryItem
            title="総売上"
            value={totalSales.toLocaleString()}
            icon={<CurrencyCircleDollar size={24} color="#91A2B7" weight="bold" />}
            unit="円"
          />
          <SummaryItem
            title="総来店数"
            value={totalVisitCount.toLocaleString()}
            icon={<Storefront size={24} color="#91A2B7" weight="bold" />}
            unit="回"
          />
          <SummaryItem
            title="来店間隔"
            value={visitFrequency ? formatVisitFrequency(visitFrequency) : "---"}
            icon={<Timer size={24} color="#91A2B7" weight="bold" />}
            unit="日"
          />
          <SummaryItem
            title="最終来店"
            value={lastVisitAt ? formatRelativeDate(lastVisitAt) : "---"}
            icon={<ClockCounterClockwise size={24} color="#91A2B7" weight="bold" />}
          />
        </StyledContentContainer>
      </div>
  );
};

const StyledContentContainer = styled("div")(({ theme }) => ({
  background: "#F5F6F9",
  padding: 16,
  borderRadius: 4,
  display: "grid",
  gap: 10,
  gridTemplateColumns: "auto auto auto auto",
  [theme.breakpoints.down("tablet")]: {
    gridTemplateColumns: "auto auto",
  }
}))

type SummaryItemProps = {
  icon: JSX.Element;
  unit?: string;
  value: number | string;
  title: string;
};

const SummaryItem = ({ icon, value, unit, title }: SummaryItemProps) => (
  <StyledSummaryItemContainer>
    <StyledSummaryItemValueContainer>
      {icon}

      <div style={{ marginLeft: 6 }}>
        <StyledItemValue>{value}</StyledItemValue>
        {unit}
      </div>
    </StyledSummaryItemValueContainer>

    <StyledSummaryItemTitle>{title}</StyledSummaryItemTitle>
  </StyledSummaryItemContainer>
);

const StyledSummaryItemContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})
const StyledSummaryItemValueContainer = styled("div")({
  display: "flex",
  alignItems: "center"
})
const StyledItemValue = styled("span")({
  fontSize: 20,
  fontWeight: "bold",
  marginRight: 2,
})
const StyledSummaryItemTitle = styled("div")({
  marginTop: 4,
  color: "#6e6e6e",
})

export default CustomerSummary;
