import { QuestionnaireResponse } from "../../types/questionnaire";
import { RatingLabels } from "../../constants/questionnaire";
import { QuestionnaireFormType, QuestionnaireSectionItemFormType, QuestionnaireSectionFormsType, QuestionnaireSectionFormType } from "./type";
import { v4 as uuid } from "uuid";

export const convertResponseToForm = (response: QuestionnaireResponse): QuestionnaireFormType => {
  const sections = [...Array(QuestionnaireSectionCount)].reduce((results, _, i) => {
    const defaultSection = DefaultFormValue[`section${i + 1}`] as QuestionnaireSectionFormType;
    const currentSection = response.questionnaire_sections
      .find((v) => v.dashboard_section === defaultSection.dashboard_section);

    return {
      ...results,
      [`section${defaultSection.order}`]: {
        ...defaultSection,
        ...(currentSection ? {
          id: currentSection.id,
          enabled: currentSection.status === "open",
          title: currentSection.title || "",
          required: currentSection.required,
          items: currentSection.section_items.map((item) => ({
            key: String(item.id),
            status: item.status,
            order: item.order,
            label: item.label || "",
            label_value: item.label_value || ""
          }))
        } : {
          enabled: false
        }),
      }
    };
  }, {} as QuestionnaireSectionFormsType);

  return {
    title: response.questionnaire.title || "",
    description: response.questionnaire.description || "",
    thanks_message: response.questionnaire.thanks_message || "",
    google_map_review_url: response.questionnaire.google_map_review_url || "",
    ...sections
  };
};

export const convertFormToRequest = (formValue: QuestionnaireFormType) => ({
  title: formValue.title,
  description: formValue.description,
  thanks_message: formValue.thanks_message,
  google_map_review_url: formValue.google_map_review_url,
  should_select_answer_store: true,
  questionnaire_type: formValue.questionnaire_type,
  sections: [...Array(QuestionnaireSectionCount)].map((_, i) => {
    const defaultSection = DefaultFormValue[`section${i + 1}`] as QuestionnaireSectionFormType;
    const currentSection = formValue[`section${i + 1}`] as QuestionnaireSectionFormType;

    return {
      id: currentSection.id,
      status: currentSection.enabled ? "open" : "close",
      order: defaultSection.order,
      dashboard_section: defaultSection.dashboard_section,
      title: currentSection.title,
      section_type: defaultSection.section_type,
      required: currentSection.required,
      items: currentSection.items.map((item: QuestionnaireSectionItemFormType) => ({
        id: item.unpersisted ? null : Number(item.key),
        status: item.status,
        label: item.label,
        order: item.order,
        label_value: item.label_value
      }))
    };
  })
});

export const generateSectionItem = (
  override?: Partial<QuestionnaireSectionItemFormType>
): QuestionnaireSectionItemFormType => ({
  key: uuid(),
  unpersisted: true,
  status: override?.status || "open",
  order: override?.order || null,
  label: override?.label || "",
  label_value: override?.label_value || ""
});
const generateRatingItems = (): QuestionnaireSectionItemFormType[] => [
  generateSectionItem({ order: 1, label: RatingLabels[5], label_value: "5" }),
  generateSectionItem({ order: 2, label: RatingLabels[4], label_value: "4" }),
  generateSectionItem({ order: 3, label: RatingLabels[3], label_value: "3" }),
  generateSectionItem({ order: 4, label: RatingLabels[2], label_value: "2" }),
  generateSectionItem({ order: 5, label: RatingLabels[1], label_value: "1" })
];

export const DefaultFormValue: QuestionnaireFormType = {
  status: "close",
  title: "顧客満足度調査アンケート",
  description: "いつもご利用いただき誠にありがとうございます。今後、サービスを改善していくための参考にさせて頂きますので、ご感想をお聞かせください。",
  thanks_message: "回答を元に今後のサービス改善の参考にさせていただきます！\n※ 回答に対し、個別でのご返信はできませんのでご了承ください。",
  google_map_review_url: "",
  questionnaire_type: "restaurant_satisfaction",
  section1: {
    enabled: true,
    order: 1,
    title: "今回ご来店のきっかけを教えて下さい",
    section_type: "multi_tag_select",
    dashboard_section: "visit_trigger",
    required: true,
    items: [
      generateSectionItem({ label: "家族・友人・知人の紹介" }),
      generateSectionItem({ label: "グルメサイト" }),
      generateSectionItem({ label: "Googleマップ" }),
      generateSectionItem({ label: "SNS" }),
      generateSectionItem({ label: "お店を気に入っているから" }),
      generateSectionItem({ label: "トイポのクーポン" }),
      generateSectionItem({ label: "トイポのお知らせ" }),
      generateSectionItem({ label: "たまたまお店の前を通りかかった" })
    ]
  },
  section2: {
    enabled: true,
    order: 2,
    section_type: "single_select",
    title: "これまでの来店回数を教えて下さい",
    dashboard_section: "number_of_visit",
    required: true,
    items: [
      generateSectionItem({ order: 1, label: "はじめて" }),
      generateSectionItem({ order: 2, label: "2回目" }),
      generateSectionItem({ order: 3, label: "3回目" }),
      generateSectionItem({ order: 4, label: "4回目" }),
      generateSectionItem({ order: 5, label: "5回以上" })
    ]
  },
  section3: {
    enabled: false,
    order: 3,
    title: "今回ご注文されたメニューを教えてください",
    section_type: "multi_tag_select",
    dashboard_section: "order_items",
    required: true,
    items: [generateSectionItem({ order: 1, label: "その他" })]
  },
  section4: {
    enabled: true,
    order: 4,
    section_type: "rating",
    title: "当店の満足度を教えて下さい",
    dashboard_section: "total_quality",
    required: true,
    items: [
      generateSectionItem({ order: 1, label: RatingLabels[1], label_value: "1" }),
      generateSectionItem({ order: 2, label: RatingLabels[2], label_value: "2" }),
      generateSectionItem({ order: 3, label: RatingLabels[3], label_value: "3" }),
      generateSectionItem({ order: 4, label: RatingLabels[4], label_value: "4" }),
      generateSectionItem({ order: 5, label: RatingLabels[5], label_value: "5" })
    ]
  },
  section5: {
    enabled: true,
    order: 5,
    section_type: "single_select",
    title: "味はいかがでしたか？",
    dashboard_section: "taste_of_food",
    required: true,
    items: generateRatingItems()
  },
  section6: {
    enabled: true,
    order: 6,
    section_type: "single_select",
    title: "量はいかがでしたか？",
    dashboard_section: "amount_of_food",
    required: true,
    items: generateRatingItems()
  },
  section7: {
    enabled: true,
    order: 7,
    section_type: "single_select",
    title: "メニューの品揃えはいかがでしたか？",
    dashboard_section: "variety_of_food",
    required: true,
    items: generateRatingItems()
  },
  section8: {
    enabled: true,
    order: 8,
    section_type: "single_select",
    title: "接客はいかがでしたか？",
    dashboard_section: "customer_service",
    required: true,
    items: generateRatingItems()
  },
  section9: {
    enabled: true,
    order: 9,
    section_type: "single_select",
    title: "提供スピードはいかがでしたか？",
    dashboard_section: "speed_of_delivery",
    required: true,
    items: generateRatingItems()
  },
  section10: {
    enabled: true,
    order: 10,
    section_type: "single_select",
    title: "価格はいかがでしたか？",
    dashboard_section: "price_of_food",
    required: true,
    items: generateRatingItems()
  },
  section11: {
    enabled: true,
    order: 11,
    section_type: "single_select",
    title: "お店の清潔感はいかがでしたか？",
    dashboard_section: "cleanliness_of_store",
    required: true,
    items: generateRatingItems()
  },
  section12: {
    enabled: true,
    order: 12,
    section_type: "single_select",
    title: "お店の雰囲気はいかがでしたか？",
    dashboard_section: "ambience_of_store",
    required: true,
    items: generateRatingItems()
  },
  section13: {
    enabled: true,
    order: 13,
    section_type: "multi_line_text",
    title: "良かった点や改善してほしい点など具体的にお聞かせください",
    dashboard_section: "review",
    required: false,
    placeholder: "こちらに入力してください",
    items: [generateSectionItem({ order: 1 })]
  }
};
export const QuestionnaireSectionCount = 13;
export const generateDefaultQuestionnaireRequestParams = (staffId: number, enabledPushNotification: boolean) => ({
  ...convertFormToRequest(DefaultFormValue),
  enabled_report_notification: true,
  enabled_daily_report: true,
  enabled_weekly_report: true,
  enabled_monthly_report: true,
  report_notification_time: "09:00",
  report_notification_policies: [{
    staff_id: staffId,
    enabled_email: true,
    enabled_push_notification: enabledPushNotification,
    include_child_store_staffs: true
  }],
  enabled_answer_notification: true,
  answer_notification_timing: "everytime",
  answer_notification_policies: [{
    staff_id: staffId,
    enabled_email: true,
    enabled_push_notification: enabledPushNotification,
    include_child_store_staffs: true
  }]
});
