import React from "react";
import Chip from "@mui/material/Chip";
import { useMobileFlag } from "../../../lib/hooks";
import { StoreBenchmarkResource } from "../../../types/storeBenchmark";

type Props = {
  benchmark: StoreBenchmarkResource
};

const BenchmarkStatusChip = ({
  benchmark
}: Props): JSX.Element => {
  const isMobile = useMobileFlag();
  const label = benchmark.status === "paused"
    ? "停止中"
    : benchmark.benchmark_progress === "before"
    ? "開始前"
    : benchmark.benchmark_progress === "ongoing"
    ? "進行中"
    : "完了";
  const color = benchmark.benchmark_progress === "before" || benchmark.status === "paused"
    ? "#747474"
    : benchmark.benchmark_progress === "ongoing"
    ? "#e58634"
    : "#75be6e";
  const backgroundColor =  benchmark.benchmark_progress === "before" || benchmark.status === "paused"
    ? "#f1f1f1"
    : benchmark.benchmark_progress === "ongoing"
    ? "#fcf3eb"
    : "#f1f9f0";

  return (
    <Chip
      variant="status"
      label={label}
      style={{
        color,
        backgroundColor,
        ...(isMobile ? { padding: "2px 4px" } : {})
      }}
    />
  );
};

export default BenchmarkStatusChip;
