import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SuspendView from "../../../views/Suspend/SuspendView";
import AccountChangeView from "../../../views/AccountChange/AccountChangeView";
import NewPasswordView from "../../../views/Password/NewPasswordView";
import { useRenderer } from "../hooks";

const RejectedRoute = (): JSX.Element => {
  const renderer = useRenderer();

  return (
    <Switch>
      <Route exact path="/admin/suspend" render={renderer(SuspendView)} />
      <Route exact path="/admin/account" render={renderer(AccountChangeView)} />
      <Route exact path="/admin/staff/password/new" render={renderer(NewPasswordView)} />
      <Redirect to="/admin/suspend" />
    </Switch>
  );
};

export default RejectedRoute;
