import { AxiosResponse } from "axios";
import { useState } from "react";
import { createContainer } from "unstated-next";
import { connectionApi } from "../../lib/api";

interface Connection {
  id: number;
  is_staff: boolean;
}

interface UpdateParams {
  is_staff: boolean;
}

const useConnectionEntityHook = () => {
  const [connection, setConnection] = useState<Connection>();
  return {
    state: { connection },
    logic: {
      getConnection: async (
        userId: number
      ): Promise<AxiosResponse<Connection>> => {
        const response: AxiosResponse<Connection> =
          await connectionApi.getConnection(userId);
        setConnection(response.data);

        return response;
      },
      updateConnetion: async ({
        userId,
        params,
      }: {
        userId: number;
        params: UpdateParams;
      }): Promise<AxiosResponse<Connection>> => {
        const response: AxiosResponse<Connection> =
          await connectionApi.updateConnection({ userId, params });
        setConnection(response.data);
        return response;
      },
    },
  };
};

export default createContainer(useConnectionEntityHook);
