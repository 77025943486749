/* eslint-disable @typescript-eslint/camelcase */
import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const getOrderFeature = async () => {
  const GET_ORDER_FEATURE = `/staff/stores/${loadViewObj()?.id}/order_features`;

  const response = await apiClient({
    method: "GET",
    uri: GET_ORDER_FEATURE,
  });
  return response.data;
};

export const updateOrderFeature = async ({ params }) => {
  const UPDATE_ORDER_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/order_features`;

  const requestParams = {
    order_feature: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_ORDER_FEATURE,
    params: requestParams,
  });
  return response.data;
};
