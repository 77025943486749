import React from "react";
import MediaQuery from "react-responsive";
import { Table } from "react-bootstrap";

// components
import SmallTableBody from "../../../components/Table/SmallTableBody";

// container
import {
  DiscountContentsType,
  DiscountContentsTypeToJp,
} from "../../../containers/entities/DiscountEntityContainer";

type Props = {
  discountContents;
  discountContentTypeToJp: (
    discountContentsType: DiscountContentsType
  ) => DiscountContentsTypeToJp;
};

const DiscountContentsListTable: React.FC<Props> = ({
  discountContents,
  discountContentTypeToJp,
}) => {
  const thArray = [
    "",
    "タイトル",
    "コンテンツタイプ",
    "割引設定額",
    "利用回数（指定期間内）",
    "割引額（指定期間内）",
  ];

  return (
    <Table striped hover>
      <MediaQuery query="(min-width: 767px)">
        <thead>
          <tr>
            {thArray.map((prop) => (
              <th key={`column-${prop}`}>{prop}</th>
            ))}
          </tr>
        </thead>
      </MediaQuery>
      <tbody>
        {discountContents.map((discountContent) => (
          <React.Fragment
            key={`discount-contents-${discountContent.content_type}-${discountContent.content_id}`}
          >
            {/* PCサイズ */}
            <tr>
              <MediaQuery query="(min-width: 767px)">
                <td style={{ width: 48 }}>
                  {!!discountContent.image_url && (
                    <img
                      key={discountContent.id}
                      alt="画像"
                      src={discountContent.image_url}
                      width={48}
                      height={30}
                      style={{
                        borderRadius: 2,
                        objectFit: "cover",
                      }}
                    />
                  )}
                </td>
                <td style={{ width: "50%" }}>{discountContent.title}</td>
                <td>{discountContentTypeToJp(discountContent.content_type)}</td>
                <td>{discountContent.discount_setting_amount || "---"}</td>
                <td>{`${discountContent.count}回`}</td>
                <td>
                  {discountContent.all_discount_amount
                    ? `${discountContent.all_discount_amount}円`
                    : "---"}
                </td>
              </MediaQuery>
              {/* スマホサイズ */}
              <MediaQuery query="(max-width: 766px)">
                <div />
                <SmallTableBody
                  title={discountContent.title}
                  contents={[
                    `コンテンツタイプ: ${
                      discountContentTypeToJp(discountContent.content_type) ||
                      "---"
                    }`,
                    `割引設定額: ${
                      discountContent.discount_setting_amount || "---"
                    }`,
                    `利用回数（指定期間内）: ${discountContent.count}回`,
                    `割引額（指定期間内）: ${
                      discountContent.all_discount_amount
                        ? `${discountContent.all_discount_amount}円`
                        : "---"
                    }`,
                  ]}
                />
              </MediaQuery>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default DiscountContentsListTable;
