import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

type Props = ComponentPropsWithoutRef<"div"> & {
  mask: boolean;
  hideSpinner?: boolean;
  children: React.ReactNode;
};

const DisablingMask = ({
  mask,
  hideSpinner = false,
  children,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledContainer {...otherProps}>
      {mask && (
        <StyledMask>
          {!hideSpinner && <CircularProgress />}
        </StyledMask>
      )}
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled("div")({
  position: "relative"
});

const StyledMask = styled("div")({
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export default DisablingMask;
