import React, { useState } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import { useApi, useMobileFlag } from "../../lib/hooks";
import { DATE_PICK_OPTION } from "../../lib/general";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotification } from "../../providers/NotificationProvider";
import { StoreBenchmarkType, StoreBenchmarkIndicator } from "../../types/storeBenchmark";
import { StoreBenchmarkTypeLabels, StoreBenchmarkIndicatorLabels } from "../../constants/storeBenchmark";
import FormLabel from "../../components/FormInputs/FormLabel";
import YearMonthForm from "../../components/FormInputs/YearMonthForm";
import Section from "../../components/Section";
import DailyBenchmarkCalendarForm, { DailyBenchmarkMap } from "./parts/DailyBenchmarkCalendarForm";
import BenchmarkProgressNotificationSettingsForm, {
  generateDefaultValues as generateDefaultNotificationSettings,
  BenchmarkNotificationSetting
} from "./parts/BenchmarkProgressNotificationSettingsForm";

type BenchmarkForm = {
  start_date: string;
  end_date: string;
  benchmark_type: StoreBenchmarkType;
  benchmark_indicator: StoreBenchmarkIndicator;
  benchmark_value: number;
  title: string;
  description: string;
  track_daily_performance: boolean;
  enabled_progress_notification: boolean;
  enabled_daily_progress_notification: boolean;
  notification_settings: BenchmarkNotificationSetting;
  daily_notification_settings: BenchmarkNotificationSetting;
};

const BenchmarkFormView = () => {
  const isMobile = useMobileFlag();
  const { showSuccessNotification } = useNotification();
  const { currentStore } = useLoginContext();
  const history = useHistory();
  const { api, loading } = useApi();
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth() + 1;
  const defaultValues = generateDefaultValues(thisYear, thisMonth);
  const { control, handleSubmit, errors, setValue, watch } = useForm<BenchmarkForm>({ defaultValues });
  const watchValues = watch();
  const [startYear, setStartYear] = useState(thisYear);
  const [startMonth, setStartMonth] = useState(thisMonth);
  const [dailyBenchmarkMap, setDailyBenchmarkMap] = useState<DailyBenchmarkMap>({});
  const generateBenchmarkTitleWith = (params: Partial<ValueForTitleGeneration>) => generateBenchmarkTitle({
    month: params.month || thisMonth,
    indicator: params.indicator || watchValues.benchmark_indicator
  });

  const resetDate = (year: number, month: number) => {
    setValue("start_date", moment([year, month - 1, 1]).format("YYYY-MM-DD"));
    setValue("end_date", moment([year, month - 1, 1]).endOf("month").format("YYYY-MM-DD"));
    setValue("title", generateBenchmarkTitleWith({ month }));
    setDailyBenchmarkMap({});
  };

  const submit = async (formValue: BenchmarkForm) => {
    const params = {
      title: formValue.title,
      description: formValue.description,
      benchmark_type: formValue.benchmark_type,
      benchmark_indicator: formValue.benchmark_indicator,
      benchmark_value: formValue.benchmark_value,
      daily_benchmarks: formValue.track_daily_performance ? (
        Object.entries(dailyBenchmarkMap)
          .filter(([, value]) => value)
          .map(([index, value]) => ({ index: Number(index), benchmark_value: value }))
      ) : [],
      ...(formValue.benchmark_type === "monthly" ? {
        start_year: startYear,
        start_month: startMonth
      } : {
        start_date: formValue.start_date,
        end_date: formValue.end_date
      }),
      enabled_progress_notification: formValue.enabled_progress_notification,
      ...(formValue.enabled_progress_notification ? {
        time_of_notification_on_started: formValue.notification_settings.timeOfNotificationOnStarted,
        time_of_notification_on_middle: formValue.notification_settings.timeOfNotificationOnMiddle,
        time_of_notification_on_finished: formValue.notification_settings.timeOfNotificationOnFinished,
        days_of_notification_on_finished: formValue.notification_settings.daysOfNotificationOnFinished,
        notification_policies: formValue.notification_settings.notificationPolicies
      } : {}),
      enabled_daily_progress_notification: formValue.enabled_daily_progress_notification,
      ...(formValue.enabled_daily_progress_notification ? {
        time_of_daily_notification_on_started: formValue.daily_notification_settings.timeOfNotificationOnStarted,
        time_of_daily_notification_on_middle: formValue.daily_notification_settings.timeOfNotificationOnMiddle,
        time_of_daily_notification_on_finished: formValue.daily_notification_settings.timeOfNotificationOnFinished,
        days_of_daily_notification_on_finished: formValue.daily_notification_settings.daysOfNotificationOnFinished,
        daily_notification_policies: formValue.daily_notification_settings.notificationPolicies
      } : {})
    };

    const response = await api.post("/store_benchmarks", params);
    if (response) {
      showSuccessNotification("新しく目標を作成しました。");
      history.push("/admin/benchmark");
    }
  };

  return (
    <Box>
      <Section title="目標設定">
        <Box mb={1}>
          <FormLabel label="タイプ" style={{ marginBottom: 0 }} />
          <Controller
            control={control}
            name="benchmark_type"
            render={({ value, onChange }) => (
              <RadioGroup
                row
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  resetDate(startYear, startMonth);
                }}
              >
                <FormControlLabel
                  value="monthly"
                  control={<Radio disabled={loading} />}
                  label={StoreBenchmarkTypeLabels.monthly}
                />
                <FormControlLabel
                  value="temporary"
                  control={<Radio disabled={loading} />}
                  label={StoreBenchmarkTypeLabels.temporary}
                />
              </RadioGroup>
            )}
          />
        </Box>

        <Box mb={2}>
          <FormLabel label="指標" />
          <Controller
            control={control}
            name="benchmark_indicator"
            render={({ onChange, value }) => (
              <Select
                sx={{ width: 200 }}
                value={value}
                disabled={loading}
                onChange={({ target }) => {
                  onChange(target.value);
                  setValue("title", generateBenchmarkTitleWith({ indicator: target.value }));
                }}
              >
                {Object.entries(StoreBenchmarkIndicatorLabels).map(([indicator, label]) => (
                  <MenuItem key={indicator} value={indicator}>{label}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>

        <Box mb={2}>
          <FormLabel label="目標値" />
          <Controller
            control={control}
            name="benchmark_value"
            valueAsNumber
            rules={{ required: "目標値を入力してください" }}
            render={({ value, onChange }) => (
              <OutlinedInput
                error={Boolean(errors.benchmark_value)}
                type="number"
                value={value}
                disabled={loading}
                onChange={({ target }) => onChange(target.value === "" ? 0 : Math.max(0, Number(target.value)))}
              />
            )}
          />
          {errors.benchmark_value?.message && (
            <FormHelperText error>{errors.benchmark_value?.message}</FormHelperText>
          )}
        </Box>

        <Box mb={1}>
          <FormLabel label="目標期間" />
          <Box display={watchValues.benchmark_type === "monthly" ? "block" : "none"}>
            <YearMonthForm
              years={[...Array(3)].map((_, i) => thisYear + i)}
              year={startYear}
              month={startMonth}
              disabled={loading}
              onChange={(year, month) => {
                if (year === null || month === null) return;

                setStartYear(year);
                setStartMonth(month);
                resetDate(year, month);
              }}
            />
          </Box>

          <Box
            display={watchValues.benchmark_type === "temporary" ? "flex" : "none"}
            alignItems="center"
            gap={1}
            width={300}
          >
            <Controller
              control={control}
              name="start_date"
              rules={{
                required: "開始日を入力してください",
                validate: (startDate) => (
                  moment(watchValues.end_date).diff(startDate, "days") <= 60
                    || "目標期間は最大60日です"
                )
              }}
              render={({ onChange, value }) => (
                <Flatpickr
                  value={value || ""}
                  options={{ ...DATE_PICK_OPTION, maxDate: watchValues.end_date }}
                  onChange={(dates: Date[]) => {
                    const newValue = dates[0];
                    onChange(newValue ? moment(newValue).format("YYYY-MM-DD") : null)
                  }}
                />
              )}
            />
            〜
            <Controller
              control={control}
              name="end_date"
              rules={{ required: "終了日を入力してください" }}
              render={({ onChange, value }) => (
                <Flatpickr
                  value={value || ""}
                  options={{ ...DATE_PICK_OPTION, minDate: watchValues.start_date }}
                  onChange={(dates: Date[]) => {
                    const newValue = dates[0];
                    onChange(newValue ? moment(newValue).format("YYYY-MM-DD") : null)
                  }}
                />
              )}
            />
          </Box>
          {errors.start_date?.message && (
            <FormHelperText error>{errors.start_date?.message}</FormHelperText>
          )}
          {errors.end_date?.message && (
            <FormHelperText error>{errors.end_date?.message}</FormHelperText>
          )}
        </Box>

        <Controller
          control={control}
          name="track_daily_performance"
          render={({ onChange, value }) => (
            <FormControlLabel
              label="日次の目標も設定する"
              disabled={isMobile || loading || moment(watchValues.end_date).isSame(moment(watchValues.start_date), "day")}
              control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
            />
          )}
        />
        {isMobile && (
          <FormHelperText>※日次目標の設定はPC版から行なえます。</FormHelperText>
        )}

        {!isMobile && watchValues.track_daily_performance && (
          <DailyBenchmarkCalendarForm
            startDate={watchValues.start_date}
            endDate={watchValues.end_date}
            baseBenchmarkValue={watchValues.benchmark_value}
            dailyBenchmarkMap={dailyBenchmarkMap}
            disabled={loading}
            onChange={setDailyBenchmarkMap}
          />
        )}
      </Section>

      <Section title="基本情報">
        <Box mb={2}>
          <FormLabel label="目標タイトル" />
          <Controller
            control={control}
            name="title"
            render={(props) => (
              <OutlinedInput
                fullWidth
                disabled={loading}
                {...props}
              />
            )}
          />
        </Box>

        <Box>
          <FormLabel label="メモ" attachment="任意" />
          <Controller
            control={control}
            name="description"
            render={(props) => (
              <OutlinedInput
                fullWidth
                multiline
                rows={5}
                disabled={loading}
                placeholder="目標に関するメモを入力しましょう"
                {...props}
              />
            )}
          />
        </Box>
      </Section>

      <Section title="目標進捗の通知設定">
        <Box p={1.5} bgcolor="#F2F2F2">
          <Controller
            control={control}
            name="enabled_progress_notification"
            render={({ onChange, value }) => (
              <FormControlLabel
                label="目標進捗の通知を受け取る"
                disabled={loading}
                sx={{ mb: 0 }}
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
              />
            )}
          />
        </Box>

        <Box display={watchValues.enabled_progress_notification ? "block" : "none"}>
          <Controller
            control={control}
            name="notification_settings"
            render={({ onChange, value }) => (
              <BenchmarkProgressNotificationSettingsForm
                storeId={currentStore.id}
                notificationSettings={value}
                labelOnStarted="目標開始日の"
                labelOnMiddle="毎週月曜日の"
                labelOnFinished="目標終了の"
                disabled={loading}
                onChange={onChange}
              />
            )}
          />
        </Box>
      </Section>

      <Box display={watchValues.track_daily_performance ? "block" : "none"}>
        <Section title="日次目標進捗の通知設定">
          <Box p={1.5} bgcolor="#F2F2F2">
            <Controller
              control={control}
              name="enabled_daily_progress_notification"
              render={({ onChange, value }) => (
                <FormControlLabel
                  label="日次目標進捗の通知を受け取る"
                  disabled={loading}
                  sx={{ mb: 0 }}
                  control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                />
              )}
            />
          </Box>

          <Box display={watchValues.enabled_daily_progress_notification ? "block" : "none"}>
            <Controller
              control={control}
              name="daily_notification_settings"
              render={({ onChange, value }) => (
                <BenchmarkProgressNotificationSettingsForm
                  storeId={currentStore.id}
                  notificationSettings={value}
                  labelOnStarted="日次目標を設定している日の"
                  labelOnMiddle="日次目標を設定している日の"
                  labelOnFinished="日次目標を設定している日の"
                  disabled={loading}
                  onChange={onChange}
                />
              )}
            />
          </Box>
        </Section>
      </Box>

      <StyledActionContainer>
        <Button
          variant="outlined"
          color="cancel"
          disabled={loading}
          onClick={() => history.goBack()}
        >
          もどる
        </Button>

        <Button
          variant="contained"
          color="submit"
          disabled={loading}
          onClick={handleSubmit(submit)}
        >
          作成する
        </Button>
      </StyledActionContainer>
    </Box>
  );
};

const generateDefaultValues = (year: number, month: number): BenchmarkForm => ({
  start_date: moment([year, month - 1, 1]).format("YYYY-MM-DD"),
  end_date: moment([year, month - 1, 1]).endOf("month").format("YYYY-MM-DD"),
  benchmark_type: "monthly",
  benchmark_indicator: "connected_unique_user_count",
  benchmark_value: 100,
  title: generateBenchmarkTitle({ month, indicator: "connected_unique_user_count" }),
  description: "",
  track_daily_performance: false,
  enabled_progress_notification: true,
  enabled_daily_progress_notification: true,
  notification_settings: generateDefaultNotificationSettings(),
  daily_notification_settings: generateDefaultNotificationSettings()
});

type ValueForTitleGeneration = {
  month: number;
  indicator: StoreBenchmarkIndicator;
};
const generateBenchmarkTitle = (params: ValueForTitleGeneration) => {
  const label = StoreBenchmarkIndicatorLabels[params.indicator];
  return `${params.month}月${label}`;
};

const StyledActionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  "& > button": {
    width: 300
  }
}));

export default BenchmarkFormView;
