export const PaymentBenefitLabels = {
  disable: "無効",
  only_toypo_payment: "トイポ支払いのみで有効",
  only_store_payment: "店内支払いのみで有効",
  everything: "トイポ・店内支払いで有効"
} as const;

export const PaymentBenefits = [
  "disable",
  "only_toypo_payment",
  "only_store_payment",
  "everything",
] as const;
