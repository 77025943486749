import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";
import { downloadFile } from "../general";

export const getActivityLogs = async (urlParams: string) => {
  const GET_ACTIVITY_LOGS_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs${urlParams}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_ACTIVITY_LOGS_URL,
  });

  return response;
};

export const updateActivityLog = async ({ id, params }) => {
  const UPDATE_ACTIVITY_LOG_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs/${id}`;

  const requestParams = {
    activity_log: params,
  };

  const response = await apiClient({
    method: "PUT",
    uri: UPDATE_ACTIVITY_LOG_URL,
    params: requestParams,
  });

  return response;
};

export const deleteActivityLog = async (id, doCancelDependentLogs) => {
  const DELETE_ACTIVITY_LOG_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs/${id}?do_cancel_dependent_logs=${doCancelDependentLogs}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_ACTIVITY_LOG_URL,
  });

  return response;
};

export const getContents = async () => {
  const GET_CONTENTS_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs/contents`;

  const response = await apiClient({
    method: "GET",
    uri: GET_CONTENTS_URL,
  });

  return response.data;
};

export const getUseSubscriptionLogs = async () => {
  const GET_USE_SUBSCRIPTION_LOGS_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs?action_type=use_subscription`;

  const response = await apiClient({
    method: "GET",
    uri: GET_USE_SUBSCRIPTION_LOGS_URL,
  });

  return response.data;
};

export const getUseTicketLogs = async () => {
  const GET_USE_TICKET_LOGS_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs?action_type=use_ticket`;

  const response = await apiClient({
    method: "GET",
    uri: GET_USE_TICKET_LOGS_URL,
  });

  return response.data;
};

export const downloadActivityLogsCsv = async (urlParams: string) => {
  const DOWNLOAD_ACTIVITY_LOGS_CSV_URL = `/staff/stores/${
    loadViewObj()?.id
  }/activity_logs${urlParams}&format=csv`;

  await apiClient({
    method: "GET",
    uri: DOWNLOAD_ACTIVITY_LOGS_CSV_URL,
    responseType: "blob",
  }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const filename = decodeURI(contentDisposition.split("'").slice(-1)[0]);
    const blob = new Blob([response.data], { type: "text/csv" });
    downloadFile(blob, decodeURI(filename));
  });
};
