import React from "react";
import { Row, Col, ButtonToolbar, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Pagination } from "@mui/material";
import styled from "@emotion/styled";
import SmallTableBody from "../../../components/Table/SmallTableBody";
import { Card } from "../../../components/Card/Card";
import Button from "../../../components/CustomButton/CustomButton";
import { StampCardContent } from "../../../containers/entities/StampCardContentEntityContainer";
import { CouponContentBlock } from "../../../types/api/coupon.d";
import { usePcSizeFlag } from "../../../lib/hooks"
import {
  AutoCouponContentStatusToJpObj,
  CouponStatusColors,
  CouponStatusType
} from "../../../containers/entities/CouponContentEntityContainer";
import ContentSalesEffect from "../../Coupon/parts/ContentSalesEffect";
import { useLoginContext } from "../../../providers/LoginContextProvider";

const StyledStatusLabel = styled.div<{ status: CouponStatusType }>`
  background-color: ${(props) => CouponStatusColors[props.status]};
  display: inline;
  color: white;
  white-space: nowrap;
  padding: 5px 6px;
  border: 0;
  border-radius: 6px;
  font-weight: bold;
`;

type Props = {
  stampCardContent?: StampCardContent;
  privilegeCouponContents: CouponContentBlock[];
  onChangePage: (page: number) => void;
  totalPages: number;
  currentPage: number;
};

const PrivilegeStampListView = ({
  stampCardContent,
  privilegeCouponContents,
  onChangePage,
  totalPages,
  currentPage
}: Props): JSX.Element => {
  const history = useHistory();
  const isPcOrTablet = usePcSizeFlag();
  const { currentStore } = useLoginContext();

  return (
    <Row>
      <Col md={12}>
        <ButtonToolbar style={{ marginBottom: 10 }}>
          <Button
            bsStyle="info"
            fill
            pullRight
            onClick={() =>
              history.push("privilege_stamp/new", {
                maxStamp: stampCardContent?.max_stamp
              })
            }
          >
            <b>特典を作成する</b>
          </Button>
        </ButtonToolbar>
        <Card
          title="特典一覧"
          ctTableFullWidth
          content={
            <>
              <Table striped hover>
                {isPcOrTablet && (
                  <thead>
                    <tr>
                      <th> </th>
                      <th>特典タイトル</th>
                      <StyledNoWrapTh>必要スタンプ</StyledNoWrapTh>
                      <StyledNoWrapTh>発行</StyledNoWrapTh>
                      {currentStore.show_coupon_arrival_count && (
                        <StyledNoWrapTh>到達</StyledNoWrapTh>
                      )}
                      <StyledNoWrapTh>閲覧</StyledNoWrapTh>
                      <StyledNoWrapTh>利用</StyledNoWrapTh>
                      <StyledNoWrapTh>売上効果</StyledNoWrapTh>
                      <StyledNoWrapTh>スタンプカードに反映</StyledNoWrapTh>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {privilegeCouponContents.map((item) => (
                      <tr
                        key={item.coupon_content.id}
                        onClick={(): void =>
                          history.push("privilege_stamp/edit", {
                            privilegeCoupon: {
                              ...item.qrcode,
                              ...item.obtain_condition,
                              ...item.coupon_content
                            },
                            maxStamp: stampCardContent?.max_stamp
                          })
                        }
                        style={{cursor: "pointer"}}>
                        {
                          isPcOrTablet ? (
                            <>
                              <td style={{ width: 48 }}>
                                {item.coupon_content.image_url && (
                                  <img
                                    key={item.coupon_content.id}
                                    alt="クーポン画像"
                                    src={item.coupon_content.image_url}
                                    width={48}
                                    height={30}
                                    style={{ borderRadius: 2, objectFit: "cover" }}
                                  />
                                )}
                              </td>
                              <td>
                                <div><b>{item.coupon_content.benefit}</b></div>
                                {item.coupon_content.memo && (
                                  <StyledMemo>{item.coupon_content.memo}</StyledMemo>
                                  )}
                              </td>
                              <StyledNoWrapTd>{item.obtain_condition?.threshold_point}</StyledNoWrapTd>
                              <StyledNoWrapTd>{item.delivered_count}</StyledNoWrapTd>
                              {currentStore.show_coupon_arrival_count && (
                                <StyledNoWrapTd>{item.arrival_count}</StyledNoWrapTd>
                              )}
                              <StyledNoWrapTd>{item.read_count}</StyledNoWrapTd>
                              <StyledNoWrapTd>{item.used_count}</StyledNoWrapTd>
                              <StyledNoWrapTdSalesEffect>
                                <ContentSalesEffect value={item.sales_effect}/>
                              </StyledNoWrapTdSalesEffect>
                              <StyledNoWrapTd>
                                <StyledStatusLabel status={item.coupon_content.status}>
                                  {AutoCouponContentStatusToJpObj[item.coupon_content.status]}
                                </StyledStatusLabel>
                              </StyledNoWrapTd>
                            </>
                          ) : (
                            <SmallTableBody
                              image={item.coupon_content.image_url}
                              title={item.coupon_content.benefit}
                              contents={[
                                `必要スタンプ数:  ${
                                  item.obtain_condition?.threshold_point || "---"
                                }`,
                              ]}
                              status={
                                <StyledStatusLabel status={item.coupon_content.status}>
                                  {AutoCouponContentStatusToJpObj[item.coupon_content.status]}
                                </StyledStatusLabel>
                              }
                              memo={item.coupon_content.memo}
                            />
                          )
                        }
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          }
          stats={
            totalPages > 1 ? (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={ (_, page) => onChangePage(page)}
                sx={{ fontSize: 24, color: "black" }}
              />
            ) : null
          }
        />
      </Col>
    </Row>
  );
};

const StyledNoWrapTh = styled("th")({ whiteSpace: "nowrap" });
const StyledNoWrapTd = styled("td")({ whiteSpace: "nowrap" });
const StyledMemo = styled("div")({
  fontSize: 12,
  color: "#787878"
});
const StyledNoWrapTdSalesEffect = styled("td")({ whiteSpace: "nowrap" , backgroundColor: "#EFF8F7" });

export default PrivilegeStampListView;
