import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  ColorType,
  membersCardRankColorCode,
} from "../../../containers/entities/MembersCardContentEntityContainer";

type Props = {
  avatar: string;
  rankColor?: ColorType | null;
  iconSize?: number;
  badgeSize?: number;
  badgePosition?: number;
};

const UserIcon = ({
  avatar,
  rankColor,
  iconSize = 30,
  badgeSize = 9,
  badgePosition = 0,
}: Props): JSX.Element => {
  return (
    <Box position="relative">
      <StyledUserIcon
        alt="ユーザーアイコン"
        src={avatar}
        width={iconSize}
        height={iconSize}
      />
      {rankColor && (
        <StyledRankBadge color={rankColor} position={badgePosition} size={badgeSize} />
      )}
    </Box>
  );
};

const StyledUserIcon = styled("img")({
  borderRadius: 24,
  objectFit: "cover",
});

const StyledRankBadge = styled("div", {
  shouldForwardProp: (prop: string) => !["position", "size", "color"].includes(prop)
})<{ position: number; size: number; color: ColorType }>(({ position, size, color }) => ({
  borderRadius: "50%",
  position: "absolute",
  right: position,
  bottom: position,
  width: size,
  height: size,
  backgroundColor: membersCardRankColorCode[color],
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: color === "white" ? "lightgray" : "white",
}));

export default UserIcon;
