import {
  createContext as reactCreateContext,
  useContext as reactUseContext
} from "react";

export function createContext<T>() {
  const context = reactCreateContext<T | undefined>(undefined);
  const useContext = () => {
    const c = reactUseContext(context);
    if (c === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }

    return c;
  }

  return [useContext, context] as const;
}
