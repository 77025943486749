import React, { Dispatch, SetStateAction } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import moment from "moment";
import {
  DataPeriod,
  DataUnit,
} from "../../../containers/entities/AnalyticsEntityContainer";

type Props = {
  selectedUnit: DataUnit;
  setSelectedUnit: React.Dispatch<React.SetStateAction<DataUnit>>;
  setDataPeriod: Dispatch<SetStateAction<DataPeriod>>;
};

const AnalyticsUnitButtonGroup: React.FC<Props> = ({
  selectedUnit,
  setSelectedUnit,
  setDataPeriod,
}) => {
  const units: DataUnit[] = ["daily", "weekly", "monthly"];
  const unitsToJp = (unit: DataUnit): string => {
    switch (unit) {
      case "daily":
        return "日";
      case "weekly":
        return "週";
      case "monthly":
        return "月";
      default:
        return "";
    }
  };

  const onClick = (unit) => {
    setSelectedUnit(unit);
    switch (unit) {
      case "daily":
        setDataPeriod({
          type: "last_7_days",
          from: moment().add(-7, "day").startOf("day").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        });
        break;
      case "weekly":
        setDataPeriod({
          type: "last_8_weeks",
          from: moment().add(-8, "week").startOf("week").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        });
        break;
      case "monthly":
        setDataPeriod({
          type: "this_year",
          from: moment().startOf("year").format("YYYY/MM/DD"),
          to: moment().endOf("year").format("YYYY/MM/DD"),
        });
        break;
      default:
        break;
    }
  };

  return (
    <ToggleButtonGroup
      type="radio"
      name="unit"
      onChange={onClick}
      style={{
        marginLeft: 10,
        marginRight: 10,
        height: 36,
      }}
    >
      {units.map((unit) => (
        <ToggleButton
          key={unit}
          value={unit}
          style={{
            borderWidth: 1,
            backgroundColor: selectedUnit === unit ? "#3A4357" : undefined,
            color: selectedUnit === unit ? "white" : undefined,
            fontSize: 14
          }}
        >
          {unitsToJp(unit)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default AnalyticsUnitButtonGroup;
