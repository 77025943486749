import React, { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Row, Col, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Pagination } from "@mui/material";
import { SortOrderType } from "../../../types/common.d";
import { NoticeSortByType } from "../../../types/notice.d";
import { NoticeContentsResponse } from "../../../types/api/notice.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import {
  NoticeStatusType,
  NoticeStatus,
  NoticeStatusColors,
  NoticeContentStatusToJpObj,
} from "../../../containers/entities/NoticeEntityContainer";
import { useNotification } from "../../../providers/NotificationProvider";
import { Card } from "../../../components/Card/Card";
import ContentSummaryCard from "../../../components/Card/ContentSummaryCard";
import DisablingMask from "../../../components/DisablingMask";
import NoticeTable from "./NoticeTable";

const sortMenuItems: {
  sortBy: NoticeSortByType;
  sortOrder: SortOrderType;
  label: string;
}[] = [
  {
    sortBy: "publish_at",
    sortOrder: "desc",
    label: "配信日/配信予定日の新しい順",
  },
  {
    sortBy: "publish_at",
    sortOrder: "asc",
    label: "配信日/配信予定日の古い順",
  },
];

type NoticeContentsProps = {
  page?: number;
  sortBy?: NoticeSortByType;
  sortOrder?: SortOrderType;
};

const NoticeList = ({ showArrivalCount }): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const isPcOrTablet = usePcSizeFlag();
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4;

  const [sortBy, setSortBy] = useState<NoticeSortByType>("publish_at");
  const [sortOrder, setSortOrder] = useState<SortOrderType>("desc");
  const [selectedSort, setSelectedSort] = useState<string>("配信日/配信予定日の新しい順");

  const { api, loading } = useApi();
  const {
    api: noticeContentsGetApi,
    response: noticeContentsResponse,
    headers: noticeContentsResHeaders,
  } = useApi();

  const getNoticeContents = useCallback(
    async (params: NoticeContentsProps = {}) => {
      const sort = params.sortBy || "publish_at";
      const order = params.sortOrder || "desc";
      const page = params.page || 1;

      const res = await noticeContentsGetApi.get({
        uri: "/notice_contents",
        params: {
          type: "normal",
          page: page,
          sort: sort,
          order: order,
          limit: PAGE_LIMIT,
        },
      });

      if (!res) return;

      setSortBy(sort);
      setSortOrder(order);
    },
    [noticeContentsGetApi, PAGE_LIMIT]
  );

  useEffect(() => {
    getNoticeContents();
  }, [getNoticeContents]);

  const noticeContents: NoticeContentsResponse["notice_contents"] =
    noticeContentsResponse?.notice_contents || [];

  const deleteNoticeContent = async (id: number): Promise<void> => {
    if (!window.confirm("このお知らせを削除しますか？")) return;

    const res = await api.delete(`/notice_contents/${id}`);
    if (!res) return;

    getNoticeContents();
    showSuccessNotification("お知らせを削除しました。");
  };

  return (
    <Row>
      <Col md={12}>
        <Card
          title="通常配信"
          rightContent={
            !isPcOrTablet && (
              <ButtonToolbar>
                <DropdownButton
                  title={selectedSort}
                  bsSize="small"
                  style={{ border: "none", color: "#000" }}
                  pullRight
                  id="dropdown-notice-list"
                >
                  {sortMenuItems.map((item) => (
                    <MenuItem
                      key={item.label}
                      onClick={() => {
                        getNoticeContents({
                          sortBy: item.sortBy,
                          sortOrder: item.sortOrder,
                        });
                        setSelectedSort(item.label);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </DropdownButton>
              </ButtonToolbar>
            )
          }
          content={
            isPcOrTablet ? (
              <DisablingMask mask={loading}>
                <NoticeTable
                  noticeContents={noticeContents}
                  goToEdit={(id) => {
                    history.push(`notices/edit?notice_content_id=${id}`);
                  }}
                  deleteNoticeContent={(id) => deleteNoticeContent(id)}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  onSort={(newSortBy, newSortOrder) => {
                    getNoticeContents({
                      sortBy: newSortBy,
                      sortOrder: newSortOrder,
                    });
                  }}
                  showArrivalCount={showArrivalCount}
                />
              </DisablingMask>
            ) : (
              noticeContents.map(
                (
                  {
                    notice_content,
                    delivered_count,
                    read_count,
                    read_count_diff,
                    arrival_count
                  },
                  index
                ) => (
                  <ContentSummaryCard
                    style={{
                      padding: "16px 0",
                      borderBottom:
                        noticeContents.length - 1 === index
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                    key={notice_content.id}
                    onClick={() => history.push(`notices/edit?notice_content_id=${notice_content.id}`)}
                    imageUrl={notice_content.image_urls[0] || ""}
                    title={notice_content.title}
                    label={
                      <StyledStatusLabel status={notice_content.status}>
                        {NoticeContentStatusToJpObj[notice_content.status]}
                      </StyledStatusLabel>
                    }
                    note={
                      notice_content.publish_at
                        ? `配信日: ${moment(notice_content.publish_at).format("YYYY/M/D(dddd) HH:mm")}`
                        : ""
                    }
                    mask={notice_content.status !== NoticeStatus.Publish}
                    deliveredCount={delivered_count}
                    readCount={read_count}
                    readCountDiff={read_count_diff}
                    createdAt={notice_content.created_at}
                    memo={notice_content.memo}
                    {...(showArrivalCount && { arrivalCount: arrival_count })}
                  />
                )
              )
            )
          }
          stats={
            noticeContentsResHeaders && Number(noticeContentsResHeaders["total-pages"]) > 1 ? (
              <Pagination
                data-cy="notice-list-pagination"
                count={Number(noticeContentsResHeaders["total-pages"])}
                page={Number(noticeContentsResHeaders["current-page"])}
                onChange={(_, value) =>
                  getNoticeContents({
                    page: value,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                  })
                }
                sx={{ fontSize: 24, color: "black" }}
              />
            ) : null
          }
        />
      </Col>
    </Row>
  );
};

const StyledStatusLabel = styled("div", {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: NoticeStatusType }>(({ status, theme }) => ({
  backgroundColor: NoticeStatusColors[status],
  display: "inline",
  color: "white",
  padding: theme.spacing(0.5, 1),
  border: 0,
  borderRadius: 6,
  fontWeight: 700,
  fontSize: 10,
}));

export default NoticeList;
