import React, { useState } from "react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  open: boolean;
  defaultValue: string;
  disabled: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
};

const RetrospectiveModal = ({
  open,
  defaultValue,
  disabled,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  const [text, setText] = useState(defaultValue);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <StyledDialogTitle>
        振り返り
        <i onClick={onClose} className="ri-close-line" />
      </StyledDialogTitle>

      <DialogContent>
        <OutlinedInput
          fullWidth
          multiline
          rows={6}
          value={text}
          disabled={disabled}
          placeholder="目標に関する振り返りの内容を残しておきましょう。"
          onChange={(e) => setText(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          fullWidth
          variant="outlined"
          color="cancel"
          disabled={disabled}
          onClick={onClose}
        >
          閉じる
        </Button>

        <Button
          fullWidth
          variant="contained"
          color="submit"
          disabled={disabled || text === ""}
          onClick={() => onSubmit(text)}
        >
          内容を保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  "& > i": {
    cursor: "pointer"
  }
});

export default RetrospectiveModal;
