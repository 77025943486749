import { OrderStatus, OrderType } from "../../types/order";

const StatusMap = {
  takeout: ["unapproved", "receive", "ready", "wait", "done"],
  preorder: ["unapproved", "receive", "ready", "wait", "done"],
  delivery: ["unapproved", "receive", "ready", "wait", "in_delivery", "done"],
  ec: ["receive", "ready", "shipped", "done"]
} as const;

export const calculateNextStatus = (orderType: OrderType, currentStatus: OrderStatus): OrderStatus | null => {
  const statuses = StatusMap[orderType] || [];
  const currentIndex = statuses.findIndex((s: OrderStatus) => s === currentStatus);
  return currentIndex === -1 ? null : statuses[currentIndex + 1];
};

export const calculateAvailableStatuses = (orderType: OrderType, currentStatus: OrderStatus): OrderStatus[] => {
  if (currentStatus === "cancel") {
    return ["cancel"];
  } else if (currentStatus === "unapproved") {
    return ["unapproved", "receive"];
  } else {
    const statuses: OrderStatus[] = [];

    statuses.push("receive");
    statuses.push("ready");
    if (["delivery", "preorder", "takeout"].includes(orderType)) statuses.push("wait");
    if (orderType === "delivery") statuses.push("in_delivery");
    if (orderType === "ec") statuses.push("shipped");
    statuses.push("done");

    return statuses;
  }
};
