import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

type Props = {
  title: React.ReactNode;
  onMoveToDetail?: () => void;
  children: React.ReactNode;
};

const HomeSection = ({
  title,
  onMoveToDetail,
  children
}: Props): JSX.Element => {
  return (
    <Paper variant="outlined" sx={{ borderRadius: 2 }}>
      <StyledSectionHeader>
        <div>{title}</div>

        {onMoveToDetail && (
          <Button
            size="small"
            color="submit"
            sx={{ fontSize: 15, lineHeight: 1, p: 0 }}
            onClick={onMoveToDetail}
          >
            詳しく見る
          </Button>
        )}
      </StyledSectionHeader>

      <Divider />

      {children}
    </Paper>
  );
};

const StyledSectionHeader = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: 16,
  fontWeight: 700,
  margin: theme.spacing(1.5),
  justifyContent: "space-between",
  alignItems: "center"
}));

export default HomeSection;
