import React from "react";
import { useHistory } from "react-router";
import { Grid, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useApi } from "../../lib/hooks";
import { Card } from "../../components/Card/Card";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import FormInputs from "../../components/FormInputs/FormInputs";
import AuthEntityContainer from "../../containers/entities/AuthEntityContainer";
import { useNotification } from "../../providers/NotificationProvider";

type FormTypes = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const NewPasswordView = (): JSX.Element => {
  const history = useHistory();
  const { showErrorNotification } = useNotification();
  const authEntityContainer = AuthEntityContainer.useContainer();
  const { updatePassword } = authEntityContainer.api;
  const { currentStaff } = useLoginContext();
  const { api } = useApi();

  const { register, errors, handleSubmit, setError } = useForm<FormTypes>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data) => {
    const { newPassword, confirmPassword } = data;
    if (newPassword !== confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "新しいパスワードと確認用パスワードが一致していません。",
      });
      return;
    }

    try {
      await updatePassword(data);
      await api.patch(`/staffs/${currentStaff.id}`, { registered: true });
      history.push("/admin/home");
      window.location.reload();
    } catch (error) {
      showErrorNotification(error.message);
    }
  };

  const onError = () => {
    showErrorNotification("値が正しく入力されていません");
  };

  return (
    <Grid fluid className="content">
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row className="justify-content-md-center">
          <Col xs={10} md={6} xsOffset={1} mdOffset={3}>
            <div style={{ marginTop: 50 }} />
            <Card
              title=""
              ctTableResponsive
              content={
                <>
                  <FormInputs
                    properties={[
                      {
                        name: "currentPassword",
                        type: "password",
                        ncol: "col-xs-12 col-md-10 col-md-offset-1",
                        label: "現在のパスワード",
                        placeholder: "現在のパスワードを入力する",
                        bsClass: "form-control",
                        inputRef: register({
                          required: "パスワードを入力してください。",
                        }),
                        validationMessage: errors.currentPassword?.message,
                      },
                      {
                        name: "newPassword",
                        type: "password",
                        ncol: "col-xs-12 col-md-10 col-md-offset-1",
                        label: "新しいパスワード",
                        placeholder: "新しいパスワードを入力する",
                        bsClass: "form-control",
                        inputRef: register({
                          required: "新しいパスワードを入力してください。",
                          minLength: {
                            value: 6,
                            message:
                              "6文字以上のパスワードを設定してください。",
                          },
                        }),
                        validationMessage: errors.newPassword?.message,
                      },
                      {
                        name: "confirmPassword",
                        type: "password",
                        ncol: "col-xs-12 col-md-10 col-md-offset-1",
                        label: "確認用パスワード",
                        placeholder: "確認用パスワードを入力する",
                        bsClass: "form-control",
                        inputRef: register({
                          required: "確認用のパスワードを入力してください。",
                        }),
                        validationMessage: errors.confirmPassword?.message,
                      },
                    ]}
                  />

                  <Row style={{ marginBottom: 30 }}>
                    <Col xs={10} xsOffset={1}>
                      <LoadingButton
                        type="submit"
                        label="変更する"
                        loadingLabel="変更中..."
                        block
                        fill
                        color="info"
                      />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Form>
    </Grid>
  );
};

export default NewPasswordView;
