import React, { useState, useEffect } from "react";
import { FormGroup, Modal, Radio } from "react-bootstrap";
import Button from "../../../components/CustomButton/CustomButton";
import { SubscriptionContentStatus } from "../../../containers/entities/SubscriptionContentEntityContainer";
import { SubscriptionContentStatusLabel } from "../../../constants/subscription";

interface Props {
  show: boolean;
  onHide: () => void;
  subscriptionId: number;
  defaultStatus: SubscriptionContentStatus;
  onClickStatusUpdateButton: ({
    newStatus,
    id,
  }: {
    newStatus: SubscriptionContentStatus;
    id: number;
  }) => void;
}

const statuses: SubscriptionContentStatus[] = ["open", "limited_open", "close"];

const SubscriptionStatusChangeModal: React.FC<Props> = ({
  show,
  onHide,
  subscriptionId,
  defaultStatus,
  onClickStatusUpdateButton,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<SubscriptionContentStatus>("draft");

  useEffect(() => {
    setSelectedStatus(defaultStatus);
  }, [defaultStatus]);

  const onClickUpdateStatus = () => {
    if (selectedStatus === "draft" || !subscriptionId) {
      return;
    }

    onClickStatusUpdateButton({ newStatus: selectedStatus, id: subscriptionId });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="small"
      aria-labelledby="contained-modal-title-sm"
      onExited={() => {
        document.body.style.overflow = "auto";
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title componentClass="h6">どの状態に更新しますか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          {statuses.map((status) => (
            <Radio
              key={`subscription-status-change-modal-${status}`}
              name="radioGroup"
              checked={status === selectedStatus}
              onChange={() => setSelectedStatus(status)}
            >
              <b>{SubscriptionContentStatusLabel[status]}</b>
            </Radio>
          ))}
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="info" bsSize="small" fill onClick={onClickUpdateStatus}>
          <b>更新する</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionStatusChangeModal;
