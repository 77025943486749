import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { optionItemApi } from "../../lib/api";
import EntityContainerInterface from "./_EntityContainerInterface";

// interface
export interface OptionItemInterface {
  id: number;
  order_feature_id: number;
  title: string;
  price: number;
  status: OptionItemStatus;
  created_at: Date;
}

interface OptionItemParamsInterface {
  title?: string;
  body?: string;
  price?: number;
  category?: "recommend" | "main" | "sub" | "drink" | "option";
  status?: OptionItemStatus;
}

export type OptionItemStatus = "draft" | "open" | "close" | "preview";

const statuses: OptionItemStatus[] = ["draft", "open", "preview", "close"];
const statusesToJpObj = {
  draft: "下書き",
  open: "公開",
  preview: "下書き",
  close: "非公開",
};

const useOptionItemEntityHook = (): EntityContainerInterface => {
  const [optionItems, setOptionItems] = useState<OptionItemInterface[]>([]);

  const updateOptionItemState = useCallback(
    (newItem: OptionItemInterface): void => {
      setOptionItems((currentOptionItems) => {
        const index = currentOptionItems.findIndex(
          (item) => item.id === newItem.id
        );
        return [
          ...currentOptionItems.slice(0, index),
          newItem,
          ...currentOptionItems.slice(index + 1),
        ];
      });
    },
    []
  );

  const addOptionItemState = useCallback(
    (newItem) => {
      setOptionItems([newItem, ...optionItems]);
    },
    [optionItems]
  );

  const deleteOptionItemsState = useCallback(
    (deleteItemId) => {
      const index = optionItems.findIndex((item) => item.id === deleteItemId);
      setOptionItems([
        ...optionItems.slice(0, index),
        ...optionItems.slice(index + 1),
      ]);
    },
    [optionItems]
  );

  return {
    state: {
      optionItems,
    },
    constant: {
      statuses,
    },
    logic: {
      setOptionItems: setOptionItems,
      getOptionItems: useCallback(async (): Promise<OptionItemInterface[]> => {
        const newOptionItems = await optionItemApi.getOptionItems();
        setOptionItems(newOptionItems);
        return newOptionItems;
      }, []),
      createOptionItem: async (
        params: OptionItemParamsInterface
      ): Promise<OptionItemInterface> => {
        const optionItem = await optionItemApi.createOptionItem({
          params: params,
        });
        addOptionItemState(optionItem);
        return optionItem;
      },
      updateOptionItem: async (
        id: number,
        params: OptionItemParamsInterface
      ): Promise<OptionItemInterface> => {
        const optionItem = await optionItemApi.updateOptionItem({
          id: id,
          params: params,
        });
        updateOptionItemState(optionItem);
        return optionItem;
      },
      deleteOptionItem: async (id: number): Promise<void> => {
        await optionItemApi.deleteOptionItem({ id: id });
        deleteOptionItemsState(id);
      },
      statusToJp: (status) => statusesToJpObj[status],
    },
  };
};

export default createContainer(useOptionItemEntityHook);
