/* eslint @typescript-eslint/camelcase: 0 */
/* eslint camelcase: 0 */
import apiClient from "../apiClient";
import { OwnerStaffInterface } from "../../../containers/entities/OwnerStaffEntityContainer";

export const giveOwnerToStaff = async (
  id: number
): Promise<OwnerStaffInterface> => {
  const GIVE_OWNER_TO_STAFF = `/staff/owner/owner_staffs?staff_id=${id}`;

  const params = {
    owner_staff: {
      role: "admin",
    },
  };

  const response = await apiClient({
    method: "POST",
    params,
    uri: GIVE_OWNER_TO_STAFF,
  });
  return response.data;
};

export const removeOwnerFromStaff = async (
  id: number
): Promise<OwnerStaffInterface> => {
  const REMOVE_OWNER_FROM_STAFF = `/staff/owner/owner_staffs?staff_id=${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: REMOVE_OWNER_FROM_STAFF,
  });
  return response.data;
};
