import React, { useEffect, useState, Fragment } from "react";
import { Grid, Row, Col, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { getMonth } from "../../lib/general";
import { isViewObjGroup, loadViewObj } from "../../lib/viewObj";
import { useNotification } from "../../providers/NotificationProvider";
import TicketContentEntityContainer, {
  TicketContent,
  TicketContentStatus,
  TikcetSnapshot,
} from "../../containers/entities/TicketContentEntityContainer";
import OwnerEntityContainer from "../../containers/entities/OwnerEntityContainer";
import { Card } from "../../components/Card/Card";
import Button from "../../components/CustomButton/CustomButton";
import GuideButton from "../../components/Link/GuideLink";
import TenantAlert from "../../components/Modal/TenantAlert";
import TicketStatusChangeModal from "./parts/TicketStatusChangeModal";
import TicketListTable from "./parts/TicketListTable";

const TicketListView = (): JSX.Element => {
  const minWidth600px: boolean = useMediaQuery({
    query: "(min-width: 600px)",
  });
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const history = useHistory();
  const [selectedTicketContent, setSelectedTicketContentContent] =
    useState<TicketContent>();
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);

  //   ticketContent
  const ticketContentEntityContainer =
    TicketContentEntityContainer.useContainer();
  const { ticketContents, ticketSnapshots } =
    ticketContentEntityContainer.state;
  const { statuses } = ticketContentEntityContainer.constant;
  const {
    getTicketContents,
    updateTicketContent,
    downloadTicketsCsv,
    getTicketSnapshots,
    statusToJp,
    downloadTicketsCsvbyS3,
  } = ticketContentEntityContainer.logic;

  const ownerEntityContainer = OwnerEntityContainer.useContainer();
  const { owner, cardApplicationStatus } = ownerEntityContainer.state;
  const { getOwner } = ownerEntityContainer.logic;
  const canPublishTicket = owner?.has_tenant && cardApplicationStatus === "passed"

  const goTo = {
    ticketEdit: (ticketContent: TicketContent): void =>
      history.push("ticket/edit", { ticket: ticketContent }),
    ticketNew: (): void => history.push("ticket/new", { type: "ticket" }),
    config: (): void => history.push("config"),
  };

  const changeTicketStatus = (
    id: number | undefined,
    newStatus: TicketContentStatus
  ) => {
    if (!canPublishTicket && ["open", "limited_open"].includes(newStatus)) {
      showErrorNotification("チケットを公開するには、口座登録とクレジット利用申請が必要です。");
      return;
    }
    updateTicketContent(id, { status: newStatus })
      .then(() => {
        showSuccessNotification("状態を変更しました。");
      })
      .catch((error) => {
        showErrorNotification(error.message);
      });
  };

  const onClickDownloadTicketsCsvbyS3 =
    (snapshot: TikcetSnapshot) => {
      const STORE_NAME = loadViewObj()?.name;
      const FILE_NAME = moment(snapshot.snapshot_at).format(
        `YYYY年MM月D日時点_${STORE_NAME}_販売済みチケット一覧`
      );
      downloadTicketsCsvbyS3(snapshot.s3_url, FILE_NAME);
    };

  useEffect(() => {
    getOwner();
    getTicketSnapshots();
    getTicketContents();
  }, [getOwner, getTicketContents, getTicketSnapshots]);

  return (
    <div className="content">
      <TenantAlert functionName="チケット" />

      <Grid fluid>
        <Row style={{ marginBottom: 10 }}>
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              alignItems: minWidth600px ? "center" : "flex-end",
              flexDirection: minWidth600px ? "row" : "column",
            }}
          >
            <GuideButton
              label="チケット"
              link="https://toypo.notion.site/8be2acc24b51449cb1fecaa16b30afbe"
            />

            <ButtonToolbar className="pull-right">
              <Button bsStyle="info" fill pullRight onClick={goTo.ticketNew}>
                <b>チケットを作成する</b>
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card
              title="チケット一覧"
              ctTableFullWidth
              rightContent={
                <ButtonToolbar style={{ marginBottom: 10, marginLeft: 2 }}>
                  <DropdownButton
                    title={<b>販売済みチケット一覧CSV出力</b>}
                    className="btn-info"
                    pullRight
                    id="dropdown-menu-list"
                  >
                    <MenuItem eventKey="0" onClick={downloadTicketsCsv}>
                      現在の状態を出力
                    </MenuItem>
                    {ticketSnapshots.map((snapshot, i) => (
                      <Fragment key={snapshot.id}>
                        <MenuItem divider />
                        <MenuItem
                          eventKey={i + 1}
                          onClick={(): void => {
                            onClickDownloadTicketsCsvbyS3(snapshot);
                          }}
                        >
                          {`${getMonth(
                            snapshot.snapshot_at
                          )}月時点での状態を出力`}
                        </MenuItem>
                      </Fragment>
                    ))}
                  </DropdownButton>
                </ButtonToolbar>
              }
              content={
                <TicketListTable
                  ticketContents={ticketContents}
                  statusToJp={statusToJp}
                  goTo={goTo}
                  setSelectedTicketContentContent={setSelectedTicketContentContent}
                  setShowStatusChangeModal={setShowStatusChangeModal}
                />
              }
            />
          </Col>
        </Row>

        <TicketStatusChangeModal
          ticketId={selectedTicketContent?.id}
          ticketStatus={selectedTicketContent?.status}
          statuses={
            isViewObjGroup()
              ? statuses.filter((status) => status !== "limited_open")
              : statuses
          }
          statusToJp={statusToJp}
          show={showStatusChangeModal}
          onHide={(): void => setShowStatusChangeModal(false)}
          changeStatus={changeTicketStatus}
        />
      </Grid>
    </div>
  );
};

export default TicketListView;
