import React, { memo, FC } from "react";
import {
  BarDatum,
  BarLegendProps,
  BarTooltipProps,
  ComputedBarDatumWithValue,
  ComputedDatum,
  ResponsiveBar,
} from "@nivo/bar";
import { ColorSchemeId, InheritedColorConfig } from "@nivo/colors";
import { AxisProps, GridValues } from "@nivo/axes";
import { Margin, PropertyAccessor } from "@nivo/core";
import { CircularProgress } from "@mui/material";
import GraphSummaries from "./parts/GraphSummaries";
import BarGraphTooltip from "./parts/BarGraphTooltip";
import { DataUnit } from "../../containers/entities/AnalyticsEntityContainer";
import BarGraphAxisBottomDateRenderTick from "./parts/BarGraphAxisBottomDateRenderTick";

type Props = {
  data: BarDatum[];
  indexBy: string;
  keys: string[];
  layout?: "vertical" | "horizontal";
  scheme?: ColorSchemeId;
  color?;
  maxValue?: number | "auto";
  minValue?: number | "auto";
  enableGridX?: boolean;
  gridXValues?: GridValues<string | number>;
  enableGridY?: boolean;
  gridYValues?: GridValues<string | number>;
  enableLabel?: boolean;
  margin?: Partial<Margin>;
  padding?: number;
  label?: PropertyAccessor<ComputedDatum<BarDatum>, string>;
  labelSkipWidth?: number;
  labelSkipHeight?: number;
  labelTextColor?: InheritedColorConfig<ComputedBarDatumWithValue<BarDatum>>;
  height?: string | number;
  totalKey?: string;
  dataUnit?: DataUnit;
  dataKeyToJp?;
  unit?: string;
  axisTop?: AxisProps | null;
  axisRight?: AxisProps | null;
  axisBottom?: AxisProps | null;
  axisLeft?: AxisProps | null;
  legends?: BarLegendProps[];
  onClick?: (
    datum: ComputedDatum<BarDatum> & {
      color: string;
    },
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ) => void;
  tooltip?: FC<BarTooltipProps<BarDatum>>;
  onMouseEnter?: (
    datum: ComputedDatum<BarDatum>,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ) => void;
  onMouseLeave?: (
    datum: ComputedDatum<BarDatum>,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ) => void;
  summaries?: {
    label: string;
    mainValue: number;
    mainValueUnit?: string;
    comparedLabel: string;
    subValue?: number;
    subValueUnit?: string;
    comparedValue: number;
    comparedValueUnit?: string;
    type: "positive" | "negative";
  }[];
  theme?;
};

const BarGraph: FC<Props> = ({
  data,
  indexBy,
  keys,
  layout,
  color,
  maxValue,
  minValue,
  scheme,
  enableGridX = false,
  gridXValues = 4,
  enableGridY = false,
  gridYValues = 4,
  enableLabel = false,
  margin = {
    top: 40,
    right: 10,
    bottom: 40,
    left: 50,
  },
  padding = 0.3,
  label,
  labelSkipWidth = 12,
  labelSkipHeight = 12,
  labelTextColor = { from: "color", modifiers: [["darker", 1.6]] },
  height = "100%",
  unit = "",
  totalKey,
  dataUnit,
  dataKeyToJp,
  axisTop = null,
  axisRight = null,
  axisBottom = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: 32,
    tickValues: 10,
    format: (value): string => value.toLocaleString(),
    renderTick:
      indexBy === "date"
        ? (props): JSX.Element => (
            <BarGraphAxisBottomDateRenderTick
              {...props}
              unit={dataUnit}
              data={data}
            />
          )
        : undefined,
  },
  axisLeft = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: -40,
    tickValues: 10,
    format: (value): string => value.toLocaleString(),
  },
  legends = [],
  onClick,
  tooltip = (props): JSX.Element => (
    <BarGraphTooltip
      keys={keys}
      data={props.data}
      color={color}
      indexBy={indexBy}
      unit={unit}
      totalKey={totalKey}
      dataUnit={dataUnit}
      dataKeyToJp={dataKeyToJp}
    />
  ),
  onMouseEnter,
  onMouseLeave,
  summaries = [],
  theme,
}) => {
  if (!data) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <>
      <div style={{ height: height }}>
        <ResponsiveBar
          data={data}
          layout={layout}
          keys={keys}
          indexBy={indexBy}
          maxValue={maxValue}
          minValue={minValue}
          margin={margin}
          enableGridX={enableGridX}
          gridXValues={gridXValues}
          enableGridY={enableGridY}
          gridYValues={gridYValues}
          padding={padding}
          colors={
            scheme
              ? { scheme: scheme }
              : ({ id, data: d }): string =>
                  color ? color[id] : d[`${id}_color`]
          }
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={axisTop}
          axisRight={axisRight}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          enableLabel={enableLabel}
          label={label}
          labelSkipWidth={labelSkipWidth}
          labelSkipHeight={labelSkipHeight}
          labelTextColor={labelTextColor}
          onClick={onClick}
          tooltip={tooltip}
          theme={theme}
          legends={legends}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </div>
      <GraphSummaries summaries={summaries} />
    </>
  );
};

export default memo(BarGraph);
