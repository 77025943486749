import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "../../../components/DialogTitle";
import { MenuStatus } from "../../../types/menu";

type Props = {
  defaultStatus: MenuStatus;
  open: boolean;
  disabled: boolean;
  onSubmit: (status: MenuStatus) => void;
  onClose: () => void;
};

const MenuStatusChangeModal = ({
  defaultStatus,
  open,
  disabled,
  onSubmit,
  onClose
}: Props): JSX.Element => {
  const [selectedStatus, setSelectedStatus] = useState<MenuStatus | null>(null);

  useEffect(() => {
    if (open && defaultStatus !== "draft") {
      setSelectedStatus(defaultStatus);
    }
  }, [open, defaultStatus]);

  const handleSubmit = () => {
    if (selectedStatus === null || selectedStatus === defaultStatus) {
      onClose();
    } else {
      onSubmit(selectedStatus);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle onClose={onClose}>
        どの状態に更新しますか？
      </DialogTitle>

      <DialogContent>
        <RadioGroup
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value as MenuStatus)}
        >
          <FormControlLabel
            value="open"
            control={<Radio disabled={disabled} />}
            label="公開"
          />

          <FormControlLabel
            value="close"
            control={<Radio disabled={disabled} />}
            label="非公開"
          />

          <FormControlLabel
            value="sold"
            control={<Radio disabled={disabled} />}
            label="売り切れ"
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="submit"
          disabled={selectedStatus === null || disabled}
          onClick={handleSubmit}
        >
          更新する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuStatusChangeModal;
