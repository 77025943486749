import React from "react";
import { styled } from "@mui/material/styles";
interface Props {
  title: string;
  contents: string[];
  status?: JSX.Element;
  button?: JSX.Element;
  goTo?: () => void;
  onIconHide?: boolean;
  image?: string;
  imageShape?: "default" | "circle";
  displayImageBlank?: boolean;
  imageBlankIcon?: string;
  memo?: string;
}

// うまく抽象化できてない。

const SmallTableBody: React.FC<Props> = ({
  image,
  displayImageBlank,
  imageBlankIcon,
  imageShape = "default",
  title,
  contents,
  status,
  button,
  goTo,
  onIconHide = true,
  memo,
}) => (
  <>
    <ImageTd
      image={image}
      displayImageBlank={displayImageBlank}
      imageShape={imageShape}
      imageBlankIcon={imageBlankIcon}
    />
    <td
      style={{ width: "auto", padding: 9, wordBreak: "break-word" }}
      onClick={goTo}
    >
      <div>
        <div style={{ fontWeight: "bold", fontSize: 14, marginBottom: 7 }}>
          {!onIconHide && (
            <i
              className="far fa-user-circle fa-lg"
              style={{ marginRight: 5 }}
            />
          )}
          {title}
        </div>
        {contents.map((content) => {
          if (!content) return null;
          return (
            <div key={content} style={{ color: "#525252" }}>
              {content}
            </div>
          );
        })}
        {memo && (
          <StyledMemo>{memo}</StyledMemo>
        )}
      </div>
    </td>

    {!!status && (
      <td
        style={{
          width: 68,
          textAlign: "center",
          fontSize: 10,
          color: "white",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        }}
        aria-hidden="true"
        onClick={goTo}
      >
        {status}
      </td>
    )}

    {!!button && (
      <td style={{ textAlign: "center", width: 30, padding: 5 }}>{button}</td>
    )}
  </>
);

const ImageTd = ({ image, imageShape, displayImageBlank, imageBlankIcon }) => {
  if (!image && !displayImageBlank) return null;

  if (imageShape === "default") {
    return (
      <td style={{ fontWeight: "bold", padding: 0, width: 48 }}>
        {!image && displayImageBlank && !imageBlankIcon && (
          <div
            style={{
              borderRadius: 2,
              width: 48,
              height: 30,
              background: "lightgray",
            }}
          />
        )}

        {!image && displayImageBlank && imageBlankIcon && (
          <i
            className={imageBlankIcon}
            style={{
              borderRadius: 2,
              width: 48,
              height: 30,
              fontSize: 40,
            }}
          />
        )}

        {image && (
          <img
            alt="画像"
            src={image}
            style={{
              borderRadius: 2,
              objectFit: "cover",
              width: 48,
              height: 30,
              background: "lightgray",
            }}
          />
        )}
      </td>
    );
  }
  if (imageShape === "circle") {
    return (
      <td style={{ fontWeight: "bold", padding: 0, width: 40 }}>
        {!image && displayImageBlank && !imageBlankIcon && (
          <div
            style={{
              borderRadius: 20,
              width: 40,
              height: 40,
              background: "lightgray",
            }}
          />
        )}

        {!image && displayImageBlank && imageBlankIcon && (
          <i
            className={imageBlankIcon}
            style={{
              borderRadius: 20,
              width: 40,
              height: 40,
              fontSize: 40,
            }}
          />
        )}

        {image && (
          <img
            alt="画像"
            src={image}
            style={{
              borderRadius: 20,
              width: 40,
              height: 40,
              background: "lightgray",
              objectFit: "cover",
            }}
          />
        )}
      </td>
    );
  }
  return null;
};

const StyledMemo = styled("div")({
  fontSize: 12,
  color: "#787878"
});

export default SmallTableBody;
