import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import Button from "../../../components/CustomButton/CustomButton";

interface Props {
  editMode: boolean;
  disabled: boolean;
  onClickDeleteButton: () => void;
  onClickBackButton: () => void;
  loading: boolean;
}

const NoticeTemplateButtonGroup: React.FC<Props> = ({
  editMode,
  disabled,
  onClickDeleteButton,
  onClickBackButton,
  loading,
}) => (
  <>
    <Row style={{ marginBottom: 30 }}>
      <Col md={5} xs={6} mdOffset={1}>
        <Button block fill onClick={onClickBackButton}>
          <b>もどる</b>
        </Button>
      </Col>
      <Col md={5} xs={6}>
        <LoadingButton
          type="submit"
          label={editMode ? "編集する" : "作成する"}
          loadingLabel={editMode ? "編集中..." : "作成中..."}
          color="info"
          block
          fill
          disabled={disabled}
          loading={loading}
        />
      </Col>
    </Row>

    {editMode && (
      <Row>
        <Col md={6} xs={8} mdOffset={3} xsOffset={2}>
          <LoadingButton
            label="このテンプレートを削除する"
            loadingLabel="削除中..."
            color="danger"
            block
            fill={false}
            simple
            loadingColor="red"
            onClick={onClickDeleteButton}
          />
        </Col>
      </Row>
    )}
  </>
);

export default memo(NoticeTemplateButtonGroup);
