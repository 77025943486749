import React from "react";
import { Prompt, withRouter } from "react-router";
import { Grid, Row, Col, FormControl, FormGroup, Form, HelpBlock, Modal } from "react-bootstrap";
import Button from "../components/CustomButton/CustomButton";
import LoadingButton from "../components/CustomButton/LoadingButton";
import FormInputs from "../components/FormInputs/FormInputs";
import FormLabel from "../components/FormInputs/FormLabel";
import { Card } from "../components/Card/Card";
import { combineContainer } from "../lib/general";
import { useOnceEffect } from "../lib/hooks";
import TenantEntityContainer from "../containers/entities/TenantEntityContainer";
import BankAccountFormViewContainer from "../containers/views/BankAccountFormViewContainer";

const BankAccountFormViewWrapper = (props) => (
  <BankAccountFormViewContainer.Provider>
    <BankAccountFormView {...props} />
  </BankAccountFormViewContainer.Provider>
);

export default withRouter(BankAccountFormViewWrapper);

const BankAccountFormView = (props) => {
  const container = combineContainer([
    TenantEntityContainer.useContainer(),
    BankAccountFormViewContainer.useContainer(),
  ]);
  const { location } = props;
  const { api, logic, state, setViewState } = container;
  const initialBankAccount = location.state ? location.state : logic.getInitialBankAccount();
  useOnceEffect(() => {
    setViewState.setForm(initialBankAccount);
  });

  const goTo = {
    selectBank: () => props.history.push("bank_account/select"),
    back: () => props.history.goBack(),
    config: () => props.history.push("/admin/config"),
  };

  const onClick = {
    getBankAccount: async () => {
      try {
        await api.getBankAccounts(state.keyword);
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      }
    },
    getBankBranch: async () => {
      try {
        await api.getBankBranch(state.branchKeyword, state.bankCode);
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      }
    },
    createBankAccount: async () => {
      // 変更したformの値だけリクエストすればいい？
      const formData = logic.getBankAccountFormData();
      if (!logic.validate(formData)) {
        props.displayNotification({
          level: "error",
          message: "値が正しく入力されていない可能性があります",
        });
        return;
      }
      try {
        await api.createBankAccount(formData);
        props.displayNotification({
          level: "success",
          message: "口座を登録しました",
        });
        setViewState.isDirty(false);
        goTo.config();
      } catch (error) {
        props.displayNotification({
          level: "error",
          message: error.message,
        });
      }
    },
    editBankAccount: async () => {
      // 変更したformの値だけリクエストすればいい？
      const formData = logic.getBankAccountFormData();
      if (!logic.validate(formData)) {
        props.displayNotification({
          level: "error",
          message: "値が正しく入力されていない可能性があります",
        });
        return;
      }
      try {
        await api.editBankAccount(formData);
        props.displayNotification({
          level: "success",
          message: "口座を編集しました",
        });
        setViewState.isDirty(false);
        goTo.config();
      } catch (error) {
        props.displayNotification({
          level: "error",
          message: error.message,
        });
      }
    },
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={10}>
            <Card
              title="口座登録"
              content={
                <form>
                  <FormLabel label="銀行名" />
                  <div style={{ marginBottom: 10 }}>
                    <p
                      style={{
                        display: "inline",
                        marginRight: 15,
                        fontSize: 14,
                      }}
                    >
                      {state.bankName}
                    </p>
                    <Button
                      bsStyle="info"
                      fill
                      // style={{ display: "block" }}
                      onClick={() => setViewState.viewMode(true)}
                    >
                      <b>銀行選択</b>
                    </Button>
                  </div>
                  <HelpBlock className="text-danger">{state.bankNameValidationMessage}</HelpBlock>

                  <Row>
                    <Col md={5}>
                      <FormGroup controlId="formControlsSelect">
                        <FormLabel label="支店名" />
                        <div style={{ display: "flex" }}>
                          <p style={{ marginRight: 20 }}>{state.bankBranchCode}</p>
                          <p>{state.bankBranchName}</p>
                        </div>
                      </FormGroup>
                      <HelpBlock className="text-danger">
                        {state.branchNameValidationMessage}
                      </HelpBlock>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <FormGroup controlId="formControlsSelect">
                        <FormLabel label="口座の種類" />
                        <FormControl
                          componentClass="select"
                          name="bankAccountType"
                          value={state.bankAccountType}
                          onChange={({ target }) => {
                            logic.handleFormChange(target.name, target.value);
                          }}
                        >
                          <option value=""> </option>
                          <option value="普通">普通</option>
                          <option value="当座">当座</option>
                        </FormControl>
                        <HelpBlock className="text-danger">
                          {state.bankAccountTypeValidationMessage}
                        </HelpBlock>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormInputs
                    properties={[
                      {
                        name: "bankAccountNumber",
                        label: "口座番号",
                        ncol: "col-md-5",
                        type: "",
                        bsClass: "form-control",
                        value: state.bankAccountNumber,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                        validationMessage: state.bankAccountNumberValidationMessage,
                      },
                      {
                        name: "bankAccountHolderName",
                        label: "口座名義（カタカナ）",
                        ncol: "col-md-5",
                        type: "text",
                        placeholder: "例： トイポタロウ",
                        bsClass: "form-control",
                        value: state.bankAccountHolderName,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                        validationMessage: state.holderNameValidationMessage,
                      },
                    ]}
                  />
                  <FormGroup>
                    <FormLabel label="最低入金額" />
                    <Row>
                      <Col style={{ display: "flex", paddingLeft: 15 }}>
                        <FormControl
                          type="text"
                          bsSize="small"
                          bsClass="form-control"
                          name="minimumTransferAmount"
                          style={{ width: 150 }}
                          value={state.minimumTransferAmount}
                          onChange={({ target }) =>
                            logic.handleFormChange(target.name, target.value)
                          }
                        />
                        <span style={{ marginLeft: 7, lineHeight: 3 }}>円</span>
                      </Col>
                    </Row>
                    <HelpBlock className="text-danger">
                      {state.minimumTransferAmountValidationMessage}
                    </HelpBlock>
                    <HelpBlock className="text-muted">
                      売上入金サイクルについては
                      <a
                        target="_blank"
                        href="https://toypo.notion.site/5c2f21e0628a4a74af83e714062ebd3b"
                        rel="noopener noreferrer"
                      >
                        こちら
                      </a>
                      をご覧ください。
                    </HelpBlock>
                  </FormGroup>
                </form>
              }
            />
          </Col>
        </Row>
        <Col md={10}>
          <Row style={{ marginBottom: 30 }}>
            <Col xs={6} md={5} mdOffset={1}>
              <Button fill block onClick={goTo.back}>
                <b>もどる</b>
              </Button>
            </Col>
            <Col xs={6} md={5}>
              {state.tenant ? (
                <LoadingButton
                  label="更新する"
                  loadingLabel="更新中..."
                  color="info"
                  block
                  fill
                  onClick={onClick.editBankAccount}
                  disabled={!state.isDirty}
                />
              ) : (
                <LoadingButton
                  label="登録する"
                  loadingLabel="更新中..."
                  color="info"
                  block
                  fill
                  onClick={onClick.createBankAccount}
                  disabled={!state.isDirty}
                />
              )}
              <BankAccountModal
                state={state}
                logic={logic}
                onClick={onClick}
                setViewState={setViewState}
              />
            </Col>
          </Row>
        </Col>
      </Grid>
      <Prompt
        when={state.isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </div>
  );
};

const BankAccountModal = (props) => {
  const { state, logic, onClick, setViewState } = props;
  return (
    <Modal show={state.viewMode} onHide={() => setViewState.viewMode(false)}>
      <Modal.Header closeButton>
        {state.doSelectBranch ? <b>支店を選択する</b> : <b>銀行を選択する</b>}
      </Modal.Header>
      <Modal.Body>
        {!state.doSelectBranch ? (
          <>
            <b>銀行を検索する</b>
            <Row style={{ marginTop: 10 }}>
              <Col xs={10} md={6}>
                <FormGroup>
                  <Form style={{ display: "flex" }}>
                    <Col xs={8} style={{ padding: 0 }}>
                      <FormControl
                        type="text"
                        name="keyword"
                        placeholder="キーワードを入力"
                        value={state.keyword}
                        onChange={({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        }}
                      />
                    </Col>
                    <Col xs={4} style={{ padding: 0, marginLeft: 10 }}>
                      <Button type="button" onClick={onClick.getBankAccount}>
                        <b>検索</b>
                      </Button>
                    </Col>
                  </Form>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {state.bankAccount.map((bankInfo) => (
                  <div
                    key={bankInfo.code}
                    style={{
                      cursor: "pointer",
                      verticalAlign: "middle",
                      border: "solid 1px #ccc",
                      margin: "0 10px 10px 0",
                      borderRadius: 3,
                      padding: "6px 10px",
                      backgroundColor: "#fff",
                      textAlign: "center",
                      fontSize: 14,
                      color: "#0076d7",
                    }}
                    onClick={() => {
                      setViewState.bankCode(bankInfo.code);
                      setViewState.bankName(bankInfo.name);
                      setViewState.doSelectBranch(true);
                    }}
                  >
                    {bankInfo.name}
                  </div>
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <b>{`${state.bankName}の支店を検索する`}</b>
            <Row style={{ marginTop: 10 }}>
              <Col xs={10} md={6}>
                <FormGroup>
                  <Form style={{ display: "flex" }}>
                    <Col xs={8} style={{ padding: 0 }}>
                      <FormControl
                        type="text"
                        name="branchKeyword"
                        placeholder="キーワードを入力"
                        value={state.branchKeyword}
                        onChange={({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        }}
                      />
                    </Col>
                    <Col xs={4} style={{ padding: 0, marginLeft: 10 }}>
                      <Button type="button" onClick={onClick.getBankBranch}>
                        <b>検索</b>
                      </Button>
                    </Col>
                  </Form>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {state.bankBranch.map((branchInfo) => (
                  <div
                    key={branchInfo.code}
                    style={{
                      cursor: "pointer",
                      verticalAlign: "middle",
                      border: "solid 1px #ccc",
                      margin: "0 10px 10px 0",
                      borderRadius: 3,
                      padding: "6px 10px",
                      backgroundColor: "#fff",
                      textAlign: "center",
                      fontSize: 14,
                      color: "#0076d7",
                    }}
                    onClick={() => {
                      setViewState.bankBranchCode(branchInfo.code);
                      setViewState.bankBranchName(branchInfo.name);
                      setViewState.viewMode(false);
                      setViewState.doSelectBranch(false);
                    }}
                  >
                    {branchInfo.name}
                  </div>
                ))}
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

// export default BankAccountModal;
