import React, { useEffect } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import OwnerEntityContainer from "../../containers/entities/OwnerEntityContainer";
import { Card } from "../../components/Card/Card";

const OwnerDetail: React.FC = () => {
  const ownerEntityContainer = OwnerEntityContainer.useContainer();

  const { owner } = ownerEntityContainer.state;
  const { getOwner, ownerTypesToJp } = ownerEntityContainer.logic;

  useEffect(() => {
    getOwner();
  }, [getOwner]);

  return (
    <Grid fluid className="content">
      <Row>
        <Col md={10}>
          <Card
            title="事業者設定"
            content={
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 17,
                  overflowWrap: "break-word",
                }}
              >
                <Row>
                  <Col xs={4}>
                    <p>事業者名</p>
                  </Col>
                  <Col xs={8}>{owner?.name || "未設定"}</Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <p>事業タイプ</p>
                  </Col>
                  <Col xs={8}>
                    {owner ? ownerTypesToJp(owner.owner_type) : "未設定"}
                  </Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <p>法人名</p>
                  </Col>
                  <Col xs={8}>{owner?.corp_name || "未設定"}</Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <p>メールアドレス</p>
                  </Col>
                  <Col xs={8}>{owner?.email || "未設定"}</Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <p>電話番号</p>
                  </Col>
                  <Col xs={8}>{owner?.phone_number || "未設定"}</Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <p>特定商取引法に関するURL</p>
                  </Col>
                  <Col xs={8}>{owner?.tokushoho_url || "未設定"}</Col>
                </Row>
              </div>
            }
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default OwnerDetail;
