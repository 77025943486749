import React from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { QuestionnaireAnswerNotificationTiming, QuestionnaireResource } from "../../../types/questionnaire";
import { StaffNotificationPolicyResource } from "../../../types/staffNotificationPolicy";
import { padding } from "../../../lib/general";
import { useApi } from "../../../lib/hooks";
import { useNotification } from "../../../providers/NotificationProvider";
import Section from "../../../components/Section";
import StaffNotificationPolicySelectorForm, { StaffNotificationPolicyForm } from "../../../components/StaffNotificationPolicySelectorForm";
import FormLabel from "../../../components/FormInputs/FormLabel";

type NotificationSettingForm = {
  enabled_report_notification: boolean;
  enabled_daily_report: boolean;
  enabled_weekly_report: boolean;
  enabled_monthly_report: boolean;
  report_notification_time: string;
  report_notification_policies: StaffNotificationPolicyForm[];
  enabled_answer_notification: boolean;
  answer_notification_timing: QuestionnaireAnswerNotificationTiming;
  enabled_notification_on_good_result: boolean;
  enabled_notification_on_bad_result: boolean;
  enabled_notification_on_review_exists: boolean;
  answer_notification_policies: StaffNotificationPolicyForm[];
};

type Props = {
  editable: boolean;
  questionnaire: QuestionnaireResource;
  reportNotificationPolicies: StaffNotificationPolicyResource[];
  answerNotificationPolicies: StaffNotificationPolicyResource[];
};

const NotificationSettings = ({
  editable,
  questionnaire,
  reportNotificationPolicies,
  answerNotificationPolicies
}: Props): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const { api, loading } = useApi();
  const defaultValues = generateDefaultValues(questionnaire, reportNotificationPolicies, answerNotificationPolicies);
  const { control, handleSubmit, errors, watch, reset } = useForm<NotificationSettingForm>({ defaultValues });
  const watchValues = watch();

  const submit = (values: NotificationSettingForm) => {
    const enabledReport = values.enabled_report_notification;
    const enabledAnswer = values.enabled_answer_notification;
    const params = {
      enabled_report_notification: enabledReport,
      enabled_daily_report: enabledReport && values.enabled_daily_report,
      enabled_weekly_report: enabledReport && values.enabled_weekly_report,
      enabled_monthly_report: enabledReport && values.enabled_monthly_report,
      report_notification_time: enabledReport ? values.report_notification_time : null,
      report_notification_policies: enabledReport ? values.report_notification_policies : [],
      enabled_answer_notification: enabledAnswer,
      answer_notification_timing: enabledAnswer ? values.answer_notification_timing : null,
      enabled_notification_on_good_result: enabledAnswer && values.enabled_notification_on_good_result,
      enabled_notification_on_bad_result: enabledAnswer && values.enabled_notification_on_bad_result,
      enabled_notification_on_review_exists: enabledAnswer && values.enabled_notification_on_review_exists,
      answer_notification_policies: enabledAnswer ? values.answer_notification_policies : []
    };
    const response = api.put(`/questionnaires/${questionnaire.id}`, params);
    if (!response) return;

    showSuccessNotification("通知設定を更新しました。");

    const resetValues = generateDefaultValues(
      params,
      params.report_notification_policies,
      params.answer_notification_policies
    );
    reset(resetValues);
  };

  return (
    <>
      {!editable && (
        <StyledAlert severity="info">
          このアンケートの通知設定は編集できません。編集したい場合は作成元の店舗から行ってください。
        </StyledAlert>
      )}

      <Section title="レポート通知設定">
        <Box p={1.5} bgcolor="#F2F2F2">
          <Controller
            control={control}
            name="enabled_report_notification"
            render={({ onChange, value }) => (
              <FormControlLabel
                label="レポート通知を受け取る"
                disabled={loading || !editable}
                sx={{ mb: 0 }}
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
              />
            )}
          />
        </Box>

        {watchValues.enabled_report_notification && (
          <>
            <Box mt={2}>
              <FormLabel label="タイプ" />
              <Box display="flex" flexDirection="column">
                <Controller
                  control={control}
                  name="enabled_daily_report"
                  rules={{
                    validate: (value) => value ||
                      watchValues.enabled_weekly_report ||
                      watchValues.enabled_monthly_report ||
                      "レポート通知を受け取る場合、日次/週次/月次から最低1つは選択してください。"
                  }}
                  render={({ onChange, value }) => (
                    <ColumnAlignedCheckbox
                      label="日次レポート"
                      note="1日のレポートを翌日に通知します"
                      disabled={loading || !editable}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="enabled_weekly_report"
                  render={({ onChange, value }) => (
                    <ColumnAlignedCheckbox
                      label="週次レポート"
                      note="1週間のレポートを翌週の月曜日に通知します"
                      disabled={loading || !editable}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="enabled_monthly_report"
                  render={({ onChange, value }) => (
                    <ColumnAlignedCheckbox
                      label="月次レポート"
                      note="1ヶ月間のレポートを翌月の1日に通知します"
                      disabled={loading || !editable}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />

                <FormHelperText>※回答がなかった場合は通知されません。</FormHelperText>

                {errors.enabled_daily_report?.message && (
                  <FormHelperText error>{errors.enabled_daily_report?.message}</FormHelperText>
                )}
              </Box>
            </Box>

            <Box mt={2}>
              <FormLabel label="通知時刻" />
              <Controller
                control={control}
                name="report_notification_time"
                render={(props) => (
                  <Select
                    {...props}
                    disabled={loading || !editable}
                  >
                    {[...Array(24)].map((_, i) => `${padding(i, 2)}:00`).map((time) => (
                      <MenuItem key={time} value={time}>{time}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>

            <Box mt={2}>
              <Controller
                control={control}
                name="report_notification_policies"
                rules={{ validate: (value) => value.length > 0 }}
                render={({ onChange, value }) => (
                  <StaffNotificationPolicySelectorForm
                    disabled={loading || !editable}
                    policies={value}
                    includeChildStoreStaffs
                    onChange={(policies) => onChange(policies)}
                    tableProps={{
                      sx: {
                        width: { mobile: "100vw", tablet: "100%" },
                        ml: { mobile: -2, tablet: 0 }
                      }
                    }}
                  />
                )}
              />

              {errors.report_notification_policies && (
                <FormHelperText error>通知を送るスタッフを選択してください。</FormHelperText>
              )}
            </Box>
          </>
        )}
      </Section>

      <Section title="回答ごとの通知設定">
        <Box p={1.5} bgcolor="#F2F2F2">
          <Controller
            control={control}
            name="enabled_answer_notification"
            render={({ onChange, value }) => (
              <FormControlLabel
                label="回答ごとの通知を受け取る"
                disabled={loading || !editable}
                sx={{ mb: 0 }}
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
              />
            )}
          />
        </Box>

        {watchValues.enabled_answer_notification && (
          <>
            <Box mt={2}>
              <FormLabel label="通知タイミング" />
              <Controller
                control={control}
                name="answer_notification_timing"
                render={(props) => (
                  <RadioGroup row {...props}>
                    <FormControlLabel
                      value="everytime"
                      control={<Radio disabled={loading || !editable} />}
                      label="回答があった時に毎回通知"
                    />
                    <FormControlLabel
                      value="specific"
                      control={<Radio disabled={loading || !editable} />}
                      label="指定の回答があった時のみ通知"
                    />
                  </RadioGroup>
                )}
              />
            </Box>

            {watchValues.answer_notification_timing === "specific" && (
              <>
                <Box display="flex" flexWrap="wrap">
                  <Controller
                    control={control}
                    name="enabled_notification_on_good_result"
                    rules={{
                      validate: (value) => value ||
                        watchValues.enabled_notification_on_bad_result ||
                        watchValues.enabled_notification_on_review_exists ||
                        "通知タイミングを最低1つは選択してください。"
                    }}
                    render={({ onChange, value }) => (
                      <ColumnAlignedCheckbox
                        label="総合満足度が高い時(星4以上)"
                        checked={value}
                        disabled={loading || !editable}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="enabled_notification_on_bad_result"
                    render={({ onChange, value }) => (
                      <ColumnAlignedCheckbox
                        label="総合満足度が低い時(星2以下)"
                        checked={value}
                        disabled={loading || !editable}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="enabled_notification_on_review_exists"
                    render={({ onChange, value }) => (
                      <ColumnAlignedCheckbox
                        label="コメントの記入がある時"
                        checked={value}
                        disabled={loading || !editable}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />
                </Box>

                {errors.enabled_notification_on_good_result?.message && (
                  <FormHelperText error>{errors.enabled_notification_on_good_result?.message}</FormHelperText>
                )}
              </>
            )}

            <Box mt={1}>
              <Controller
                control={control}
                name="answer_notification_policies"
                rules={{ validate: (value) => value.length > 0 }}
                render={({ onChange, value }) => (
                  <StaffNotificationPolicySelectorForm
                    disabled={loading || !editable}
                    policies={value}
                    includeChildStoreStaffs
                    onChange={(policies) => onChange(policies)}
                    tableProps={{
                      sx: {
                        width: { mobile: "100vw", tablet: "100%" },
                        ml: { mobile: -2, tablet: 0 }
                      }
                    }}
                  />
                )}
              />

              {errors.answer_notification_policies && (
                <FormHelperText error>通知を送るスタッフを選択してください。</FormHelperText>
              )}
            </Box>
          </>
        )}
      </Section>

      {editable && (
        <StyledActionContainer>
          <Button
            variant="outlined"
            color="cancel"
            disabled={loading}
            onClick={() => history.push("/admin/satisfaction_questionnaire")}
          >
            もどる
          </Button>

          <Button
            variant="contained"
            color="submit"
            disabled={loading}
            onClick={handleSubmit(submit)}
          >
            変更を保存する
          </Button>
        </StyledActionContainer>
      )}
    </>
  );
};

type QuestionnaireNotificationSetting = {
  enabled_daily_report: boolean;
  enabled_weekly_report: boolean;
  enabled_monthly_report: boolean;
  report_notification_time: string | null;
  answer_notification_timing: QuestionnaireAnswerNotificationTiming | null;
  enabled_notification_on_good_result: boolean;
  enabled_notification_on_bad_result: boolean;
  enabled_notification_on_review_exists: boolean;
};

const generateDefaultValues = (
  notificationSetting: QuestionnaireNotificationSetting,
  reportNotificationPolicies: (StaffNotificationPolicyResource | StaffNotificationPolicyForm)[],
  answerNotificationPolicies: (StaffNotificationPolicyResource | StaffNotificationPolicyForm)[]
): NotificationSettingForm => ({
  enabled_report_notification: notificationSetting.enabled_daily_report ||
    notificationSetting.enabled_weekly_report ||
    notificationSetting.enabled_monthly_report,
  enabled_daily_report: reportNotificationPolicies.length > 0 ? notificationSetting.enabled_daily_report : true,
  enabled_weekly_report: reportNotificationPolicies.length > 0 ? notificationSetting.enabled_weekly_report : true,
  enabled_monthly_report: reportNotificationPolicies.length > 0 ? notificationSetting.enabled_monthly_report : true,
  report_notification_time: notificationSetting.report_notification_time ?? "09:00",
  report_notification_policies: reportNotificationPolicies.length > 0 ? (
    reportNotificationPolicies.map((policy) => ({
      staff_id: policy.staff_id,
      enabled_email: policy.enabled_email,
      enabled_push_notification: policy.enabled_push_notification,
      include_child_store_staffs: policy.include_child_store_staffs
    }))
  ) : [{
    staff_id: null,
    enabled_email: true,
    enabled_push_notification: true,
    include_child_store_staffs: true
  }],
  enabled_answer_notification: notificationSetting.answer_notification_timing !== null,
  answer_notification_timing: notificationSetting.answer_notification_timing ?? "everytime",
  enabled_notification_on_good_result: notificationSetting.answer_notification_timing === "specific"
    ? notificationSetting.enabled_notification_on_good_result
    : true,
  enabled_notification_on_bad_result: notificationSetting.answer_notification_timing === "specific"
    ? notificationSetting.enabled_notification_on_bad_result
    : true,
  enabled_notification_on_review_exists: notificationSetting.answer_notification_timing === "specific"
    ? notificationSetting.enabled_notification_on_review_exists
    : true,
  answer_notification_policies: answerNotificationPolicies.length > 0 ? (
    answerNotificationPolicies.map((policy) => ({
      staff_id: policy.staff_id,
      enabled_email: policy.enabled_email,
      enabled_push_notification: policy.enabled_push_notification,
      include_child_store_staffs: policy.include_child_store_staffs
    }))
  ) : [{
    staff_id: null,
    enabled_email: true,
    enabled_push_notification: true,
    include_child_store_staffs: true
  }],
});

type ColumnAlignedCheckboxProps = CheckboxProps & {
  label: string;
  note?: string;
};

const ColumnAlignedCheckbox = ({
  label,
  note,
  ...otherProps
}: ColumnAlignedCheckboxProps): JSX.Element => {
  return (
    <FormControlLabel
      sx={{
        ml: -0.5,
        alignItems: { mobile: "flex-start", tablet: "center" },
        "& > .MuiCheckbox-root": {
          padding: { mobile: "0 4px 0 0", tablet: 0.5 }
        }
      }}
      label={
        <Box
          display="flex"
          sx={{
            alignItems: { mobile: "flex-start", tablet: "center" },
            flexDirection: { mobile: "column", tablet: "row" },
            gap: { mobile: 0.25, tablet: 1 }
          }}
        >
          {label}
          {note && (
            <Box fontSize={13} color="#787878">{note}</Box>
          )}
        </Box>
      }
      control={<Checkbox {...otherProps} />}
    />
  );
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    margin: theme.spacing(2, 0)
  },
  [theme.breakpoints.up("laptop")]: {
    margin: theme.spacing(2)
  }
}));

const StyledActionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  "& > button": {
    width: 300
  }
}));

export default NotificationSettings;
