import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const createMembersCardRank = async ({ params }) => {
  const CREATE_MEMBERS_CARD_RANK = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents/members_card_ranks`;

  const requestParams = {
    members_card_rank: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_MEMBERS_CARD_RANK,
    params: requestParams,
  });
  return response.data;
};

export const getMembersCardRank = async (id) => {
  const GET_MEMBERS_CARD_RANK = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents/members_card_ranks/${id}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_MEMBERS_CARD_RANK,
  });
  return response.data;
};

export const updateMembersCardRank = async ({ id, params }) => {
  const UPDATE_MEMBERS_CARD_RANK = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents/members_card_ranks/${id}`;

  const requestParams = {
    members_card_rank: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_MEMBERS_CARD_RANK,
    params: requestParams,
  });
  return response.data;
};
