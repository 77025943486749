import React from "react";
import OwnerDetail from "./OwnerDetail";
import OwnerFormView from "./OwnerFormView";
import { isOwner } from "../../lib/staff";

type OwnerSwitchViewProps = {
  displayNotification: ({ level, message: string }) => void;
};

const OwnerSwitchView: React.FC<OwnerSwitchViewProps> = (props) =>
  isOwner() ? <OwnerFormView {...props} /> : <OwnerDetail />;

export default OwnerSwitchView;
