import { loadViewObj } from "../viewObj";
import apiClient from "./apiClient";

export const getDiscountContents = async (urlParams: string) => {
  const GET_DISCOUNT_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/discount_contents${urlParams}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_DISCOUNT_CONTENTS,
  });
  return response;
};
