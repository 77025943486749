import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "@mui/material/styles";

type Props = ComponentPropsWithoutRef<"div"> & {
  published?: boolean;
  label: string;
};

const StatusBar = ({
  label,
  published = false,
  ...otherProps
}: Props): JSX.Element => (
  <StyledContent published={published} {...otherProps}>
    {label}
  </StyledContent>
);

const StyledContent = styled("div", {
  shouldForwardProp: (props) => props !== "published"
})<{ published: boolean }>(({ published }) => ({
  padding: "8px 12px 8px 12px",
  fontWeight: 700,
  fontSize: 15,
  color: "white",
  backgroundColor: published ? "#53B635" : "#A1A1AD"
}));

export default StatusBar;
