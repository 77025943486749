import React, { useState, useEffect, useCallback } from "react";
import { Pagination } from "@mui/material";
import { usePcSizeFlag } from "../../../lib/hooks";
import { useNotification } from "../../../providers/NotificationProvider";
import { Card } from "../../../components/Card/Card";

import EventList from "./EventList";
import EventModal from "./EventModal";

import EventEntityContainer, { EventItem } from "../../../containers/entities/EventEntityContainer";

const EventView = () => {
  const isPcOrTablet = usePcSizeFlag();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const eventContainer = EventEntityContainer.useContainer();
  const { events } = eventContainer.state;
  const { getEvents, deleteEvent } = eventContainer.logic;

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [activeModal, setActiveModal] = useState(false);
  const [eventData, setEventData] = useState<EventItem | null>(null);

  const getEventData = useCallback(
    (page = currentPage) => {
      getEvents({ page }).then((response) => {
        setTotalPage(Number(response.headers["total-pages"]));
      });
    },
    [currentPage, getEvents]
  );

  useEffect(() => {
    getEventData();
  }, [getEventData]);

  const onClickDeleteIcon = (id: number) => {
    if (!window.confirm("このイベントを削除します。よろしいですか？")) {
      return;
    }
    deleteEvent(id)
      .then(() => {
        setCurrentPage(1);
        getEventData();
        showSuccessNotification("イベントを削除しました。");
      })
      .catch((error) => {
        showErrorNotification(error.message);
      });
  };

  const onClickEditIcon = (event: EventItem) => {
    setEventData(event);
    setActiveModal(true);
  };

  return (
    <>
      <Card
        title="イベントスケジュール"
        content={
          <div>
            <div style={{ marginBottom: "10px" }}>
              <button
                type="button"
                style={{
                  color: "#1DC7EA",
                  fontWeight: "bold",
                  border: "none",
                  backgroundColor: "initial",
                }}
                onClick={(): void => setActiveModal(true)}
              >
                ＋ イベントを追加
              </button>
            </div>

            <div
              style={{
                width: isPcOrTablet ? "70%" : "100%",
                display: "grid",
                rowGap: "10px",
              }}
            >
              <EventList
                events={events}
                onClickEditIcon={onClickEditIcon}
                onClickDeleteIcon={onClickDeleteIcon}
              />

              <Pagination
                count={totalPage}
                page={currentPage}
                onChange={(_, value): void => setCurrentPage(value)}
                sx={{ fontSize: 24, color: "black", margin: "0 auto" }}
              />
            </div>
          </div>
        }
      />

      <EventModal
        getEventData={getEventData}
        eventData={eventData}
        setCurrentPage={setCurrentPage}
        show={activeModal}
        onHide={(): void => {
          setActiveModal(false);
          setEventData(null);
        }}
      />
    </>
  );
};

export default EventView;
