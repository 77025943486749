import React from "react";
import AdminMenu from "../components/ToypoApp/sidebar/AdminMenu";

const AdminMenuView = () => {
  return (
    <AdminMenu />
  );
};

export default AdminMenuView;
