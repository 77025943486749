import React, { FC } from "react";

type Props = {
  point;
  unit: string;
};

const LineGraphTooltip: FC<Props> = ({ point, unit }) => (
  <div
    style={{
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow: "rgb(0 0 0 / 25%) 0px 1px 2px",
      padding: "5px 9px",
    }}
  >
    <p style={{ marginBottom: 0, fontSize: 12 }}>{point.data.xFormatted}</p>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          display: "block",
          width: 12,
          height: 12,
          background: point.color,
          marginRight: 7,
        }}
      />
      <span>
        {point.data.y && point.data.y.toLocaleString()}{" "}
        <span style={{ fontSize: 8 }}>{unit}</span>
      </span>
    </div>
  </div>
);

export default LineGraphTooltip;
