import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const ImageLoading = () => {
  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "35%",
        left: "40%",
      }}
    >
      <CircularProgress />
    </div>
  );
};
export default ImageLoading;
