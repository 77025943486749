import React from "react";
import { SubscriptionContentResource } from "../../../../types/resource/subscriptionContent";
import DateOptionFormItem from "../parts/DateOptionFormItem";
import ValueRangeFormItem from "../parts/ValueRangeFormItem";
import SubscriptionContentFormItem from "../parts/SubscriptionContentFormItem";
import { Condition, ConditionDiff } from "../../type";

type Props = {
  condition: Condition,
  onChange: (diff: ConditionDiff) => void;
};

const SubscriptionUsedCountCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <>
      <SubscriptionContentFormItem
        condition={condition}
        onSelectSubscriptionContent={(subscriptionContent: SubscriptionContentResource) => {
          onChange({ instance_id: subscriptionContent.id, subscriptionContent });
        }}
      />

      <ValueRangeFormItem
        style={{ marginTop: 8 }}
        label="利用回数"
        unit="回"
        zeroLabel="利用していない"
        from={condition.value_from}
        to={condition.value_to}
        onChange={(value_from, value_to) => onChange({ value_from, value_to })}
      />

      <DateOptionFormItem
        style={{ marginTop: 8 }}
        condition={condition}
        label="利用日"
        noneLabel="すべての期間"
        onChange={(scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to) => {
          onChange({ scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to });
        }}
      />
    </>
  );
};

export default SubscriptionUsedCountCondition;
