import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";
import { downloadFile } from "../general";

export const getCharges = async () => {
  const GET_CHARGES = `/staff/stores/${loadViewObj()?.id}/charges`;

  const response = await apiClient({
    method: "GET",
    uri: GET_CHARGES,
  });
  return response;
};

export const downloadChargesCsv = async () => {
  const viewObj = loadViewObj();
  const GET_CHARGES_CSV = `/staff/stores/${viewObj?.id}/charges?format=csv`;

  await apiClient({
    method: "GET",
    uri: GET_CHARGES_CSV,
    responseType: "blob",
  }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const filename = decodeURI(contentDisposition.split("'").slice(-1)[0]);
    const blob = new Blob([response.data], { type: "text/csv" });
    downloadFile(blob, decodeURI(filename));
  });
};

export const getProducts = async () => {
  const GET_PRODUCTS = `/staff/stores/${loadViewObj()?.id}/products`;

  const response = await apiClient({
    method: "GET",
    uri: GET_PRODUCTS,
  });
  return response;
};

export const getTotalChargeAmount = async ({ from, to }) => {
  const GET_TOTAL_SALES = `/staff/stores/${
    loadViewObj()?.id
  }/charges/total?from=${from}&to=${to}`;

  const response = await apiClient({
    method: "GET",
    uri: GET_TOTAL_SALES,
  });
  return response;
};
