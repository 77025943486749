import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Icon,
  styled
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import { useNotification } from "../../../providers/NotificationProvider";
import { useApi } from "../../../lib/hooks";
import { OrderResponse } from "../../../types/order";

type Props = {
  orderId: number;
  open: boolean;
  onClose: () => void;
};

const CANCEL_REASONS = [
  "品切れ",
  "注文の受付を終了した",
  "店舗が混雑している",
  "店舗が休み",
  "注文者からのキャンセル",
  "その他"
];

const OrderCancelModal = ({
  orderId,
  open,
  onClose,
}: Props): JSX.Element => {
  const { showSuccessNotification } = useNotification();
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [otherReason, setOtherReason] = useState<string>("");
  const { api, response, loaded, reset, loading } = useApi<OrderResponse>();
  const updateApi = useApi();
  const order = response?.order ?? null;

  const fetchOrder = useCallback(() => {
    if (orderId) {
      api.get(`/orders/${orderId}`);
    }
  }, [api, orderId]);

  useEffect(() => {
    if (open) {
      fetchOrder();
    } else {
      reset();
      setSelectedReason(null);
      setOtherReason("");
    }
  }, [open, fetchOrder, reset]);

  const cancelOrder = async (reason: string) => {
    const response = await updateApi.api.put(`/orders/${orderId}`, { status: "cancel", uber_direct_undeliverable_reason_by_store: reason });
    if (response !== null) {
      showSuccessNotification('注文をキャンセルしました。');
      fetchOrder();
      onClose();
    }
  };

  const confirmAndCancel = () => {
    if (selectedReason) {
      if (selectedReason === "その他" && !otherReason) {
        alert("キャンセル理由を入力してください。");
        return;
      }
      const reason = selectedReason === "その他" ? otherReason : selectedReason;
      const message = [
        `「${reason}」の内容で注文をキャンセルしますか？`,
        "※お客様にキャンセルすることを説明した上で行ってください",
        "※また、キャンセルを取り消すことはできません"
      ].join("\n");

      if (window.confirm(message)) {
        cancelOrder(reason);
      }
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>
        注文キャンセル
      </DialogTitle>
      <DialogContent>
        {!loaded && (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress />
          </Box>
        )}
        {order && (
          <StyledContainer>
            <Typography variant="body1">キャンセル理由を選択してください。</Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2}>
              {CANCEL_REASONS.map((reason, index) => (
                <StyledButton
                  key={index}
                  selected={selectedReason === reason}
                  onClick={() => setSelectedReason(reason)}
                  disabled={loading}
                  isLoading={loading}
                >
                  {reason}
                </StyledButton>
              ))}
            </Box>
            {selectedReason === "その他" && (
              <TextField
                fullWidth
                variant="outlined"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                margin="normal"
              />
            )}
            <StyledNotesContainer>
              <StyledNotesTitle><StyledIcon><i className="ri-alert-line" color="" /></StyledIcon>注意事項</StyledNotesTitle>
              <Typography variant="body1">・注文キャンセルを取り消すことはできませんので、ご注意ください。</Typography>
            </StyledNotesContainer>
          </StyledContainer>
        )}
      </DialogContent>
      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          fullWidth style={{ background: "#BDBDBD" }}
          onClick={onClose}>
          閉じる
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="danger"
          onClick={confirmAndCancel}
          disabled={!selectedReason || loading}
        >
          注文をキャンセルする
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledButton = styled(Button)<{ selected: boolean; isLoading: boolean }>(({ selected, isLoading, theme }) => ({
  width: `calc((100% / 2) - ${theme.spacing(1)})`,
  border: "1px solid",
  borderColor: selected ? "#fff" : "#2F2F2F",
  backgroundColor: selected ? "#2F2F2F" : "#fff",
  color: selected ? "#fff" : "#2F2F2F",
  "&:hover": {
    backgroundColor: selected ? "#2F2F2F" : "#f5f5f5",
  },
  ...(isLoading && {
    backgroundColor: "#f5f5f5",
    color: "#fff",
    borderColor: "#f5f5f5",
  }),
}));

const StyledNotesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: "#FFF3F3",
  display: "flex",
  flexDirection: "column"
}));

const StyledNotesTitle = styled(Typography)(({ theme }) => ({
  color: "#555555",
  display: "flex",
  fontWeight: 600,
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  gap: theme.spacing(2),
  display: "flex",
  flexDirection: "column"
}));

const StyledIcon = styled(Icon)({
  color: "#E46453",
  display: "flex",
  alignItems: "center",
  fontWeight: "normal"
});

export default OrderCancelModal;
