/* eslint-disable import/prefer-default-export */
import apiClient from "../apiClient";

export const getTenant = async () => {
  const GET_TENANT = "/staff/owner/tenants";

  const response = await apiClient({
    method: "GET",
    uri: GET_TENANT,
  });
  return response.data;
};

export const createBankAccount = async ({ params }) => {
  const CREATE_BANK_ACCOUNT = "/staff/owner/tenants";

  const requestParams = {
    tenant: params,
  };
  const response = await apiClient({
    method: "POST",
    uri: CREATE_BANK_ACCOUNT,
    params: requestParams,
  });
  return response.data;
};

export const editBankAccount = async ({ params }) => {
  const EDIT_BANK_ACCOUNT = "/staff/owner/tenants";

  const requestParams = {
    tenant: params,
  };

  const response = await apiClient({
    method: "PUT",
    uri: EDIT_BANK_ACCOUNT,
    params: requestParams,
  });
  return response.data;
};

export const applyCreditCard = async () => {
  const APPLY_CREDIT_CARD =
    "/staff/owner/tenants/apply?return_to=https://toypo.me";

  const response = await apiClient({
    method: "POST",
    uri: APPLY_CREDIT_CARD,
  });
  return response.data;
};
