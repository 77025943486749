import { Japanese } from "flatpickr/dist/l10n/ja";
import React from "react";
import { Col, FormGroup, HelpBlock, Radio, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { useFormContext } from "react-hook-form";
import { Card } from "../../../components/Card/Card";
import FormLabel from "../../../components/FormInputs/FormLabel";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import {
  NoticeContent,
  NoticeStatus,
  NoticeStatusType,
} from "../../../containers/entities/NoticeEntityContainer";
import { altFormat, formatDate } from "../../../lib/general";
import ChangeIndicator from "../../../components/ChangeIndicator";
import { NoticeContentStatusLabels } from "../../../constants/noticeContent";

type Props = {
  editMode: boolean;
  notice: NoticeContent;
  onClickPublishNoticeButton: (NoticeStatus: NoticeStatusType) => Promise<void>;
  statusLabelBackgroundColor: (notice: NoticeContent) => string;
};

const PublishNoticeCard = ({
  editMode,
  notice,
  onClickPublishNoticeButton,
  statusLabelBackgroundColor,
  ...otherProps
}: Props): JSX.Element => {
  const { errors, setValue, watch } = useFormContext();
  const watchStatus = watch("notice.status") as NoticeStatusType;
  const watchPublishAt = watch("notice.publish_at");

  return (
    <Row {...otherProps}>
      <Col md={12}>
        <Card
          title="配信設定"
          rightContent={
            notice && (
              <p
                style={{
                  backgroundColor: statusLabelBackgroundColor(notice),
                  color: "white",
                  padding: "4px 15px",
                  border: 0,
                  borderRadius: 20,
                  fontWeight: "bold",
                  fontSize: 12,
                }}
              >
                {NoticeContentStatusLabels[notice.status]}
              </p>
            )
          }
          content={
            <>
              {notice?.status === NoticeStatus.Publish ? (
                <p>
                  配信日：
                  {formatDate(notice.publish_at)}
                </p>
              ) : (
                <>
                  <Row style={{ marginBottom: 10 }}>
                    <Col
                      data-cy="notice-form-view-notice-status"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: 10,
                        alignItems: "center"
                      }}
                    >
                      <Radio
                        key="notice-form-active-now"
                        checked={watchStatus === NoticeStatus.Publish}
                        onChange={() => setValue("notice.status", NoticeStatus.Publish, { shouldDirty: true })}
                        style={{ margin: 0 }}
                      >
                        <p>今すぐ配信</p>
                      </Radio>
                      <Radio
                        key="notice-form-active-reservation"
                        checked={watchStatus === NoticeStatus.Reservation}
                        onChange={() => setValue("notice.status", NoticeStatus.Reservation, { shouldDirty: true })}
                        style={{ margin: 0 }}
                      >
                        <p>配信予約</p>
                      </Radio>
                      <Radio
                        key="notice-form-active-none"
                        checked={watchStatus === NoticeStatus.Unpublished}
                        onChange={() => setValue("notice.status", NoticeStatus.Unpublished, { shouldDirty: true })}
                        style={{ margin: 0 }}
                      >
                        <p>まだ配信しない</p>
                      </Radio>
                      {editMode && (watchStatus !== notice?.status) && (
                        <ChangeIndicator />
                      )}
                    </Col>
                  </Row>
                  {watchStatus === NoticeStatus.Reservation && (
                    <Row>
                      <Col md={6} sm={9} xs={12}>
                        <FormGroup bsSize="large">
                          <FormLabel label="配信予定日" />
                          <Flatpickr
                            name="notice.publish_at"
                            data-enable-time
                            value={watchPublishAt}
                            options={{
                              altInput: true,
                              allowInput: true,
                              locale: Japanese,
                              altFormat: altFormat,
                              minuteIncrement: 0,
                            }}
                            onChange={(date) => {
                              setValue("notice.publish_at", date.toLocaleString().slice(0, -3), {
                                shouldDirty: false,
                              });
                            }}
                          />
                        </FormGroup>
                        <HelpBlock className="text-danger">
                          {errors?.notice?.publish_at?.message}
                        </HelpBlock>
                      </Col>
                    </Row>
                  )}
                  {editMode && (
                    <Row style={{ marginRight: 0 }}>
                      <Col>
                        <LoadingButton
                          label="変更する"
                          loadingLabel="変更中..."
                          color="info"
                          fill
                          simple
                          pullRight
                          loadingColor="red"
                          disabled={watchStatus === notice.status && watchPublishAt === notice.publish_at}
                          onClick={() => onClickPublishNoticeButton(watchStatus)}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          }
        />
      </Col>
    </Row>
  );
};

export default PublishNoticeCard;
