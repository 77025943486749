import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { useLoginContext } from "../providers/LoginContextProvider";

type Props = ButtonProps & {
  iconSize?: number;
};

// Admin権限を持っているスタッフのみ操作できるボタンは
// このコンポーネントを使う
const AdminShieldedButton = ({
  onClick,
  disabled = false,
  children,
  iconSize = 14,
  ...otherProps
}: Props) => {
  const { isAdminRole } = useLoginContext();

  return isAdminRole ? (
    <Button
      color="submit"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      endIcon={
        <i
          className="ri-shield-user-line"
          style={{ lineHeight: 1, fontSize: iconSize }}
        />
      }
      {...otherProps}
    >
      {children}
    </Button>
  ) : null
}

export default AdminShieldedButton;
