import { useState } from "react";
import { createContainer } from "unstated-next";
import { validateTextForm, validatePassword } from "../../lib/validation";

const usePasswordEditFormViewHook = () => {
  const [formNewPassword, setFormNewPassword] = useState("");
  const [formConfirmPassword, setFormConfirmPassword] = useState("");
  const [
    newPasswordValidationMessage,
    setNewPasswordValidationMessage,
  ] = useState("");
  const [
    confirmPasswordValidationMessage,
    setConfirmPasswordValidationMessage,
  ] = useState("");
  const [
    comparePasswordValidationMessage,
    setComparePasswordValidationMessage,
  ] = useState("");

  const state = {
    formNewPassword,
    formConfirmPassword,
    newPasswordValidationMessage,
    confirmPasswordValidationMessage,
    comparePasswordValidationMessage,
  };

  const setViewState = {
    initialize: (values) => {
      const { newPassword, confirmPassword } = values;
      setFormNewPassword(newPassword);
      setFormConfirmPassword(confirmPassword);
      setConfirmPasswordValidationMessage("");
      setNewPasswordValidationMessage("");
      setComparePasswordValidationMessage("");
    },
    formNewPassword: setFormNewPassword,
    formConfirmPassword: setFormConfirmPassword,
    newPasswordValidationMessage: setNewPasswordValidationMessage,
    confirmPasswordValidationMessage: setConfirmPasswordValidationMessage,
    comparePasswordValidationMessage: setComparePasswordValidationMessage,
  };

  const logic = {
    getInitialPasswordReset: () => {
      return {
        newPassword: "",
        confirmPassword: "",
      };
    },
    handleFormChange: (name, value) => {
      setViewState[name](value);
    },
    validate: (password) => {
      const resultNewPassword = validateTextForm(password.newPassword);
      setViewState.newPasswordValidationMessage(resultNewPassword.message);

      const resultConfirmPassword = validateTextForm(password.confirmPassword);
      setViewState.confirmPasswordValidationMessage(
        resultConfirmPassword.message
      );

      const resultComparePassword = validatePassword(password);
      setViewState.comparePasswordValidationMessage(
        resultComparePassword.message
      );

      return (
        resultNewPassword.validation &&
        resultConfirmPassword.validation &&
        resultComparePassword.validation
      );
    },
  };
  return { state, setViewState, logic };
};

export default createContainer(usePasswordEditFormViewHook);
