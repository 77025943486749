import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import ConditionFormItem from "./ConditionFormItem";
import TimeRangeFormItem from "./TimeRangeFormItem";
import DateRangeForm from "./DateRangeForm";
import { Condition, ScopeType, TimeType, RelativeScopeType } from "../../type";
import { hasScope } from "../../utils";
import ValueRangeFormItem from "./ValueRangeFormItem";

type Props = {
  condition: Condition,
  label: React.ReactNode;
  noneLabel?: string;
  onChange: (
    scopeFrom: ScopeType,
    scopeTo: ScopeType,
    relativeBeginningScope: RelativeScopeType,
    relativeEndScope: RelativeScopeType,
    timeFrom: TimeType,
    timeTo: TimeType
  ) => void;
  [key:string]: any;
};

const DateOptionFormItem = ({
  condition,
  label,
  noneLabel,
  onChange,
  ...otherProps
}: Props): JSX.Element => {
  const [option, setOption] = useState<string>(() => {
    const { scope_from, scope_to, relative_beginning_scope, relative_end_scope } = condition;

    if (relative_beginning_scope === 0 && relative_end_scope === 0) {
      return "today";
    }
    if (relative_beginning_scope === 1 && relative_end_scope === 1) {
      return "yesterday";
    }
    if (relative_beginning_scope === 7 && relative_end_scope === null) {
      return "last_7_days";
    }
    if (relative_beginning_scope === 30 && relative_end_scope === null) {
      return "last_30_days";
    }

    const hasScope = scope_from !== null || scope_to != null;
    const hasRelativeScope = relative_beginning_scope != null || relative_end_scope != null;
    return hasScope ? "absolute" : (hasRelativeScope ? "relative" : "none");
  });

  const handleChange = (e: any) => {
    const value = e.target.value;
    let newRelativeBeginningScope: RelativeScopeType;
    let newRelativeEndScope: RelativeScopeType;
    let newTimeFrom = condition.time_from;
    let newTimeTo = condition.time_to;

    switch (value) {
      case "today":
        newRelativeBeginningScope = 0;
        newRelativeEndScope = 0;
        break;

      case "yesterday":
        newRelativeBeginningScope = 1;
        newRelativeEndScope = 1;
        break;

      case "last_7_days":
        newRelativeBeginningScope = 7;
        newRelativeEndScope = null;
        break;

      case "last_30_days":
        newRelativeBeginningScope = 30;
        newRelativeEndScope = null;
        break;

      default:
        newRelativeBeginningScope = null;
        newRelativeEndScope = null;
        newTimeFrom = null;
        newTimeTo = null;
        break;
    }

    setOption(value);
    onChange(null, null, newRelativeBeginningScope, newRelativeEndScope, newTimeFrom, newTimeTo);
  };

  return (
    <>
      <ConditionFormItem label={label} {...otherProps}>
        <FormControl
          componentClass="select"
          value={option}
          onChange={handleChange}
        >
          {Options.map((option) => (
            <option key={option} value={option}>
              {option === "none" && (noneLabel || OptionLabel[option])}
              {option !== "none" && OptionLabel[option]}
            </option>
          ))}
        </FormControl>
      </ConditionFormItem>

      {option === "absolute" && (
        <ConditionFormItem style={{ marginTop: 8 }}>
          <DateRangeForm
            from={condition.scope_from}
            to={condition.scope_to}
            onChange={(from, to) => onChange(from, to, null, null, condition.time_from, condition.time_to)}
          />
        </ConditionFormItem>
      )}

      {option === "relative" && (
        <ValueRangeFormItem
          style={{ marginTop: 8 }}
          label="経過日数"
          unit="日"
          from={String(condition.relative_end_scope)}
          to={String(condition.relative_beginning_scope)}
          onChange={(from, to) => {
            onChange(
              null,
              null,
              to === null ? null : Number(to),
              from === null ? null : Number(from),
              condition.time_from,
              condition.time_to
            )
          }}
        />
      )}

      {hasScope(condition) && (
        <TimeRangeFormItem
          style={{ marginTop: 8 }}
          from={condition.time_from}
          to={condition.time_to}
          onChange={(time_from, time_to) => onChange(
            condition.scope_from,
            condition.scope_to,
            condition.relative_beginning_scope,
            condition.relative_end_scope,
            time_from,
            time_to
          )}
        />
      )}
    </>
  );
};

const Options = [
  "none",
  "today",
  "yesterday",
  "last_7_days",
  "last_30_days",
  "absolute",
  "relative"
] as const;
const OptionLabel = {
  none: "指定しない",
  today: "今日",
  yesterday: "昨日",
  last_7_days: "7日以内",
  last_30_days: "30日以内",
  absolute: "期間を指定",
  relative: "経過日数を指定"
} as const;

export default DateOptionFormItem;
