import React from "react";
import { Radio } from "react-bootstrap";
import styled from "@emotion/styled";
import { SubscriptionContentResource } from "../../../../types/resource/subscriptionContent";
import { Condition, ConditionDiff } from "../../type";
import ConditionFormItem from "../parts/ConditionFormItem";
import DateOptionFormItem from "../parts/DateOptionFormItem";
import SubscriptionContentFormItem from "../parts/SubscriptionContentFormItem";

type Props = {
  condition: Condition,
  onChange: (diff: ConditionDiff) => void;
};

const SubscriptionPurchasedCountCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <>
      <SubscriptionContentFormItem
        condition={condition}
        onSelectSubscriptionContent={(subscriptionContent: SubscriptionContentResource) => {
          onChange({ instance_id: subscriptionContent.id, subscriptionContent });
        }}
      />

      <ConditionFormItem label="購入" style={{ marginTop: 8 }}>
        <StyledRadioContainer>
          <Radio
            name={"subscription-purchase-radioGroup-" + condition.key}
            checked={condition.value_from === "1"}
            onChange={() => onChange({ value_from: "1" })}
          >
            購入したことがある
          </Radio>

          <Radio
            name={"subscription--purchaseradioGroup-" + condition.key}
            checked={condition.value_from === "0"}
            onChange={() => onChange({
              value_from: "0",
              scope_from: null,
              scope_to: null,
              relative_beginning_scope: null,
              relative_end_scope: null
            })}
          >
            購入したことがない
          </Radio>
        </StyledRadioContainer>
      </ConditionFormItem>

      {condition.value_from !== "0" && (
        <DateOptionFormItem
          style={{ marginTop: 8 }}
          condition={condition}
          label="購入日"
          noneLabel="すべての期間"
          onChange={(scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to) => {
            onChange({ scope_from, scope_to, relative_beginning_scope, relative_end_scope, time_from, time_to });
          }}
        />
      )}
    </>
  );
};

const StyledRadioContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  paddingBottom: 8,
  "& .radio": {
    margin: "0 8px 0 0",
    padding: 0
  },
  "& .radio label": {
    paddingLeft: 24
  },
  "& .radio label input": {
    marginTop: 6
  }
});

export default SubscriptionPurchasedCountCondition;
