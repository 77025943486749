import React, { FC } from "react";
import moment from "moment";
import { BarDatum } from "@nivo/bar";
import { DataUnit } from "../../../containers/entities/AnalyticsEntityContainer";

type Props = {
  keys;
  data: BarDatum;
  indexBy: string;
  color?;
  unit?: string;
  totalKey?: string | null;
  dataUnit?: DataUnit;
  dataKeyToJp?;
};

const BarGraphTooltip: FC<Props> = ({
  keys,
  data,
  indexBy,
  color,
  unit,
  totalKey = null,
  dataUnit,
  dataKeyToJp,
}) => (
  <div
    style={{
      backgroundColor: "#fff",
      borderRadius: 2,
      boxShadow: "rgb(0 0 0 / 25%) 0px 1px 2px",
      padding: "5px 9px",
    }}
  >
    <p style={{ marginBottom: 5, fontSize: 12 }}>
      {indexBy === "date" ? (
        <>
          {dataUnit === "monthly" && moment(data[indexBy]).format("YYYY年M月")}
          {dataUnit === "weekly" &&
            moment(data[indexBy]).format("YYYY年M月D日週")}
          {(dataUnit === "daily" || !dataUnit) &&
            moment(data[indexBy]).format("YYYY年M月D日")}
        </>
      ) : (
        data[indexBy]
      )}
    </p>

    {keys.map((key) => (
      <div
        key={key}
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 12,
          marginBottom: 3,
        }}
      >
        <span
          style={{
            display: "block",
            width: 12,
            height: 12,
            background: color ? color[key] : data[`${key}_color`],
            marginRight: 7,
          }}
        />
        <>
          {keys.length > 1 ? (
            <>
              <span style={{ marginRight: 5 }}>
                {dataKeyToJp ? dataKeyToJp(key) : key} :{" "}
                {data[key] ? data[key].toLocaleString() : 0}
              </span>
              <span style={{ fontSize: 8 }}>{unit}</span>
            </>
          ) : (
            <>
              <span>{data[key] || 0}</span>
              <span style={{ fontSize: 8 }}>{unit}</span>
            </>
          )}
        </>
      </div>
    ))}

    {!!totalKey && (
      <>
        <span
          style={{
            marginRight: 7,
            fontSize: 12,
          }}
        >
          合計
        </span>
        <span style={{ fontSize: 12, marginRight: 5 }}>
          {data[totalKey].toLocaleString()}
        </span>
        <span style={{ fontSize: 8 }}>{unit}</span>
      </>
    )}
  </div>
);

export default BarGraphTooltip;
