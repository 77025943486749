import React, { useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import styled from "@emotion/styled";
import { FormControl, Radio } from "react-bootstrap";
import ConditionFormItem from "./ConditionFormItem";
import { ValueType } from "../../type";

type RangeClass = "manual" | "zero";
type Props = {
  label: string;
  unit: string;
  zeroLabel?: string;
  from: ValueType;
  to: ValueType;
  onChange: (from: ValueType, to: ValueType) => void;
  style?: React.CSSProperties;
};

const MIN_VALUE = 1;

const ValueRangeFormItem = ({
  label,
  unit,
  zeroLabel,
  from,
  to,
  onChange,
  style
}: Props): JSX.Element => {
  const radioNameRef = useRef(uuid());
  const showRangeClass = Boolean(zeroLabel);
  const isValueZero = from === "0" && to === "0";
  const defaultRangeClass = isValueZero && showRangeClass ? "zero" : "manual";
  const [rangeClass, setRangeClass] = useState<RangeClass>(defaultRangeClass);

  const handleChangeFrom = (value: string, adjust = false) => {
    if (value === "") {
      onChange(null, to);
    } else if (!adjust) {
      onChange(value, to);
    } else {
      // たとえばtoに"3"が入力されている状態でfromに"5"とか入力されたら
      // 5日以上〜3日以内のように矛盾してしまうので
      // 上記のようなfrom > toの状態になったらfromとtoを逆転させる
      const newFrom = Math.max(MIN_VALUE, Number(value));
      if (newFrom > (to === null ? Number.MAX_SAFE_INTEGER : Number(to))) {
        onChange(to, String(newFrom));
      } else {
        onChange(String(newFrom), to);
      }
    }
  };

  const handleChangeTo = (value: string, adjust = false) => {
    if (value === "") {
      onChange(from, null);
    } else if (!adjust) {
      onChange(from, value);
    } else {
      // handleChangeFromと逆のことをします。
      // たとえばfromに"5"が入力されている状態でtoに"3"とか入力されたら
      // 5日以上〜3日以内のように矛盾してしまうので
      // 上記のようなto < fromの状態になったらtoとfromを逆転させる
      const newTo = Math.min(Number(value), Number.MAX_SAFE_INTEGER);
      if (newTo < (from === null ? MIN_VALUE : Number(from))) {
        onChange(String(newTo), from);
      } else {
        onChange(from, String(newTo));
      }
    }
  };

  const handleSelectManual = (e: any) => {
    if (e.target.checked) {
      setRangeClass("manual");
      onChange("1", null);
    }
  };

  const handleSelectZero = (e: any) => {
    if (e.target.checked) {
      setRangeClass("zero");
      onChange("0", "0");
    }
  };

  return (
    <div style={style}>
      {showRangeClass && (
        <ConditionFormItem label={label}>
          <StyledRadioContainer>
            <Radio
              name={`radioGroup${radioNameRef.current}`}
              checked={rangeClass === "manual"}
              onChange={handleSelectManual}
            >
              1{unit}以上
            </Radio>

            <Radio
              name={`radioGroup${radioNameRef.current}`}
              checked={rangeClass === "zero"}
              onChange={handleSelectZero}
            >
              {zeroLabel}
            </Radio>
          </StyledRadioContainer>
        </ConditionFormItem>
      )}

      {rangeClass === "manual" && (
        <ConditionFormItem label={showRangeClass ? "" : label}>
          <StyledFlexContainer>
            <FormControl
              type="number"
              bsSize="small"
              min={MIN_VALUE}
              value={from === null ? "" : from}
              onChange={(e: any) => handleChangeFrom(e.target.value)}
              onBlur={(e: any) => handleChangeFrom(e.target.value, true)}
              style={{ flex: 1, marginRight: 4 }}
            />
            <div>{unit}</div>

            <StyledSpacer>〜</StyledSpacer>

            <FormControl
              type="number"
              bsSize="small"
              min={MIN_VALUE}
              value={to === null ? "" : to}
              onChange={(e: any) => handleChangeTo(e.target.value)}
              onBlur={(e: any) => handleChangeTo(e.target.value, true)}
              style={{ flex: 1 , marginRight: 4 }}
            />
            <div>{unit}</div>
          </StyledFlexContainer>
        </ConditionFormItem>
      )}
    </div>
  );
};

const StyledFlexContainer = styled.div({
  display: "flex",
  alignItems: "center"
});
const StyledSpacer = styled.div({
  padding: "0 8px"
});

const StyledRadioContainer = styled.div({
  display: "flex",
  alignItems: "center",
  paddingBottom: 8,
  "& .radio": {
    margin: "0 8px 0 0",
    padding: 0
  },
  "& .radio label": {
    paddingLeft: 24
  },
  "& .radio label input": {
    marginTop: 6
  }
});

export default ValueRangeFormItem;
