import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useApi } from "../../../lib/hooks";

type Props = {
  userIds: number[];
};

const TargetUserList = ({
  userIds
}: Props): JSX.Element => {
  const { api, response } = useApi();
  const selectedUsers = response?.customers || [];

  useEffect(() => {
    if (userIds.length > 0) {
      api.get("/users", { user_ids: userIds.join(",") });
    }
  }, [api, userIds]);

  return userIds.length === 0 ? (
    <div>
      指定のユーザーに配信したい場合は
      <Link to="/admin/customers">ユーザー管理一覧</Link>
      から対象のユーザーを選択してください。
    </div>
  ) : (
    <StyledUsernameContainer>
      {selectedUsers.map((selectedUser) => (
        <StyledUsernameLabel key={selectedUser.id}>
          {selectedUser.name}
        </StyledUsernameLabel>
      ))}
    </StyledUsernameContainer>
  );
};

const StyledUsernameContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap'
});

const StyledUsernameLabel = styled('div')({
  color: "rgba(0,0,0,.68)",
  backgroundColor: "rgb(230, 230, 230)",
  fontWeight: "bold",
  borderRadius: 5,
  marginBottom: 8,
  marginRight: 8,
  fontSize: 16,
  padding: 8
});

export default TargetUserList;
