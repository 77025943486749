/* eslint @typescript-eslint/camelcase: 0 */
/* eslint camelcase: 0 */
import { AxiosResponse } from "axios";
import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

import {
  NoticeTemplateInterface,
  NoticeTemplateParamsInterface,
} from "../../containers/entities/NoticeTemplateEntityContainer";

interface Message {
  message: string;
}

export const getNoticeTemplates = async (): Promise<
  NoticeTemplateInterface[]
> => {
  const GET_NOTICE_TEMPLATES = `/staff/stores/${
    loadViewObj()?.id
  }/notice_content_templates`;

  const response: AxiosResponse<NoticeTemplateInterface[]> = await apiClient({
    method: "GET",
    uri: GET_NOTICE_TEMPLATES,
  });
  return response.data;
};

export const createNoticeTamplate = async ({
  params,
}: {
  params: NoticeTemplateParamsInterface;
}): Promise<NoticeTemplateInterface> => {
  const CREATE_NOTICE_TEMPLATE = `/staff/stores/${
    loadViewObj()?.id
  }/notice_content_templates`;
  const requestParams = {
    notice_content_template: params,
  };

  const response: AxiosResponse<NoticeTemplateInterface> = await apiClient({
    method: "POST",
    uri: CREATE_NOTICE_TEMPLATE,
    params: requestParams,
  });
  return response.data;
};

export const updateNoticeTemplate = async ({
  template_notice_id,
  params,
}: {
  template_notice_id: number;
  params: Partial<NoticeTemplateParamsInterface>;
}): Promise<NoticeTemplateInterface> => {
  const UPDATE_NOTICE_TEMPLATE = `/staff/stores/${
    loadViewObj()?.id
  }/notice_content_templates/${template_notice_id}`;

  const requestParams = {
    notice_content_template: params,
  };

  const response: AxiosResponse<NoticeTemplateInterface> = await apiClient({
    method: "PATCH",
    uri: UPDATE_NOTICE_TEMPLATE,
    params: requestParams,
  });
  return response.data;
};

export const deleteNoticeTemplate = async ({
  template_notice_id,
}: {
  template_notice_id: number;
}): Promise<Message> => {
  const DELETE_NOTICE_TEMPLATE = `/staff/stores/${
    loadViewObj()?.id
  }/notice_content_templates/${template_notice_id}`;

  const response: AxiosResponse<Message> = await apiClient({
    method: "DELETE",
    uri: DELETE_NOTICE_TEMPLATE,
  });
  return response.data;
};
