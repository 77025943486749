import React from "react";
import { HelpBlock, FormGroup, ControlLabel } from "react-bootstrap";
import CopyToClipBoard from "react-copy-to-clipboard";
import CustomButton from "../../../components/CustomButton/CustomButton";

interface Props {
  storeLink: string | null;
  displayNotification: ({
    level,
    message,
  }: {
    level: "success" | "error" | "warning";
    message: string;
  }) => void;
}

const StoreUrlSection: React.FC<Props> = ({ storeLink, displayNotification }) => (
  <FormGroup bsSize="large" style={{ marginBottom: 30 }}>
    <ControlLabel
      style={{
        display: "block",
        fontWeight: 700,
        fontSize: 18,
      }}
    >
      URL
    </ControlLabel>
    <HelpBlock className="text-muted" style={{ fontSize: 11, marginBottom: 25 }}>
      <span style={{ display: "block" }}>
        アプリダウンロード用URLをSNSでシェアして、アプリユーザーを増やしましょう。
      </span>
      <span style={{ display: "block" }}>
        お客様はURLからトイポアプリをダウンロードすると、お店のアプリユーザーになった状態から始まります。
      </span>
      <span style={{ display: "block" }}>
        ※PCでこちらのURLを開くとhttps://toypo.meに遷移します。
      </span>
    </HelpBlock>

    <span style={{ fontSize: 16 }}>{storeLink || "https://toypo.me"}</span>
    <CopyToClipBoard
      text={storeLink}
      onCopy={(): void => {
        displayNotification({
          level: "success",
          message: "コピーしました。",
        });
      }}
    >
      <CustomButton style={{ marginLeft: 30 }} bsStyle="info">
        <b>URLをコピー</b>
      </CustomButton>
    </CopyToClipBoard>

    {/* <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div style={{ display: "flex", marginRight: 30 }}>
        <img
          src={instagram}
          alt="instagram"
          style={{ width: 32, height: 32, marginRight: 10 }}
        />
        <i
          className="fab fa-twitter-square"
          style={{
            fontSize: 32,
            color: "#1DA1F2",
            marginRight: 10,
          }}
        />
        <i
          className="fab fa-facebook-square"
          style={{
            fontSize: 32,
            color: "#3B5998",
            marginRight: 10,
          }}
        />
      </div>
    </div> */}
  </FormGroup>
);

export default StoreUrlSection;
