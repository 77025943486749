import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "./DialogTitle";
import FormLabel from "./FormInputs/FormLabel";
import { useLoginContext } from "../providers/LoginContextProvider";
import { useApi } from "../lib/hooks";
import { CopyStoresResponse } from "../types/coupon";
import { StoreTypeLabels } from "../constants/store";

const COPY_CONDITIONS_URL = "https://toypo.notion.site/591141fefb9544de8d1fbce4ea8676de";

type Props = {
  copiedContentName: string;
  contentId: number;
  open: boolean;
  onClose: () => void;
  onClickCopy: (contentId: number, storeIds: number[]) => void;
  disabled: boolean;
};
const ORDER_BY_STORE_TYPE = {
  "owner_group": 1,
  "store_group": 2,
  "child": 3
};

const ContentCopyDialog = ({
  copiedContentName,
  contentId,
  open,
  onClose,
  onClickCopy,
  disabled,
}: Props): JSX.Element => {
  const { currentStore, currentStaff } = useLoginContext();
  const [copyTarget, setCopyTarget] = useState<"self" | "other">("self");
  const [copyStoreIds, setCopyStoreIds] = useState<number[]>([currentStore.id]);
  const copyStoresApi = useApi<CopyStoresResponse>();

  const copyStores = copyStoresApi.response?.stores ?? [];
  const reasonsUnavailableCopyToAllStore = copyStoresApi.response?.uncopyable_reasons_to_all_stores ?? [];
  const hasMultipleStores = currentStaff.stores.length > 1;

  useEffect(() => {
    if (open) {
      setCopyTarget("self");
      setCopyStoreIds([currentStore.id]);
    }
  }, [open, currentStore]);

  useEffect(() => {
    if (open && hasMultipleStores) {
      copyStoresApi.api.get(`/coupon_contents/${contentId}/copy_stores`);
    }
  }, [open, hasMultipleStores, copyStoresApi.api, contentId]);


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>クーポン複製</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <FormLabel label="複製するクーポン" />
          {copiedContentName}
        </Box>

        {hasMultipleStores && (
          <>
            <Box mb={2}>
              <FormLabel label="複製先" />
              <RadioGroup
                row
                value={copyTarget}
                onChange={(e) => {
                  if (e.target.value === "self") {
                    setCopyTarget("self");
                    setCopyStoreIds([currentStore.id]);
                  } else {
                    setCopyTarget("other");
                    setCopyStoreIds([]);
                  }
                }}
              >
                <FormControlLabel
                  value="self"
                  control={<Radio disabled={disabled} />}
                  label={currentStore.name}
                />
                <FormControlLabel
                  disabled={!copyStoresApi.response?.copyable_to_other_stores}
                  value="other"
                  control={<Radio disabled={disabled} />}
                  label="他の店舗・グループ"
                />
              </RadioGroup>

              {reasonsUnavailableCopyToAllStore.length > 0 && (
                <StyledNoteContainer>
                  {reasonsUnavailableCopyToAllStore.map((reason) => (
                    <StyledNote key={reason}>
                      <div>※ {reason}</div>
                    </StyledNote>
                  ))}
                  <StyledNote>
                    ※ 複製の制限に関しての詳細は
                    <a href={COPY_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">こちら</a>
                  </StyledNote>
                </StyledNoteContainer>
              )}
            </Box>

            {copyTarget === "other" && (
              <Box mb={2}>
                <FormLabel label="複製店舗" attachment="複数選択可" />
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  disabled={disabled}
                  options={
                    copyStores
                      .sort((a, b) => ORDER_BY_STORE_TYPE[a.store.store_type] - ORDER_BY_STORE_TYPE[b.store.store_type])
                      .map((store) => ({
                        ...store,
                        type: StoreTypeLabels[store.store.store_type],
                      }))
                  }
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.store.name}
                  getOptionDisabled={(option) => !option.copyable}
                  isOptionEqualToValue={(option, value) => option.store.id === value.store.id}
                  onChange={(_, values) => setCopyStoreIds(values.map((value) => value.store.id))}
                  renderInput={(params) => (
                    <TextField {...params} label={null} />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: "4px 8px" }}>
                      <Checkbox checked={selected} />
                      <Box>
                        <Box>{option.store.name}</Box>
                        {!!option.uncopyable_reasons?.length && (
                          <StyledNoteContainer>
                            {option.uncopyable_reasons.map((reason) => (
                              <StyledNote key={reason}>
                                <div>※ {reason}</div>
                              </StyledNote>
                            ))}
                            <StyledNote>
                              <a href={COPY_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">詳しくはこちら</a>
                            </StyledNote>
                          </StyledNoteContainer>
                        )}
                      </Box>
                    </li>
                  )}
                />
                <StyledNoteContainer>
                  <StyledNote>※ 権限をもつ店舗・グループに複製できます。</StyledNote>
                </StyledNoteContainer>
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          fullWidth
          color="cancel"
          variant="outlined"
          onClick={onClose}
        >
          閉じる
        </Button>
        <Button
          fullWidth
          color="submit"
          variant="contained"
          disabled={disabled || copyStoreIds.length === 0}
          onClick={() => onClickCopy(contentId, copyStoreIds)}
        >
          複製する
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const StyledNoteContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledNote = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing(0.5),
}));

export default ContentCopyDialog;
