export const RestaurantSatisfactionSectionLabel = {
  taste_of_food: "味",
  amount_of_food: "量",
  variety_of_food: "品揃え",
  customer_service: "接客",
  speed_of_delivery: "提供スピード",
  price_of_food: "価格",
  cleanliness_of_store: "清潔感",
  ambience_of_store: "雰囲気"
} as const;

export const RatingLabels = {
  5: "満足",
  4: "やや満足",
  3: "ふつう",
  2: "やや不満",
  1: "不満"
} as const;

export const RestaurantSatisfactionSections = Object.keys(RestaurantSatisfactionSectionLabel);
