import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { FormChangedAlertModal, Alert } from "../../../components/Modal/FormChangedAlertModal";
import { useBooleanState } from "../../../lib/hooks";
import { QuestionnaireStatus } from "../../../types/questionnaire";

type Props = {
  status: QuestionnaireStatus;
  disabled: boolean;
  onChangeStatus: (status: QuestionnaireStatus) => void;
};

const QuestionnairePublishSettingCard = ({
  status,
  disabled,
  onChangeStatus
}: Props): JSX.Element => {
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const [newStatus, setNewStatus] = useState<QuestionnaireStatus>("open");

  const openModalFor = (s: QuestionnaireStatus) => {
    setNewStatus(s);
    openModal();
  };

  const handleSubmit = () => {
    onChangeStatus(newStatus);
    closeModal();
  };

  return (
    <>
      <div className="card">
        <div className="header">
          <h4 className="title">公開設定</h4>
        </div>
        <div className="content" style={{ display: "flex", justifyContent: "flex-end" }}>
          {status === "close" && (
            <CustomButton
              bsStyle="info"
              fill
              disabled={disabled}
              onClick={() => openModalFor("open")}
            >
              <strong>顧客満足度アンケートを公開</strong>
            </CustomButton>
          )}

          {status === "open" && (
            <CustomButton
              bsStyle="danger"
              disabled={disabled}
              onClick={() => openModalFor("close")}
            >
              <strong>顧客満足度アンケートの公開を停止</strong>
            </CustomButton>
          )}
        </div>
      </div>

      <FormChangedAlertModal
        show={isModalOpen}
        title={newStatus === "close"
          ? "顧客満足度調査アンケートの公開を停止"
          : "顧客満足度調査アンケートを公開"
        }
        submitButtonLabel={newStatus === "close" ? "公開を停止する" : "公開する"}
        onSubmit={handleSubmit}
        onCancel={closeModal}
        disabled={disabled}
        needAgreement={newStatus === "close"}
        displayStatus={newStatus === "close" ? "danger" : "info"}
      >
        {newStatus === "open" && (
          <>顧客満足度調査アンケートを公開してよろしいですか？</>
        )}

        {newStatus === "close" && (
          <>
            顧客満足度調査アンケートの公開を停止してよろしいですか？
            <Alert severity="danger">
              公開を停止すると、ユーザーがアンケートに回答できなくなります。
            </Alert>
          </>
        )}
      </FormChangedAlertModal>
    </>
  );
};

export default QuestionnairePublishSettingCard;
