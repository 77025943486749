import React from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { ServiceStatusColors, ServiceStatusLabels } from "../../../constants/service";
import { ServiceStatusType } from "../../../types/service";

type ServiceType = {
  id: number;
  user_id: number;
  used_count: number;
  daily_used_count: number;
  expiration_at: string | null;
  monthly_used_count: number;
  service_content_id: number;
  status: ServiceStatusType;
};
type ServiceContentType = {
  id: number;
  store_id: number;
  members_card_rank_id: number;
  title: string;
  body: string;
  status: "draft" | "open" | "close";
  image_url: string | null;
  service_type: "discount" | "free" | "other" | null;
  service_value: number | null;
  expiration_month: number | null;
  usable_count: number | null;
  daily_usable_count: number | null;
  monthly_usable_count: number | null;
  created_at: string;
};

type ServiceResponseType = {
  service: ServiceType;
  service_content: ServiceContentType;
};

type Props = {
  services: Array<ServiceResponseType>;
};

const ContentListSmall = ({ services }: Props): JSX.Element => (
  <Table striped hover>
    <tbody>
      {services.map(({ service, service_content }) => (
        <tr key={service.id}>
          <td>
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "flex-start",
              }}
            >
              {service_content.image_url && (
                <img
                  key={service_content.id}
                  alt="サービス画像"
                  src={service_content.image_url}
                  width={48}
                  height={30}
                  style={{
                    borderRadius: 2,
                    objectFit: "cover",
                    marginRight: 8,
                  }}
                />
              )}

              <div>
                <div style={{ marginBottom: 4 }}>
                  <div style={{ fontWeight: 700 }}>
                    {service_content.title}
                  </div>
                </div>

                <div style={{ color: "#7d7d7d" }}>
                  利用状況{" "}
                  <span style={{ color: ServiceStatusColors[service.status], fontWeight: 700 }}>
                    {ServiceStatusLabels[service.status]}
                  </span>
                </div>
                <div style={{ color: "#7d7d7d" }}>
                  配信日時{" "}
                  {moment(service_content.created_at).format(
                    "YYYY/M/D(dddd) HH:mm"
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default ContentListSmall;
