import maleAvatar from "../assets/img/male-avatar.png";
import femaleAvatar from "../assets/img/female-avatar.png";
import otherAvatar from "../assets/img/other-avatar.png";
import initialAvatar from "../assets/img/avatar.png";

export const DEFAULT_AVATAR = {
  male: maleAvatar,
  female: femaleAvatar,
  other: otherAvatar,
  null: initialAvatar,
};

export const DAYS = [...Array(31).keys()].map((i) => ++i);

export const HOUR = [...Array(24).keys()];

export const HOURS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];


