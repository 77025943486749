import React, { useState } from "react";
import { ImageCard } from "./ImageInput";
import CustomButton from "../CustomButton/CustomButton";
import QrNameChangeModal from "../Modal/QrNameChangeModal";
import { downloadFile } from "../../lib/general";

interface Props {
  id: string;
  name: string;
  href: string;
  image: string;
  altMessage: string;
  filename: string;
  onClickDeleteButton: (e) => Promise<void>;
  updateQrcodeName: ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) => Promise<any>;
  label?: JSX.Element;
  width?: number;
  height?: number;
  displayNotification: ({
    level,
    message,
  }: {
    level: "success" | "error" | "warning";
    message: string;
  }) => void;
  getStore: () => Promise<void>;
}

const QrImageDisplay: React.FC<Props> = ({
  href,
  image,
  altMessage,
  width = 120,
  height = 120,
  filename,
  onClickDeleteButton,
  updateQrcodeName,
  id,
  name,
  label,
  displayNotification,
  getStore,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        width: 300,
        backgroundColor: "#f6f6f7",
        padding: 15,
        borderRadius: 5,
        marginRight: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div style={{ width: "80%", fontSize: 18 }}>{name}</div>
        <button
          type="button"
          onClick={(): void => setShow(true)}
          style={{
            border: "none",
            backgroundColor: "initial",
          }}
        >
          <i
            className="fas fa-pencil-alt"
            style={{ width: "10%", fontSize: 20 }}
          />
        </button>
        <button
          type="button"
          onClick={onClickDeleteButton}
          style={{
            border: "none",
            backgroundColor: "initial",
          }}
        >
          <i
            className="fas fa-trash-alt"
            style={{ width: "10%", fontSize: 20 }}
          />
        </button>
      </div>

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div>
          <a href={href} target="_blank" rel="noopener noreferrer">
            <ImageCard
              src={image}
              altMessage={altMessage}
              width={width}
              height={height}
            />
          </a>
          {!!label && (
            <div
              style={{
                width: width,
                fontSize: 12,
                textAlign: "left",
                marginBottom: 5,
                marginLeft: 10,
              }}
            >
              {label}
            </div>
          )}
        </div>

        <CustomButton
          bsStyle="info"
          fill
          block
          style={{ padding: "4px", marginLeft: 10 }}
          onClick={(): void => {
            fetch(image, { cache: "reload" })
              .then((response) => response.blob())
              .then((blob) => {
                downloadFile(blob, filename);
              });
          }}
        >
          <b>保存する</b>
        </CustomButton>
      </div>

      <QrNameChangeModal
        show={show}
        onHide={(): void => setShow(false)}
        id={id}
        qrName={name}
        updateQrcodeName={updateQrcodeName}
        displayNotification={displayNotification}
        getStore={getStore}
      />
    </div>
  );
};

export default QrImageDisplay;
