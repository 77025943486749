import React from "react";
import { FormControl, Col, HelpBlock } from "react-bootstrap";
import FormLabel from "./FormLabel";
import FormGroup from "./FormGroup";

// !!! IMPORTANT !!!
// このコンポーネントは古いreact-bootstrapを利用しているためDeprecatedにします。
// 新しくsrc/components/MultipleSelectFormを利用するようにしましょう。
type Props = {
  label?: string;
  labelAttachment?: string;
  name?: string;
  options;
  selectedItems?;
  width?;
  // 型定義が無いし、コールバックの命名に沿ってないのでaddItem / deleteItemはDEPRECATEDにします。
  // 代わりにonAddItem / onDeleteItemを使おう！
  addItem?;
  deleteItem?;
  onAddItem?: (value: string) => void;
  onDeleteItem?: (value: string) => void;
  validationMessage?: string;
  disabled?: boolean;
  [key:string]: any;
};

const MultipleSelectorForm: React.FC<Props> = ({
  label = "",
  labelAttachment = "",
  name = "",
  options,
  selectedItems = [],
  width = 300,
  addItem,
  deleteItem,
  onAddItem,
  onDeleteItem,
  validationMessage = "",
  disabled = false,
  ...otherProps
}) => (
  <FormGroup {...otherProps}>
    {label && <FormLabel label={label} attachment={labelAttachment} />}
    <Col style={{ display: "flex" }}>
      <FormControl
        name={name}
        componentClass="select"
        style={{ width }}
        onChange={(e: any) => {
          if (addItem) {
            addItem(e);
          }
          if (onAddItem) {
            onAddItem(e.target.value);
          }
        }}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={`${name}-option-${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormControl>
    </Col>
    {selectedItems.length > 0 && (
      <Col style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
        {selectedItems.map((itemValue) => {
          const selected = options.find((option) => option.value === itemValue);
          if (!selected) {
            return null;
          }
          return (
            <div
              key={`${name}-selected-item-${selected.value}`}
              style={{
                color: "rgba(0,0,0,.68)",
                backgroundColor: "rgb(230, 230, 230)",
                fontWeight: "bold",
                borderRadius: 5,
                marginBottom: 8,
                marginRight: 8,
                fontSize: 16,
                padding: 5,
              }}
            >
              {selected.label}
              {!disabled && (
                <i
                  className="fas fa-times-circle"
                  style={{ marginLeft: 5, fontSize: 14 }}
                  onClick={() => {
                    if (deleteItem) {
                      deleteItem(selected.value);
                    }
                    if (onDeleteItem) {
                      onDeleteItem(selected.value);
                    }
                  }}
                />
              )}
            </div>
          );
        })}
      </Col>
    )}

    {Boolean(validationMessage) && (
      <HelpBlock className="text-danger">{validationMessage}</HelpBlock>
    )}
  </FormGroup>
);

export default MultipleSelectorForm;
