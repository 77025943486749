import apiClient from "../apiClient";
import { downloadFile } from "../../general";

export const downloadChargesCsv = async () => {
  const GET_CHARGES_CSV = `/staff/owner/charges?format=csv`;
  await apiClient({
    method: "GET",
    uri: GET_CHARGES_CSV,
    responseType: "blob",
  }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const filename = decodeURI(contentDisposition.split("'").slice(-1)[0]);
    const blob = new Blob([response.data], { type: "text/csv" });
    downloadFile(blob, decodeURI(filename));
  });
};
