import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Prompt } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import OutlinedInput from "@mui/material/OutlinedInput";
import { OrderFeatureResource } from "../../../types/order";
import Section from "../../../components/Section";
import FormLabel from "../../../components/FormInputs/FormLabel";
import OrderDateForm, { OrderDateFormType } from "./OrderDateForm";

export type CommonSettingsFormType = {
  appeal_sentence: string;
  today_order_enabled: boolean;
  can_select_receive_time: boolean;
  reserve_order_enabled: boolean;
  reserve_start_day: number;
  reserve_end_day: number;
  order_dates: OrderDateFormType[];
};

type Props = {
  orderFeature: OrderFeatureResource
  disabled: boolean;
  onSubmit: (params: Partial<OrderFeatureResource>) => void;
};

const CommonSettingsForm = ({
  orderFeature,
  disabled,
  onSubmit
}: Props): JSX.Element => {
  const defaultValues = generateDefaultValues(orderFeature);
  const { control, watch, handleSubmit, errors, formState, reset } = useForm<CommonSettingsFormType>({ defaultValues });
  const { isDirty } = formState;
  const watchValues = watch();

  useEffect(() => {
    reset(generateDefaultValues(orderFeature));
  }, [reset, orderFeature]);

  return (
    <>
      <Section title="基本設定">
        <FormLabel label="アピール文" />
        <Controller
          control={control}
          name="appeal_sentence"
          render={({ onChange, value }) => (
            <OutlinedInput
              fullWidth
              multiline
              value={value}
              disabled={disabled}
              rows={5}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        />
      </Section>

      <Section title="受付時間・受取時間">
        <Box>
          <Controller
            control={control}
            name="today_order_enabled"
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                sx={{ color: value ? undefined : "#aaa" }}
                label="当日の注文を受け付ける"
              />
            )}
          />
        </Box>

        <Box>
          <Controller
            control={control}
            name="can_select_receive_time"
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                sx={{ color: value ? undefined : "#aaa" }}
                label="ユーザーが受取時間を指定できるようにする"
              />
            )}
          />

          <Tooltip title="受取時間を指定できるようにすると、ユーザーが商品を注文する際に、曜日毎に設定された受取時間内で受取時間を自分で指定することができるようになります。">
            <i className="ri-question-fill" style={{ fontSize: 20, color: "#787878" }} />
          </Tooltip>
        </Box>

        <Box>
          <Controller
            control={control}
            name="reserve_order_enabled"
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                sx={{ color: value ? undefined : "#aaa" }}
                label="翌日以降の予約注文を受け付ける"
              />
            )}
          />

          <Tooltip title="予約注文を受け付けるようにすると、ユーザーが商品を注文する際に、予約可能期間内で受取日を自分で指定することができるようになります。">
            <i className="ri-question-fill" style={{ fontSize: 20, color: "#787878" }} />
          </Tooltip>
        </Box>

        <Box mb={3} color={!watchValues.reserve_order_enabled ? "#aaa" : undefined}>
          <FormLabel
            label="予約可能期間"
            style={{ color: !watchValues.reserve_order_enabled ? "#aaa" : undefined }}
          />
          <Box display="flex" alignItems="center" gap={1}>
            <Controller
              control={control}
              name="reserve_start_day"
              rules={{
                required: "予約可能期間を入力してください。",
                validate: (v) => v < watchValues.reserve_end_day || "予約可能期間の開始と終了が逆です。"
              }}
              render={({ onChange, value }) => (
                <OutlinedInput
                  type={watchValues.reserve_order_enabled ? "number" : "text"}
                  value={watchValues.reserve_order_enabled ? value : "---"}
                  disabled={!watchValues.reserve_order_enabled || disabled}
                  sx={{ width: 100 }}
                  onChange={(e) => onChange(Math.max(1, Number(e.target.value || 1)))}
                />
              )}
            />
            日後から
            <Controller
              control={control}
              name="reserve_end_day"
              rules={{ required: "予約可能期間を入力してください。" }}
              render={({ onChange, value }) => (
                <OutlinedInput
                  type={watchValues.reserve_order_enabled ? "number" : "text"}
                  value={watchValues.reserve_order_enabled ? value : "---"}
                  disabled={!watchValues.reserve_order_enabled || disabled}
                  sx={{ width: 100 }}
                  onChange={(e) => onChange(Math.max(1, Number(e.target.value || 1)))}
                />
              )}
            />
            日後まで
          </Box>

          {(errors.reserve_start_day?.message || errors.reserve_end_day?.message) && (
            <FormHelperText error>
              {errors.reserve_start_day?.message || errors.reserve_end_day?.message}
            </FormHelperText>
          )}
        </Box>

        <FormLabel label="受付時間・受取時間の設定" />
        <Controller
          control={control}
          name="order_dates"
          render={({ onChange, value }) => (
            value.map((orderDate: OrderDateFormType, i: number) => (
              <React.Fragment key={orderDate.week}>
                <OrderDateForm
                  openingTimeDisabled={disabled}
                  receivedTimeDisabled={!watchValues.can_select_receive_time || disabled}
                  orderDate={{
                    ...orderDate,
                    receive_from_time: watchValues.can_select_receive_time ? orderDate.receive_from_time : "",
                    receive_to_time: watchValues.can_select_receive_time ? orderDate.receive_to_time : ""
                  }}
                  onChange={(params) => {
                    value[i] = { ...value[i], ...params };
                    onChange([...value]);
                  }}
                />

                <Divider sx={{ my: 2 }} />
              </React.Fragment>
            ))
          )}
        />
      </Section>

      <Box display="flex" justifyContent="center" mb={5}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          sx={{ width: 300 }}
          onClick={handleSubmit(onSubmit)}
        >
          変更を保存する
        </Button>
      </Box>

      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </>
  );
};

const generateDefaultValues = (orderFeature: OrderFeatureResource): CommonSettingsFormType => ({
  appeal_sentence: orderFeature.appeal_sentence || "",
  today_order_enabled: orderFeature.today_order_enabled,
  can_select_receive_time: orderFeature.can_select_receive_time,
  reserve_order_enabled: orderFeature.reserve_order_enabled,
  reserve_start_day: orderFeature.reserve_start_day,
  reserve_end_day: orderFeature.reserve_end_day,
  order_dates: orderFeature.order_dates.map((orderDate) => ({
    week: orderDate.week,
    active: orderDate.active,
    from_time: orderDate.from_time,
    to_time: orderDate.to_time,
    receive_from_time: orderDate.receive_from_time,
    receive_to_time: orderDate.receive_to_time
  }))
});

export default CommonSettingsForm;
