import { useCallback, useState, Dispatch, SetStateAction } from "react";
import { createContainer } from "unstated-next";
import { ownerApi, ownerOwnerApi } from "../../lib/api";
import { isOwner } from "../../lib/staff";
import { OwnerResponse, OwnerType, JpPlanType } from "../../types/owner";

type CardApplicationStatusType = "passed" | "notApply" | "inReview";

const ownerTypes: OwnerType[] = ["corporation", "individual"];
const ownerTypesToJpObj = {
  corporation: "法人",
  individual: "個人",
};
export const ownerPlanToJp = {
  test: "テストプラン" as JpPlanType,
  free: "フリープラン" as JpPlanType,
  light: "ライトプラン" as JpPlanType,
  standard: "スタンダードプラン" as JpPlanType,
  pro: "プロプラン" as JpPlanType,
  enterprise: "エンタープライズプラン" as JpPlanType,
  custom: "カスタマイズプラン" as JpPlanType,
};
// アップグレード・ダウングレードかを判断するために使用（値が大きくなる場合はアップグレード・値が小さくなる場合はダウングレード）
// エンタープライズプラン・カスタマイズプランはどうする？
const ownerJpPlanToGrade = {
  テストプラン: 1,
  フリープラン: 2,
  ライトプラン: 3,
  スタンダードプラン: 4,
  プロプラン: 5,
  エンタープライズプラン: 6,
  カスタマイズプラン: 7,
};

type OwnerEntity = {
  state: {
    owner: OwnerResponse | null,
    plan: JpPlanType,
    cardApplicationStatus: CardApplicationStatusType,
    cardStatus: CardApplicationStatusType[],
    planFee: number | null,
    customerLimit: number | null
  },
  constant: {
    ownerTypes: OwnerType[]
  },
  logic: {
    setAll: (owner: OwnerResponse) => void,
    setOwner: Dispatch<SetStateAction<OwnerResponse | null>>,
    getOwner: () => Promise<OwnerResponse>,
    createOwner: (params: OwnerResponse) => Promise<OwnerResponse>,
    updateOwner: (params: OwnerResponse) => Promise<OwnerResponse>,
    deleteOwner: () => Promise<void>,
    ownerTypesToJp: (data: OwnerType) => string,
    ownerJpPlanToGrade: (ownerJpPlan: JpPlanType | "") => number
  }
};

const useOwnerEntityHook = (): OwnerEntity => {
  const [owner, setOwner] = useState<OwnerResponse | null>(null);
  const [plan, setPlan] = useState<JpPlanType>("");
  const [planFee, setPlanFee] = useState<number | null>(null);
  const [customerLimit, setCustomerLimit] = useState<number | null>(null);
  const [cardStatus, setCardStatus] = useState<CardApplicationStatusType[]>([]);
  const [cardApplicationStatus, setCardApplicationStatus] =
    useState<CardApplicationStatusType>("notApply");

  const setCard = useCallback((newOwner) => {
    if (newOwner?.reviewed_brands?.length) {
      setCardStatus(newOwner.reviewed_brands.map((data) => data.status));
      const passedStatus = newOwner.reviewed_brands.some(
        (value) => value.status === "passed"
      );
      setCardApplicationStatus(passedStatus ? "passed" : "inReview");
    } else {
      setCardStatus([]);
      setCardApplicationStatus("notApply");
    }
  }, []);

  const setAll = useCallback((owner) => {
    setOwner(owner);
    setPlan(ownerPlanToJp[owner?.plan]);
    setPlanFee(owner?.plan_fee);
    setCustomerLimit(owner?.plan_customer_limit);
    setCard(owner);
  }, [setCard]);

  return {
    state: {
      owner,
      plan,
      cardApplicationStatus,
      cardStatus,
      planFee,
      customerLimit,
    },
    constant: { ownerTypes },
    logic: {
      setOwner,
      setAll,
      getOwner: useCallback(async (): Promise<OwnerResponse> => {
        let newOwner: OwnerResponse;
        //  TODO:条件分岐必要？
        if (isOwner()) {
          newOwner = await ownerOwnerApi.getOwner();
        } else {
          newOwner = await ownerApi.getOwner();
        }
        setAll(newOwner);
        return newOwner;
      }, [setAll]),
      createOwner: async (params: OwnerResponse): Promise<OwnerResponse> => {
        const newOwner: OwnerResponse = await ownerOwnerApi.createOwner({
          params,
        });
        setOwner(newOwner);
        return newOwner;
      },
      updateOwner: async (params: OwnerResponse): Promise<OwnerResponse> => {
        const newOwner: OwnerResponse = await ownerOwnerApi.updateOwner({
          params,
        });
        setOwner(newOwner);
        return newOwner;
      },
      deleteOwner: async () => {
        await ownerOwnerApi.deleteOwner();
        setOwner(null);
      },
      ownerTypesToJp: (data: OwnerType) => ownerTypesToJpObj[data],
      ownerJpPlanToGrade: (ownerJpPlan: JpPlanType | ""): number =>
        ownerJpPlanToGrade[ownerJpPlan],
    },
  };
};

export default createContainer(useOwnerEntityHook);
