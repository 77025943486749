import React, { useEffect, useState } from "react";
import { Grid, Row, Col, Table, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import Pagination from "@mui/material/Pagination";
import { useNotification } from "../../providers/NotificationProvider";
import Button from "../../components/CustomButton/CustomButton";
import { Card } from "../../components/Card/Card";
import OptionButton from "../../components/CustomButton/OptionButton";
import CreateGroupModal from "./_parts/CreateGroupModal";
import AddStoreToGroupModal from "./_parts/AddStoreToGroupModal";
import EditGroupModal from "./_parts/EditGroupModal";
import SmallTableBody from "../../components/Table/SmallTableBody";
import { useGetApiCall, useApi } from "../../lib/hooks";

const GroupsListView = () => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const deleteApi = useApi("/staff");
  const { response, reload } = useGetApiCall("/owner/stores?type=group", {}, "/staff");
  const groups = response ?? [];
  const [groupsByStatus, setGroupsByStatus] = useState([]);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showAddStoreToGroupModal, setShowAddStoreToGroupModal] = useState(false);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const goStoreEdit = (storeId) => history.push("stores/edit", { storeId });

  const groupOptions = (group, store) => [{
    label: "このグループから外す",
    labelColor: "#FF4A55",
    onClick: async () => {
      const path = `/stores/${group.id}/child_stores/${store.id}`;
      const response = await deleteApi.api.delete(path);
      if (response) {
        showSuccessNotification(`店舗をグループから外しました。`);
        reload();
      }
    }
  }];

  const onClickPagination = (v, group, offSet) => {
    const newGroups = v.groups.map((g) =>
      g.id === group.id ? { ...g, offSet: offSet } : g
    );
    const newStatusFiltered = {
      isOpen: v.isOpen,
      groups: newGroups,
    };
    if (v.isOpen === groupsByStatus[0].isOpen) {
      setGroupsByStatus([newStatusFiltered, groupsByStatus[1]]);
    } else if (v.isOpen === groupsByStatus[1].isOpen) {
      setGroupsByStatus([groupsByStatus[0], newStatusFiltered]);
    }
  };

  useEffect(() => {
    const offSet = 0;
    const parPage = 10;

    if (groups.length !== 0) {
      setGroupsByStatus([
        {
          isOpen: true,
          groups: groups
            .filter((g) => g.status === "open")
            .map((g) => ({ ...g, offSet, parPage })),
        },
        {
          isOpen: false,
          groups: groups
            .filter((g) => g.status === "close")
            .map((g) => ({ ...g, offSet, parPage })),
        },
      ]);
    }
  }, [groups]);

  return (
    <Grid fluid className="content">
      <Row>
        <Col md={12}>
          <ButtonToolbar style={{ marginBottom: 10 }}>
            <Button
              bsStyle="info"
              fill
              pullRight
              onClick={() => setShowCreateGroupModal(true)}
            >
              <b>グループを作成する</b>
            </Button>
          </ButtonToolbar>

          {groupsByStatus.map((v) => (
            <React.Fragment key={v.isOpen}>
              {v.groups.map((group) => (
                <Card
                  key={group.id}
                  title={
                    group.store_type === "owner_group"
                      ? `${group.name}(オーナー)`
                      : group.name
                  }
                  category={
                    group.store_type === "owner_group" &&
                    "オーナーは全ての店舗が所属するグループです。自動で店舗が追加されます。"
                  }
                  titleRightContent={
                    <>
                      <span
                        style={{
                          backgroundColor: v.isOpen ? "#00ddb4" : "#4b4b4b",
                          display: "inline-block",
                          color: "white",
                          padding: "0px 8px",
                          border: 0,
                          fontSize: 15,
                          borderRadius: 6,
                          fontWeight: "bold",
                          marginRight: 10,
                        }}
                      >
                        {v.isOpen ? "公開" : "非公開"}
                      </span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`group-tooltip-${group.id}`}>
                            グループを編集
                          </Tooltip>
                        }
                      >
                        <i
                          className="fas fa-pen"
                          onClick={() => {
                            setSelectedGroup(group);
                            setShowEditGroupModal(true);
                          }}
                          style={{ fontSize: 15 }}
                        />
                      </OverlayTrigger>
                    </>
                  }
                  rightContent={
                    group.store_type !== "owner_group" && (
                      <Button
                        onClick={() => {
                          setSelectedGroup(group);
                          setShowAddStoreToGroupModal(true);
                        }}
                      >
                        店舗を追加する
                      </Button>
                    )
                  }
                  content={
                    <Table striped hover>
                      <thead>
                        {/* PC */}
                        <MediaQuery query="(min-width: 767px)">
                          <tr>
                            <th />
                            <th>店舗名</th>
                            <th>所属グループ</th>
                            <th>状態</th>
                            <th />
                          </tr>
                        </MediaQuery>
                        {/* スマホ */}
                        <MediaQuery query="(max-width: 766px)">
                          <tr>
                            <th />
                            <th>店舗名</th>
                            <th>状態</th>
                            <th />
                          </tr>
                        </MediaQuery>
                      </thead>
                      <tbody>
                        {group.child_stores
                          .filter((store) =>
                            ["open", "close"].includes(store.status)
                          )
                          .slice(group.offSet, group.offSet + group.parPage)
                          .map((store) => (
                            <tr key={store.id}>
                              <MediaQuery query="(min-width: 767px)">
                                <td
                                  width={48}
                                  onClick={() => goStoreEdit(store.id)}
                                >
                                  {store.app_icon_url && (
                                    <img
                                      key={store.id}
                                      alt="店舗画像"
                                      src={store.app_icon_url}
                                      width={45}
                                      height={45}
                                      style={{
                                        borderRadius: 2,
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                                </td>
                                <td
                                  style={{ fontWeight: "bold" }}
                                  onClick={() => goStoreEdit(store.id)}
                                >
                                  {store.name}
                                </td>
                                <td
                                  style={{ fontWeight: "bold" }}
                                  onClick={() => goStoreEdit(store.id)}
                                >
                                  {store.parent_stores
                                    .map((parent) => parent.name)
                                    .join(", ")}
                                </td>
                                <td
                                  width={80}
                                  onClick={() => goStoreEdit(store.id)}
                                >
                                  <div
                                    className={
                                      store.status === "open"
                                        ? "active"
                                        : "closed"
                                    }
                                  >
                                    {store.status === "open"
                                      ? "公開"
                                      : "非公開"}
                                  </div>
                                </td>
                                <td width={50}>
                                  {group.store_type !== "owner_group" && (
                                    <OptionButton options={groupOptions(group, store)} />
                                  )}
                                </td>
                              </MediaQuery>
                              <MediaQuery query="(max-width: 766px)">
                                <SmallTableBody
                                  title={store.name}
                                  contents={[]}
                                  image={store.app_icon_url}
                                  goTo={() => goStoreEdit(store.id)}
                                  status={
                                    <div
                                      className={
                                        store.status === "open"
                                          ? "active"
                                          : "closed"
                                      }
                                    >
                                      {store.status === "open"
                                        ? "公開"
                                        : "非公開"}
                                    </div>
                                  }
                                  button={group.store_type !== "owner_group" && <OptionButton options={groupOptions(group, store)} />}
                                />
                              </MediaQuery>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  }
                  stats={
                    <Pagination
                      count={
                        Math.ceil(group.child_stores.filter((store) =>
                          ["open", "close"].includes(store.status)
                        ).length / group.parPage)
                      }
                      page={group.offSet + 1}
                      onChange={(_, offSet) => onClickPagination(v, group, offSet - 1)}
                    />
                  }
                />
              ))}
            </React.Fragment>
          ))}
        </Col>
      </Row>

      <CreateGroupModal
        show={showCreateGroupModal}
        onHide={() => setShowCreateGroupModal(false)}
        reloadData={reload}
      />
      <AddStoreToGroupModal
        show={showAddStoreToGroupModal}
        onHide={() => setShowAddStoreToGroupModal(false)}
        group={selectedGroup}
        reloadData={reload}
      />
      <EditGroupModal
        show={showEditGroupModal}
        onHide={() => setShowEditGroupModal(false)}
        group={selectedGroup}
        reloadData={reload}
      />
    </Grid>
  );
};

export default GroupsListView;
