import React from "react";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Genders, GenderLabel } from "../../constants/user";
import { UserGender } from "../../types/user";

type Props = Omit<SelectProps, "onChange"> & {
  disableEmpty?: boolean;
  emptyLabel?: string;
  gender: UserGender | null;
  onChange: (gender: UserGender | null) => void;
};

const GenderSelectForm = ({
  disableEmpty = false,
  emptyLabel = "すべて",
  gender,
  onChange,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <Select
      value={gender ?? "all"}
      onChange={(e) => {
        const { value } = e.target;
        onChange(value === "all" ? null : value as UserGender);
      }}
      {...otherProps}
    >
      {!disableEmpty && (
        <MenuItem value="all">{emptyLabel}</MenuItem>
      )}
      {Genders.map((gender) => (
        <MenuItem key={gender} value={gender}>
          {GenderLabel[gender]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default GenderSelectForm;
