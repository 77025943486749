import React from "react";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

type Props = {
  onClose: () => void;
  open: boolean;
  storeName: string;
  businessDay: string;
  receiptNo: string;
};

const ToshibaTecPosUserComplementConfirmDialog = ({
  onClose,
  open,
  storeName,
  businessDay,
  receiptNo
}: Props) => {
  const theme = useTheme();

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>チェックイン情報を登録しました</DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <Box color={theme.palette.danger.main} fontWeight={700}>※チェックインは翌日反映されます</Box>
        </Box>
        <Box mb={2}>
          <Box fontWeight={700} mb={0.5}>店舗</Box>
          <Box>{storeName}</Box>
        </Box>
        <Box mb={2}>
          <Box fontWeight={700} mb={0.5}>営業日</Box>
          <Box>{businessDay}</Box>
        </Box>
        <Box mb={2}>
          <Box fontWeight={700} mb={0.5}>レシート番号</Box>
          <Box>{receiptNo}</Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" color="cancel" onClick={onClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ToshibaTecPosUserComplementConfirmDialog;
