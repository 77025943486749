import React from "react";
import Typography from "@mui/material/Typography";
import { ActivityLogTag } from "../../../types/activityLog";

type Props = {
  tag: ActivityLogTag;
};
const ActivityLogTagChip = ({
  tag
}: Props): JSX.Element => {
  const isBorderTag = ["visit", "with_check_in"].includes(tag.type);
  const isCancelAndRefunded = ["cancel", "refunded"].includes(tag.type);
  const bgcolor = TagsColor[tag.type]

  const fontColor = isCancelAndRefunded
    ? "#91A2B7"
    : isBorderTag
    ? bgcolor
    : "#fff";

  return (
    <Typography
      style={{
        fontSize: 10,
        backgroundColor: isBorderTag ? "#fff" : bgcolor,
        color: fontColor,
        padding: "4px 8px",
        borderRadius: 4,
        border: isBorderTag ? `1px solid ${bgcolor}` : "",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        height: 20,
        whiteSpace: "nowrap"
      }}
    >
      {tag.name}
    </Typography>
  );
};

export const TagsColor = {
  check_in: "#91A2B7",
  payment: "#1E88E5",
  charge: "#ED690A",
  cancel: "#E6E6E6",
  refunded: "#E6E6E6",
  visit: "#ED6CC0",
  with_check_in: "#596069",
} as const;

export default ActivityLogTagChip;
