import React, { useState } from "react";
import { styled } from "@mui/material/styles";

type Props<T> = {
  list: Array<{ label: string; value: T }>;
  defaultSelectedValue: T;
  onClick: (value: T) => void;
  [key: string]: string | number | boolean | {} | null | undefined;
};

const DataFilteringSidebar: <T>(props: Props<T>) => JSX.Element = ({
  list,
  defaultSelectedValue,
  onClick,
  ...otherProps
}) => {
  const [selectedItem, setSelectedItem] = useState(defaultSelectedValue);

  return (
    <div {...otherProps}>
      {list.map((item) =>
        selectedItem === item.value ? (
          <StyledActiveListItem key={item.label}>{item.label}</StyledActiveListItem>
        ) : (
          <StyledInActiveListItem
            key={item.label}
            onClick={() => {
              onClick(item.value);
              setSelectedItem(item.value);
            }}
          >
            {item.label}
          </StyledInActiveListItem>
        )
      )}
    </div>
  );
};


const StyledActiveListItem = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
  cursor: "pointer",
  fontWeight: 700,
  backgroundColor: "#f5f5f5",
  borderRadius: 4,
}));

const StyledInActiveListItem = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
  cursor: "pointer",
}));

export default DataFilteringSidebar;
