import React from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { useNotification } from "../../../providers/NotificationProvider";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import FormInput from "../../../components/FormInputs/FormInput";
import CustomButton from "../../../components/CustomButton/CustomButton";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { isEmailValid } from "../../../lib/general";
import { useApi } from "../../../lib/hooks";

type AccountTransferForm = {
  email: string;
  password: string;
  promptPasswordChange: boolean;
};

type Props = {
  show: boolean;
  onClose: () => void;
};

const AccountTransferModal = ({
  show,
  onClose
}: Props): JSX.Element => {
  const { showSuccessNotification } = useNotification();
  const { logout } = useLoginContext();
  const { api, loading } = useApi("/staff");
  const defaultValues = {
    email: "",
    password: "",
    promptPasswordChange: true
  };
  const methods = useForm<AccountTransferForm>({ defaultValues });
  const { register, watch, handleSubmit, errors } = methods;
  const watchValues = watch();
  const canSubmit = Boolean(watchValues.email) && Boolean(watchValues.password);

  const takeOverAccount = async (data: AccountTransferForm) => {
    const message = `現在ログイン中のアカウントを${data.email}に受け渡します。よろしいですか？`;
    if (!window.confirm(message)) {
      return;
    }

    const staff = {
      email: data.email,
      password: data.password,
      registered: !data.promptPasswordChange
    };
    const res = await api.put("/staffs/me/transfer", { staff });
    if (res) {
      showSuccessNotification("アカウント受け渡しが完了しました。ログアウトします");
      setTimeout(logout, 500);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(takeOverAccount)}>
        <Modal.Header>
          <Modal.Title>
            アカウント受け渡し
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <FormInput
              name="email"
              label="受け渡し先のメールアドレス"
              ncol="col-md-12"
              type="email"
              inputRef={register({
                required: "メールアドレスを入力してください",
                validate: (email) => isEmailValid(email) || "正しい形式のメールアドレスを入力してください"
              })}
              validationMessage={errors.email?.message}
            />
          </Row>

          <Row>
            <FormInput
              name="password"
              label="新しいパスワード"
              ncol="col-md-8"
              type="password"
              inputRef={register({ required: "パスワードを入力してください" })}
              validationMessage={errors.password?.message}
            />
          </Row>

          <Row>
            <Col md={12}>
              <CustomCheckbox
                name="promptPasswordChange"
                id="promptPasswordChange"
                label="初回ログイン時にパスワードを変更させる"
                inputRef={register}
              />
            </Col>
          </Row>

          <StyledNote>
            ※アカウント受け渡しはメールアドレスのドメインが@toypo.co.jpのスタッフのみ使える機能です。<br />
            ※アカウントを受け渡した後は自動的に管理画面からログアウトされます。
          </StyledNote>
        </Modal.Body>

        <StyledFooter>
          <CustomButton onClick={onClose}>
            閉じる
          </CustomButton>

          <LoadingButton
            type="submit"
            label="アカウントを受け渡す"
            color="danger"
            fill
            disabled={!canSubmit}
            loading={loading}
          />
        </StyledFooter>
      </Form>
    </Modal>
  );
};

const StyledNote = styled("div")({
  fontSize: 12,
  color: "#888",
  marginTop: 32,
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right"
});

const StyledFooter = styled(Modal.Footer)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center"
});

export default AccountTransferModal;
