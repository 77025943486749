import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Row, Col, Modal, FormGroup, FormControl } from "react-bootstrap";
import CustomButton from "../../../components/CustomButton/CustomButton";

type FormModalProps = {
  show: boolean;
  onHide: () => void;
  title: string;
  max?: number;
  onGivePoint: (point: number) => void;
  disabled: boolean;
};

const FormModal: FC<FormModalProps> = ({
  show,
  onHide,
  title,
  max,
  onGivePoint,
  disabled, 
}) => {
  const [givePoint, setGivePoint] = useState<string>("");

  useEffect(() => {
    setGivePoint("");
  }, [show]);

  const validate = () => {
    if(max && Number(givePoint) > max) {
      return `${max.toLocaleString()}${title}以下を設定してください`;
    }
  };

  return (
    <Modal show={show} onHide={onHide} bsSize="small">
      <Modal.Header closeButton>{title}を付与する</Modal.Header>
      <Modal.Body>
        <b>付与する{title}数</b>
        <Row style={{ marginTop: 10 }}>
          <Col md={12} xs={10}>
            <FormGroup>
              <Col md={12} xs={8} style={{ padding: 0 }}>
                <FormControl
                  type="number"
                  placeholder={`${title}数を入力`}
                  value={givePoint}
                  disabled={disabled}
                  onChange={(e: any) => {
                    setGivePoint(e.target.value);
                  }}
                />

                <StyledValidation validation={Boolean(validate())}>{validate()}</StyledValidation>
                {max && <StyledNote>最大{max.toLocaleString()}ポイントまで付与できます</StyledNote>}
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          bsStyle="info"
          fill
          disabled={!Number(givePoint) || Boolean(validate()) || disabled}
          onClick={() => {
            const point = Number(givePoint);
            onGivePoint(point);
          }}
        >
          <b>付与する</b>
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

const ValidationTextSpace = 18;
const StyledValidation = styled("div")<{ validation: boolean }>(({ validation }) => ({
  lineHeight: `${ValidationTextSpace}px`,
  paddingTop: validation ? 0 : ValidationTextSpace,
  marginTop: 4,
  fontSize:13,
  color: "#ff4a55",
}));
const StyledNote = styled("div")({
  marginTop: 4,
  fontSize:13,
  color: "#888",
});

export default FormModal;
