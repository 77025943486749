import React, { useState, useRef, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { parse } from "csv-parse/dist/esm/sync";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AxiosError } from "axios";
import DialogTitle from "../../../components/DialogTitle";
import { useApi } from "../../../lib/hooks";
import { useNotification } from "../../../providers/NotificationProvider";

type Props = {
  open: boolean;
  onClose: () => void;
};

type OptionItemPreview = {
  title: string;
  price: string;
};

type OptionTemplatePreview = {
  id: string | null;
  title: string;
  is_required: boolean | null;
  is_multiple: boolean | null;
  items: OptionItemPreview[] | null;
};

type ApiError = {
  line_number: number;
  message: string;
};

const OptionCsvImportDialog = ({
  open,
  onClose,
}: Props): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const csvApi = useApi();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<ApiError[] | null>(null);
  const [previewOptions, setPreviewOptions] = useState<(OptionTemplatePreview | null)[]>([]);
  const [shouldReload, setShouldReload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // ダイアログを初期状態に戻す
  const cleanUp = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    
    setFile(null);
    setPreviewOptions([]);
    setErrors(null);
    setUploading(false);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    if (files && files[0]) {
      setFile(files[0]);
      loadOptionAsPreview(files[0]);
    }
    setErrors(null);
  };

  const loadOptionAsPreview = useCallback(async (loadedFile: File) => {
    // CSVのBooleanは文字列でも渡されるため、それをBooleanに変換する。不正な文字列があった場合はnullを返す
    const elementToBoolean = (element: string | boolean): boolean | null => {
      if (typeof element === "boolean") {
        return element;
      }
      if (typeof element === "string") {
        if(element === "true" || element === "TRUE")
          return true;
        if(element === "false" || element === "FALSE")
          return false;
      }
      return null;
    }
    
    // 渡された行データをオプションアイテムにする。データの個数が不正の場合nullを返す
    const extractToOptionItem = (row: string[]): OptionItemPreview[] | null => {
      if (row.length % 2 !== 0) {
        return null;
      }
      const items: OptionItemPreview[] = [];
      for (let index = 0; index < row.length; index += 2) {
        if (row[index] !== "" && row[index + 1] !== "") {
          items.push({
            title: row[index],
            price: row[index + 1],
          });
        }
      }
      return items;
    }

    // 渡された行データをオプションテンプレートにする。データの個数が不正の場合nullを返す
    const extractToOptionTemplate = (row: string[]): OptionTemplatePreview | null => {
      if (row.length <= 4) {
        return null;
      }
      return {
        id: row[0] === "" ? null : row[0], // IDの欄に何もなければ新規作成として表示する
        title: row[1],
        is_required: elementToBoolean(row[2]),
        is_multiple: elementToBoolean(row[3]),
        items: extractToOptionItem(row.slice(4)),
      };
    }

    if (loadedFile !== null) {
      const reader = new FileReader();
      reader.readAsText(loadedFile);
      reader.onload = async () => {
        const csv = reader.result as string;
        try {
          const rows = await parse(csv, { skip_empty_lines: true, from: 2, relax_column_count: true });
          const loadedOptions = rows.map((row: string[]) => extractToOptionTemplate(row));         
          setPreviewOptions(loadedOptions); // extractToOptionTemplate から null が帰ってきたら、不正なCSVであることを示しているので、Submitできなくなる
        } catch {
          showErrorNotification("予期せぬエラーが発生しました。ファイルの形式を確かめた上で、もう一度お試しください。");
          cleanUp();
        }
      };
    }
  }, [showErrorNotification]);

  const handleClose = useCallback(() => {
    onClose();
    cleanUp();
    if(shouldReload) { // オプションが更新された場合にのみ、ダイアログを閉じた際にリロードする
      window.location.reload(); 
    }
  }, [onClose, shouldReload]);

  const handleClickFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    return fileInputRef.current?.click();
  };

  const handleClickUpload = useCallback(async () => {
    if (!file) return;
    const params = new FormData();
    params.append("csv_file", file);

    setUploading(true);
    try {
      // エラーメッセージはバリデーション結果が返ってくるので、こちらでcatchする
      const response = await csvApi.api.post("/option_templates/csv_import", params, { showErrorNotification: false, throwsError: true });
      if (response !== null) {
        const optionCount = response.data.count;
        showSuccessNotification(`${optionCount}件のオプションをインポート・編集しました`);
        cleanUp();
      }
      setShouldReload(true);
    } catch(error) {
      setFile(null); // これないと同名のファイルを再選択できなくなる
      // エラーからバリデーション結果を抽出
      const responseErrors = (error as AxiosError).response?.data?.errors as ApiError[];
      if (responseErrors) {
        setErrors(responseErrors);
        showErrorNotification("CSVの内容に誤りがあります。各項目のエラーをお確かめの上、修正して選択し直してください。");
      } else {
        showErrorNotification(
          "予期せぬエラーが発生しました。もう一度お試しください。しばらく時間が経っている場合は、インポートが処理中の可能性があります。しばらく待ってページを更新して下さい" ||
            (error as AxiosError).response?.data?.message ||
            (error as AxiosError).message
        );
      }
    }
    setUploading(false);
  }, [csvApi.api, file, showErrorNotification, showSuccessNotification]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <DialogTitle>
        オプションのCSVインポート
      </DialogTitle>
      <DialogScrollContent>
        {previewOptions.length === 0 &&
          <EmptyDialogContent>CSVファイルを選択してください</EmptyDialogContent>
        }
        {previewOptions.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>タイトル</TableCell>
                <TableCell>オプション内容</TableCell>
                <TableCell>必須</TableCell>
                <TableCell>複数選択</TableCell>
                {errors && <TableCell width="40%">エラー</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewOptions.map((option, index) => (
                <TableRow>
                  {option &&
                    <>
                      <TableCell>{option.id ?? "新規追加"}</TableCell>
                      <TableCell>{option.title}</TableCell>
                      <TableCell>
                        {option.items && (
                          <>
                            {option.items.map((item) => (
                              <>
                                <text>{item.title}({item.price}円)</text><br />
                              </>
                            ))}
                          </>
                        )}
                        {option.items == null &&
                          <>オプション内容が不正な可能性があります。<br/>末尾のデータの長さなどを確認してみてください</>
                        }
                      </TableCell>
                      <TableCell>{option.is_required ? "選択必須" : option.is_required == null ? "値が不正です。予期せぬ結果になる可能性があります" : ""}</TableCell>
                      <TableCell>{option.is_multiple ? "複数選択可能" : option.is_multiple == null ? "値が不正です。予期せぬ結果になる可能性があります" : ""}</TableCell>
                      {errors &&
                        // line_number は "CSV配列上"のインデックスを返すので、ヘッダーを考慮すると一つずれている
                        <TableCell>{errors.find(element => element.line_number - 1 === index)?.message ?? ""}</TableCell>
                      }
                    </>
                  }
                  {option === null && (
                    <>
                      <TableCell/>
                      <TableCell>不正な行です。CSVを確認して下さい(オプション内容を含んでいるか、など)。({index+1}行目)</TableCell>
                      <TableCell/>
                      <TableCell/>
                      <TableCell/>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogScrollContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={uploading}
          onClick={handleClickFileSelect}>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleChangeFile}
            style={{ display: "none" }}
          />
          {fileInputRef.current ? ("CSVファイルを再選択する") : ("CSVファイルを選択する")}
        </Button>
      </DialogActions>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="cancel"
          disabled={uploading}
          onClick={handleClose}
        >
          閉じる
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="submit"
          onClick={handleClickUpload}
          disabled={uploading || file === null || errors !== null || previewOptions.includes(null)}>
          内容を確認の上、インポート・編集
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EmptyDialogContent = styled(DialogContent)(() => ({
  textAlign: "center",
}));

const DialogScrollContent = styled(DialogContent)({
  overflowY: "scroll",
  maxHeight: "50vh",
});

export default OptionCsvImportDialog;
