import React, { useState } from "react";
import styled from "@emotion/styled";
import { Close, Search } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton/CustomButton";

interface Props {
  defaultKeyword: string;
  onSubmit: (keyword: string) => void;
  [key:string]: any;
}

const CustomerSearch = ({
  defaultKeyword,
  onSubmit,
  ...otherProps
}: Props): JSX.Element => {
  const [keyword, setKeyword] = useState<string>(defaultKeyword);

  const handleClear = () => {
    setKeyword("");
    onSubmit("");
  };

  return (
    <StyledContainer>
      <StyledSearchIcon />

      <StyledInput
        type="text"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit(keyword);
          }
        }}
        {...otherProps}
      />

      {Boolean(keyword) && (
        <StyledCloseIcon
          color="disabled"
          onClick={handleClear}
        />
      )}

      <StyledSubmitButton
        bsStyle="info"
        fill
        disabled={!keyword}
        onClick={() => onSubmit(keyword)}
      >
        検索
      </StyledSubmitButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div({
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  height: 48,
  borderRadius: 4,
  border: "1px solid #E3E3E5",
  padding: "0 8px 0 16px",
});

const StyledSearchIcon = styled(Search)({
  display: "block",
  marginRight: 8,
  color: "#9E9E9E",
  fontSize: 28
});

const StyledInput = styled.input({
  flexGrow: 1,
  borderWidth: 0
});

const StyledCloseIcon = styled(Close)({
  marginRight: 8,
  fontSize: 18,
  cursor: "pointer"
});

const StyledSubmitButton = (props: { [key:string]: any }) => (
  <CustomButton
    style={{
      height: 30,
      display: "flex",
      alignItems: "center",
      fontWeight: 700
    }}
    {...props}
  />
);

export default CustomerSearch;
