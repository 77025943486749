import React from "react";
import { useHistory } from "react-router";
import { Grid, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Card } from "../../components/Card/Card";
import FormInputs from "../../components/FormInputs/FormInputs";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import AuthEntityContainer from "../../containers/entities/AuthEntityContainer";
import { useNotification } from "../../providers/NotificationProvider";
import { regexpEmail } from "../../lib/validation";
import ToypoLogo from "../../assets/img/toypo-logo-black.png";

type FormTypes = {
  email: string;
  password: string;
};

const LoginView= (): JSX.Element => {
  const history = useHistory();
  const { showErrorNotification } = useNotification();
  const authEntityContainer = AuthEntityContainer.useContainer();
  const { signIn } = authEntityContainer.api;
  const { register, errors, handleSubmit } = useForm<FormTypes>({
    defaultValues: { email: "", password: "" },
  });

  const onSubmit = async (data) => {
    try {
      await signIn(data);
      window.location.reload();
    } catch (error) {
      showErrorNotification(error.message);
    }
  };

  const onError = () => showErrorNotification("値が正しく入力されていません");

  return (
    <Grid fluid className="content">
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row className="justify-content-md-center">
          <Col sm={6} xs={10} smOffset={3} xsOffset={1}>
            <div style={{ marginBottom: 20, textAlign: "center" }}>
              <img
                src={ToypoLogo}
                style={{ width: "45%", maxWidth: 190 }}
                alt="ロゴ"
              />
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: 24,
                margin: "16px 0"
              }}
            >
              店舗用ログイン
            </div>
            <Card
              title=""
              content={
                <>
                  <FormInputs
                    properties={[
                      {
                        name: "email",
                        type: "email",
                        ncol: "col-md-10 col-md-offset-1",
                        placeholder: "メールアドレスを入力する",
                        bsClass: "form-control",
                        inputRef: register({
                          required: "メールアドレスを入力してください。",
                          pattern: {
                            value: regexpEmail,
                            message:
                              "正しい形式のメールアドレスを入力してください。",
                          },
                        }),
                        validationMessage: errors.email?.message,
                      },
                      {
                        name: "password",
                        type: "password",
                        ncol: "col-md-10 col-md-offset-1",
                        placeholder: "パスワードを入力する",
                        bsClass: "form-control",
                        inputRef: register({
                          required: "パスワードを入力してください。",
                        }),
                        validationMessage: errors.password?.message,
                      },
                    ]}
                  />

                  <Row style={{ marginBottom: 10 }}>
                    <Col xs={10} xsOffset={1}>
                      <div
                        style={{
                          textAlign: "right",
                          color: "#525252",
                          marginTop: 5,
                          fontSize: 12,
                        }}
                      >
                        パスワードを忘れた方は
                        <button
                          type="button"
                          style={{
                            border: "none",
                            color: "#007bff",
                            padding: 0,
                          }}
                          onClick={() => history.push("staff/password/reset")}
                        >
                          こちら
                        </button>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 10 }}>
                    <Col xs={10} xsOffset={1}>
                      <LoadingButton
                        type="submit"
                        label="ログイン"
                        loadingLabel="ログイン中..."
                        block
                        fill
                        color="info"
                      />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Form>
    </Grid>
  );
};

export default LoginView;
