import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { NoticeTemplateInterface } from "../../../containers/entities/NoticeTemplateEntityContainer";

type Props = {
  title: string;
  onClick: (template?: NoticeTemplateInterface) => void;
  icon: string;
  description: string;
};

const NoticeCreationCouduitItem: FC<Props> = ({
  title,
  onClick,
  icon,
  description,
}) => {
  const isPcOrTablet = useMediaQuery({ query: "(min-width: 600px)" });

  return (
    <div
      key={title}
      style={{
        borderRadius: 4,
        backgroundColor: "#FFF",
        width: "100%",
        padding: 10,
        border: "1px solid #DDD",
        cursor: "pointer",
        maxWidth: isPcOrTablet ? 250 : undefined,
        flex: "1 1 auto",
      }}
      onClick={() => onClick()}
      aria-hidden
    >
      <div
        style={{
          backgroundColor: "#718096",
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i
          className={icon}
          color="#FFF"
          style={{ color: "#FFF", fontSize: 21 }}
        />
      </div>
      <h1
        style={{
          color: "#555",
          marginTop: 10,
          marginBottom: 5,
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        {title}
        <i className="fas fa-angle-right" style={{ marginLeft: 10 }} />
      </h1>
      <p style={{ fontSize: 12, color: "#555" }}>{description}</p>
    </div>
  );
};

export default NoticeCreationCouduitItem;
