import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { useNotification } from "../../providers/NotificationProvider";
import { useBooleanState, useApi, useMobileFlag, useQuery } from "../../lib/hooks";
import { downloadCSVFile } from "../../lib/general";
import OrderStatusChangeModal from "./parts/OrderStatusChangeModal";
import LaptopEcOrderListTable from "./parts/LaptopEcOrderListTable";
import MobileEcOrderListTable from "./parts/MobileEcOrderListTable";
import { OrderStatusLabels } from "../../constants/order";
import { OrderStatus, OrdersResponse, OrderResource } from "../../types/order";
import DisablingMask from "../../components/DisablingMask";
import OrderDetailModal from "./parts/OrderDetailModal";

const TabList = ["receive", "ready", "shipped", "done", "cancel"];
type TabType =  typeof TabList[number];

const EcManagementView = (): JSX.Element => {
  const isMobile = useMobileFlag();
  const { showSuccessNotification } = useNotification();
  const {
    ecOrders,
    reloadEcOrders,
    loading: fetching,
    queryParams,
    changeUrlAndFetchEcOrders,
    totalPages,
    setIdToUrl
  } = useEcOrders();
  const csvApi = useApi();
  const updateApi = useApi();
  const loading = fetching;
  const [selectedEcOrder, setSelectedEcOrder] = useState<OrderResource | null>(null);
  const [isOpenStatusChangeModal, openStatusChangeModal, closeStatusChangeModal] = useBooleanState(false);
  const [csvAnchorEl, setCsvAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const downloadEcOrdersCsv = async (status?: OrderStatus[]) => {
    const csvData = await csvApi.api.blob("/orders.csv", { type: "ec", status });

    downloadCSVFile(csvData);
  }

  const changeStatus = async (id: number, status: OrderStatus) => {
    const response = await updateApi.api.put(`/orders/${id}`, { status });
    if (response !== null) {
      showSuccessNotification(`注文のステータスを変更しました。`);
      reloadEcOrders();
    }
  };

  const handleClickStatusLabel = (order: OrderResource): void => {
    if (order.status === "cancel") {
      window.alert("キャンセルした注文の状態は変更できません");
    } else {
      setSelectedEcOrder(order);
      openStatusChangeModal();
    }
  };

  const cancelOrder = (id: number) => {
    const message = "この注文をキャンセルしますか？\nキャンセルすると返金が行われます。\nお客様にキャンセルすることを説明した上で行ってください。";
    if (window.confirm(message)) {
      changeStatus(id, "cancel");
    }
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, order: OrderResource) => {
    setMenuAnchorEl(e.currentTarget);
    setSelectedEcOrder(order);
  };

  const menuHandler = (fn: () => void) => () => {
    fn();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Paper
        variant={isMobile ? "elevation" : "outlined"}
        square={isMobile}
        sx={{ m: isMobile ? 0 : 2 }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} p={2}>
          <Typography variant="title">オンラインショップ注文一覧</Typography>

          {!isMobile && (
            <>
              <Button
                disabled={csvApi.loading || loading}
                variant="contained"
                color="submit"
                endIcon={<i className="ri-arrow-down-s-fill" style={{ lineHeight: "1" }} />}
                onClick={(e) => setCsvAnchorEl(e.currentTarget)}
              >
                CSV出力
              </Button>

              <Menu
                anchorEl={csvAnchorEl}
                open={Boolean(csvAnchorEl)}
                onClose={() => setCsvAnchorEl(null)}
              >
                <MenuItem onClick={() => downloadEcOrdersCsv(["receive", "ready", "shipped"])}>対応中</MenuItem>
                <MenuItem onClick={() => downloadEcOrdersCsv(["done"])}>対応済み</MenuItem>
                <MenuItem onClick={() => downloadEcOrdersCsv(["cancel"])}>キャンセル</MenuItem>
                <MenuItem onClick={() => downloadEcOrdersCsv()}>すべて</MenuItem>
              </Menu>
            </>
          )}
        </Box>

        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={queryParams.tab}
          onChange={(_, tab: string) => changeUrlAndFetchEcOrders({ page: 1, tab })}
        >
          <Tab value="receive" label={OrderStatusLabels.receive} disabled={loading} />
          <Tab value="ready" label={OrderStatusLabels.ready} disabled={loading} />
          <Tab value="shipped" label={OrderStatusLabels.shipped} disabled={loading} />
          <Tab value="done" label={OrderStatusLabels.done} disabled={loading} />
          <Tab value="cancel" label={OrderStatusLabels.cancel} disabled={loading} />
        </Tabs>

        <DisablingMask mask={loading}>
          {isMobile ? (
            <MobileEcOrderListTable
              orders={ecOrders}
              onClickOrder={(order) => setIdToUrl(order.id)}
              onClickMenu={handleClickMenu}
              onUpdateStatus={changeStatus}
            />
          ) : (
            <LaptopEcOrderListTable
              orders={ecOrders}
              onClickOrder={(order) => setIdToUrl(order.id)}
              onClickMenu={handleClickMenu}
              onClickStatusLabel={handleClickStatusLabel}
            />
          )}
        </DisablingMask>

        {totalPages > 1 && (
          <Pagination
            sx={{ my: 1 }}
            disabled={loading}
            count={totalPages}
            page={queryParams.page}
            onChange={(_, page) => changeUrlAndFetchEcOrders({ ...queryParams, page })}
          />
        )}
      </Paper>

      {selectedEcOrder && (
        <OrderStatusChangeModal
          order={selectedEcOrder}
          open={isOpenStatusChangeModal}
          onClose={closeStatusChangeModal}
          disabled={updateApi.loading}
          onSubmit={async (newStatus) => {
            changeStatus(selectedEcOrder.id, newStatus);
            closeStatusChangeModal();
          }}
        />
      )}

      {selectedEcOrder && (
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={menuHandler(() => setIdToUrl(selectedEcOrder.id))}>
            詳細を見る
          </MenuItem>

          {selectedEcOrder.status !== "cancel" && (
            <>
              <MenuItem onClick={menuHandler(openStatusChangeModal)}>状態を変更する</MenuItem>
              <MenuItem
                sx={{ color: "#FF4A55" }}
                onClick={menuHandler(() => cancelOrder(selectedEcOrder.id))}
              >
                注文をキャンセルする
              </MenuItem>
            </>
          )}
        </Menu>
      )}

      <OrderDetailModal
        orderId={queryParams.id ?? 0}
        open={Boolean(queryParams.id)}
        onClose={() => {
          setIdToUrl(undefined);
          if (queryParams.tab === "doing") {
            reloadEcOrders();
          }
        }}
      />
    </>
  );
};

export type QueryParams = {
  id?: number;
  page: number;
  tab: TabType;
};

const useEcOrders = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const tabFromQuery = query.get("tab") as TabType;
  const { api, loading, response, totalPages } = useApi<OrdersResponse>();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    id: Number(query.get("id") || 0),
    page: Number(query.get("page") || 1),
    tab: TabList.includes(tabFromQuery) ? tabFromQuery : TabList[0]
  });

  const changeUrl = useCallback((params: QueryParams) => {
    setQueryParams(params);

    const searchParams = new URLSearchParams();
    if (params.id) {
      searchParams.append("id", String(params.id));
    }
    searchParams.append("tab", params.tab);
    searchParams.append("page", String(params.page));

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);
  }, [history, location.pathname]);

  const fetchEcOrders = useCallback(() => {
    api.get("/orders", {
      type: "ec",
      status: queryParams.tab,
      page: queryParams.page,
      limit: ["done", "cancel"].includes(queryParams.tab) ? 20 : undefined
    });
  }, [api, queryParams]);

  useEffect(() => {
    fetchEcOrders();
  }, [fetchEcOrders]);

  return {
    changeUrlAndFetchEcOrders: (params: QueryParams) => changeUrl({ page: params.page, tab: params.tab }),
    setIdToUrl: (id: number | undefined) => changeUrl({ ...queryParams, id }),
    loading,
    queryParams,
    ecOrders: response?.orders || [],
    reloadEcOrders: fetchEcOrders,
    totalPages
  };
};

export default EcManagementView;
