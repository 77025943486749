import { AxiosResponse } from "axios";
import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { ownerChargeApi, chargeApi } from "../../lib/api";
import { isOwner } from "../../lib/staff";

export interface Charge {
  id: number;
  store_id: number;
  user_id: number;
  pay_charge_id: string;
  store_name: string;
  user_name: string;
  amount: number;
  description: string | null;
  category: "by_ticket" | "by_order" | "by_subscription";
  payment_method: "credit" | "at_store" | "cash_on_delivery";
  refunded: boolean;
  card_brand:
    | "visa"
    | "master_card"
    | "jcb"
    | "diners_club"
    | "american_express"
    | "discover";
  card_last4: string;
  fee_rate: number;
  created_at: string;
  charge_products: ChargeProduct[];
}

export interface ChargeProduct {
  charge_id: number;
  created_at: string;
  id: number;
  price: number;
  product_id: number;
  quantity: number;
  title: string;
}

export interface TotalCharge {
  amount: number;
  details: {
    category: Category;
    amount: number;
    payment_methods: { payment_method: PaymentMethod; amount: number }[];
  }[];
}

type Category = "by_order" | "by_ticket" | "by_subscription";
type PaymentMethod = "at_store" | "credit";

export interface Product {
  id: number;
  title: string;
  price: number;
  category: "ticket" | "menu" | "subscription";
  sales_number: number;
  store_name: string;
  created_at: string;
}

const paymentMethodJpObj = {
  at_store: "店舗決済",
  credit: "クレジット決済",
};

export const convertPaymentMethodToJp = (method: PaymentMethod) =>
  paymentMethodJpObj[method];

const categoryJpObj = {
  by_order: "モバイルオーダー",
  by_ticket: "チケット",
  by_subscription: "サブスクリプション",
};

export const convertCategoryToJp = (category: Category) =>
  categoryJpObj[category];

const useReportEntityHook = () => {
  const [charges, setCharges] = useState<Charge[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [totalCharge, setTotalCharge] = useState<TotalCharge>({
    amount: 0,
    details: [],
  });

  return {
    state: {
      charges,
      products,
      totalCharge,
    },
    logic: {
      getCharges: useCallback(async (): Promise<Charge[]> => {
        const reportData: AxiosResponse<Charge[]> =
          await chargeApi.getCharges();

        setCharges(reportData.data);
        return reportData.data;
      }, []),
      downloadChargesCsv: async () => {
        if (isOwner()) {
          await ownerChargeApi.downloadChargesCsv();
        } else {
          await chargeApi.downloadChargesCsv();
        }
      },
      getProducts: useCallback(async (): Promise<Product[]> => {
        const productData = await chargeApi.getProducts();

        setProducts(productData.data);
        return productData.data;
      }, []),
      getTotalCharge: useCallback(async ({ from, to }): Promise<TotalCharge> => {
        const totalSalesData = await chargeApi.getTotalChargeAmount({
          from,
          to,
        });

        setTotalCharge(totalSalesData.data);
        return totalSalesData.data;
      }, []),
    },
  };
};

export default createContainer(useReportEntityHook);
