import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Card } from "../../../components/Card/Card";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import {
  BannerStatus,
  ResponseBannerData,
} from "../../../containers/entities/BannerEntityContainer";

type Props = {
  editMode: boolean;
  onClickStatusUpdateButton: () => Promise<void>;
  disabledSubmitButton: boolean;
};

const optionArray = (): BannerStatus[] => ["open", "close"];

const BannerFormPublishSettingCard = ({
  editMode,
  onClickStatusUpdateButton,
  disabledSubmitButton
}: Props): JSX.Element => {
  const { register } = useFormContext<{
    banner: ResponseBannerData & { image: string }
  }>();

  return (
    <Card
      title="公開設定"
      content={
        <>
          <Row>
            <Col sm={12}>
              <SelectorForm
                name="banner.status"
                inputRef={register}
                width={180}
                style={{ marginBottom: 0 }}
                options={optionArray().map((status) => ({
                  label: status === "open" ? "公開" : "非公開",
                  value: status,
                }))}
              />
            </Col>
          </Row>

          {editMode && (
            <BannerFormPublishSettingCardButtonGroup
              disabledSubmitButton={disabledSubmitButton}
              onClickStatusUpdateButton={onClickStatusUpdateButton}
            />
          )}
        </>
      }
    />
  );
};

export default BannerFormPublishSettingCard;

type BannerFormActiveSettingCardButtonGroupProps = Pick<
  Props,
  "disabledSubmitButton" | "onClickStatusUpdateButton"
>;

const BannerFormPublishSettingCardButtonGroup = ({
  disabledSubmitButton,
  onClickStatusUpdateButton,
}: BannerFormActiveSettingCardButtonGroupProps): JSX.Element => (
  <Row
    style={{
      marginRight: 0,
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    }}
  >
    <Col
      style={{
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
      }}
    >
      <LoadingButton
        label="変更する"
        loadingLabel="変更中..."
        color="info"
        fill
        simple
        pullRight
        loadingColor="red"
        disabled={disabledSubmitButton}
        onClick={async (): Promise<void> => {
          await onClickStatusUpdateButton();
        }}
      />
    </Col>
  </Row>
);
