import React, { useEffect } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { useApi } from "../../../lib/hooks";
import { calculateGeneration } from "../../../lib/general";
import { GenderLabel, GenerationLabel } from "../../../constants/user";
import { QuestionnaireSectionResponse, QuestionnaireDashboardSection } from "../../../types/questionnaire";
import { MembersCardRankResponse } from "../../../types/membersCard";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { RestaurantSatisfactionAnswerQueryParams } from "../type";

type Props = {
  sections: QuestionnaireSectionResponse[];
  queryParams: RestaurantSatisfactionAnswerQueryParams;
};

const RestaurantSatisfactionFilterCondition = ({
  sections,
  queryParams
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext();
  const rankApi = useApi<MembersCardRankResponse>();
  const storeNameMap = currentStore.child_stores
    .reduce((result, { id, name }) => ({ ...result, [id]: name }), {});
  const generateLabelMap = (dashboardSection: QuestionnaireDashboardSection) => sections
    .find((v) => v.dashboard_section === dashboardSection)
    ?.section_items
    .reduce((result, { id, label }) => ({ ...result, [id]: label }), {}) || {};
  const totalQualityLabelMap = generateLabelMap("total_quality");
  const orderItemLabelMap = generateLabelMap("order_items")

  useEffect(() => {
    if (queryParams.rankId) {
      rankApi.api.get(`/members_card_contents/members_card_ranks/${queryParams.rankId}`);
    }
  }, [rankApi.api, queryParams.rankId]);

  return (
    <Box display="flex" flexWrap="wrap" gap={1} alignItems="center" fontWeight={700}>
      絞り込み条件：
      {isNoCondition(queryParams) && "なし"}

      {queryParams.totalQualityIds.map((totalQualityId) => (
        <StyledConditionChip>
          {totalQualityLabelMap[totalQualityId]}
        </StyledConditionChip>
      ))}

      {(queryParams.dateFrom || queryParams.dateTo) && (
        <StyledConditionChip>{queryParams.dateFrom} ~ {queryParams.dateTo}</StyledConditionChip>
      )}

      {queryParams.answerStoreIds.map((answerStoreId) => (
        <StyledConditionChip key={answerStoreId}>
          {storeNameMap[answerStoreId]}
        </StyledConditionChip>
      ))}

      {queryParams.orderItemIds.map((orderItemId) => (
        <StyledConditionChip key={orderItemId}>
          {orderItemLabelMap[orderItemId]}
        </StyledConditionChip>
      ))}

      {queryParams.gender && (
        <StyledConditionChip>{GenderLabel[queryParams.gender]}</StyledConditionChip>
      )}

      {queryParams.ageFrom !== null && (
        <StyledConditionChip>{GenerationLabel[calculateGeneration(queryParams.ageFrom)]}</StyledConditionChip>
      )}

      {queryParams.rankId && rankApi.response && (
        <StyledConditionChip>{rankApi.response.name}</StyledConditionChip>
      )}
    </Box>
  );
};

export const isNoCondition = (queryParams: RestaurantSatisfactionAnswerQueryParams) => (
  queryParams.totalQualityIds.length === 0 &&
    queryParams.dateFrom === null &&
    queryParams.dateTo === null &&
    queryParams.answerStoreIds.length === 0 &&
    queryParams.orderItemIds.length === 0 &&
    queryParams.gender === null &&
    queryParams.ageFrom === null &&
    queryParams.rankId === null
);

const StyledConditionChip = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  backgroundColor: "#eee",
  fontSize: 14,
  borderRadius: 4
}));

export default RestaurantSatisfactionFilterCondition;
