import React from "react";
import styled from "@emotion/styled";
import { SubscriptionContentStatusLabel } from "../../constants/subscription";
import { SubscriptionContentStatus } from "../../types/subscription";

const ColorsByStatus = {
  draft: "#b0b0b0",
  preview: "#b0b0b0",
  open: "#00ddb4",
  close: "#4b4b4b",
  sold: "#e66464",
  limited_open: "#5c9ee0"
} as const;

type Props = {
  status: SubscriptionContentStatus;
  [key: string]: any;
};

const SubscriptionContentStatusChip = ({
  status,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledChip status={status} {...otherProps}>
      {SubscriptionContentStatusLabel[status]}
    </StyledChip>
  );
};

const StyledChip = styled("div")<{ status: SubscriptionContentStatus }>(({ status }) => ({
  backgroundColor: ColorsByStatus[status],
  color: "white",
  display: "inline",
  padding: "5px 8px",
  border: 0,
  borderRadius: 6,
  fontWeight: "bold"
}));

export default SubscriptionContentStatusChip;
