import React from "react";
import MembersCardRankSelectForm from "../../../FormInputs/MembersCardRankSelectForm";
import { Condition, ConditionDiff } from "../../type";

type Props = {
  condition: Condition,
  onChange: (diff: ConditionDiff) => void;
};

const MembersCardRankCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <MembersCardRankSelectForm
      fullWidth
      emptyLabel="選択してください"
      sx={{ backgroundColor: "#fff" }}
      rankId={condition.value_from === null ? null : Number(condition.value_from)}
      onChange={(rankId, rank) => {
        onChange({
          rank,
          value_from: rankId === null ? null : String(rankId)
        })
      }}
    />
  );
};

export default MembersCardRankCondition;
