import React, { useState } from "react";
import { Prompt, withRouter } from "react-router";
import { Grid, Row, Col, Tooltip, OverlayTrigger, HelpBlock, Image } from "react-bootstrap";
import { Backdrop, CircularProgress } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { Card } from "../../components/Card/Card";
import FormInputs from "../../components/FormInputs/FormInputs";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import ImageTrimmingInput from "../../components/ImageInput/ImageTrimmingInput";
import FormLabel from "../../components/FormInputs/FormLabel";
import FormGroup from "../../components/FormInputs/FormGroup";
import DefaultOpenTimeView from "./_parts/DefaultOpenTimeView";
import SpecialOpenTimeView from "./_parts/SpecialOpenTimeView";
import StoreViewPublishSettingCard from "./_parts/StoreViewPublishSettingCard";
import AppContainer from "../../containers/AppContainer";
import StoreEntityContainer from "../../containers/entities/StoreEntityContainer";
import StoreViewContainer from "../../containers/views/StoreViewContainer";
import { combineContainer } from "../../lib/general";
import { useOnceEffect } from "../../lib/hooks";

const StoreViewWrapper = (props) => (
  <StoreViewContainer.Provider>
    <StoreView {...props} />
  </StoreViewContainer.Provider>
);

export default withRouter(StoreViewWrapper);

// StoresFromViewと同様の内容だが、こちらが古い実装
const StoreView = (props) => {
  const container = combineContainer([
    AppContainer.useContainer(),
    StoreEntityContainer.useContainer(),
    StoreViewContainer.useContainer(),
  ]);

  const { currentStaff, reloadCurrentStaff } = useLoginContext();
  const { api, state, setViewState, setEntityState, logic } = container;
  const { store, isDirty } = state;
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);

  useOnceEffect(() => {
    api.getStore().then((res) => {
      setEntityState.store(res);
      setViewState.initialize(res);
    });
  });

  const subImageUrls = [store?.subImageUrls];

  const onClick = {
    updateButton: async () => {
      // TODO:  もう少しうまくかけると思う
      const formData = {
        name: state.formName,
        address: state.formAddress,
        phone_number: state.formPhone,
        businessType: state.formBusinessType,
        appeal: state.formAppeal,
        customerUnitPrice: state.customerUnitPrice,
        monthlyCustomer: state.monthlyCustomer,
        appIcon: state.formAppIcon || state.appIconUrl, // ロゴ画像「変更/登録」または「登録済み(変更なし)」
        url: state.formUrl,
        code: state.formCode,
        twitterUrl: state.twitterUrl,
        instagramUrl: state.instagramUrl,
        facebookUrl: state.facebookUrl,
        mapUrl: state.mapUrl,
        open_time: state.formOpenTime,
        firstMessageStaffImage:
          state.formFirstMessageStaffImage || state.firstMessageStaffImageUrl,
        firstMessageStaffName: state.firstMessageStaffName,
        firstMessage: state.firstMessage,
        qrscan_mode: state.qrscanMode,
        status: state.formStatus,
      };

      if (!logic.validate(formData)) {
        props.displayNotification({
          level: "error",
          message: "値が正しく入力されていない可能性があります",
        });
        return;
      }
      if (state.formCode && !state.store?.code && state.formCode !== "") {
        if (
          !window.confirm(
            "店舗コードは一度設定すると変更できません。変更内容を保存しますか？"
          )
        ) {
          return;
        }
      }

      // 送信するデータ形式は、base64の必要があるので、ロゴ画像を変更がない限り更新しない。
      if (!state.formAppIcon) delete formData.appIcon;
      if (!state.formFirstMessageStaffImage)
        delete formData.firstMessageStaffImage;

      try {
        await api.updateStore(formData);
        api.getStore().then((res) => {
          setEntityState.store(res);
          setViewState.initialize(res);
        });
        await reloadCurrentStaff();
        props.displayNotification({
          level: "success",
          message: "更新しました。",
        });
        setViewState.isDirty(false);
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      }
    },
    changeStatus: async (status) => {
      const isSave = isDirty
        ? window.confirm(
            "行った変更が保存されていない可能性があります。変更内容を保存しますか？"
          )
        : false;
      const isUpdateStatus = window.confirm(
        `${status === "open" ? "公開" : "非公開"}に変更しますか？`
      );

      const params = isSave
        ? {
            name: state.formName,
            address: state.formAddress,
            phone_number: state.formPhone,
            businessType: state.formBusinessType,
            appeal: state.formAppeal,
            customerUnitPrice: state.customerUnitPrice,
            appIcon: state.formAppIcon || state.appIconUrl, // ロゴ画像「変更/登録」または「登録済み(変更なし)」
            url: state.formUrl,
            code: state.formCode,
            twitterUrl: state.twitterUrl,
            instagramUrl: state.instagramUrl,
            facebookUrl: state.facebookUrl,
            open_time: state.formOpenTime,
            firstMessageStaffImage:
              state.formFirstMessageStaffImage ||
              state.firstMessageStaffImageUrl,
            firstMessageStaffName: state.firstMessageStaffName,
            firstMessage: state.firstMessage,
            status: isUpdateStatus ? status : undefined,
          }
        : {
            status: isUpdateStatus ? status : undefined,
          };

      if (isSave && !logic.validate(params)) {
        props.displayNotification({
          level: "error",
          message: "値が正しく入力されていません。",
        });
        return;
      }

      if (!isSave && !isUpdateStatus) {
        return;
      }

      // 送信するデータ形式は、base64(state.formImage)の必要がある。なので、ロゴ画像を変更がない限り更新しない。
      if (isSave && !state.formAppIcon) delete params.appIcon;
      if (isSave && !state.formFirstMessageStaffImage)
        delete params.firstMessageStaffImage;

      setIsBackdropLoading(true);
      try {
        await api.updateStore(params);
        api.getStore().then((res) => {
          setEntityState.store(res);
          setViewState.initialize(res);
          setViewState.isDirty(false);
        });
        await reloadCurrentStaff();
        props.displayNotification({
          level: "success",
          message: "更新しました。",
        });
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      } finally {
        setIsBackdropLoading(false);
      }
    },
  };

  const func = {
    createSubImage: async (img) => {
      const createSubImage = img;
      setViewState.formSubImage(createSubImage);
      const subImage = {
        image: createSubImage,
      };

      try {
        await api.createSubImage(subImage).then((res) => {
          setEntityState.store(res);
        });
        props.displayNotification({
          level: "success",
          message: "紹介画像を作成しました。",
        });
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      }
    },
    updateSubImage: async (updateImage, id) => {
      const updateSubImage = updateImage;
      setViewState.formSubImage(updateSubImage);
      const subImage = {
        image: updateSubImage,
        index: id,
      };
      try {
        await api.updateSubImage(subImage).then((res) => {
          setEntityState.store(res);
        });
        props.displayNotification({
          level: "success",
          message: "紹介画像を編集しました。",
        });
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      }
    },
    deleteSubImage: async (index) => {
      if (!window.confirm("この画像を削除しますか？")) {
        return;
      }
      try {
        await api.deleteSubImage(index);
        api.getStore().then((res) => {
          setEntityState.store(res);

          props.displayNotification({
            level: "success",
            message: "画像を削除しました。",
          });
        });
      } catch (error) {
        props.displayNotification({ level: "error", message: error.message });
      }
    },
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <StoreViewPublishSettingCard
              onClickStatusUpdateButton={onClick.changeStatus}
              store={store}
              onSelect={({ target }) => {
                logic.handleFormStatusChange(target.value);
              }}
              formStatus={state.formStatus}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title="基本情報"
              content={
                <form>
                  <FormGroup style={{ marginBottom: 20 }}>
                    <FormLabel label="ロゴ画像" />
                    <ImageTrimmingInput
                      aspect={1}
                      height={180}
                      width={180}
                      doCompress
                      doTrimming
                      altMessage="ロゴ画像"
                      image={
                        state.formAppIcon ||
                        state.appIconUrl ||
                        store?.appIconUrl
                      }
                      doDisplayImage={logic.doDisplayImage(
                        state.appIconUrl || store?.appIconUrl
                      )}
                      updateImage={(img) => {
                        setViewState.formAppIcon(img);
                        setViewState.isDirty(true);
                      }}
                    />
                  </FormGroup>

                  <FormInputs
                    properties={[
                      {
                        name: "formName",
                        label: "店舗名",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value: state.formName,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                        validationMessage: state.nameValidationMessage,
                      },
                      {
                        name: "formAddress",
                        label: "住所",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        value: state.formAddress,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                        validationMessage: state.addressValidationMessage,
                      },
                      {
                        name: "formPhone",
                        label: "電話番号",
                        ncol: "col-md-3",
                        type: "tel",
                        bsClass: "form-control",
                        value: state.formPhone,
                        onChange: logic.handlePhoneNumber,
                        validationMessage: state.phoneNumberValidationMessage,
                      },
                    ]}
                  />

                  {currentStaff.is_toypo_member && (
                    <FormGroup>
                      <FormLabel label="チェックインの運用方法" />
                      <div>
                        <input
                          type="radio"
                          id="mpm"
                          value="mpm"
                          name="qrscanMode"
                          checked={state.qrscanMode === "mpm"}
                          onChange={({ target }) => logic.handleFormChange(target.name, target.value)}
                        />
                        <label htmlFor="mpm">店舗側がQRコードを提示する（MPM）</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="cpm"
                          value="cpm"
                          name="qrscanMode"
                          checked={state.qrscanMode === "cpm"}
                          onChange={({ target }) => logic.handleFormChange(target.name, target.value)}
                        />
                        <label htmlFor="cpm">ユーザー側がQRコードを提示する（CPM）</label>
                      </div>
                    </FormGroup>
                  )}


                  <LoadingButton
                    label="更新する"
                    loadingLabel="更新中..."
                    color="info"
                    fill
                    pullRight
                    onClick={onClick.updateButton}
                    disabled={!isDirty}
                  />
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>

        {/* 基本営業時間 */}
        <Row>
          <Col md={12}>
            <DefaultOpenTimeView
              displayNotification={props.displayNotification}
            />
          </Col>
        </Row>

        {/* 特別営業時間 */}
        <Row>
          <Col md={12}>
            <SpecialOpenTimeView
              displayNotification={props.displayNotification}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card
              title="追加情報"
              content={
                <form>
                  <Row>
                    <FormGroup className="col-md-6">
                      <FormInputs
                        properties={[
                          {
                            name: "formCode",
                            label: "店舗コード",
                            ncol: "col-md-7",
                            type: "text",
                            value: state.formCode,
                            bsClass: "form-control",
                            onChange: ({ target }) => {
                              logic.handleFormChange(
                                target.name,
                                target.value || null
                              );
                            },
                            validationMessage: state.codeValidationMessage,
                            disabled: state.store?.code,
                            unit: (
                              <CopyToClipboard
                                text={state.formCode}
                                onCopy={() =>
                                  props.displayNotification({
                                    level: "success",
                                    message: "コピーしました。",
                                  })
                                }
                              >
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      <strong>コピーする</strong>
                                    </Tooltip>
                                  }
                                >
                                  <i className="far fa-copy" />
                                </OverlayTrigger>
                              </CopyToClipboard>
                            ),
                          },
                        ]}
                      />
                      {!state.store?.code && (
                        <HelpBlock className="text-muted">
                          店舗コードは一度設定すると変更できなくなります。
                        </HelpBlock>
                      )}
                    </FormGroup>
                  </Row>
                  {/* 画像 */}
                  <FormGroup>
                    <FormLabel label="紹介画像" />
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {(store?.subImageUrls || state.subImageUrl).map(
                        (subImage, i) => (
                          <ImageTrimmingInput
                            doTrimming
                            key={subImage}
                            id={i}
                            canDelete
                            doCompress
                            width={200}
                            height={130}
                            altMessage="紹介画像"
                            image={subImage}
                            createImage={func.createSubsImage}
                            updateImage={func.updateSubImage}
                            deleteImage={func.deleteSubImage}
                            doDisplayImage={logic.doDisplaySubImage(
                              state.subImageUrl || store?.subImageUrls
                            )}
                          />
                        )
                      )}
                      {subImageUrls.flat().length !== 3 ? (
                        <ImageTrimmingInput
                          doCompress
                          doTrimming
                          blank
                          multiple
                          width={200}
                          height={130}
                          id={3}
                          doDisplayImage={false}
                          updateImage={func.createSubImage}
                        />
                      ) : null}
                    </div>
                    <p style={{ fontSize: 13, color: "#888" }}>
                      ※紹介画像は3枚まで追加でき、縦横比16:9で表示されます。
                    </p>
                  </FormGroup>

                  <FormInputs
                    properties={[
                      {
                        name: "formAppeal",
                        label: "紹介文",
                        ncol: "col-md-10",
                        rows: "3",
                        componentClass: "textarea",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value: state.formAppeal,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                        validationMessage: state.appealValidationMessage,
                      },
                      {
                        name: "customerUnitPrice",
                        unit: "円",
                        label: "客単価",
                        ncol: "col-md-3 col-sm-3 col-xs-8",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value: state.customerUnitPrice,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                      {
                        name: "monthlyCustomer",
                        unit: "人",
                        label: "月次来客数",
                        ncol: "col-md-3 col-sm-3 col-xs-8",
                        type: "text",
                        bsClass: "form-control",
                        value: state.monthlyCustomer,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                    ]}
                  />

                  <FormInputs
                    properties={[
                      {
                        name: "formUrl",
                        label: "店舗ホームページ",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value:
                          state.formUrl === null ? store?.url : state.formUrl,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                      {
                        name: "mapUrl",
                        label: "マップURL（GoogleマップなどのURL）",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value:
                          state.mapUrl === null
                            ? store?.mapUrl
                            : state.mapUrl,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                      {
                        name: "twitterUrl",
                        label: "twitter URL",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value:
                          state.twitterUrl === null
                            ? store?.twitterUrl
                            : state.twitterUrl,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                      {
                        name: "instagramUrl",
                        label: "instagram URL",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value:
                          state.instagramUrl === null
                            ? store?.instagramUrl
                            : state.instagramUrl,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                      {
                        name: "facebookUrl",
                        label: "facebook URL",
                        ncol: "col-md-6",
                        type: "text",
                        bsClass: "form-control",
                        // 空文字とnullを区別
                        value:
                          state.facebookUrl === null
                            ? store?.facebookUrl
                            : state.facebookUrl,
                        onChange: ({ target }) => {
                          logic.handleFormChange(target.name, target.value);
                        },
                      },
                    ]}
                  />

                  <LoadingButton
                    label="更新する"
                    loadingLabel="更新中..."
                    color="info"
                    fill
                    pullRight
                    onClick={onClick.updateButton}
                    disabled={!isDirty}
                  />
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} id="firstMessage">
            <Card
              title="初回登録ユーザーへの登録メッセージ"
              category={
                "メッセージが設定されていない場合はデフォルトのアニメーションが表示されます。\nメッセージ表示時にスタッフ名・スタッフ画像が設定されていない場合は、それぞれ店舗名・店舗画像が表示されます。"
              }
              content={
                <Row>
                  <Col md={12}>
                    <FormInputs
                      properties={[
                        {
                          name: "firstMessage",
                          label: "メッセージ内容",
                          note: "150文字以内で入力してください。",
                          placeholder:
                            "お店の簡単な紹介やアプリで配信することなどを入力しましょう",
                          ncol: "col-md-10",
                          rows: "3",
                          type: "text",
                          componentClass: "textarea",
                          bsClass: "form-control",
                          // 空文字とnullを区別
                          value:
                            state.firstMessage === null
                              ? store?.firstMessage
                              : state.firstMessage,
                          onChange: ({ target }) => {
                            logic.handleFormChange(target.name, target.value);
                          },
                          validationMessage:
                            state.firstMessageValidationMessage,
                        },
                        {
                          name: "firstMessageStaffName",
                          label: "スタッフ名",
                          placeholder: "例：店長",
                          note: "10文字以内で入力してください。",
                          ncol: "col-md-6",
                          type: "text",
                          bsClass: "form-control",
                          // 空文字とnullを区別
                          value:
                            state.firstMessageStaffName === null
                              ? store?.firstMessageStaffName
                              : state.firstMessageStaffName,
                          onChange: ({ target }) => {
                            logic.handleFormChange(target.name, target.value);
                          },
                          validationMessage:
                            state.firstMessageStaffNameValidationMessage,
                        },
                      ]}
                    />
                    <FormGroup>
                      <FormLabel label="スタッフ画像" />
                      <ImageTrimmingInput
                        aspect={1}
                        height={175}
                        width={175}
                        doTrimming
                        altMessage="スタッフ画像"
                        image={
                          state.formFirstMessageStaffImage ||
                          state.firstMessageStaffImageUrl ||
                          store?.firstMessageStaffImageUrl
                        }
                        doDisplayImage={logic.doDisplayFirstMessageStaffImage(
                          state.firstMessageStaffImageUrl ||
                            store?.firstMessageStaffImageUrl
                        )}
                        updateImage={(img) => {
                          setViewState.formFirstMessageStaffImage(img);
                          setViewState.isDirty(true);
                        }}
                        deleteImage={() => {
                          setViewState.formFirstMessageStaffImage(null);
                        }}
                      />

                      <HelpBlock className="text-muted">
                        画像は縦横比1:1で表示されます。
                      </HelpBlock>
                    </FormGroup>

                    <FormGroup style={{ marginBottom: 20 }}>
                      <FormLabel label="メッセージ例" />
                      <Col>
                        <Image
                          style={{ width: 320, height: 320 }}
                          src={require("../../assets/img/new_conection_message_sample.png")}
                        />
                      </Col>
                    </FormGroup>
                    <LoadingButton
                      label="更新する"
                      loadingLabel="更新中..."
                      color="info"
                      fill
                      pullRight
                      onClick={onClick.updateButton}
                      disabled={!isDirty}
                    />
                    <div className="clearfix" />
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </div>
  );
};
