import React from "react";
import { ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";

type PublishDropdownProps = {
  title: string;
  disabled: boolean;
  onMovePage: (url: string) => void;
};

const PublishDropdown = ({
  title,
  disabled,
  onMovePage,
}: PublishDropdownProps): JSX.Element => (
  <ButtonToolbar className="pull-right">
    <DropdownButton
      disabled={disabled}
      title={<strong>{title}</strong>}
      className="btn-info btn-fill"
      pullRight
      id="dropdown-template-list"
    >
      <MenuItem header>お知らせ</MenuItem>
      <MenuItem onClick={() => onMovePage("notices/new?type=normal")}>
        通常配信お知らせを作成
      </MenuItem>
      <MenuItem onClick={() => onMovePage("notices/new?type=auto")}>
        自動配信お知らせを作成
      </MenuItem>
      <MenuItem header>クーポン</MenuItem>
      <MenuItem onClick={() => onMovePage("coupons/new?type=normal")}>
        通常配信クーポンを作成
      </MenuItem>
      <MenuItem onClick={() => onMovePage("coupons/new?type=auto")}>
        自動配信クーポンを作成
      </MenuItem>
      <MenuItem header>メニュー</MenuItem>
      <MenuItem onClick={() => onMovePage("menu/new?1")}>
        メニューを作成
      </MenuItem>
    </DropdownButton>
  </ButtonToolbar>
);

export default PublishDropdown;
