export const PayjpStatementTypeLabels = {
  sales: "売上",
  service_fee: "サービス利用料",
  transfer_fee: "振込手数料",
  misc: "調整金"
} as const;

export const PayjpBalanceStatusLabels = {
  collecting: "集計中",
  transfer: "入金",
  claim: "請求"
} as const;
