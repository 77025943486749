import React, { useEffect, ComponentPropsWithoutRef } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useApi } from "../lib/hooks";
import { QuickSightDashboardPostResponse, QuickSightDashboardType } from "../types/quickSightDashboard";

type Props = ComponentPropsWithoutRef<"iframe"> & {
  params?: { [key: string]: string | number | null };
  dashboardType: QuickSightDashboardType;
};

const QuickSightDashboard = ({
  params,
  dashboardType,
  style = {},
  ...otherProps
}: Props): JSX.Element => {
  const { api, response } = useApi<QuickSightDashboardPostResponse>();
  const embedUrl = response?.embed_url;

  useEffect(() => {
    api.post(`/quick_sight_dashboards/${dashboardType}/embed_urls`);
  }, [api, dashboardType]);

  const urlParams = params ? (
    Object.entries(params)
      .filter(([_, value]) => value)
      .map(([key, value]) => `p.${key}=${value}`)
      .join("&")
  ) : "";

  return embedUrl === undefined ? (
    <Box display="flex" justifyContent="center" py={20}>
      <CircularProgress />
    </Box>
  ) : embedUrl === null ? (
    <Box display="flex" justifyContent="center" py={20}>
      一時的なエラーが発生しました。数秒後に画面を更新してください。
    </Box>
  ) : (
    <iframe
      width="100%"
      height="100%"
      title="店舗ダッシュボード"
      style={{ border: 0, ...style }}
      src={`${embedUrl}&locale=ja-JP#${urlParams}`}
      {...otherProps}
    />
  );
};

export default QuickSightDashboard;
