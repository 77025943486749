import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "@mui/material/styles";

type Props = Omit<ComponentPropsWithoutRef<"div">, "title"> & {
  title: string | JSX.Element;
  element?: JSX.Element | null;
};
const SectionTitle = ({
  title,
  element,
  ...otherProps
}: Props): JSX.Element => (
  <StyledContainer {...otherProps}>
    <StyledTitle>{title}</StyledTitle>

    {Boolean(element) && <div>{element}</div>}
  </StyledContainer>
);

const StyledContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const StyledTitle = styled("div")({
  fontSize: 15,
  color: "rgba(0, 0, 0, 0.54)",
});

export default SectionTitle;
