import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { eventApi } from "../../lib/api";

export type EventItem = {
  id: number | null;
  title: string;
  body: string;
  is_all_day: boolean;
  start_year: number | null;
  start_month: number | null;
  start_date: number | null;
  start_hour: number | null;
  start_minute: number | null;
  start_day: number | null;
  end_year: number | null;
  end_month: number | null;
  end_date: number | null;
  end_hour: number | null;
  end_minute: number | null;
  end_day: number | null;
};

const useEventEntityHook = () => {
  const [events, setEvents] = useState<EventItem[] | []>([]);
  return {
    state: { events },
    logic: {
      getEvents: useCallback(async ({ page }): Promise<AxiosResponse<EventItem[]>> => {
        const response: AxiosResponse<EventItem[]> = await eventApi.getEvents({
          page,
        });
        setEvents(response.data);
        return response;
      }, []),
      createEvent: async (data: EventItem): Promise<EventItem[]> => {
        const response: EventItem[] = await eventApi.createEvent(data);
        setEvents(response);
        return response;
      },
      updateEvent: async (data: EventItem): Promise<EventItem[]> => {
        const response: EventItem[] = await eventApi.updateEvent(data);
        setEvents(response);
        return response;
      },
      deleteEvent: async (id: number): Promise<EventItem[]> => {
        const response: EventItem[] = await eventApi.deleteEvent(id);
        setEvents(response);
        return response;
      },
    },
  };
};

export default createContainer(useEventEntityHook);
