import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { bannerApi } from "../../lib/api";

export type BannerStatus = "close" | "open";

export type ClickType =
  | "url"
  | "menu_page"
  | "delivery_page"
  | "takeout_page"
  | "ec_page"
  | "preorder_page";

export type ResponseBannerData = {
  id: number;
  click_url: string | null;
  click_type: ClickType;
  image_url: string;
  status: BannerStatus;
  title: string;
  priority: number | null;
  size: BannerSize;
  position: BannerPosition;
  store_id: number;
};

export type RequestBannerData = {
  status?: BannerStatus;
  image?: string;
  title: string;
  priority: number | null;
  click_url?: string | null;
  click_type: ClickType;
  size: BannerSize;
  position: BannerPosition;
};

type StatusesToJpObj = {
  close: "非公開";
  open: "公開";
};

type JpStatus = StatusesToJpObj[keyof StatusesToJpObj];

export const statusToJpObj: StatusesToJpObj = {
  close: "非公開",
  open: "公開",
} as const;

export const bannerSize = {
  Small: "small",
  Medium: "medium",
  Large: "large",
} as const;
export type BannerSize = typeof bannerSize[keyof typeof bannerSize];
export const bannerSizes = Object.values(bannerSize);
export const sizeToJpObj = {
  [bannerSize.Small]: "小バナー",
  [bannerSize.Medium]: "中バナー",
  [bannerSize.Large]: "大バナー",
} as const;

export const bannerPosition = {
  Top: "top",
  Center: "center",
  Bottom: "bottom",
} as const;
export type BannerPosition = typeof bannerPosition[keyof typeof bannerPosition];
export const bannerPositions = Object.values(bannerPosition);
export const positionToJpObj = {
  [bannerPosition.Top]: "上",
  [bannerPosition.Center]: "中央",
  [bannerPosition.Bottom]: "下",
} as const;

export const imageSize = {
  [bannerSize.Small]: {
    height: 80,
    aspectHorizontal: 4,
    aspectVertical: 1,
  },
  [bannerSize.Medium]: {
    height: 128,
    aspectHorizontal: 5,
    aspectVertical: 2,
  },
  [bannerSize.Large]: {
    height: 214,
    aspectHorizontal: 3,
    aspectVertical: 2,
  },
} as const;

export const clickType = {
  Url: "url",
  MenuPage: "menu_page",
  DeliveryPage: "delivery_page",
  TakeoutPage: "takeout_page",
  EcPage: "ec_page",
  PreorderPage: "preorder_page",
} as const;

export const clickTypes = Object.values(clickType);

export const clickTypeToJpObj = {
  [clickType.Url]: "指定のURL",
  [clickType.MenuPage]: "メニューページ",
  [clickType.DeliveryPage]: "メニューページ(デリバリー)",
  [clickType.TakeoutPage]: "メニューページ(テイクアウト)",
  [clickType.EcPage]: "メニューページ(オンラインショップ)",
  [clickType.PreorderPage]: "メニューページ(プレオーダー)",
};

const useBannerEntityHook = () => {
  const [banners, setBanners] = useState<ResponseBannerData[]>([]);

  // memo:なくても正常な挙動はするが、チラつきをなくす為入れている
  const deleteBannerFromBanners = useCallback(
    (deleteBannerId) => {
      const index = banners.findIndex((banner) => banner.id === deleteBannerId);
      setBanners([...banners.slice(0, index), ...banners.slice(index + 1)]);
    },
    [banners]
  );

  const updateBannerFromBanners = useCallback(
    (newBanner) => {
      const index = banners.findIndex((banner) => banner.id === newBanner.id);
      setBanners([
        ...banners.slice(0, index),
        newBanner,
        ...banners.slice(index + 1),
      ]);
    },
    [banners]
  );

  return {
    state: { banners },
    logic: {
      createBanner: async (
        bannerData: RequestBannerData
      ): Promise<ResponseBannerData> => {
        const newBanner = await bannerApi.createBanner(bannerData);
        return newBanner;
      },
      updateBanner: async (
        bannerId: number,
        params: Partial<RequestBannerData>
      ): Promise<ResponseBannerData> => {
        const newBanners = await bannerApi.updateBanner(bannerId, params);
        updateBannerFromBanners(newBanners);
        return newBanners;
      },
      deleteBanner: async (bannerId: number): Promise<void> => {
        await bannerApi.deleteBanner(bannerId);
        deleteBannerFromBanners(bannerId);
      },
      getBanners: useCallback(async () => {
        const newBanners = await bannerApi.getBanners();
        setBanners(newBanners);
        return newBanners;
      }, []),
    },
  };
};

export default createContainer(useBannerEntityHook);
