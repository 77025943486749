import React, { useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { useApi } from "../../lib/hooks";
import SelectorForm from "../FormInputs/SelectorForm";
import { ConditionGroup } from "../UserGroup/UserGroupModal";
import ConditionEditor from "../UserGroup/ConditionEditor";
import UserGroupCollectionSelectBox from "./_parts/UserGroupCollectionSelectBox";
import TargetUserList from "./_parts/TargetUserList";
import { TargetUserCondition, TargetUserConditionParams, TargetGroup, TargetGroupType } from "./type.d";

type Props = {
  allLabel?: string | null;
  disabled?: boolean;
  errorMessage: string;
  targetUserCondition: TargetUserCondition,
  onChangeTargetUserCondition: (targetUserCondition: TargetUserCondition) => void;
  showChangeIndicator?: boolean;
};

const TargetUserForm = ({
  allLabel = null,
  disabled = false,
  errorMessage,
  targetUserCondition,
  onChangeTargetUserCondition,
  showChangeIndicator = false
}: Props): JSX.Element => {
  const { api } = useApi();
  const [matchedUserCount, setMatchedUserCount] = useState<number>(0);
  const [openModalOnMount, setOpenModalOnMount] = useState(false);

  const options = Object.entries(TargetGroup).map(([value, label]) => ({
    value,
    label: value === "all" ? allLabel ?? label : label
  }))

  const handleChangeTargetUserCondition = (params: TargetUserConditionParams = {}, targetGroup: TargetGroupType) => {
    onChangeTargetUserCondition({
      targetGroup,
      conditionGroups: params.conditionGroups || [],
      userIds: params.userIds || [],
      userGroupIds: [],
      userGroupCollectionId: params.userGroupCollectionId || null
    });
  };

  const generateHandler = (key: string) => (value: ConditionGroup[] | number[] | number | null) =>
    handleChangeTargetUserCondition({ [key]: value }, targetUserCondition.targetGroup);

  const fetchMatchedUserCount = useCallback((conditionGroups: ConditionGroup[]) => {
    if (conditionGroups.length > 0) {
      api.post("/users/conditions", {
        condition_groups: conditionGroups.map(({ conditions }) => conditions)
      }).then((response) => {
        setMatchedUserCount(response?.data.user_count || 0);
      });
    } else {
      setMatchedUserCount(0);
    }
  }, [api]);

  useEffect(() => {
    fetchMatchedUserCount(targetUserCondition.conditionGroups);
  }, [fetchMatchedUserCount, targetUserCondition.conditionGroups]);

  return (
    <>
      <StyledSelectContainer>
        <SelectorForm
          style={{ marginRight: 16, marginBottom: 0 }}
          width={300}
          options={options}
          value={targetUserCondition.targetGroup}
          disabled={disabled}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const newTargetGroup = e.target.value as TargetGroupType;
            handleChangeTargetUserCondition({}, newTargetGroup);
            setOpenModalOnMount(newTargetGroup === "conditions");
          }}
          showChangeIndicator={showChangeIndicator}
        />

        {!disabled && targetUserCondition.targetGroup === "conditions" && (
          <>現時点での対象人数： {matchedUserCount}人</>
        )}
      </StyledSelectContainer>

      {targetUserCondition.targetGroup === "conditions" && (
        <ConditionEditor
          openModalOnMount={openModalOnMount}
          disabled={disabled}
          conditionGroups={targetUserCondition.conditionGroups}
          onChangeConditionGroups={generateHandler("conditionGroups")}
        />
      )}

      {targetUserCondition.targetGroup === "collections" && (
        <UserGroupCollectionSelectBox
          disabled={disabled}
          onSelect={generateHandler("userGroupCollectionId")}
          userGroupCollectionId={targetUserCondition.userGroupCollectionId}
        />
      )}

      {targetUserCondition.targetGroup === "specific_user" && (
        <TargetUserList userIds={targetUserCondition.userIds} />
      )}

      {Boolean(errorMessage) && (
        <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
      )}
    </>
  );
};

const StyledSelectContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
  flexWrap: "wrap",
});

const StyledErrorMessage = styled("div")({
  color: "red",
  margin: "8px 0 0 8px"
});

export default TargetUserForm;
