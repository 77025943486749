import React, { useEffect } from "react";
import { withRouter, Prompt } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, OverlayTrigger, Tooltip, HelpBlock } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { copyText } from "../../lib/general";
import { Card } from "../../components/Card/Card";
import FormInputs from "../../components/FormInputs/FormInputs";
import SelectorForm from "../../components/FormInputs/SelectorForm";
import ImageForm from "../../components/FormInputs/ImageForm";
import FormLabel from "../../components/FormInputs/FormLabel";
import CustomButton from "../../components/CustomButton/CustomButton";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import { SubscriptionContentStatus } from "../../containers/entities/SubscriptionContentEntityContainer";
import OwnerEntityContainer from "../../containers/entities/OwnerEntityContainer";
import SubscriptionPublishSettingCard from "./parts/SubscriptionPublishSettingCard";
import { isViewObjGroup } from "../../lib/viewObj";
import { useNotification } from "../../providers/NotificationProvider";
import { FormChangedAlertModal, ModalContentFormChangedList } from "../../components/Modal/FormChangedAlertModal";
import { useApi, useBooleanState } from "../../lib/hooks";
import { SubscriptionForm, SubscriptionFormLocation, SubscriptionFormParam } from "./type";

const LIMITED_OPEN_URL_ELEMENT_ID = "SubscriptionLimitedOpenUrl";

const FieldLabels = {
  title: "タイトル",
  body: "サービス内容",
  price: "販売価格",
  image: "画像",
  limit_quantity: "販売制限枚数",
  monthly_usable_count: "月間利用回数",
  daily_usable_count: "日間利用回数",
  this_term_usable_count: "契約期間内利用回数",
  expiration_month: "有効期限",
};

const SubscriptionFormView = (): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const location = useLocation<SubscriptionFormLocation>();
  const history = useHistory();
  const editSubscription = location.state?.subscription;
  const editMode = Boolean(editSubscription?.id);
  const isEditable = !editMode || editSubscription?.status === "draft";
  const ownerEntityContianer = OwnerEntityContainer.useContainer();
  const { owner, cardApplicationStatus } = ownerEntityContianer.state;
  const [isOpenSubmitModal, openSubmitModal, closeSubmitModal] = useBooleanState(false);
  const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] = useBooleanState(false);

  const defaultValues = {
    status: editSubscription?.status || "draft",
    title: editSubscription?.title || "",
    body: editSubscription?.body || "",
    price: editSubscription?.price || 0,
    monthly_usable_count: editSubscription?.monthly_usable_count || null,
    daily_usable_count: editSubscription?.daily_usable_count || null,
    this_term_usable_count: editSubscription?.this_term_usable_count || null,
    expiration_month: editSubscription?.expiration_month || 1,
    limit_quantity: editSubscription?.limit_quantity || null,
    image: editSubscription?.image_url || "",
  };
  const methods = useForm<SubscriptionForm>({ defaultValues });
  const { register, unregister, handleSubmit, getValues, setValue, watch, errors, reset } = methods;
  const { isDirty, dirtyFields } = methods.formState;
  const watchImage = watch("image", null);
  const watchStatus = watch("status") as SubscriptionContentStatus;
  const canPublishSubscription = owner?.has_tenant && cardApplicationStatus === "passed";
  const watchSubscription = watch();

  useEffect(() => {
    register("image", { required: "画像を設定してください。" });

    return () => {
      unregister(["image"]);
    };
  }, [register, unregister]);

  const handleCopyUrl = (id: string) => {
    copyText(id);
    showSuccessNotification("コピーしました。");
  };

  const { api, loading } = useApi();
  // 編集時，画像が変更されていないときにリクエストから画像を削除する
  // imageの中にimage_urlが入っているため
  const processFormData = (data: SubscriptionFormParam): SubscriptionFormParam => {
      const processedData = data;

      if (editMode) { delete processedData.status }

      if (editMode && processedData.image === editSubscription?.image_url) {
        delete processedData.image;
      }

      return processedData;
    };

  const createSubscription = async () => {
    const values = getValues();
    const formData = processFormData(values);

    if (!canPublishSubscription && ["open", "limited_open"].includes(values.status || "")) {
      showErrorNotification("サブスクを公開するには、口座登録とクレジット利用申請が必要です。");
      return;
    }

    const res = await api.post("/subscription_contents", { subscription_content: formData });
    if (res !== null) {
      showSuccessNotification("サブスクを作成しました。");
      reset();
      history.goBack();
    }
  };

  const updateSubscription = async (params: SubscriptionFormParam) => {
    if (!editSubscription) return;

    if (!canPublishSubscription && ["open", "limited_open"].includes(params.status || "")) {
      showErrorNotification("サブスクを公開するには、口座登録とクレジット利用申請が必要です。");
      return;
    }
    const res = await api.patch(`/subscription_contents/${editSubscription.id}`, {
      subscription_content: params
    });
    if (res !== null) {
      showSuccessNotification("サブスクを更新しました。");
      reset();
      history.goBack();
    }
  };

  const updateSubscriptionStatus = async (status: SubscriptionContentStatus) => {
    await updateSubscription({ status });
  };

  const handleUpdateButton = async () => {
    const values = getValues();
    const formData = processFormData(values);
    await updateSubscription(formData);
  };

  const deleteSubscription = async () => {
    if (!editSubscription) return;
    const res = await api.delete(`/subscription_contents/${editSubscription.id}`);
    if (res !== null) {
      showSuccessNotification(`${editSubscription.title}を削除しました。`);
      reset();
      history.goBack();
    }
  }

  const onError = () => {
    showErrorNotification("値が正しく入力されていません。");
  };

  return (
    <>
      <FormProvider {...methods}>
      <Grid fluid className="content">
        <Form>
          {editMode && (
            <Row>
              <Col md={10}>
                <SubscriptionPublishSettingCard
                  disabled={loading}
                  editMode={editMode}
                  subscriptionStatus={editSubscription?.status}
                  watchStatus={watchStatus}
                  onChangeStatus={updateSubscriptionStatus}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={10}>
              <Card
                title={editMode ? "サブスク編集" : "サブスク作成"}
                content={
                  <>
                    <FormGroup>
                      <ImageForm
                        doCompress
                        doTrimming
                        label="画像"
                        onChange={(img: string) => {
                          setValue("image", img, {
                            shouldDirty: true,
                          });
                        }}
                        value={watchImage}
                        validationMessage={errors.image?.message}
                        showChangeIndicator={editMode && Boolean(dirtyFields.image)}
                      />
                      <HelpBlock className="text-muted">
                        画像は縦横比16:9で表示されます。
                      </HelpBlock>
                    </FormGroup>
                    <FormInputs
                      properties={[
                        {
                          name: "title",
                          label: FieldLabels.title,
                          placeholder: "タイトル",
                          type: "text",
                          bsClass: "form-control",
                          ncol: "col-md-6",
                          inputRef: register({
                            required: "タイトルを入力してください。",
                          }),
                          validationMessage: errors?.title?.message,
                          showChangeIndicator: editMode && Boolean(dirtyFields.title)
                        },
                        {
                          name: "body",
                          label: FieldLabels.body,
                          placeholder: "サブスクの内容を記載してください",
                          ncol: "col-md-12",
                          rows: "6",
                          componentClass: "textarea",
                          bsClass: "form-control",
                          inputRef: register({
                            required: "サービス内容を入力してください。",
                          }),
                          validationMessage: errors?.body?.message,
                          showChangeIndicator: editMode && Boolean(dirtyFields.body)
                        },
                      ]}
                    />

                    <FormInputs
                      properties={[
                        {
                          name: "price",
                          label: `${FieldLabels.price}※`,
                          type: "number",
                          bsClass: "form-control",
                          ncol: "col-md-3",
                          disabled: !isEditable,
                          inputRef: register({
                            required: "販売価格を設定してください。",
                            min: {
                              value: 50,
                              message: "50円以上の価格を設定してください。",
                            },
                            max: {
                              value: 100000,
                              message: "10万円以下の価格を設定してください。",
                            },
                            valueAsNumber: true,
                          }),
                          unit: "円",
                          validationMessage: errors?.price?.message,
                          showChangeIndicator: editMode && Boolean(dirtyFields.price)
                        },
                        {
                          name: "limit_quantity",
                          label: `${FieldLabels.limit_quantity}※`,
                          attachment: "任意",
                          type: "number",
                          bsClass: "form-control",
                          ncol: "col-md-3",
                          disabled: !isEditable,
                          inputRef: register({
                            min: {
                              value: 1,
                              message: "適切な値を設定してください。",
                            },
                            max: {
                              value: 10000,
                              message: "1万枚以下の値を設定してください。",
                            },
                            valueAsNumber: true,
                          }),
                          unit: "枚",
                          validationMessage: errors?.limit_quantity?.message,
                          showChangeIndicator: editMode && ((Number.isNaN(watchSubscription.limit_quantity) ? null : watchSubscription.limit_quantity) !== editSubscription?.limit_quantity)
                        },
                      ]}
                    />

                    <Row>
                      <FormGroup className="col-md-7">
                        <Box mb={2.5}>
                          <SelectorForm
                            name="this_term_usable_count"
                            disabled={!isEditable}
                            inputRef={register({
                              valueAsNumber: true,
                              validate: (value) => {
                                if (Boolean(value) && Boolean(watchSubscription.monthly_usable_count)) {
                                  return `${FieldLabels.this_term_usable_count}と${FieldLabels.monthly_usable_count}は同時に指定できません。`;
                                }
                                return true;
                              }
                            })}
                            label={`${FieldLabels.this_term_usable_count}※`}
                            style={{ marginBottom: 0 }}
                            options={[...Array(100)].map((_, i) => ({
                              label: i === 0 ? "設定しない" : `${i}回`,
                              value: i === 0 ? "" : i,
                            }))}
                            showChangeIndicator={editMode && ((Number.isNaN(watchSubscription.this_term_usable_count) ? null : watchSubscription?.this_term_usable_count) !== editSubscription?.this_term_usable_count)}
                          />
                          {errors.this_term_usable_count?.message && (
                            <FormHelperText error>
                              {errors.this_term_usable_count?.message}
                            </FormHelperText>
                          )}
                        </Box>

                        <SelectorForm
                          name="monthly_usable_count"
                          disabled={!isEditable}
                          inputRef={register({ valueAsNumber: true })}
                          label={`${FieldLabels.monthly_usable_count}※`}
                          options={[...Array(100)].map((_, i) => ({
                            label: i === 0 ? "設定しない" : `${i}回`,
                            value: i === 0 ? "" : i,
                          }))}
                          showChangeIndicator={editMode && ((Number.isNaN(watchSubscription.monthly_usable_count) ? null : watchSubscription?.monthly_usable_count) !== editSubscription?.monthly_usable_count)}
                        />

                        <SelectorForm
                          name="daily_usable_count"
                          disabled={!isEditable}
                          inputRef={register({ valueAsNumber: true })}
                          label={`${FieldLabels.daily_usable_count}※`}
                          options={[...Array(100)].map((_, i) => ({
                            label: i === 0 ? "設定しない" : `${i}回`,
                            value: i === 0 ? "" : i,
                          }))}
                          showChangeIndicator={editMode && ((Number.isNaN(watchSubscription.daily_usable_count) ? null : watchSubscription?.daily_usable_count) !== editSubscription?.daily_usable_count)}
                        />

                        <SelectorForm
                          name="expiration_month"
                          disabled={!isEditable}
                          inputRef={register({ valueAsNumber: true })}
                          label={`${FieldLabels.expiration_month}※`}
                          options={[...Array(6)].map((_, i) => ({
                            label: `${i + 1}ヶ月`,
                            value: i + 1,
                          }))}
                          showChangeIndicator={editMode && Boolean(dirtyFields.expiration_month)}
                        />
                      </FormGroup>
                    </Row>
                    <HelpBlock>※のついた項目は公開後編集不可となります。</HelpBlock>

                    {editMode && editSubscription?.status === "limited_open" && (
                      <FormGroup bsSize="large">
                        <FormLabel label="限定公開リンク" />
                        {isViewObjGroup() ? (
                          <p>オーナーおよびグループは限定公開機能に対応しておりません。対応をお待ち下さい。</p>
                        ) : (
                          <div>
                            <span id={LIMITED_OPEN_URL_ELEMENT_ID}>
                              {editSubscription.limited_open_url}
                            </span>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip">
                                  <strong>コピーする</strong>
                                </Tooltip>
                              }
                            >
                              <i
                                className="far fa-copy"
                                role="button"
                                aria-hidden
                                onClick={() => handleCopyUrl(LIMITED_OPEN_URL_ELEMENT_ID)}
                              />
                            </OverlayTrigger>
                          </div>
                        )}
                      </FormGroup>
                    )}
                  </>
                }
              />
            </Col>
          </Row>

          {!editMode && (
            <Row>
              <Col md={10}>
                <SubscriptionPublishSettingCard
                  disabled={loading}
                  editMode={editMode}
                  subscriptionStatus={editSubscription?.status}
                  watchStatus={watchStatus}
                  onChangeStatus={updateSubscriptionStatus}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col md={10}>
              <Row style={{ marginBottom: 30 }}>
                <Col xs={6} md={5} mdOffset={1}>
                  <CustomButton block fill onClick={history.goBack}>
                    <b>もどる</b>
                  </CustomButton>
                </Col>
                <Col xs={6} md={5}>
                  <LoadingButton
                    color="info"
                    label={editMode ? "編集する" : "作成する"}
                    block
                    fill
                    onClick={handleSubmit(editMode ? openSubmitModal : createSubscription, onError)}
                    disabled={!isDirty}
                    loading={loading}
                  />
                </Col>
              </Row>

              {editMode && (
                <Row>
                  <Col xs={8} xsOffset={2} md={6} mdOffset={3}>
                    <LoadingButton
                      color="danger"
                      label="削除する"
                      block
                      simple
                      onClick={openDeleteModal}
                      disabled={watchStatus !== "draft"}
                      loading={loading}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>

        <Prompt
          when={isDirty}
          message="行った変更が保存されていない可能性があります。このページを離れますか？"
        />
      </Grid>
    </FormProvider>

      {editMode && (
        <>
          <FormChangedAlertModal
            title="サブスクの更新"
            show={isOpenSubmitModal}
            onSubmit={handleUpdateButton}
            onCancel={closeSubmitModal}
          >
            サブスクの内容を更新してよろしいですか？
            <ModalContentFormChangedList
              changedProperties={
                Object.entries(FieldLabels)
                  .map(([key, label]) => (dirtyFields?.[key] ? label : ""))
                  .filter(v => v)
              }
            />
          </FormChangedAlertModal>

          <FormChangedAlertModal
            title="サブスクの削除"
            submitButtonLabel="削除する"
            show={isOpenDeleteModal}
            onSubmit={deleteSubscription}
            onCancel={closeDeleteModal}
            displayStatus="danger"
          >
            {`${editSubscription?.title}を削除してよろしいですか？`}
          </FormChangedAlertModal>
        </>
      )}
    </>
  );
};

export default withRouter(SubscriptionFormView);
