import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "../../../components/DialogTitle";
import FormLabel from "../../../components/FormInputs/FormLabel";
import { useApi } from "../../../lib/hooks";
import { NoticeContentBodyResponse } from "../../../types/noticeContent";
import { useNotification } from "../../../providers/NotificationProvider";

type Props = {
  defaultTitle: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (title: string, body: string) => void;
};
const NoticeContentBodyAIAsistantDialog = ({
  defaultTitle,
  open,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  const { api, response, reset } = useApi<NoticeContentBodyResponse>();
  const { showErrorNotification } = useNotification();
  const [title, setTitle] = useState(defaultTitle);
  const [content, setContent] = useState('');
  const [position, setPosition] = useState(-1);
  const [generating, setGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);
  const answer = response?.content ?? '';
  const canGenerate = Boolean(title);

  const close = () => {
    if (!generating) {
      onClose();
    }
  };

  const handleGenerate = async () => {
    setGenerating(true);
    setGenerated(false);
    setPosition(-1);

    const params = { title, content };
    const response = await api.post("/notice_content_bodies", params, { showErrorNotification: false });
    if (!response) {
      showErrorNotification("本文を生成できませんでした");
      setGenerating(false);
      return;
    }

    const answerInResponse = response.data.content;
    const intervalId = setInterval(() => {
      setPosition((p) => {
        const newValue = p + 1;
        if (newValue === answerInResponse.length) {
          setGenerating(false);
          setGenerated(true);
          clearInterval(intervalId);
        }
        return newValue;
      });
    }, 60);
  };

  useEffect(() => {
    if (open) {
      setTitle(defaultTitle);
      setContent('');
      setPosition(-1);
      setGenerated(false);
      reset();
    }
  }, [defaultTitle, open, reset]);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={close}
    >
      <DialogTitle onClose={close}>
        <Box display="flex" flexDirection="column">
          AIアシスタント
          <Typography variant="body2">
            お知らせのタイトルと簡単な内容を入力すると、AIが自動でお知らせの本文を提案してくれます。
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <FormLabel label="お知らせのタイトル" />
          <OutlinedInput
            fullWidth
            placeholder="土日限定でフルーツの販売をはじめます！"
            disabled={generating}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>

        <FormLabel label="お知らせしたい内容や含めたい要素を教えて下さい" />
        <OutlinedInput
          multiline
          fullWidth
          rows={3}
          placeholder={[
            "例:",
            "今月末まで土日限定のスイーツを販売するのでお店に来て欲しい",
            "絵文字を使って明るい感じのお知らせにしたい"
          ].join("\n")}
          disabled={generating}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />

        {position > -1 && (
          <Box bgcolor="#eee" p={1} borderRadius={1} mt={2}>
            <Typography whiteSpace="pre-wrap">
              {answer.substring(0, position + 1)}{answer.length > position && '|'}
            </Typography>
          </Box>
        )}

        <Alert severity="warning" sx={{ mt: 2, fontSize: 14 }}>
          AIの提案した本文を利用する場合、提案された内容に間違いがないかどうか必ずご確認ください。
        </Alert>
      </DialogContent>

      <DialogActions sx={{ alignItems: "center" }}>
        <Button
          variant={generated ? "text" : "contained"}
          color="submit"
          disabled={!canGenerate || generating}
          onClick={handleGenerate}
        >
          {generating && (
            <Box display="flex" gap={1} alignItems="center">
              <CircularProgress size={20} />
              AIが本文を書いています
            </Box>
          )}

          {!generating && (
            <>{generated ? "別の本文を提案してもらう" : "AIに本文を提案してもらう"}</>
          )}
        </Button>

        {generated && (
          <Button
            variant="contained"
            color="submit"
            disabled={generating}
            onClick={() => onSubmit(title, answer)}
          >
            この内容をお知らせの本文に追記する
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
};

export default NoticeContentBodyAIAsistantDialog;
