import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "../../DialogTitle";
import { Condition } from "../type";
import { getErrors, generateDefaultCondition } from "../utils";
import ConditionForm from "./ConditionForm";

type Errors = { [key:number]: string };
type Props = {
  defaultConditions?: Condition[];
  show: boolean;
  onSubmit: (conditions: Condition[]) => void;
  onDelete: () => void;
  onClose: () => void;
};

const UserGroupModal = ({
  defaultConditions,
  show,
  onSubmit,
  onDelete,
  onClose
}: Props): JSX.Element => {
  const [conditions, setConditions] = useState<Condition[]>(defaultConditions || []);
  const [errors, setErrors] = useState<Errors>({});
  const resetConditions = useCallback(() => setConditions([generateDefaultCondition()]), []);

  const validateConditions = (targets: Condition[]): boolean => {
    if (targets.length === 0) {
      return false;
    }

    const errors = getErrors(targets);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (show) {
      setErrors({});
      if (defaultConditions === undefined || defaultConditions.length === 0) {
        resetConditions();
      } else {
        setConditions(defaultConditions);
      }
    }
  }, [show, defaultConditions, resetConditions]);

  const handleAddNewCondition = () => {
    const newConditions = [...conditions, generateDefaultCondition()];
    setConditions(newConditions);
    validateConditions(conditions);
  };

  const handleUpdateCondition = (index: number, condition: Condition) => {
    const newConditions = [...conditions];
    newConditions[index] = condition;
    setConditions(newConditions);

    if (Boolean(errors[condition.key])) {
      validateConditions(newConditions);
    }
  };

  const handleDeleteCondition = (index: number) => {
    if (conditions.length === 1) return;

    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  const handleSubmit = () => {
    if (defaultConditions === conditions) {
      onClose();
    } else if (defaultConditions && conditions.length === 1 && !conditions[0].condition_type) {
      onDelete();
    } else if (validateConditions(conditions)) {
      onSubmit(conditions);
    }
  };

  return (
    <Dialog
      fullWidth
      open={show}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>
        絞り込み条件
      </DialogTitle>

      <DialogContent>
        {conditions.map((condition, i) => (
          <React.Fragment key={condition.key}>
            {i > 0 && (
              <StyledConditionDivider>
                <hr />
                <div>かつ</div>
                <hr />
              </StyledConditionDivider>
            )}

            <ConditionForm
              conditionLabel={`条件${i + 1}`}
              condition={condition}
              errorMessage={errors[condition.key]}
              onUpdateCondition={newCondition => handleUpdateCondition(i, newCondition)}
              onDelete={() => handleDeleteCondition(i)}
            />
          </React.Fragment>
        ))}

        <StyledAddConditionButton onClick={handleAddNewCondition}>
          <i className="fas fa-plus" />
          条件を追加
        </StyledAddConditionButton>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          color="cancel"
          onClick={resetConditions}
        >
          条件をリセットする
        </Button>

        <Button
          fullWidth
          variant="contained"
          color="submit"
          onClick={handleSubmit}
        >
          この条件で絞り込む
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledConditionDivider = styled.div({
  display: "flex",
  alignItems: "center",
  "& > hr": {
    flex: 1,
    borderColor: "#bababa"
  },
  "& > div": {
    fontWeight: 700,
    margin: "0 8px",
    fontSize: 15,
    color: "#bababa"
  }
});

const StyledAddConditionButton = styled.div({
  textAlign: "center",
  width: "100%",
  color: "#1DC7EA",
  cursor: "pointer",
  backgroundColor: "rgba(29, 199, 234, 0.1)",
  borderRadius: 4,
  padding: "8px 16px",
  borderWidth: 0,
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 8,
  "&:hover": {
    color: "#0eb0d1",
    backgroundColor: "rgba(29, 199, 234, 0.2)"
  },
  "& > i": {
    marginRight: 8
  }
});

export default UserGroupModal;
