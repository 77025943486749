import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Card } from "../../../components/Card/Card";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SwitchForm from "../../../components/FormInputs/SwitchForm";
import TooltipWithLabel from "../../../components/Tooltip/TooltipWithLabel";
import {
  CouponStatus,
  CouponStatusType,
  PrivilegeCouponType,
} from "../../../containers/entities/PrivilegeCouponContentEntityContainer";

type Props = {
  editMode: boolean;
  coupon: PrivilegeCouponType;
  onClickPublishButton: (status: CouponStatusType) => Promise<void>;
  stampCardMode: boolean;
};

const PrivilegeCouponFormActiveSettingsCard: FC<Props> = ({
  editMode,
  coupon,
  onClickPublishButton,
  stampCardMode,
}) => {
  const { setValue, watch } = useFormContext<{ coupon: PrivilegeCouponType & { image: string } }>();
  const watchStatus = watch("coupon.status") as CouponStatusType;

  return (
    <Card
      title="有効設定"
      titleRightContent={stampCardMode && (
        <TooltipWithLabel
          tooltip="有効時にスタンプカードに反映されます"
          style={{ fontSize: 16, display: "inline-block" }}
        />
      )}
      content={
        <>
          <Row>
            <Col md={12}>
              <SwitchForm
                checkedText="有効"
                uncheckedText="無効"
                formMarginBottom={8}
                checked={watchStatus === CouponStatus.Publish}
                onChange={(value: boolean): void => {
                  const status = value ? CouponStatus.Publish : CouponStatus.Unpublished;
                  setValue("coupon.status", status, {
                    shouldDirty: true,
                  });
                }}
              />
            </Col>
          </Row>

          {editMode && (
            <Row style={{ marginRight: 0 }}>
              <Col>
                <LoadingButton
                  label="変更する"
                  loadingLabel="変更中..."
                  color="info"
                  fill
                  pullRight
                  loadingColor="red"
                  disabled={coupon?.status === watchStatus}
                  onClick={async (): Promise<void> => {
                    await onClickPublishButton(watchStatus);
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};

export default PrivilegeCouponFormActiveSettingsCard;
