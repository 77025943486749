import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";

import { Card } from "../../../components/Card/Card";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SelectorForm from "../../../components/FormInputs/SelectorForm";

import { RankUpMethod } from "../../../containers/entities/MembersCardContentEntityContainer";
import { RankUpMethodLabel } from "../../../constants/membersCard";
import { useNotification } from "../../../providers/NotificationProvider";
import { MembersCardForm } from "../type.d";

// ランクアップ方法は、ランクごとに保存されたランクアップ方法が参照されるように修正(2023/01/17)。
// 本Component上で会員証コンテンツのランクアップ方法が変更されると、
// バックエンド側でランクごとのそれは追従して変更されるが、
// 一度QRになるとランクごとのランクアップ方法は他のランクアップ方法に変更されなくなる。
// 現時点で、「一部ランクをQRで使いたい」顧客がいるため上記修正を行ったが、
// これによって「一度ランクアップ方法をQRコードにした」場合に他のランクアップ方法が使えなくなるため、
// 一時的に選択肢から削除している。
const OptionsArray: (boolean) => RankUpMethod[] = (canEdit) =>
  canEdit
    ? ["rank_up_by_check_in", "rank_up_by_payment", "rank_up_by_point"]
    : ["rank_up_by_qrcode"];

interface Props {
  canEdit: boolean;
  disabled: boolean;
  onSubmit: () => void;
  style: React.CSSProperties,
}

const RankSettingCard: React.FC<Props> = ({
  canEdit,
  disabled,
  onSubmit,
  style,
}) => {
  const { showErrorNotification } = useNotification();
  const { register, control, formState, handleSubmit } = useFormContext<MembersCardForm>();
  const { dirtyFields } = formState;
  const watchDefaultRankUpMethod = useWatch({ control, name: "default_rank_up_method"});

  const onError = () => {
    showErrorNotification("値が正しく入力されていません。");
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)} style={style}>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title="ランク設定"
            content={
              <>
                {/* ランクアップ方法 */}
                <SelectorForm
                  name="default_rank_up_method"
                  inputRef={register}
                  label="ランクアップ方法"
                  disabled={!canEdit || disabled}
                  options={OptionsArray(canEdit).map((method) => ({
                    label: RankUpMethodLabel[method],
                    value: method,
                  }))}
                  showChangeIndicator={dirtyFields.default_rank_up_method}
                />

                {/* 対象とする期間 */}
                <SelectorForm
                  name="rank_value_expiration_month"
                  inputRef={register({ valueAsNumber: true })}
                  label="対象とする期間"
                  disabled={watchDefaultRankUpMethod === "rank_up_by_qrcode" || disabled}
                  options={[...Array(25)].map((_, index) => ({
                    label: index ? `過去${index}ヶ月間` : "設定しない",
                    value: index,
                  }))}
                  showChangeIndicator={dirtyFields.rank_value_expiration_month}
                />

                <Row>
                  <Col xs={12}>
                    <LoadingButton
                      type="submit"
                      label="更新する"
                      loadingLabel="更新中..."
                      color="info"
                      fill
                      pullRight
                      disabled={Object.keys(dirtyFields).length === 0|| disabled}
                    />
                  </Col>
                </Row>
              </>
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

export default RankSettingCard;
