import React from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    cancel: Palette["primary"];
    submit: Palette["primary"];
    danger: Palette["primary"];
    neutral: Palette["primary"];
    toypoAdmin: Palette["primary"];
  }

  interface PaletteOptions {
    cancel: PaletteOptions["primary"];
    submit: PaletteOptions["primary"];
    danger: PaletteOptions["primary"];
    neutral: PaletteOptions["primary"];
    toypoAdmin: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    hiddenScrollbar: {
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    cancel: true;
    submit: true;
    danger: true;
    neutral: true;
    toypoAdmin: true;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    cancel: true;
    submit: true;
    danger: true;
    neutral: true;
  }
}

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    cancel: true;
    submit: true;
    danger: true;
    neutral: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    cancel: true;
    submit: true;
    danger: true;
    neutral: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsColorOverrides {
    cancel: true;
    submit: true;
    danger: true;
    neutral: true;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    danger: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    status: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}

const theme = createTheme({
  mixins: {
    hiddenScrollbar: {
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    }
  },
  palette: {
    cancel: {
      main: "#888",
      contrastText: "#fff"
    },
    submit: {
      main: "#1dc7ea",
      contrastText: "#fff"
    },
    danger: {
      main: "#ff4a55",
      contrastText: "#fff"
    },
    neutral: {
      main: "#444"
    },
    toypoAdmin: {
      main: "#9C27B0",
      contrastText: "#fff"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 767
    },
  },
  components: {
    MuiRadio: {
      defaultProps: {
        color: "submit"
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: "submit"
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: "small",
        label: null
      },
      styleOverrides: {
        root: {
          "&.MuiInputBase-multiline": {
            padding: 12,
            lineHeight: 1.3
          }
        },
        input: {
          fontSize: 16
        },
        notchedOutline: {
          "& > legend > span": {
            display: "none"
          }
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: "auto"
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 14,
          boxShadow: "0 0 0",
          "&:hover": {
            boxShadow: "0 0 0",
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 14,
          textTransform: "none",
          boxShadow: "0 0 0",
          "&:hover": {
            boxShadow: "0 0 0",
          },
          "&:active": {
            boxShadow: "0 0 0",
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40
        },
        flexContainer: {
          borderBottom: "solid 1px #eee"
        },
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          padding: "4px 8px",
          minWidth: 70,
          minHeight: 40,
          color: "#1dc7ea",
          marginBottom: -1,
          marginRight: 4,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#fff #fff #eee #fff",
          "&:first-of-type": {
            marginLeft: 8
          },
          "&:hover": {
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#eee",
            borderRadius: "4px 4px 0 0",
          },
          "&.Mui-selected": {
            color: "#333",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#eee #eee #fff #eee",
            borderRadius: "4px 4px 0 0",
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& > tbody > tr:nth-of-type(even)": {
            backgroundColor: "#f9f9f9"
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700,
          color: "#9a9a9a",
          padding: "8px 16px"
        },
        body: {
          fontSize: 14,
          padding: "12px 16px"
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: {
            variant: "status"
          },
          style: {
            textTransform: "none",
            borderRadius: 4,
            height: "auto",
            padding: "4px 8px",
            fontSize: 14,
            fontWeight: 700,
            "& .MuiChip-label": {
              padding: 0
            }
          },
        }
      ]
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "title"
          },
          style: {
            fontSize: 22,
            fontWeight: 700,
            display: "block"
          },
        }
      ],
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: "flex-start"
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 22,
          fontWeight: 700,
          borderBottom: "solid 1px #eee",
          padding: "12px 16px",
          marginBottom: 12
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: "flex",
          padding: "0 16px 16px 16px"
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          "&:before": {
            opacity: 1,
            backgroundColor: "#eee"
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          border: "solid 1px #ddd"
        }
      }
    }
  },
});

const ThemeProvider = ({
  children
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
