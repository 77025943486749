import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Prompt } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Section from "../../../components/Section";
import FormLabel from "../../../components/FormInputs/FormLabel";
import { OrderFeatureResource } from "../../../types/order";

export type TakeoutSettingsFormType = {
  order_duration: number;
  order_duration_on_busy: number;
  minimum_order_price: number;
  can_pay_at_store: boolean;
};

type Props = {
  orderFeature: OrderFeatureResource;
  disabled: boolean;
  onSubmit: (params: TakeoutSettingsFormType) => void;
};

const TakeoutSettingsForm = ({
  orderFeature,
  disabled,
  onSubmit
}: Props): JSX.Element => {
  const defaultValues = generateDefaultValues(orderFeature);
  const { control, handleSubmit, errors, formState, reset } = useForm<TakeoutSettingsFormType>({ defaultValues });
  const { isDirty } = formState;

  useEffect(() => {
    reset(generateDefaultValues(orderFeature));
  }, [reset, orderFeature]);

  return (
    <>
      <Section title="テイクアウト基本設定">
        <Box mb={2}>
          <FormLabel label="仕上がり目安時間(商品の調理や準備にかかる時間)" />
          <Box display="flex" flexWrap="wrap" gap={1}>
            <Box display="flex" alignItems="center">
              通常時
              <Controller
                control={control}
                name="order_duration"
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    disabled={disabled}
                    sx={{ ml: 1, mr: 2 }}
                    onChange={({ target }) => onChange(Number(target.value))}
                  >
                    {DURATIONS.map((duration) => (
                      <MenuItem key={duration} value={duration}>{duration}分</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>

            <Box display="flex" alignItems="center">
              忙しい時
              <Controller
                control={control}
                name="order_duration_on_busy"
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    disabled={disabled}
                    sx={{ ml: 1 }}
                    onChange={({ target }) => onChange(Number(target.value))}
                  >
                    {DURATIONS.map((duration) => (
                      <MenuItem key={duration} value={duration}>{duration}分</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box mb={2}>
          <FormLabel label="注文最低金額" />
          <Controller
            control={control}
            name="minimum_order_price"
            rules={{
              required: "注文最低金額を入力してください。",
              min: { value: 50, message: "注文最低金額は50円以上にしてください。" }
            }}
            render={({ onChange, value }) => (
              <OutlinedInput
                type="number"
                value={value}
                disabled={disabled}
                sx={{ mr: 1 }}
                onChange={({ target }) => onChange(target.value === "" ? 1 : Math.max(1, Number(target.value)))}
              />
            )}
          />
          円
          {errors.minimum_order_price?.message && (
            <FormHelperText error>
              {errors.minimum_order_price?.message}
            </FormHelperText>
          )}
        </Box>

        <Box>
          <Controller
            control={control}
            name="can_pay_at_store"
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                sx={{ color: value ? undefined : "#aaa" }}
                label="店舗での支払いを許可する"
              />
            )}
          />
        </Box>
      </Section>

      <Box display="flex" justifyContent="center" mb={5}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          sx={{ width: 300 }}
          onClick={handleSubmit(onSubmit)}
        >
          変更を保存する
        </Button>
      </Box>

      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />
    </>
  );
};

const generateDefaultValues = (orderFeature: OrderFeatureResource): TakeoutSettingsFormType => ({
  order_duration: orderFeature.order_duration,
  order_duration_on_busy: orderFeature.order_duration_on_busy,
  minimum_order_price: orderFeature.minimum_order_price,
  can_pay_at_store: orderFeature.can_pay_at_store
});

const DURATIONS = [...Array(24)].map((_, i) => (i + 1) * 5);

export default TakeoutSettingsForm;
