export const LogStatusLabel = {
  active: "有効",
  cancel: "取消済み/返金済み",
} as const;

export const IllegalStatusLabel = {
  not_check: "未確認なログ",
  legal: "正常なログ",
  illegal: "不正なログ",
  doubtful: "疑わしいログ",
} as const;

// ログ状態
export const IllegalLogStatusBackgroundColor = {
  cancel: "#F6F6F6",
  doubtful: "#fff9f0",
  illegal: "#fff7f7"
} as const;

// 不正、疑惑ログに表示するテキストカラー
export const IllegalLogStatusFontColor = {
  illegal: "#ED766A",
  doubtful: "#F4BA72",
} as const;

export const ContentTypeMappings = {
  open_notice: "notice",
  get_notice: "notice",
  get_coupon: "coupon",
  use_coupon: "coupon",
  open_coupon: "coupon",
  get_stamp: "stamp",
  get_check_in_stamp: "stamp",
  get_custom_stamp: "stamp",
  complete_stamp_card: "stamp",
  get_payment_stamp: "stamp",
  use_ticket: "ticket",
  get_ticket: "ticket",
  use_service: "service",
  get_service: "service",
  expire_point: "point",
  get_point: "point",
  remove_point: "point",
  get_check_in_point: "point",
  get_custom_point: "point",
  get_payment_point:  "point",
  use_point: "point",
  use_subscription: "subscription",
  get_subscription: "subscription",
  update_subscription: "subscription",
  cancel_subscription: "subscription",
  cancel_request_subscription: "subscription",
  use_house_money: "house_money",
  charge_house_money_by_credit: "house_money",
  charge_house_money_by_cash: "house_money"
} as const;

export const ActionTypeLabels = {
  open_app: "アプリ起動",
  view_store_page: "お店アプリページ表示(登録前)",
  open_notice: "お知らせ閲覧",
  get_notice: "お知らせ受取",
  get_coupon: "クーポン受取",
  open_coupon: "クーポン開封",
  use_coupon: "クーポン利用",
  get_stamp: "スタンプ獲得(指定数)",
  get_check_in_stamp: "スタンプ獲得(チェックイン)",
  complete_stamp_card: "スタンプカードコンプリート",
  get_payment_stamp: "スタンプ獲得(お会計)",
  connect_store: "お店アプリ登録",
  delete_connection: "お店アプリ登録解除",
  reconnect_store:  "お店アプリ再登録",
  check_in: "チェックイン",
  use_ticket: "チケット利用",
  get_ticket: "チケット購入",
  use_service: "サービス利用",
  get_service: "サービス獲得",
  expire_point: "ポイント失効",
  get_point: "ポイント獲得(指定数)",
  remove_point: "ポイント削除(指定数)",
  get_check_in_point: "ポイント獲得(チェックイン)",
  get_payment_point: "ポイント獲得(お会計)",
  use_point: "ポイント交換",
  rank_up: "ランクアップ",
  rank_down: "ランクダウン",
  rank_update: "ランク更新",
  get_order: "オーダー",
  use_subscription: "サブスク利用",
  get_subscription: "サブスク購入",
  update_subscription: "サブスク更新",
  cancel_subscription: "サブスク解約",
  cancel_request_subscription: "サブスク解約リクエスト",
  use_house_money: "ハウスマネー決済",
  charge_house_money_by_credit: "ハウスマネーチャージ(クレジットカード)",
  charge_house_money_by_cash: "ハウスマネーチャージ(現金)",
} as const;

export const ActionTypes = [
  "use_coupon",
  "open_coupon",
  "get_coupon",
  "open_notice",
  "get_notice",
  "get_stamp",
  "get_check_in_stamp",
  "get_payment_stamp",
  "connect_store",
  "delete_connection",
  "reconnect_store",
  "check_in",
  "use_ticket",
  "get_ticket",
  "use_subscription",
  "get_subscription",
  "update_subscription",
  "cancel_subscription",
  "cancel_request_subscription",
  "get_order",
  "expire_point",
  "get_point",
  "remove_point",
  "get_check_in_point",
  "get_payment_point",
  "use_point",
  "rank_up",
  "rank_update",
  "use_service",
  "use_house_money",
  "charge_house_money_by_credit",
  "charge_house_money_by_cash"
] as const;
