import styled from "@emotion/styled";
import React, { useEffect, useCallback, useState } from "react";
import moment from "moment";
import { Pagination, CircularProgress } from "@mui/material";
import { Gift } from "phosphor-react";
import { Table } from "react-bootstrap";
import { useApi, useGetApiCall, usePcSizeFlag } from "../../../lib/hooks";
import { CouponStatus } from "../../../types/coupon";
import { CouponsResponse, CouponCountByStatusResponse } from "../../../types/api/coupon.d";
import CustomButton from "../../../components/CustomButton/CustomButton";
import SectionTitle from "./SectionTitle";
import NoItemContent from "../../../components/Utils/NoItemContent";

const CouponStatusLabel = {
  active: "利用可能",
  questionnaire_unanswered: "アンケート未回答",
  toypo_questionnaire_unanswered: "アンケート未回答",
  used: "利用済み",
  expired: "期限切れ"
};

const CouponStatuses = [
  "questionnaire_unanswered",
  "toypo_questionnaire_unanswered",
  "active",
  "used",
  "expired"
] as const;

const QuestionnaireStatuses = [
  "questionnaire_unanswered",
  "toypo_questionnaire_unanswered"
];

const DEFAULT_STATUS = "active";

type Props = {
  userId: number;
  onClickCreateCoupon: () => void;
};

type GetCouponsArgs = {
  page?: number;
  couponStatus?: CouponStatus | null;
};

type CountByStatus = { CouponStatus: number };

const Coupon = ({ userId, onClickCreateCoupon }: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();
  const [selectedStatus, setSelectedStatus] = useState<CouponStatus | null>(DEFAULT_STATUS);
  const displayCount = (count: number) => count >= 100 ? "99+" : count;

  const {
    api,
    response: couponsResponse,
    totalPages,
    currentPage,
    loading,
    loaded: couponsLoaded,
  } = useApi<CouponsResponse>();
  const {
    response,
    loaded: countByStatusLoaded
  } = useGetApiCall<CouponCountByStatusResponse>(`/users/${userId}/coupon_count_by_status`);
  const loaded = couponsLoaded && countByStatusLoaded;
  const coupons = couponsResponse?.coupons || [];
  const countByStatus: {} | CountByStatus = (response?.count_by_status || [])
    .reduce((results, v) => ({ [v.status]: v.count, ...results }), {});
  const totalCouponCount = response?.total_count;
  // ステータスで絞り込むラベルを表示するためのステータス一覧を生成する。
  // 基本的には該当のステータスのクーポンを1枚も持っていなくても
  // ラベルとして表示する仕様になっている。
  // しかし、"アンケート未回答"というステータスのクーポンは
  // 1枚も持っていない場合はラベルを非表示にする。
  // 理由としては以下の通り。
  // - アンケート機能を有効にしており、かつアンケート回答を必須にしている
  // - エンジニアが裏側でアンケートURLを設定している
  // というあまり一般的ではない状況に発生するステータスであるため
  // アンケート未回答ステータスに関しては常に表示されていると顧客に対して混乱を招くため。
  const visibleCouponStatuses = CouponStatuses
    .filter(status => !QuestionnaireStatuses.includes(status) || countByStatus[status] !== 0);

  const getCoupons = useCallback((params: GetCouponsArgs = {}) => {
    api.get(`/users/${userId}/coupons`, {
      page: params.page || 1,
      limit: 5,
      status: params.couponStatus,
    });
  }, [api, userId]);

  useEffect(() => {
    getCoupons({ couponStatus: DEFAULT_STATUS });
  }, [getCoupons]);

  const filterCouponByStatus = (status: CouponStatus) => {
    if (loading) return;

    if (status === selectedStatus) {
      setSelectedStatus(null);
      getCoupons();
    } else {
      setSelectedStatus(status);
      getCoupons({ couponStatus: status });
    }
  };

  if (!loaded) {
    return (
      <StyledInitialLoadContainer>
        <CircularProgress />
      </StyledInitialLoadContainer>
    );
  }

  if (totalCouponCount === 0) {
    return (
      <div>
        <SectionTitle
          style={{ marginBottom: 12 }}
          title={<b>配信済みのクーポン</b>}
          element={
            <CustomButton bsStyle="info" fill pullRight onClick={onClickCreateCoupon}>
              <b>クーポンを作成</b>
            </CustomButton>
          }
        />

        <NoItemContent label="配信済みのクーポンはありません" icon={<Gift />} />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle
        style={{ marginBottom: 12 }}
        title={<b>配信済みのクーポン</b>}
        element={
          <CustomButton bsStyle="info" fill pullRight onClick={onClickCreateCoupon}>
            <b>クーポンを作成</b>
          </CustomButton>
        }
      />

      <StyledLabelContainer style={{ marginBottom: 8 }}>
      {visibleCouponStatuses.map((status) => (
        <StyledLabel
          key={status}
          filterable={!loading}
          selected={status === selectedStatus}
          onClick={() => filterCouponByStatus(status)}
        >
          <div>{CouponStatusLabel[status]}</div>
          <StyledCount>{displayCount(countByStatus[status])}</StyledCount>
        </StyledLabel>
      ))}
      </StyledLabelContainer>

      <StyledContainer>
        {loading && (
          <StyledProgressContainer>
            <CircularProgress />
          </StyledProgressContainer>
        )}

        {coupons.length === 0 && selectedStatus ? (
          <NoItemContent label={`${CouponStatusLabel[selectedStatus]}のクーポンはありません`} icon={<Gift />} />
        ) : isPcOrTablet ? (
          <Table striped hover>
            <thead>
              <tr>
                {["タイトル", "開封状況", "利用状況", "配信日時"].map((prop) => (
                  <th key={prop}>{prop}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {coupons.map(({ coupon, coupon_content, store }) => (
                <tr key={coupon.id}>
                  <td>
                    <StyledImageContainer>
                      {coupon_content.image_url && (
                        <StyledImage
                          key={coupon_content.id}
                          alt="クーポン画像"
                          src={coupon_content.image_url}
                          width={48}
                          height={30}
                        />
                      )}

                      <div>
                        <StyledCouponTitle>{coupon_content.benefit}</StyledCouponTitle>
                        <StyledStoreName>{store.name}</StyledStoreName>
                      </div>
                    </StyledImageContainer>
                  </td>
                  <td>
                    <StyledCouponReadStatus isRead={coupon.is_read}>
                      {coupon.is_read ? "開封済み" : "未開封"}
                    </StyledCouponReadStatus>
                  </td>
                  <td>
                    <StyledCouponStatus status={coupon.status}>
                      {CouponStatusLabel[coupon.status]}
                    </StyledCouponStatus>
                  </td>
                  <td>
                    {coupon.created_at
                      ? moment(coupon.created_at).format("YYYY/M/D(dddd) HH:mm")
                      : "----"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Table striped hover>
            <tbody>
              {coupons.map(({ coupon, coupon_content, store }) => (
                <tr key={coupon.id}>
                  <td>
                    <div style={{ display: "flex", gap: 16, alignItems: "flex-start" }}>
                      {coupon_content.image_url && (
                        <StyledImage
                          key={coupon_content.id}
                          alt="クーポン画像"
                          src={coupon_content.image_url}
                          width={48}
                          height={30}
                        />
                      )}

                      <div>
                        <div style={{ marginBottom: 4 }}>
                          <StyledCouponTitle>{coupon_content.benefit}</StyledCouponTitle>
                          <StyledStoreName>{store.name}</StyledStoreName>
                        </div>
  
                        <div style={{ color: "#7d7d7d" }}>
                          開封状況{" "}
                          <StyledCouponReadStatus isRead={coupon.is_read}>
                            {coupon.is_read ? "開封済み" : "未開封"}
                          </StyledCouponReadStatus>
                        </div>
                        <div style={{ color: "#7d7d7d" }}>
                          利用状況{" "}
                          <StyledCouponStatus status={coupon.status}>
                            {CouponStatusLabel[coupon.status]}
                          </StyledCouponStatus>
                        </div>
                        <div style={{ color: "#7d7d7d" }}>
                          配信日時{" "}
                          {coupon_content.publish_at
                            ? moment(coupon_content.publish_at).format("YYYY/M/D(dddd) HH:mm")
                            : "----"}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => getCoupons({ page, couponStatus: selectedStatus })}
            style={{ paddingBottom: 8, borderBottom: "1px solid #E8E8E8" }}
            sx={{ fontSize: 24, color: "black" }}
          />
        )}
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled("div")({
  position: "relative",
});

const StyledInitialLoadContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 150,
});

const StyledProgressContainer = styled("div")({
  position: "absolute",
  zIndex: 10,
  backgroundColor: "#ffffff6e",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledImageContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledImage = styled("img")({
  borderRadius: 2,
  objectFit: "cover",
  marginRight: 8,
});

const StyledCouponTitle = styled("div")({
  fontWeight: "bold"
});

const StyledStoreName = styled("div")({
  color: "#9A9A9A",
  fontSize: 12,
});

const StyledCouponReadStatus = styled("span")<{ isRead: boolean }>(({ isRead }) => ({
  color: isRead ? "#00ddb4" : "#888",
  fontWeight: "bold",
}));

const StyledCouponStatus = styled("span")<{ status: CouponStatus }>(({ status }) => ({
  color: status === "expired" ? "#000" :
    status === "used" ? "#00ddb4" :
    "#888",
  fontWeight: "bold",
}));

const StyledLabelContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
});

const StyledLabel = styled("div")<{ filterable: boolean, selected: boolean }>(({ filterable, selected }) => ({
  display: "inline-flex",
  padding: "5px 6px",
  color: selected ? "#1DC7EA" : "#787878",
  borderColor: selected ? "#1DC7EA" : "#ddd",
  opacity: filterable ? 1 : 0.5,
  cursor: filterable ? "pointer" : "not-allowed",
  borderStyle: "solid",
  borderRadius: 4,
  backgroundColor: selected ? "#EDF9FC" : "white",
}));

const StyledCount = styled("div")({
  fontWeight: "bold",
  marginLeft: 4,
});

export default Coupon;
