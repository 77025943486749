import React, { useEffect, useState, useCallback } from "react";
import { intersection } from "lodash";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useHistory, useLocation } from "react-router-dom";
import { useNotification } from "../../providers/NotificationProvider";
import { useLoginContext } from "../../providers/LoginContextProvider";
import LaptopActivityLogTable from "./parts/LaptopActivityLogTable";
import MobileActivityLogTable from "./parts/MobileActivityLogTable";
import ActivityLogFilteringConditionElements from "./parts/ActivityLogFilteringConditionElements";
import ActivityLogIllegalStatusChangeModal from "./parts/ActivityLogIllegalStatusChangeModal";
import { useGetApiCall, useApi, useQuery, usePcSizeFlag, useBooleanState } from "../../lib/hooks";
import {
  ActionType,
  ActivityLogContentResponse,
  ActivityLogFilteringCondition,
  ActivityLogStatus,
  IllegalStatus,
  UserLogsResponse,
  ActivityLogResource
} from "../../types/activityLog";
import Section from "../../components/Section";
import { downloadCSVFile } from "../../lib/general";
import NoItemContent from "../../components/Utils/NoItemContent";
import { Activity } from "phosphor-react";
import ActivityLogFilteringModal from "./parts/ActivityLogFilteringModal";
import { deleteActivityLog, updateActivityLog } from "../../lib/api/activityLog";
import { ActionTypes } from "../../constants/activityLog";
import DisablingMask from "../../components/DisablingMask";

type QueryParams = ActivityLogFilteringCondition & {
  page: number;
  tab_type: TabType;
}

type TabType = "all" | "transaction" | "check_in" | "others";

const ActivityLogView = (): JSX.Element => {
  const history = useHistory();
  const isPcOrTablet = usePcSizeFlag();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { currentStore, fromWebView } = useLoginContext();
  const [isFilteringModalOpen, openFilteringModal, closeFilteringModal] = useBooleanState(false);
  const [isIllegalStatusChangeModalOpen, openIllegalStatusChangeModal, closeIllegalStatusChangeModal] = useBooleanState(false);
  const [selectedIllegalStatus, setSelectedIllegalStatus] = useState<IllegalStatus | null>("not_check");
  const [doubtfulReasonText, setDoubtfulReasonText] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedActivityLog, setSelectedActivityLog] = useState<ActivityLogResource | null>(null);
  const { response: contentsResponse } = useGetApiCall<ActivityLogContentResponse>("/activity_logs/contents");
  const {
    changeUrlAndReloadActivityLogs,
    activityLogs,
    totalPages,
    currentPage,
    queryParams,
    loading,
    downloadLogs
  } = useActivityLogApi();
  const [selectedTab, setSelectedTab] = useState<TabType>(queryParams.tab_type);

  const activityLogFilteringCondition: ActivityLogFilteringCondition = {
    from: queryParams.from || null,
    to: queryParams.to || null,
    store_ids: queryParams.store_ids || [],
    action_types: queryParams.action_types || [],
    content_id: queryParams.content_id || null,
    statuses: queryParams.statuses || [],
    illegal_statuses: queryParams.illegal_statuses || []
  };

  const handleUpdateIllegalStatus = async () => {
    const params = {
      illegal_status: selectedIllegalStatus,
      doubtful_reason_text: doubtfulReasonText
    };
    updateActivityLog({ id: selectedActivityLog!.id, params }).then(() => {
      closeIllegalStatusChangeModal();
      setSelectedIllegalStatus(null);
      setDoubtfulReasonText("");
      changeUrlAndReloadActivityLogs({ ...queryParams })
      showSuccessNotification("不正ステータスを変更しました。");
    }).catch((err) => showErrorNotification(err.message));
  };

  const handleDeleteActionLogs = (id: number, doCancelDependentLogs = false) => {
    const message = doCancelDependentLogs ? "チェックイン処理を取り消しますか？" : "このログを取り消しますか？";
    if (window.confirm(message)) {
      deleteActivityLog(id, doCancelDependentLogs)
        .then(() => {
          showSuccessNotification("取り消しました。")
          changeUrlAndReloadActivityLogs({ ...queryParams })
        })
        .catch((err) => showErrorNotification(err?.response?.data?.message || err.message));
    }
  };

  const isPayment = (activityLog: ActivityLogResource) =>
    activityLog.tags.some((tag) => ["charge", "payment"].includes(tag.type));

  return (
    <DisablingMask mask={loading}>
      <Section sx={{padding: 0}}>
        <Box pt={2} px={2}>
          <Box display="flex" justifyContent="end" gap={2}>
            <Button
              variant="outlined"
              color="submit"
              startIcon={<i className="ri-equalizer-line" />}
              onClick={openFilteringModal}
              sx={{ width: !isPcOrTablet ? "100%" : null, height: !isPcOrTablet ? 36 : null }}
            >
              絞り込み設定
            </Button>

            {!fromWebView && isPcOrTablet && (
              <Button
                variant="outlined"
                color="cancel"
                onClick={downloadLogs}
              >
                CSV出力
              </Button>
            )}
          </Box>

          {contentsResponse && (
            <Box my={1.5}>
              <ActivityLogFilteringConditionElements
                store={currentStore}
                condition={activityLogFilteringCondition}
                contents={contentsResponse}
              />
            </Box>
          )}
        </Box>

        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ mb: 2 }}
          onChange={(_, tab: TabType) => {
            setSelectedTab(tab);
            changeUrlAndReloadActivityLogs({ ...queryParams, tab_type: tab, page: 1 });
          }}
        >
          <Tab label="すべて" value="all" />
          <Tab label="取引" value="transaction" />
          <Tab label="チェックイン" value="check_in" />
          <Tab label="その他" value="others" />
        </Tabs>

        {isPcOrTablet ? (
          <LaptopActivityLogTable
            activityLogs={activityLogs}
            onClickMenu={(e, activityLog) => {
              setMenuAnchorEl(e.currentTarget);
              setSelectedActivityLog(activityLog);
            }}
          />
        ) : (
          <MobileActivityLogTable
            activityLogs={activityLogs}
            onClickMenu={(e, activityLog) => {
              setMenuAnchorEl(e.currentTarget);
              setSelectedActivityLog(activityLog);
            }}
          />
        )}

        {activityLogs.length === 0 && (
          <NoItemContent
            icon={<Activity size={32} weight="bold" />}
            label="利用履歴がありません"
          />
        )}

        {totalPages > 1 && activityLogs.length !== 0 && (
          <Pagination
            sx={{ my: 1 }}
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => changeUrlAndReloadActivityLogs({ ...queryParams, page })}
          />
        )}
      </Section>

      {selectedActivityLog && (
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
          onClick={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={() => history.push(`customers/detail?user_id=${selectedActivityLog.user_id}`)}>
            ユーザー情報を開く
          </MenuItem>

          <MenuItem
            onClick={() => {
              openIllegalStatusChangeModal();
              setSelectedIllegalStatus(selectedActivityLog.illegal_status);
              setDoubtfulReasonText(selectedActivityLog.doubtful_reason_text);
            }}
          >
            不正ステータスを変更する
          </MenuItem>

          {selectedActivityLog.status !== "cancel" && (
            <MenuItem
              sx={{ color: (theme) => theme.palette.danger.main }}
              onClick={() => handleDeleteActionLogs(selectedActivityLog.id)}
            >
              {isPayment(selectedActivityLog) ? "返金する" : "このログを取り消す"}
            </MenuItem>
          )}

          {selectedActivityLog.status !== "cancel" && !isPayment(selectedActivityLog) && (
            <MenuItem
              sx={{ color: (theme) => theme.palette.danger.main }}
              onClick={() => handleDeleteActionLogs(selectedActivityLog.id, true)}
            >
              チェックイン処理を取り消す
            </MenuItem>
          )}
        </Menu>
      )}


      {contentsResponse && (
        <ActivityLogFilteringModal
          contents={contentsResponse}
          defaultCondition={activityLogFilteringCondition}
          onSubmit={(condition) => changeUrlAndReloadActivityLogs({ ...queryParams, ...condition, page: 1 })}
          open={isFilteringModalOpen}
          onClose={closeFilteringModal}
        />
      )}

      <ActivityLogIllegalStatusChangeModal
        show={isIllegalStatusChangeModalOpen}
        onHide={closeIllegalStatusChangeModal}
        selectedIllegalStatus={selectedIllegalStatus}
        setSelectedIllegalStatus={setSelectedIllegalStatus}
        illegalStatuses={["not_check", "legal", "illegal", "doubtful"]}
        onClickUpdateIllegalStatusButton={handleUpdateIllegalStatus}
        doubtfulReasonText={doubtfulReasonText}
        setDoubtfulReasonText={setDoubtfulReasonText}
      />
    </DisablingMask>
  );
};

const useActivityLogApi = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { api, response, totalPages, currentPage, loading } = useApi<UserLogsResponse>();
  const downloadApi = useApi();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: Number(query.get("page")) || 1,
    from: query.get("from") || "",
    to: query.get("to") || "",
    tab_type: query.get("tab_type") as TabType || "all",
    store_ids: query.getAll("store_ids[]").map(Number),
    action_types: query.getAll("action_types[]") as ActionType[] || [],
    content_id: Number(query.get("content_id")) || null,
    statuses: query.getAll("statuses[]") as ActivityLogStatus[] || [],
    illegal_statuses: query.getAll("illegal_statuses[]") as IllegalStatus[] || []
  });

  const toQueryString = useCallback((params: QueryParams) => {
    const { page, from, to, store_ids, content_id, statuses, illegal_statuses, tab_type, action_types } = params;
    let queryString = `page=${page || 1}`;
    if (from && to) queryString += `&from=${from}&to=${to}`;
    if (store_ids) store_ids.forEach(v => queryString += `&store_ids[]=${v}`);
    if (content_id) queryString += `&content_id=${content_id}`;
    if (statuses) statuses.forEach(v => queryString += `&statuses[]=${v}`);
    if (illegal_statuses) illegal_statuses.forEach(v => queryString += `&illegal_statuses[]=${v}`);
    if (tab_type) queryString += `&tab_type=${tab_type}`;
    if (action_types) action_types.forEach(v => queryString += `&action_types[]=${v}`);
    return queryString;
  }, []);

  const changeUrl = useCallback((params: QueryParams) => {
    setQueryParams(params);
    history.replace(`${location.pathname}?${toQueryString(params)}`);
  }, [history, location.pathname, toQueryString]);

  const downloadLogs = async () => {
    const response = await downloadApi.api.blob(`/user_logs.csv?${toQueryString(queryParams)}`)
    downloadCSVFile(response);
  };

  useEffect(() => {
    const { page, from, to, store_ids, content_id, statuses, illegal_statuses, tab_type, action_types } = queryParams;

    let queryString = `page=${page || 1}`;
    if (from && to) queryString += `&from=${from}&to=${to}`;
    if (store_ids) store_ids.forEach(v => queryString += `&store_ids[]=${v}`);
    if (content_id) queryString += `&content_id=${content_id}`;
    if (statuses) statuses.forEach(v => queryString += `&statuses[]=${v}`);
    if (illegal_statuses) illegal_statuses.forEach(v => queryString += `&illegal_statuses[]=${v}`);

    const mergedActionTypes: string[] = action_types && action_types.length > 0
      ? intersection(action_types, ActionTypesByTab[tab_type || "all"])
      : ActionTypesByTab[tab_type || "all"];

    mergedActionTypes.length > 0
      ? mergedActionTypes.forEach(v => queryString += `&action_types[]=${v}`)
      : queryString += `&action_types[]`;

    api.get(`/user_logs?${queryString}`);
  }, [api, queryParams]);

  return {
    changeUrlAndReloadActivityLogs: changeUrl,
    activityLogs: response?.user_logs ?? [],
    totalPages,
    currentPage,
    queryParams,
    downloadLogs,
    loading: loading || downloadApi.loading
  };
};

const IgnoreActionTypes = ["open_coupon", "get_coupon", "open_notice", "get_notice"]

const ActionTypesByTab = {
  all: ActionTypes.filter((type) => !IgnoreActionTypes.includes(type)),
  transaction: [
    "get_ticket",
    "get_subscription",
    "update_subscription",
    "get_order",
    "use_house_money",
    "charge_house_money_by_credit",
    "charge_house_money_by_cash"
  ],
  check_in: [
    "check_in"
  ],
  others: [
    "use_coupon",
    "get_stamp",
    "get_check_in_stamp",
    "get_payment_stamp",
    "connect_store",
    "delete_connection",
    "reconnect_store",
    "use_ticket",
    "use_subscription",
    "cancel_subscription",
    "cancel_request_subscription",
    "expire_point",
    "get_point",
    "remove_point",
    "get_check_in_point",
    "get_payment_point",
    "use_point",
    "rank_up",
    "rank_update",
    "use_service"
  ]
} as const;

export default ActivityLogView;
