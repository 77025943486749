import React from "react";

interface Props {
  image: string;
  description: string;
  what: string;
}

const FeatureDetail: React.FC<Props> = ({ image, description, what }) => (
  <div>
    {/* 画像 */}
    <div>
      <img src={image} alt="機能の詳細" style={{ width: "100%" }} />
    </div>

    {/* 内容 */}
    <div style={{ display: "grid", gap: 24, marginTop: 32 }}>
      <div>
        <Title title="機能の説明" />
        <div style={{ whiteSpace: "break-spaces" }}>{description}</div>
      </div>

      <div>
        <Title title="できること" />
        <div style={{ whiteSpace: "break-spaces" }}>{what}</div>
      </div>
    </div>
  </div>
);

export default FeatureDetail;

const Title: React.FC<{ title: string }> = ({ title }) => (
  <div
    style={{
      fontSize: 16,
      fontWeight: "bold",
      borderBottom: "1px solid #DFDFDF",
      paddingBottom: 10,
      marginBottom: 10,
    }}
  >
    {title}
  </div>
);
