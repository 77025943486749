import React, { useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNotification } from "../../../providers/NotificationProvider";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import FormInputs from "../../../components/FormInputs/FormInputs";
import { useApi } from "../../../lib/hooks";

interface Props {
  show: boolean;
  onHide: () => void;
  reloadData: () => Promise<void>;
}

const CreateGroupModal = ({
  show,
  onHide,
  reloadData,
}: Props): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { api, loading } = useApi("/staff");
  const { register, unregister, handleSubmit, errors, formState } = useForm({
    defaultValues: { group: { name: "" } },
  });
  const { isDirty } = formState;

  const onSubmit = async (data) => {
    const params = {
      name: data.group.name,
      store_type: "store_group",
      email: "sample@example.com"
    };
    const response = await api.post("/owner/stores", { store: params });
    if (!response) return;

    showSuccessNotification("グループを作成しました。");
    onHide();
    reloadData();
  };

  const onError = () => showErrorNotification("値が正しく入力されていません。");

  useEffect(() => {
    register({ name: "group.name" });

    return () => {
      unregister(["group.name"]);
    };
  }, [register, unregister]);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>グループを作成</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormInputs
            properties={[
              {
                name: "group.name",
                label: "グループ名",
                placeholder: "グループ名を入力",
                ncol: "col-md-12",
                type: "text",
                bsClass: "form-control",
                inputRef: register({
                  required: "グループ名を入力してください。",
                }),
                validationMessage: errors?.group?.name?.message,
              },
            ]}
          />

          <LoadingButton
            type="submit"
            label="作成する"
            loadingLabel="作成中..."
            color="info"
            fill
            block
            disabled={!isDirty}
            loading={loading}
          />
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default CreateGroupModal;
