import React, { useState, useRef, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { parse } from "csv-parse/dist/esm/sync";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AxiosError } from "axios";
import { MenuType } from "../../../types/menu";
import DialogTitle from "../../../components/DialogTitle";
import { useApi } from "../../../lib/hooks";
import { useNotification } from "../../../providers/NotificationProvider";
import MenuStatusChip from "../../../components/Chip/MenuStatusChip";

type Props = {
  menuType: MenuType,
  open: boolean;
  onClose: () => void;
};

type MenuPreview = {
  image_url: string | null;
  id: string | null;
  title: string;
  category_label: string;
  price: string;
  status_label: string;
  optionTemplateIds: string[];
};

type ApiError = {
  line_number: number;
  message: string;
};

const MenuStatusByLabel = {
  "下書き": "draft",
  "公開": "open",
  "非公開": "close",
  "売り切れ": "sold"
} as const;

const MenuCsvImportDialog = ({
  menuType,
  open,
  onClose,
}: Props): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const csvApi = useApi();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<ApiError[] | null>(null);
  const [previewMenus, setPreviewMenus] = useState<MenuPreview[]>([]);
  const [shouldReload, setShouldReload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // ダイアログを初期状態に戻す
  const cleanUp = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    
    setFile(null);
    setPreviewMenus([]);
    setErrors(null);
    setUploading(false);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    if (files && files[0]) {
      setFile(files[0]);
      loadMenuAsPreview(files[0]);
    }
    setErrors(null);
  };

  const loadMenuAsPreview = useCallback(async (loadedFile: File) => {
    if (loadedFile !== null) {
      const reader = new FileReader();
      reader.readAsText(loadedFile);
      reader.onload = async () => {
        const csv = reader.result as string;
        try {
          const rows = await parse(csv, { skip_empty_lines: true, relax_column_count: true, from: 2 }); // 1行目は行の説明という前提
          const loadedMenus = rows.map((row: string[]) => ({
            id: row[0] === "" ? null : row[0], // IDの欄に何もなければ新規作成として表示する
            image_url: row[1] === "" ? null : row[1],
            title: row[4],
            category_label: row[3],
            price: row[6],
            status_label: row[2],
            optionTemplateIds: row.slice(13).filter((item) => item !== ""),
          }));
          setPreviewMenus(loadedMenus);
        } catch {
          showErrorNotification("予期せぬエラーが発生しました。ファイルの形式を確かめた上で、もう一度お試しください。");
          cleanUp();
        }
      };
    }
  }, [showErrorNotification]);

  const handleClose = useCallback(() => {
    onClose();
    cleanUp();
    if(shouldReload) { // メニューが更新された場合にのみ、ダイアログを閉じた際にリロードする
      // TODO: リファクタリング適用後に消す
      // レビューのコンテキストを減らすために、feature/menu-import-by-csv-demo ブランチにあるオリジナルの実装のリファクタリングは削っている。
      // ただし、このリファクタリングがないとテーブルにある要素だけを更新する、という事ができないので一旦画面全体をリロードしている。
      window.location.reload(); 
    }
  }, [onClose, shouldReload]);

  const handleClickFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    return fileInputRef.current?.click();
  };

  const handleClickUpload = useCallback(async () => {
    if (!file) return;
    const params = new FormData();
    params.append("csv_file", file);
    params.append("menu_type", menuType);

    setUploading(true);
    try {
      // エラーメッセージはバリデーション結果が返ってくるので、こちらでcatchする
      const response = await csvApi.api.post("/menus/csv_import", params, { showErrorNotification: false, throwsError: true, timeout: 600000 });
      if (response !== null) {
        const menuCount = response.data.menus.length;
        showSuccessNotification(`${menuCount}件のメニューをインポート・編集しました`);
        cleanUp();
      }
      setShouldReload(true);
    } catch(error) {
      setFile(null); // これないと同名のファイルを再選択できなくなる
      // エラーからバリデーション結果を抽出
      const responseErrors = (error as AxiosError).response?.data?.errors as ApiError[];
      if (responseErrors) {
        setErrors(responseErrors);
        showErrorNotification("CSVの内容に誤りがあります。各項目のエラーをお確かめの上、修正して選択し直してください。");
      } else {
        showErrorNotification(
          "予期せぬエラーが発生しました。もう一度お試しください。しばらく時間が経っている場合は、インポートが処理中の可能性があります。しばらく待ってページを更新して下さい" ||
            (error as AxiosError).response?.data?.message ||
            (error as AxiosError).message
        );
      }
    }
    setUploading(false);
  }, [csvApi.api, file, menuType, showErrorNotification, showSuccessNotification]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <DialogTitle>
        メニューのCSVインポート
      </DialogTitle>
      <DialogScrollContent>
        {previewMenus.length === 0 &&
          <EmptyDialogContent>CSVファイルを選択してください</EmptyDialogContent>
        }
        {previewMenus.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>ID</TableCell>
                <TableCell>タイトル</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>値段</TableCell>
                <TableCell>状態</TableCell>
                <TableCell>オプション</TableCell>
                {errors && <TableCell width="40%">エラー</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewMenus.map((menu, index) => (
                <TableRow>
                  <TableCell width={48}>
                    {menu.image_url && <StyledMenuImage alt="メニュー画像" src={menu.image_url} />}
                  </TableCell>
                  <TableCell>{menu.id ?? "新規追加"}</TableCell>
                  <TableCell>{menu.title}</TableCell>
                  <TableCell>{menu.category_label}</TableCell>
                  <TableCell>{menu.price}円</TableCell>
                  <TableCell>
                    {MenuStatusByLabel[menu.status_label] &&
                      <MenuStatusChip
                      status={MenuStatusByLabel[menu.status_label]}
                      />
                    }
                    {!MenuStatusByLabel[menu.status_label] &&
                      menu.status_label
                    }
                  </TableCell>
                  <TableCell>{menu.optionTemplateIds.length}個のオプションを指定</TableCell>
                  {errors &&
                    // line_number は "CSV配列上"のインデックスを返すので、ヘッダーを考慮すると一つずれている
                    <TableCell>{errors.find(element => element.line_number - 1 === index)?.message ?? ""}</TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogScrollContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={uploading}
          onClick={handleClickFileSelect}>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleChangeFile}
            style={{ display: "none" }}
          />
          {fileInputRef.current ? ("CSVファイルを再選択する") : ("CSVファイルを選択する")}
        </Button>
      </DialogActions>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="cancel"
          disabled={uploading}
          onClick={handleClose}
        >
          閉じる
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="submit"
          onClick={handleClickUpload}
          disabled={uploading || file === null || errors !== null}>
          内容を確認の上、インポート・編集
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EmptyDialogContent = styled(DialogContent)(() => ({
  textAlign: "center",
}));

const IMAGE_WIDTH = 48;
const IMAGE_HEIGHT = 30;
const StyledMenuImage = styled("img")({
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
  borderRadius: 2,
  objectFit: "cover"
});

const DialogScrollContent = styled(DialogContent)({
  overflowY: "scroll",
  maxHeight: "50vh",
});

export default MenuCsvImportDialog;
