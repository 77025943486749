import { storage } from "./general";

type OwnerType = {
  status:
    | "excess" // プラン超過用
    | "not_reviewed"
    | "passed"
    | "reviewing"
    | "rejected"
    | "rejected_by_terms";
};

export const loadOwner = (): OwnerType | null => {
  const owner = storage.getItem("owner");
  if (owner) {
    return JSON.parse(owner);
  }
  return null;
};

export const setOwner = ({ status }: OwnerType): void => {
  const owner = { status };

  storage.setItem("owner", JSON.stringify(owner));
};

export const removeOwner = (): boolean => {
  try {
    storage.removeItem("owner");
    return true;
  } catch (error) {
    return false;
  }
};
