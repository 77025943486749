import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";

const CustomCheckbox = (props) => {
  const {
    id,
    number,
    label,
    inline,
    onChange,
    checked,
    inputRef,
    disabled = false,
    labelStyle = { color: "#333", fontSize: 14 },
    containerProps = {},
    style,
    ...rest
  } = props;
  const classes = inline ? "checkbox checkbox-inline" : "checkbox";
  const defaultId = useMemo(uuid, []);

  return (
    <div
      className={classes}
      style={{ marginBottom: 0, marginTop: 0, height: "auto", ...style }}
      {...containerProps}
    >
      <input
        id={number || id || defaultId}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        ref={inputRef}
        disabled={disabled}
        {...rest}
      />
      <label
        htmlFor={number || id || defaultId}
        style={{ display: "flex", ...labelStyle }}
        className="checkBoxLabel"
      >
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
