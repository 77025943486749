import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";
import { formatDateExceptTimeToJp } from "../../../lib/general";
import { useGetApiCall, usePcSizeFlag } from "../../../lib/hooks";
import { CouponBlock } from "../../../types/api/coupon.d";
import { ServiceBlock } from "../../../types/api/service.d";
import { StampCardsResponse } from "../../../types/api/stampCard.d";
import { TicketsContentResponse } from "../../../types/api/ticket.d";
import { SubscriptionsResponse } from "../../../types/api/subscription.d";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import StampAndPointAddCheckboxes from "./StampAndPointAddCheckboxes";
import { CheckInFormType } from "./CheckInModal";

type CheckInHookType = {
  coupons: CouponBlock[];
  services: ServiceBlock[];
  tickets: TicketsContentResponse[];
  subscriptions: SubscriptionsResponse[];
  stampCards: StampCardsResponse[];
};

const useAvailableContents = (userId: number): CheckInHookType => {
  const { response: couponsResponse } = useGetApiCall(`/users/${userId}/coupons`, {
    sort: "expiration_date",
    order: "asc",
    status: "active",
  });
  const { response: servicesResponse } = useGetApiCall(`/users/${userId}/services`, {
    sort: "expiration_at",
    order: 'asc',
    status: "active",
  });
  const { response: ticketsResponse } = useGetApiCall(`/users/${userId}/tickets`, {
    sort: "expiration_to",
    order: "asc",
    status: "active",
  });
  const { response: subscriptionsResponse } = useGetApiCall(`/users/${userId}/subscriptions`, {
    sort: "billing_day",
    order: "asc",
    status: "active",
  });
  const { response: stampCardsResponse } = useGetApiCall(`/users/${userId}/stamp_cards`);

  return {
    coupons: couponsResponse?.coupons || [],
    services: servicesResponse?.services || [],
    tickets: ticketsResponse?.tickets || [],
    subscriptions: subscriptionsResponse?.subscriptions || [],
    stampCards: stampCardsResponse?.stamp_cards || [],
  }
};

type Props = {
  userId: number,
  isNeedAmount: boolean,
  membersCardPointEnabled: boolean,
};

const ManualCheckInForm = ({ userId, isNeedAmount, membersCardPointEnabled }: Props) => {
  const { currentStore } = useLoginContext();
  const activeFunction = currentStore.active_function;
  const isPcOrTablet = usePcSizeFlag();
  const { coupons, services, tickets, subscriptions, stampCards } = useAvailableContents(userId);
  const { control, errors } = useFormContext<CheckInFormType>();

  return (
    <>
      <StyledSectionContainer>
        <StyledSectionTitleContainer>
          <SectionTitle title="チェックイン日時" />
        </StyledSectionTitleContainer>

        <Controller
          name="checkInByManual.checkInAt"
          control={control}
          rules={{ required: "チェックイン日時を入力してください" }}
          render={({ onChange, value }) => (
            <Flatpickr
              value={value}
              options={{
                enableTime: true,
                altInput: true,
                allowInput: true,
                locale: Japanese,
                altFormat: "Y/m/d H:i",
                static: true,
                maxDate: new Date()
              }}
              onChange={(date: Date[]) => onChange(date[0])}
            />
          )}
        />
        {errors.checkInByManual?.checkInAt?.message && (
          <FormHelperText error>{errors.checkInByManual?.checkInAt?.message}</FormHelperText>
        )}
      </StyledSectionContainer>

      {activeFunction?.coupon && (
        <StyledSectionContainer>
          <StyledSectionTitleContainer>
            <SectionTitle title="クーポン利用" label="複数選択可" />
          </StyledSectionTitleContainer>

          {!coupons.length ? (
            <>保有しているクーポンはありません</>
          ) : (
            <StyledContentList isPcOrTablet={isPcOrTablet}>
              <Controller
                name="checkInByManual.couponIds"
                control={control}
                render={({ onChange, value }) => (
                  <>
                    {coupons.map(({ coupon, coupon_content }) => (
                      <FormControlLabel
                        key={coupon.id}
                        control={
                          <Checkbox
                            checked={value.some((id: number) => id === coupon.id)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, coupon.id]
                                : value.filter((id: number) => id !== coupon.id);
                              onChange(newValue);
                            }}
                          />
                        }
                        label={
                          <>
                            {coupon_content.benefit}
                            {coupon.expiration_date && (
                              <StyledNote>
                                {formatDateExceptTimeToJp(coupon.expiration_date, "minute")}まで有効
                              </StyledNote>
                            )}
                          </>
                        }
                      />
                    ))}
                  </>
                )}
              />
            </StyledContentList>
          )}
        </StyledSectionContainer>
      )}

      {activeFunction?.members_card && (
        <StyledSectionContainer>
          <StyledSectionTitleContainer>
            <SectionTitle title="サービス利用" label="複数選択可" />
          </StyledSectionTitleContainer>

          {!services.length ? (
            <>保有しているサービスはありません</>
          ) : (
            <StyledContentList isPcOrTablet={isPcOrTablet}>
              <Controller
                name="checkInByManual.serviceIds"
                control={control}
                render={({ onChange, value }) => (
                  <>
                    {services.map(({ service, service_content }) => (
                      <FormControlLabel
                        key={service.id}
                        control={
                          <Checkbox
                            checked={value.some((id: number) => id === service.id)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, service.id]
                                : value.filter((id: number) => id !== service.id);
                              onChange(newValue);
                            }}
                          />
                        }
                        label={
                          <>
                            {service_content.title}
                            {service.expiration_at && (
                              <StyledNote>
                                {formatDateExceptTimeToJp(service.expiration_at, "minute")}まで有効
                              </StyledNote>
                            )}
                          </>
                        }
                      />
                    ))}
                  </>
                )}
              />
            </StyledContentList>
          )}
        </StyledSectionContainer>
      )}

      {activeFunction?.ticket && (
        <StyledSectionContainer>
          <StyledSectionTitleContainer>
            <SectionTitle title="チケット利用" label="複数選択可" />
          </StyledSectionTitleContainer>

          {!tickets.length ? (
            <>保有しているチケットはありません</>
          ) : (
            <StyledContentList isPcOrTablet={isPcOrTablet}>
              <Controller
                name="checkInByManual.ticketIds"
                control={control}
                render={({ onChange, value }) => (
                  <>
                    {tickets.map(({ ticket, ticket_content }) => (
                      <FormControlLabel
                        key={ticket.id}
                        control={
                          <Checkbox
                            checked={value.some((id: number) => id === ticket.id)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, ticket.id]
                                : value.filter((id: number) => id !== ticket.id);
                              onChange(newValue);
                            }}
                          />
                        }
                        label={
                          <>
                            {ticket_content.title}
                            {ticket.expiration_to && (
                              <StyledNote>
                                {formatDateExceptTimeToJp(ticket.expiration_to, "minute")}まで有効
                              </StyledNote>
                            )}
                          </>
                        }
                      />
                    ))}
                  </>
                )}
              />
            </StyledContentList>
          )}
        </StyledSectionContainer>
      )}

      {activeFunction?.subscription && (
        <StyledSectionContainer>
          <StyledSectionTitleContainer>
            <SectionTitle title="サブスク利用" label="複数選択可" />
          </StyledSectionTitleContainer>

          {!subscriptions.length ? (
            <div>保有しているサブスクはありません</div>
          ) : (
            <StyledContentList isPcOrTablet={isPcOrTablet}>
              <Controller
                name="checkInByManual.subscriptionIds"
                control={control}
                render={({ onChange, value }) => (
                  <>
                    {subscriptions.map(({ subscription, subscription_content }) => (
                      <FormControlLabel
                        key={subscription.id}
                        control={
                          <Checkbox
                            checked={value.some((id: number) => id === subscription.id)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, subscription.id]
                                : value.filter((id: number) => id !== subscription.id);
                              onChange(newValue);
                            }}
                          />
                        }
                        label={subscription_content.title}
                      />
                    ))}
                  </>
                )}
              />
            </StyledContentList>
          )}
        </StyledSectionContainer>
      )}

      <StyledSectionContainer>
        <StyledSectionTitleContainer>
          <SectionTitle title="スタンプ・ポイント付与" />
        </StyledSectionTitleContainer>
        <StampAndPointAddCheckboxes
          stampCards={stampCards}
          isNeedAmount={isNeedAmount}
          isStampEnabled={Boolean(activeFunction?.stamp_card)}
          isPointEnabled={Boolean(activeFunction?.members_card && membersCardPointEnabled)}
        />
      </StyledSectionContainer>
    </>
  )
}

type SectionTitleProps = {
  title: string;
  label?: string;
};

const SectionTitle = ({ title, label }: SectionTitleProps) => (
  <StyledTitleContainer>
    <StyledContentTitle>{title}</StyledContentTitle>
    {label && (
      <StyledLabel>{label}</StyledLabel>
    )}
  </StyledTitleContainer>
);

const StyledSectionContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
const StyledSectionTitleContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));
const StyledTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));
const StyledContentTitle = styled("div")({
  fontSize: 16,
  fontWeight: "bold",
});
const StyledLabel = styled("div")(({ theme }) => ({
  backgroundColor: "#4678B2",
  color: "white",
  fontWeight: "bold",
  padding: theme.spacing(0.5, 1),
  borderRadius: 4,
}));
const StyledContentList = styled("div", {
  shouldForwardProp: (prop) => prop !== "isPcOrTablet"
})<{ isPcOrTablet: boolean; }>(({ isPcOrTablet, theme }) => ({
  backgroundColor: "#F4F4F4",
  display: "grid",
  gridTemplateColumns: isPcOrTablet ? "1fr 1fr" : "1fr",
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));
const StyledNote = styled("div")({
  color: "#6e6e6e",
  fontSize: 12,
});

export default ManualCheckInForm;
