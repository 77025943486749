import React from "react";
import Paper, { PaperProps } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useMobileFlag } from "../lib/hooks";

type Props = PaperProps & {
  title?: React.ReactNode;
  children: React.ReactNode;
};

const Section = ({
  title,
  sx = {},
  children,
  ...otherProps
}: Props): JSX.Element => {
  const isMobile = useMobileFlag();

  return (
    <Paper
      variant={isMobile ? "elevation" : "outlined"}
      square={isMobile}
      sx={{
        padding: 2,
        margin: {
          mobile: 0,
          tablet: 2
        },
        ...sx
      }}
      {...otherProps}
    >
      {Boolean(title) && (
        <Typography variant="title" sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}

      {children}
    </Paper>
  );
};

export default Section;
