import React, { useState, useEffect, FC } from "react";
import moment from "moment";

import AnalyticsEntityContainer, {
  DataPeriod,
  DataUnit,
  BarGraphData,
  GraphSummary,
  dataPeriodTypeToPeriod,
  formatDateLabel,
  dataUnitToJpObj,
} from "../../../containers/entities/AnalyticsEntityContainer";

import BarGraphCard from "./BarGraphCard";
import { DisplayNotification } from "../../../types/common.d";

type Props = {
  preData?: BarGraphData;
  userGroupId?: number;
  displayNotification: DisplayNotification;
  initialDataUnit?: DataUnit;
  lg?: number;
};

type DataKeyJp = "売上" | "オーダー" | "サブスク" | "チケット";
type DataKey = "charge" | "order" | "subscription" | "ticket";

const getInitialDataPeriod = (dataUnit: DataUnit): DataPeriod => {
  if (dataUnit === "daily") {
    return {
      type: "last_7_days",
      from: dataPeriodTypeToPeriod.last_7_days.from,
      to: dataPeriodTypeToPeriod.last_7_days.to,
    };
  }

  if (dataUnit === "weekly") {
    return {
      type: "last_8_weeks",
      from: dataPeriodTypeToPeriod.last_8_weeks.from,
      to: dataPeriodTypeToPeriod.last_8_weeks.to,
    };
  }

  return {
    type: "this_year",
    from: dataPeriodTypeToPeriod.this_year.from,
    to: dataPeriodTypeToPeriod.this_year.to,
  };
};

const ChargeGraph: FC<Props> = ({
  preData,
  userGroupId,
  lg = 6,
  initialDataUnit = "weekly",
  displayNotification,
}) => {
  const dataType = "charge";
  const unit = "円";
  const analyticsEntityContainer = AnalyticsEntityContainer.useContainer();
  const { getChargeData } = analyticsEntityContainer.logic;

  const [data, setData] = useState<BarGraphData | undefined>(preData);
  const [summaries, setSummaries] = useState<GraphSummary[]>();
  const [dataUnit, setDataUnit] = useState<DataUnit>(initialDataUnit);
  const [dataPeriod, setDataPeriod] = useState<DataPeriod>(
    getInitialDataPeriod(initialDataUnit)
  );

  const dataKeys = ["order", "subscription", "ticket"];
  const totalKey = "charge";
  const dataColor = {
    charge: "#AFB8F0",
    order: "#47B8B3",
    subscription: "#F1C631",
    ticket: "#5B8FF9",
  };

  const dataKeyToJpObj = {
    charge: "売上",
    order: "オーダー",
    subscription: "サブスク",
    ticket: "チケット",
  } as const;
  const dataKeyToJp = (key: DataKey): DataKeyJp => dataKeyToJpObj[key];

  const maxValue = data?.data
    ? Math.max(...data?.data?.map((d) => d.charge)) || 10000
    : undefined;

  useEffect(() => {
    getChargeData({
      unit: dataUnit,
      from: dataPeriod.from,
      to: dataPeriod.to,
      user_group_id: userGroupId,
    }).then((res) => {
      setData(res);
      setSummaries([
        {
          label: formatDateLabel(
            moment(res?.data[res?.data.length - 1]?.date).format("YYYY/MM/DD"),
            dataUnit
          ),
          mainValue: res?.data[res?.data.length - 1]?.charge,
          mainValueUnit: unit,
          comparedLabel: `前${dataUnitToJpObj[dataUnit]}`,
          comparedValue:
            res?.data[res?.data.length - 1]?.charge -
            res?.data[res?.data.length - 2]?.charge,
          comparedValueUnit: unit,
          type: "positive",
        },
      ]);
    });
  }, [userGroupId, dataUnit, dataPeriod, getChargeData]);

  if (data?.display === false) return null;

  return (
    <BarGraphCard
      dataType={dataType}
      data={data}
      summaries={summaries}
      dataKeys={dataKeys}
      dataColor={dataColor}
      dataPeriod={dataPeriod}
      dataUnit={dataUnit}
      unit={unit}
      maxValue={maxValue}
      lg={lg}
      convertDataKeyToJp={dataKeyToJp}
      setDataPeriod={setDataPeriod}
      setDataUnit={setDataUnit}
      totalKey={totalKey}
      displayNotification={displayNotification}
    />
  );
};

export default ChargeGraph;
