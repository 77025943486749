import React, { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Row, Col, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import moment from "moment";
import { Pagination } from "@mui/material";
import { useHistory } from "react-router";
import { CouponContentStatusToJpObj } from "../../../containers/entities/CouponContentEntityContainer";
import { SortOrderType } from "../../../types/common.d";
import { CouponSortByType, CouponContentResource } from "../../../types/coupon";
import { CouponContentsResponse } from "../../../types/api/coupon.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import { Card } from "../../../components/Card/Card";
import ContentSummaryCard from "../../../components/Card/ContentSummaryCard";
import DisablingMask from "../../../components/DisablingMask";
import OptionButton from "../../../components/CustomButton/OptionButton";
import PresentCouponTable from "./PresentCouponTable";
import StatusLabel from "./StatusLabel";

const SortLabels = {
  publish_at: {
    desc: "配信日/配信予定日の新しい順",
    asc: "配信日/配信予定日の古い順"
  },
  read_count: {
    desc: "開封数の多い順",
    asc: "開封数の少ない順"
  },
  used_count: {
    desc: "利用数の多い順",
    asc: "利用数の少ない順"
  },
} as const;

const SortFields = [
  { sortBy: "publish_at", sortOrder: "desc" },
  { sortBy: "publish_at", sortOrder: "asc" },
  { sortBy: "read_count", sortOrder: "desc" },
  { sortBy: "read_count", sortOrder: "asc" },
  { sortBy: "used_count", sortOrder: "desc" },
  { sortBy: "used_count", sortOrder: "asc" },
] as const;

type FetchApiParams = Partial<{
  page: number;
  sortBy: CouponSortByType;
  sortOrder: SortOrderType;
}>;

type Props = {
  onDeleteCouponContent: (id: number) => void;
  onClickCopyButton: (couponContent: CouponContentResource) => void;
  loading: boolean;
  showArrivalCount: boolean;
};

const PresentCouponList = ({
  onDeleteCouponContent,
  onClickCopyButton,
  loading,
  showArrivalCount
}: Props): JSX.Element => {
  const history = useHistory();
  const isPcOrTablet = usePcSizeFlag();
  const limit = isPcOrTablet ? 20 : 4;
  const [sortBy, setSortBy] = useState<CouponSortByType>(SortFields[0].sortBy);
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortFields[0].sortOrder);
  const { api: couponContentGetApi, response, totalPages, currentPage } = useApi<CouponContentsResponse>();
  const couponContents = response?.coupon_contents || [];

  const getCouponContents = useCallback(async (params: FetchApiParams = {}) => {
    const page = params.page || 1;
    const sort = params.sortBy || SortFields[0].sortBy;
    const order = params.sortOrder || SortFields[0].sortOrder;

    const query = { type: "present", page, sort, order, limit };
    const res = await couponContentGetApi.get("/coupon_contents", query);
    if (!res) return;

    setSortBy(sort);
    setSortOrder(order);
  }, [limit, couponContentGetApi]);

  useEffect(() => {
    getCouponContents();
  }, [getCouponContents]);

  return (
    <Row>
      <Col md={12}>
        <Card
          headerStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          title="通常配信"
          rightContent={
            !isPcOrTablet && (
              <ButtonToolbar>
                <DropdownButton
                  title={SortLabels[sortBy][sortOrder]}
                  bsSize="small"
                  style={{ border: "none", color: "#000" }}
                  pullRight
                  id="dropdown-coupon-list"
                >
                  {SortFields.map((item) => (
                    <MenuItem onClick={() => getCouponContents(item)}>
                      {SortLabels[item.sortBy][item.sortOrder]}
                    </MenuItem>
                  ))}
                </DropdownButton>
              </ButtonToolbar>
            )
          }
          content={
            isPcOrTablet ? (
              <DisablingMask mask={loading}>
                <PresentCouponTable
                  couponContents={couponContents}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  onClickCouponContent={(id) => history.push(`coupons/edit?type=normal&coupon_content_id=${id}`)}
                  onClickCopyButton={onClickCopyButton}
                  onDeleteCouponContent={(id) => onDeleteCouponContent(id)}
                  onSort={(newSortBy, newSortOrder) => {
                    getCouponContents({ sortBy: newSortBy, sortOrder: newSortOrder });
                  }}
                  showArrivalCount={showArrivalCount}
                />
              </DisablingMask>
            ) : (
              couponContents.map((item, index) => (
                <StyledContentSummaryCard
                  key={item.coupon_content.id}
                  last={couponContents.length - 1 === index}
                  onClick={() => history.push(`coupons/edit?type=normal&coupon_content_id=${item.coupon_content.id}`)}
                  options={
                    <OptionButton
                      options={[{
                        label: "複製",
                        onClick: () => onClickCopyButton(item.coupon_content),
                      }, item.coupon_content.published_number === 0 && {
                        label: "削除",
                        labelColor: "#FF4A55",
                        onClick: () => onDeleteCouponContent(item.coupon_content.id),
                      }].filter((v) => v)}
                    />
                  }
                  imageUrl={item.coupon_content.image_url}
                  title={item.coupon_content.benefit}
                  label={
                    <StatusLabel status={item.coupon_content.status} size="small">
                      {CouponContentStatusToJpObj[item.coupon_content.status]}
                    </StatusLabel>
                  }
                  note={
                    item.coupon_content.publish_at
                      ? `配信日: ${moment(item.coupon_content.publish_at).format("YYYY/M/D(dddd) HH:mm")}`
                      : ""
                  }
                  mask={item.coupon_content.status !== "publish"}
                  deliveredCount={item.delivered_count}
                  readCount={item.read_count}
                  readCountDiff={item.read_count_diff}
                  usedCount={item.used_count}
                  salesEffect={item.sales_effect}
                  {...(showArrivalCount && { arrivalCount: item.arrival_count })}
                  createdAt={item.coupon_content.created_at}
                  memo={item.coupon_content.memo}
                />
              ))
            )
          }
          stats={
            totalPages > 1 ? (
              <Pagination
                data-cy="present-coupon-list-pagination"
                count={totalPages}
                page={currentPage}
                onChange={(_, page) => getCouponContents({ page, sortBy, sortOrder })}
                sx={{ fontSize: 24, color: "black" }}
              />
            ) : null
          }
        />
      </Col>
    </Row>
  );
};

const StyledContentSummaryCard = styled(ContentSummaryCard, {
  shouldForwardProp: (prop: string) => "last" !== prop,
})<{ last: boolean }>(({ theme, last }) => ({
  padding: theme.spacing(2, 0),
  borderBottom: last ? "none" : "1px solid rgba(0, 0, 0, 0.1)",
}));

export default PresentCouponList;
