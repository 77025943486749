import React from "react";
import { styled } from "@mui/system";
import MuiDialogTitle from "@mui/material/DialogTitle";

type Props = {
  onClose?: () => void;
  children: React.ReactNode;
}

const DialogTitle = ({
  onClose,
  children
}: Props): JSX.Element => {
  return (
    <StyledDialogTitle>
      {children}

      {onClose && (
        <i onClick={onClose} className="ri-close-line" />
      )}
    </StyledDialogTitle>
  );
};

const StyledDialogTitle = styled(MuiDialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  "& > i": {
    cursor: "pointer"
  }
});

export default DialogTitle;
