import React from "react";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";

type Props = {
  maxDate?: string | null;
  minDate?: string | null;
  [key:string]: any;
};

const DatePicker = ({
  maxDate,
  minDate,
  ...otherProps
}: Props) => (
  <Flatpickr
    options={{
      altInput: true,
      allowInput: true,
      locale: Japanese,
      altFormat: "Y-m-d",
      maxDate,
      minDate
    }}
    {...otherProps}
  />
);

export default DatePicker;
