import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import { UserActionSummaryType, SortOrderType } from "../../../types/common.d";
import { UserSortByType, UserTableType } from "../../../types/user";
import { CouponContentsUsersResponse } from "../../../types/api/coupon.d";
import DataFilteringSidebar from "../../../components/Sidebar/DataFilteringSidebar";
import CustomerListLarge from "../../Customer/parts/CustomerListLarge";
import CustomerListSmall from "../../Customer/parts/CustomerListSmall";

const actionTypeToJp = {
  delivered: "配信",
  read: "開封",
  visit: "来店",
} as const;

const sortMenuItems = [
  {
    sortBy: "visit_count",
    sortOrder: "desc",
    label: "来店数の多い順",
  },
  {
    sortBy: "visit_count",
    sortOrder: "asc",
    label: "来店数の少ない順",
  },
  {
    sortBy: "last_visit_at",
    sortOrder: "desc",
    label: "最終来店日の新しい順",
  },
  {
    sortBy: "last_visit_at",
    sortOrder: "asc",
    label: "最終来店日の古い順",
  },
  {
    sortBy: "connected_at",
    sortOrder: "desc",
    label: "登録日の新しい順",
  },
  {
    sortBy: "connected_at",
    sortOrder: "asc",
    label: "登録日の古い順",
  },
] as const;

type GetUsersArgs = {
  summaryType?: UserActionSummaryType;
  page?: number;
  sortBy?: UserSortByType;
  sortOrder?: SortOrderType;
};

type Props = {
  couponContentId: number;
  defaultSummaryType: UserActionSummaryType;
  onClickCustomer: (customerId: number) => void;
};

const UserListSection = ({
  couponContentId,
  defaultSummaryType,
  onClickCustomer,
}: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();
  const [selectedItem, setSelectedItem] = useState<UserActionSummaryType>(defaultSummaryType);
  const [sortBy, setSortBy] = useState<UserSortByType>(sortMenuItems[2].sortBy);
  const [sortOrder, setSortOrder] = useState<SortOrderType>(sortMenuItems[2].sortOrder);
  const [selectedSortLabel, setSelectedSortLabel] = useState<string>(sortMenuItems[2].label);

  const { api: usersGetApi, response: usersResponse, headers: usersResHeaders } = useApi();
  const getUsers = useCallback(
    async (params: GetUsersArgs = {}) => {
      const summaryType = params.summaryType || defaultSummaryType;
      const sort = params.sortBy || sortMenuItems[2].sortBy;
      const order = params.sortOrder || sortMenuItems[2].sortOrder;
      const page = params.page || 1;

      const res = await usersGetApi.get({
        uri: `/coupon_contents/${couponContentId}/users`,
        params: {
          type: summaryType,
          page: page,
          sort: sort,
          order: order,
        },
      });

      if (!res) {
        return;
      }

      setSortBy(sort);
      setSortOrder(order);
    },
    [defaultSummaryType, usersGetApi, couponContentId]
  );
  const users: CouponContentsUsersResponse["users"] = usersResponse?.users || [];
  const userCount = Number(usersResponse?.user_count);
  const allUserCount = Number(usersResponse?.all_user_count);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const formattedUsers: UserTableType = users.map(
    ({ user, connection, members_card, members_card_rank }) => ({
      id: user.id,
      name: user.name,
      nick_name: user.kana_name,
      avatar_url: user.avatar_url,
      sex: user.sex,
      phone_number: user.phone_number,
      visit_count: connection.visit_count,
      last_visit_at: connection.last_visit_at,
      rank_color: members_card_rank?.color || null,
      rank_name: members_card_rank?.name || null,
      member_code: members_card?.member_code || null,
      connected_at: connection.connected_at,
    })
  );

  const onSort = async (newSortBy: UserSortByType, newSortOrder: SortOrderType): Promise<void> => {
    getUsers({
      summaryType: selectedItem,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    });
  };

  return (
    <StyledContainer>
      {isPcOrTablet && (
        <DataFilteringSidebar
          style={{
            backgroundColor: "white",
            padding: "20px 12px",
            minHeight: 400,
            borderRight: "1px solid #E3E3E5",
          }}
          list={[
            {
              label: actionTypeToJp.delivered,
              value: "delivered",
            },
            {
              label: actionTypeToJp.read,
              value: "read",
            },
            {
              label: actionTypeToJp.visit,
              value: "visit",
            },
          ]}
          defaultSelectedValue={defaultSummaryType}
          onClick={(item: UserActionSummaryType) => {
            setSelectedItem(item);
            getUsers({ summaryType: item });
          }}
        />
      )}

      <StyledCustomerListContainer>
        <StyledActionTitleContainer>
          {isPcOrTablet ? (
            <StyledActionTitle>{actionTypeToJp[selectedItem]}</StyledActionTitle>
          ) : (
            <>
              <ButtonToolbar>
                <DropdownButton
                  title={actionTypeToJp[selectedItem]}
                  style={{
                    fontWeight: 700,
                    color: "#000",
                    fontSize: 24,
                    borderWidth: 0,
                    padding: 0,
                  }}
                  id="dropdown-action-type-list"
                >
                  <DataFilteringSidebar
                    list={[
                      {
                        label: actionTypeToJp.delivered,
                        value: "delivered",
                      },
                      {
                        label: actionTypeToJp.read,
                        value: "read",
                      },
                      {
                        label: actionTypeToJp.visit,
                        value: "visit",
                      },
                    ]}
                    defaultSelectedValue={defaultSummaryType}
                    onClick={(item: UserActionSummaryType) => {
                      setSelectedItem(item);
                      getUsers({ summaryType: item });
                    }}
                  />
                </DropdownButton>
              </ButtonToolbar>

              <ButtonToolbar>
                <DropdownButton
                  title={selectedSortLabel}
                  bsSize="small"
                  style={{ border: "none", color: "#000" }}
                  pullRight
                  id="dropdown-coupon-list"
                >
                  {sortMenuItems.map((item) => (
                    <MenuItem
                      key={item.label}
                      onClick={() => {
                        getUsers({
                          summaryType: selectedItem,
                          sortBy: item.sortBy,
                          sortOrder: item.sortOrder,
                        });
                        setSelectedSortLabel(item.label);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </DropdownButton>
              </ButtonToolbar>
            </>
          )}
        </StyledActionTitleContainer>

        <StyledUserCountContainer>
          <UserCount userCount={userCount} allUserCount={allUserCount} />
        </StyledUserCountContainer>

        <CustomerList
          onClickCustomer={onClickCustomer}
          users={formattedUsers}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
        />
        {usersResHeaders && Number(usersResHeaders["total-pages"]) > 1 ? (
          <Pagination
            count={Number(usersResHeaders["total-pages"])}
            page={Number(usersResHeaders["current-page"])}
            onChange={(_, page) => getUsers({ page, sortBy, sortOrder, summaryType: selectedItem })}
            sx={{ fontSize: 24, color: "black" }}
          />
        ) : null}
      </StyledCustomerListContainer>
    </StyledContainer>
  );
};

type UserCountProps = {
  userCount: number;
  allUserCount: number;
};

const UserCount = ({ userCount, allUserCount }: UserCountProps): JSX.Element => (
  <Box fontWeight={700} display="flex" alignItems="baseline">
    {userCount < allUserCount ? (
      <>
        <Box fontSize={28}>{userCount.toLocaleString()}</Box>人
        <Box fontSize={16} px={0.5}>/</Box>
        <Box fontSize={20}>{allUserCount.toLocaleString()}</Box>人
      </>
    ) : (
      <>
        <Box fontSize={20}>{allUserCount.toLocaleString()}</Box>人
      </>
    )}
  </Box>
);

type CustomerListProps = {
  onClickCustomer: (customerId: number) => void;
  users: UserTableType;
  sortBy: UserSortByType;
  sortOrder: SortOrderType;
  onSort: (sortBy: UserSortByType, sortOrder: SortOrderType) => void;
};

const CustomerList = ({
  onClickCustomer,
  users,
  sortBy,
  sortOrder,
  onSort,
}: CustomerListProps): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();

  return (
    <>
      {isPcOrTablet ? (
        <CustomerListLarge
          onClickCustomer={onClickCustomer}
          users={users}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
        />
      ) : (
        <CustomerListSmall onClickCustomer={onClickCustomer} users={users} />
      )}
    </>
  );
};

const StyledActionTitle = styled("div")({
  fontWeight: 700,
  fontSize: 24,
});

const StyledContainer = styled("div")(({ theme }) => ({
  display: "grid",
  padding: theme.spacing(3, 2),
  gridTemplateColumns: "220px auto",
  [theme.breakpoints.down("laptop")]: {
    gridTemplateColumns: "1fr",
  }
}));

const StyledCustomerListContainer = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(3, 2),
}));

const StyledActionTitleContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const StyledUserCountContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

export default UserListSection;
