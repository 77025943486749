import React, { useState, useEffect } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Calendar from "./Calendar";

export type DailyBenchmarkMap = { [key: number]: number };
export type DayOffMap = { [key: number]: boolean };

type Props = {
  startDate: string;
  endDate: string;
  baseBenchmarkValue: number;
  dailyBenchmarkMap: DailyBenchmarkMap;
  fillDayOffsByDailyBenchmark?: boolean;
  disabled?: boolean;
  onChange: (dailyBenchmarkMap: DailyBenchmarkMap) => void;
};

const DailyBenchmarkCalendarForm = ({
  startDate,
  endDate,
  baseBenchmarkValue,
  dailyBenchmarkMap,
  fillDayOffsByDailyBenchmark = false,
  disabled = false,
  onChange
}: Props): JSX.Element => {
  const numOfDates = moment(endDate).diff(startDate, "days") + 1;
  const defaultStoreDayOffs = !fillDayOffsByDailyBenchmark ? {} : [...Array(numOfDates)]
    .reduce((results, _, i) => {
      return dailyBenchmarkMap[i] === undefined
        ? { ...results, [i]: true }
        : results;
    }, {});
  const [storeDayOffs, setStoreDayOffs] = useState<DayOffMap>(defaultStoreDayOffs);
  const sumOfDailyBenchmark = Object.entries(dailyBenchmarkMap)
    .reduce((sum, [dateIndex, value]) => storeDayOffs[dateIndex] ? sum : sum + value, 0);
  const diffByDailyBenchmark = sumOfDailyBenchmark - baseBenchmarkValue;

  // 目標数値を営業日で均等に割り振ります。たとえば
  // 目標期間: 6/1 ~ 6/30
  // 目標値: 100
  // 定休日: 毎週火曜と金曜
  // という目標を作る場合、以下のようなカレンダーになる。
  //   月 | 火 | 水 | 木 | 金 | 土 | 日
  //  ----+----+----+----+----+----+----
  //    - |  - |  - |  1 |  - |  3 |  4
  //    5 |  - |  7 |  8 |  - | 10 | 11
  //   12 |  - | 14 | 15 |  - | 17 | 18
  //   19 |  - | 21 | 22 |  - | 24 | 25
  //   26 |  - | 28 | 29 |  - |  - |  -
  // この時、21営業日あるので、単純に割り算すると100 / 21 = 4.76....と計算でき
  // 1日あたりの目標値は4.76...となるが、目標値は整数値で入れたいので、
  // 0から4.76を足し上げて出来た配列の各要素の整数部分の差を取って、
  // それを日次目標値とすることでぴったり合うようになる。
  // [   0    -   4.76    -    9.52   -    14.28  -   19.04   -   23.80  - ....]
  //          |           |           |           |           |
  //          |           |           |           |           |
  //      6/1の目標 | 6/3の目標 | 6/4の目標 | 6/5の目標 | 6/7の目標
  //      ----------+-----------+-----------+-----------+----------
  //        4(=4-0) |   5(=9-4) |  5(=14-9) | 5(=19-14) | 4(=23-19)
  const handleDivideBenchmarkValueEvenly = () => {
    const numOfDayOffs = Object.entries(storeDayOffs).filter(([, dayOff]) => dayOff).length;
    const numOfDates = moment(endDate).diff(moment(startDate), "days") + 1;
    const numOfBenchmarkDates = numOfDates - numOfDayOffs;
    const unit = baseBenchmarkValue / numOfBenchmarkDates;
    const dividedBenchmarkValues = [...Array(numOfBenchmarkDates)]
      .map((_, i) => Math.floor(unit * (i + 1)) - Math.floor(unit * i));

    let x = 0;
    const newDailyBenchmarkMap = [...Array(numOfDates)].reduce((results, _, i) => (
      !storeDayOffs[i]
        ? { ...results, [i]: dividedBenchmarkValues[x++] }
        : dailyBenchmarkMap[i] !== undefined
        ? { ...results, [i]: dailyBenchmarkMap[i] }
        : results
    ), {});
    onChange(newDailyBenchmarkMap);
  };

  useEffect(() => {
    if (!fillDayOffsByDailyBenchmark) {
      setStoreDayOffs({});
    }
  }, [startDate, endDate, fillDayOffsByDailyBenchmark]);

  return (
    <>
      <StyledDailyBenchmarkNote>
        <Box display="flex" alignItems="center">
          日次目標の累計:
          <StyledDailyBenchmarkNoteLabel>
            {sumOfDailyBenchmark}
          </StyledDailyBenchmarkNoteLabel>
        </Box>

        <Box display="flex" alignItems="center">
          月次目標との差:
          <StyledDailyBenchmarkNoteLabel color={diffByDailyBenchmark < 0 ? "#E15F4D" : undefined}>
            {diffByDailyBenchmark > 0 && '+'}{diffByDailyBenchmark}
          </StyledDailyBenchmarkNoteLabel>
        </Box>

        <Button
          color="submit"
          startIcon={<i className="ri-loop-right-line" />}
          disabled={disabled}
          onClick={handleDivideBenchmarkValueEvenly}
        >
          月次の目標値を均等に営業日に割り振る
        </Button>
      </StyledDailyBenchmarkNote>

      <Calendar
        startDate={startDate}
        endDate={endDate}
        cellHeight={84}
        renderHeader={(_, dateIndice) => (
          <Box display="flex" justifyContent="center">
            <StyledDayOffLabel
              label="休業週にする"
              control={
                <Checkbox
                  checked={dateIndice.every((dateIndex) => storeDayOffs[dateIndex] === true)}
                  disabled={disabled}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setStoreDayOffs((current) => ({
                      ...current,
                      ...dateIndice.reduce((results, i) => ({ ...results, [i]: checked }), {})
                    }));
                    if (checked) {
                      const newDailyBenchmarkMap = { ...dailyBenchmarkMap };
                      dateIndice.forEach((dateIndex) => newDailyBenchmarkMap[dateIndex] = 0);
                      onChange(newDailyBenchmarkMap);
                    }
                  }}
                />
              }
            />
          </Box>
        )}
        renderCell={(dateIndex) => (
          <Box p={1} display="flex" flexDirection="column" alignItems="flex-end">
            <StyledDayOffLabel
              label="休"
              control={
                <Checkbox
                  checked={storeDayOffs[dateIndex] || false}
                  disabled={disabled}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setStoreDayOffs((current) => ({ ...current, [dateIndex]: checked }));
                    if (checked) {
                      const newDailyBenchmarkMap = { ...dailyBenchmarkMap };
                      newDailyBenchmarkMap[dateIndex] = 0;
                      onChange(newDailyBenchmarkMap);
                    }
                  }}
                />
              }
            />

            <OutlinedInput
              type="number"
              disabled={disabled || storeDayOffs[dateIndex]}
              style={{ backgroundColor: storeDayOffs[dateIndex] ? "#f1f1f1" : "transparent" }}
              value={dailyBenchmarkMap[dateIndex] || ""}
              onChange={(e) => {
                const value = Number(e.target.value);
                const newDailyBenchmarkMap = { ...dailyBenchmarkMap, [dateIndex]: Math.max(0, value) };
                onChange(newDailyBenchmarkMap);
              }}
            />
          </Box>
        )}
      />
    </>
  );
};

const StyledDailyBenchmarkNote = styled("div")({
  fontSize: 15,
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap"
});
const StyledDailyBenchmarkNoteLabel = styled(Box)(({ theme }) => ({
  fontSize: 17,
  fontWeight: 700,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(4)
}));

const StyledDayOffLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  "& .MuiCheckbox-root": {
    padding: theme.spacing(0, 0.5)
  }
}));

export default DailyBenchmarkCalendarForm;
