import React from "react";
import styled from "@emotion/styled";
import { MenuStatusLabel } from "../../constants/menu";
import { MenuStatus } from "../../types/menu";

const ColorsByStatus = {
  draft: "#b0b0b0",
  open: "#00ddb4",
  close: "#4b4b4b",
  preview: "#b0b0b0",
  sold: "#e66464",
} as const;

type Props = {
  status: MenuStatus;
  [key: string]: any;
};

const MenuStatusChip = ({
  status,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledChip status={status} {...otherProps}>
      {MenuStatusLabel[status]}
    </StyledChip>
  );
};

const StyledChip = styled("div")<{ status: MenuStatus }>(({ status }) => ({
  "backgroundColor": ColorsByStatus[status],
  "display": "inline",
  "color": "white",
  "padding": "5px 8px",
  "border": 0,
  "borderRadius": 6,
  "fontWeight": "bold"
}));

export default MenuStatusChip;
