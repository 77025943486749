import React, { useState } from "react";
import { ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { SortOrder } from "../../../types/common.d";
import { UserSortByType } from "../../../types/user";

const SortFields = [{
  sort: "visit_count",
  order: "desc",
  label: "来店数の多い順",
}, {
  sort: "visit_count",
  order: "asc",
  label: "来店数の少ない順",
}, {
  sort: "last_visit_at",
  order: "desc",
  label: "最終来店日の新しい順",
}, {
  sort: "last_visit_at",
  order: "asc",
  label: "最終来店日の古い順",
}, {
  sort: "connected_at",
  order: "desc",
  label: "登録日の新しい順",
}, {
  sort: "connected_at",
  order: "asc",
  label: "登録日の古い順",
}] as const;

type Props = {
  onSort: (sort: UserSortByType, order: SortOrder) => void;
};

const SortButtonOnMobile = ({
  onSort
}: Props): JSX.Element => {
  const [label, setLabel] = useState<string>(SortFields[2].label);

  return (
    <ButtonToolbar>
      <DropdownButton
        title={label}
        bsSize="small"
        style={{ border: "none", color: "#000" }}
        pullRight
        id="dropdown-coupon-list"
      >
        {SortFields.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              onSort(item.sort, item.order);
              setLabel(item.label);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </DropdownButton>
    </ButtonToolbar>
  );
};

export default SortButtonOnMobile;
