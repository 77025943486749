import React from "react";
import { Condition, ConditionDiff } from "../../type";
import GenerationSelectForm from "../../../FormInputs/GenerationSelectForm";

type Props = {
  condition: Condition;
  onChange: (diff: ConditionDiff) => void;
};

const GenerationCondition = ({
  condition,
  onChange
}: Props): JSX.Element => {
  return (
    <GenerationSelectForm
      fullWidth
      emptyLabel="選択してください"
      sx={{ backgroundColor: "#fff" }}
      age={condition.value_from === null ? null : Number(condition.value_from)}
      onChange={(_, range) => {
        onChange({
          value_from: range === null ? null : String(range[0]),
          value_to: range === null ? null : String(range[1])
        })
      }}
    />
  );
};

export default GenerationCondition;
