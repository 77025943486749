import { createContainer } from "unstated-next";
import { authApi, staffApi, userApi } from "../../lib/api";
import { setAuthFromHeader } from "../../lib/auth";

const useLoginEntityHook = () => {
  const api = {
    signIn: async (data) => {
      const user = await authApi
        .signIn({
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          setAuthFromHeader(res.headers);
          return res.data;
        });
      return user;
    },
    signUp: async (data) => {
      const user = await authApi
        .signUp({
          email: data.email,
          password: data.password,
          name: data.name,
          registered: data.registered,
        })
        .then((res) => {
          setAuthFromHeader(res.headers);
          return res.data;
        });
      return user;
    },
    postPasswordResetLink: async (data) => {
      await staffApi.postPasswordResetLink({
        email: data.email,
      });
    },
    updatePassword: async (data) => {
      await staffApi.updatePassword({
        password: data.newPassword,
        currentPassword: data.currentPassword,
      });
    },
    postUserPasswordResetLink: async (data) => {
      await userApi.postPasswordResetLink({
        email: data.email,
      });
    },
  };

  const bind = {
    toAuthParams: (data) => {
      const { email, password } = data;
      return {
        email: email,
        password: password,
      };
    },
    toAuthEntity: (data) => {
      const { email, password, registered, stores, owner } = data;
      return { email, password, registered, stores, owner };
    },
    toPasswordResetParams: (data) => {
      const { email } = data;
      return {
        email: email,
      };
    },
  };

  return { api, bind };
};

export default createContainer(useLoginEntityHook);
