import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useApi, useGetApiCall, useBooleanState } from "../../../lib/hooks";
import { useNotification } from "../../../providers/NotificationProvider";
import { StampCardsResponse } from "../../../types/api/stampCard.d";
import { loadViewObj } from "../../../lib/viewObj";
import FormModal from "./FormModal";
import SectionTitle from "./SectionTitle";

type Props = {
  userId: number;
};

const StampCard = ({ userId }: Props): JSX.Element => {
  const [selectedStampCardId, setSelectedStampCardId] = useState<number>();
  const [isPointModalOpen, openPointModal, closePointModal] = useBooleanState(false);

  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { api: stampCardUpdateApi, loading: stampCardUpdateApiLoading } = useApi();
  const { response: stampCardsResponse, reload: reloadStampCard } = useGetApiCall(
    `/users/${userId}/stamp_cards`
  );
  const stampCards: Array<StampCardsResponse> = stampCardsResponse?.stamp_cards || [];

  const giveStamp = async (stamp: number): Promise<void> => {
    if (stamp <= 0 || stamp >= 1000) {
      showErrorNotification("1回で付与できるスタンプは1〜999までです。");
      return;
    }

    const res = await stampCardUpdateApi.put(
      `/stamp_cards/${selectedStampCardId}`,
      { stamp_card: { stamp } }
    );

    if (!res) return;

    reloadStampCard();
    showSuccessNotification("スタンプを付与しました。");
    closePointModal();
  };

  const onClickOpenModalButton = (stampId): void => {
    if (loadViewObj()?.role !== "admin") {
      window.alert("指定数でのスタンプを付与する権限がありません");
      return;
    }

    setSelectedStampCardId(stampId);
    openPointModal();
  };

  return (
    <div>
      <SectionTitle style={{ marginBottom: 12 }} title={<b>スタンプカード情報</b>} />

      <StyledContentsContainer>
        {stampCards.map(({ stamp_card, store }) => (
          <StyledStampCardContainer key={stamp_card.id}>
            <SytledName>{store.name}</SytledName>
            <StyledStampValue>{stamp_card.stamp.toLocaleString()}スタンプ</StyledStampValue>
            <StyledStampValue>（累計: {stamp_card.total_stamp.toLocaleString()}スタンプ）</StyledStampValue>
            <Button
              color="submit"
              variant="contained"
              onClick={() => onClickOpenModalButton(stamp_card.id)}
              style={{ padding: "0 8px" }}
            >
              <b>スタンプ付与</b>
            </Button>
          </StyledStampCardContainer>
        ))}
      </StyledContentsContainer>

      <FormModal
        show={isPointModalOpen}
        onHide={closePointModal}
        title="スタンプ"
        onGivePoint={giveStamp}
        disabled={stampCardUpdateApiLoading}
      />
    </div>
  );
};

const StyledContentsContainer = styled("div")(({ theme }) => ({
  borderRadius: 4,
  background: "#F5F6F9",
  padding: theme.spacing(1, 2),
}));

const StyledStampCardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 0),
}));

const SytledName = styled("div")(({ theme }) => ({
  color: "#9A9A9A",
    fontWeight: "bold",
    wordBreak: "break-all",
    width: 200,
    marginRight: theme.spacing(2),
}));

const StyledStampValue = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  wordBreak: "keep-all",
  marginRight: theme.spacing(2),
}));

export default StampCard;
