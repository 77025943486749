import React, { useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../providers/NotificationProvider";
import { useApi } from "../../../lib/hooks";
import FormInputs from "../../../components/FormInputs/FormInputs";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import SwitchForm from "../../../components/FormInputs/SwitchForm";

interface Props {
  show: boolean;
  onHide: () => void;
  group: {
    id: number;
    name: string;
    status: string;
    is_open_child_stores: boolean;
  };
  reloadData: () => Promise<void>;
}

const EditGroupModal: React.FC<Props> = ({
  show,
  onHide,
  group,
  reloadData,
}) => {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { api, loading } = useApi("/staff");

  const onSubmit = async (data) => {
    const params = { store: data.group };
    const response = await api.patch(`/owner/stores/${group.id}`, params);
    if (!response) return;

    showSuccessNotification("編集を保存しました。");
    reloadData();
    onHide();
  };

  const onDelete = async () => {
    if (!window.confirm("グループを削除しますか？")) return;

    const params = { store: { status: "archive" } };
    const response = await api.patch(`/owner/stores/${group.id}`, params);
    if (!response) return;

    showSuccessNotification("削除しました。");
    reloadData();
    onHide();
  };

  const onError = () => showErrorNotification("値が正しく入力されていません。");

  const {
    register,
    handleSubmit,
    errors,
    formState,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      group: { name: "", status: "close", is_open_child_stores: false },
    },
  });

  useEffect(() => {
    reset({
      group: {
        name: group.name,
        status: group.status,
        is_open_child_stores: group.is_open_child_stores,
      },
    });
  }, [reset, group]);

  const { isDirty } = formState;

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>グループを編集</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormInputs
            properties={[
              {
                name: "group.name",
                label: "グループ名",
                placeholder: "グループ名を入力",
                ncol: "col-md-12",
                type: "text",
                bsClass: "form-control",
                inputRef: register({
                  required: "グループ名を入力してください。",
                }),
                validationMessage: errors?.group?.name?.message,
              },
            ]}
          />

          <Controller
            control={control}
            name="group.is_open_child_stores"
            render={({ value }) => (
              <SwitchForm
                label="店舗情報ページにその他の店舗を表示する"
                checked={value}
                onChange={(isChecked) => {
                  setValue("group.is_open_child_stores", isChecked, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="group.status"
            render={({ value }): JSX.Element => (
              <SwitchForm
                label="公開する"
                checkedText="公開"
                uncheckedText="非公開"
                checked={value === "open"}
                onChange={(isChecked: boolean): void => {
                  setValue("group.status", isChecked ? "open" : "close", {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />

          <LoadingButton
            type="submit"
            label="保存する"
            loadingLabel="保存中..."
            color="info"
            fill
            block
            disabled={!isDirty}
            loading={loading}
          />
          <LoadingButton
            label="このグループを削除する"
            loadingLabel="削除中..."
            color="danger"
            fill={false}
            block
            simple
            loading={loading}
            onClick={onDelete}
          />
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default EditGroupModal;
