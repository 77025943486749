import React, { Dispatch, FC, SetStateAction } from "react";
import {
  DataPeriod,
  DataPeriodType,
  DataType,
  DataUnit,
} from "../../../containers/entities/AnalyticsEntityContainer";
import AnalyticsPeriodSelectorForm from "./AnalyticsPeriodSelectorForm";
import AnalyticsUnitButtonGroup from "./AnalyticsUnitButtonGroup";

type Props = {
  dataType: DataType;
  dataUnit: DataUnit;
  dataPeriod: DataPeriod;
  setSelectedDataType: Dispatch<SetStateAction<DataType | null>>;
  setDataUnit: Dispatch<SetStateAction<DataUnit>>;
  setDataPeriod: Dispatch<SetStateAction<DataPeriod>>;
  onShowDataPeriodModal: () => void;
  dataPeriodTypeToPeriod: {
    [key in DataPeriodType]: { from: string; to: string };
  };
};

const AnalyticsBarGraphRightContent: FC<Props> = ({
  dataType,
  dataUnit,
  dataPeriod,
  setSelectedDataType,
  setDataUnit,
  setDataPeriod,
  onShowDataPeriodModal,
  dataPeriodTypeToPeriod,
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
    }}
  >
    <AnalyticsUnitButtonGroup
      selectedUnit={dataUnit}
      setSelectedUnit={setDataUnit}
      setDataPeriod={setDataPeriod}
    />
    <AnalyticsPeriodSelectorForm
      dataType={dataType}
      selectedUnit={dataUnit}
      setDataPeriod={setDataPeriod}
      dataPeriod={dataPeriod}
      onShowDataPeriodModal={onShowDataPeriodModal}
      setSelectedDataType={setSelectedDataType}
      dataPeriodTypeToPeriod={dataPeriodTypeToPeriod}
    />
  </div>
);

export default AnalyticsBarGraphRightContent;
