/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { ImageCard } from "./ImageInput";
import CustomButton from "../CustomButton/CustomButton";
import { downloadFile } from "../../lib/general";

type PropsType = {
  width: number;
  height: number;
  onClickCreateButton?: (e) => void;
  onClickDeleteButton?: (e) => void;

  label?: string;
  href?: string;
  filename?: string;
  altMessage?: string;
  image?: string;
};

const ImageDisplay: React.FC<PropsType> = (props) => {
  const {
    href = "",
    altMessage = "",
    image,
    width,
    height,
    filename,
    onClickCreateButton,
    onClickDeleteButton,
    label,
  } = props;

  // 画像追加
  if (!image) {
    return (
      <div
        style={{
          cursor: "pointer",
          width: width,
          height: height,
          color: "#ccc",
          border: "dashed 2px #ccc",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClickCreateButton}
      >
        <i className="fas fa-plus-circle" style={{ fontSize: 30 }} />
      </div>
    );
  }

  // 画像表示
  return (
    <div style={{ marginRight: 20, marginBottom: 10 }}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <ImageCard
          src={image}
          altMessage={altMessage}
          width={width}
          height={height}
        />
      </a>
      {label && (
        <p
          style={{
            whiteSpace: "pre-line",
            width: width,
            fontSize: 12,
            textAlign: "center",
            marginBottom: 5,
            marginLeft: 0,
          }}
        >
          {label}
        </p>
      )}
      <CustomButton
        bsStyle="info"
        fill
        block
        style={{ padding: "4px" }}
        onClick={() => {
          fetch(image, { cache: "reload" })
            .then((response) => response.blob())
            .then((blob) => {
              downloadFile(blob, filename);
            });
        }}
      >
        <b>
          <i className="fas fa-download" /> 保存する
        </b>
      </CustomButton>
      {onClickDeleteButton && (
        <CustomButton
          bsStyle="danger"
          block
          onClick={onClickDeleteButton}
          style={{ padding: "4px" }}
        >
          <b>
            <i className="fas fa-trash-alt" /> 削除する
          </b>
        </CustomButton>
      )}
    </div>
  );
};

export default ImageDisplay;
