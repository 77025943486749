import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { blue, red, teal, deepPurple, orange, amber, indigo, green, deepOrange } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useLoginContext } from "../../../providers/LoginContextProvider";

const AdminMenu = (): JSX.Element => {
  const { isOwnerRejected } = useLoginContext();

  return (
    <StyledContainer>
      {isOwnerRejected ? <RejectedMenu /> : <LoginMenu />}
    </StyledContainer>
  );
};

const LoginMenu = (): JSX.Element => {
  const history = useHistory();
  const { currentStaff, currentStore, hasFunction } = useLoginContext();
  const isOwnerGroup = currentStore.store_type === "owner_group";
  const isStoreGroup = currentStore.store_type === "store_group";
  const isGroup = isOwnerGroup || isStoreGroup;
  const isOwner = currentStaff.is_owner;
  const isSingle = currentStaff.stores.length === 1;
  const isSingleOwner = isSingle && isOwner;
  const goTo = (path: string) => () => history.push(path);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        <StyledFeatureBox onClick={goTo("/admin/notices")}>
          <StyledFeatureIcon
            iconColor={blue[50]}
            bgcolor={blue[400]}
            className="ri-notification-line"
          />
          お知らせ
        </StyledFeatureBox>

        <StyledFeatureBox onClick={goTo("/admin/coupons")}>
          <StyledFeatureIcon
            iconColor={red[50]}
            bgcolor={red[200]}
            className="ri-gift-line"
          />
          クーポン
        </StyledFeatureBox>

        {hasFunction("ticket") && (
          <StyledFeatureBox onClick={goTo("/admin/ticket")}>
            <StyledFeatureIcon
              iconColor={teal[50]}
              bgcolor={teal[400]}
              className="ri-coupon-2-line"
            />
            チケット
          </StyledFeatureBox>
        )}

        {hasFunction("subscription") && (
          <StyledFeatureBox onClick={goTo("/admin/subscription")}>
            <StyledFeatureIcon
              iconColor={deepPurple[50]}
              bgcolor={deepPurple[300]}
              className="ri-calendar-check-line"
            />
            サブスク
          </StyledFeatureBox>
        )}

        {hasFunction("stamp_card") && (
          <StyledFeatureBox onClick={goTo("/admin/stampcard")}>
            <StyledFeatureIcon
              iconColor={orange[50]}
              bgcolor={orange[400]}
              className="ri-emotion-happy-line"
            />
            スタンプカード
          </StyledFeatureBox>
        )}

        {hasFunction("members_card") && (
          <StyledFeatureBox onClick={goTo("/admin/memberscard")}>
            <StyledFeatureIcon
              iconColor={amber[50]}
              bgcolor={amber[400]}
              className="ri-award-line"
            />
            会員カード
          </StyledFeatureBox>
        )}

        {hasFunction("banner") && (
          <StyledFeatureBox onClick={goTo("/admin/banner")}>
            <StyledFeatureIcon
              iconColor={indigo[50]}
              bgcolor={indigo[300]}
              className="ri-image-2-line"
            />
            バナー
          </StyledFeatureBox>
        )}

        {hasFunction("calendar") && (
          <StyledFeatureBox onClick={goTo("/admin/calendar")}>
            <StyledFeatureIcon
              iconColor={green[50]}
              bgcolor={green[400]}
              className="ri-calendar-event-line"
            />
            カレンダー
          </StyledFeatureBox>
        )}

        {hasFunction("menu") && (
          <StyledFeatureBox onClick={goTo("/admin/menu")}>
            <StyledFeatureIcon
              iconColor={deepOrange[50]}
              bgcolor={deepOrange[300]}
              className="ri-book-2-line"
            />
            メニュー
          </StyledFeatureBox>
        )}
      </Box>

      {hasFunction("order") && (
        <StyledMenuContainer>
          <StyledMenuSection>
            <i className="ri-smartphone-line" />
            モバイルオーダー
          </StyledMenuSection>

          <Divider />

          <StyledMenuItemContainer>
            <StyledMenuItem onClick={goTo("/admin/order/setting")}>モバイルオーダー設定</StyledMenuItem>
            <StyledMenuItem onClick={goTo("/admin/order/menu")}>メニュー設定</StyledMenuItem>
            <StyledMenuItem onClick={goTo("/admin/order/orders")}>注文管理</StyledMenuItem>
          </StyledMenuItemContainer>
        </StyledMenuContainer>
      )}

      {hasFunction("ec") && (
        <StyledMenuContainer>
          <StyledMenuSection>
            <i className="ri-shopping-cart-line" />
            オンラインショップ
          </StyledMenuSection>

          <Divider />

          <StyledMenuItemContainer>
            {(isSingleOwner || isOwnerGroup) && (
              <StyledMenuItem onClick={goTo("/admin/ec/setting")}>オンラインショップ設定</StyledMenuItem>
            )}
            <StyledMenuItem onClick={goTo("/admin/ec/menu")}>メニュー設定</StyledMenuItem>
            {(isSingleOwner || isOwnerGroup) && (
              <StyledMenuItem onClick={goTo("/admin/ec/orders")}>注文管理</StyledMenuItem>
            )}
          </StyledMenuItemContainer>
        </StyledMenuContainer>
      )}

      <StyledMenuContainer>
        <StyledMenuSection>
          <i className="ri-user-3-line" />
          ユーザー管理
        </StyledMenuSection>

        <Divider />

        <StyledMenuItemContainer>
          <StyledMenuItem onClick={goTo("/admin/customers")}>ユーザー一覧</StyledMenuItem>
          <StyledMenuItem onClick={goTo("/admin/user_log")}>利用履歴</StyledMenuItem>
        </StyledMenuItemContainer>
      </StyledMenuContainer>

      <StyledMenuContainer>
        <StyledMenuSection>
          <i className="ri-pie-chart-2-line" />
          データ分析
        </StyledMenuSection>

        <Divider />

        <StyledMenuItemContainer>
          <StyledMenuItem onClick={goTo("/admin/dashboard")}>ダッシュボード</StyledMenuItem>
          {hasFunction("analytics_report") && (
            <StyledMenuItem onClick={goTo("/admin/analytics_report")}>自動分析</StyledMenuItem>
          )}
          {hasFunction("questionnaire") && (
            <StyledMenuItem onClick={goTo("/admin/satisfaction_questionnaire")}>顧客満足度</StyledMenuItem>
          )}
        </StyledMenuItemContainer>
      </StyledMenuContainer>

      <StyledMenuContainer>
        <StyledMenuSection>
          <i className="ri-function-line" />
          その他
        </StyledMenuSection>

        <Divider />

        <StyledMenuItemContainer>
          <StyledMenuItem onClick={goTo("/admin/benchmark")}>目標管理</StyledMenuItem>
          {hasFunction("sales") && (
            <StyledMenuItem onClick={goTo("/admin/report")}>売上管理</StyledMenuItem>
          )}
          <StyledMenuItem onClick={goTo("/admin/discount")}>割引管理</StyledMenuItem>
          <StyledMenuItem onClick={goTo("/admin/promotional_material")}>QRコード・販促物</StyledMenuItem>
          <StyledMenuItem onClick={goTo("/admin/plugin")}>機能を追加</StyledMenuItem>
        </StyledMenuItemContainer>
      </StyledMenuContainer>

      {isGroup && (
        <StyledMenuContainer>
          <StyledMenuSection>
            <i className="ri-organization-chart" />
            グループ管理
          </StyledMenuSection>

          <Divider />

          <StyledMenuItemContainer>
            <StyledMenuItem onClick={goTo("/admin/group/edit")}>基本情報</StyledMenuItem>
            {isOwner && <StyledMenuItem onClick={goTo("/admin/groups")}>グループ一覧</StyledMenuItem>}
            <StyledMenuItem onClick={goTo("/admin/child_stores")}>店舗一覧</StyledMenuItem>
            {isOwnerGroup && isOwner && (
              <StyledMenuItem onClick={goTo("/admin/stores/new")}>店舗追加</StyledMenuItem>
            )}
          </StyledMenuItemContainer>
        </StyledMenuContainer>
      )}

      <StyledMenuContainer>
        <StyledMenuSection>
          <i className="ri-settings-2-line" />
          設定
        </StyledMenuSection>

        <Divider />

        <StyledMenuItemContainer>
          {!isGroup && <StyledMenuItem onClick={goTo("/admin/store/edit")}>店舗情報</StyledMenuItem>}
          {isSingleOwner && <StyledMenuItem onClick={goTo("/admin/stores/new")}>店舗追加</StyledMenuItem>}
          {(isSingle || isOwnerGroup) && (
            <StyledMenuItem onClick={goTo("/admin/owner")}>事業者情報</StyledMenuItem>
          )}
          <StyledMenuItem onClick={goTo("/admin/staff")}>スタッフ管理</StyledMenuItem>
        </StyledMenuItemContainer>
      </StyledMenuContainer>
    </Box>
  );
};

const RejectedMenu = (): JSX.Element => {
  return <div>利用停止中</div>;
};

const StyledContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: "#F3F5F9"
}));

const StyledFeatureBox = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(1.5),
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 4,
  width: (window.innerWidth - 8 * 4) / 3,
}));
const StyledFeatureIcon = styled("i", {
  shouldForwardProp: (prop: string) => !["iconColor", "bgcolor"].includes(prop)
})<{ iconColor: string; bgcolor: string; }>(({ iconColor, bgcolor, theme }) => ({
  display: "block",
  fontSize: 20,
  margin: theme.spacing(0.5, 0, 1, 0),
  "&:before": {
    backgroundColor: bgcolor,
    color: iconColor,
    padding: theme.spacing(1),
    borderRadius: 30
  }
}));

const StyledMenuContainer = styled("div")({
  backgroundColor: "#fff",
  borderRadius: 4
});
const StyledMenuSection = styled("div")(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: 16,
  fontWeight: 700,
  padding: theme.spacing(1, 2),
  "& > i": {
    fontSize: 20,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  }
}));
const StyledMenuItemContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 2)
}));
const StyledMenuItem = styled("div")({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 2.4
});

export default AdminMenu;
