import React, { FC } from "react";
import { Table } from "react-bootstrap";
import { Smartphone } from "@mui/icons-material";
import { formatDateExceptTime, formatRelativeDate } from "../../../lib/general";
import { UserTableType } from "../../../types/user";
import { DEFAULT_AVATAR } from "../../../lib/const";
import UserIcon from "./UserIcon";

type Props = {
  onClickCustomer: (customerId: number) => void;
  users: UserTableType;
};

const CustomerListSmall: FC<Props> = ({ onClickCustomer, users }) => (
  <Table striped hover>
    <tbody>
      {users.map((customer) => (
        <tr
          key={`customer-management${customer.id}`}
          onClick={() => onClickCustomer(customer.id)}
          style={{ cursor: "pointer" }}
        >
          <td>
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "flex-start",
              }}
            >
              <UserIcon
                avatar={customer.avatar_url || DEFAULT_AVATAR[customer.sex]}
                rankColor={customer.rank_color}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {(customer.name || customer.id) +
                    (customer.nick_name ? `（${customer.nick_name}）` : "")}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    color: "#9a9a9a",
                    fontSize: 12,
                    display: "flex",
                  }}
                >
                  {Boolean(customer.rank_name) && <div>{customer.rank_name}・</div>}
                  {Boolean(customer.member_code) && <div>{customer.member_code}</div>}
                </div>
                <div
                  style={{
                    marginTop: 4,
                    color: "#7d7d7d",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  電話番号{" "}
                  <Smartphone
                    style={{
                      color: "#D8D8D8",
                      marginLeft: 4,
                    }}
                  />
                  {customer.phone_number
                    ? customer.phone_number.substring(customer.phone_number.length - 4)
                    : "---"}
                </div>
                <div style={{ color: "#7d7d7d" }}>来店数 {customer.visit_count}</div>
                <div style={{ color: "#7d7d7d" }}>
                  最終来店日{" "}
                  {customer.last_visit_at ? formatRelativeDate(customer.last_visit_at) : "---"}
                </div>
                <div style={{ color: "#7d7d7d" }}>
                  登録日 {formatDateExceptTime(customer.connected_at)}
                </div>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default CustomerListSmall;
