import React, { useEffect } from "react";
import { Row, Col, Grid } from "react-bootstrap";
import useSWR from "swr";
import { Pagination } from "@mui/material";
import { AxiosResponse } from "axios";
import { DisplayNotification } from "../../../types/common.d";
import { Card } from "../../../components/Card/Card";
import DiscountContentsListTable from "./DiscountContentsListTable";
import DiscountEntityContainer, {
  DiscountLogFilteringCondition,
  FilteringPeriod,
} from "../../../containers/entities/DiscountEntityContainer";

export type Props = {
  changeUrl: ({
    content_page,
    log_page,
    condition,
    period,
  }: {
    content_page: number;
    log_page: number;
    condition: DiscountLogFilteringCondition;
    period: FilteringPeriod;
  }) => void;
  displayNotification: DisplayNotification;
};

const DiscountContentsListView: React.FC<Props> = ({
  changeUrl,
  displayNotification
}) => {
  // container
  const discountEntityContainer = DiscountEntityContainer.useContainer();
  const { filteringPeriod, logPage, urlParams, discountLogFilteringCondition } =
    discountEntityContainer.state;

  const { getDiscountContents, setContentPage, discountContentTypeToJp } =
    discountEntityContainer.logic;

  const fetcher = async (): Promise<AxiosResponse> =>
    getDiscountContents(urlParams);

  const { data, error } = useSWR(
    `/discount_contents${urlParams}`,
    urlParams ? fetcher : null,
    {
      dedupingInterval: 30000,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (data) {
      setContentPage(data.headers["current-page"]);
    }
  }, [data, setContentPage]);

  useEffect(() => {
    if (error) {
      displayNotification({
        level: "error",
        message: error.message || "ログの取得に失敗しました。",
      });
    }
  }, [error, displayNotification]);

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <Card
            title="割引コンテンツ一覧"
            ctTableFullWidth
            content={
              <DiscountContentsListTable
                discountContents={data?.data || []}
                discountContentTypeToJp={discountContentTypeToJp}
              />
            }
            stats={
              <>
                <Pagination
                  count={Number(data?.headers["total-pages"] || 0)}
                  page={Number(data?.headers["current-page"]) || 1}
                  onChange={(event, value): void =>
                    changeUrl({
                      period: filteringPeriod,
                      content_page: value,
                      log_page: logPage,
                      condition: discountLogFilteringCondition,
                    })
                  }
                  sx={{ fontSize: 24, color: "black" }}
                />
              </>
            }
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default DiscountContentsListView;
