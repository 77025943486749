import React from "react";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { Form, Row, Col, Alert } from "react-bootstrap";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotification } from "../../providers/NotificationProvider";
import { Card } from "../../components/Card/Card";
import FormInput from "../../components/FormInputs/FormInput";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import { isEmailValid } from "../../lib/general";
import { useApi, useQuery, useBooleanState } from "../../lib/hooks";
import AccountTransferModal from "./parts/AccountTransferModal";

type AccountForm = {
  name: string;
  email: string;
};

const AccountChangeView = (): JSX.Element => {
  const { showSuccessNotification } = useNotification();
  const { currentStaff } = useLoginContext();
  const query = useQuery();
  const messageFromQuery = query.get("message");
  const { api, loading } = useApi();
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);

  const defaultValues = {
    name: currentStaff.name,
    email: currentStaff.email
  };
  const methods = useForm<AccountForm>({ defaultValues });
  const { register, handleSubmit, errors, reset, formState } = methods;
  const { isDirty } = formState;

  const updateStaff = async (data: AccountForm) => {
    const params = {
      staff: data,
      confirm_success_url: `${process.env.REACT_APP_BASE_URL}admin/login`,
      error_redirect_url: `${process.env.REACT_APP_BASE_URL}admin/account`
    };
    const res = await api.patch(`/staffs/${currentStaff.id}`, params);
    if (res) {
      showSuccessNotification("更新しました。");
      reset(data);
    }
  };

  return (
    <Row className="content">
      <Col md={12}>
        <Card
          title="アカウント情報"
          content={
            <Form onSubmit={handleSubmit(updateStaff)}>
              {Boolean(messageFromQuery) && (
                <Alert bsStyle="danger" style={{ width: "fit-content" }}>
                  {messageFromQuery}
                </Alert>
              )}

              <Row>
                <FormInput
                  name="name"
                  label="氏名"
                  ncol="col-md-7"
                  type="text"
                  inputRef={register({ required: "氏名を入力してください" })}
                  validationMessage={errors.name?.message}
                />
              </Row>

              <Row>
                <FormInput
                  name="email"
                  label="メールアドレス"
                  ncol="col-md-7"
                  type="email"
                  inputRef={register({
                    required: "メールアドレスを入力してください",
                    validate: (email) => isEmailValid(email) || "正しい形式のメールアドレスを入力してください"
                  })}
                  validationMessage={errors.email?.message}
                />
              </Row>

              <div style={{ display: "flex", gap: 8, justifyContent: "flex-end" }}>
                {currentStaff.email.endsWith("@toypo.co.jp") && (
                  <Button
                    color="toypoAdmin"
                    variant="contained"
                    onClick={openModal}
                  >
                    <strong>アカウント受け渡し</strong>
                  </Button>
                )}

                <LoadingButton
                  type="submit"
                  label="更新する"
                  loadingLabel="更新中..."
                  color="info"
                  disabled={!isDirty}
                  loading={loading}
                  fill
                  pullRight
                />
              </div>
            </Form>
          }
        />
      </Col>

      <AccountTransferModal show={isModalOpen} onClose={closeModal} />
    </Row>
  );
};

export default AccountChangeView;
