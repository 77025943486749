import React, { useState } from "react";
import styled from "@emotion/styled";
import { Col, Modal, Radio, Row } from "react-bootstrap";
import Button from "../../../components/CustomButton/CustomButton";
import { MembersCardRankResource } from "../../../types/membersCard";
import { RankUpMethodLabel } from "../../../constants/membersCard";

interface Props {
  show: boolean;
  onHide: () => void;
  currentMembersCardRankId: number;
  membersCardRanks: MembersCardRankResource[];
  onChangeMembersCardRank: (id: number) => void;
}

const StyledRankUpMethod = styled.b`
  background-color: lightgray;
  border-radius: 3px;
  padding: 1px;
  font-size: 0.8em;
`;

const ModalForMembersRankChange = ({
  show,
  onHide,
  currentMembersCardRankId,
  membersCardRanks,
  onChangeMembersCardRank,
}: Props): JSX.Element => {
  const [selectedRankId, setSelectedRankId] = useState(currentMembersCardRankId);

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setSelectedRankId(currentMembersCardRankId);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title componentClass="h6">どのランクに変更しますか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            {membersCardRanks.map((rank) => (
              <Radio
                disabled={rank.id === currentMembersCardRankId}
                key={rank.id}
                name="radioGroup"
                style={rank.id === currentMembersCardRankId ? { color: "gray" } : {}}
                onChange={() => setSelectedRankId(rank.id)}
              >
                <b>{rank.name}</b>
                <StyledRankUpMethod>
                  {rank.is_default_rank ? "初期ランク" : RankUpMethodLabel[rank.rank_up_method]}
                </StyledRankUpMethod>
                {rank.id === currentMembersCardRankId && <b>現在のランク</b>}
              </Radio>
            ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="info"
          bsSize="small"
          fill
          onClick={() => {
            onChangeMembersCardRank(selectedRankId);
          }}
          disabled={selectedRankId === currentMembersCardRankId}
        >
          <b>変更する</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalForMembersRankChange;
