import { AxiosResponse } from "axios";
import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";
import { TicketContent } from "../../containers/entities/TicketContentEntityContainer";

export const getTicketContents = async (): Promise<TicketContent[]> => {
  const GET_TICKET_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_contents`;

  const response: AxiosResponse<TicketContent[]> = await apiClient({
    method: "GET",
    uri: GET_TICKET_CONTENTS,
  });
  return response.data;
};

export const getTicketContent = async (id: number): Promise<TicketContent> => {
  const GET_TICKET_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_contents/${id}`;

  const response: AxiosResponse<TicketContent> = await apiClient({
    method: "GET",
    uri: GET_TICKET_CONTENT,
  });
  return response.data;
};

export const createTicketContent = async ({ params }) => {
  const POST_TICKET_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_contents`;

  const requestParams = {
    ticket_content: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: POST_TICKET_CONTENTS,
    params: requestParams,
  });
  return response;
};

export const updateTicketContent = async ({ id, params }) => {
  const UPDATE_TICKET_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_contents/${id}`;
  const requestParams = {
    ticket_content: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_TICKET_CONTENTS,
    params: requestParams,
  });
  return response;
};

export const deleteTicketContent = async (id) => {
  const DELETE_TICKET_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_contents/${id}`;

  const response = await apiClient({
    method: "DELETE",
    uri: DELETE_TICKET_CONTENTS,
  });
  return response;
};
