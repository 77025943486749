import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useApi } from "../../../lib/hooks";
import { QuestionnaireAnswersResponse, QuestionnaireFeatureResponse } from "../../../types/questionnaire";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import RestaurantSatisfactionAnswerItem from "../../SatisfactionQuestionnaire/parts/RestaurantSatisfactionAnswerItem";
import RestaurantSatisfactionAnswerAverage from "../../SatisfactionQuestionnaire/parts/RestaurantSatisfactionAnswerAverage";
import HomeSection from "./HomeSection";

const QuestionnaireAnswerList = (): JSX.Element | null => {
  const history = useHistory();
  const { hasFunction } = useLoginContext();
  const hasQuestionnaire = hasFunction("questionnaire");
  const featureApi = useApi<QuestionnaireFeatureResponse>();
  const answersApi = useApi<QuestionnaireAnswersResponse>();
  const defaultId = featureApi.response?.questionnaire_feature.default_restaurant_satisfaction_questionnaire_id;
  const answer = answersApi.response?.questionnaire_answers[0] || null;

  useEffect(() => {
    if (hasQuestionnaire) {
      featureApi.api.get("/questionnaire_features").then((response) => {
        if (!response) return;

        const id = response.data.questionnaire_feature.default_restaurant_satisfaction_questionnaire_id;
        if (id) {
          answersApi.api.get(`/questionnaires/${id}/answers?limit=1`)
        }
      });
    }
  }, [hasQuestionnaire, featureApi.api, answersApi.api]);

  return hasQuestionnaire && answer && defaultId ? (
    <HomeSection
      title="顧客満足度"
      onMoveToDetail={() => history.push(`/admin/satisfaction_questionnaire/${defaultId}?tab=answers`)}
    >
      <Box p={2}>
        <RestaurantSatisfactionAnswerAverage questionnaireId={defaultId} />
      </Box>

      <Divider />

      <Box p={2}>
        <RestaurantSatisfactionAnswerItem answer={answer} questionnaireId={defaultId} />
      </Box>
    </HomeSection>
  ) : null;
};

export default QuestionnaireAnswerList;
