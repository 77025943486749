import React, { useEffect, useCallback, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotification } from "../../providers/NotificationProvider";
import StatusBar from "../../components/StatusBar";
import QuickSightDashboard from "../../components/QuickSightDashboard";
import { useQuery, useApi, useBooleanState } from "../../lib/hooks";
import { QuestionnaireResponse, QuestionnaireStatus } from "../../types/questionnaire";
import QuestionnaireForm from "./parts/QuestionnaireForm";
import RestaurantSatisfactionAnswers from "./parts/RestaurantSatisfactionAnswers";
import NotificationSettings from "./parts/NotificationSettings";
import { QuestionnaireFormType } from "./type";
import { convertResponseToForm, convertFormToRequest } from "./defaultValues";

const TabList = ["dashboard", "answers", "questionnaire", "notification"];
type Tab = typeof TabList[number];

const SatisfactionQuestionnaireView = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery();
  const tabFromQuery = query.get("tab") as Tab;
  const currentTab = TabList.includes(tabFromQuery) ? tabFromQuery : "answers";
  const { currentStore } = useLoginContext();
  const { showSuccessNotification } = useNotification();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const questionnaireFeatureUpdateApi = useApi();
  const questionnaireApi = useApi<QuestionnaireResponse>();
  const questionnaireUpdateApi = useApi();
  const { store, questionnaire, is_default } = questionnaireApi.response || {};
  const isQuestionnaireOpen = questionnaire?.status === "open";
  const questionnaireUrl = questionnaire?.questionnaire_url || "";
  const defaultValues = useMemo<QuestionnaireFormType | null>(() => (
    questionnaireApi.response ? convertResponseToForm(questionnaireApi.response) : null
  ), [questionnaireApi.response]);

  const reloadQuestionnaire = useCallback(() => {
    if (id) {
      questionnaireApi.api.get(`/questionnaires/${id}`);
    }
  }, [id, questionnaireApi.api]);

  useEffect(() => {
    reloadQuestionnaire();
  }, [reloadQuestionnaire]);

  const updateQuestionnaire = async (formValue: QuestionnaireFormType) => {
    const params = convertFormToRequest(formValue);
    const response = await questionnaireUpdateApi.api.put(`/questionnaires/${id}`, params);
    if (response !== null) {
      showSuccessNotification("アンケートが更新されました");
      reloadQuestionnaire();
    }
  };

  const updateStatus = async (status: QuestionnaireStatus) => {
    const params = { status };
    const response = await questionnaireUpdateApi.api.put(`/questionnaires/${id}`, params);
    if (response !== null) {
      showSuccessNotification(
        status === "open"
          ? "アンケートが公開されました"
          : "アンケートの公開を停止しました"
      );
      reloadQuestionnaire();
    }
  };

  const changeDefaultQuestionnaire = async () => {
    const params = { default_restaurant_satisfaction_questionnaire_id: id };
    await questionnaireFeatureUpdateApi.api.put("/questionnaire_features", params);
    showSuccessNotification("ホーム画面に表示するアンケートを変更しました");
    reloadQuestionnaire();
    closeModal();
  };

  const handleCopyUrl = () => {
    window.navigator.clipboard.writeText(questionnaireUrl)
      .then(() => showSuccessNotification("URLをコピーしました。"));
  };

  return !questionnaireApi.loaded ? (
    <StyledLoadingContainer>
      <CircularProgress color="inherit" />
    </StyledLoadingContainer>
  ) : (
    <>
      <StatusBar
        published={isQuestionnaireOpen}
        label={isQuestionnaireOpen ? "顧客満足度調査アンケートは公開中です": "顧客満足度調査アンケートは非公開です"}
      />

      <StyledHeaderContainer>
        <StyledHeaderTitle>
          <strong>{questionnaire?.title}</strong>
          <Box mt={0.5} fontSize={14}>{store?.name}</Box>
        </StyledHeaderTitle>

        <StyledHeaderRightContainer>
          <StyledPinContainer
            defaultQuestionnaire={is_default}
            onClick={!is_default ? openModal : undefined}
          >
            <i className="ri-pushpin-fill" style={{ fontSize: 18 }} />
            <Box>{is_default ? "ホーム画面へ表示中" : "ホーム画面へ表示する"}</Box>
          </StyledPinContainer>

          <Button
            variant="contained"
            color="submit"
            endIcon={<i className="ri-arrow-down-s-fill" style={{ lineHeight: "1" }} />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            配信する
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <StyledDropdownHeader>配信方法</StyledDropdownHeader>
            <MenuItem disabled>お知らせ</MenuItem>
            <MenuItem onClick={() => history.push(`/admin/notices/new?type=normal&questionnaire_url=${questionnaireUrl}`)}>
              通常配信お知らせ
            </MenuItem>
            <MenuItem onClick={() => history.push(`/admin/notices/new?type=auto&questionnaire_url=${questionnaireUrl}`)}>
              自動配信お知らせ
            </MenuItem>
            <MenuItem disabled>クーポン</MenuItem>
            <MenuItem onClick={() => history.push(`/admin/coupons/new?type=normal&questionnaire_id=${id}`)}>
              通常配信クーポン
            </MenuItem>
            <MenuItem onClick={() => history.push(`/admin/coupons/new?type=auto&questionnaire_id=${id}`)}>
              自動配信クーポン
            </MenuItem>
            <MenuItem onClick={() => history.push(`/admin/privilege_coupon/new?isCode=true&questionnaire_id=${id}`)}>
              特殊配信クーポン
            </MenuItem>
            <MenuItem disabled>その他</MenuItem>
            <MenuItem onClick={handleCopyUrl}>URLをコピー</MenuItem>
          </Menu>
        </StyledHeaderRightContainer>
      </StyledHeaderContainer>

      <Box bgcolor="#fff">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={(_, tab: string) => history.push(`/admin/satisfaction_questionnaire/${id}?tab=${tab}`)}
        >
          <Tab value="dashboard" label="ダッシュボード" />
          <Tab value="answers" label="回答" />
          <Tab value="questionnaire" label="アンケート設定" />
          <Tab value="notification" label="通知設定" />
        </Tabs>
      </Box>

      {currentTab === "dashboard" && questionnaire && (
        <QuickSightDashboard
          params={{
            QuestionnaireId: questionnaire.id,
            StoreId: currentStore.store_type === "child" ? currentStore.id : null
          }}
          dashboardType="questionnaire"
        />
      )}

      {currentTab === "answers" && questionnaireApi.response && (
        <RestaurantSatisfactionAnswers
          sections={questionnaireApi.response.questionnaire_sections}
          questionnaireId={id}
        />
      )}

      {currentTab === "questionnaire" && questionnaire && defaultValues && (
        <QuestionnaireForm
          status={questionnaire.status}
          editable={questionnaire.store_id === currentStore.id}
          defaultValues={defaultValues}
          disabled={questionnaireUpdateApi.loading}
          onSubmit={updateQuestionnaire}
          onChangeStatus={updateStatus}
        />
      )}

      {currentTab === "notification" && questionnaireApi.response && (
        <NotificationSettings
          editable={questionnaireApi.response.questionnaire.store_id === currentStore.id}
          questionnaire={questionnaireApi.response.questionnaire}
          reportNotificationPolicies={questionnaireApi.response.report_notification_policies}
          answerNotificationPolicies={questionnaireApi.response.answer_notification_policies}
        />
      )}

      <Dialog maxWidth="xs" open={isModalOpen} onClose={closeModal}>
        <DialogContent>
          この満足度アンケートの回答結果をホーム画面に表示してよろしいですか？
        </DialogContent>
        <DialogActions>
          <Button color="cancel" variant="contained" fullWidth onClick={closeModal}>
            キャンセル
          </Button>

          <Button color="submit" variant="contained" fullWidth onClick={changeDefaultQuestionnaire}>
            表示する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StyledHeaderContainer = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1, 2),

  [theme.breakpoints.down("laptop")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const StyledHeaderTitle = styled("div")({
  fontSize: 17,
  fontWeight: 500,
});

const StyledHeaderRightContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  width: "auto",
  justifyContent: "flex-end",

  [theme.breakpoints.down("laptop")]: {
    width: "100%",
  }
}));

const StyledPinContainer = styled("div", {
  shouldForwardProp: (prop) => "defaultQuestionnaire" !== prop
})<{ defaultQuestionnaire?: boolean }>(({ theme, defaultQuestionnaire }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  fontWeight: 700,
  color: defaultQuestionnaire ? "#A9A9A9" : "#1dc7ea",
  cursor: defaultQuestionnaire ? "default" : "pointer"
}));

const StyledDropdownHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderBottom: "solid 1px #eee",
  marginBottom: theme.spacing(1),
  fontWeight: 700,
  color: "#818181"
}));

const StyledLoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  padding: theme.spacing(10)
}));

export default SatisfactionQuestionnaireView;
