import React, { useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";
import { FileDoneOutlined } from "@ant-design/icons";
import { Table } from "react-bootstrap";
import { OrdersResponse } from "../../../types/api/order.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import SectionTitle from "./SectionTitle";
import NoItemContent from "../../../components/Utils/NoItemContent";

const orderTypesToJp = {
  display: "店内メニュー",
  takeout: "テイクアウト",
  delivery: "デリバリー",
  preorder: "プレオーダー",
  ec: "オンラインショップ",
};

type Props = {
  userId: number;
};

const Order = ({ userId }: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();

  const { api: MenusGetApi, response: menusResponse, headers: menusResHeaders } = useApi();
  const menus: Array<OrdersResponse> = menusResponse?.menus || [];

  const getOrders = useCallback((page: number = 1) => {
    MenusGetApi.get(`/users/${userId}/menus`, {
      page,
      limit: 5,
    });
  }, [MenusGetApi, userId]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  if (menus.length === 0) {
    return (
      <div>
        <SectionTitle style={{ marginBottom: 12 }} title={<b>購入済みのメニュー</b>} />

        <NoItemContent label="購入済みのメニューはありません" icon={<FileDoneOutlined />} />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle style={{ marginBottom: 12 }} title={<b>購入済みのメニュー</b>} />

      {isPcOrTablet ? (
        <Table striped hover>
          <thead>
            <tr>
              {["カテゴリ", "タイトル", "個数"].map((prop) => (
                <th key={prop}>{prop}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {menus.map(({ menu, store, total_ordered_quantity }) => (
              <tr key={menu.id}>
                <td>
                  <div>{orderTypesToJp[menu.menu_type]}</div>
                </td>
                <td>
                  <Box display="flex" alignItems="center">
                    {menu.image_url && (
                      <StyledImage
                        key={menu.id}
                        alt="メニュー画像"
                        src={menu.image_url}
                      />
                    )}

                    <div>
                      <Box fontWeight={700}>{menu.title}</Box>
                      <Box color="#9A9A9A" fontSize={12}>{store.name}</Box>
                    </div>
                  </Box>
                </td>
                <td>
                  <div>{total_ordered_quantity}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table striped hover>
          <tbody>
            {menus.map(({ menu, store, total_ordered_quantity }) => (
              <tr key={menu.id}>
                <td>
                  <Box display="flex" alignItems="flex-start" gap={2}>
                    {menu.image_url && (
                      <StyledImage
                        key={menu.id}
                        alt="メニュー画像"
                        src={menu.image_url}
                      />
                    )}

                    <div>
                      <Box marginBottom={0.5}>
                        <Box fontWeight={700}>{menu.title}</Box>
                        <Box color="#9A9A9A" fontSize={12}>{store.name}</Box>
                      </Box>
                      <Box color="#7d7d7d">
                        カテゴリー <span>{orderTypesToJp[menu.menu_type]}</span>
                      </Box>
                      <Box color="#7d7d7d">
                        個数 <span>{total_ordered_quantity}</span>
                      </Box>
                    </div>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {Number(menusResHeaders["total-pages"]) > 1 && (
        <Pagination
          count={Number(menusResHeaders["total-pages"]) || 1}
          page={Number(menusResHeaders["current-page"]) || 1}
          onChange={(_, page) => getOrders(page)}
          style={{ paddingBottom: 8, borderBottom: "1px solid #E8E8E8" }}
          sx={{ fontSize: 24, color: "black" }}
        />
      )}
    </div>
  );
};

const StyledImage = styled("img")(({ theme }) => ({
  borderRadius: 2,
  objectFit: "cover",
  marginRight: theme.spacing(1),
  width: 48,
  height: 30,
}));

export default Order;
