import { useCallback, useState, Dispatch, SetStateAction } from "react";
import { createContainer } from "unstated-next";
import { menuApi } from "../../lib/api";
import { MenuResponse, MenuStatus } from "../../types/menu";

// interface
export type MenuInterface = MenuResponse;

type MenuTargetUserCondition = {
  age_from?: number | null;
  age_to?: number | null;
  birth_month?: number | null;
  created_at?: string;
  gender?: "male" | "female" | "other" | null;
  id?: number;
  menu_id?: number | null;
  rank?: number | null;
  rank_ids?: number[];
  updated_at?: string;
  user_group_collection_id?: number | null;
  user_group_ids?: number[];
  user_ids?: number[];
  users_count?: number;
};

export interface MenuParamsInterface {
  title?: string;
  body?: string;
  price?: number | null;
  category?: number;
  menu_type?: MenuTypeFunction;
  status?: MenuStatus;
  priority?: number | null;
  orderable_quantity_by_user?: number | null;
  image?: string; // base64
  enabled_target_user_condition?: boolean;
  target_user_condition?: MenuTargetUserCondition | null;
}

const menuType = {
  Display: "display",
  Takeout: "takeout",
  Delivery: "delivery",
  Ec: "ec",
  reorder: "preorder",
} as const;
export type MenuTypeFunction = typeof menuType[keyof typeof menuType];
const functions = Object.values(menuType);
const orderFunctions = ["takeout", "delivery", "preorder"];
const functionsToJpObj: { [Key in MenuTypeFunction]: string } = {
  display: "店内メニュー",
  takeout: "テイクアウト",
  delivery: "デリバリー",
  ec: "オンラインショップ",
  preorder: "プレオーダー",
};

const menuStatus = {
  Draft: "draft",
  Open: "open",
  Close: "close",
  Preview: "preview",
  Sold: "sold",
} as const;
const statuses = Object.values(menuStatus);

const statusesToJpObj = {
  [menuStatus.Draft]: "下書き",
  [menuStatus.Open]: "公開",
  [menuStatus.Close]: "非公開",
  [menuStatus.Preview]: "下書き",
  [menuStatus.Sold]: "売り切れ",
} as const;

type MenuEntity = {
  state: {
    menus: MenuInterface[];
  };
  constant: {
    functions: MenuTypeFunction[];
    orderFunctions: string[];
    statuses: MenuStatus[];
  };
  logic: {
    setMenus: Dispatch<SetStateAction<MenuInterface[]>>;
    getMenus: () => Promise<MenuInterface[]>;
    getMenu: (id: number) => Promise<MenuInterface>;
    createMenu: (params: MenuParamsInterface) => Promise<MenuInterface>;
    updateMenu: (id: number, params: MenuParamsInterface) => Promise<MenuInterface>;
    deleteMenu: (id: number) => Promise<void>;
    functionToJp: (func: MenuTypeFunction) => string;
    statusToJp: (status: MenuStatus) => string;
  };
};

const useMenuEntityHook = (): MenuEntity => {
  const [menus, setMenus] = useState<MenuInterface[]>([]);

  const updateMenuState = useCallback(
    (newMenu) => {
      const index = menus.findIndex((menu) => menu.id === newMenu.id);
      setMenus([...menus.slice(0, index), newMenu, ...menus.slice(index + 1)]);
    },
    [menus]
  );

  const addMenuState = useCallback(
    (newMenu) => {
      setMenus([newMenu, ...menus]);
    },
    [menus]
  );

  const deleteMenuState = useCallback(
    (deleteMenuId) => {
      const index = menus.findIndex((menu) => menu.id === deleteMenuId);
      setMenus([...menus.slice(0, index), ...menus.slice(index + 1)]);
    },
    [menus]
  );

  return {
    state: { menus },
    constant: { functions, orderFunctions, statuses },
    logic: {
      setMenus: setMenus,
      getMenus: useCallback(async (): Promise<MenuInterface[]> => {
        const newMenus: MenuInterface[] = await menuApi.getMenus();
        setMenus(newMenus);
        return newMenus;
      }, []),
      getMenu: useCallback(async (id: number): Promise<MenuInterface> => {
        const newMenu: MenuInterface = await menuApi.getMenu(id);
        return newMenu;
      }, []),
      createMenu: async (params: MenuParamsInterface): Promise<MenuInterface> => {
        const newMenu: MenuInterface = await menuApi.createMenu({ params });
        addMenuState(newMenu);
        return newMenu;
      },
      updateMenu: async (id: number, params: MenuParamsInterface): Promise<MenuInterface> => {
        const newMenu: MenuInterface = await menuApi.updateMenu({
          id: id,
          params,
        });
        updateMenuState(newMenu);
        return newMenu;
      },
      deleteMenu: async (id: number): Promise<void> => {
        await menuApi.deleteMenu({ id: id });
        deleteMenuState(id);
      },
      functionToJp: (func: MenuTypeFunction): string => functionsToJpObj[func],
      statusToJp: (status: MenuStatus): string => statusesToJpObj[status],
    },
  };
};

export default createContainer(useMenuEntityHook);
