import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useApi } from "../../../lib/hooks";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import { CategoryColorMap, CategoryLabel } from "../../../constants/analyticsReport";
import { AnalyticsReportsResponse, AnalyticsReportCategory } from "../../../types/analyticsReport";
import AnalyticsReportCategoryIcon from "../../../components/AnalyticsReportCategoryIcon";

const AnalyticsReports = () => {
  const history = useHistory();
  const { hasFunction } = useLoginContext();
  const hasReport = hasFunction("analytics_report");
  const { api, response } = useApi<AnalyticsReportsResponse>();
  const reports = response?.reports || [];

  useEffect(() => {
    if (hasReport) {
      api.get("/analytics_reports?period=6&limit=3");
    }
  }, [hasReport, api]);

  return reports.length > 0 ? (
    <Box display="grid" gap={2}>
      {reports.map(({ report }) => (
        <StyledReportContainer
          key={report.id}
          fullWidth
          startIcon={<AnalyticsReportCategoryIcon category={report.category} />}
          endIcon={<i className="ri-arrow-right-s-line" style={{ fontSize: 24, color: "#888" }} />}
          color="neutral"
          category={report.category}
          onClick={() => history.push("/admin/analytics_report")}
        >
          <Box flexGrow={1}>
            <Box fontSize={13} color={CategoryColorMap[report.category]} fontWeight={700}>
              {CategoryLabel[report.category]}
            </Box>

            <Box fontSize={15} mt={0.5} lineHeight={1.5}>
              {report.title}
            </Box>
          </Box>
        </StyledReportContainer>
      ))}
    </Box>
  ) : null;
};

const StyledReportContainer = styled(Button)<{
  category: AnalyticsReportCategory
}>(({ theme, category }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(1, 2),
  borderLeft: `solid 4px ${CategoryColorMap[category]}`,
  textTransform: "none",
  textAlign: "left",
  lineHeight: "1.0"
}));

export default AnalyticsReports;
