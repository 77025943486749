import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useNotification } from "../../../providers/NotificationProvider";
import { useApi } from "../../../lib/hooks";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import LoadingButton from "../../../components/CustomButton/LoadingButton";

const AddStoreToGroupModal = ({
  show,
  onHide,
  group,
  reloadData
}) => {
  const { showSuccessNotification } = useNotification();
  const { api, response } = useApi("/staff");
  const createApi = useApi("/staff");
  const stores = response ?? [];

  const [selectedStoreIds, setSelectedStoreIds] = useState([]);

  useEffect(() => {
    if (show) {
      api.get("/owner/stores");
    }
  }, [show, api]);

  const handleClick = async () => {
    const params = { child_store: { ids: selectedStoreIds } };
    const response = await createApi.api.post(`/stores/${group.id}/child_stores`, params);
    setSelectedStoreIds([]);

    if (!response) return;

    showSuccessNotification("編集を保存しました。");
    reloadData();
    onHide();
  };

  // 選択されたグループに所属する店舗のID
  const childStoreIds = group?.child_stores?.map((child) => child.id);

  // 選択されたグループに所属しない店舗，かつarchiveでない店舗
  const filteredStore = stores.filter((store) =>
    !childStoreIds?.includes(store.id) &&
      ["open", "close"].includes(store.status)
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>店舗を追加</b>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table hover>
          <tbody>
            {filteredStore.map((store) => (
              <tr
                key={store.id}
                onClick={() =>
                  setSelectedStoreIds((prevIds) => {
                    if (prevIds.includes(store.id)) {
                      return prevIds.filter((id) => id !== store.id);
                    }

                    const newIds = [...prevIds, store.id];
                    return [...new Set(newIds)];
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <td
                  style={{
                    width: 40,
                    border: "0px none",
                    borderRadius: "5px 0 0 5px",
                  }}
                >
                  <CustomCheckbox
                    readOnly
                    checked={selectedStoreIds.includes(store.id)}
                  />
                </td>
                <td style={{ width: "40%", border: "0px none" }}>
                  {store.name}
                </td>
                <td style={{ border: "0px none", borderRadius: "0 5px 5px 0" }}>
                  {store.parent_stores.map((v) => v.name).join(", ")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <LoadingButton
          type="submit"
          label="追加する"
          loadingLabel="追加中..."
          color="info"
          fill
          block
          disabled={!selectedStoreIds.length}
          onClick={handleClick}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddStoreToGroupModal;
