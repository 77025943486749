import apiClient from "./apiClient";
import { loadViewObj } from "../viewObj";

export const createStampCardContent = async ({ params }) => {
  const CREATE_STAMP_CARD_CONTENT = "/stamp_card_contents";

  const requestParams = {
    stamp_card_content: params,
  };

  const response = await apiClient({
    method: "POST",
    uri: CREATE_STAMP_CARD_CONTENT,
    params: requestParams,
  });
  return response;
};

export const getStampCardContent = async () => {
  const GET_STAMP_CARD_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/stamp_card_contents`;

  const response = await apiClient({
    method: "GET",
    uri: GET_STAMP_CARD_CONTENT,
  });
  return response;
};

export const updateStampCardContent = async ({ params }) => {
  const UPDATE_STAMP_CARD_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/stamp_card_contents`;

  const requestParams = {
    stamp_card_content: params,
  };

  const response = await apiClient({
    method: "PATCH",
    uri: UPDATE_STAMP_CARD_CONTENT,
    params: requestParams,
  });
  return response;
};
