import React, { useEffect } from "react";
import { Modal, Form, Row, Col, FormGroup, HelpBlock } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";

// components
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import FormLabel from "../../../components/FormInputs/FormLabel";
import CustomButton from "../../../components/CustomButton/CustomButton";

// container
import { DataPeriod } from "./TotalChargeCard";

// lib
import { compareValidateDate } from "../../../lib/validation";
import { formatDateExceptTime } from "../../../lib/general";

type Props = {
  dataPeriod?: DataPeriod;
  show: boolean;
  setDataPeriod: (formData: DataPeriod) => void;
  onHide: () => void;
};

const PeriodFilteringModal: React.FC<Props> = ({
  show,
  onHide,
  dataPeriod,
  setDataPeriod,
}) => {
  const { handleSubmit, errors, getValues, watch, setValue, control, reset } =
    useForm<{ dataPeriod: DataPeriod }>({
      defaultValues: {
        dataPeriod: {
          from: dataPeriod?.from,
          to: dataPeriod?.to,
        },
      },
    });

  const watchFrom = watch("dataPeriod.from");
  const watchTo = watch("dataPeriod.to");

  const onSubmit = (data) => {
    setDataPeriod(data.dataPeriod);

    onHide();
  };

  const onError = () => {
    // displayNotification({
    //   level: "error",
    //   message: "値が正しく入力されていません。",
    // });
  };

  const onClickResetButton = () => {
    setValue("dataPeriod.from", null, {
      shouldDirty: true,
    });
    setValue("dataPeriod.to", null, {
      shouldDirty: true,
    });
  };

  const validateFrom = () => {
    const getValuesFrom = getValues("dataPeriod.from");
    const getValuesTo = getValues("dataPeriod.to");

    if (!getValuesFrom && !!getValuesTo) {
      return "期間を入力してください";
    }
    return true;
  };

  const validateTo = () => {
    const getValuesFrom = getValues("dataPeriod.from");
    const getValuesTo = getValues("dataPeriod.to");

    if (!!getValuesFrom && !getValuesTo) {
      return "期間を入力してください";
    }

    if (
      getValuesFrom &&
      getValuesTo &&
      compareValidateDate(getValuesTo, getValuesFrom).validation
    ) {
      return "開始日時より過去の終了日時を設定することはできません";
    }

    return true;
  };

  useEffect(() => {
    reset({ dataPeriod: dataPeriod });
  }, [dataPeriod, reset]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={(): void => {
        document.body.style.overflow = "auto";
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>期間絞り込み</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <FormGroup bsSize="large">
                <FormLabel label="期間" />
                <Row>
                  <Col lg={5} md={5} xs={12}>
                    <Controller
                      control={control}
                      name="dataPeriod.from"
                      rules={{
                        validate: validateFrom,
                      }}
                      render={(): JSX.Element => (
                        <Flatpickr
                          value={watchFrom}
                          options={{
                            altInput: true,
                            allowInput: true,
                            locale: Japanese,
                            altFormat: "Y-m-d",
                          }}
                          onChange={(date): void => {
                            setValue(
                              "dataPeriod.from",
                              formatDateExceptTime(new Date(date)),
                              { shouldDirty: true }
                            );
                          }}
                          placeholder="いつから"
                        />
                      )}
                    />
                    <HelpBlock className="text-danger">
                      {errors?.dataPeriod?.from?.message}
                    </HelpBlock>
                  </Col>
                  <Col lg={5} md={5} xs={12}>
                    <Controller
                      control={control}
                      name="dataPeriod.to"
                      rules={{
                        validate: validateTo,
                      }}
                      render={(): JSX.Element => (
                        <Flatpickr
                          value={watchTo}
                          options={{
                            altInput: true,
                            allowInput: true,
                            locale: Japanese,
                            altFormat: "Y-m-d",
                          }}
                          onChange={(date): void => {
                            setValue(
                              "dataPeriod.to",
                              formatDateExceptTime(new Date(date)),
                              { shouldDirty: true }
                            );
                          }}
                          placeholder="いつまで"
                        />
                      )}
                    />
                    <HelpBlock className="text-danger">
                      {errors?.dataPeriod?.to?.message}
                    </HelpBlock>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          <LoadingButton
            type="submit"
            label="この条件で絞り込む"
            loadingLabel="絞り込み中..."
            color="info"
            fill
            block
          />

          <CustomButton
            block
            simple
            style={{ marginTop: 10 }}
            onClick={onClickResetButton}
          >
            <b style={{ fontSize: 14 }}>条件をリセットする</b>
          </CustomButton>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default PeriodFilteringModal;
