import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Grid, Row, Col } from "react-bootstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoginContext } from "../../providers/LoginContextProvider";
import ChargeEntityContainer from "../../containers/entities/ChargeEntityContainer";
import TotalChargeCard from "./parts/TotalChargeCard";
import ProductSalesCard from "./parts/ProductSalesCard";
import ChargesListCard from "./parts/ChargesListCard";
import ChargeGraph from "../Analytics/parts/ChargeGraph";
import { downloadCSVFile } from "../../lib/general";
import { useApi } from "../../lib/hooks";
import { DisplayNotification } from "../../types/common.d";
import { PayjpBalancesResponse, PayjpStatement } from "../../types/payjp";
import Section from "../../components/Section";
import { PayjpBalanceStatusLabels } from "../../constants/payjp";
import BalanceList from "./parts/BalanceList";

type Props = {
  displayNotification: DisplayNotification;
};

const ReportView = ({
  displayNotification,
}: Props): JSX.Element => {
  const history = useHistory();
  const { currentStaff, currentStore } = useLoginContext();
  const chargeEntityContainer = ChargeEntityContainer.useContainer();
  const {
    getCharges,
    getProducts,
    downloadChargesCsv,
  } = chargeEntityContainer.logic;
  const { charges, products } = chargeEntityContainer.state;
  const csvApi = useApi("/staff/owner/payjp");
  const balancesApi = useApi<PayjpBalancesResponse>("/staff/owner/payjp");
  const balances = balancesApi.response?.data ?? [];
  const latestBalance = balances[0];
  const multipleStoresExists = currentStaff.stores.length > 1;
  const showTransfer = currentStaff.is_owner &&
    ((!multipleStoresExists && currentStore.store_type === "child") ||
      (multipleStoresExists && currentStore.store_type === "owner_group"));

  useEffect(() => {
    // データ取得
    getCharges();
    getProducts();

    if (showTransfer) {
      balancesApi.api.get("/balances");
    }
  }, [getCharges, getProducts, balancesApi.api, showTransfer]);


  const downloadCharges = async (statement: PayjpStatement) => {
    if (!statement.term) return;

    const response = await csvApi.api.blob(`/charges.csv?term_id=${statement.term.id}`);
    if (!response) return;

    downloadCSVFile(response);
  };

  const devideProducts = ["menu", "ticket", "subscription"]
    .map((category) => products.filter((value) => value.category === category))

  return (
    <Grid fluid className="content">
      {showTransfer && (
        <Box display="flex" gap={2} mb={2} alignItems="flex-start">
          <Section title="次回入金" sx={{ margin: 0, flex: "1 0 0" }}>
            {!latestBalance && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {latestBalance && (
              <Box fontWeight={700}>
                <Box display="flex" flexDirection="column" gap={1} mb={1}>
                  <Box display="flex" alignItems="baseline" fontSize={40} gap={1}>
                    {latestBalance.net > 0
                      ? <>{latestBalance.net.toLocaleString()} <Box fontSize={24}>円</Box></>
                      : <strong style={{ fontSize: 40 }}>---</strong>}
                  </Box>

                  <Box fontSize={12}>
                    入金予定日: {latestBalance.due_date ? moment(latestBalance.due_date * 1000).format("YYYY/M/D") : "---"}
                  </Box>

                  <Box fontSize={12}>
                    ステータス: {PayjpBalanceStatusLabels[latestBalance.state] ?? "---"}
                  </Box>
                </Box>

                <Box fontSize={10} color="#787878">
                  決済手数料を差し引いた金額です。<br />
                  繰越金も含まれています。
                </Box>
              </Box>
            )}
          </Section>

          <Section title="入金情報" sx={{ margin: 0, flex: "2 0 0" }}>
            {!balancesApi.loaded && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {balancesApi.loaded && (
              <>
                <BalanceList
                  disabled={csvApi.loading}
                  balances={balances}
                  onClickDownload={downloadCharges}
                />

                <Typography fontSize={13} color="#787878" mt={1} lineHeight="180%">
                  最新の12件の入金明細をダウンロードすることが可能です。<br />
                  2024年5月以前の入金データが必要な場合はサポートまでお問い合わせください。
                </Typography>
              </>
            )}
          </Section>
        </Box>
      )}

      <Row>
        <Col xs={12}>
          <TotalChargeCard />
        </Col>
      </Row>

      <Row>
        <ChargeGraph
          lg={12}
          initialDataUnit="daily"
          displayNotification={displayNotification}
        />
      </Row>

      <Row>
        <Col md={12}>
          <ChargesListCard
            downloadChargesCsv={downloadChargesCsv}
            charges={charges}
            history={history}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <ProductSalesCard devideProducts={devideProducts} />
        </Col>
      </Row>
    </Grid>
  );
};

export default ReportView;
