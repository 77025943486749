import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CircularProgress from "@mui/material/CircularProgress";
import StatusBar from "../../components/StatusBar";
import { useApi, useGetApiCall, useQuery } from "../../lib/hooks";
import { StoreBenchmarkResponse } from "../../types/storeBenchmark";
import BenchmarkDetailPerformance from "./parts/BenchmarkDetailPerformance";
import BenchmarkEditForm from "./parts/BenchmarkEditForm";
import BenchmarkNotificationSettings from "./parts/BenchmarkNotificationSettings";

const TABS = ["performance", "benchmark", "notification"];
type Tab = typeof TABS[number];

const BenchmarkDetailView = (): JSX.Element => {
  const { id } = useParams();
  const history = useHistory();
  const query = useQuery();
  const currentTab = TABS.includes(query.get("tab") || "") ? query.get("tab") as Tab : "performance";
  const benchmarkApi = useGetApiCall<StoreBenchmarkResponse>(`/store_benchmarks/${id}`);
  const updateApi = useApi();
  const benchmark = benchmarkApi.response?.store_benchmark;
  const notificationPolicies = benchmarkApi.response?.notification_policies ?? [];
  const dailyNotificationPolicies = benchmarkApi.response?.daily_notification_policies ?? [];
  const dailyBenchmarks = (benchmarkApi.response?.children || [])
    .map(({ store_benchmark }) => store_benchmark);

  const changeTab = (tab: Tab) => history.push(`?tab=${tab}`);

  return benchmarkApi.loaded && benchmark ? (
    <div>
      {benchmark.status === "active" && benchmark.benchmark_progress === "ongoing" && (
        <StatusBar published label="目標は現在進行中です" />
      )}
      {benchmark.status === "paused" && (
        <StatusBar label="目標は現在停止中です" />
      )}

      <Box bgcolor="#fff">
        <Box p={2}>
          <Box fontSize={17} fontWeight={700}>{benchmark.title}</Box>
          <Box fontSize={13} color="#555555">{benchmark.start_date} 〜 {benchmark.end_date}</Box>
        </Box>

        <Tabs
          value={currentTab}
          onChange={(_, tab: Tab) => changeTab(tab)}
        >
          <Tab value="performance" label="実績" />
          <Tab value="benchmark" label="目標設定" />
          <Tab value="notification" label="通知設定" />
        </Tabs>
      </Box>

      {currentTab === "performance" && (
        <BenchmarkDetailPerformance
          benchmark={benchmark}
          dailyBenchmarks={dailyBenchmarks}
          disabled={benchmarkApi.loading || updateApi.loading}
          onUpdatedRetrospective={benchmarkApi.reload}
          onClickChangeDailyPerformance={() => changeTab("benchmark")}
        />
      )}

      {currentTab === "benchmark" && (
        <BenchmarkEditForm
          benchmark={benchmark}
          dailyBenchmarks={dailyBenchmarks}
          onUpdatedBenchmark={benchmarkApi.reload}
        />
      )}

      {currentTab === "notification" && (
        <BenchmarkNotificationSettings
          benchmark={benchmark}
          dailyBenchmarks={dailyBenchmarks}
          notificationPolicies={notificationPolicies}
          dailyNotificationPolicies={dailyNotificationPolicies}
          onUpdatedNotification={benchmarkApi.reload}
        />
      )}
    </div>
  ) : (
    <Box display="flex" justifyContent="center" my={5}>
      <CircularProgress />
    </Box>
  );
};

export default BenchmarkDetailView;
